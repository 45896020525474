export const CityData = [
    {
      "id": 1,
      "CityName": "Delhi NCR",
      "CityValue": "Delhi NCR"
    },
    {
      "id": 2,
      "CityName": "Mumbai MMR",
      "CityValue": "MMR"
    },
    {
      "id": 3,
      "CityName": "Bangalore",
      "CityValue": "Bangalore"
    },
    {
      "id": 4,
      "CityName": "Hyderabad",
      "CityValue": "Hyderabad"
    },
    {
      "id": 5,
      "CityName": "Chennai",
      "CityValue": "Chennai"
    },
    {
      "id": 6,
      "CityName": "Ahmadabad",
      "CityValue": "Ahmadabad"
    },
    {
      "id": 7,
      "CityName": "Kolkata",
      "CityValue": "Kolkata"
    },
    {
      "id": 8,
      "CityName": "Pune",
      "CityValue": "Pune"
    },
    {
      "id": 9,
      "CityName": "Surat",
      "CityValue": "Surat"
    },
    {
      "id": 10,
      "CityName": "Coimbatore",
      "CityValue": "Coimbatore"
    },
    {
      "id": 11,
      "CityName": "Jaipur",
      "CityValue": "Jaipur"
    },
    {
      "id": 12,
      "CityName": "Mysore",
      "CityValue": "Mysore"
    },
    {
      "id": 13,
      "CityName": "Chandigarh",
      "CityValue": "Chandigarh"
    },
    {
      "id": 14,
      "CityName": "Vishakhapatnam",
      "CityValue": "Vishakhapatnam"
    },
    {
      "id": 15,
      "CityName": "Nashik",
      "CityValue": "Nashik"
    },
    {
      "id": 16,
      "CityName": "Vijayawada",
      "CityValue": "Vijayawada"
    },
    {
      "id": 17,
      "CityName": "Madurai",
      "CityValue": "Madurai"
    },
    {
      "id": 18,
      "CityName": "Thiruvananthapuram",
      "CityValue": "Thiruvananthapuram"
    },
    {
      "id": 19,
      "CityName": "Warangal",
      "CityValue": "Warangal"
    },
    {
      "id": 20,
      "CityName": "Mangalore",
      "CityValue": "Mangalore"
    },
    {
      "id": 21,
      "CityName": "Lucknow",
      "CityValue": "Lucknow"
    },
    {
      "id": 22,
      "CityName": "Indore",
      "CityValue": "Indore"
    },
    {
      "id": 23,
      "CityName": "Delhi NCT",
      "CityValue": "Delhi NCT"
    },
    {
      "id": 24,
      "CityName": "Greater Mumbai",
      "CityValue": "Greater Mumbai"
    },
    {
      "id": 25,
      "CityName": "Pimpri Chinchwad",
      "CityValue": "Pimpri Chinchwad"
    },
    {
      "id": 26,
      "CityName": "Nagpur",
      "CityValue": "Nagpur"
    },
    {
      "id": 27,
      "CityName": "Kanpur",
      "CityValue": "Kanpur"
    },
    {
      "id": 28,
      "CityName": "Ludhiana",
      "CityValue": "Ludhiana"
    },
    {
      "id": 29,
      "CityName": "Bhopal",
      "CityValue": "Bhopal"
    },
    {
      "id": 30,
      "CityName": "Varanasi",
      "CityValue": "Varanasi"
    },
    {
      "id": 31,
      "CityName": "Rajkot",
      "CityValue": "Rajkot"
    },
    {
      "id": 32,
      "CityName": "Ghaziabad",
      "CityValue": "Ghaziabad"
    },
    {
      "id": 33,
      "CityName": "Patna",
      "CityValue": "Patna"
    },
    {
      "id": 34,
      "CityName": "Agra",
      "CityValue": "Agra"
    },
    {
      "id": 35,
      "CityName": "Thane",
      "CityValue": "Thane"
    },
    {
      "id": 36,
      "CityName": "Faridabad",
      "CityValue": "Faridabad"
    },
    {
      "id": 37,
      "CityName": "Vadodara",
      "CityValue": "Vadodara"
    },
    {
      "id": 38,
      "CityName": "Meerut",
      "CityValue": "Meerut"
    },
    {
      "id": 39,
      "CityName": "Allahabad",
      "CityValue": "Allahabad"
    },
    {
      "id": 40,
      "CityName": "Amritsar",
      "CityValue": "Amritsar"
    },
    {
      "id": 41,
      "CityName": "Vasai-Virar City",
      "CityValue": "Vasai-Virar City"
    },
    {
      "id": 42,
      "CityName": "Aurangabad",
      "CityValue": "Aurangabad"
    },
    {
      "id": 43,
      "CityName": "Jodhpur",
      "CityValue": "Jodhpur"
    },
    {
      "id": 44,
      "CityName": "Gwalior",
      "CityValue": "Gwalior"
    },
    {
      "id": 45,
      "CityName": "Dhanbad",
      "CityValue": "Dhanbad"
    },
    {
      "id": 46,
      "CityName": "Kalyan-Dombivli",
      "CityValue": "Kalyan-Dombivli"
    },
    {
      "id": 47,
      "CityName": "Gurgaon",
      "CityValue": "Gurgaon"
    },
    {
      "id": 48,
      "CityName": "Raipur",
      "CityValue": "Raipur"
    },
    {
      "id": 49,
      "CityName": "Jabalpur",
      "CityValue": "Jabalpur"
    },
    {
      "id": 50,
      "CityName": "Haora",
      "CityValue": "Haora"
    },
    {
      "id": 51,
      "CityName": "Kota",
      "CityValue": "Kota"
    },
    {
      "id": 52,
      "CityName": "Navi Mumbai",
      "CityValue": "Navi Mumbai"
    },
    {
      "id": 53,
      "CityName": "Jalandhar",
      "CityValue": "Jalandhar"
    },
    {
      "id": 54,
      "CityName": "Bhubaneswar",
      "CityValue": "Bhubaneswar"
    },
    {
      "id": 55,
      "CityName": "Ranchi",
      "CityValue": "Ranchi"
    },
    {
      "id": 56,
      "CityName": "Guwahati",
      "CityValue": "Guwahati"
    },
    {
      "id": 57,
      "CityName": "Tiruchirappalli",
      "CityValue": "Tiruchirappalli"
    },
    {
      "id": 58,
      "CityName": "Hubli-Dharwad",
      "CityValue": "Hubli-Dharwad"
    },
    {
      "id": 59,
      "CityName": "Solapur",
      "CityValue": "Solapur"
    },
    {
      "id": 60,
      "CityName": "Moradabad",
      "CityValue": "Moradabad"
    },
    {
      "id": 61,
      "CityName": "Kochi",
      "CityValue": "Kochi"
    },
    {
      "id": 62,
      "CityName": "Noida",
      "CityValue": "Noida"
    },
    {
      "id": 63,
      "CityName": "Bareilly",
      "CityValue": "Bareilly"
    },
    {
      "id": 64,
      "CityName": "Salem",
      "CityValue": "Salem"
    },
    {
      "id": 65,
      "CityName": "Dehradun",
      "CityValue": "Dehradun"
    },
    {
      "id": 66,
      "CityName": "Bhiwandi Nizampur",
      "CityValue": "Bhiwandi Nizampur"
    },
    {
      "id": 67,
      "CityName": "Bikaner",
      "CityValue": "Bikaner"
    },
    {
      "id": 68,
      "CityName": "Saharanpur",
      "CityValue": "Saharanpur"
    },
    {
      "id": 69,
      "CityName": "Guntur",
      "CityValue": "Guntur"
    },
    {
      "id": 70,
      "CityName": "Mira-Bhayandar",
      "CityValue": "Mira-Bhayandar"
    },
    {
      "id": 71,
      "CityName": "Aligarh",
      "CityValue": "Aligarh"
    },
    {
      "id": 72,
      "CityName": "Gorakhpur",
      "CityValue": "Gorakhpur"
    },
    {
      "id": 73,
      "CityName": "Tiruppur",
      "CityValue": "Tiruppur"
    },
    {
      "id": 74,
      "CityName": "Jamnagar",
      "CityValue": "Jamnagar"
    },
    {
      "id": 75,
      "CityName": "Amravati",
      "CityValue": "Amravati"
    },
    {
      "id": 76,
      "CityName": "Bhilai Nagar",
      "CityValue": "Bhilai Nagar"
    },
    {
      "id": 77,
      "CityName": "Udaipur",
      "CityValue": "Udaipur"
    },
    {
      "id": 78,
      "CityName": "Nellore",
      "CityValue": "Nellore"
    },
    {
      "id": 79,
      "CityName": "Firozabad",
      "CityValue": "Firozabad"
    },
    {
      "id": 80,
      "CityName": "Jamshedpur",
      "CityValue": "Jamshedpur"
    },
    {
      "id": 81,
      "CityName": "Bhavnagar",
      "CityValue": "Bhavnagar"
    },
    {
      "id": 82,
      "CityName": "Jammu",
      "CityValue": "Jammu"
    },
    {
      "id": 83,
      "CityName": "Cuttack",
      "CityValue": "Cuttack"
    },
    {
      "id": 84,
      "CityName": "Loni",
      "CityValue": "Loni"
    },
    {
      "id": 85,
      "CityName": "Kozhikode",
      "CityValue": "Kozhikode"
    },
    {
      "id": 86,
      "CityName": "Kolhapur",
      "CityValue": "Kolhapur"
    },
    {
      "id": 87,
      "CityName": "Nanded Waghala",
      "CityValue": "Nanded Waghala"
    },
    {
      "id": 88,
      "CityName": "Ajmer",
      "CityValue": "Ajmer"
    },
    {
      "id": 89,
      "CityName": "Gulbarga",
      "CityValue": "Gulbarga"
    },
    {
      "id": 90,
      "CityName": "Asansol",
      "CityValue": "Asansol"
    },
    {
      "id": 91,
      "CityName": "Durgapur",
      "CityValue": "Durgapur"
    },
    {
      "id": 92,
      "CityName": "Siliguri",
      "CityValue": "Siliguri"
    },
    {
      "id": 93,
      "CityName": "Ulhasnagar",
      "CityValue": "Ulhasnagar"
    },
    {
      "id": 94,
      "CityName": "Ujjain",
      "CityValue": "Ujjain"
    },
    {
      "id": 95,
      "CityName": "Malegaon",
      "CityValue": "Malegaon"
    },
    {
      "id": 96,
      "CityName": "Ambattur",
      "CityValue": "Ambattur"
    },
    {
      "id": 97,
      "CityName": "Sangli Miraj Kupwad",
      "CityValue": "Sangli Miraj Kupwad"
    },
    {
      "id": 98,
      "CityName": "Jhansi",
      "CityValue": "Jhansi"
    },
    {
      "id": 99,
      "CityName": "Belgaum",
      "CityValue": "Belgaum"
    },
    {
      "id": 100,
      "CityName": "Kurnool",
      "CityValue": "Kurnool"
    },
    {
      "id": 101,
      "CityName": "Gaya",
      "CityValue": "Gaya"
    },
    {
      "id": 102,
      "CityName": "Greater Noida",
      "CityValue": "Greater Noida"
    },
    {
      "id": 103,
      "CityName": "Tirunelveli",
      "CityValue": "Tirunelveli"
    },
    {
      "id": 104,
      "CityName": "Jalgaon",
      "CityValue": "Jalgaon"
    },
    {
      "id": 105,
      "CityName": "Rohtak",
      "CityValue": "Rohtak"
    },
    {
      "id": 106,
      "CityName": "Patiala",
      "CityValue": "Patiala"
    },
    {
      "id": 107,
      "CityName": "Rajarhat Gopalpur",
      "CityValue": "Rajarhat Gopalpur"
    },
    {
      "id": 108,
      "CityName": "Maheshtala",
      "CityValue": "Maheshtala"
    },
    {
      "id": 109,
      "CityName": "Bellary",
      "CityValue": "Bellary"
    },
    {
      "id": 110,
      "CityName": "Akola",
      "CityValue": "Akola"
    },
    {
      "id": 111,
      "CityName": "Davanagere",
      "CityValue": "Davanagere"
    },
    {
      "id": 112,
      "CityName": "Bhagalpur",
      "CityValue": "Bhagalpur"
    },
    {
      "id": 113,
      "CityName": "Tirupati",
      "CityValue": "Tirupati"
    },
    {
      "id": 114,
      "CityName": "Latur",
      "CityValue": "Latur"
    },
    {
      "id": 115,
      "CityName": "Rajpur Sonarpur",
      "CityValue": "Rajpur Sonarpur"
    },
    {
      "id": 116,
      "CityName": "Dhule",
      "CityValue": "Dhule"
    },
    {
      "id": 117,
      "CityName": "Ozhukarai",
      "CityValue": "Ozhukarai"
    },
    {
      "id": 118,
      "CityName": "Bokaro Steel City",
      "CityValue": "Bokaro Steel City"
    },
    {
      "id": 119,
      "CityName": "Bhatpara",
      "CityValue": "Bhatpara"
    },
    {
      "id": 120,
      "CityName": "Vellore",
      "CityValue": "Vellore"
    },
    {
      "id": 121,
      "CityName": "Muzaffarnagar",
      "CityValue": "Muzaffarnagar"
    },
    {
      "id": 122,
      "CityName": "Kakinada",
      "CityValue": "Kakinada"
    },
    {
      "id": 123,
      "CityName": "Agartala",
      "CityValue": "Agartala"
    },
    {
      "id": 124,
      "CityName": "Avadi",
      "CityValue": "Avadi"
    },
    {
      "id": 125,
      "CityName": "Shahjahanpur",
      "CityValue": "Shahjahanpur"
    },
    {
      "id": 126,
      "CityName": "Mathura",
      "CityValue": "Mathura"
    },
    {
      "id": 127,
      "CityName": "Bilaspur",
      "CityValue": "Bilaspur"
    },
    {
      "id": 128,
      "CityName": "Panihati",
      "CityValue": "Panihati"
    },
    {
      "id": 129,
      "CityName": "Rajahmundry",
      "CityValue": "Rajahmundry"
    },
    {
      "id": 130,
      "CityName": "Puducherry",
      "CityValue": "Puducherry"
    },
    {
      "id": 131,
      "CityName": "Panipat",
      "CityValue": "Panipat"
    },
    {
      "id": 132,
      "CityName": "Korba",
      "CityValue": "Korba"
    },
    {
      "id": 133,
      "CityName": "Muzaffarpur",
      "CityValue": "Muzaffarpur"
    },
    {
      "id": 134,
      "CityName": "Thrissur",
      "CityValue": "Thrissur"
    },
    {
      "id": 135,
      "CityName": "South DumDum",
      "CityValue": "South DumDum"
    },
    {
      "id": 136,
      "CityName": "Kadapa",
      "CityValue": "Kadapa"
    },
    {
      "id": 137,
      "CityName": "Ahmadnagar",
      "CityValue": "Ahmadnagar"
    },
    {
      "id": 138,
      "CityName": "Brahmapur",
      "CityValue": "Brahmapur"
    },
    {
      "id": 139,
      "CityName": "Alwar",
      "CityValue": "Alwar"
    },
    {
      "id": 140,
      "CityName": "Kollam",
      "CityValue": "Kollam"
    },
    {
      "id": 141,
      "CityName": "Secunderabad",
      "CityValue": "Secunderabad"
    },
    {
      "id": 142,
      "CityName": "Bhilwara",
      "CityValue": "Bhilwara"
    },
    {
      "id": 143,
      "CityName": "Sonipat",
      "CityValue": "Sonipat"
    },
    {
      "id": 144,
      "CityName": "Bijapur",
      "CityValue": "Bijapur"
    },
    {
      "id": 145,
      "CityName": "Kamarhati",
      "CityValue": "Kamarhati"
    },
    {
      "id": 146,
      "CityName": "Raurkela",
      "CityValue": "Raurkela"
    },
    {
      "id": 147,
      "CityName": "Rampur",
      "CityValue": "Rampur"
    },
    {
      "id": 148,
      "CityName": "Shimoga",
      "CityValue": "Shimoga"
    },
    {
      "id": 149,
      "CityName": "Hisar",
      "CityValue": "Hisar"
    },
    {
      "id": 150,
      "CityName": "Biharsharif",
      "CityValue": "Biharsharif"
    },
    {
      "id": 151,
      "CityName": "Nizamabad",
      "CityValue": "Nizamabad"
    },
    {
      "id": 152,
      "CityName": "Junagadh",
      "CityValue": "Junagadh"
    },
    {
      "id": 153,
      "CityName": "Kulti",
      "CityValue": "Kulti"
    },
    {
      "id": 154,
      "CityName": "Parbhani",
      "CityValue": "Parbhani"
    },
    {
      "id": 155,
      "CityName": "Karnal",
      "CityValue": "Karnal"
    },
    {
      "id": 156,
      "CityName": "Chandrapur",
      "CityValue": "Chandrapur"
    },
    {
      "id": 157,
      "CityName": "Darbhanga",
      "CityValue": "Darbhanga"
    },
    {
      "id": 158,
      "CityName": "Jalna",
      "CityValue": "Jalna"
    },
    {
      "id": 159,
      "CityName": "Barddhaman",
      "CityValue": "Barddhaman"
    },
    {
      "id": 160,
      "CityName": "Ichalkaranji",
      "CityValue": "Ichalkaranji"
    },
    {
      "id": 161,
      "CityName": "Bally",
      "CityValue": "Bally"
    },
    {
      "id": 162,
      "CityName": "Tumkur",
      "CityValue": "Tumkur"
    },
    {
      "id": 163,
      "CityName": "Aizawl",
      "CityValue": "Aizawl"
    },
    {
      "id": 164,
      "CityName": "Karimnagar",
      "CityValue": "Karimnagar"
    },
    {
      "id": 165,
      "CityName": "Hapur",
      "CityValue": "Hapur"
    },
    {
      "id": 166,
      "CityName": "Pallavaram",
      "CityValue": "Pallavaram"
    },
    {
      "id": 167,
      "CityName": "Kharagpur",
      "CityValue": "Kharagpur"
    },
    {
      "id": 168,
      "CityName": "Purnia",
      "CityValue": "Purnia"
    },
    {
      "id": 169,
      "CityName": "Barasat",
      "CityValue": "Barasat"
    },
    {
      "id": 170,
      "CityName": "Sagar",
      "CityValue": "Sagar"
    },
    {
      "id": 171,
      "CityName": "Bathinda",
      "CityValue": "Bathinda"
    },
    {
      "id": 172,
      "CityName": "Anantapur",
      "CityValue": "Anantapur"
    },
    {
      "id": 173,
      "CityName": "Tiruvottiyur",
      "CityValue": "Tiruvottiyur"
    },
    {
      "id": 174,
      "CityName": "Dewas",
      "CityValue": "Dewas"
    },
    {
      "id": 175,
      "CityName": "Imphal",
      "CityValue": "Imphal"
    },
    {
      "id": 176,
      "CityName": "Satna",
      "CityValue": "Satna"
    },
    {
      "id": 177,
      "CityName": "Kirari Suleman Nagar",
      "CityValue": "Kirari Suleman Nagar"
    },
    {
      "id": 178,
      "CityName": "Maunath Bhanjan",
      "CityValue": "Maunath Bhanjan"
    },
    {
      "id": 179,
      "CityName": "Gandhidham",
      "CityValue": "Gandhidham"
    },
    {
      "id": 180,
      "CityName": "Ambarnath",
      "CityValue": "Ambarnath"
    },
    {
      "id": 181,
      "CityName": "Farrukhabad-cum-Fatehgarh",
      "CityValue": "Farrukhabad-cum-Fatehgarh"
    },
    {
      "id": 182,
      "CityName": "Durg",
      "CityValue": "Durg"
    },
    {
      "id": 183,
      "CityName": "Hardwar",
      "CityValue": "Hardwar"
    },
    {
      "id": 184,
      "CityName": "Ratlam",
      "CityValue": "Ratlam"
    },
    {
      "id": 185,
      "CityName": "Haldwani-cum-Kathgodam",
      "CityValue": "Haldwani-cum-Kathgodam"
    },
    {
      "id": 186,
      "CityName": "Arrah",
      "CityValue": "Arrah"
    },
    {
      "id": 187,
      "CityName": "North DumDum",
      "CityValue": "North DumDum"
    },
    {
      "id": 188,
      "CityName": "New Delhi",
      "CityValue": "New Delhi"
    },
    {
      "id": 189,
      "CityName": "Bharatpur",
      "CityValue": "Bharatpur"
    },
    {
      "id": 190,
      "CityName": "Gandhinagar",
      "CityValue": "Gandhinagar"
    },
    {
      "id": 191,
      "CityName": "Etawah",
      "CityValue": "Etawah"
    },
    {
      "id": 192,
      "CityName": "Sikar",
      "CityValue": "Sikar"
    },
    {
      "id": 193,
      "CityName": "Begusarai",
      "CityValue": "Begusarai"
    },
    {
      "id": 194,
      "CityName": "Katihar",
      "CityValue": "Katihar"
    },
    {
      "id": 195,
      "CityName": "Thoothukkudi",
      "CityValue": "Thoothukkudi"
    },
    {
      "id": 196,
      "CityName": "Uluberia",
      "CityValue": "Uluberia"
    },
    {
      "id": 197,
      "CityName": "Ramagundam",
      "CityValue": "Ramagundam"
    },
    {
      "id": 198,
      "CityName": "Bulandshahr",
      "CityValue": "Bulandshahr"
    },
    {
      "id": 199,
      "CityName": "Raichur",
      "CityValue": "Raichur"
    },
    {
      "id": 200,
      "CityName": "Morvi",
      "CityValue": "Morvi"
    },
    {
      "id": 201,
      "CityName": "Alappuzha",
      "CityValue": "Alappuzha"
    },
    {
      "id": 202,
      "CityName": "Panchkula",
      "CityValue": "Panchkula"
    },
    {
      "id": 203,
      "CityName": "Sambhal",
      "CityValue": "Sambhal"
    },
    {
      "id": 204,
      "CityName": "Singrauli",
      "CityValue": "Singrauli"
    },
    {
      "id": 205,
      "CityName": "Rewa",
      "CityValue": "Rewa"
    },
    {
      "id": 206,
      "CityName": "Pali",
      "CityValue": "Pali"
    },
    {
      "id": 207,
      "CityName": "Nagercoil",
      "CityValue": "Nagercoil"
    },
    {
      "id": 208,
      "CityName": "Morena",
      "CityValue": "Morena"
    },
    {
      "id": 209,
      "CityName": "Mango",
      "CityValue": "Mango"
    },
    {
      "id": 210,
      "CityName": "Mirzapur-cum-Vindhyachal",
      "CityValue": "Mirzapur-cum-Vindhyachal"
    },
    {
      "id": 211,
      "CityName": "Nandyal",
      "CityValue": "Nandyal"
    },
    {
      "id": 212,
      "CityName": "Hospet",
      "CityValue": "Hospet"
    },
    {
      "id": 213,
      "CityName": "Yamunanagar",
      "CityValue": "Yamunanagar"
    },
    {
      "id": 214,
      "CityName": "Vizianagaram",
      "CityValue": "Vizianagaram"
    },
    {
      "id": 215,
      "CityName": "Nadiad",
      "CityValue": "Nadiad"
    },
    {
      "id": 216,
      "CityName": "Thanjavur",
      "CityValue": "Thanjavur"
    },
    {
      "id": 217,
      "CityName": "Karawal Nagar",
      "CityValue": "Karawal Nagar"
    },
    {
      "id": 218,
      "CityName": "Ganganagar",
      "CityValue": "Ganganagar"
    },
    {
      "id": 219,
      "CityName": "Munger",
      "CityValue": "Munger"
    },
    {
      "id": 220,
      "CityName": "Tambaram",
      "CityValue": "Tambaram"
    },
    {
      "id": 221,
      "CityName": "Murwara",
      "CityValue": "Murwara"
    },
    {
      "id": 222,
      "CityName": "Raurkela",
      "CityValue": "Raurkela"
    },
    {
      "id": 223,
      "CityName": "Naihati",
      "CityValue": "Naihati"
    },
    {
      "id": 224,
      "CityName": "Bidar",
      "CityValue": "Bidar"
    },
    {
      "id": 225,
      "CityName": "Sirsa",
      "CityValue": "Sirsa"
    },
    {
      "id": 226,
      "CityName": "Burhanpur",
      "CityValue": "Burhanpur"
    },
    {
      "id": 227,
      "CityName": "English Bazar",
      "CityValue": "English Bazar"
    },
    {
      "id": 228,
      "CityName": "Eluru",
      "CityValue": "Eluru"
    },
    {
      "id": 229,
      "CityName": "Ongole",
      "CityValue": "Ongole"
    },
    {
      "id": 230,
      "CityName": "Khandwa",
      "CityValue": "Khandwa"
    },
    {
      "id": 231,
      "CityName": "Baranagar",
      "CityValue": "Baranagar"
    },
    {
      "id": 232,
      "CityName": "Chapra",
      "CityValue": "Chapra"
    },
    {
      "id": 233,
      "CityName": "Khora",
      "CityValue": "Khora"
    },
    {
      "id": 234,
      "CityName": "Deoghar",
      "CityValue": "Deoghar"
    },
    {
      "id": 235,
      "CityName": "Haldia",
      "CityValue": "Haldia"
    },
    {
      "id": 236,
      "CityName": "Amroha",
      "CityValue": "Amroha"
    },
    {
      "id": 237,
      "CityName": "Anand",
      "CityValue": "Anand"
    },
    {
      "id": 238,
      "CityName": "Dindigul",
      "CityValue": "Dindigul"
    },
    {
      "id": 239,
      "CityName": "Bhiwani",
      "CityValue": "Bhiwani"
    },
    {
      "id": 240,
      "CityName": "Madanapalle",
      "CityValue": "Madanapalle"
    },
    {
      "id": 241,
      "CityName": "Navi Mumbai Panvel Raigarh",
      "CityValue": "Navi Mumbai Panvel Raigarh"
    },
    {
      "id": 242,
      "CityName": "Bhind",
      "CityValue": "Bhind"
    },
    {
      "id": 243,
      "CityName": "Nangloi Jat",
      "CityValue": "Nangloi Jat"
    },
    {
      "id": 244,
      "CityName": "Madhyamgram",
      "CityValue": "Madhyamgram"
    },
    {
      "id": 245,
      "CityName": "Akbarpur",
      "CityValue": "Akbarpur"
    },
    {
      "id": 246,
      "CityName": "Ambala",
      "CityValue": "Ambala"
    },
    {
      "id": 247,
      "CityName": "Berhampore",
      "CityValue": "Berhampore"
    },
    {
      "id": 248,
      "CityName": "S.A.S. Nagar",
      "CityValue": "S.A.S. Nagar"
    },
    {
      "id": 249,
      "CityName": "Puri",
      "CityValue": "Puri"
    },
    {
      "id": 250,
      "CityName": "Mahbubnagar",
      "CityValue": "Mahbubnagar"
    },
    {
      "id": 251,
      "CityName": "Khammam",
      "CityValue": "Khammam"
    },
    {
      "id": 252,
      "CityName": "Gangtok",
      "CityValue": "Gangtok"
    },
    {
      "id": 253,
      "CityName": "Silchar",
      "CityValue": "Silchar"
    },
    {
      "id": 254,
      "CityName": "Dinapur Nizamat",
      "CityValue": "Dinapur Nizamat"
    },
    {
      "id": 255,
      "CityName": "Hardoi",
      "CityValue": "Hardoi"
    },
    {
      "id": 256,
      "CityName": "Bhalswa Jahangir Pur",
      "CityValue": "Bhalswa Jahangir Pur"
    },
    {
      "id": 257,
      "CityName": "Guna",
      "CityValue": "Guna"
    },
    {
      "id": 258,
      "CityName": "Adoni",
      "CityValue": "Adoni"
    },
    {
      "id": 259,
      "CityName": "Bahraich",
      "CityValue": "Bahraich"
    },
    {
      "id": 260,
      "CityName": "Orai",
      "CityValue": "Orai"
    },
    {
      "id": 261,
      "CityName": "Bidhannagar",
      "CityValue": "Bidhannagar"
    },
    {
      "id": 262,
      "CityName": "Bhusawal",
      "CityValue": "Bhusawal"
    },
    {
      "id": 263,
      "CityName": "Zirakpur",
      "CityValue": "Zirakpur"
    },
    {
      "id": 264,
      "CityName": "Mahesana",
      "CityValue": "Mahesana"
    },
    {
      "id": 265,
      "CityName": "Fatehpur",
      "CityValue": "Fatehpur"
    },
    {
      "id": 266,
      "CityName": "Raiganj",
      "CityValue": "Raiganj"
    },
    {
      "id": 267,
      "CityName": "Sambalpur",
      "CityValue": "Sambalpur"
    },
    {
      "id": 268,
      "CityName": "Shivpuri",
      "CityValue": "Shivpuri"
    },
    {
      "id": 269,
      "CityName": "Bhiwadi",
      "CityValue": "Bhiwadi"
    },
    {
      "id": 270,
      "CityName": "Rae Bareli",
      "CityValue": "Rae Bareli"
    },
    {
      "id": 271,
      "CityName": "Badlapur",
      "CityValue": "Badlapur"
    },
    {
      "id": 272,
      "CityName": "Vapi",
      "CityValue": "Vapi"
    },
    {
      "id": 273,
      "CityName": "Delhi Cantonment",
      "CityValue": "Delhi Cantonment"
    },
    {
      "id": 274,
      "CityName": "Panvel",
      "CityValue": "Panvel"
    },
    {
      "id": 275,
      "CityName": "Sitapur",
      "CityValue": "Sitapur"
    },
    {
      "id": 276,
      "CityName": "Unnao",
      "CityValue": "Unnao"
    },
    {
      "id": 277,
      "CityName": "Cuddalore",
      "CityValue": "Cuddalore"
    },
    {
      "id": 278,
      "CityName": "Adityapur",
      "CityValue": "Adityapur"
    },
    {
      "id": 279,
      "CityName": "Surendranagar Dudhrej",
      "CityValue": "Surendranagar Dudhrej"
    },
    {
      "id": 280,
      "CityName": "Jind",
      "CityValue": "Jind"
    },
    {
      "id": 281,
      "CityName": "Hugli-Chinsurah",
      "CityValue": "Hugli-Chinsurah"
    },
    {
      "id": 282,
      "CityName": "Sultan Pur Majra",
      "CityValue": "Sultan Pur Majra"
    },
    {
      "id": 283,
      "CityName": "Alandur",
      "CityValue": "Alandur"
    },
    {
      "id": 284,
      "CityName": "Bharuch",
      "CityValue": "Bharuch"
    },
    {
      "id": 285,
      "CityName": "Tonk",
      "CityValue": "Tonk"
    },
    {
      "id": 286,
      "CityName": "Gadag-Betigeri",
      "CityValue": "Gadag-Betigeri"
    },
    {
      "id": 287,
      "CityName": "Chhindwara",
      "CityValue": "Chhindwara"
    },
    {
      "id": 288,
      "CityName": "Navsari",
      "CityValue": "Navsari"
    },
    {
      "id": 289,
      "CityName": "Jaunpur",
      "CityValue": "Jaunpur"
    },
    {
      "id": 290,
      "CityName": "Medinipur",
      "CityValue": "Medinipur"
    },
    {
      "id": 291,
      "CityName": "Hoshiarpur",
      "CityValue": "Hoshiarpur"
    },
    {
      "id": 292,
      "CityName": "Kancheepuram",
      "CityValue": "Kancheepuram"
    },
    {
      "id": 293,
      "CityName": "Bahadurgarh",
      "CityValue": "Bahadurgarh"
    },
    {
      "id": 294,
      "CityName": "Proddatur",
      "CityValue": "Proddatur"
    },
    {
      "id": 295,
      "CityName": "Hastsal",
      "CityValue": "Hastsal"
    },
    {
      "id": 296,
      "CityName": "Rajnandgaon",
      "CityValue": "Rajnandgaon"
    },
    {
      "id": 297,
      "CityName": "Serampore",
      "CityValue": "Serampore"
    },
    {
      "id": 298,
      "CityName": "Veraval",
      "CityValue": "Veraval"
    },
    {
      "id": 299,
      "CityName": "Tenali",
      "CityValue": "Tenali"
    },
    {
      "id": 300,
      "CityName": "Moga",
      "CityValue": "Moga"
    },
    {
      "id": 301,
      "CityName": "Deoli",
      "CityValue": "Deoli"
    },
    {
      "id": 302,
      "CityName": "Anantnag",
      "CityValue": "Anantnag"
    },
    {
      "id": 303,
      "CityName": "Raigarh",
      "CityValue": "Raigarh"
    },
    {
      "id": 304,
      "CityName": "Chandannagar",
      "CityValue": "Chandannagar"
    },
    {
      "id": 305,
      "CityName": "Bhuj",
      "CityValue": "Bhuj"
    },
    {
      "id": 306,
      "CityName": "Hindupur",
      "CityValue": "Hindupur"
    },
    {
      "id": 307,
      "CityName": "Chittoor",
      "CityValue": "Chittoor"
    },
    {
      "id": 308,
      "CityName": "Banda",
      "CityValue": "Banda"
    },
    {
      "id": 309,
      "CityName": "Shimla",
      "CityValue": "Shimla"
    },
    {
      "id": 310,
      "CityName": "Saharsa",
      "CityValue": "Saharsa"
    },
    {
      "id": 311,
      "CityName": "Machilipatnam",
      "CityValue": "Machilipatnam"
    },
    {
      "id": 312,
      "CityName": "Rudrapur",
      "CityValue": "Rudrapur"
    },
    {
      "id": 313,
      "CityName": "Rewari",
      "CityValue": "Rewari"
    },
    {
      "id": 314,
      "CityName": "Robertson Pet",
      "CityValue": "Robertson Pet"
    },
    {
      "id": 315,
      "CityName": "Kishangarh",
      "CityValue": "Kishangarh"
    },
    {
      "id": 316,
      "CityName": "Budaun",
      "CityValue": "Budaun"
    },
    {
      "id": 317,
      "CityName": "Batala",
      "CityValue": "Batala"
    },
    {
      "id": 318,
      "CityName": "Udupi",
      "CityValue": "Udupi"
    },
    {
      "id": 319,
      "CityName": "Porbandar",
      "CityValue": "Porbandar"
    },
    {
      "id": 320,
      "CityName": "Vidisha",
      "CityValue": "Vidisha"
    },
    {
      "id": 321,
      "CityName": "Thanesar",
      "CityValue": "Thanesar"
    },
    {
      "id": 322,
      "CityName": "Uttarpara Kotrung",
      "CityValue": "Uttarpara Kotrung"
    },
    {
      "id": 323,
      "CityName": "Erode",
      "CityValue": "Erode"
    },
    {
      "id": 324,
      "CityName": "Faizabad",
      "CityValue": "Faizabad"
    },
    {
      "id": 325,
      "CityName": "Balurghat",
      "CityValue": "Balurghat"
    },
    {
      "id": 326,
      "CityName": "Hassan",
      "CityValue": "Hassan"
    },
    {
      "id": 327,
      "CityName": "Krishnanagar",
      "CityValue": "Krishnanagar"
    },
    {
      "id": 328,
      "CityName": "Lakhimpur",
      "CityValue": "Lakhimpur"
    },
    {
      "id": 329,
      "CityName": "Beawar",
      "CityValue": "Beawar"
    },
    {
      "id": 330,
      "CityName": "Habra",
      "CityValue": "Habra"
    },
    {
      "id": 331,
      "CityName": "Santipur",
      "CityValue": "Santipur"
    },
    {
      "id": 332,
      "CityName": "Barrackpore",
      "CityValue": "Barrackpore"
    },
    {
      "id": 333,
      "CityName": "Jamuria",
      "CityValue": "Jamuria"
    },
    {
      "id": 334,
      "CityName": "Sasaram",
      "CityValue": "Sasaram"
    },
    {
      "id": 335,
      "CityName": "Hajipur",
      "CityValue": "Hajipur"
    },
    {
      "id": 336,
      "CityName": "Pathankot",
      "CityValue": "Pathankot"
    },
    {
      "id": 337,
      "CityName": "Dallo Pura",
      "CityValue": "Dallo Pura"
    },
    {
      "id": 338,
      "CityName": "Valsad",
      "CityValue": "Valsad"
    },
    {
      "id": 339,
      "CityName": "Tiruvannamalai",
      "CityValue": "Tiruvannamalai"
    },
    {
      "id": 340,
      "CityName": "Hanumangarh",
      "CityValue": "Hanumangarh"
    },
    {
      "id": 341,
      "CityName": "Nalgonda",
      "CityValue": "Nalgonda"
    },
    {
      "id": 342,
      "CityName": "Hazaribag",
      "CityValue": "Hazaribag"
    },
    {
      "id": 343,
      "CityName": "Kaithal",
      "CityValue": "Kaithal"
    },
    {
      "id": 344,
      "CityName": "Khanna",
      "CityValue": "Khanna"
    },
    {
      "id": 345,
      "CityName": "Palwal",
      "CityValue": "Palwal"
    },
    {
      "id": 346,
      "CityName": "Abohar",
      "CityValue": "Abohar"
    },
    {
      "id": 347,
      "CityName": "Bhimavaram",
      "CityValue": "Bhimavaram"
    },
    {
      "id": 348,
      "CityName": "Godhra",
      "CityValue": "Godhra"
    },
    {
      "id": 349,
      "CityName": "Dimapur",
      "CityValue": "Dimapur"
    },
    {
      "id": 350,
      "CityName": "Bid",
      "CityValue": "Bid"
    },
    {
      "id": 351,
      "CityName": "Hathras",
      "CityValue": "Hathras"
    },
    {
      "id": 352,
      "CityName": "Dibrugarh",
      "CityValue": "Dibrugarh"
    },
    {
      "id": 353,
      "CityName": "Shillong",
      "CityValue": "Shillong"
    },
    {
      "id": 354,
      "CityName": "Chitradurga",
      "CityValue": "Chitradurga"
    },
    {
      "id": 355,
      "CityName": "Palanpur",
      "CityValue": "Palanpur"
    },
    {
      "id": 356,
      "CityName": "Kolar",
      "CityValue": "Kolar"
    },
    {
      "id": 357,
      "CityName": "Dehri",
      "CityValue": "Dehri"
    },
    {
      "id": 358,
      "CityName": "Mainpuri",
      "CityValue": "Mainpuri"
    },
    {
      "id": 359,
      "CityName": "Burari",
      "CityValue": "Burari"
    },
    {
      "id": 360,
      "CityName": "Baleshwar",
      "CityValue": "Baleshwar"
    },
    {
      "id": 361,
      "CityName": "Bhadravati",
      "CityValue": "Bhadravati"
    },
    {
      "id": 362,
      "CityName": "Malerkotla",
      "CityValue": "Malerkotla"
    },
    {
      "id": 363,
      "CityName": "Chhatarpur",
      "CityValue": "Chhatarpur"
    },
    {
      "id": 364,
      "CityName": "Damoh",
      "CityValue": "Damoh"
    },
    {
      "id": 365,
      "CityName": "Mandsaur",
      "CityValue": "Mandsaur"
    },
    {
      "id": 366,
      "CityName": "Srikakulam",
      "CityValue": "Srikakulam"
    },
    {
      "id": 367,
      "CityName": "Chas",
      "CityValue": "Chas"
    },
    {
      "id": 368,
      "CityName": "Kalol",
      "CityValue": "Kalol"
    },
    {
      "id": 369,
      "CityName": "Modinagar",
      "CityValue": "Modinagar"
    },
    {
      "id": 370,
      "CityName": "Bankura",
      "CityValue": "Bankura"
    },
    {
      "id": 371,
      "CityName": "Bettiah",
      "CityValue": "Bettiah"
    },
    {
      "id": 372,
      "CityName": "Dhaulpur",
      "CityValue": "Dhaulpur"
    },
    {
      "id": 373,
      "CityName": "Patan",
      "CityValue": "Patan"
    },
    {
      "id": 374,
      "CityName": "Kumbakonam",
      "CityValue": "Kumbakonam"
    },
    {
      "id": 375,
      "CityName": "Siwan",
      "CityValue": "Siwan"
    },
    {
      "id": 376,
      "CityName": "Mandya",
      "CityValue": "Mandya"
    },
    {
      "id": 377,
      "CityName": "Gondiya",
      "CityValue": "Gondiya"
    },
    {
      "id": 378,
      "CityName": "Raniganj",
      "CityValue": "Raniganj"
    },
    {
      "id": 379,
      "CityName": "Madavaram",
      "CityValue": "Madavaram"
    },
    {
      "id": 380,
      "CityName": "Dum Dum",
      "CityValue": "Dum Dum"
    },
    {
      "id": 381,
      "CityName": "North Barrackpore",
      "CityValue": "North Barrackpore"
    },
    {
      "id": 382,
      "CityName": "Kurichi",
      "CityValue": "Kurichi"
    },
    {
      "id": 383,
      "CityName": "Ambikapur",
      "CityValue": "Ambikapur"
    },
    {
      "id": 384,
      "CityName": "Roorkee",
      "CityValue": "Roorkee"
    },
    {
      "id": 385,
      "CityName": "Pithampur",
      "CityValue": "Pithampur"
    },
    {
      "id": 386,
      "CityName": "Rajapalayam",
      "CityValue": "Rajapalayam"
    },
    {
      "id": 387,
      "CityName": "Silvassa",
      "CityValue": "Silvassa"
    },
    {
      "id": 388,
      "CityName": "Botad",
      "CityValue": "Botad"
    },
    {
      "id": 389,
      "CityName": "Lalitpur",
      "CityValue": "Lalitpur"
    },
    {
      "id": 390,
      "CityName": "Dharmavaram",
      "CityValue": "Dharmavaram"
    },
    {
      "id": 391,
      "CityName": "Motihari",
      "CityValue": "Motihari"
    },
    {
      "id": 392,
      "CityName": "Guntakal",
      "CityValue": "Guntakal"
    },
    {
      "id": 393,
      "CityName": "Jagadhri",
      "CityValue": "Jagadhri"
    },
    {
      "id": 394,
      "CityName": "Basirhat",
      "CityValue": "Basirhat"
    },
    {
      "id": 395,
      "CityName": "Palakkad",
      "CityValue": "Palakkad"
    },
    {
      "id": 396,
      "CityName": "Bhadrak",
      "CityValue": "Bhadrak"
    },
    {
      "id": 397,
      "CityName": "Nabadwip",
      "CityValue": "Nabadwip"
    },
    {
      "id": 398,
      "CityName": "Rishra",
      "CityValue": "Rishra"
    },
    {
      "id": 399,
      "CityName": "Kanhangad",
      "CityValue": "Kanhangad"
    },
    {
      "id": 400,
      "CityName": "Pilibhit",
      "CityValue": "Pilibhit"
    },
    {
      "id": 401,
      "CityName": "Nagaon",
      "CityValue": "Nagaon"
    },
    {
      "id": 402,
      "CityName": "Jagdalpur",
      "CityValue": "Jagdalpur"
    },
    {
      "id": 403,
      "CityName": "Kashipur",
      "CityValue": "Kashipur"
    },
    {
      "id": 404,
      "CityName": "Deoria",
      "CityValue": "Deoria"
    },
    {
      "id": 405,
      "CityName": "Neemuch",
      "CityValue": "Neemuch"
    },
    {
      "id": 406,
      "CityName": "Hosur",
      "CityValue": "Hosur"
    },
    {
      "id": 407,
      "CityName": "Jetpur Navagadh",
      "CityValue": "Jetpur Navagadh"
    },
    {
      "id": 408,
      "CityName": "Khurja",
      "CityValue": "Khurja"
    },
    {
      "id": 409,
      "CityName": "Barnala",
      "CityValue": "Barnala"
    },
    {
      "id": 410,
      "CityName": "Kanchrapara",
      "CityValue": "Kanchrapara"
    },
    {
      "id": 411,
      "CityName": "Jhunjhunun",
      "CityValue": "Jhunjhunun"
    },
    {
      "id": 412,
      "CityName": "Mustafabad",
      "CityValue": "Mustafabad"
    },
    {
      "id": 413,
      "CityName": "Satara",
      "CityValue": "Satara"
    },
    {
      "id": 414,
      "CityName": "Baidyabati",
      "CityValue": "Baidyabati"
    },
    {
      "id": 415,
      "CityName": "Ashokenagar Kalyangarh",
      "CityValue": "Ashokenagar Kalyangarh"
    },
    {
      "id": 416,
      "CityName": "Chikmagalur",
      "CityValue": "Chikmagalur"
    },
    {
      "id": 417,
      "CityName": "Churu",
      "CityValue": "Churu"
    },
    {
      "id": 418,
      "CityName": "Halisahar",
      "CityValue": "Halisahar"
    },
    {
      "id": 419,
      "CityName": "Tinsukia",
      "CityValue": "Tinsukia"
    },
    {
      "id": 420,
      "CityName": "Sawai Madhopur",
      "CityValue": "Sawai Madhopur"
    },
    {
      "id": 421,
      "CityName": "Ghazipur",
      "CityValue": "Ghazipur"
    },
    {
      "id": 422,
      "CityName": "Jorhat",
      "CityValue": "Jorhat"
    },
    {
      "id": 423,
      "CityName": "Darjiling",
      "CityValue": "Darjiling"
    },
    {
      "id": 424,
      "CityName": "Barshi",
      "CityValue": "Barshi"
    },
    {
      "id": 425,
      "CityName": "Dabgram",
      "CityValue": "Dabgram"
    },
    {
      "id": 426,
      "CityName": "Muktsar",
      "CityValue": "Muktsar"
    },
    {
      "id": 427,
      "CityName": "Gangapur City",
      "CityValue": "Gangapur City"
    },
    {
      "id": 428,
      "CityName": "Baran",
      "CityValue": "Baran"
    },
    {
      "id": 429,
      "CityName": "Ambur",
      "CityValue": "Ambur"
    },
    {
      "id": 430,
      "CityName": "Giridih",
      "CityValue": "Giridih"
    },
    {
      "id": 431,
      "CityName": "Etah",
      "CityValue": "Etah"
    },
    {
      "id": 432,
      "CityName": "Puruliya",
      "CityValue": "Puruliya"
    },
    {
      "id": 433,
      "CityName": "Gondal",
      "CityValue": "Gondal"
    },
    {
      "id": 434,
      "CityName": "Narasaraopet",
      "CityValue": "Narasaraopet"
    },
    {
      "id": 435,
      "CityName": "Gangawati",
      "CityValue": "Gangawati"
    },
    {
      "id": 436,
      "CityName": "Pudukkottai",
      "CityValue": "Pudukkottai"
    },
    {
      "id": 437,
      "CityName": "Gokal Pur",
      "CityValue": "Gokal Pur"
    },
    {
      "id": 438,
      "CityName": "Bagaha",
      "CityValue": "Bagaha"
    },
    {
      "id": 439,
      "CityName": "Gudivada",
      "CityValue": "Gudivada"
    },
    {
      "id": 440,
      "CityName": "Hoshangabad",
      "CityValue": "Hoshangabad"
    },
    {
      "id": 441,
      "CityName": "Nandurbar",
      "CityValue": "Nandurbar"
    },
    {
      "id": 442,
      "CityName": "Mandoli",
      "CityValue": "Mandoli"
    },
    {
      "id": 443,
      "CityName": "Khargone",
      "CityValue": "Khargone"
    },
    {
      "id": 444,
      "CityName": "Azamgarh",
      "CityValue": "Azamgarh"
    },
    {
      "id": 445,
      "CityName": "Sehore",
      "CityValue": "Sehore"
    },
    {
      "id": 446,
      "CityName": "Chandausi",
      "CityValue": "Chandausi"
    },
    {
      "id": 447,
      "CityName": "Adilabad",
      "CityValue": "Adilabad"
    },
    {
      "id": 448,
      "CityName": "Tadpatri",
      "CityValue": "Tadpatri"
    },
    {
      "id": 449,
      "CityName": "Firozpur",
      "CityValue": "Firozpur"
    },
    {
      "id": 450,
      "CityName": "Shikohabad",
      "CityValue": "Shikohabad"
    },
    {
      "id": 451,
      "CityName": "Basti",
      "CityValue": "Basti"
    },
    {
      "id": 452,
      "CityName": "Deesa",
      "CityValue": "Deesa"
    },
    {
      "id": 453,
      "CityName": "Shamli",
      "CityValue": "Shamli"
    },
    {
      "id": 454,
      "CityName": "Chittaurgarh",
      "CityValue": "Chittaurgarh"
    },
    {
      "id": 455,
      "CityName": "Bally",
      "CityValue": "Bally"
    },
    {
      "id": 456,
      "CityName": "Amreli",
      "CityValue": "Amreli"
    },
    {
      "id": 457,
      "CityName": "Baripada",
      "CityValue": "Baripada"
    },
    {
      "id": 458,
      "CityName": "Ranibennur",
      "CityValue": "Ranibennur"
    },
    {
      "id": 459,
      "CityName": "Champdani",
      "CityValue": "Champdani"
    },
    {
      "id": 460,
      "CityName": "Yavatmal",
      "CityValue": "Yavatmal"
    },
    {
      "id": 461,
      "CityName": "Okha",
      "CityValue": "Okha"
    },
    {
      "id": 462,
      "CityName": "Karaikkudi",
      "CityValue": "Karaikkudi"
    },
    {
      "id": 463,
      "CityName": "Buxar",
      "CityValue": "Buxar"
    },
    {
      "id": 464,
      "CityName": "Port Blair",
      "CityValue": "Port Blair"
    },
    {
      "id": 465,
      "CityName": "Titagarh",
      "CityValue": "Titagarh"
    },
    {
      "id": 466,
      "CityName": "Jagtial",
      "CityValue": "Jagtial"
    },
    {
      "id": 467,
      "CityName": "Achalpur",
      "CityValue": "Achalpur"
    },
    {
      "id": 468,
      "CityName": "Baraut",
      "CityValue": "Baraut"
    },
    {
      "id": 469,
      "CityName": "Dohad",
      "CityValue": "Dohad"
    },
    {
      "id": 470,
      "CityName": "Betul",
      "CityValue": "Betul"
    },
    {
      "id": 471,
      "CityName": "Nagaur",
      "CityValue": "Nagaur"
    },
    {
      "id": 472,
      "CityName": "Suryapet",
      "CityValue": "Suryapet"
    },
    {
      "id": 473,
      "CityName": "Neyveli",
      "CityValue": "Neyveli"
    },
    {
      "id": 474,
      "CityName": "Bagalkot",
      "CityValue": "Bagalkot"
    },
    {
      "id": 475,
      "CityName": "Bongaon",
      "CityValue": "Bongaon"
    },
    {
      "id": 476,
      "CityName": "Sujangarh",
      "CityValue": "Sujangarh"
    },
    {
      "id": 477,
      "CityName": "Miryalaguda",
      "CityValue": "Miryalaguda"
    },
    {
      "id": 478,
      "CityName": "Udgir",
      "CityValue": "Udgir"
    },
    {
      "id": 479,
      "CityName": "Mangalagiri",
      "CityValue": "Mangalagiri"
    },
    {
      "id": 480,
      "CityName": "Kishanganj",
      "CityValue": "Kishanganj"
    },
    {
      "id": 481,
      "CityName": "Datia",
      "CityValue": "Datia"
    },
    {
      "id": 482,
      "CityName": "Gonda",
      "CityValue": "Gonda"
    },
    {
      "id": 483,
      "CityName": "Osmanabad",
      "CityValue": "Osmanabad"
    },
    {
      "id": 484,
      "CityName": "Kanpur",
      "CityValue": "Kanpur"
    },
    {
      "id": 485,
      "CityName": "Aurangabad",
      "CityValue": "Aurangabad"
    },
    {
      "id": 486,
      "CityName": "Mughalsarai",
      "CityValue": "Mughalsarai"
    },
    {
      "id": 487,
      "CityName": "Jalpaiguri",
      "CityValue": "Jalpaiguri"
    },
    {
      "id": 488,
      "CityName": "Kalyani",
      "CityValue": "Kalyani"
    },
    {
      "id": 489,
      "CityName": "Sultanpur",
      "CityValue": "Sultanpur"
    },
    {
      "id": 490,
      "CityName": "Hindaun",
      "CityValue": "Hindaun"
    },
    {
      "id": 491,
      "CityName": "Muradnagar",
      "CityValue": "Muradnagar"
    },
    {
      "id": 492,
      "CityName": "Jamalpur",
      "CityValue": "Jamalpur"
    },
    {
      "id": 493,
      "CityName": "Malappuram",
      "CityValue": "Malappuram"
    },
    {
      "id": 494,
      "CityName": "Seoni",
      "CityValue": "Seoni"
    },
    {
      "id": 495,
      "CityName": "Sanand",
      "CityValue": "Sanand"
    },
    {
      "id": 496,
      "CityName": "Banswara",
      "CityValue": "Banswara"
    },
    {
      "id": 497,
      "CityName": "Nawada",
      "CityValue": "Nawada"
    },
    {
      "id": 498,
      "CityName": "Hinganghat",
      "CityValue": "Hinganghat"
    },
    {
      "id": 499,
      "CityName": "Kohima",
      "CityValue": "Kohima"
    },
    {
      "id": 500,
      "CityName": "Nagapattinam",
      "CityValue": "Nagapattinam"
    },
    {
      "id": 501,
      "CityName": "Birgaon",
      "CityValue": "Birgaon"
    },
    {
      "id": 502,
      "CityName": "Dhamtari",
      "CityValue": "Dhamtari"
    },
    {
      "id": 503,
      "CityName": "Kuniyamuthur",
      "CityValue": "Kuniyamuthur"
    },
    {
      "id": 504,
      "CityName": "Bundi",
      "CityValue": "Bundi"
    },
    {
      "id": 505,
      "CityName": "Yemmiganur",
      "CityValue": "Yemmiganur"
    },
    {
      "id": 506,
      "CityName": "Chilakaluripet",
      "CityValue": "Chilakaluripet"
    },
    {
      "id": 507,
      "CityName": "Maraimalainagar",
      "CityValue": "Maraimalainagar"
    },
    {
      "id": 508,
      "CityName": "Deoband",
      "CityValue": "Deoband"
    },
    {
      "id": 509,
      "CityName": "Kapurthala",
      "CityValue": "Kapurthala"
    },
    {
      "id": 510,
      "CityName": "Wardha",
      "CityValue": "Wardha"
    },
    {
      "id": 511,
      "CityName": "Velampalayam",
      "CityValue": "Velampalayam"
    },
    {
      "id": 512,
      "CityName": "Khambhat",
      "CityValue": "Khambhat"
    },
    {
      "id": 513,
      "CityName": "Dadri",
      "CityValue": "Dadri"
    },
    {
      "id": 514,
      "CityName": "Khardah",
      "CityValue": "Khardah"
    },
    {
      "id": 515,
      "CityName": "Dhar",
      "CityValue": "Dhar"
    },
    {
      "id": 516,
      "CityName": "Balangir",
      "CityValue": "Balangir"
    },
    {
      "id": 517,
      "CityName": "Kasganj",
      "CityValue": "Kasganj"
    },
    {
      "id": 518,
      "CityName": "Tiruchengode",
      "CityValue": "Tiruchengode"
    },
    {
      "id": 519,
      "CityName": "Ballia",
      "CityValue": "Ballia"
    },
    {
      "id": 520,
      "CityName": "Sardarshahar",
      "CityValue": "Sardarshahar"
    },
    {
      "id": 521,
      "CityName": "Mahoba",
      "CityValue": "Mahoba"
    },
    {
      "id": 522,
      "CityName": "Ramanagara",
      "CityValue": "Ramanagara"
    },
    {
      "id": 523,
      "CityName": "Lakhisarai",
      "CityValue": "Lakhisarai"
    },
    {
      "id": 524,
      "CityName": "Manjeri",
      "CityValue": "Manjeri"
    },
    {
      "id": 525,
      "CityName": "Tanda",
      "CityValue": "Tanda"
    },
    {
      "id": 526,
      "CityName": "Jehanabad",
      "CityValue": "Jehanabad"
    },
    {
      "id": 527,
      "CityName": "Dhulian",
      "CityValue": "Dhulian"
    },
    {
      "id": 528,
      "CityName": "Tadepalligudem",
      "CityValue": "Tadepalligudem"
    },
    {
      "id": 529,
      "CityName": "Dod Ballapur",
      "CityValue": "Dod Ballapur"
    },
    {
      "id": 530,
      "CityName": "Ambala Sadar",
      "CityValue": "Ambala Sadar"
    },
    {
      "id": 531,
      "CityName": "Barmer",
      "CityValue": "Barmer"
    },
    {
      "id": 532,
      "CityName": "Rayachoti",
      "CityValue": "Rayachoti"
    },
    {
      "id": 533,
      "CityName": "Bhilai Charoda",
      "CityValue": "Bhilai Charoda"
    },
    {
      "id": 534,
      "CityName": "Mahuva",
      "CityValue": "Mahuva"
    },
    {
      "id": 535,
      "CityName": "Bansberia",
      "CityValue": "Bansberia"
    },
    {
      "id": 536,
      "CityName": "St.Thomas Mount-cum-Pallavaram",
      "CityValue": "St.Thomas Mount-cum-Pallavaram"
    },
    {
      "id": 537,
      "CityName": "Nagda",
      "CityValue": "Nagda"
    },
    {
      "id": 538,
      "CityName": "Pandharpur",
      "CityValue": "Pandharpur"
    },
    {
      "id": 539,
      "CityName": "Maduravoyal",
      "CityValue": "Maduravoyal"
    },
    {
      "id": 540,
      "CityName": "Vaniyambadi",
      "CityValue": "Vaniyambadi"
    },
    {
      "id": 541,
      "CityName": "Paramakudi",
      "CityValue": "Paramakudi"
    },
    {
      "id": 542,
      "CityName": "Makrana",
      "CityValue": "Makrana"
    },
    {
      "id": 543,
      "CityName": "Chalisgaon",
      "CityValue": "Chalisgaon"
    },
    {
      "id": 544,
      "CityName": "Contai",
      "CityValue": "Contai"
    },
    {
      "id": 545,
      "CityName": "Avaniapuram",
      "CityValue": "Avaniapuram"
    },
    {
      "id": 546,
      "CityName": "Bijnor",
      "CityValue": "Bijnor"
    },
    {
      "id": 547,
      "CityName": "Fatehpur",
      "CityValue": "Fatehpur"
    },
    {
      "id": 548,
      "CityName": "Jharsuguda",
      "CityValue": "Jharsuguda"
    },
    {
      "id": 549,
      "CityName": "Anjar",
      "CityValue": "Anjar"
    },
    {
      "id": 550,
      "CityName": "Jhumri Tilaiya",
      "CityValue": "Jhumri Tilaiya"
    },
    {
      "id": 551,
      "CityName": "Itarsi",
      "CityValue": "Itarsi"
    },
    {
      "id": 552,
      "CityName": "Akot",
      "CityValue": "Akot"
    },
    {
      "id": 553,
      "CityName": "Neripperichal",
      "CityValue": "Neripperichal"
    },
    {
      "id": 554,
      "CityName": "Theni Allinagaram",
      "CityValue": "Theni Allinagaram"
    },
    {
      "id": 555,
      "CityName": "Nagina",
      "CityValue": "Nagina"
    },
    {
      "id": 556,
      "CityName": "Oggiyamduraipakkam",
      "CityValue": "Oggiyamduraipakkam"
    },
    {
      "id": 557,
      "CityName": "Kairana",
      "CityValue": "Kairana"
    },
    {
      "id": 558,
      "CityName": "Chakdaha",
      "CityValue": "Chakdaha"
    },
    {
      "id": 559,
      "CityName": "Bhadreswar",
      "CityValue": "Bhadreswar"
    },
    {
      "id": 560,
      "CityName": "Kovilpatti",
      "CityValue": "Kovilpatti"
    },
    {
      "id": 561,
      "CityName": "Kot Kapura",
      "CityValue": "Kot Kapura"
    },
    {
      "id": 562,
      "CityName": "Kadayanallur",
      "CityValue": "Kadayanallur"
    },
    {
      "id": 563,
      "CityName": "Phagwara",
      "CityValue": "Phagwara"
    },
    {
      "id": 564,
      "CityName": "Amalner",
      "CityValue": "Amalner"
    },
    {
      "id": 565,
      "CityName": "Kadiri",
      "CityValue": "Kadiri"
    },
    {
      "id": 566,
      "CityName": "Viluppuram",
      "CityValue": "Viluppuram"
    },
    {
      "id": 567,
      "CityName": "Kavali",
      "CityValue": "Kavali"
    },
    {
      "id": 568,
      "CityName": "Dankuni",
      "CityValue": "Dankuni"
    },
    {
      "id": 569,
      "CityName": "Sadat Pur Gujran",
      "CityValue": "Sadat Pur Gujran"
    },
    {
      "id": 570,
      "CityName": "Udhampur",
      "CityValue": "Udhampur"
    },
    {
      "id": 571,
      "CityName": "Mancherial",
      "CityValue": "Mancherial"
    },
    {
      "id": 572,
      "CityName": "Rajpura",
      "CityValue": "Rajpura"
    },
    {
      "id": 573,
      "CityName": "Chirala",
      "CityValue": "Chirala"
    },
    {
      "id": 574,
      "CityName": "Khamgaon",
      "CityValue": "Khamgaon"
    },
    {
      "id": 575,
      "CityName": "Ramgarh Cantonment",
      "CityValue": "Ramgarh Cantonment"
    },
    {
      "id": 576,
      "CityName": "Jangipur",
      "CityValue": "Jangipur"
    },
    {
      "id": 577,
      "CityName": "Pooth Kalan",
      "CityValue": "Pooth Kalan"
    },
    {
      "id": 578,
      "CityName": "Bhadohi",
      "CityValue": "Bhadohi"
    },
    {
      "id": 579,
      "CityName": "Pilkhuwa",
      "CityValue": "Pilkhuwa"
    },
    {
      "id": 580,
      "CityName": "Anklesvar",
      "CityValue": "Anklesvar"
    },
    {
      "id": 581,
      "CityName": "Bhandara",
      "CityValue": "Bhandara"
    },
    {
      "id": 582,
      "CityName": "Hingoli",
      "CityValue": "Hingoli"
    },
    {
      "id": 583,
      "CityName": "Karaikal",
      "CityValue": "Karaikal"
    },
    {
      "id": 584,
      "CityName": "Shrirampur",
      "CityValue": "Shrirampur"
    },
    {
      "id": 585,
      "CityName": "Nirmal",
      "CityValue": "Nirmal"
    },
    {
      "id": 586,
      "CityName": "Sangrur",
      "CityValue": "Sangrur"
    },
    {
      "id": 587,
      "CityName": "Najibabad",
      "CityValue": "Najibabad"
    },
    {
      "id": 588,
      "CityName": "Goundampalayam",
      "CityValue": "Goundampalayam"
    },
    {
      "id": 589,
      "CityName": "Meerut",
      "CityValue": "Meerut"
    },
    {
      "id": 590,
      "CityName": "Sircilla",
      "CityValue": "Sircilla"
    },
    {
      "id": 591,
      "CityName": "Mormugao",
      "CityValue": "Mormugao"
    },
    {
      "id": 592,
      "CityName": "Hansi",
      "CityValue": "Hansi"
    },
    {
      "id": 593,
      "CityName": "Karauli",
      "CityValue": "Karauli"
    },
    {
      "id": 594,
      "CityName": "Kharar",
      "CityValue": "Kharar"
    },
    {
      "id": 595,
      "CityName": "Gharoli",
      "CityValue": "Gharoli"
    },
    {
      "id": 596,
      "CityName": "Kolar",
      "CityValue": "Kolar"
    },
    {
      "id": 597,
      "CityName": "Kannauj",
      "CityValue": "Kannauj"
    },
    {
      "id": 598,
      "CityName": "Faridkot",
      "CityValue": "Faridkot"
    },
    {
      "id": 599,
      "CityName": "Sahibganj",
      "CityValue": "Sahibganj"
    },
    {
      "id": 600,
      "CityName": "Gudiyatham",
      "CityValue": "Gudiyatham"
    },
    {
      "id": 601,
      "CityName": "Pollachi",
      "CityValue": "Pollachi"
    },
    {
      "id": 602,
      "CityName": "Margao",
      "CityValue": "Margao"
    },
    {
      "id": 603,
      "CityName": "Parli",
      "CityValue": "Parli"
    },
    {
      "id": 604,
      "CityName": "Ponnani",
      "CityValue": "Ponnani"
    },
    {
      "id": 605,
      "CityName": "Chandpur",
      "CityValue": "Chandpur"
    },
    {
      "id": 606,
      "CityName": "Jamui",
      "CityValue": "Jamui"
    },
    {
      "id": 607,
      "CityName": "Phusro",
      "CityValue": "Phusro"
    },
    {
      "id": 608,
      "CityName": "Gangaghat",
      "CityValue": "Gangaghat"
    },
    {
      "id": 609,
      "CityName": "Auraiya",
      "CityValue": "Auraiya"
    },
    {
      "id": 610,
      "CityName": "Molar Band",
      "CityValue": "Molar Band"
    },
    {
      "id": 611,
      "CityName": "Veerappanchatiram",
      "CityValue": "Veerappanchatiram"
    },
    {
      "id": 612,
      "CityName": "Dholka",
      "CityValue": "Dholka"
    },
    {
      "id": 613,
      "CityName": "Kadi",
      "CityValue": "Kadi"
    },
    {
      "id": 614,
      "CityName": "Araria",
      "CityValue": "Araria"
    },
    {
      "id": 615,
      "CityName": "Dausa",
      "CityValue": "Dausa"
    },
    {
      "id": 616,
      "CityName": "Udhagamandalam",
      "CityValue": "Udhagamandalam"
    },
    {
      "id": 617,
      "CityName": "Bargarh",
      "CityValue": "Bargarh"
    },
    {
      "id": 618,
      "CityName": "Phulwari Sharif",
      "CityValue": "Phulwari Sharif"
    },
    {
      "id": 619,
      "CityName": "Shahdol",
      "CityValue": "Shahdol"
    },
    {
      "id": 620,
      "CityName": "Old Malda",
      "CityValue": "Old Malda"
    },
    {
      "id": 621,
      "CityName": "Aruppukkottai",
      "CityValue": "Aruppukkottai"
    },
    {
      "id": 622,
      "CityName": "Kamptee",
      "CityValue": "Kamptee"
    },
    {
      "id": 623,
      "CityName": "Faridpur",
      "CityValue": "Faridpur"
    },
    {
      "id": 624,
      "CityName": "Panipat Taraf Makhdum Zadgan",
      "CityValue": "Panipat Taraf Makhdum Zadgan"
    },
    {
      "id": 625,
      "CityName": "Kamareddy",
      "CityValue": "Kamareddy"
    },
    {
      "id": 626,
      "CityName": "Garulia",
      "CityValue": "Garulia"
    },
    {
      "id": 627,
      "CityName": "Thalassery",
      "CityValue": "Thalassery"
    },
    {
      "id": 628,
      "CityName": "Balaghat",
      "CityValue": "Balaghat"
    },
    {
      "id": 629,
      "CityName": "Mansa",
      "CityValue": "Mansa"
    },
    {
      "id": 630,
      "CityName": "Harihar",
      "CityValue": "Harihar"
    },
    {
      "id": 631,
      "CityName": "Ballarpur",
      "CityValue": "Ballarpur"
    },
    {
      "id": 632,
      "CityName": "Mawana",
      "CityValue": "Mawana"
    },
    {
      "id": 633,
      "CityName": "Jeypur",
      "CityValue": "Jeypur"
    },
    {
      "id": 634,
      "CityName": "Bolpur",
      "CityValue": "Bolpur"
    },
    {
      "id": 635,
      "CityName": "Vijalpor",
      "CityValue": "Vijalpor"
    },
    {
      "id": 636,
      "CityName": "Dhoraji",
      "CityValue": "Dhoraji"
    },
    {
      "id": 637,
      "CityName": "Malout",
      "CityValue": "Malout"
    },
    {
      "id": 638,
      "CityName": "Balrampur",
      "CityValue": "Balrampur"
    },
    {
      "id": 639,
      "CityName": "Basoda",
      "CityValue": "Basoda"
    },
    {
      "id": 640,
      "CityName": "Sikandrabad",
      "CityValue": "Sikandrabad"
    },
    {
      "id": 641,
      "CityName": "Washim",
      "CityValue": "Washim"
    },
    {
      "id": 642,
      "CityName": "Shahabad",
      "CityValue": "Shahabad"
    },
    {
      "id": 643,
      "CityName": "Shirpur-Warwade",
      "CityValue": "Shirpur-Warwade"
    },
    {
      "id": 644,
      "CityName": "Gobindgarh",
      "CityValue": "Gobindgarh"
    },
    {
      "id": 645,
      "CityName": "Srikalahasti",
      "CityValue": "Srikalahasti"
    },
    {
      "id": 646,
      "CityName": "Ashoknagar",
      "CityValue": "Ashoknagar"
    },
    {
      "id": 647,
      "CityName": "Himatnagar",
      "CityValue": "Himatnagar"
    },
    {
      "id": 648,
      "CityName": "Gudur",
      "CityValue": "Gudur"
    },
    {
      "id": 649,
      "CityName": "Anakapalle",
      "CityValue": "Anakapalle"
    },
    {
      "id": 650,
      "CityName": "Palwancha",
      "CityValue": "Palwancha"
    },
    {
      "id": 651,
      "CityName": "Katwa",
      "CityValue": "Katwa"
    },
    {
      "id": 652,
      "CityName": "Mayiladuthurai",
      "CityValue": "Mayiladuthurai"
    },
    {
      "id": 653,
      "CityName": "Gokak",
      "CityValue": "Gokak"
    },
    {
      "id": 654,
      "CityName": "Boduppal",
      "CityValue": "Boduppal"
    },
    {
      "id": 655,
      "CityName": "Manmad",
      "CityValue": "Manmad"
    },
    {
      "id": 656,
      "CityName": "Tura",
      "CityValue": "Tura"
    },
    {
      "id": 657,
      "CityName": "Tikamgarh",
      "CityValue": "Tikamgarh"
    },
    {
      "id": 658,
      "CityName": "Nawabganj",
      "CityValue": "Nawabganj"
    },
    {
      "id": 659,
      "CityName": "S.Nallur",
      "CityValue": "S.Nallur"
    },
    {
      "id": 660,
      "CityName": "Kharghar",
      "CityValue": "Kharghar"
    },
    {
      "id": 661,
      "CityName": "Bagbera",
      "CityValue": "Bagbera"
    },
    {
      "id": 662,
      "CityName": "Sindhnur",
      "CityValue": "Sindhnur"
    },
    {
      "id": 663,
      "CityName": "Yadgir",
      "CityValue": "Yadgir"
    },
    {
      "id": 664,
      "CityName": "Chirmiri",
      "CityValue": "Chirmiri"
    },
    {
      "id": 665,
      "CityName": "Sopore",
      "CityValue": "Sopore"
    },
    {
      "id": 666,
      "CityName": "Wadhwan",
      "CityValue": "Wadhwan"
    },
    {
      "id": 667,
      "CityName": "Gurdaspur",
      "CityValue": "Gurdaspur"
    },
    {
      "id": 668,
      "CityName": "Chilla Saroda Bangar",
      "CityValue": "Chilla Saroda Bangar"
    },
    {
      "id": 669,
      "CityName": "Medininagar",
      "CityValue": "Medininagar"
    },
    {
      "id": 670,
      "CityName": "Keshod",
      "CityValue": "Keshod"
    },
    {
      "id": 671,
      "CityName": "Edathala",
      "CityValue": "Edathala"
    },
    {
      "id": 672,
      "CityName": "Tanuku",
      "CityValue": "Tanuku"
    },
    {
      "id": 673,
      "CityName": "Narnaul",
      "CityValue": "Narnaul"
    },
    {
      "id": 674,
      "CityName": "Chintamani",
      "CityValue": "Chintamani"
    },
    {
      "id": 675,
      "CityName": "Balotra",
      "CityValue": "Balotra"
    },
    {
      "id": 676,
      "CityName": "Brajarajnagar",
      "CityValue": "Brajarajnagar"
    },
    {
      "id": 677,
      "CityName": "Zahirabad",
      "CityValue": "Zahirabad"
    },
    {
      "id": 678,
      "CityName": "Virudhachalam",
      "CityValue": "Virudhachalam"
    },
    {
      "id": 679,
      "CityName": "Kharagpur Rly. Settlement",
      "CityValue": "Kharagpur Rly. Settlement"
    },
    {
      "id": 680,
      "CityName": "Sangareddy",
      "CityValue": "Sangareddy"
    },
    {
      "id": 681,
      "CityName": "Benipur",
      "CityValue": "Benipur"
    },
    {
      "id": 682,
      "CityName": "Sarni",
      "CityValue": "Sarni"
    },
    {
      "id": 683,
      "CityName": "Palacole",
      "CityValue": "Palacole"
    },
    {
      "id": 684,
      "CityName": "Mhow Cantt",
      "CityValue": "Mhow Cantt"
    },
    {
      "id": 685,
      "CityName": "Kirkee",
      "CityValue": "Kirkee"
    },
    {
      "id": 686,
      "CityName": "Fazilka",
      "CityValue": "Fazilka"
    },
    {
      "id": 687,
      "CityName": "Khatauli",
      "CityValue": "Khatauli"
    },
    {
      "id": 688,
      "CityName": "Shajapur",
      "CityValue": "Shajapur"
    },
    {
      "id": 689,
      "CityName": "Bisalpur",
      "CityValue": "Bisalpur"
    },
    {
      "id": 690,
      "CityName": "Madhubani",
      "CityValue": "Madhubani"
    },
    {
      "id": 691,
      "CityName": "Bodhan",
      "CityValue": "Bodhan"
    },
    {
      "id": 692,
      "CityName": "Rabkavi Banhatti",
      "CityValue": "Rabkavi Banhatti"
    },
    {
      "id": 693,
      "CityName": "Sunam Udham Singh Wala",
      "CityValue": "Sunam Udham Singh Wala"
    },
    {
      "id": 694,
      "CityName": "Savarkundla",
      "CityValue": "Savarkundla"
    },
    {
      "id": 695,
      "CityName": "Harda",
      "CityValue": "Harda"
    },
    {
      "id": 696,
      "CityName": "Kothagudem",
      "CityValue": "Kothagudem"
    },
    {
      "id": 697,
      "CityName": "Saunda",
      "CityValue": "Saunda"
    },
    {
      "id": 698,
      "CityName": "Koppal",
      "CityValue": "Koppal"
    },
    {
      "id": 699,
      "CityName": "Ratnagiri",
      "CityValue": "Ratnagiri"
    },
    {
      "id": 700,
      "CityName": "Palghar",
      "CityValue": "Palghar"
    },
    {
      "id": 701,
      "CityName": "Chopda",
      "CityValue": "Chopda"
    },
    {
      "id": 702,
      "CityName": "Arakonam",
      "CityValue": "Arakonam"
    },
    {
      "id": 703,
      "CityName": "Jhalawar",
      "CityValue": "Jhalawar"
    },
    {
      "id": 704,
      "CityName": "Jaora",
      "CityValue": "Jaora"
    },
    {
      "id": 705,
      "CityName": "Markapur",
      "CityValue": "Markapur"
    },
    {
      "id": 706,
      "CityName": "Rayagada",
      "CityValue": "Rayagada"
    },
    {
      "id": 707,
      "CityName": "Khopoli",
      "CityValue": "Khopoli"
    },
    {
      "id": 708,
      "CityName": "Gohana",
      "CityValue": "Gohana"
    },
    {
      "id": 709,
      "CityName": "Rishikesh",
      "CityValue": "Rishikesh"
    },
    {
      "id": 710,
      "CityName": "Ranaghat",
      "CityValue": "Ranaghat"
    },
    {
      "id": 711,
      "CityName": "Karwar",
      "CityValue": "Karwar"
    },
    {
      "id": 712,
      "CityName": "Mubarakpur",
      "CityValue": "Mubarakpur"
    },
    {
      "id": 713,
      "CityName": "Fatehabad",
      "CityValue": "Fatehabad"
    },
    {
      "id": 714,
      "CityName": "Bela Pratapgarh",
      "CityValue": "Bela Pratapgarh"
    },
    {
      "id": 715,
      "CityName": "Tamluk",
      "CityValue": "Tamluk"
    },
    {
      "id": 716,
      "CityName": "Koch Bihar",
      "CityValue": "Koch Bihar"
    },
    {
      "id": 717,
      "CityName": "Konnagar",
      "CityValue": "Konnagar"
    },
    {
      "id": 718,
      "CityName": "Budge Budge",
      "CityValue": "Budge Budge"
    },
    {
      "id": 719,
      "CityName": "Dhrangadhra",
      "CityValue": "Dhrangadhra"
    },
    {
      "id": 720,
      "CityName": "Pattukkottai",
      "CityValue": "Pattukkottai"
    },
    {
      "id": 721,
      "CityName": "Bodinayakanur",
      "CityValue": "Bodinayakanur"
    },
    {
      "id": 722,
      "CityName": "Sheikhpura",
      "CityValue": "Sheikhpura"
    },
    {
      "id": 723,
      "CityName": "Visnagar",
      "CityValue": "Visnagar"
    },
    {
      "id": 724,
      "CityName": "Khajoori Khas",
      "CityValue": "Khajoori Khas"
    },
    {
      "id": 725,
      "CityName": "Pusad",
      "CityValue": "Pusad"
    },
    {
      "id": 726,
      "CityName": "Kasipalayam",
      "CityValue": "Kasipalayam"
    },
    {
      "id": 727,
      "CityName": "Channapatna",
      "CityValue": "Channapatna"
    },
    {
      "id": 728,
      "CityName": "Suratgarh",
      "CityValue": "Suratgarh"
    },
    {
      "id": 729,
      "CityName": "Srivilliputhur",
      "CityValue": "Srivilliputhur"
    },
    {
      "id": 730,
      "CityName": "Tiruverkadu",
      "CityValue": "Tiruverkadu"
    },
    {
      "id": 731,
      "CityName": "Tindivanam",
      "CityValue": "Tindivanam"
    },
    {
      "id": 732,
      "CityName": "Jabalpur Cantt",
      "CityValue": "Jabalpur Cantt"
    },
    {
      "id": 733,
      "CityName": "Ambejogai",
      "CityValue": "Ambejogai"
    },
    {
      "id": 734,
      "CityName": "Modasa",
      "CityValue": "Modasa"
    },
    {
      "id": 735,
      "CityName": "Valparai",
      "CityValue": "Valparai"
    },
    {
      "id": 736,
      "CityName": "Krishnagiri",
      "CityValue": "Krishnagiri"
    },
    {
      "id": 737,
      "CityName": "Basmath",
      "CityValue": "Basmath"
    },
    {
      "id": 738,
      "CityName": "Basavakalyan",
      "CityValue": "Basavakalyan"
    },
    {
      "id": 739,
      "CityName": "Bangarda Chhota",
      "CityValue": "Bangarda Chhota"
    },
    {
      "id": 740,
      "CityName": "Ratangarh",
      "CityValue": "Ratangarh"
    },
    {
      "id": 741,
      "CityName": "Tadepalle",
      "CityValue": "Tadepalle"
    },
    {
      "id": 742,
      "CityName": "Sheopur",
      "CityValue": "Sheopur"
    },
    {
      "id": 743,
      "CityName": "Jamkhandi",
      "CityValue": "Jamkhandi"
    },
    {
      "id": 744,
      "CityName": "Kumarapalayam",
      "CityValue": "Kumarapalayam"
    },
    {
      "id": 745,
      "CityName": "Chamarajanagar",
      "CityValue": "Chamarajanagar"
    },
    {
      "id": 746,
      "CityName": "Barbil",
      "CityValue": "Barbil"
    },
    {
      "id": 747,
      "CityName": "Sitamarhi",
      "CityValue": "Sitamarhi"
    },
    {
      "id": 748,
      "CityName": "Panaji",
      "CityValue": "Panaji"
    },
    {
      "id": 749,
      "CityName": "Nerkunram",
      "CityValue": "Nerkunram"
    },
    {
      "id": 750,
      "CityName": "Kalamassery",
      "CityValue": "Kalamassery"
    },
    {
      "id": 751,
      "CityName": "Baheri",
      "CityValue": "Baheri"
    },
    {
      "id": 752,
      "CityName": "Gopalganj",
      "CityValue": "Gopalganj"
    },
    {
      "id": 753,
      "CityName": "Vadakara",
      "CityValue": "Vadakara"
    },
    {
      "id": 754,
      "CityName": "Rajsamand",
      "CityValue": "Rajsamand"
    },
    {
      "id": 755,
      "CityName": "Taliparamba",
      "CityValue": "Taliparamba"
    },
    {
      "id": 756,
      "CityName": "Baramula",
      "CityValue": "Baramula"
    },
    {
      "id": 757,
      "CityName": "New Barrackpore",
      "CityValue": "New Barrackpore"
    },
    {
      "id": 758,
      "CityName": "Kapas Hera",
      "CityValue": "Kapas Hera"
    },
    {
      "id": 759,
      "CityName": "Tenkasi",
      "CityValue": "Tenkasi"
    },
    {
      "id": 760,
      "CityName": "Tarn Taran",
      "CityValue": "Tarn Taran"
    },
    {
      "id": 761,
      "CityName": "Mandideep",
      "CityValue": "Mandideep"
    },
    {
      "id": 762,
      "CityName": "Bawana",
      "CityValue": "Bawana"
    },
    {
      "id": 763,
      "CityName": "Thrippunithura",
      "CityValue": "Thrippunithura"
    },
    {
      "id": 764,
      "CityName": "Bankra",
      "CityValue": "Bankra"
    },
    {
      "id": 765,
      "CityName": "Lonavala",
      "CityValue": "Lonavala"
    },
    {
      "id": 766,
      "CityName": "Koratla",
      "CityValue": "Koratla"
    },
    {
      "id": 767,
      "CityName": "Tandur",
      "CityValue": "Tandur"
    },
    {
      "id": 768,
      "CityName": "Barauni",
      "CityValue": "Barauni"
    },
    {
      "id": 769,
      "CityName": "Bhawanipatna",
      "CityValue": "Bhawanipatna"
    },
    {
      "id": 770,
      "CityName": "Haveri",
      "CityValue": "Haveri"
    },
    {
      "id": 771,
      "CityName": "Kambam",
      "CityValue": "Kambam"
    },
    {
      "id": 772,
      "CityName": "Lakkiampatti",
      "CityValue": "Lakkiampatti"
    },
    {
      "id": 773,
      "CityName": "Chomu",
      "CityValue": "Chomu"
    },
    {
      "id": 774,
      "CityName": "Payyannur",
      "CityValue": "Payyannur"
    },
    {
      "id": 775,
      "CityName": "Bina- Etawa",
      "CityValue": "Bina- Etawa"
    },
    {
      "id": 776,
      "CityName": "Quilandy",
      "CityValue": "Quilandy"
    },
    {
      "id": 777,
      "CityName": "Chaibasa",
      "CityValue": "Chaibasa"
    },
    {
      "id": 778,
      "CityName": "Sinnar",
      "CityValue": "Sinnar"
    },
    {
      "id": 779,
      "CityName": "Wadgaon Kolhati",
      "CityValue": "Wadgaon Kolhati"
    },
    {
      "id": 780,
      "CityName": "Dhenkanal",
      "CityValue": "Dhenkanal"
    },
    {
      "id": 781,
      "CityName": "Virudhunagar",
      "CityValue": "Virudhunagar"
    },
    {
      "id": 782,
      "CityName": "Dadhel",
      "CityValue": "Dadhel"
    },
    {
      "id": 783,
      "CityName": "Karanja",
      "CityValue": "Karanja"
    },
    {
      "id": 784,
      "CityName": "Tohana",
      "CityValue": "Tohana"
    },
    {
      "id": 785,
      "CityName": "Supaul",
      "CityValue": "Supaul"
    },
    {
      "id": 786,
      "CityName": "Bapatla",
      "CityValue": "Bapatla"
    },
    {
      "id": 787,
      "CityName": "Mangrol",
      "CityValue": "Mangrol"
    },
    {
      "id": 788,
      "CityName": "Nagari",
      "CityValue": "Nagari"
    },
    {
      "id": 789,
      "CityName": "Uran Islampur",
      "CityValue": "Uran Islampur"
    },
    {
      "id": 790,
      "CityName": "Palani",
      "CityValue": "Palani"
    },
    {
      "id": 791,
      "CityName": "Kodad",
      "CityValue": "Kodad"
    },
    {
      "id": 792,
      "CityName": "Palitana",
      "CityValue": "Palitana"
    },
    {
      "id": 793,
      "CityName": "Halol",
      "CityValue": "Halol"
    },
    {
      "id": 794,
      "CityName": "Malkapur",
      "CityValue": "Malkapur"
    },
    {
      "id": 795,
      "CityName": "Mettupalayam",
      "CityValue": "Mettupalayam"
    },
    {
      "id": 796,
      "CityName": "Villianur",
      "CityValue": "Villianur"
    },
    {
      "id": 797,
      "CityName": "Arambag",
      "CityValue": "Arambag"
    },
    {
      "id": 798,
      "CityName": "Kuri Bhagtasani",
      "CityValue": "Kuri Bhagtasani"
    },
    {
      "id": 799,
      "CityName": "Nabha",
      "CityValue": "Nabha"
    },
    {
      "id": 800,
      "CityName": "Poonamallee",
      "CityValue": "Poonamallee"
    },
    {
      "id": 801,
      "CityName": "Dharmapuri",
      "CityValue": "Dharmapuri"
    },
    {
      "id": 802,
      "CityName": "Kundrathur",
      "CityValue": "Kundrathur"
    },
    {
      "id": 803,
      "CityName": "Anaiyur",
      "CityValue": "Anaiyur"
    },
    {
      "id": 804,
      "CityName": "Suri",
      "CityValue": "Suri"
    },
    {
      "id": 805,
      "CityName": "Sahaswan",
      "CityValue": "Sahaswan"
    },
    {
      "id": 806,
      "CityName": "Samastipur",
      "CityValue": "Samastipur"
    },
    {
      "id": 807,
      "CityName": "Neyyattinkara",
      "CityValue": "Neyyattinkara"
    },
    {
      "id": 808,
      "CityName": "Beypore",
      "CityValue": "Beypore"
    },
    {
      "id": 809,
      "CityName": "Piduguralla",
      "CityValue": "Piduguralla"
    },
    {
      "id": 810,
      "CityName": "Badvel",
      "CityValue": "Badvel"
    },
    {
      "id": 811,
      "CityName": "Gadwal",
      "CityValue": "Gadwal"
    },
    {
      "id": 812,
      "CityName": "Bishnupur",
      "CityValue": "Bishnupur"
    },
    {
      "id": 813,
      "CityName": "Sivakasi",
      "CityValue": "Sivakasi"
    },
    {
      "id": 814,
      "CityName": "Raghogarh -Vijaypur",
      "CityValue": "Raghogarh -Vijaypur"
    },
    {
      "id": 815,
      "CityName": "Bongaigaon",
      "CityValue": "Bongaigaon"
    },
    {
      "id": 816,
      "CityName": "Ladnu",
      "CityValue": "Ladnu"
    },
    {
      "id": 817,
      "CityName": "Nokha",
      "CityValue": "Nokha"
    },
    {
      "id": 818,
      "CityName": "Bari",
      "CityValue": "Bari"
    },
    {
      "id": 819,
      "CityName": "Inam Karur",
      "CityValue": "Inam Karur"
    },
    {
      "id": 820,
      "CityName": "Jaisalmer",
      "CityValue": "Jaisalmer"
    },
    {
      "id": 821,
      "CityName": "Buldana",
      "CityValue": "Buldana"
    },
    {
      "id": 822,
      "CityName": "Mithe Pur",
      "CityValue": "Mithe Pur"
    },
    {
      "id": 823,
      "CityName": "Jagraon",
      "CityValue": "Jagraon"
    },
    {
      "id": 824,
      "CityName": "Rath",
      "CityValue": "Rath"
    },
    {
      "id": 825,
      "CityName": "Barh",
      "CityValue": "Barh"
    },
    {
      "id": 826,
      "CityName": "Pul Pehlad",
      "CityValue": "Pul Pehlad"
    },
    {
      "id": 827,
      "CityName": "Narwana",
      "CityValue": "Narwana"
    },
    {
      "id": 828,
      "CityName": "Mannargudi",
      "CityValue": "Mannargudi"
    },
    {
      "id": 829,
      "CityName": "Laharpur",
      "CityValue": "Laharpur"
    },
    {
      "id": 830,
      "CityName": "Masaurhi",
      "CityValue": "Masaurhi"
    },
    {
      "id": 831,
      "CityName": "Pammal",
      "CityValue": "Pammal"
    },
    {
      "id": 832,
      "CityName": "Ziauddin Pur",
      "CityValue": "Ziauddin Pur"
    },
    {
      "id": 833,
      "CityName": "Jatani",
      "CityValue": "Jatani"
    },
    {
      "id": 834,
      "CityName": "Puliankudi",
      "CityValue": "Puliankudi"
    },
    {
      "id": 835,
      "CityName": "Byasanagar",
      "CityValue": "Byasanagar"
    },
    {
      "id": 836,
      "CityName": "Siddipet",
      "CityValue": "Siddipet"
    },
    {
      "id": 837,
      "CityName": "Chikkaballapura",
      "CityValue": "Chikkaballapura"
    },
    {
      "id": 838,
      "CityName": "Taj Pul",
      "CityValue": "Taj Pul"
    },
    {
      "id": 839,
      "CityName": "Kathua",
      "CityValue": "Kathua"
    },
    {
      "id": 840,
      "CityName": "Karur",
      "CityValue": "Karur"
    },
    {
      "id": 841,
      "CityName": "Kopargaon",
      "CityValue": "Kopargaon"
    },
    {
      "id": 842,
      "CityName": "Shahade",
      "CityValue": "Shahade"
    },
    {
      "id": 843,
      "CityName": "Puzhithivakkam",
      "CityValue": "Puzhithivakkam"
    },
    {
      "id": 844,
      "CityName": "Chakardharpur",
      "CityValue": "Chakardharpur"
    },
    {
      "id": 845,
      "CityName": "Kuchaman City",
      "CityValue": "Kuchaman City"
    },
    {
      "id": 846,
      "CityName": "Ujhani",
      "CityValue": "Ujhani"
    },
    {
      "id": 847,
      "CityName": "Bihat",
      "CityValue": "Bihat"
    },
    {
      "id": 848,
      "CityName": "Pachora",
      "CityValue": "Pachora"
    },
    {
      "id": 849,
      "CityName": "Sangamner",
      "CityValue": "Sangamner"
    },
    {
      "id": 850,
      "CityName": "Pulivendla",
      "CityValue": "Pulivendla"
    },
    {
      "id": 851,
      "CityName": "Kayamkulam",
      "CityValue": "Kayamkulam"
    },
    {
      "id": 852,
      "CityName": "Bardoli",
      "CityValue": "Bardoli"
    },
    {
      "id": 853,
      "CityName": "Sillod",
      "CityValue": "Sillod"
    },
    {
      "id": 854,
      "CityName": "Vrindavan",
      "CityValue": "Vrindavan"
    },
    {
      "id": 855,
      "CityName": "Diphu",
      "CityValue": "Diphu"
    },
    {
      "id": 856,
      "CityName": "Narsimhapur",
      "CityValue": "Narsimhapur"
    },
    {
      "id": 857,
      "CityName": "Nawalgarh",
      "CityValue": "Nawalgarh"
    },
    {
      "id": 858,
      "CityName": "Gohad",
      "CityValue": "Gohad"
    },
    {
      "id": 859,
      "CityName": "Wanaparthy",
      "CityValue": "Wanaparthy"
    },
    {
      "id": 860,
      "CityName": "Bopal",
      "CityValue": "Bopal"
    },
    {
      "id": 861,
      "CityName": "Sherkot",
      "CityValue": "Sherkot"
    },
    {
      "id": 862,
      "CityName": "Chhibramau",
      "CityValue": "Chhibramau"
    },
    {
      "id": 863,
      "CityName": "Borsad",
      "CityValue": "Borsad"
    },
    {
      "id": 864,
      "CityName": "Kosi Kalan",
      "CityValue": "Kosi Kalan"
    },
    {
      "id": 865,
      "CityName": "Hosakote",
      "CityValue": "Hosakote"
    },
    {
      "id": 866,
      "CityName": "Rayadurg",
      "CityValue": "Rayadurg"
    },
    {
      "id": 867,
      "CityName": "Kharia",
      "CityValue": "Kharia"
    },
    {
      "id": 868,
      "CityName": "Tezpur",
      "CityValue": "Tezpur"
    },
    {
      "id": 869,
      "CityName": "Mauranipur",
      "CityValue": "Mauranipur"
    },
    {
      "id": 870,
      "CityName": "Tirupathur",
      "CityValue": "Tirupathur"
    },
    {
      "id": 871,
      "CityName": "Vinukonda",
      "CityValue": "Vinukonda"
    },
    {
      "id": 872,
      "CityName": "Nimbahera",
      "CityValue": "Nimbahera"
    },
    {
      "id": 873,
      "CityName": "Tilhar",
      "CityValue": "Tilhar"
    },
    {
      "id": 874,
      "CityName": "Talegaon Dabhade",
      "CityValue": "Talegaon Dabhade"
    },
    {
      "id": 875,
      "CityName": "Hasanpur",
      "CityValue": "Hasanpur"
    },
    {
      "id": 876,
      "CityName": "Mawlai",
      "CityValue": "Mawlai"
    },
    {
      "id": 877,
      "CityName": "Jhargram",
      "CityValue": "Jhargram"
    },
    {
      "id": 878,
      "CityName": "Paradip",
      "CityValue": "Paradip"
    },
    {
      "id": 879,
      "CityName": "Rajgarh",
      "CityValue": "Rajgarh"
    },
    {
      "id": 880,
      "CityName": "Yanam",
      "CityValue": "Yanam"
    },
    {
      "id": 881,
      "CityName": "Chengalpattu",
      "CityValue": "Chengalpattu"
    },
    {
      "id": 882,
      "CityName": "Dhone",
      "CityValue": "Dhone"
    },
    {
      "id": 883,
      "CityName": "Mulbagal",
      "CityValue": "Mulbagal"
    },
    {
      "id": 884,
      "CityName": "Arani",
      "CityValue": "Arani"
    },
    {
      "id": 885,
      "CityName": "Kendujhar",
      "CityValue": "Kendujhar"
    },
    {
      "id": 886,
      "CityName": "Nipani",
      "CityValue": "Nipani"
    },
    {
      "id": 887,
      "CityName": "Kiratpur",
      "CityValue": "Kiratpur"
    },
    {
      "id": 888,
      "CityName": "Sirsi",
      "CityValue": "Sirsi"
    },
    {
      "id": 889,
      "CityName": "Khurai",
      "CityValue": "Khurai"
    },
    {
      "id": 890,
      "CityName": "Chidambaram",
      "CityValue": "Chidambaram"
    },
    {
      "id": 891,
      "CityName": "Armur",
      "CityValue": "Armur"
    },
    {
      "id": 892,
      "CityName": "Gajraula",
      "CityValue": "Gajraula"
    },
    {
      "id": 893,
      "CityName": "Lucknow",
      "CityValue": "Lucknow"
    },
    {
      "id": 894,
      "CityName": "Jahangirabad",
      "CityValue": "Jahangirabad"
    },
    {
      "id": 895,
      "CityName": "Ilkal",
      "CityValue": "Ilkal"
    },
    {
      "id": 896,
      "CityName": "Naya Gaon",
      "CityValue": "Naya Gaon"
    },
    {
      "id": 897,
      "CityName": "Binnaguri",
      "CityValue": "Binnaguri"
    },
    {
      "id": 898,
      "CityName": "Raxaul Bazar",
      "CityValue": "Raxaul Bazar"
    },
    {
      "id": 899,
      "CityName": "Sathuvachari",
      "CityValue": "Sathuvachari"
    },
    {
      "id": 900,
      "CityName": "Lohardaga",
      "CityValue": "Lohardaga"
    },
    {
      "id": 901,
      "CityName": "Dabra",
      "CityValue": "Dabra"
    },
    {
      "id": 902,
      "CityName": "Sardhana",
      "CityValue": "Sardhana"
    },
    {
      "id": 903,
      "CityName": "Jawaharnagar",
      "CityValue": "Jawaharnagar"
    },
    {
      "id": 904,
      "CityName": "Attur",
      "CityValue": "Attur"
    },
    {
      "id": 905,
      "CityName": "Shegaon",
      "CityValue": "Shegaon"
    },
    {
      "id": 906,
      "CityName": "Gola Gokaran Nath",
      "CityValue": "Gola Gokaran Nath"
    },
    {
      "id": 907,
      "CityName": "Udumalaipettai",
      "CityValue": "Udumalaipettai"
    },
    {
      "id": 908,
      "CityName": "Mokameh",
      "CityValue": "Mokameh"
    },
    {
      "id": 909,
      "CityName": "Cheruvannur",
      "CityValue": "Cheruvannur"
    },
    {
      "id": 910,
      "CityName": "Sandila",
      "CityValue": "Sandila"
    },
    {
      "id": 911,
      "CityName": "Panruti",
      "CityValue": "Panruti"
    },
    {
      "id": 912,
      "CityName": "Charkhi Dadri",
      "CityValue": "Charkhi Dadri"
    },
    {
      "id": 913,
      "CityName": "Panna",
      "CityValue": "Panna"
    },
    {
      "id": 914,
      "CityName": "Thiruvallur",
      "CityValue": "Thiruvallur"
    },
    {
      "id": 915,
      "CityName": "Nuzvid",
      "CityValue": "Nuzvid"
    },
    {
      "id": 916,
      "CityName": "Chikhli",
      "CityValue": "Chikhli"
    },
    {
      "id": 917,
      "CityName": "Itanagar",
      "CityValue": "Itanagar"
    },
    {
      "id": 918,
      "CityName": "Lunglei",
      "CityValue": "Lunglei"
    },
    {
      "id": 919,
      "CityName": "Kallakkurichi",
      "CityValue": "Kallakkurichi"
    },
    {
      "id": 920,
      "CityName": "Pithoragarh",
      "CityValue": "Pithoragarh"
    },
    {
      "id": 921,
      "CityName": "Wadi",
      "CityValue": "Wadi"
    },
    {
      "id": 922,
      "CityName": "Shahpur",
      "CityValue": "Shahpur"
    },
    {
      "id": 923,
      "CityName": "Vicarabad",
      "CityValue": "Vicarabad"
    },
    {
      "id": 924,
      "CityName": "Puttur",
      "CityValue": "Puttur"
    },
    {
      "id": 925,
      "CityName": "Palamaner",
      "CityValue": "Palamaner"
    },
    {
      "id": 926,
      "CityName": "Barwani",
      "CityValue": "Barwani"
    },
    {
      "id": 927,
      "CityName": "Ramnagar",
      "CityValue": "Ramnagar"
    },
    {
      "id": 928,
      "CityName": "Pune",
      "CityValue": "Pune"
    },
    {
      "id": 929,
      "CityName": "Tiptur",
      "CityValue": "Tiptur"
    },
    {
      "id": 930,
      "CityName": "Sirhind Fatehgarh Sahib",
      "CityValue": "Sirhind Fatehgarh Sahib"
    },
    {
      "id": 931,
      "CityName": "North Lakhimpur",
      "CityValue": "North Lakhimpur"
    },
    {
      "id": 932,
      "CityName": "Kanuru",
      "CityValue": "Kanuru"
    },
    {
      "id": 933,
      "CityName": "Wani",
      "CityValue": "Wani"
    },
    {
      "id": 934,
      "CityName": "Gangoh",
      "CityValue": "Gangoh"
    },
    {
      "id": 935,
      "CityName": "Bhatapara",
      "CityValue": "Bhatapara"
    },
    {
      "id": 936,
      "CityName": "Una",
      "CityValue": "Una"
    },
    {
      "id": 937,
      "CityName": "Panskura",
      "CityValue": "Panskura"
    },
    {
      "id": 938,
      "CityName": "Dhubri",
      "CityValue": "Dhubri"
    },
    {
      "id": 939,
      "CityName": "Aonla",
      "CityValue": "Aonla"
    },
    {
      "id": 940,
      "CityName": "Punganur",
      "CityValue": "Punganur"
    },
    {
      "id": 941,
      "CityName": "Daudnagar",
      "CityValue": "Daudnagar"
    },
    {
      "id": 942,
      "CityName": "Sidhpur",
      "CityValue": "Sidhpur"
    },
    {
      "id": 943,
      "CityName": "Kotharia",
      "CityValue": "Kotharia"
    },
    {
      "id": 944,
      "CityName": "Bhadravati",
      "CityValue": "Bhadravati"
    },
    {
      "id": 945,
      "CityName": "Macherla",
      "CityValue": "Macherla"
    },
    {
      "id": 946,
      "CityName": "Chitrakoot Dham",
      "CityValue": "Chitrakoot Dham"
    },
    {
      "id": 947,
      "CityName": "Jaggaiahpet",
      "CityValue": "Jaggaiahpet"
    },
    {
      "id": 948,
      "CityName": "Rampurhat",
      "CityValue": "Rampurhat"
    },
    {
      "id": 949,
      "CityName": "Gobichettipalayam",
      "CityValue": "Gobichettipalayam"
    },
    {
      "id": 950,
      "CityName": "Alipurduar",
      "CityValue": "Alipurduar"
    },
    {
      "id": 951,
      "CityName": "Ashta",
      "CityValue": "Ashta"
    },
    {
      "id": 952,
      "CityName": "Nedumangad",
      "CityValue": "Nedumangad"
    },
    {
      "id": 953,
      "CityName": "Palasa Kasibugga",
      "CityValue": "Palasa Kasibugga"
    },
    {
      "id": 954,
      "CityName": "Upleta",
      "CityValue": "Upleta"
    },
    {
      "id": 955,
      "CityName": "Anakaputhur",
      "CityValue": "Anakaputhur"
    },
    {
      "id": 956,
      "CityName": "Ponnur",
      "CityValue": "Ponnur"
    },
    {
      "id": 957,
      "CityName": "Sira",
      "CityValue": "Sira"
    },
    {
      "id": 958,
      "CityName": "Kandukur",
      "CityValue": "Kandukur"
    },
    {
      "id": 959,
      "CityName": "Ramapuram",
      "CityValue": "Ramapuram"
    },
    {
      "id": 960,
      "CityName": "Ramanathapuram",
      "CityValue": "Ramanathapuram"
    },
    {
      "id": 961,
      "CityName": "Venkatagiri",
      "CityValue": "Venkatagiri"
    },
    {
      "id": 962,
      "CityName": "Gadchiroli",
      "CityValue": "Gadchiroli"
    },
    {
      "id": 963,
      "CityName": "Manglaur",
      "CityValue": "Manglaur"
    },
    {
      "id": 964,
      "CityName": "Veerapandi",
      "CityValue": "Veerapandi"
    },
    {
      "id": 965,
      "CityName": "Sendhwa",
      "CityValue": "Sendhwa"
    },
    {
      "id": 966,
      "CityName": "Gayespur",
      "CityValue": "Gayespur"
    },
    {
      "id": 967,
      "CityName": "Jalaun",
      "CityValue": "Jalaun"
    },
    {
      "id": 968,
      "CityName": "Perungudi",
      "CityValue": "Perungudi"
    },
    {
      "id": 969,
      "CityName": "Porur",
      "CityValue": "Porur"
    },
    {
      "id": 970,
      "CityName": "Hiriyur",
      "CityValue": "Hiriyur"
    },
    {
      "id": 971,
      "CityName": "Thennala",
      "CityValue": "Thennala"
    },
    {
      "id": 972,
      "CityName": "Kodungallur",
      "CityValue": "Kodungallur"
    },
    {
      "id": 973,
      "CityName": "Biswan",
      "CityValue": "Biswan"
    },
    {
      "id": 974,
      "CityName": "Chiplun",
      "CityValue": "Chiplun"
    },
    {
      "id": 975,
      "CityName": "Sembakkam",
      "CityValue": "Sembakkam"
    },
    {
      "id": 976,
      "CityName": "Rupnagar",
      "CityValue": "Rupnagar"
    },
    {
      "id": 977,
      "CityName": "Madhepura",
      "CityValue": "Madhepura"
    },
    {
      "id": 978,
      "CityName": "Bobbili",
      "CityValue": "Bobbili"
    },
    {
      "id": 979,
      "CityName": "Moonniyur",
      "CityValue": "Moonniyur"
    },
    {
      "id": 980,
      "CityName": "Sultanganj",
      "CityValue": "Sultanganj"
    },
    {
      "id": 981,
      "CityName": "Madhupur",
      "CityValue": "Madhupur"
    },
    {
      "id": 982,
      "CityName": "Tirurangadi",
      "CityValue": "Tirurangadi"
    },
    {
      "id": 983,
      "CityName": "Valasaravakkam",
      "CityValue": "Valasaravakkam"
    },
    {
      "id": 984,
      "CityName": "Arcot",
      "CityValue": "Arcot"
    },
    {
      "id": 985,
      "CityName": "Rajampet",
      "CityValue": "Rajampet"
    },
    {
      "id": 986,
      "CityName": "Mandapeta",
      "CityValue": "Mandapeta"
    },
    {
      "id": 987,
      "CityName": "Sattenapalle",
      "CityValue": "Sattenapalle"
    },
    {
      "id": 988,
      "CityName": "Hilsa",
      "CityValue": "Hilsa"
    },
    {
      "id": 989,
      "CityName": "Jalor",
      "CityValue": "Jalor"
    },
    {
      "id": 990,
      "CityName": "Jait Pur",
      "CityValue": "Jait Pur"
    },
    {
      "id": 991,
      "CityName": "Kollegal",
      "CityValue": "Kollegal"
    },
    {
      "id": 992,
      "CityName": "Sidhi",
      "CityValue": "Sidhi"
    },
    {
      "id": 993,
      "CityName": "Bakhtiarpur",
      "CityValue": "Bakhtiarpur"
    },
    {
      "id": 994,
      "CityName": "Baghpat",
      "CityValue": "Baghpat"
    },
    {
      "id": 995,
      "CityName": "Fatwah",
      "CityValue": "Fatwah"
    },
    {
      "id": 996,
      "CityName": "Nandivaram - Guduvancheri",
      "CityValue": "Nandivaram - Guduvancheri"
    },
    {
      "id": 997,
      "CityName": "Thiruvarur",
      "CityValue": "Thiruvarur"
    },
    {
      "id": 998,
      "CityName": "Devakottai",
      "CityValue": "Devakottai"
    },
    {
      "id": 999,
      "CityName": "Abu Road",
      "CityValue": "Abu Road"
    },
    {
      "id": 1000,
      "CityName": "Sankarankoil",
      "CityValue": "Sankarankoil"
    },
    {
      "id": 1001,
      "CityName": "Ayodhya",
      "CityValue": "Ayodhya"
    },
    {
      "id": 1002,
      "CityName": "Samalkot",
      "CityValue": "Samalkot"
    },
    {
      "id": 1003,
      "CityName": "Siruguppa",
      "CityValue": "Siruguppa"
    },
    {
      "id": 1004,
      "CityName": "Kandi",
      "CityValue": "Kandi"
    },
    {
      "id": 1005,
      "CityName": "Karimganj",
      "CityValue": "Karimganj"
    },
    {
      "id": 1006,
      "CityName": "Anjangaon",
      "CityValue": "Anjangaon"
    },
    {
      "id": 1007,
      "CityName": "Sironj",
      "CityValue": "Sironj"
    },
    {
      "id": 1008,
      "CityName": "Didwana",
      "CityValue": "Didwana"
    },
    {
      "id": 1009,
      "CityName": "Sihor",
      "CityValue": "Sihor"
    },
    {
      "id": 1010,
      "CityName": "Nagaram",
      "CityValue": "Nagaram"
    },
    {
      "id": 1011,
      "CityName": "Choudwar",
      "CityValue": "Choudwar"
    },
    {
      "id": 1012,
      "CityName": "Thiruthangal",
      "CityValue": "Thiruthangal"
    },
    {
      "id": 1013,
      "CityName": "Unjha",
      "CityValue": "Unjha"
    },
    {
      "id": 1014,
      "CityName": "Gumla",
      "CityValue": "Gumla"
    },
    {
      "id": 1015,
      "CityName": "Hodal",
      "CityValue": "Hodal"
    },
    {
      "id": 1016,
      "CityName": "Samana",
      "CityValue": "Samana"
    },
    {
      "id": 1017,
      "CityName": "Dhuri",
      "CityValue": "Dhuri"
    },
    {
      "id": 1018,
      "CityName": "Kalna",
      "CityValue": "Kalna"
    },
    {
      "id": 1019,
      "CityName": "Dumraon",
      "CityValue": "Dumraon"
    },
    {
      "id": 1020,
      "CityName": "Narasapur",
      "CityValue": "Narasapur"
    },
    {
      "id": 1021,
      "CityName": "Mahasamund",
      "CityValue": "Mahasamund"
    },
    {
      "id": 1022,
      "CityName": "Mandla",
      "CityValue": "Mandla"
    },
    {
      "id": 1023,
      "CityName": "Challakere",
      "CityValue": "Challakere"
    },
    {
      "id": 1024,
      "CityName": "Periyasemur",
      "CityValue": "Periyasemur"
    },
    {
      "id": 1025,
      "CityName": "Phulia",
      "CityValue": "Phulia"
    },
    {
      "id": 1026,
      "CityName": "Viramgam",
      "CityValue": "Viramgam"
    },
    {
      "id": 1027,
      "CityName": "Dyane",
      "CityValue": "Dyane"
    },
    {
      "id": 1028,
      "CityName": "Bid",
      "CityValue": "Bid"
    },
    {
      "id": 1029,
      "CityName": "Jangaon",
      "CityValue": "Jangaon"
    },
    {
      "id": 1030,
      "CityName": "Medavakkam",
      "CityValue": "Medavakkam"
    },
    {
      "id": 1031,
      "CityName": "Chakan",
      "CityValue": "Chakan"
    },
    {
      "id": 1032,
      "CityName": "Deglur",
      "CityValue": "Deglur"
    },
    {
      "id": 1033,
      "CityName": "Arsikere",
      "CityValue": "Arsikere"
    },
    {
      "id": 1034,
      "CityName": "Budhana",
      "CityValue": "Budhana"
    },
    {
      "id": 1035,
      "CityName": "Pallikaranai",
      "CityValue": "Pallikaranai"
    },
    {
      "id": 1036,
      "CityName": "Kanakapura",
      "CityValue": "Kanakapura"
    },
    {
      "id": 1037,
      "CityName": "Dungargarh",
      "CityValue": "Dungargarh"
    },
    {
      "id": 1038,
      "CityName": "Edappadi",
      "CityValue": "Edappadi"
    },
    {
      "id": 1039,
      "CityName": "Jaspur",
      "CityValue": "Jaspur"
    },
    {
      "id": 1040,
      "CityName": "Pernampattu",
      "CityValue": "Pernampattu"
    },
    {
      "id": 1041,
      "CityName": "Mudhol",
      "CityValue": "Mudhol"
    },
    {
      "id": 1042,
      "CityName": "Bheemunipatnam",
      "CityValue": "Bheemunipatnam"
    },
    {
      "id": 1043,
      "CityName": "Mandvi",
      "CityValue": "Mandvi"
    },
    {
      "id": 1044,
      "CityName": "Baramati",
      "CityValue": "Baramati"
    },
    {
      "id": 1045,
      "CityName": "Petlad",
      "CityValue": "Petlad"
    },
    {
      "id": 1046,
      "CityName": "Teghra",
      "CityValue": "Teghra"
    },
    {
      "id": 1047,
      "CityName": "Sidlaghatta",
      "CityValue": "Sidlaghatta"
    },
    {
      "id": 1048,
      "CityName": "Baruipur",
      "CityValue": "Baruipur"
    },
    {
      "id": 1049,
      "CityName": "Gangarampur",
      "CityValue": "Gangarampur"
    },
    {
      "id": 1050,
      "CityName": "Perambalur",
      "CityValue": "Perambalur"
    },
    {
      "id": 1051,
      "CityName": "Roshan Pura alias Dichaon Khurd",
      "CityValue": "Roshan Pura alias Dichaon Khurd"
    },
    {
      "id": 1052,
      "CityName": "Kawardha",
      "CityValue": "Kawardha"
    },
    {
      "id": 1053,
      "CityName": "Mukand Pur",
      "CityValue": "Mukand Pur"
    },
    {
      "id": 1054,
      "CityName": "Tirur",
      "CityValue": "Tirur"
    },
    {
      "id": 1055,
      "CityName": "Umred",
      "CityValue": "Umred"
    },
    {
      "id": 1056,
      "CityName": "Rajam",
      "CityValue": "Rajam"
    },
    {
      "id": 1057,
      "CityName": "Lachhmangarh",
      "CityValue": "Lachhmangarh"
    },
    {
      "id": 1058,
      "CityName": "Kalpi",
      "CityValue": "Kalpi"
    },
    {
      "id": 1059,
      "CityName": "Nawanshahr",
      "CityValue": "Nawanshahr"
    },
    {
      "id": 1060,
      "CityName": "Namakkal",
      "CityValue": "Namakkal"
    },
    {
      "id": 1061,
      "CityName": "Umarkhed",
      "CityValue": "Umarkhed"
    },
    {
      "id": 1062,
      "CityName": "Naugachhia",
      "CityValue": "Naugachhia"
    },
    {
      "id": 1063,
      "CityName": "Thanthoni",
      "CityValue": "Thanthoni"
    },
    {
      "id": 1064,
      "CityName": "Deolali",
      "CityValue": "Deolali"
    },
    {
      "id": 1065,
      "CityName": "Thiruparankundram",
      "CityValue": "Thiruparankundram"
    },
    {
      "id": 1066,
      "CityName": "Sagar",
      "CityValue": "Sagar"
    },
    {
      "id": 1067,
      "CityName": "Pithapuram",
      "CityValue": "Pithapuram"
    },
    {
      "id": 1068,
      "CityName": "Shorapur",
      "CityValue": "Shorapur"
    },
    {
      "id": 1069,
      "CityName": "Kaliaganj",
      "CityValue": "Kaliaganj"
    },
    {
      "id": 1070,
      "CityName": "Shujalpur",
      "CityValue": "Shujalpur"
    },
    {
      "id": 1071,
      "CityName": "Milavittan",
      "CityValue": "Milavittan"
    },
    {
      "id": 1072,
      "CityName": "Mahmudabad",
      "CityValue": "Mahmudabad"
    },
    {
      "id": 1073,
      "CityName": "Ottappalam",
      "CityValue": "Ottappalam"
    },
    {
      "id": 1074,
      "CityName": "Thirumangalam",
      "CityValue": "Thirumangalam"
    },
    {
      "id": 1075,
      "CityName": "Godda",
      "CityValue": "Godda"
    },
    {
      "id": 1076,
      "CityName": "Forbesganj",
      "CityValue": "Forbesganj"
    },
    {
      "id": 1077,
      "CityName": "Goalpara",
      "CityValue": "Goalpara"
    },
    {
      "id": 1078,
      "CityName": "Tundla",
      "CityValue": "Tundla"
    },
    {
      "id": 1079,
      "CityName": "Ghatal",
      "CityValue": "Ghatal"
    },
    {
      "id": 1080,
      "CityName": "Rajagangapur",
      "CityValue": "Rajagangapur"
    },
    {
      "id": 1081,
      "CityName": "Ullal",
      "CityValue": "Ullal"
    },
    {
      "id": 1082,
      "CityName": "Arwal",
      "CityValue": "Arwal"
    },
    {
      "id": 1083,
      "CityName": "Mangadu",
      "CityValue": "Mangadu"
    },
    {
      "id": 1084,
      "CityName": "Baduria",
      "CityValue": "Baduria"
    },
    {
      "id": 1085,
      "CityName": "Parvathipuram",
      "CityValue": "Parvathipuram"
    },
    {
      "id": 1086,
      "CityName": "Puttur",
      "CityValue": "Puttur"
    },
    {
      "id": 1087,
      "CityName": "Gangakhed",
      "CityValue": "Gangakhed"
    },
    {
      "id": 1088,
      "CityName": "Kagaznagar",
      "CityValue": "Kagaznagar"
    },
    {
      "id": 1089,
      "CityName": "Hunsur",
      "CityValue": "Hunsur"
    },
    {
      "id": 1090,
      "CityName": "Phalodi",
      "CityValue": "Phalodi"
    },
    {
      "id": 1091,
      "CityName": "Mahnar Bazar",
      "CityValue": "Mahnar Bazar"
    },
    {
      "id": 1092,
      "CityName": "Repalle",
      "CityValue": "Repalle"
    },
    {
      "id": 1093,
      "CityName": "Gulaothi",
      "CityValue": "Gulaothi"
    },
    {
      "id": 1094,
      "CityName": "Joura",
      "CityValue": "Joura"
    },
    {
      "id": 1095,
      "CityName": "Atrauli",
      "CityValue": "Atrauli"
    },
    {
      "id": 1096,
      "CityName": "Vazhakkala",
      "CityValue": "Vazhakkala"
    },
    {
      "id": 1097,
      "CityName": "Tuni",
      "CityValue": "Tuni"
    },
    {
      "id": 1098,
      "CityName": "Khairabad",
      "CityValue": "Khairabad"
    },
    {
      "id": 1099,
      "CityName": "Ramnagar",
      "CityValue": "Ramnagar"
    },
    {
      "id": 1100,
      "CityName": "Bhabua",
      "CityValue": "Bhabua"
    },
    {
      "id": 1101,
      "CityName": "Kotputli",
      "CityValue": "Kotputli"
    },
    {
      "id": 1102,
      "CityName": "Jasdan",
      "CityValue": "Jasdan"
    },
    {
      "id": 1103,
      "CityName": "Chatra",
      "CityValue": "Chatra"
    },
    {
      "id": 1104,
      "CityName": "Daund",
      "CityValue": "Daund"
    },
    {
      "id": 1105,
      "CityName": "Morar Cantt",
      "CityValue": "Morar Cantt"
    },
    {
      "id": 1106,
      "CityName": "Garhmukhteshwar",
      "CityValue": "Garhmukhteshwar"
    },
    {
      "id": 1107,
      "CityName": "Islampur",
      "CityValue": "Islampur"
    },
    {
      "id": 1108,
      "CityName": "Ambah",
      "CityValue": "Ambah"
    },
    {
      "id": 1109,
      "CityName": "Narkatiaganj",
      "CityValue": "Narkatiaganj"
    },
    {
      "id": 1110,
      "CityName": "Thodupuzha",
      "CityValue": "Thodupuzha"
    },
    {
      "id": 1111,
      "CityName": "Khanapuram Haveli",
      "CityValue": "Khanapuram Haveli"
    },
    {
      "id": 1112,
      "CityName": "Konch",
      "CityValue": "Konch"
    },
    {
      "id": 1113,
      "CityName": "Sahibabad Daulat Pur",
      "CityValue": "Sahibabad Daulat Pur"
    },
    {
      "id": 1114,
      "CityName": "Bachpalle",
      "CityValue": "Bachpalle"
    },
    {
      "id": 1115,
      "CityName": "Ozar",
      "CityValue": "Ozar"
    },
    {
      "id": 1116,
      "CityName": "Bikramganj",
      "CityValue": "Bikramganj"
    },
    {
      "id": 1117,
      "CityName": "Ramnagar",
      "CityValue": "Ramnagar"
    },
    {
      "id": 1118,
      "CityName": "Kunnamkulam",
      "CityValue": "Kunnamkulam"
    },
    {
      "id": 1119,
      "CityName": "Kasaragod",
      "CityValue": "Kasaragod"
    },
    {
      "id": 1120,
      "CityName": "Jaffrabad",
      "CityValue": "Jaffrabad"
    },
    {
      "id": 1121,
      "CityName": "Peerzadguda",
      "CityValue": "Peerzadguda"
    },
    {
      "id": 1122,
      "CityName": "Mundka",
      "CityValue": "Mundka"
    },
    {
      "id": 1123,
      "CityName": "Mathur",
      "CityValue": "Mathur"
    },
    {
      "id": 1124,
      "CityName": "Jiaganj-Azimganj",
      "CityValue": "Jiaganj-Azimganj"
    },
    {
      "id": 1125,
      "CityName": "Rampura Phul",
      "CityValue": "Rampura Phul"
    },
    {
      "id": 1126,
      "CityName": "Melamadai",
      "CityValue": "Melamadai"
    },
    {
      "id": 1127,
      "CityName": "Bhongir",
      "CityValue": "Bhongir"
    },
    {
      "id": 1128,
      "CityName": "Yevla",
      "CityValue": "Yevla"
    },
    {
      "id": 1129,
      "CityName": "Bilimora",
      "CityValue": "Bilimora"
    },
    {
      "id": 1130,
      "CityName": "Agra",
      "CityValue": "Agra"
    },
    {
      "id": 1131,
      "CityName": "Seohara",
      "CityValue": "Seohara"
    },
    {
      "id": 1132,
      "CityName": "Amalapuram",
      "CityValue": "Amalapuram"
    },
    {
      "id": 1133,
      "CityName": "Nohar",
      "CityValue": "Nohar"
    },
    {
      "id": 1134,
      "CityName": "Bihta",
      "CityValue": "Bihta"
    },
    {
      "id": 1135,
      "CityName": "Jhajjar",
      "CityValue": "Jhajjar"
    },
    {
      "id": 1136,
      "CityName": "Anekal",
      "CityValue": "Anekal"
    },
    {
      "id": 1137,
      "CityName": "Bhadrachalam",
      "CityValue": "Bhadrachalam"
    },
    {
      "id": 1138,
      "CityName": "Hemambikanagar",
      "CityValue": "Hemambikanagar"
    },
    {
      "id": 1139,
      "CityName": "Pallichal",
      "CityValue": "Pallichal"
    },
    {
      "id": 1140,
      "CityName": "Dharapuram",
      "CityValue": "Dharapuram"
    },
    {
      "id": 1141,
      "CityName": "Dahanu",
      "CityValue": "Dahanu"
    },
    {
      "id": 1142,
      "CityName": "Bakshi Ka Talab",
      "CityValue": "Bakshi Ka Talab"
    },
    {
      "id": 1143,
      "CityName": "Bhainsa",
      "CityValue": "Bhainsa"
    },
    {
      "id": 1144,
      "CityName": "Biaora",
      "CityValue": "Biaora"
    },
    {
      "id": 1145,
      "CityName": "Begum Pur",
      "CityValue": "Begum Pur"
    },
    {
      "id": 1146,
      "CityName": "Chhaya",
      "CityValue": "Chhaya"
    },
    {
      "id": 1147,
      "CityName": "Akkarampalle",
      "CityValue": "Akkarampalle"
    },
    {
      "id": 1148,
      "CityName": "Kalapatti",
      "CityValue": "Kalapatti"
    },
    {
      "id": 1149,
      "CityName": "Khekada",
      "CityValue": "Khekada"
    },
    {
      "id": 1150,
      "CityName": "Kalimpong",
      "CityValue": "Kalimpong"
    },
    {
      "id": 1151,
      "CityName": "Dehradun",
      "CityValue": "Dehradun"
    },
    {
      "id": 1152,
      "CityName": "Perungalathur",
      "CityValue": "Perungalathur"
    },
    {
      "id": 1153,
      "CityName": "Bhinmal",
      "CityValue": "Bhinmal"
    },
    {
      "id": 1154,
      "CityName": "Kannur",
      "CityValue": "Kannur"
    },
    {
      "id": 1155,
      "CityName": "Karad",
      "CityValue": "Karad"
    },
    {
      "id": 1156,
      "CityName": "Walajapet",
      "CityValue": "Walajapet"
    },
    {
      "id": 1157,
      "CityName": "Pipariya",
      "CityValue": "Pipariya"
    },
    {
      "id": 1158,
      "CityName": "Phaltan",
      "CityValue": "Phaltan"
    },
    {
      "id": 1159,
      "CityName": "Jamner",
      "CityValue": "Jamner"
    },
    {
      "id": 1160,
      "CityName": "Padra",
      "CityValue": "Padra"
    },
    {
      "id": 1161,
      "CityName": "Padrauna",
      "CityValue": "Padrauna"
    },
    {
      "id": 1162,
      "CityName": "Farooqnagar",
      "CityValue": "Farooqnagar"
    },
    {
      "id": 1163,
      "CityName": "Ambala Cantt.",
      "CityValue": "Ambala Cantt."
    },
    {
      "id": 1164,
      "CityName": "Ranipettai",
      "CityValue": "Ranipettai"
    },
    {
      "id": 1165,
      "CityName": "Tanda",
      "CityValue": "Tanda"
    },
    {
      "id": 1166,
      "CityName": "Athni",
      "CityValue": "Athni"
    },
    {
      "id": 1167,
      "CityName": "Dhampur",
      "CityValue": "Dhampur"
    },
    {
      "id": 1168,
      "CityName": "Banka",
      "CityValue": "Banka"
    },
    {
      "id": 1169,
      "CityName": "Kodur",
      "CityValue": "Kodur"
    },
    {
      "id": 1170,
      "CityName": "Meerpet",
      "CityValue": "Meerpet"
    },
    {
      "id": 1171,
      "CityName": "Bapraula",
      "CityValue": "Bapraula"
    },
    {
      "id": 1172,
      "CityName": "Gursahaiganj",
      "CityValue": "Gursahaiganj"
    },
    {
      "id": 1173,
      "CityName": "Chettipalayam",
      "CityValue": "Chettipalayam"
    },
    {
      "id": 1174,
      "CityName": "Khalilabad",
      "CityValue": "Khalilabad"
    },
    {
      "id": 1175,
      "CityName": "Nanjangud",
      "CityValue": "Nanjangud"
    },
    {
      "id": 1176,
      "CityName": "Rasipuram",
      "CityValue": "Rasipuram"
    },
    {
      "id": 1177,
      "CityName": "Bellampalle",
      "CityValue": "Bellampalle"
    },
    {
      "id": 1178,
      "CityName": "Manjlegaon",
      "CityValue": "Manjlegaon"
    },
    {
      "id": 1179,
      "CityName": "Bail Hongal",
      "CityValue": "Bail Hongal"
    },
    {
      "id": 1180,
      "CityName": "Perinthalmanna",
      "CityValue": "Perinthalmanna"
    },
    {
      "id": 1181,
      "CityName": "Panipat Taraf Ansar",
      "CityValue": "Panipat Taraf Ansar"
    },
    {
      "id": 1182,
      "CityName": "Metpalle",
      "CityValue": "Metpalle"
    },
    {
      "id": 1183,
      "CityName": "Sadasivpet",
      "CityValue": "Sadasivpet"
    },
    {
      "id": 1184,
      "CityName": "Gooty",
      "CityValue": "Gooty"
    },
    {
      "id": 1185,
      "CityName": "Bangarmau",
      "CityValue": "Bangarmau"
    },
    {
      "id": 1186,
      "CityName": "Gudalur",
      "CityValue": "Gudalur"
    },
    {
      "id": 1187,
      "CityName": "Mettur",
      "CityValue": "Mettur"
    },
    {
      "id": 1188,
      "CityName": "Kapadvanj",
      "CityValue": "Kapadvanj"
    },
    {
      "id": 1189,
      "CityName": "Gudalur",
      "CityValue": "Gudalur"
    },
    {
      "id": 1190,
      "CityName": "Pallikal",
      "CityValue": "Pallikal"
    },
    {
      "id": 1191,
      "CityName": "Jalandhar Cantt.",
      "CityValue": "Jalandhar Cantt."
    },
    {
      "id": 1192,
      "CityName": "Pakaur",
      "CityValue": "Pakaur"
    },
    {
      "id": 1193,
      "CityName": "Garhwa",
      "CityValue": "Garhwa"
    },
    {
      "id": 1194,
      "CityName": "Peddapuram",
      "CityValue": "Peddapuram"
    },
    {
      "id": 1195,
      "CityName": "Kottayam",
      "CityValue": "Kottayam"
    },
    {
      "id": 1196,
      "CityName": "Tanalur",
      "CityValue": "Tanalur"
    },
    {
      "id": 1197,
      "CityName": "Mandi Dabwali",
      "CityValue": "Mandi Dabwali"
    },
    {
      "id": 1198,
      "CityName": "Ranavav",
      "CityValue": "Ranavav"
    },
    {
      "id": 1199,
      "CityName": "Olavanna",
      "CityValue": "Olavanna"
    },
    {
      "id": 1200,
      "CityName": "Daman",
      "CityValue": "Daman"
    },
    {
      "id": 1201,
      "CityName": "Siddipet",
      "CityValue": "Siddipet"
    },
    {
      "id": 1202,
      "CityName": "Nasirabad",
      "CityValue": "Nasirabad"
    },
    {
      "id": 1203,
      "CityName": "Jaysingpur",
      "CityValue": "Jaysingpur"
    },
    {
      "id": 1204,
      "CityName": "Nanpara",
      "CityValue": "Nanpara"
    },
    {
      "id": 1205,
      "CityName": "Vita",
      "CityValue": "Vita"
    },
    {
      "id": 1206,
      "CityName": "Jugsalai",
      "CityValue": "Jugsalai"
    },
    {
      "id": 1207,
      "CityName": "Khagaria",
      "CityValue": "Khagaria"
    },
    {
      "id": 1208,
      "CityName": "Manvi",
      "CityValue": "Manvi"
    },
    {
      "id": 1209,
      "CityName": "Dehu Road",
      "CityValue": "Dehu Road"
    },
    {
      "id": 1210,
      "CityName": "Koraput",
      "CityValue": "Koraput"
    },
    {
      "id": 1211,
      "CityName": "Dandeli",
      "CityValue": "Dandeli"
    },
    {
      "id": 1212,
      "CityName": "Palladam",
      "CityValue": "Palladam"
    },
    {
      "id": 1213,
      "CityName": "Joda",
      "CityValue": "Joda"
    },
    {
      "id": 1214,
      "CityName": "Barbigha",
      "CityValue": "Barbigha"
    },
    {
      "id": 1215,
      "CityName": "Vengara",
      "CityValue": "Vengara"
    },
    {
      "id": 1216,
      "CityName": "Firozpur Cantt",
      "CityValue": "Firozpur Cantt"
    },
    {
      "id": 1217,
      "CityName": "Sivasagar",
      "CityValue": "Sivasagar"
    },
    {
      "id": 1218,
      "CityName": "Kunnamangalam",
      "CityValue": "Kunnamangalam"
    },
    {
      "id": 1219,
      "CityName": "Gadarwara",
      "CityValue": "Gadarwara"
    },
    {
      "id": 1220,
      "CityName": "Thottipalayam",
      "CityValue": "Thottipalayam"
    },
    {
      "id": 1221,
      "CityName": "Kandhla",
      "CityValue": "Kandhla"
    },
    {
      "id": 1222,
      "CityName": "Vadavalli",
      "CityValue": "Vadavalli"
    },
    {
      "id": 1223,
      "CityName": "Sailu",
      "CityValue": "Sailu"
    },
    {
      "id": 1224,
      "CityName": "Sikandrarao",
      "CityValue": "Sikandrarao"
    },
    {
      "id": 1225,
      "CityName": "Gidderbaha",
      "CityValue": "Gidderbaha"
    },
    {
      "id": 1226,
      "CityName": "Burla",
      "CityValue": "Burla"
    },
    {
      "id": 1227,
      "CityName": "Alangad",
      "CityValue": "Alangad"
    },
    {
      "id": 1228,
      "CityName": "Nangal",
      "CityValue": "Nangal"
    },
    {
      "id": 1229,
      "CityName": "Bhupalpalle",
      "CityValue": "Bhupalpalle"
    },
    {
      "id": 1230,
      "CityName": "Balia",
      "CityValue": "Balia"
    },
    {
      "id": 1231,
      "CityName": "Nithari",
      "CityValue": "Nithari"
    },
    {
      "id": 1232,
      "CityName": "Bhachau",
      "CityValue": "Bhachau"
    },
    {
      "id": 1233,
      "CityName": "Bharat Heavy Electricals Limited Ranipur",
      "CityValue": "Bharat Heavy Electricals Limited Ranipur"
    },
    {
      "id": 1234,
      "CityName": "Dondaicha-Warwade",
      "CityValue": "Dondaicha-Warwade"
    },
    {
      "id": 1235,
      "CityName": "Raisen",
      "CityValue": "Raisen"
    },
    {
      "id": 1236,
      "CityName": "Sholinganallur",
      "CityValue": "Sholinganallur"
    },
    {
      "id": 1237,
      "CityName": "Melvisharam",
      "CityValue": "Melvisharam"
    },
    {
      "id": 1238,
      "CityName": "Jaura Khurd",
      "CityValue": "Jaura Khurd"
    },
    {
      "id": 1239,
      "CityName": "Thiruvalla",
      "CityValue": "Thiruvalla"
    },
    {
      "id": 1240,
      "CityName": "Kendrapara",
      "CityValue": "Kendrapara"
    },
    {
      "id": 1241,
      "CityName": "Salur",
      "CityValue": "Salur"
    },
    {
      "id": 1242,
      "CityName": "Bishrampur",
      "CityValue": "Bishrampur"
    },
    {
      "id": 1243,
      "CityName": "Harapanahalli",
      "CityValue": "Harapanahalli"
    },
    {
      "id": 1244,
      "CityName": "Barwala",
      "CityValue": "Barwala"
    },
    {
      "id": 1245,
      "CityName": "Khordha",
      "CityValue": "Khordha"
    },
    {
      "id": 1246,
      "CityName": "Mehkar",
      "CityValue": "Mehkar"
    },
    {
      "id": 1247,
      "CityName": "Chalakudy",
      "CityValue": "Chalakudy"
    },
    {
      "id": 1248,
      "CityName": "Atarra",
      "CityValue": "Atarra"
    },
    {
      "id": 1249,
      "CityName": "Nehtaur",
      "CityValue": "Nehtaur"
    },
    {
      "id": 1250,
      "CityName": "Tiruttani",
      "CityValue": "Tiruttani"
    },
    {
      "id": 1251,
      "CityName": "Dungarpur",
      "CityValue": "Dungarpur"
    },
    {
      "id": 1252,
      "CityName": "Kichha",
      "CityValue": "Kichha"
    },
    {
      "id": 1253,
      "CityName": "Thakurdwara",
      "CityValue": "Thakurdwara"
    },
    {
      "id": 1254,
      "CityName": "Jammalamadugu",
      "CityValue": "Jammalamadugu"
    },
    {
      "id": 1255,
      "CityName": "Vattiyoorkavu",
      "CityValue": "Vattiyoorkavu"
    },
    {
      "id": 1256,
      "CityName": "Champa",
      "CityValue": "Champa"
    },
    {
      "id": 1257,
      "CityName": "Ahmadpur",
      "CityValue": "Ahmadpur"
    },
    {
      "id": 1258,
      "CityName": "Gumia",
      "CityValue": "Gumia"
    },
    {
      "id": 1259,
      "CityName": "Dabhoi",
      "CityValue": "Dabhoi"
    },
    {
      "id": 1260,
      "CityName": "Merta City",
      "CityValue": "Merta City"
    },
    {
      "id": 1261,
      "CityName": "Mohammadi",
      "CityValue": "Mohammadi"
    },
    {
      "id": 1262,
      "CityName": "Sumerpur",
      "CityValue": "Sumerpur"
    },
    {
      "id": 1263,
      "CityName": "Dumka",
      "CityValue": "Dumka"
    },
    {
      "id": 1264,
      "CityName": "Muhammadabad",
      "CityValue": "Muhammadabad"
    },
    {
      "id": 1265,
      "CityName": "Homnabad",
      "CityValue": "Homnabad"
    },
    {
      "id": 1266,
      "CityName": "Vadalur",
      "CityValue": "Vadalur"
    },
    {
      "id": 1267,
      "CityName": "Nilambur",
      "CityValue": "Nilambur"
    },
    {
      "id": 1268,
      "CityName": "Pileru",
      "CityValue": "Pileru"
    },
    {
      "id": 1269,
      "CityName": "Chirkunda",
      "CityValue": "Chirkunda"
    },
    {
      "id": 1270,
      "CityName": "Rameswaram",
      "CityValue": "Rameswaram"
    },
    {
      "id": 1271,
      "CityName": "Sundargarh",
      "CityValue": "Sundargarh"
    },
    {
      "id": 1272,
      "CityName": "Koilambakkam",
      "CityValue": "Koilambakkam"
    },
    {
      "id": 1273,
      "CityName": "Medak",
      "CityValue": "Medak"
    },
    {
      "id": 1274,
      "CityName": "Bilaspur",
      "CityValue": "Bilaspur"
    },
    {
      "id": 1275,
      "CityName": "Bangarapet",
      "CityValue": "Bangarapet"
    },
    {
      "id": 1276,
      "CityName": "Ranjangaon",
      "CityValue": "Ranjangaon"
    },
    {
      "id": 1277,
      "CityName": "Nellikuppam",
      "CityValue": "Nellikuppam"
    },
    {
      "id": 1278,
      "CityName": "Thoubal",
      "CityValue": "Thoubal"
    },
    {
      "id": 1279,
      "CityName": "Mappilaiurani",
      "CityValue": "Mappilaiurani"
    },
    {
      "id": 1280,
      "CityName": "Warora",
      "CityValue": "Warora"
    },
    {
      "id": 1281,
      "CityName": "Komalapuram",
      "CityValue": "Komalapuram"
    },
    {
      "id": 1282,
      "CityName": "Simdega",
      "CityValue": "Simdega"
    },
    {
      "id": 1283,
      "CityName": "Dhaka",
      "CityValue": "Dhaka"
    },
    {
      "id": 1284,
      "CityName": "Dhupguri",
      "CityValue": "Dhupguri"
    },
    {
      "id": 1285,
      "CityName": "Hebbagodi",
      "CityValue": "Hebbagodi"
    },
    {
      "id": 1286,
      "CityName": "Murshidabad",
      "CityValue": "Murshidabad"
    },
    {
      "id": 1287,
      "CityName": "Digras",
      "CityValue": "Digras"
    },
    {
      "id": 1288,
      "CityName": "Rau",
      "CityValue": "Rau"
    },
    {
      "id": 1289,
      "CityName": "Bairgania",
      "CityValue": "Bairgania"
    },
    {
      "id": 1290,
      "CityName": "Malur",
      "CityValue": "Malur"
    },
    {
      "id": 1291,
      "CityName": "Bandikui",
      "CityValue": "Bandikui"
    },
    {
      "id": 1292,
      "CityName": "Baikunthpur",
      "CityValue": "Baikunthpur"
    },
    {
      "id": 1293,
      "CityName": "Kopali",
      "CityValue": "Kopali"
    },
    {
      "id": 1294,
      "CityName": "Mattannur",
      "CityValue": "Mattannur"
    },
    {
      "id": 1295,
      "CityName": "Nandura",
      "CityValue": "Nandura"
    },
    {
      "id": 1296,
      "CityName": "Sihora",
      "CityValue": "Sihora"
    },
    {
      "id": 1297,
      "CityName": "Dowleswaram",
      "CityValue": "Dowleswaram"
    },
    {
      "id": 1298,
      "CityName": "Sabalgarh",
      "CityValue": "Sabalgarh"
    },
    {
      "id": 1299,
      "CityName": "Pandhurna",
      "CityValue": "Pandhurna"
    },
    {
      "id": 1300,
      "CityName": "Medchal",
      "CityValue": "Medchal"
    },
    {
      "id": 1301,
      "CityName": "Haflong",
      "CityValue": "Haflong"
    },
    {
      "id": 1302,
      "CityName": "Jintur",
      "CityValue": "Jintur"
    },
    {
      "id": 1303,
      "CityName": "Balapur",
      "CityValue": "Balapur"
    },
    {
      "id": 1304,
      "CityName": "Ramganj Mandi",
      "CityValue": "Ramganj Mandi"
    },
    {
      "id": 1305,
      "CityName": "Samalkha",
      "CityValue": "Samalkha"
    },
    {
      "id": 1306,
      "CityName": "Sainthia",
      "CityValue": "Sainthia"
    },
    {
      "id": 1307,
      "CityName": "Surampatti",
      "CityValue": "Surampatti"
    },
    {
      "id": 1308,
      "CityName": "Siana",
      "CityValue": "Siana"
    },
    {
      "id": 1309,
      "CityName": "Gobardanga",
      "CityValue": "Gobardanga"
    },
    {
      "id": 1310,
      "CityName": "Golaghat",
      "CityValue": "Golaghat"
    },
    {
      "id": 1311,
      "CityName": "Paralakhemundi",
      "CityValue": "Paralakhemundi"
    },
    {
      "id": 1312,
      "CityName": "Warud",
      "CityValue": "Warud"
    },
    {
      "id": 1313,
      "CityName": "Ganj Dundawara",
      "CityValue": "Ganj Dundawara"
    },
    {
      "id": 1314,
      "CityName": "Chirawa",
      "CityValue": "Chirawa"
    },
    {
      "id": 1315,
      "CityName": "Mahabubabad",
      "CityValue": "Mahabubabad"
    },
    {
      "id": 1316,
      "CityName": "Wadgaon Road",
      "CityValue": "Wadgaon Road"
    },
    {
      "id": 1317,
      "CityName": "Deeg",
      "CityValue": "Deeg"
    },
    {
      "id": 1318,
      "CityName": "Ratia",
      "CityValue": "Ratia"
    },
    {
      "id": 1319,
      "CityName": "Kakkalapalle",
      "CityValue": "Kakkalapalle"
    },
    {
      "id": 1320,
      "CityName": "Katol",
      "CityValue": "Katol"
    },
    {
      "id": 1321,
      "CityName": "Kottakkal",
      "CityValue": "Kottakkal"
    },
    {
      "id": 1322,
      "CityName": "Wagholi",
      "CityValue": "Wagholi"
    },
    {
      "id": 1323,
      "CityName": "Tigri",
      "CityValue": "Tigri"
    },
    {
      "id": 1324,
      "CityName": "Paschim Punropara",
      "CityValue": "Paschim Punropara"
    },
    {
      "id": 1325,
      "CityName": "Wankaner",
      "CityValue": "Wankaner"
    },
    {
      "id": 1326,
      "CityName": "Tirupati",
      "CityValue": "Tirupati"
    },
    {
      "id": 1327,
      "CityName": "Maner",
      "CityValue": "Maner"
    },
    {
      "id": 1328,
      "CityName": "Sagar Cantt",
      "CityValue": "Sagar Cantt"
    },
    {
      "id": 1329,
      "CityName": "Bharthana",
      "CityValue": "Bharthana"
    },
    {
      "id": 1330,
      "CityName": "Dharmanagar",
      "CityValue": "Dharmanagar"
    },
    {
      "id": 1331,
      "CityName": "Chattar Pur",
      "CityValue": "Chattar Pur"
    },
    {
      "id": 1332,
      "CityName": "Aland",
      "CityValue": "Aland"
    },
    {
      "id": 1333,
      "CityName": "Vikramasingapuram",
      "CityValue": "Vikramasingapuram"
    },
    {
      "id": 1334,
      "CityName": "Rajgir",
      "CityValue": "Rajgir"
    },
    {
      "id": 1335,
      "CityName": "Kekri",
      "CityValue": "Kekri"
    },
    {
      "id": 1336,
      "CityName": "Shahabad",
      "CityValue": "Shahabad"
    },
    {
      "id": 1337,
      "CityName": "Kodinar",
      "CityValue": "Kodinar"
    },
    {
      "id": 1338,
      "CityName": "Sadabad",
      "CityValue": "Sadabad"
    },
    {
      "id": 1339,
      "CityName": "Chirakkal",
      "CityValue": "Chirakkal"
    },
    {
      "id": 1340,
      "CityName": "Kanker",
      "CityValue": "Kanker"
    },
    {
      "id": 1341,
      "CityName": "Rudauli",
      "CityValue": "Rudauli"
    },
    {
      "id": 1342,
      "CityName": "Jambusar",
      "CityValue": "Jambusar"
    },
    {
      "id": 1343,
      "CityName": "Anugul",
      "CityValue": "Anugul"
    },
    {
      "id": 1344,
      "CityName": "Madippakkam",
      "CityValue": "Madippakkam"
    },
    {
      "id": 1345,
      "CityName": "Idar",
      "CityValue": "Idar"
    },
    {
      "id": 1346,
      "CityName": "Choornikkara",
      "CityValue": "Choornikkara"
    },
    {
      "id": 1347,
      "CityName": "Bhawani Mandi",
      "CityValue": "Bhawani Mandi"
    },
    {
      "id": 1348,
      "CityName": "Maradu",
      "CityValue": "Maradu"
    },
    {
      "id": 1349,
      "CityName": "Kakkodi",
      "CityValue": "Kakkodi"
    },
    {
      "id": 1350,
      "CityName": "Sunabeda",
      "CityValue": "Sunabeda"
    },
    {
      "id": 1351,
      "CityName": "Bavla",
      "CityValue": "Bavla"
    },
    {
      "id": 1352,
      "CityName": "Barauli",
      "CityValue": "Barauli"
    },
    {
      "id": 1353,
      "CityName": "Punalur",
      "CityValue": "Punalur"
    },
    {
      "id": 1354,
      "CityName": "Tumsar",
      "CityValue": "Tumsar"
    },
    {
      "id": 1355,
      "CityName": "Nelamangala",
      "CityValue": "Nelamangala"
    },
    {
      "id": 1356,
      "CityName": "Paithan",
      "CityValue": "Paithan"
    },
    {
      "id": 1357,
      "CityName": "Shahbad",
      "CityValue": "Shahbad"
    },
    {
      "id": 1358,
      "CityName": "Mandamarri",
      "CityValue": "Mandamarri"
    },
    {
      "id": 1359,
      "CityName": "Pratapgarh",
      "CityValue": "Pratapgarh"
    },
    {
      "id": 1360,
      "CityName": "Abdu Rahiman Nagar",
      "CityValue": "Abdu Rahiman Nagar"
    },
    {
      "id": 1361,
      "CityName": "Sherghati",
      "CityValue": "Sherghati"
    },
    {
      "id": 1362,
      "CityName": "Dhanpuri",
      "CityValue": "Dhanpuri"
    },
    {
      "id": 1363,
      "CityName": "Gudalur",
      "CityValue": "Gudalur"
    },
    {
      "id": 1364,
      "CityName": "Cherthala",
      "CityValue": "Cherthala"
    },
    {
      "id": 1365,
      "CityName": "Kayalpattinam",
      "CityValue": "Kayalpattinam"
    },
    {
      "id": 1366,
      "CityName": "Thangadh",
      "CityValue": "Thangadh"
    },
    {
      "id": 1367,
      "CityName": "Dehgam",
      "CityValue": "Dehgam"
    },
    {
      "id": 1368,
      "CityName": "Jogabani",
      "CityValue": "Jogabani"
    },
    {
      "id": 1369,
      "CityName": "Patti",
      "CityValue": "Patti"
    },
    {
      "id": 1370,
      "CityName": "Mukhani",
      "CityValue": "Mukhani"
    },
    {
      "id": 1371,
      "CityName": "Nalhati",
      "CityValue": "Nalhati"
    },
    {
      "id": 1372,
      "CityName": "Ayyappanthangal",
      "CityValue": "Ayyappanthangal"
    },
    {
      "id": 1373,
      "CityName": "Naila-Janjgir",
      "CityValue": "Naila-Janjgir"
    },
    {
      "id": 1374,
      "CityName": "Kottuvally",
      "CityValue": "Kottuvally"
    },
    {
      "id": 1375,
      "CityName": "Saravanampatti",
      "CityValue": "Saravanampatti"
    },
    {
      "id": 1376,
      "CityName": "Nelliyalam",
      "CityValue": "Nelliyalam"
    },
    {
      "id": 1377,
      "CityName": "Khambhalia",
      "CityValue": "Khambhalia"
    },
    {
      "id": 1378,
      "CityName": "Changanassery",
      "CityValue": "Changanassery"
    },
    {
      "id": 1379,
      "CityName": "Ramachandrapuram",
      "CityValue": "Ramachandrapuram"
    },
    {
      "id": 1380,
      "CityName": "Sojat",
      "CityValue": "Sojat"
    },
    {
      "id": 1381,
      "CityName": "Nainital",
      "CityValue": "Nainital"
    },
    {
      "id": 1382,
      "CityName": "Bandipore",
      "CityValue": "Bandipore"
    },
    {
      "id": 1383,
      "CityName": "Mahe",
      "CityValue": "Mahe"
    },
    {
      "id": 1384,
      "CityName": "Kannamangalam",
      "CityValue": "Kannamangalam"
    },
    {
      "id": 1385,
      "CityName": "Pirayiri",
      "CityValue": "Pirayiri"
    },
    {
      "id": 1386,
      "CityName": "Mihijam",
      "CityValue": "Mihijam"
    },
    {
      "id": 1387,
      "CityName": "Paliya Kalan",
      "CityValue": "Paliya Kalan"
    },
    {
      "id": 1388,
      "CityName": "Aranthangi",
      "CityValue": "Aranthangi"
    },
    {
      "id": 1389,
      "CityName": "Mookondapalli",
      "CityValue": "Mookondapalli"
    },
    {
      "id": 1390,
      "CityName": "Satara",
      "CityValue": "Satara"
    },
    {
      "id": 1391,
      "CityName": "Nowgong",
      "CityValue": "Nowgong"
    },
    {
      "id": 1392,
      "CityName": "Kannad",
      "CityValue": "Kannad"
    },
    {
      "id": 1393,
      "CityName": "Vellakoil",
      "CityValue": "Vellakoil"
    },
    {
      "id": 1394,
      "CityName": "Pakbara",
      "CityValue": "Pakbara"
    },
    {
      "id": 1395,
      "CityName": "Barahiya",
      "CityValue": "Barahiya"
    },
    {
      "id": 1396,
      "CityName": "Madambakkam",
      "CityValue": "Madambakkam"
    },
    {
      "id": 1397,
      "CityName": "Nathdwara",
      "CityValue": "Nathdwara"
    },
    {
      "id": 1398,
      "CityName": "Shamshabad",
      "CityValue": "Shamshabad"
    },
    {
      "id": 1399,
      "CityName": "Nawabganj",
      "CityValue": "Nawabganj"
    },
    {
      "id": 1400,
      "CityName": "Chinnamanur",
      "CityValue": "Chinnamanur"
    },
    {
      "id": 1401,
      "CityName": "Shirur",
      "CityValue": "Shirur"
    },
    {
      "id": 1402,
      "CityName": "Arvi",
      "CityValue": "Arvi"
    },
    {
      "id": 1403,
      "CityName": "Kabnur",
      "CityValue": "Kabnur"
    },
    {
      "id": 1404,
      "CityName": "Jaygaon",
      "CityValue": "Jaygaon"
    },
    {
      "id": 1405,
      "CityName": "Shoranur",
      "CityValue": "Shoranur"
    },
    {
      "id": 1406,
      "CityName": "Dharapadavedu",
      "CityValue": "Dharapadavedu"
    },
    {
      "id": 1407,
      "CityName": "Sachin",
      "CityValue": "Sachin"
    },
    {
      "id": 1408,
      "CityName": "Porsa",
      "CityValue": "Porsa"
    },
    {
      "id": 1409,
      "CityName": "Obra",
      "CityValue": "Obra"
    },
    {
      "id": 1410,
      "CityName": "Narayanpet",
      "CityValue": "Narayanpet"
    },
    {
      "id": 1411,
      "CityName": "Kottamkara",
      "CityValue": "Kottamkara"
    },
    {
      "id": 1412,
      "CityName": "Urapakkam",
      "CityValue": "Urapakkam"
    },
    {
      "id": 1413,
      "CityName": "Bethamcherla",
      "CityValue": "Bethamcherla"
    },
    {
      "id": 1414,
      "CityName": "Libas Pur",
      "CityValue": "Libas Pur"
    },
    {
      "id": 1415,
      "CityName": "Sandur",
      "CityValue": "Sandur"
    },
    {
      "id": 1416,
      "CityName": "Diamond Harbour",
      "CityValue": "Diamond Harbour"
    },
    {
      "id": 1417,
      "CityName": "Limbdi",
      "CityValue": "Limbdi"
    },
    {
      "id": 1418,
      "CityName": "Melur",
      "CityValue": "Melur"
    },
    {
      "id": 1419,
      "CityName": "Kurseong",
      "CityValue": "Kurseong"
    },
    {
      "id": 1420,
      "CityName": "Panachikkad",
      "CityValue": "Panachikkad"
    },
    {
      "id": 1421,
      "CityName": "Bansi",
      "CityValue": "Bansi"
    },
    {
      "id": 1422,
      "CityName": "Vaijapur",
      "CityValue": "Vaijapur"
    },
    {
      "id": 1423,
      "CityName": "Sedam",
      "CityValue": "Sedam"
    },
    {
      "id": 1424,
      "CityName": "Nidadavole",
      "CityValue": "Nidadavole"
    },
    {
      "id": 1425,
      "CityName": "Peddapalle",
      "CityValue": "Peddapalle"
    },
    {
      "id": 1426,
      "CityName": "Memari",
      "CityValue": "Memari"
    },
    {
      "id": 1427,
      "CityName": "Muthanampalayam",
      "CityValue": "Muthanampalayam"
    },
    {
      "id": 1428,
      "CityName": "Dongar Parasia",
      "CityValue": "Dongar Parasia"
    },
    {
      "id": 1429,
      "CityName": "Jillalguda",
      "CityValue": "Jillalguda"
    },
    {
      "id": 1430,
      "CityName": "Radhanpur",
      "CityValue": "Radhanpur"
    },
    {
      "id": 1431,
      "CityName": "Thrikkovilvattom",
      "CityValue": "Thrikkovilvattom"
    },
    {
      "id": 1432,
      "CityName": "Channarayapatna",
      "CityValue": "Channarayapatna"
    },
    {
      "id": 1433,
      "CityName": "Periyakulam",
      "CityValue": "Periyakulam"
    },
    {
      "id": 1434,
      "CityName": "Aurangabad",
      "CityValue": "Aurangabad"
    },
    {
      "id": 1435,
      "CityName": "Talcher",
      "CityValue": "Talcher"
    },
    {
      "id": 1436,
      "CityName": "Bhadra",
      "CityValue": "Bhadra"
    },
    {
      "id": 1437,
      "CityName": "Kannanendal",
      "CityValue": "Kannanendal"
    },
    {
      "id": 1438,
      "CityName": "Sugauli",
      "CityValue": "Sugauli"
    },
    {
      "id": 1439,
      "CityName": "Savanur",
      "CityValue": "Savanur"
    },
    {
      "id": 1440,
      "CityName": "Thudiyalur",
      "CityValue": "Thudiyalur"
    },
    {
      "id": 1441,
      "CityName": "Jalalabad",
      "CityValue": "Jalalabad"
    },
    {
      "id": 1442,
      "CityName": "Soyagaon",
      "CityValue": "Soyagaon"
    },
    {
      "id": 1443,
      "CityName": "Pinjore",
      "CityValue": "Pinjore"
    },
    {
      "id": 1444,
      "CityName": "Manapparai",
      "CityValue": "Manapparai"
    },
    {
      "id": 1445,
      "CityName": "Nannambra",
      "CityValue": "Nannambra"
    },
    {
      "id": 1446,
      "CityName": "Maihar",
      "CityValue": "Maihar"
    },
    {
      "id": 1447,
      "CityName": "Triprangode",
      "CityValue": "Triprangode"
    },
    {
      "id": 1448,
      "CityName": "Wanadongri",
      "CityValue": "Wanadongri"
    },
    {
      "id": 1449,
      "CityName": "Sindgi",
      "CityValue": "Sindgi"
    },
    {
      "id": 1450,
      "CityName": "Nilothi",
      "CityValue": "Nilothi"
    },
    {
      "id": 1451,
      "CityName": "Chelannur",
      "CityValue": "Chelannur"
    },
    {
      "id": 1452,
      "CityName": "Kankuria",
      "CityValue": "Kankuria"
    },
    {
      "id": 1453,
      "CityName": "Bhalki",
      "CityValue": "Bhalki"
    },
    {
      "id": 1454,
      "CityName": "Bodh Gaya",
      "CityValue": "Bodh Gaya"
    },
    {
      "id": 1455,
      "CityName": "Khagaul",
      "CityValue": "Khagaul"
    },
    {
      "id": 1456,
      "CityName": "Kumbalangy",
      "CityValue": "Kumbalangy"
    },
    {
      "id": 1457,
      "CityName": "Cheeka",
      "CityValue": "Cheeka"
    },
    {
      "id": 1458,
      "CityName": "Mohammadabad",
      "CityValue": "Mohammadabad"
    },
    {
      "id": 1459,
      "CityName": "Keelakarai",
      "CityValue": "Keelakarai"
    },
    {
      "id": 1460,
      "CityName": "Mugalivakkam",
      "CityValue": "Mugalivakkam"
    },
    {
      "id": 1461,
      "CityName": "Barpeta",
      "CityValue": "Barpeta"
    },
    {
      "id": 1462,
      "CityName": "Kamal Pur Majra Burari",
      "CityValue": "Kamal Pur Majra Burari"
    },
    {
      "id": 1463,
      "CityName": "Ariankuppam",
      "CityValue": "Ariankuppam"
    },
    {
      "id": 1464,
      "CityName": "Jiwan Pur alias Johri Pur",
      "CityValue": "Jiwan Pur alias Johri Pur"
    },
    {
      "id": 1465,
      "CityName": "Pallipalayam",
      "CityValue": "Pallipalayam"
    },
    {
      "id": 1466,
      "CityName": "Chavara",
      "CityValue": "Chavara"
    },
    {
      "id": 1467,
      "CityName": "Ghatampur",
      "CityValue": "Ghatampur"
    },
    {
      "id": 1468,
      "CityName": "Jhajha",
      "CityValue": "Jhajha"
    },
    {
      "id": 1469,
      "CityName": "Maudaha",
      "CityValue": "Maudaha"
    },
    {
      "id": 1470,
      "CityName": "Saundatti-Yellamma",
      "CityValue": "Saundatti-Yellamma"
    },
    {
      "id": 1471,
      "CityName": "Kudappanakkunnu",
      "CityValue": "Kudappanakkunnu"
    },
    {
      "id": 1472,
      "CityName": "Wadi",
      "CityValue": "Wadi"
    },
    {
      "id": 1473,
      "CityName": "Thottada",
      "CityValue": "Thottada"
    },
    {
      "id": 1474,
      "CityName": "Kondasamudram",
      "CityValue": "Kondasamudram"
    },
    {
      "id": 1475,
      "CityName": "Sohna",
      "CityValue": "Sohna"
    },
    {
      "id": 1476,
      "CityName": "Rafiganj",
      "CityValue": "Rafiganj"
    },
    {
      "id": 1477,
      "CityName": "Kattipparuthi",
      "CityValue": "Kattipparuthi"
    },
    {
      "id": 1478,
      "CityName": "Dibai",
      "CityValue": "Dibai"
    },
    {
      "id": 1479,
      "CityName": "Akluj",
      "CityValue": "Akluj"
    },
    {
      "id": 1480,
      "CityName": "Bahadurganj",
      "CityValue": "Bahadurganj"
    },
    {
      "id": 1481,
      "CityName": "Dasna",
      "CityValue": "Dasna"
    },
    {
      "id": 1482,
      "CityName": "Coonoor",
      "CityValue": "Coonoor"
    },
    {
      "id": 1483,
      "CityName": "Khairthal",
      "CityValue": "Khairthal"
    },
    {
      "id": 1484,
      "CityName": "Thirunindravur",
      "CityValue": "Thirunindravur"
    },
    {
      "id": 1485,
      "CityName": "Manakunnam",
      "CityValue": "Manakunnam"
    },
    {
      "id": 1486,
      "CityName": "Kalliyoor",
      "CityValue": "Kalliyoor"
    },
    {
      "id": 1487,
      "CityName": "Gauribidanur",
      "CityValue": "Gauribidanur"
    },
    {
      "id": 1488,
      "CityName": "Gharaunda",
      "CityValue": "Gharaunda"
    },
    {
      "id": 1489,
      "CityName": "Shirdi",
      "CityValue": "Shirdi"
    },
    {
      "id": 1490,
      "CityName": "Mungeli",
      "CityValue": "Mungeli"
    },
    {
      "id": 1491,
      "CityName": "Chakapara",
      "CityValue": "Chakapara"
    },
    {
      "id": 1492,
      "CityName": "Belpahar",
      "CityValue": "Belpahar"
    },
    {
      "id": 1493,
      "CityName": "Balasinor",
      "CityValue": "Balasinor"
    },
    {
      "id": 1494,
      "CityName": "Dwarka",
      "CityValue": "Dwarka"
    },
    {
      "id": 1495,
      "CityName": "Pen",
      "CityValue": "Pen"
    },
    {
      "id": 1496,
      "CityName": "Indi",
      "CityValue": "Indi"
    },
    {
      "id": 1497,
      "CityName": "Kaman",
      "CityValue": "Kaman"
    },
    {
      "id": 1498,
      "CityName": "Revelganj",
      "CityValue": "Revelganj"
    },
    {
      "id": 1499,
      "CityName": "Jalalabad",
      "CityValue": "Jalalabad"
    },
    {
      "id": 1500,
      "CityName": "Perumbaikad",
      "CityValue": "Perumbaikad"
    },
    {
      "id": 1501,
      "CityName": "Kampli",
      "CityValue": "Kampli"
    },
    {
      "id": 1502,
      "CityName": "Jhalrapatan",
      "CityValue": "Jhalrapatan"
    },
    {
      "id": 1503,
      "CityName": "Bakhri",
      "CityValue": "Bakhri"
    },
    {
      "id": 1504,
      "CityName": "Walwadi",
      "CityValue": "Walwadi"
    },
    {
      "id": 1505,
      "CityName": "Anandpur",
      "CityValue": "Anandpur"
    },
    {
      "id": 1506,
      "CityName": "Ghatshila",
      "CityValue": "Ghatshila"
    },
    {
      "id": 1507,
      "CityName": "Thirumuruganpoondi",
      "CityValue": "Thirumuruganpoondi"
    },
    {
      "id": 1508,
      "CityName": "Pehowa",
      "CityValue": "Pehowa"
    },
    {
      "id": 1509,
      "CityName": "Hindalco Industries Ltd.",
      "CityValue": "Hindalco Industries Ltd."
    },
    {
      "id": 1510,
      "CityName": "Colgong",
      "CityValue": "Colgong"
    },
    {
      "id": 1511,
      "CityName": "Naharlagun",
      "CityValue": "Naharlagun"
    },
    {
      "id": 1512,
      "CityName": "Agar",
      "CityValue": "Agar"
    },
    {
      "id": 1513,
      "CityName": "Bilari",
      "CityValue": "Bilari"
    },
    {
      "id": 1514,
      "CityName": "Bantval",
      "CityValue": "Bantval"
    },
    {
      "id": 1515,
      "CityName": "Niwai",
      "CityValue": "Niwai"
    },
    {
      "id": 1516,
      "CityName": "Shahabad",
      "CityValue": "Shahabad"
    },
    {
      "id": 1517,
      "CityName": "Solan",
      "CityValue": "Solan"
    },
    {
      "id": 1518,
      "CityName": "Bilara",
      "CityValue": "Bilara"
    },
    {
      "id": 1519,
      "CityName": "Rajula",
      "CityValue": "Rajula"
    },
    {
      "id": 1520,
      "CityName": "Kadungalloor",
      "CityValue": "Kadungalloor"
    },
    {
      "id": 1521,
      "CityName": "Gogri Jamalpur",
      "CityValue": "Gogri Jamalpur"
    },
    {
      "id": 1522,
      "CityName": "Dalli-Rajhara",
      "CityValue": "Dalli-Rajhara"
    },
    {
      "id": 1523,
      "CityName": "Nangli",
      "CityValue": "Nangli"
    },
    {
      "id": 1524,
      "CityName": "Ganderbal",
      "CityValue": "Ganderbal"
    },
    {
      "id": 1525,
      "CityName": "Vyara",
      "CityValue": "Vyara"
    },
    {
      "id": 1526,
      "CityName": "Kulathummal",
      "CityValue": "Kulathummal"
    },
    {
      "id": 1527,
      "CityName": "Lalganj",
      "CityValue": "Lalganj"
    },
    {
      "id": 1528,
      "CityName": "Puranpur",
      "CityValue": "Puranpur"
    },
    {
      "id": 1529,
      "CityName": "Noorpur",
      "CityValue": "Noorpur"
    },
    {
      "id": 1530,
      "CityName": "Boisar",
      "CityValue": "Boisar"
    },
    {
      "id": 1531,
      "CityName": "Khair",
      "CityValue": "Khair"
    },
    {
      "id": 1532,
      "CityName": "Othukkungal",
      "CityValue": "Othukkungal"
    },
    {
      "id": 1533,
      "CityName": "Murtijapur",
      "CityValue": "Murtijapur"
    },
    {
      "id": 1534,
      "CityName": "Mamidalapadu",
      "CityValue": "Mamidalapadu"
    },
    {
      "id": 1535,
      "CityName": "Vapi",
      "CityValue": "Vapi"
    },
    {
      "id": 1536,
      "CityName": "Peringathur",
      "CityValue": "Peringathur"
    },
    {
      "id": 1537,
      "CityName": "Rahuri",
      "CityValue": "Rahuri"
    },
    {
      "id": 1538,
      "CityName": "Chikodi",
      "CityValue": "Chikodi"
    },
    {
      "id": 1539,
      "CityName": "Chaklasi",
      "CityValue": "Chaklasi"
    },
    {
      "id": 1540,
      "CityName": "Sanawad",
      "CityValue": "Sanawad"
    },
    {
      "id": 1541,
      "CityName": "Tilda Newra",
      "CityValue": "Tilda Newra"
    },
    {
      "id": 1542,
      "CityName": "Mayyanad",
      "CityValue": "Mayyanad"
    },
    {
      "id": 1543,
      "CityName": "Bahjoi",
      "CityValue": "Bahjoi"
    },
    {
      "id": 1544,
      "CityName": "Kakrala",
      "CityValue": "Kakrala"
    },
    {
      "id": 1545,
      "CityName": "Nadapuram",
      "CityValue": "Nadapuram"
    },
    {
      "id": 1546,
      "CityName": "Dubrajpur",
      "CityValue": "Dubrajpur"
    },
    {
      "id": 1547,
      "CityName": "Kanigiri",
      "CityValue": "Kanigiri"
    },
    {
      "id": 1548,
      "CityName": "Pihani",
      "CityValue": "Pihani"
    },
    {
      "id": 1549,
      "CityName": "Bayana",
      "CityValue": "Bayana"
    },
    {
      "id": 1550,
      "CityName": "Sirohi",
      "CityValue": "Sirohi"
    },
    {
      "id": 1551,
      "CityName": "Satana",
      "CityValue": "Satana"
    },
    {
      "id": 1552,
      "CityName": "Tuensang",
      "CityValue": "Tuensang"
    },
    {
      "id": 1553,
      "CityName": "Thuvakudi",
      "CityValue": "Thuvakudi"
    },
    {
      "id": 1554,
      "CityName": "Sivaganga",
      "CityValue": "Sivaganga"
    },
    {
      "id": 1555,
      "CityName": "Akkalkot",
      "CityValue": "Akkalkot"
    },
    {
      "id": 1556,
      "CityName": "Amadalavalasa",
      "CityValue": "Amadalavalasa"
    },
    {
      "id": 1557,
      "CityName": "Kagal",
      "CityValue": "Kagal"
    },
    {
      "id": 1558,
      "CityName": "Athiyannur",
      "CityValue": "Athiyannur"
    },
    {
      "id": 1559,
      "CityName": "Ameenapur",
      "CityValue": "Ameenapur"
    },
    {
      "id": 1560,
      "CityName": "Pipar City",
      "CityValue": "Pipar City"
    },
    {
      "id": 1561,
      "CityName": "Athirampuzha",
      "CityValue": "Athirampuzha"
    },
    {
      "id": 1562,
      "CityName": "Ghosi",
      "CityValue": "Ghosi"
    },
    {
      "id": 1563,
      "CityName": "Shikarpur",
      "CityValue": "Shikarpur"
    },
    {
      "id": 1564,
      "CityName": "Gill",
      "CityValue": "Gill"
    },
    {
      "id": 1565,
      "CityName": "Karumathampatti",
      "CityValue": "Karumathampatti"
    },
    {
      "id": 1566,
      "CityName": "Zira",
      "CityValue": "Zira"
    },
    {
      "id": 1567,
      "CityName": "Aroor",
      "CityValue": "Aroor"
    },
    {
      "id": 1568,
      "CityName": "Kukra",
      "CityValue": "Kukra"
    },
    {
      "id": 1569,
      "CityName": "Khunti",
      "CityValue": "Khunti"
    },
    {
      "id": 1570,
      "CityName": "Ganaur",
      "CityValue": "Ganaur"
    },
    {
      "id": 1571,
      "CityName": "Bokaro",
      "CityValue": "Bokaro"
    },
    {
      "id": 1572,
      "CityName": "Sarangpur",
      "CityValue": "Sarangpur"
    },
    {
      "id": 1573,
      "CityName": "Thazhuthala",
      "CityValue": "Thazhuthala"
    },
    {
      "id": 1574,
      "CityName": "Bilasipara",
      "CityValue": "Bilasipara"
    },
    {
      "id": 1575,
      "CityName": "Kollancode",
      "CityValue": "Kollancode"
    },
    {
      "id": 1576,
      "CityName": "Jajapur",
      "CityValue": "Jajapur"
    },
    {
      "id": 1577,
      "CityName": "Sonepur",
      "CityValue": "Sonepur"
    },
    {
      "id": 1578,
      "CityName": "Katpadi",
      "CityValue": "Katpadi"
    },
    {
      "id": 1579,
      "CityName": "Partur",
      "CityValue": "Partur"
    },
    {
      "id": 1580,
      "CityName": "Tasgaon",
      "CityValue": "Tasgaon"
    },
    {
      "id": 1581,
      "CityName": "Nongthymmai",
      "CityValue": "Nongthymmai"
    },
    {
      "id": 1582,
      "CityName": "Udaipur",
      "CityValue": "Udaipur"
    },
    {
      "id": 1583,
      "CityName": "Lingsugur",
      "CityValue": "Lingsugur"
    },
    {
      "id": 1584,
      "CityName": "Manali",
      "CityValue": "Manali"
    },
    {
      "id": 1585,
      "CityName": "Lahar",
      "CityValue": "Lahar"
    },
    {
      "id": 1586,
      "CityName": "Giddaluru",
      "CityValue": "Giddaluru"
    },
    {
      "id": 1587,
      "CityName": "Nakodar",
      "CityValue": "Nakodar"
    },
    {
      "id": 1588,
      "CityName": "Sathyamangalam",
      "CityValue": "Sathyamangalam"
    },
    {
      "id": 1589,
      "CityName": "Dalkhola",
      "CityValue": "Dalkhola"
    },
    {
      "id": 1590,
      "CityName": "Baraily",
      "CityValue": "Baraily"
    },
    {
      "id": 1591,
      "CityName": "Sumerpur",
      "CityValue": "Sumerpur"
    },
    {
      "id": 1592,
      "CityName": "Thana Bhawan",
      "CityValue": "Thana Bhawan"
    },
    {
      "id": 1593,
      "CityName": "Neem-Ka-Thana",
      "CityValue": "Neem-Ka-Thana"
    },
    {
      "id": 1594,
      "CityName": "Karamadai",
      "CityValue": "Karamadai"
    },
    {
      "id": 1595,
      "CityName": "Puzhal",
      "CityValue": "Puzhal"
    },
    {
      "id": 1596,
      "CityName": "Kattivakkam",
      "CityValue": "Kattivakkam"
    },
    {
      "id": 1597,
      "CityName": "Vilankurichi",
      "CityValue": "Vilankurichi"
    },
    {
      "id": 1598,
      "CityName": "Yenamalakuduru",
      "CityValue": "Yenamalakuduru"
    },
    {
      "id": 1599,
      "CityName": "Navlock Garden",
      "CityValue": "Navlock Garden"
    },
    {
      "id": 1600,
      "CityName": "Shahbudinpur",
      "CityValue": "Shahbudinpur"
    },
    {
      "id": 1601,
      "CityName": "Jaitu",
      "CityValue": "Jaitu"
    },
    {
      "id": 1602,
      "CityName": "Karaipudur",
      "CityValue": "Karaipudur"
    },
    {
      "id": 1603,
      "CityName": "Neelankarai",
      "CityValue": "Neelankarai"
    },
    {
      "id": 1604,
      "CityName": "Ausa",
      "CityValue": "Ausa"
    },
    {
      "id": 1605,
      "CityName": "Nanjikottai",
      "CityValue": "Nanjikottai"
    },
    {
      "id": 1606,
      "CityName": "Mallawan",
      "CityValue": "Mallawan"
    },
    {
      "id": 1607,
      "CityName": "Kovvur",
      "CityValue": "Kovvur"
    },
    {
      "id": 1608,
      "CityName": "Koottilangadi",
      "CityValue": "Koottilangadi"
    },
    {
      "id": 1609,
      "CityName": "Mapusa",
      "CityValue": "Mapusa"
    },
    {
      "id": 1610,
      "CityName": "Pathri",
      "CityValue": "Pathri"
    },
    {
      "id": 1611,
      "CityName": "Perumanna",
      "CityValue": "Perumanna"
    },
    {
      "id": 1612,
      "CityName": "Bhavani",
      "CityValue": "Bhavani"
    },
    {
      "id": 1613,
      "CityName": "Vetapalem",
      "CityValue": "Vetapalem"
    },
    {
      "id": 1614,
      "CityName": "Chorode",
      "CityValue": "Chorode"
    },
    {
      "id": 1615,
      "CityName": "Ugra Kheri",
      "CityValue": "Ugra Kheri"
    },
    {
      "id": 1616,
      "CityName": "Yawal",
      "CityValue": "Yawal"
    },
    {
      "id": 1617,
      "CityName": "Safidon",
      "CityValue": "Safidon"
    },
    {
      "id": 1618,
      "CityName": "Thrikkadavoor",
      "CityValue": "Thrikkadavoor"
    },
    {
      "id": 1619,
      "CityName": "Varkala",
      "CityValue": "Varkala"
    },
    {
      "id": 1620,
      "CityName": "Digdoh",
      "CityValue": "Digdoh"
    },
    {
      "id": 1621,
      "CityName": "Jalesar",
      "CityValue": "Jalesar"
    },
    {
      "id": 1622,
      "CityName": "Chavakkad",
      "CityValue": "Chavakkad"
    },
    {
      "id": 1623,
      "CityName": "Kothamangalam",
      "CityValue": "Kothamangalam"
    },
    {
      "id": 1624,
      "CityName": "Bhadgaon",
      "CityValue": "Bhadgaon"
    },
    {
      "id": 1625,
      "CityName": "Nilanga",
      "CityValue": "Nilanga"
    },
    {
      "id": 1626,
      "CityName": "Bollaram",
      "CityValue": "Bollaram"
    },
    {
      "id": 1627,
      "CityName": "Dharuhera",
      "CityValue": "Dharuhera"
    },
    {
      "id": 1628,
      "CityName": "Pilibanga",
      "CityValue": "Pilibanga"
    },
    {
      "id": 1629,
      "CityName": "Krishnarajanagara",
      "CityValue": "Krishnarajanagara"
    },
    {
      "id": 1630,
      "CityName": "Islampur",
      "CityValue": "Islampur"
    },
    {
      "id": 1631,
      "CityName": "Tiruvethipuram",
      "CityValue": "Tiruvethipuram"
    },
    {
      "id": 1632,
      "CityName": "Sankarapuram",
      "CityValue": "Sankarapuram"
    },
    {
      "id": 1633,
      "CityName": "Malpura",
      "CityValue": "Malpura"
    },
    {
      "id": 1634,
      "CityName": "Madanriting",
      "CityValue": "Madanriting"
    },
    {
      "id": 1635,
      "CityName": "Dongargarh",
      "CityValue": "Dongargarh"
    },
    {
      "id": 1636,
      "CityName": "Vazhayur",
      "CityValue": "Vazhayur"
    },
    {
      "id": 1637,
      "CityName": "Rawatbhata",
      "CityValue": "Rawatbhata"
    },
    {
      "id": 1638,
      "CityName": "Surandai",
      "CityValue": "Surandai"
    },
    {
      "id": 1639,
      "CityName": "Parola",
      "CityValue": "Parola"
    },
    {
      "id": 1640,
      "CityName": "Andipalayam",
      "CityValue": "Andipalayam"
    },
    {
      "id": 1641,
      "CityName": "Taki",
      "CityValue": "Taki"
    },
    {
      "id": 1642,
      "CityName": "Morshi",
      "CityValue": "Morshi"
    },
    {
      "id": 1643,
      "CityName": "Bidasar",
      "CityValue": "Bidasar"
    },
    {
      "id": 1644,
      "CityName": "Lunawada",
      "CityValue": "Lunawada"
    },
    {
      "id": 1645,
      "CityName": "Wai",
      "CityValue": "Wai"
    },
    {
      "id": 1646,
      "CityName": "Ashta",
      "CityValue": "Ashta"
    },
    {
      "id": 1647,
      "CityName": "Neduva",
      "CityValue": "Neduva"
    },
    {
      "id": 1648,
      "CityName": "Fatehpur",
      "CityValue": "Fatehpur"
    },
    {
      "id": 1649,
      "CityName": "Phulabani",
      "CityValue": "Phulabani"
    },
    {
      "id": 1650,
      "CityName": "Tilpat",
      "CityValue": "Tilpat"
    },
    {
      "id": 1651,
      "CityName": "Mahalingpur",
      "CityValue": "Mahalingpur"
    },
    {
      "id": 1652,
      "CityName": "Nabarangapur",
      "CityValue": "Nabarangapur"
    },
    {
      "id": 1653,
      "CityName": "Rawatsar",
      "CityValue": "Rawatsar"
    },
    {
      "id": 1654,
      "CityName": "Pallapatti",
      "CityValue": "Pallapatti"
    },
    {
      "id": 1655,
      "CityName": "Pujali",
      "CityValue": "Pujali"
    },
    {
      "id": 1656,
      "CityName": "Kheri",
      "CityValue": "Kheri"
    },
    {
      "id": 1657,
      "CityName": "Sonbhadra",
      "CityValue": "Sonbhadra"
    },
    {
      "id": 1658,
      "CityName": "Jhabua",
      "CityValue": "Jhabua"
    },
    {
      "id": 1659,
      "CityName": "Ramanattukara",
      "CityValue": "Ramanattukara"
    },
    {
      "id": 1660,
      "CityName": "Vijayapura",
      "CityValue": "Vijayapura"
    },
    {
      "id": 1661,
      "CityName": "Karamsad",
      "CityValue": "Karamsad"
    },
    {
      "id": 1662,
      "CityName": "Taherpur",
      "CityValue": "Taherpur"
    },
    {
      "id": 1663,
      "CityName": "Parappur",
      "CityValue": "Parappur"
    },
    {
      "id": 1664,
      "CityName": "Ichchapuram",
      "CityValue": "Ichchapuram"
    },
    {
      "id": 1665,
      "CityName": "Malayinkeezhu",
      "CityValue": "Malayinkeezhu"
    },
    {
      "id": 1666,
      "CityName": "Talakkad",
      "CityValue": "Talakkad"
    },
    {
      "id": 1667,
      "CityName": "Lanka",
      "CityValue": "Lanka"
    },
    {
      "id": 1668,
      "CityName": "Sangariya",
      "CityValue": "Sangariya"
    },
    {
      "id": 1669,
      "CityName": "Chunar",
      "CityValue": "Chunar"
    },
    {
      "id": 1670,
      "CityName": "Chhabra",
      "CityValue": "Chhabra"
    },
    {
      "id": 1671,
      "CityName": "Piro",
      "CityValue": "Piro"
    },
    {
      "id": 1672,
      "CityName": "Mokokchung",
      "CityValue": "Mokokchung"
    },
    {
      "id": 1673,
      "CityName": "Badnagar",
      "CityValue": "Badnagar"
    },
    {
      "id": 1674,
      "CityName": "Peerkankaranai",
      "CityValue": "Peerkankaranai"
    },
    {
      "id": 1675,
      "CityName": "Ellenabad",
      "CityValue": "Ellenabad"
    },
    {
      "id": 1676,
      "CityName": "Raipur",
      "CityValue": "Raipur"
    },
    {
      "id": 1677,
      "CityName": "Sarwat",
      "CityValue": "Sarwat"
    },
    {
      "id": 1678,
      "CityName": "Pattamundai",
      "CityValue": "Pattamundai"
    },
    {
      "id": 1679,
      "CityName": "Lakshmeshwar",
      "CityValue": "Lakshmeshwar"
    },
    {
      "id": 1680,
      "CityName": "Kodaikanal",
      "CityValue": "Kodaikanal"
    },
    {
      "id": 1681,
      "CityName": "Usilampatti",
      "CityValue": "Usilampatti"
    },
    {
      "id": 1682,
      "CityName": "Malavalli",
      "CityValue": "Malavalli"
    },
    {
      "id": 1683,
      "CityName": "Shikarpur",
      "CityValue": "Shikarpur"
    },
    {
      "id": 1684,
      "CityName": "Bamor",
      "CityValue": "Bamor"
    },
    {
      "id": 1685,
      "CityName": "Rudrapur",
      "CityValue": "Rudrapur"
    },
    {
      "id": 1686,
      "CityName": "Umarga",
      "CityValue": "Umarga"
    },
    {
      "id": 1687,
      "CityName": "Maharajganj",
      "CityValue": "Maharajganj"
    },
    {
      "id": 1688,
      "CityName": "Bindki",
      "CityValue": "Bindki"
    },
    {
      "id": 1689,
      "CityName": "Brahmapuri",
      "CityValue": "Brahmapuri"
    },
    {
      "id": 1690,
      "CityName": "Kadodara",
      "CityValue": "Kadodara"
    },
    {
      "id": 1691,
      "CityName": "Purna",
      "CityValue": "Purna"
    },
    {
      "id": 1692,
      "CityName": "Mannarkad-I",
      "CityValue": "Mannarkad-I"
    },
    {
      "id": 1693,
      "CityName": "Nargund",
      "CityValue": "Nargund"
    },
    {
      "id": 1694,
      "CityName": "Risod",
      "CityValue": "Risod"
    },
    {
      "id": 1695,
      "CityName": "Kondli",
      "CityValue": "Kondli"
    },
    {
      "id": 1696,
      "CityName": "Uravakonda",
      "CityValue": "Uravakonda"
    },
    {
      "id": 1697,
      "CityName": "Kumhari",
      "CityValue": "Kumhari"
    },
    {
      "id": 1698,
      "CityName": "Lalsot",
      "CityValue": "Lalsot"
    },
    {
      "id": 1699,
      "CityName": "Mahidpur",
      "CityValue": "Mahidpur"
    },
    {
      "id": 1700,
      "CityName": "Sampla",
      "CityValue": "Sampla"
    },
    {
      "id": 1701,
      "CityName": "Jamkhed",
      "CityValue": "Jamkhed"
    },
    {
      "id": 1702,
      "CityName": "Malkapur",
      "CityValue": "Malkapur"
    },
    {
      "id": 1703,
      "CityName": "Attingal",
      "CityValue": "Attingal"
    },
    {
      "id": 1704,
      "CityName": "Palavakkam",
      "CityValue": "Palavakkam"
    },
    {
      "id": 1705,
      "CityName": "Mehmedabad",
      "CityValue": "Mehmedabad"
    },
    {
      "id": 1706,
      "CityName": "Khaira",
      "CityValue": "Khaira"
    },
    {
      "id": 1707,
      "CityName": "Gharonda Neemka Bangar alias Patpar Ganj",
      "CityValue": "Gharonda Neemka Bangar alias Patpar Ganj"
    },
    {
      "id": 1708,
      "CityName": "Salaya",
      "CityValue": "Salaya"
    },
    {
      "id": 1709,
      "CityName": "Methala",
      "CityValue": "Methala"
    },
    {
      "id": 1710,
      "CityName": "Muddebihal",
      "CityValue": "Muddebihal"
    },
    {
      "id": 1711,
      "CityName": "Hojai",
      "CityValue": "Hojai"
    },
    {
      "id": 1712,
      "CityName": "Rairangpur",
      "CityValue": "Rairangpur"
    },
    {
      "id": 1713,
      "CityName": "Vilangudi",
      "CityValue": "Vilangudi"
    },
    {
      "id": 1714,
      "CityName": "Tarsadi",
      "CityValue": "Tarsadi"
    },
    {
      "id": 1715,
      "CityName": "Nangli Sakrawati",
      "CityValue": "Nangli Sakrawati"
    },
    {
      "id": 1716,
      "CityName": "Sangole",
      "CityValue": "Sangole"
    },
    {
      "id": 1717,
      "CityName": "Sangaria",
      "CityValue": "Sangaria"
    },
    {
      "id": 1718,
      "CityName": "Shahpura",
      "CityValue": "Shahpura"
    },
    {
      "id": 1719,
      "CityName": "Nattakam",
      "CityValue": "Nattakam"
    },
    {
      "id": 1720,
      "CityName": "Titlagarh",
      "CityValue": "Titlagarh"
    },
    {
      "id": 1721,
      "CityName": "Bagru",
      "CityValue": "Bagru"
    },
    {
      "id": 1722,
      "CityName": "Hisua",
      "CityValue": "Hisua"
    },
    {
      "id": 1723,
      "CityName": "Sahjanwan",
      "CityValue": "Sahjanwan"
    },
    {
      "id": 1724,
      "CityName": "Kurali",
      "CityValue": "Kurali"
    },
    {
      "id": 1725,
      "CityName": "Daryapur Banosa",
      "CityValue": "Daryapur Banosa"
    },
    {
      "id": 1726,
      "CityName": "Kuruvattur",
      "CityValue": "Kuruvattur"
    },
    {
      "id": 1727,
      "CityName": "Alandi",
      "CityValue": "Alandi"
    },
    {
      "id": 1728,
      "CityName": "Peruvallur",
      "CityValue": "Peruvallur"
    },
    {
      "id": 1729,
      "CityName": "Erattupetta",
      "CityValue": "Erattupetta"
    },
    {
      "id": 1730,
      "CityName": "Hamirpur",
      "CityValue": "Hamirpur"
    },
    {
      "id": 1731,
      "CityName": "Sanchore",
      "CityValue": "Sanchore"
    },
    {
      "id": 1732,
      "CityName": "Sikka",
      "CityValue": "Sikka"
    },
    {
      "id": 1733,
      "CityName": "Zuzuvadi",
      "CityValue": "Zuzuvadi"
    },
    {
      "id": 1734,
      "CityName": "Kansad",
      "CityValue": "Kansad"
    },
    {
      "id": 1735,
      "CityName": "Gaura Barhaj",
      "CityValue": "Gaura Barhaj"
    },
    {
      "id": 1736,
      "CityName": "Panipat Taraf Rajputan",
      "CityValue": "Panipat Taraf Rajputan"
    },
    {
      "id": 1737,
      "CityName": "Mhowgaon",
      "CityValue": "Mhowgaon"
    },
    {
      "id": 1738,
      "CityName": "Guskara",
      "CityValue": "Guskara"
    },
    {
      "id": 1739,
      "CityName": "Ambasamudram",
      "CityValue": "Ambasamudram"
    },
    {
      "id": 1740,
      "CityName": "Chelambra",
      "CityValue": "Chelambra"
    },
    {
      "id": 1741,
      "CityName": "Zaidpur",
      "CityValue": "Zaidpur"
    },
    {
      "id": 1742,
      "CityName": "Babar Pur",
      "CityValue": "Babar Pur"
    },
    {
      "id": 1743,
      "CityName": "Venganoor",
      "CityValue": "Venganoor"
    },
    {
      "id": 1744,
      "CityName": "Vilappil",
      "CityValue": "Vilappil"
    },
    {
      "id": 1745,
      "CityName": "Khacharod",
      "CityValue": "Khacharod"
    },
    {
      "id": 1746,
      "CityName": "Uchgaon",
      "CityValue": "Uchgaon"
    },
    {
      "id": 1747,
      "CityName": "Dinhata",
      "CityValue": "Dinhata"
    },
    {
      "id": 1748,
      "CityName": "Khaga",
      "CityValue": "Khaga"
    },
    {
      "id": 1749,
      "CityName": "Rajakhera",
      "CityValue": "Rajakhera"
    },
    {
      "id": 1750,
      "CityName": "Kopaganj",
      "CityValue": "Kopaganj"
    },
    {
      "id": 1751,
      "CityName": "Eramalloor",
      "CityValue": "Eramalloor"
    },
    {
      "id": 1752,
      "CityName": "Shamsabad",
      "CityValue": "Shamsabad"
    },
    {
      "id": 1753,
      "CityName": "Kottakuppam",
      "CityValue": "Kottakuppam"
    },
    {
      "id": 1754,
      "CityName": "Tarikere",
      "CityValue": "Tarikere"
    },
    {
      "id": 1755,
      "CityName": "Pathanamthitta",
      "CityValue": "Pathanamthitta"
    },
    {
      "id": 1756,
      "CityName": "Bidhuna",
      "CityValue": "Bidhuna"
    },
    {
      "id": 1757,
      "CityName": "Nawapur",
      "CityValue": "Nawapur"
    },
    {
      "id": 1758,
      "CityName": "Halvad",
      "CityValue": "Halvad"
    },
    {
      "id": 1759,
      "CityName": "Jhadeshwar",
      "CityValue": "Jhadeshwar"
    },
    {
      "id": 1760,
      "CityName": "Begamganj",
      "CityValue": "Begamganj"
    },
    {
      "id": 1761,
      "CityName": "Marancheri",
      "CityValue": "Marancheri"
    },
    {
      "id": 1762,
      "CityName": "Elampalloor",
      "CityValue": "Elampalloor"
    },
    {
      "id": 1763,
      "CityName": "Kaipamangalam",
      "CityValue": "Kaipamangalam"
    },
    {
      "id": 1764,
      "CityName": "Mharal Bk",
      "CityValue": "Mharal Bk"
    },
    {
      "id": 1765,
      "CityName": "Umaria",
      "CityValue": "Umaria"
    },
    {
      "id": 1766,
      "CityName": "Bhokar",
      "CityValue": "Bhokar"
    },
    {
      "id": 1767,
      "CityName": "Naugawan Sadat",
      "CityValue": "Naugawan Sadat"
    },
    {
      "id": 1768,
      "CityName": "Champhai",
      "CityValue": "Champhai"
    },
    {
      "id": 1769,
      "CityName": "Muruganpalayam",
      "CityValue": "Muruganpalayam"
    },
    {
      "id": 1770,
      "CityName": "Vengola",
      "CityValue": "Vengola"
    },
    {
      "id": 1771,
      "CityName": "Georai",
      "CityValue": "Georai"
    },
    {
      "id": 1772,
      "CityName": "Paravoor",
      "CityValue": "Paravoor"
    },
    {
      "id": 1773,
      "CityName": "Dharangaon",
      "CityValue": "Dharangaon"
    },
    {
      "id": 1774,
      "CityName": "Yerraguntla",
      "CityValue": "Yerraguntla"
    },
    {
      "id": 1775,
      "CityName": "Chaksu",
      "CityValue": "Chaksu"
    },
    {
      "id": 1776,
      "CityName": "Sirkali",
      "CityValue": "Sirkali"
    },
    {
      "id": 1777,
      "CityName": "Avilala",
      "CityValue": "Avilala"
    },
    {
      "id": 1778,
      "CityName": "Madayi",
      "CityValue": "Madayi"
    },
    {
      "id": 1779,
      "CityName": "Savner",
      "CityValue": "Savner"
    },
    {
      "id": 1780,
      "CityName": "Kumta",
      "CityValue": "Kumta"
    },
    {
      "id": 1781,
      "CityName": "Dighwara",
      "CityValue": "Dighwara"
    },
    {
      "id": 1782,
      "CityName": "Katra",
      "CityValue": "Katra"
    },
    {
      "id": 1783,
      "CityName": "Kunigal",
      "CityValue": "Kunigal"
    },
    {
      "id": 1784,
      "CityName": "Manamadurai",
      "CityValue": "Manamadurai"
    },
    {
      "id": 1785,
      "CityName": "Hudkeshwar bk.",
      "CityValue": "Hudkeshwar bk."
    },
    {
      "id": 1786,
      "CityName": "Nautanwa",
      "CityValue": "Nautanwa"
    },
    {
      "id": 1787,
      "CityName": "Jewar",
      "CityValue": "Jewar"
    },
    {
      "id": 1788,
      "CityName": "Chhota Gobindpur",
      "CityValue": "Chhota Gobindpur"
    },
    {
      "id": 1789,
      "CityName": "Pundri",
      "CityValue": "Pundri"
    },
    {
      "id": 1790,
      "CityName": "Perinad",
      "CityValue": "Perinad"
    },
    {
      "id": 1791,
      "CityName": "Chittaranjan",
      "CityValue": "Chittaranjan"
    },
    {
      "id": 1792,
      "CityName": "Kaimganj",
      "CityValue": "Kaimganj"
    },
    {
      "id": 1793,
      "CityName": "Basavana Bagevadi",
      "CityValue": "Basavana Bagevadi"
    },
    {
      "id": 1794,
      "CityName": "Alamcode",
      "CityValue": "Alamcode"
    },
    {
      "id": 1795,
      "CityName": "Garhakota",
      "CityValue": "Garhakota"
    },
    {
      "id": 1796,
      "CityName": "Taranagar",
      "CityValue": "Taranagar"
    },
    {
      "id": 1797,
      "CityName": "Gariadhar",
      "CityValue": "Gariadhar"
    },
    {
      "id": 1798,
      "CityName": "Puzhathi",
      "CityValue": "Puzhathi"
    },
    {
      "id": 1799,
      "CityName": "Ponneri",
      "CityValue": "Ponneri"
    },
    {
      "id": 1800,
      "CityName": "Warisaliganj",
      "CityValue": "Warisaliganj"
    },
    {
      "id": 1801,
      "CityName": "Kalka",
      "CityValue": "Kalka"
    },
    {
      "id": 1802,
      "CityName": "Rajauri",
      "CityValue": "Rajauri"
    },
    {
      "id": 1803,
      "CityName": "Vemulawada",
      "CityValue": "Vemulawada"
    },
    {
      "id": 1804,
      "CityName": "Pappinisseri",
      "CityValue": "Pappinisseri"
    },
    {
      "id": 1805,
      "CityName": "Chemancheri",
      "CityValue": "Chemancheri"
    },
    {
      "id": 1806,
      "CityName": "Kadur",
      "CityValue": "Kadur"
    },
    {
      "id": 1807,
      "CityName": "Remuna",
      "CityValue": "Remuna"
    },
    {
      "id": 1808,
      "CityName": "Malkangiri",
      "CityValue": "Malkangiri"
    },
    {
      "id": 1809,
      "CityName": "Biramitrapur",
      "CityValue": "Biramitrapur"
    },
    {
      "id": 1810,
      "CityName": "Sankeshwar",
      "CityValue": "Sankeshwar"
    },
    {
      "id": 1811,
      "CityName": "Dharmabad",
      "CityValue": "Dharmabad"
    },
    {
      "id": 1812,
      "CityName": "Eramala",
      "CityValue": "Eramala"
    },
    {
      "id": 1813,
      "CityName": "Villiappally",
      "CityValue": "Villiappally"
    },
    {
      "id": 1814,
      "CityName": "Dindar Pur",
      "CityValue": "Dindar Pur"
    },
    {
      "id": 1815,
      "CityName": "Suar",
      "CityValue": "Suar"
    },
    {
      "id": 1816,
      "CityName": "Nolambur",
      "CityValue": "Nolambur"
    },
    {
      "id": 1817,
      "CityName": "Bagasara",
      "CityValue": "Bagasara"
    },
    {
      "id": 1818,
      "CityName": "Chanderi",
      "CityValue": "Chanderi"
    },
    {
      "id": 1819,
      "CityName": "Antah",
      "CityValue": "Antah"
    },
    {
      "id": 1820,
      "CityName": "Basudebpur",
      "CityValue": "Basudebpur"
    },
    {
      "id": 1821,
      "CityName": "Hailakandi",
      "CityValue": "Hailakandi"
    },
    {
      "id": 1822,
      "CityName": "Ramgarh",
      "CityValue": "Ramgarh"
    },
    {
      "id": 1823,
      "CityName": "Karala",
      "CityValue": "Karala"
    },
    {
      "id": 1824,
      "CityName": "Rahatgarh",
      "CityValue": "Rahatgarh"
    },
    {
      "id": 1825,
      "CityName": "Amilo",
      "CityValue": "Amilo"
    },
    {
      "id": 1826,
      "CityName": "Bhojpur Dharampur",
      "CityValue": "Bhojpur Dharampur"
    },
    {
      "id": 1827,
      "CityName": "Dhamnod",
      "CityValue": "Dhamnod"
    },
    {
      "id": 1828,
      "CityName": "Kondapalle",
      "CityValue": "Kondapalle"
    },
    {
      "id": 1829,
      "CityName": "Sasvad",
      "CityValue": "Sasvad"
    },
    {
      "id": 1830,
      "CityName": "Varangaon",
      "CityValue": "Varangaon"
    },
    {
      "id": 1831,
      "CityName": "Fatehpur Sikri",
      "CityValue": "Fatehpur Sikri"
    },
    {
      "id": 1832,
      "CityName": "Vedaranyam",
      "CityValue": "Vedaranyam"
    },
    {
      "id": 1833,
      "CityName": "Kokrajhar",
      "CityValue": "Kokrajhar"
    },
    {
      "id": 1834,
      "CityName": "Zamania",
      "CityValue": "Zamania"
    },
    {
      "id": 1835,
      "CityName": "Kotdwara",
      "CityValue": "Kotdwara"
    },
    {
      "id": 1836,
      "CityName": "Ayappakkam",
      "CityValue": "Ayappakkam"
    },
    {
      "id": 1837,
      "CityName": "Puraini",
      "CityValue": "Puraini"
    },
    {
      "id": 1838,
      "CityName": "Edaganasalai",
      "CityValue": "Edaganasalai"
    },
    {
      "id": 1839,
      "CityName": "Samdhan",
      "CityValue": "Samdhan"
    },
    {
      "id": 1840,
      "CityName": "Lumding",
      "CityValue": "Lumding"
    },
    {
      "id": 1841,
      "CityName": "Kangeyam",
      "CityValue": "Kangeyam"
    },
    {
      "id": 1842,
      "CityName": "Jayankondam",
      "CityValue": "Jayankondam"
    },
    {
      "id": 1843,
      "CityName": "Vijapur",
      "CityValue": "Vijapur"
    },
    {
      "id": 1844,
      "CityName": "Tharamangalam",
      "CityValue": "Tharamangalam"
    },
    {
      "id": 1845,
      "CityName": "Bisauli",
      "CityValue": "Bisauli"
    },
    {
      "id": 1846,
      "CityName": "Soro",
      "CityValue": "Soro"
    },
    {
      "id": 1847,
      "CityName": "Barpeta Road",
      "CityValue": "Barpeta Road"
    },
    {
      "id": 1848,
      "CityName": "Matla",
      "CityValue": "Matla"
    },
    {
      "id": 1849,
      "CityName": "Sitarganj",
      "CityValue": "Sitarganj"
    },
    {
      "id": 1850,
      "CityName": "Mamun",
      "CityValue": "Mamun"
    },
    {
      "id": 1851,
      "CityName": "Mukerian",
      "CityValue": "Mukerian"
    },
    {
      "id": 1852,
      "CityName": "Multai",
      "CityValue": "Multai"
    },
    {
      "id": 1853,
      "CityName": "Berasia",
      "CityValue": "Berasia"
    },
    {
      "id": 1854,
      "CityName": "Madhapar",
      "CityValue": "Madhapar"
    },
    {
      "id": 1855,
      "CityName": "Malajkhand",
      "CityValue": "Malajkhand"
    },
    {
      "id": 1856,
      "CityName": "Narsinghgarh",
      "CityValue": "Narsinghgarh"
    },
    {
      "id": 1857,
      "CityName": "Ambad",
      "CityValue": "Ambad"
    },
    {
      "id": 1858,
      "CityName": "Tuljapur",
      "CityValue": "Tuljapur"
    },
    {
      "id": 1859,
      "CityName": "Talikota",
      "CityValue": "Talikota"
    },
    {
      "id": 1860,
      "CityName": "Almora",
      "CityValue": "Almora"
    },
    {
      "id": 1861,
      "CityName": "Dera Bassi",
      "CityValue": "Dera Bassi"
    },
    {
      "id": 1862,
      "CityName": "Bijuri",
      "CityValue": "Bijuri"
    },
    {
      "id": 1863,
      "CityName": "Jagdishpur",
      "CityValue": "Jagdishpur"
    },
    {
      "id": 1864,
      "CityName": "Uran",
      "CityValue": "Uran"
    },
    {
      "id": 1865,
      "CityName": "Deulgaon Raja",
      "CityValue": "Deulgaon Raja"
    },
    {
      "id": 1866,
      "CityName": "Vijainagar",
      "CityValue": "Vijainagar"
    },
    {
      "id": 1867,
      "CityName": "Elayavoor",
      "CityValue": "Elayavoor"
    },
    {
      "id": 1868,
      "CityName": "Tifra",
      "CityValue": "Tifra"
    },
    {
      "id": 1869,
      "CityName": "Utraula",
      "CityValue": "Utraula"
    },
    {
      "id": 1870,
      "CityName": "Manendragarh",
      "CityValue": "Manendragarh"
    },
    {
      "id": 1871,
      "CityName": "Jagatsinghapur",
      "CityValue": "Jagatsinghapur"
    },
    {
      "id": 1872,
      "CityName": "Hatta",
      "CityValue": "Hatta"
    },
    {
      "id": 1873,
      "CityName": "Jhansi",
      "CityValue": "Jhansi"
    },
    {
      "id": 1874,
      "CityName": "Punahana",
      "CityValue": "Punahana"
    },
    {
      "id": 1875,
      "CityName": "Uttar Satali",
      "CityValue": "Uttar Satali"
    },
    {
      "id": 1876,
      "CityName": "Dhandhuka",
      "CityValue": "Dhandhuka"
    },
    {
      "id": 1877,
      "CityName": "Rajpipla",
      "CityValue": "Rajpipla"
    },
    {
      "id": 1878,
      "CityName": "Thenhippalam",
      "CityValue": "Thenhippalam"
    },
    {
      "id": 1879,
      "CityName": "Jhanjharpur",
      "CityValue": "Jhanjharpur"
    },
    {
      "id": 1880,
      "CityName": "Umreth",
      "CityValue": "Umreth"
    },
    {
      "id": 1881,
      "CityName": "Barel",
      "CityValue": "Barel"
    },
    {
      "id": 1882,
      "CityName": "Kaij",
      "CityValue": "Kaij"
    },
    {
      "id": 1883,
      "CityName": "Kundli",
      "CityValue": "Kundli"
    },
    {
      "id": 1884,
      "CityName": "Kalol",
      "CityValue": "Kalol"
    },
    {
      "id": 1885,
      "CityName": "Chockli",
      "CityValue": "Chockli"
    },
    {
      "id": 1886,
      "CityName": "Gajendragarh",
      "CityValue": "Gajendragarh"
    },
    {
      "id": 1887,
      "CityName": "Marigaon",
      "CityValue": "Marigaon"
    },
    {
      "id": 1888,
      "CityName": "Kalyandurg",
      "CityValue": "Kalyandurg"
    },
    {
      "id": 1889,
      "CityName": "Ajanur",
      "CityValue": "Ajanur"
    },
    {
      "id": 1890,
      "CityName": "Badepalle",
      "CityValue": "Badepalle"
    },
    {
      "id": 1891,
      "CityName": "Dhanera",
      "CityValue": "Dhanera"
    },
    {
      "id": 1892,
      "CityName": "Maur",
      "CityValue": "Maur"
    },
    {
      "id": 1893,
      "CityName": "Manwath",
      "CityValue": "Manwath"
    },
    {
      "id": 1894,
      "CityName": "Tirukalukundram",
      "CityValue": "Tirukalukundram"
    },
    {
      "id": 1895,
      "CityName": "Ibrahimpatnam",
      "CityValue": "Ibrahimpatnam"
    },
    {
      "id": 1896,
      "CityName": "Bachhraon",
      "CityValue": "Bachhraon"
    },
    {
      "id": 1897,
      "CityName": "Ramdurg",
      "CityValue": "Ramdurg"
    },
    {
      "id": 1898,
      "CityName": "Chinna Anuppanadi",
      "CityValue": "Chinna Anuppanadi"
    },
    {
      "id": 1899,
      "CityName": "Karanje Tarf Satara",
      "CityValue": "Karanje Tarf Satara"
    },
    {
      "id": 1900,
      "CityName": "Parassala",
      "CityValue": "Parassala"
    },
    {
      "id": 1901,
      "CityName": "Pakri Dayal",
      "CityValue": "Pakri Dayal"
    },
    {
      "id": 1902,
      "CityName": "Kakching",
      "CityValue": "Kakching"
    },
    {
      "id": 1903,
      "CityName": "Behror",
      "CityValue": "Behror"
    },
    {
      "id": 1904,
      "CityName": "Kharagpur",
      "CityValue": "Kharagpur"
    },
    {
      "id": 1905,
      "CityName": "Narsipatnam",
      "CityValue": "Narsipatnam"
    },
    {
      "id": 1906,
      "CityName": "Shrigonda",
      "CityValue": "Shrigonda"
    },
    {
      "id": 1907,
      "CityName": "Islamnagar",
      "CityValue": "Islamnagar"
    },
    {
      "id": 1908,
      "CityName": "Banda",
      "CityValue": "Banda"
    },
    {
      "id": 1909,
      "CityName": "Jalalpur",
      "CityValue": "Jalalpur"
    },
    {
      "id": 1910,
      "CityName": "Basni Belima",
      "CityValue": "Basni Belima"
    },
    {
      "id": 1911,
      "CityName": "Allapuram",
      "CityValue": "Allapuram"
    },
    {
      "id": 1912,
      "CityName": "Ghugus",
      "CityValue": "Ghugus"
    },
    {
      "id": 1913,
      "CityName": "Pandharkaoda",
      "CityValue": "Pandharkaoda"
    },
    {
      "id": 1914,
      "CityName": "Kaptanganj",
      "CityValue": "Kaptanganj"
    },
    {
      "id": 1915,
      "CityName": "Chitapur",
      "CityValue": "Chitapur"
    },
    {
      "id": 1916,
      "CityName": "Vilavoorkkal",
      "CityValue": "Vilavoorkkal"
    },
    {
      "id": 1917,
      "CityName": "Narsampet",
      "CityValue": "Narsampet"
    },
    {
      "id": 1918,
      "CityName": "Sathupalle",
      "CityValue": "Sathupalle"
    },
    {
      "id": 1919,
      "CityName": "Mannanchery",
      "CityValue": "Mannanchery"
    },
    {
      "id": 1920,
      "CityName": "Podili",
      "CityValue": "Podili"
    },
    {
      "id": 1921,
      "CityName": "Jamtara",
      "CityValue": "Jamtara"
    },
    {
      "id": 1922,
      "CityName": "Thrikkodithanam",
      "CityValue": "Thrikkodithanam"
    },
    {
      "id": 1923,
      "CityName": "Aimanam",
      "CityValue": "Aimanam"
    },
    {
      "id": 1924,
      "CityName": "Puthenvelikkara",
      "CityValue": "Puthenvelikkara"
    },
    {
      "id": 1925,
      "CityName": "Mon",
      "CityValue": "Mon"
    },
    {
      "id": 1926,
      "CityName": "Badami",
      "CityValue": "Badami"
    },
    {
      "id": 1927,
      "CityName": "Tiruchendur",
      "CityValue": "Tiruchendur"
    },
    {
      "id": 1928,
      "CityName": "Madikeri",
      "CityValue": "Madikeri"
    },
    {
      "id": 1929,
      "CityName": "Edappal",
      "CityValue": "Edappal"
    },
    {
      "id": 1930,
      "CityName": "Rajauli",
      "CityValue": "Rajauli"
    },
    {
      "id": 1931,
      "CityName": "Sholingur",
      "CityValue": "Sholingur"
    },
    {
      "id": 1932,
      "CityName": "Avanashi",
      "CityValue": "Avanashi"
    },
    {
      "id": 1933,
      "CityName": "Gunupur",
      "CityValue": "Gunupur"
    },
    {
      "id": 1934,
      "CityName": "Wokha",
      "CityValue": "Wokha"
    },
    {
      "id": 1935,
      "CityName": "Banmankhi Bazar",
      "CityValue": "Banmankhi Bazar"
    },
    {
      "id": 1936,
      "CityName": "Oddanchatram",
      "CityValue": "Oddanchatram"
    },
    {
      "id": 1937,
      "CityName": "Thuraiyur",
      "CityValue": "Thuraiyur"
    },
    {
      "id": 1938,
      "CityName": "Manawar",
      "CityValue": "Manawar"
    },
    {
      "id": 1939,
      "CityName": "Feroke",
      "CityValue": "Feroke"
    },
    {
      "id": 1940,
      "CityName": "Maduranthakam",
      "CityValue": "Maduranthakam"
    },
    {
      "id": 1941,
      "CityName": "Dhanaura",
      "CityValue": "Dhanaura"
    },
    {
      "id": 1942,
      "CityName": "Milak",
      "CityValue": "Milak"
    },
    {
      "id": 1943,
      "CityName": "Uthamapalayam",
      "CityValue": "Uthamapalayam"
    },
    {
      "id": 1944,
      "CityName": "Kasba",
      "CityValue": "Kasba"
    },
    {
      "id": 1945,
      "CityName": "Marhaura",
      "CityValue": "Marhaura"
    },
    {
      "id": 1946,
      "CityName": "Pulgaon",
      "CityValue": "Pulgaon"
    },
    {
      "id": 1947,
      "CityName": "Sirsaganj",
      "CityValue": "Sirsaganj"
    },
    {
      "id": 1948,
      "CityName": "Angamaly",
      "CityValue": "Angamaly"
    },
    {
      "id": 1949,
      "CityName": "Kundarki",
      "CityValue": "Kundarki"
    },
    {
      "id": 1950,
      "CityName": "Bhogaon",
      "CityValue": "Bhogaon"
    },
    {
      "id": 1951,
      "CityName": "Hussainabad",
      "CityValue": "Hussainabad"
    },
    {
      "id": 1952,
      "CityName": "Chumukedima",
      "CityValue": "Chumukedima"
    },
    {
      "id": 1953,
      "CityName": "Rosera",
      "CityValue": "Rosera"
    },
    {
      "id": 1954,
      "CityName": "Ahmedgarh",
      "CityValue": "Ahmedgarh"
    },
    {
      "id": 1955,
      "CityName": "Rajgarh",
      "CityValue": "Rajgarh"
    },
    {
      "id": 1956,
      "CityName": "Ulliyeri",
      "CityValue": "Ulliyeri"
    },
    {
      "id": 1957,
      "CityName": "Devadurga",
      "CityValue": "Devadurga"
    },
    {
      "id": 1958,
      "CityName": "Kondagaon",
      "CityValue": "Kondagaon"
    },
    {
      "id": 1959,
      "CityName": "Egra",
      "CityValue": "Egra"
    },
    {
      "id": 1960,
      "CityName": "Kabrai",
      "CityValue": "Kabrai"
    },
    {
      "id": 1961,
      "CityName": "Adiramapattinam",
      "CityValue": "Adiramapattinam"
    },
    {
      "id": 1962,
      "CityName": "Rehli",
      "CityValue": "Rehli"
    },
    {
      "id": 1963,
      "CityName": "Muttayyapuram",
      "CityValue": "Muttayyapuram"
    },
    {
      "id": 1964,
      "CityName": "Vandiyur",
      "CityValue": "Vandiyur"
    },
    {
      "id": 1965,
      "CityName": "Birnagar",
      "CityValue": "Birnagar"
    },
    {
      "id": 1966,
      "CityName": "Mussoorie",
      "CityValue": "Mussoorie"
    },
    {
      "id": 1967,
      "CityName": "Kodoli",
      "CityValue": "Kodoli"
    },
    {
      "id": 1968,
      "CityName": "Leh Ladakh",
      "CityValue": "Leh Ladakh"
    },
    {
      "id": 1969,
      "CityName": "Guledgudda",
      "CityValue": "Guledgudda"
    },
    {
      "id": 1970,
      "CityName": "Kurekuppa",
      "CityValue": "Kurekuppa"
    },
    {
      "id": 1971,
      "CityName": "Madukkarai",
      "CityValue": "Madukkarai"
    },
    {
      "id": 1972,
      "CityName": "Aya Nagar",
      "CityValue": "Aya Nagar"
    },
    {
      "id": 1973,
      "CityName": "Makhdumpur",
      "CityValue": "Makhdumpur"
    },
    {
      "id": 1974,
      "CityName": "Patratu",
      "CityValue": "Patratu"
    },
    {
      "id": 1975,
      "CityName": "Ladwa",
      "CityValue": "Ladwa"
    },
    {
      "id": 1976,
      "CityName": "Cheriyamundam",
      "CityValue": "Cheriyamundam"
    },
    {
      "id": 1977,
      "CityName": "Gomoh",
      "CityValue": "Gomoh"
    },
    {
      "id": 1978,
      "CityName": "Kalakad",
      "CityValue": "Kalakad"
    },
    {
      "id": 1979,
      "CityName": "Motipur",
      "CityValue": "Motipur"
    },
    {
      "id": 1980,
      "CityName": "Sri Madhopur",
      "CityValue": "Sri Madhopur"
    },
    {
      "id": 1981,
      "CityName": "Mangrulpir",
      "CityValue": "Mangrulpir"
    },
    {
      "id": 1982,
      "CityName": "Rasra",
      "CityValue": "Rasra"
    },
    {
      "id": 1983,
      "CityName": "Katri Piper Khera",
      "CityValue": "Katri Piper Khera"
    },
    {
      "id": 1984,
      "CityName": "Jandiala",
      "CityValue": "Jandiala"
    },
    {
      "id": 1985,
      "CityName": "Ellakkudy",
      "CityValue": "Ellakkudy"
    },
    {
      "id": 1986,
      "CityName": "Jaridih Bazar",
      "CityValue": "Jaridih Bazar"
    },
    {
      "id": 1987,
      "CityName": "JashpurNagar",
      "CityValue": "JashpurNagar"
    },
    {
      "id": 1988,
      "CityName": "Jafrabad",
      "CityValue": "Jafrabad"
    },
    {
      "id": 1989,
      "CityName": "Kareli",
      "CityValue": "Kareli"
    },
    {
      "id": 1990,
      "CityName": "Cheranallur",
      "CityValue": "Cheranallur"
    },
    {
      "id": 1991,
      "CityName": "Salamedu",
      "CityValue": "Salamedu"
    },
    {
      "id": 1992,
      "CityName": "Domariyaganj",
      "CityValue": "Domariyaganj"
    },
    {
      "id": 1993,
      "CityName": "Chirala",
      "CityValue": "Chirala"
    },
    {
      "id": 1994,
      "CityName": "Colonelganj",
      "CityValue": "Colonelganj"
    },
    {
      "id": 1995,
      "CityName": "Aron",
      "CityValue": "Aron"
    },
    {
      "id": 1996,
      "CityName": "Sheohar",
      "CityValue": "Sheohar"
    },
    {
      "id": 1997,
      "CityName": "Suriyampalayam",
      "CityValue": "Suriyampalayam"
    },
    {
      "id": 1998,
      "CityName": "Puthuppariyaram",
      "CityValue": "Puthuppariyaram"
    },
    {
      "id": 1999,
      "CityName": "Chittur-Thathamangalam",
      "CityValue": "Chittur-Thathamangalam"
    },
    {
      "id": 2000,
      "CityName": "Kulgam",
      "CityValue": "Kulgam"
    },
    {
      "id": 2001,
      "CityName": "Karjan",
      "CityValue": "Karjan"
    },
    {
      "id": 2002,
      "CityName": "Pandua",
      "CityValue": "Pandua"
    },
    {
      "id": 2003,
      "CityName": "Cherukavu",
      "CityValue": "Cherukavu"
    },
    {
      "id": 2004,
      "CityName": "Palwal",
      "CityValue": "Palwal"
    },
    {
      "id": 2005,
      "CityName": "Irimbiliyam",
      "CityValue": "Irimbiliyam"
    },
    {
      "id": 2006,
      "CityName": "Sulluru",
      "CityValue": "Sulluru"
    },
    {
      "id": 2007,
      "CityName": "Manavadar",
      "CityValue": "Manavadar"
    },
    {
      "id": 2008,
      "CityName": "Kalliasseri",
      "CityValue": "Kalliasseri"
    },
    {
      "id": 2009,
      "CityName": "Byadgi",
      "CityValue": "Byadgi"
    },
    {
      "id": 2010,
      "CityName": "Vadakkuvalliyur",
      "CityValue": "Vadakkuvalliyur"
    },
    {
      "id": 2011,
      "CityName": "Dhanauli",
      "CityValue": "Dhanauli"
    },
    {
      "id": 2012,
      "CityName": "Bhatkal",
      "CityValue": "Bhatkal"
    },
    {
      "id": 2013,
      "CityName": "Kalpetta",
      "CityValue": "Kalpetta"
    },
    {
      "id": 2014,
      "CityName": "Vandavasi",
      "CityValue": "Vandavasi"
    },
    {
      "id": 2015,
      "CityName": "Murliganj",
      "CityValue": "Murliganj"
    },
    {
      "id": 2016,
      "CityName": "Latehar",
      "CityValue": "Latehar"
    },
    {
      "id": 2017,
      "CityName": "Narsala",
      "CityValue": "Narsala"
    },
    {
      "id": 2018,
      "CityName": "Nongstoin",
      "CityValue": "Nongstoin"
    },
    {
      "id": 2019,
      "CityName": "Ner",
      "CityValue": "Ner"
    },
    {
      "id": 2020,
      "CityName": "Tarakeswar",
      "CityValue": "Tarakeswar"
    },
    {
      "id": 2021,
      "CityName": "Bilgram",
      "CityValue": "Bilgram"
    },
    {
      "id": 2022,
      "CityName": "Hirakud",
      "CityValue": "Hirakud"
    },
    {
      "id": 2023,
      "CityName": "Rapar",
      "CityValue": "Rapar"
    },
    {
      "id": 2024,
      "CityName": "Mainaguri",
      "CityValue": "Mainaguri"
    },
    {
      "id": 2025,
      "CityName": "Panagudi",
      "CityValue": "Panagudi"
    },
    {
      "id": 2026,
      "CityName": "Hinkal",
      "CityValue": "Hinkal"
    },
    {
      "id": 2027,
      "CityName": "Seoni-Malwa",
      "CityValue": "Seoni-Malwa"
    },
    {
      "id": 2028,
      "CityName": "Anupshahr",
      "CityValue": "Anupshahr"
    },
    {
      "id": 2029,
      "CityName": "Tirukkoyilur",
      "CityValue": "Tirukkoyilur"
    },
    {
      "id": 2030,
      "CityName": "Mahendragarh",
      "CityValue": "Mahendragarh"
    },
    {
      "id": 2031,
      "CityName": "Sujanpur",
      "CityValue": "Sujanpur"
    },
    {
      "id": 2032,
      "CityName": "Yellandu",
      "CityValue": "Yellandu"
    },
    {
      "id": 2033,
      "CityName": "Gangwa",
      "CityValue": "Gangwa"
    },
    {
      "id": 2034,
      "CityName": "Umbergaon",
      "CityValue": "Umbergaon"
    },
    {
      "id": 2035,
      "CityName": "Afzalpur",
      "CityValue": "Afzalpur"
    },
    {
      "id": 2036,
      "CityName": "Jalladiampet",
      "CityValue": "Jalladiampet"
    },
    {
      "id": 2037,
      "CityName": "Purquazi",
      "CityValue": "Purquazi"
    },
    {
      "id": 2038,
      "CityName": "Meenambakkam",
      "CityValue": "Meenambakkam"
    },
    {
      "id": 2039,
      "CityName": "Sriperumbudur",
      "CityValue": "Sriperumbudur"
    },
    {
      "id": 2040,
      "CityName": "Karjat",
      "CityValue": "Karjat"
    },
    {
      "id": 2041,
      "CityName": "Babarpur Ajitmal",
      "CityValue": "Babarpur Ajitmal"
    },
    {
      "id": 2042,
      "CityName": "Ramanayyapeta",
      "CityValue": "Ramanayyapeta"
    },
    {
      "id": 2043,
      "CityName": "Banshra",
      "CityValue": "Banshra"
    },
    {
      "id": 2044,
      "CityName": "Paravur",
      "CityValue": "Paravur"
    },
    {
      "id": 2045,
      "CityName": "Eloor",
      "CityValue": "Eloor"
    },
    {
      "id": 2046,
      "CityName": "Baddi",
      "CityValue": "Baddi"
    },
    {
      "id": 2047,
      "CityName": "Raikot",
      "CityValue": "Raikot"
    },
    {
      "id": 2048,
      "CityName": "Kadirur",
      "CityValue": "Kadirur"
    },
    {
      "id": 2049,
      "CityName": "Khandela",
      "CityValue": "Khandela"
    },
    {
      "id": 2050,
      "CityName": "Karera",
      "CityValue": "Karera"
    },
    {
      "id": 2051,
      "CityName": "Shafi Pur Ranhola",
      "CityValue": "Shafi Pur Ranhola"
    },
    {
      "id": 2052,
      "CityName": "Badangpet",
      "CityValue": "Badangpet"
    },
    {
      "id": 2053,
      "CityName": "Manugur",
      "CityValue": "Manugur"
    },
    {
      "id": 2054,
      "CityName": "Pudukkottai",
      "CityValue": "Pudukkottai"
    },
    {
      "id": 2055,
      "CityName": "Hosdurga",
      "CityValue": "Hosdurga"
    },
    {
      "id": 2056,
      "CityName": "Tekkali",
      "CityValue": "Tekkali"
    },
    {
      "id": 2057,
      "CityName": "Uttar Bagdogra",
      "CityValue": "Uttar Bagdogra"
    },
    {
      "id": 2058,
      "CityName": "Mansa",
      "CityValue": "Mansa"
    },
    {
      "id": 2059,
      "CityName": "Ukhrul",
      "CityValue": "Ukhrul"
    },
    {
      "id": 2060,
      "CityName": "Aklera",
      "CityValue": "Aklera"
    },
    {
      "id": 2061,
      "CityName": "Minjur",
      "CityValue": "Minjur"
    },
    {
      "id": 2062,
      "CityName": "Gobra Nawapara",
      "CityValue": "Gobra Nawapara"
    },
    {
      "id": 2063,
      "CityName": "Injambakkam",
      "CityValue": "Injambakkam"
    },
    {
      "id": 2064,
      "CityName": "Achhabal",
      "CityValue": "Achhabal"
    },
    {
      "id": 2065,
      "CityName": "Tiruchanur",
      "CityValue": "Tiruchanur"
    },
    {
      "id": 2066,
      "CityName": "Hole Narsipur",
      "CityValue": "Hole Narsipur"
    },
    {
      "id": 2067,
      "CityName": "Erandol",
      "CityValue": "Erandol"
    },
    {
      "id": 2068,
      "CityName": "Gadhada",
      "CityValue": "Gadhada"
    },
    {
      "id": 2069,
      "CityName": "Kemri",
      "CityValue": "Kemri"
    },
    {
      "id": 2070,
      "CityName": "Beldanga",
      "CityValue": "Beldanga"
    },
    {
      "id": 2071,
      "CityName": "Deolali Pravara",
      "CityValue": "Deolali Pravara"
    },
    {
      "id": 2072,
      "CityName": "Powayan",
      "CityValue": "Powayan"
    },
    {
      "id": 2073,
      "CityName": "Amlai",
      "CityValue": "Amlai"
    },
    {
      "id": 2074,
      "CityName": "Patran",
      "CityValue": "Patran"
    },
    {
      "id": 2075,
      "CityName": "Bagepalli",
      "CityValue": "Bagepalli"
    },
    {
      "id": 2076,
      "CityName": "Bemetara",
      "CityValue": "Bemetara"
    },
    {
      "id": 2077,
      "CityName": "Rajura",
      "CityValue": "Rajura"
    },
    {
      "id": 2078,
      "CityName": "Pedana",
      "CityValue": "Pedana"
    },
    {
      "id": 2079,
      "CityName": "Dipka",
      "CityValue": "Dipka"
    },
    {
      "id": 2080,
      "CityName": "Pachore",
      "CityValue": "Pachore"
    },
    {
      "id": 2081,
      "CityName": "Devanahalli",
      "CityValue": "Devanahalli"
    },
    {
      "id": 2082,
      "CityName": "Afzalgarh",
      "CityValue": "Afzalgarh"
    },
    {
      "id": 2083,
      "CityName": "Mudbidri",
      "CityValue": "Mudbidri"
    },
    {
      "id": 2084,
      "CityName": "Lal Gopalganj Nindaura",
      "CityValue": "Lal Gopalganj Nindaura"
    },
    {
      "id": 2085,
      "CityName": "Jolarpet",
      "CityValue": "Jolarpet"
    },
    {
      "id": 2086,
      "CityName": "Pilani",
      "CityValue": "Pilani"
    },
    {
      "id": 2087,
      "CityName": "Dharmadom",
      "CityValue": "Dharmadom"
    },
    {
      "id": 2088,
      "CityName": "Jalochi",
      "CityValue": "Jalochi"
    },
    {
      "id": 2089,
      "CityName": "Thanneermukkam",
      "CityValue": "Thanneermukkam"
    },
    {
      "id": 2090,
      "CityName": "Pattambi",
      "CityValue": "Pattambi"
    },
    {
      "id": 2091,
      "CityName": "Dibiyapur",
      "CityValue": "Dibiyapur"
    },
    {
      "id": 2092,
      "CityName": "Igatpuri",
      "CityValue": "Igatpuri"
    },
    {
      "id": 2093,
      "CityName": "Umarkote",
      "CityValue": "Umarkote"
    },
    {
      "id": 2094,
      "CityName": "Miranpur",
      "CityValue": "Miranpur"
    },
    {
      "id": 2095,
      "CityName": "Kundapura",
      "CityValue": "Kundapura"
    },
    {
      "id": 2096,
      "CityName": "Karumalloor",
      "CityValue": "Karumalloor"
    },
    {
      "id": 2097,
      "CityName": "Tharad",
      "CityValue": "Tharad"
    },
    {
      "id": 2098,
      "CityName": "Shamsabad",
      "CityValue": "Shamsabad"
    },
    {
      "id": 2099,
      "CityName": "Gangapur",
      "CityValue": "Gangapur"
    },
    {
      "id": 2100,
      "CityName": "Bareilly",
      "CityValue": "Bareilly"
    },
    {
      "id": 2101,
      "CityName": "Anupgarh",
      "CityValue": "Anupgarh"
    },
    {
      "id": 2102,
      "CityName": "Duliajan Oil Town",
      "CityValue": "Duliajan Oil Town"
    },
    {
      "id": 2103,
      "CityName": "Majhara Pipar Ahatmali",
      "CityValue": "Majhara Pipar Ahatmali"
    },
    {
      "id": 2104,
      "CityName": "Sankrail",
      "CityValue": "Sankrail"
    },
    {
      "id": 2105,
      "CityName": "Nibra",
      "CityValue": "Nibra"
    },
    {
      "id": 2106,
      "CityName": "Desaiganj",
      "CityValue": "Desaiganj"
    },
    {
      "id": 2107,
      "CityName": "Lakheri",
      "CityValue": "Lakheri"
    },
    {
      "id": 2108,
      "CityName": "Kon",
      "CityValue": "Kon"
    },
    {
      "id": 2109,
      "CityName": "Hoovina Hadagalli",
      "CityValue": "Hoovina Hadagalli"
    },
    {
      "id": 2110,
      "CityName": "Nakrekal",
      "CityValue": "Nakrekal"
    },
    {
      "id": 2111,
      "CityName": "Hutagalli",
      "CityValue": "Hutagalli"
    },
    {
      "id": 2112,
      "CityName": "Banswada",
      "CityValue": "Banswada"
    },
    {
      "id": 2113,
      "CityName": "Edakalinadu",
      "CityValue": "Edakalinadu"
    },
    {
      "id": 2114,
      "CityName": "Karakulam",
      "CityValue": "Karakulam"
    },
    {
      "id": 2115,
      "CityName": "Makronia Buzurg",
      "CityValue": "Makronia Buzurg"
    },
    {
      "id": 2116,
      "CityName": "Nedumpana",
      "CityValue": "Nedumpana"
    },
    {
      "id": 2117,
      "CityName": "Muvattupuzha",
      "CityValue": "Muvattupuzha"
    },
    {
      "id": 2118,
      "CityName": "Urakam",
      "CityValue": "Urakam"
    },
    {
      "id": 2119,
      "CityName": "Devarakonda",
      "CityValue": "Devarakonda"
    },
    {
      "id": 2120,
      "CityName": "Vadanappally",
      "CityValue": "Vadanappally"
    },
    {
      "id": 2121,
      "CityName": "Gingee",
      "CityValue": "Gingee"
    },
    {
      "id": 2122,
      "CityName": "Cheruthazham",
      "CityValue": "Cheruthazham"
    },
    {
      "id": 2123,
      "CityName": "Kanth",
      "CityValue": "Kanth"
    },
    {
      "id": 2124,
      "CityName": "Kukshi",
      "CityValue": "Kukshi"
    },
    {
      "id": 2125,
      "CityName": "Damua",
      "CityValue": "Damua"
    },
    {
      "id": 2126,
      "CityName": "Pardi",
      "CityValue": "Pardi"
    },
    {
      "id": 2127,
      "CityName": "Kurumbapet",
      "CityValue": "Kurumbapet"
    },
    {
      "id": 2128,
      "CityName": "Pipri",
      "CityValue": "Pipri"
    },
    {
      "id": 2129,
      "CityName": "Anklesvar",
      "CityValue": "Anklesvar"
    },
    {
      "id": 2130,
      "CityName": "Mavoor",
      "CityValue": "Mavoor"
    },
    {
      "id": 2131,
      "CityName": "Peruvayal",
      "CityValue": "Peruvayal"
    },
    {
      "id": 2132,
      "CityName": "Aurangabad",
      "CityValue": "Aurangabad"
    },
    {
      "id": 2133,
      "CityName": "Shahpura",
      "CityValue": "Shahpura"
    },
    {
      "id": 2134,
      "CityName": "Aliganj",
      "CityValue": "Aliganj"
    },
    {
      "id": 2135,
      "CityName": "Jalalabad",
      "CityValue": "Jalalabad"
    },
    {
      "id": 2136,
      "CityName": "Kotma",
      "CityValue": "Kotma"
    },
    {
      "id": 2137,
      "CityName": "Koovappady",
      "CityValue": "Koovappady"
    },
    {
      "id": 2138,
      "CityName": "Kithaur",
      "CityValue": "Kithaur"
    },
    {
      "id": 2139,
      "CityName": "Rajgurunagar",
      "CityValue": "Rajgurunagar"
    },
    {
      "id": 2140,
      "CityName": "Thalakkulathur",
      "CityValue": "Thalakkulathur"
    },
    {
      "id": 2141,
      "CityName": "Kalavad",
      "CityValue": "Kalavad"
    },
    {
      "id": 2142,
      "CityName": "Dharmsala",
      "CityValue": "Dharmsala"
    },
    {
      "id": 2143,
      "CityName": "Azhiyur",
      "CityValue": "Azhiyur"
    },
    {
      "id": 2144,
      "CityName": "Madhugiri",
      "CityValue": "Madhugiri"
    },
    {
      "id": 2145,
      "CityName": "Pachgaon",
      "CityValue": "Pachgaon"
    },
    {
      "id": 2146,
      "CityName": "Sankari",
      "CityValue": "Sankari"
    },
    {
      "id": 2147,
      "CityName": "Nahan",
      "CityValue": "Nahan"
    },
    {
      "id": 2148,
      "CityName": "Nalbari",
      "CityValue": "Nalbari"
    },
    {
      "id": 2149,
      "CityName": "Kishangarh Renwal",
      "CityValue": "Kishangarh Renwal"
    },
    {
      "id": 2150,
      "CityName": "Poolacode",
      "CityValue": "Poolacode"
    },
    {
      "id": 2151,
      "CityName": "Kinwat",
      "CityValue": "Kinwat"
    },
    {
      "id": 2152,
      "CityName": "Irugur",
      "CityValue": "Irugur"
    },
    {
      "id": 2153,
      "CityName": "Pynthormukhrah",
      "CityValue": "Pynthormukhrah"
    },
    {
      "id": 2154,
      "CityName": "Doda",
      "CityValue": "Doda"
    },
    {
      "id": 2155,
      "CityName": "Sulur",
      "CityValue": "Sulur"
    },
    {
      "id": 2156,
      "CityName": "Shiggaon",
      "CityValue": "Shiggaon"
    },
    {
      "id": 2157,
      "CityName": "Amla",
      "CityValue": "Amla"
    },
    {
      "id": 2158,
      "CityName": "Tambaur-cum-Ahamdabad",
      "CityValue": "Tambaur-cum-Ahamdabad"
    },
    {
      "id": 2159,
      "CityName": "Kurinjipadi",
      "CityValue": "Kurinjipadi"
    },
    {
      "id": 2160,
      "CityName": "Amballur",
      "CityValue": "Amballur"
    },
    {
      "id": 2161,
      "CityName": "Alirajpur",
      "CityValue": "Alirajpur"
    },
    {
      "id": 2162,
      "CityName": "Siras Pur",
      "CityValue": "Siras Pur"
    },
    {
      "id": 2163,
      "CityName": "Kattumannarkoil",
      "CityValue": "Kattumannarkoil"
    },
    {
      "id": 2164,
      "CityName": "Fatehganj Pashchimi",
      "CityValue": "Fatehganj Pashchimi"
    },
    {
      "id": 2165,
      "CityName": "Jhalod",
      "CityValue": "Jhalod"
    },
    {
      "id": 2166,
      "CityName": "Nokha",
      "CityValue": "Nokha"
    },
    {
      "id": 2167,
      "CityName": "Kondotty",
      "CityValue": "Kondotty"
    },
    {
      "id": 2168,
      "CityName": "Jowai",
      "CityValue": "Jowai"
    },
    {
      "id": 2169,
      "CityName": "Losal",
      "CityValue": "Losal"
    },
    {
      "id": 2170,
      "CityName": "Chanpatia",
      "CityValue": "Chanpatia"
    },
    {
      "id": 2171,
      "CityName": "Chaltia",
      "CityValue": "Chaltia"
    },
    {
      "id": 2172,
      "CityName": "Rajaldesar",
      "CityValue": "Rajaldesar"
    },
    {
      "id": 2173,
      "CityName": "Doraha",
      "CityValue": "Doraha"
    },
    {
      "id": 2174,
      "CityName": "Behea",
      "CityValue": "Behea"
    },
    {
      "id": 2175,
      "CityName": "Perundurai",
      "CityValue": "Perundurai"
    },
    {
      "id": 2176,
      "CityName": "Chandrapura",
      "CityValue": "Chandrapura"
    },
    {
      "id": 2177,
      "CityName": "Vadipatti",
      "CityValue": "Vadipatti"
    },
    {
      "id": 2178,
      "CityName": "Nedumbassery",
      "CityValue": "Nedumbassery"
    },
    {
      "id": 2179,
      "CityName": "Vellalur",
      "CityValue": "Vellalur"
    },
    {
      "id": 2180,
      "CityName": "Ongallur -II",
      "CityValue": "Ongallur -II"
    },
    {
      "id": 2181,
      "CityName": "Bassi",
      "CityValue": "Bassi"
    },
    {
      "id": 2182,
      "CityName": "Ferozepur jhirka",
      "CityValue": "Ferozepur jhirka"
    },
    {
      "id": 2183,
      "CityName": "Hangal",
      "CityValue": "Hangal"
    },
    {
      "id": 2184,
      "CityName": "Kunda",
      "CityValue": "Kunda"
    },
    {
      "id": 2185,
      "CityName": "Naspur",
      "CityValue": "Naspur"
    },
    {
      "id": 2186,
      "CityName": "Sonamukhi",
      "CityValue": "Sonamukhi"
    },
    {
      "id": 2187,
      "CityName": "Attibele",
      "CityValue": "Attibele"
    },
    {
      "id": 2188,
      "CityName": "Gotegaon",
      "CityValue": "Gotegaon"
    },
    {
      "id": 2189,
      "CityName": "Pallapatti",
      "CityValue": "Pallapatti"
    },
    {
      "id": 2190,
      "CityName": "Lar",
      "CityValue": "Lar"
    },
    {
      "id": 2191,
      "CityName": "Charkhari",
      "CityValue": "Charkhari"
    },
    {
      "id": 2192,
      "CityName": "Kudligi",
      "CityValue": "Kudligi"
    },
    {
      "id": 2193,
      "CityName": "Machhiwara",
      "CityValue": "Machhiwara"
    },
    {
      "id": 2194,
      "CityName": "Kalwakurthy",
      "CityValue": "Kalwakurthy"
    },
    {
      "id": 2195,
      "CityName": "Chandvad",
      "CityValue": "Chandvad"
    },
    {
      "id": 2196,
      "CityName": "Lohta",
      "CityValue": "Lohta"
    },
    {
      "id": 2197,
      "CityName": "Kumbalam",
      "CityValue": "Kumbalam"
    },
    {
      "id": 2198,
      "CityName": "Udaipurwati",
      "CityValue": "Udaipurwati"
    },
    {
      "id": 2199,
      "CityName": "Musabani",
      "CityValue": "Musabani"
    },
    {
      "id": 2200,
      "CityName": "Pathardi",
      "CityValue": "Pathardi"
    },
    {
      "id": 2201,
      "CityName": "Thathankuttai",
      "CityValue": "Thathankuttai"
    },
    {
      "id": 2202,
      "CityName": "Kharkhoda",
      "CityValue": "Kharkhoda"
    },
    {
      "id": 2203,
      "CityName": "Hadgaon",
      "CityValue": "Hadgaon"
    },
    {
      "id": 2204,
      "CityName": "Maddur",
      "CityValue": "Maddur"
    },
    {
      "id": 2205,
      "CityName": "Jevargi",
      "CityValue": "Jevargi"
    },
    {
      "id": 2206,
      "CityName": "Panagar",
      "CityValue": "Panagar"
    },
    {
      "id": 2207,
      "CityName": "Assandh",
      "CityValue": "Assandh"
    },
    {
      "id": 2208,
      "CityName": "Poothakkulam",
      "CityValue": "Poothakkulam"
    },
    {
      "id": 2209,
      "CityName": "Mehdawal",
      "CityValue": "Mehdawal"
    },
    {
      "id": 2210,
      "CityName": "Sirsi",
      "CityValue": "Sirsi"
    },
    {
      "id": 2211,
      "CityName": "Andipatti Jakkampatti",
      "CityValue": "Andipatti Jakkampatti"
    },
    {
      "id": 2212,
      "CityName": "Kodarma",
      "CityValue": "Kodarma"
    },
    {
      "id": 2213,
      "CityName": "Chendamangalam",
      "CityValue": "Chendamangalam"
    },
    {
      "id": 2214,
      "CityName": "Rampur Maniharan",
      "CityValue": "Rampur Maniharan"
    },
    {
      "id": 2215,
      "CityName": "Kottarakkara",
      "CityValue": "Kottarakkara"
    },
    {
      "id": 2216,
      "CityName": "Thrithala",
      "CityValue": "Thrithala"
    },
    {
      "id": 2217,
      "CityName": "Teghari",
      "CityValue": "Teghari"
    },
    {
      "id": 2218,
      "CityName": "Manihari",
      "CityValue": "Manihari"
    },
    {
      "id": 2219,
      "CityName": "Kizhuvalam-Koonthalloor",
      "CityValue": "Kizhuvalam-Koonthalloor"
    },
    {
      "id": 2220,
      "CityName": "Areraj",
      "CityValue": "Areraj"
    },
    {
      "id": 2221,
      "CityName": "Kalara",
      "CityValue": "Kalara"
    },
    {
      "id": 2222,
      "CityName": "Jaswantnagar",
      "CityValue": "Jaswantnagar"
    },
    {
      "id": 2223,
      "CityName": "Polur",
      "CityValue": "Polur"
    },
    {
      "id": 2224,
      "CityName": "Meenad",
      "CityValue": "Meenad"
    },
    {
      "id": 2225,
      "CityName": "Narasannapeta",
      "CityValue": "Narasannapeta"
    },
    {
      "id": 2226,
      "CityName": "Karhal",
      "CityValue": "Karhal"
    },
    {
      "id": 2227,
      "CityName": "Jalgaon",
      "CityValue": "Jalgaon"
    },
    {
      "id": 2228,
      "CityName": "Bhokardan",
      "CityValue": "Bhokardan"
    },
    {
      "id": 2229,
      "CityName": "Hastinapur",
      "CityValue": "Hastinapur"
    },
    {
      "id": 2230,
      "CityName": "Mehsi",
      "CityValue": "Mehsi"
    },
    {
      "id": 2231,
      "CityName": "Hupari",
      "CityValue": "Hupari"
    },
    {
      "id": 2232,
      "CityName": "Annigeri",
      "CityValue": "Annigeri"
    },
    {
      "id": 2233,
      "CityName": "Shivpur Charcha",
      "CityValue": "Shivpur Charcha"
    },
    {
      "id": 2234,
      "CityName": "Koothuparamba",
      "CityValue": "Koothuparamba"
    },
    {
      "id": 2235,
      "CityName": "Prithvipur",
      "CityValue": "Prithvipur"
    },
    {
      "id": 2236,
      "CityName": "Chandur",
      "CityValue": "Chandur"
    },
    {
      "id": 2237,
      "CityName": "Konappana Agrahara",
      "CityValue": "Konappana Agrahara"
    },
    {
      "id": 2238,
      "CityName": "Mahad",
      "CityValue": "Mahad"
    },
    {
      "id": 2239,
      "CityName": "Sevilimedu",
      "CityValue": "Sevilimedu"
    },
    {
      "id": 2240,
      "CityName": "Manesar",
      "CityValue": "Manesar"
    },
    {
      "id": 2241,
      "CityName": "Chengamanad",
      "CityValue": "Chengamanad"
    },
    {
      "id": 2242,
      "CityName": "Ariyalur",
      "CityValue": "Ariyalur"
    },
    {
      "id": 2243,
      "CityName": "Dinapur Cantonment",
      "CityValue": "Dinapur Cantonment"
    },
    {
      "id": 2244,
      "CityName": "Ramavarappadu",
      "CityValue": "Ramavarappadu"
    },
    {
      "id": 2245,
      "CityName": "Puthuppally",
      "CityValue": "Puthuppally"
    },
    {
      "id": 2246,
      "CityName": "Vanagaram",
      "CityValue": "Vanagaram"
    },
    {
      "id": 2247,
      "CityName": "Dhandera",
      "CityValue": "Dhandera"
    },
    {
      "id": 2248,
      "CityName": "Musiri",
      "CityValue": "Musiri"
    },
    {
      "id": 2249,
      "CityName": "Bhikhiwind",
      "CityValue": "Bhikhiwind"
    },
    {
      "id": 2250,
      "CityName": "Babiyal",
      "CityValue": "Babiyal"
    },
    {
      "id": 2251,
      "CityName": "Vadnagar",
      "CityValue": "Vadnagar"
    },
    {
      "id": 2252,
      "CityName": "Velur",
      "CityValue": "Velur"
    },
    {
      "id": 2253,
      "CityName": "Shishgarh",
      "CityValue": "Shishgarh"
    },
    {
      "id": 2254,
      "CityName": "Chalthan",
      "CityValue": "Chalthan"
    },
    {
      "id": 2255,
      "CityName": "Silao",
      "CityValue": "Silao"
    },
    {
      "id": 2256,
      "CityName": "Sausar",
      "CityValue": "Sausar"
    },
    {
      "id": 2257,
      "CityName": "Mathura",
      "CityValue": "Mathura"
    },
    {
      "id": 2258,
      "CityName": "Adampur",
      "CityValue": "Adampur"
    },
    {
      "id": 2259,
      "CityName": "Indergarh",
      "CityValue": "Indergarh"
    },
    {
      "id": 2260,
      "CityName": "Chengam",
      "CityValue": "Chengam"
    },
    {
      "id": 2261,
      "CityName": "Srinivaspur",
      "CityValue": "Srinivaspur"
    },
    {
      "id": 2262,
      "CityName": "Ayakudi",
      "CityValue": "Ayakudi"
    },
    {
      "id": 2263,
      "CityName": "Balakrishnapuram",
      "CityValue": "Balakrishnapuram"
    },
    {
      "id": 2264,
      "CityName": "Rangia",
      "CityValue": "Rangia"
    },
    {
      "id": 2265,
      "CityName": "Sewalkhas",
      "CityValue": "Sewalkhas"
    },
    {
      "id": 2266,
      "CityName": "Atholi",
      "CityValue": "Atholi"
    },
    {
      "id": 2267,
      "CityName": "Khurja Rural",
      "CityValue": "Khurja Rural"
    },
    {
      "id": 2268,
      "CityName": "Magadi",
      "CityValue": "Magadi"
    },
    {
      "id": 2269,
      "CityName": "Nadbai",
      "CityValue": "Nadbai"
    },
    {
      "id": 2270,
      "CityName": "Vattappara",
      "CityValue": "Vattappara"
    },
    {
      "id": 2271,
      "CityName": "Gundlupet",
      "CityValue": "Gundlupet"
    },
    {
      "id": 2272,
      "CityName": "Nebadhai Duttapukur",
      "CityValue": "Nebadhai Duttapukur"
    },
    {
      "id": 2273,
      "CityName": "Panchla",
      "CityValue": "Panchla"
    },
    {
      "id": 2274,
      "CityName": "Mudalgi",
      "CityValue": "Mudalgi"
    },
    {
      "id": 2275,
      "CityName": "Baloda Bazar",
      "CityValue": "Baloda Bazar"
    },
    {
      "id": 2276,
      "CityName": "Thindal",
      "CityValue": "Thindal"
    },
    {
      "id": 2277,
      "CityName": "Bank",
      "CityValue": "Bank"
    },
    {
      "id": 2278,
      "CityName": "Kulithalai",
      "CityValue": "Kulithalai"
    },
    {
      "id": 2279,
      "CityName": "Oachira",
      "CityValue": "Oachira"
    },
    {
      "id": 2280,
      "CityName": "Paonta Sahib",
      "CityValue": "Paonta Sahib"
    },
    {
      "id": 2281,
      "CityName": "Sadri",
      "CityValue": "Sadri"
    },
    {
      "id": 2282,
      "CityName": "Nattapettai",
      "CityValue": "Nattapettai"
    },
    {
      "id": 2283,
      "CityName": "Gursarai",
      "CityValue": "Gursarai"
    },
    {
      "id": 2284,
      "CityName": "Babra",
      "CityValue": "Babra"
    },
    {
      "id": 2285,
      "CityName": "Phulera",
      "CityValue": "Phulera"
    },
    {
      "id": 2286,
      "CityName": "Lohara",
      "CityValue": "Lohara"
    },
    {
      "id": 2287,
      "CityName": "Kailaras",
      "CityValue": "Kailaras"
    },
    {
      "id": 2288,
      "CityName": "Adoor",
      "CityValue": "Adoor"
    },
    {
      "id": 2289,
      "CityName": "Dataganj",
      "CityValue": "Dataganj"
    },
    {
      "id": 2290,
      "CityName": "Songadh",
      "CityValue": "Songadh"
    },
    {
      "id": 2291,
      "CityName": "Poranki",
      "CityValue": "Poranki"
    },
    {
      "id": 2292,
      "CityName": "Kattupakkam",
      "CityValue": "Kattupakkam"
    },
    {
      "id": 2293,
      "CityName": "Akhnoor",
      "CityValue": "Akhnoor"
    },
    {
      "id": 2294,
      "CityName": "Talaja",
      "CityValue": "Talaja"
    },
    {
      "id": 2295,
      "CityName": "Panmana",
      "CityValue": "Panmana"
    },
    {
      "id": 2296,
      "CityName": "Perumanna",
      "CityValue": "Perumanna"
    },
    {
      "id": 2297,
      "CityName": "Mecheri",
      "CityValue": "Mecheri"
    },
    {
      "id": 2298,
      "CityName": "Perumbavoor",
      "CityValue": "Perumbavoor"
    },
    {
      "id": 2299,
      "CityName": "Mathigiri",
      "CityValue": "Mathigiri"
    },
    {
      "id": 2300,
      "CityName": "Williamnagar",
      "CityValue": "Williamnagar"
    },
    {
      "id": 2301,
      "CityName": "Vijayapuram",
      "CityValue": "Vijayapuram"
    },
    {
      "id": 2302,
      "CityName": "Punch",
      "CityValue": "Punch"
    },
    {
      "id": 2303,
      "CityName": "Gadkhol",
      "CityValue": "Gadkhol"
    },
    {
      "id": 2304,
      "CityName": "Chinnasalem",
      "CityValue": "Chinnasalem"
    },
    {
      "id": 2305,
      "CityName": "Reoti",
      "CityValue": "Reoti"
    },
    {
      "id": 2306,
      "CityName": "Borgaon",
      "CityValue": "Borgaon"
    },
    {
      "id": 2307,
      "CityName": "Pavagada",
      "CityValue": "Pavagada"
    },
    {
      "id": 2308,
      "CityName": "Arumuganeri",
      "CityValue": "Arumuganeri"
    },
    {
      "id": 2309,
      "CityName": "Nepanagar",
      "CityValue": "Nepanagar"
    },
    {
      "id": 2310,
      "CityName": "Waraseoni",
      "CityValue": "Waraseoni"
    },
    {
      "id": 2311,
      "CityName": "Kotturu",
      "CityValue": "Kotturu"
    },
    {
      "id": 2312,
      "CityName": "Jammu Cantt.",
      "CityValue": "Jammu Cantt."
    },
    {
      "id": 2313,
      "CityName": "Mukhed",
      "CityValue": "Mukhed"
    },
    {
      "id": 2314,
      "CityName": "Manasa",
      "CityValue": "Manasa"
    },
    {
      "id": 2315,
      "CityName": "Manachanallur",
      "CityValue": "Manachanallur"
    },
    {
      "id": 2316,
      "CityName": "Naubatpur",
      "CityValue": "Naubatpur"
    },
    {
      "id": 2317,
      "CityName": "Haqiqatpur Urf Khudawas",
      "CityValue": "Haqiqatpur Urf Khudawas"
    },
    {
      "id": 2318,
      "CityName": "Kalanaur",
      "CityValue": "Kalanaur"
    },
    {
      "id": 2319,
      "CityName": "Haludbani",
      "CityValue": "Haludbani"
    },
    {
      "id": 2320,
      "CityName": "Saiha",
      "CityValue": "Saiha"
    },
    {
      "id": 2321,
      "CityName": "Kuttikkattoor",
      "CityValue": "Kuttikkattoor"
    },
    {
      "id": 2322,
      "CityName": "Sattur",
      "CityValue": "Sattur"
    },
    {
      "id": 2323,
      "CityName": "Sahajadpur",
      "CityValue": "Sahajadpur"
    },
    {
      "id": 2324,
      "CityName": "Kajora",
      "CityValue": "Kajora"
    },
    {
      "id": 2325,
      "CityName": "Gulabpura",
      "CityValue": "Gulabpura"
    },
    {
      "id": 2326,
      "CityName": "Indapur",
      "CityValue": "Indapur"
    },
    {
      "id": 2327,
      "CityName": "Parangipettai",
      "CityValue": "Parangipettai"
    },
    {
      "id": 2328,
      "CityName": "Sagwara",
      "CityValue": "Sagwara"
    },
    {
      "id": 2329,
      "CityName": "Allahabad",
      "CityValue": "Allahabad"
    },
    {
      "id": 2330,
      "CityName": "Ilayangudi",
      "CityValue": "Ilayangudi"
    },
    {
      "id": 2331,
      "CityName": "Irinjalakuda",
      "CityValue": "Irinjalakuda"
    },
    {
      "id": 2332,
      "CityName": "Khoni",
      "CityValue": "Khoni"
    },
    {
      "id": 2333,
      "CityName": "Dhaurehra",
      "CityValue": "Dhaurehra"
    },
    {
      "id": 2334,
      "CityName": "Dhanipur",
      "CityValue": "Dhanipur"
    },
    {
      "id": 2335,
      "CityName": "Jamul",
      "CityValue": "Jamul"
    },
    {
      "id": 2336,
      "CityName": "Pallippuram",
      "CityValue": "Pallippuram"
    },
    {
      "id": 2337,
      "CityName": "Gadhinglaj",
      "CityValue": "Gadhinglaj"
    },
    {
      "id": 2338,
      "CityName": "Sidhauli",
      "CityValue": "Sidhauli"
    },
    {
      "id": 2339,
      "CityName": "Ujhari",
      "CityValue": "Ujhari"
    },
    {
      "id": 2340,
      "CityName": "Bilsi",
      "CityValue": "Bilsi"
    },
    {
      "id": 2341,
      "CityName": "Sheoganj",
      "CityValue": "Sheoganj"
    },
    {
      "id": 2342,
      "CityName": "Kanksa",
      "CityValue": "Kanksa"
    },
    {
      "id": 2343,
      "CityName": "Mungaoli",
      "CityValue": "Mungaoli"
    },
    {
      "id": 2344,
      "CityName": "Anjad",
      "CityValue": "Anjad"
    },
    {
      "id": 2345,
      "CityName": "Uliyazhathura",
      "CityValue": "Uliyazhathura"
    },
    {
      "id": 2346,
      "CityName": "Margherita",
      "CityValue": "Margherita"
    },
    {
      "id": 2347,
      "CityName": "Mehna",
      "CityValue": "Mehna"
    },
    {
      "id": 2348,
      "CityName": "Gopavaram",
      "CityValue": "Gopavaram"
    },
    {
      "id": 2349,
      "CityName": "Tekkalakote",
      "CityValue": "Tekkalakote"
    },
    {
      "id": 2350,
      "CityName": "Raisinghnagar",
      "CityValue": "Raisinghnagar"
    },
    {
      "id": 2351,
      "CityName": "Jaleshwar",
      "CityValue": "Jaleshwar"
    },
    {
      "id": 2352,
      "CityName": "Faizpur",
      "CityValue": "Faizpur"
    },
    {
      "id": 2353,
      "CityName": "Mauganj",
      "CityValue": "Mauganj"
    },
    {
      "id": 2354,
      "CityName": "Taraori",
      "CityValue": "Taraori"
    },
    {
      "id": 2355,
      "CityName": "Jafrabad",
      "CityValue": "Jafrabad"
    },
    {
      "id": 2356,
      "CityName": "Kyathampalle",
      "CityValue": "Kyathampalle"
    },
    {
      "id": 2357,
      "CityName": "Kanaipur",
      "CityValue": "Kanaipur"
    },
    {
      "id": 2358,
      "CityName": "Pathirappally",
      "CityValue": "Pathirappally"
    },
    {
      "id": 2359,
      "CityName": "Mahwa",
      "CityValue": "Mahwa"
    },
    {
      "id": 2360,
      "CityName": "Dasua",
      "CityValue": "Dasua"
    },
    {
      "id": 2361,
      "CityName": "Mallikpur",
      "CityValue": "Mallikpur"
    },
    {
      "id": 2362,
      "CityName": "Kamalapuram",
      "CityValue": "Kamalapuram"
    },
    {
      "id": 2363,
      "CityName": "Ambassa",
      "CityValue": "Ambassa"
    },
    {
      "id": 2364,
      "CityName": "Bhander",
      "CityValue": "Bhander"
    },
    {
      "id": 2365,
      "CityName": "Rajgarh",
      "CityValue": "Rajgarh"
    },
    {
      "id": 2366,
      "CityName": "Amarpur",
      "CityValue": "Amarpur"
    },
    {
      "id": 2367,
      "CityName": "Reethapuram",
      "CityValue": "Reethapuram"
    },
    {
      "id": 2368,
      "CityName": "Barwaha",
      "CityValue": "Barwaha"
    },
    {
      "id": 2369,
      "CityName": "Ezhudesam",
      "CityValue": "Ezhudesam"
    },
    {
      "id": 2370,
      "CityName": "Nasrullaganj",
      "CityValue": "Nasrullaganj"
    },
    {
      "id": 2371,
      "CityName": "Sutrapada",
      "CityValue": "Sutrapada"
    },
    {
      "id": 2372,
      "CityName": "Payakaraopeta",
      "CityValue": "Payakaraopeta"
    },
    {
      "id": 2373,
      "CityName": "Pallikkunnu",
      "CityValue": "Pallikkunnu"
    },
    {
      "id": 2374,
      "CityName": "Tijara",
      "CityValue": "Tijara"
    },
    {
      "id": 2375,
      "CityName": "Thikkody",
      "CityValue": "Thikkody"
    },
    {
      "id": 2376,
      "CityName": "Nanmanda",
      "CityValue": "Nanmanda"
    },
    {
      "id": 2377,
      "CityName": "Periyanaicken-palayam",
      "CityValue": "Periyanaicken-palayam"
    },
    {
      "id": 2378,
      "CityName": "Nagar",
      "CityValue": "Nagar"
    },
    {
      "id": 2379,
      "CityName": "Kulasekharapuram",
      "CityValue": "Kulasekharapuram"
    },
    {
      "id": 2380,
      "CityName": "Chata Kalikapur",
      "CityValue": "Chata Kalikapur"
    },
    {
      "id": 2381,
      "CityName": "Jamjodhpur",
      "CityValue": "Jamjodhpur"
    },
    {
      "id": 2382,
      "CityName": "Jais",
      "CityValue": "Jais"
    },
    {
      "id": 2383,
      "CityName": "Harur",
      "CityValue": "Harur"
    },
    {
      "id": 2384,
      "CityName": "Kanth",
      "CityValue": "Kanth"
    },
    {
      "id": 2385,
      "CityName": "Renigunta",
      "CityValue": "Renigunta"
    },
    {
      "id": 2386,
      "CityName": "Titabor Town",
      "CityValue": "Titabor Town"
    },
    {
      "id": 2387,
      "CityName": "Ghoti Bk.",
      "CityValue": "Ghoti Bk."
    },
    {
      "id": 2388,
      "CityName": "Reengus",
      "CityValue": "Reengus"
    },
    {
      "id": 2389,
      "CityName": "Soron",
      "CityValue": "Soron"
    },
    {
      "id": 2390,
      "CityName": "Yelamanchili",
      "CityValue": "Yelamanchili"
    },
    {
      "id": 2391,
      "CityName": "Alathur",
      "CityValue": "Alathur"
    },
    {
      "id": 2392,
      "CityName": "Thiruppalai",
      "CityValue": "Thiruppalai"
    },
    {
      "id": 2393,
      "CityName": "Bhasaipaikar",
      "CityValue": "Bhasaipaikar"
    },
    {
      "id": 2394,
      "CityName": "Vengathur",
      "CityValue": "Vengathur"
    },
    {
      "id": 2395,
      "CityName": "Bajpur",
      "CityValue": "Bajpur"
    },
    {
      "id": 2396,
      "CityName": "Kolasib",
      "CityValue": "Kolasib"
    },
    {
      "id": 2397,
      "CityName": "Khategaon",
      "CityValue": "Khategaon"
    },
    {
      "id": 2398,
      "CityName": "Kanti",
      "CityValue": "Kanti"
    },
    {
      "id": 2399,
      "CityName": "Chinnalapatti",
      "CityValue": "Chinnalapatti"
    },
    {
      "id": 2400,
      "CityName": "Pasan",
      "CityValue": "Pasan"
    },
    {
      "id": 2401,
      "CityName": "Kottur",
      "CityValue": "Kottur"
    },
    {
      "id": 2402,
      "CityName": "Safipur",
      "CityValue": "Safipur"
    },
    {
      "id": 2403,
      "CityName": "Ratanpur",
      "CityValue": "Ratanpur"
    },
    {
      "id": 2404,
      "CityName": "Machhlishahr",
      "CityValue": "Machhlishahr"
    },
    {
      "id": 2405,
      "CityName": "Varappuzha",
      "CityValue": "Varappuzha"
    },
    {
      "id": 2406,
      "CityName": "Polichalur",
      "CityValue": "Polichalur"
    },
    {
      "id": 2407,
      "CityName": "Mirganj",
      "CityValue": "Mirganj"
    },
    {
      "id": 2408,
      "CityName": "Lilong",
      "CityValue": "Lilong"
    },
    {
      "id": 2409,
      "CityName": "Raver",
      "CityValue": "Raver"
    },
    {
      "id": 2410,
      "CityName": "Duru Verinag",
      "CityValue": "Duru Verinag"
    },
    {
      "id": 2411,
      "CityName": "Gairatganj",
      "CityValue": "Gairatganj"
    },
    {
      "id": 2412,
      "CityName": "Budhlada",
      "CityValue": "Budhlada"
    },
    {
      "id": 2413,
      "CityName": "Balusseri",
      "CityValue": "Balusseri"
    },
    {
      "id": 2414,
      "CityName": "Kudlu",
      "CityValue": "Kudlu"
    },
    {
      "id": 2415,
      "CityName": "Krishnarajpet",
      "CityValue": "Krishnarajpet"
    },
    {
      "id": 2416,
      "CityName": "Edacheri",
      "CityValue": "Edacheri"
    },
    {
      "id": 2417,
      "CityName": "Gajwel",
      "CityValue": "Gajwel"
    },
    {
      "id": 2418,
      "CityName": "Rawali Mahdood",
      "CityValue": "Rawali Mahdood"
    },
    {
      "id": 2419,
      "CityName": "Khairagarh",
      "CityValue": "Khairagarh"
    },
    {
      "id": 2420,
      "CityName": "Tirupathur",
      "CityValue": "Tirupathur"
    },
    {
      "id": 2421,
      "CityName": "Sandi",
      "CityValue": "Sandi"
    },
    {
      "id": 2422,
      "CityName": "Khajuraho",
      "CityValue": "Khajuraho"
    },
    {
      "id": 2423,
      "CityName": "Denkanikottai",
      "CityValue": "Denkanikottai"
    },
    {
      "id": 2424,
      "CityName": "Rajakhedi",
      "CityValue": "Rajakhedi"
    },
    {
      "id": 2425,
      "CityName": "Walidpur",
      "CityValue": "Walidpur"
    },
    {
      "id": 2426,
      "CityName": "Chinnavedampatti",
      "CityValue": "Chinnavedampatti"
    },
    {
      "id": 2427,
      "CityName": "Kuraoali",
      "CityValue": "Kuraoali"
    },
    {
      "id": 2428,
      "CityName": "Shahganj",
      "CityValue": "Shahganj"
    },
    {
      "id": 2429,
      "CityName": "Dhrol",
      "CityValue": "Dhrol"
    },
    {
      "id": 2430,
      "CityName": "Ramgarh Panjoopur",
      "CityValue": "Ramgarh Panjoopur"
    },
    {
      "id": 2431,
      "CityName": "Terdal",
      "CityValue": "Terdal"
    },
    {
      "id": 2432,
      "CityName": "Kora Jahanabad",
      "CityValue": "Kora Jahanabad"
    },
    {
      "id": 2433,
      "CityName": "Viswanatham",
      "CityValue": "Viswanatham"
    },
    {
      "id": 2434,
      "CityName": "Mundargi",
      "CityValue": "Mundargi"
    },
    {
      "id": 2435,
      "CityName": "Vadgaon Kasba",
      "CityValue": "Vadgaon Kasba"
    },
    {
      "id": 2436,
      "CityName": "Nagarkurnool",
      "CityValue": "Nagarkurnool"
    },
    {
      "id": 2437,
      "CityName": "Rania",
      "CityValue": "Rania"
    },
    {
      "id": 2438,
      "CityName": "Darwha",
      "CityValue": "Darwha"
    },
    {
      "id": 2439,
      "CityName": "Siddharthnagar",
      "CityValue": "Siddharthnagar"
    },
    {
      "id": 2440,
      "CityName": "Dabra",
      "CityValue": "Dabra"
    },
    {
      "id": 2441,
      "CityName": "Raghunathpur",
      "CityValue": "Raghunathpur"
    },
    {
      "id": 2442,
      "CityName": "Bagha Purana",
      "CityValue": "Bagha Purana"
    },
    {
      "id": 2443,
      "CityName": "Kalamnuri",
      "CityValue": "Kalamnuri"
    },
    {
      "id": 2444,
      "CityName": "Ezhupunna",
      "CityValue": "Ezhupunna"
    },
    {
      "id": 2445,
      "CityName": "Ponmundam",
      "CityValue": "Ponmundam"
    },
    {
      "id": 2446,
      "CityName": "Moothakunnam",
      "CityValue": "Moothakunnam"
    },
    {
      "id": 2447,
      "CityName": "Senapiratti",
      "CityValue": "Senapiratti"
    },
    {
      "id": 2448,
      "CityName": "Silapathar",
      "CityValue": "Silapathar"
    },
    {
      "id": 2449,
      "CityName": "Kattiganapalli",
      "CityValue": "Kattiganapalli"
    },
    {
      "id": 2450,
      "CityName": "Villimar Kundi",
      "CityValue": "Villimar Kundi"
    },
    {
      "id": 2451,
      "CityName": "Uthiramerur",
      "CityValue": "Uthiramerur"
    },
    {
      "id": 2452,
      "CityName": "Jaynagar Mazilpur",
      "CityValue": "Jaynagar Mazilpur"
    },
    {
      "id": 2453,
      "CityName": "Elamkunnapuzha",
      "CityValue": "Elamkunnapuzha"
    },
    {
      "id": 2454,
      "CityName": "Talode",
      "CityValue": "Talode"
    },
    {
      "id": 2455,
      "CityName": "Sarenga",
      "CityValue": "Sarenga"
    },
    {
      "id": 2456,
      "CityName": "Nabinagar",
      "CityValue": "Nabinagar"
    },
    {
      "id": 2457,
      "CityName": "Thiruverumbur",
      "CityValue": "Thiruverumbur"
    },
    {
      "id": 2458,
      "CityName": "Kandhar",
      "CityValue": "Kandhar"
    },
    {
      "id": 2459,
      "CityName": "Kalady",
      "CityValue": "Kalady"
    },
    {
      "id": 2460,
      "CityName": "Mani Majra",
      "CityValue": "Mani Majra"
    },
    {
      "id": 2461,
      "CityName": "Kotagiri",
      "CityValue": "Kotagiri"
    },
    {
      "id": 2462,
      "CityName": "Mangalam",
      "CityValue": "Mangalam"
    },
    {
      "id": 2463,
      "CityName": "Kheri Nangal",
      "CityValue": "Kheri Nangal"
    },
    {
      "id": 2464,
      "CityName": "Kushtagi",
      "CityValue": "Kushtagi"
    },
    {
      "id": 2465,
      "CityName": "Maheshwar",
      "CityValue": "Maheshwar"
    },
    {
      "id": 2466,
      "CityName": "Kachigam",
      "CityValue": "Kachigam"
    },
    {
      "id": 2467,
      "CityName": "Thodiyoor",
      "CityValue": "Thodiyoor"
    },
    {
      "id": 2468,
      "CityName": "Chhota Udaipur",
      "CityValue": "Chhota Udaipur"
    },
    {
      "id": 2469,
      "CityName": "Babina",
      "CityValue": "Babina"
    },
    {
      "id": 2470,
      "CityName": "Tarana",
      "CityValue": "Tarana"
    },
    {
      "id": 2471,
      "CityName": "Mangaldoi",
      "CityValue": "Mangaldoi"
    },
    {
      "id": 2472,
      "CityName": "Phulwaria",
      "CityValue": "Phulwaria"
    },
    {
      "id": 2473,
      "CityName": "Aali",
      "CityValue": "Aali"
    },
    {
      "id": 2474,
      "CityName": "Khamaria",
      "CityValue": "Khamaria"
    },
    {
      "id": 2475,
      "CityName": "Sarjamda",
      "CityValue": "Sarjamda"
    },
    {
      "id": 2476,
      "CityName": "Suryaraopeta",
      "CityValue": "Suryaraopeta"
    },
    {
      "id": 2477,
      "CityName": "Kalamb",
      "CityValue": "Kalamb"
    },
    {
      "id": 2478,
      "CityName": "Anaiyur",
      "CityValue": "Anaiyur"
    },
    {
      "id": 2479,
      "CityName": "Dharampur",
      "CityValue": "Dharampur"
    },
    {
      "id": 2480,
      "CityName": "Nandri",
      "CityValue": "Nandri"
    },
    {
      "id": 2481,
      "CityName": "Beohari",
      "CityValue": "Beohari"
    },
    {
      "id": 2482,
      "CityName": "Kakkanad",
      "CityValue": "Kakkanad"
    },
    {
      "id": 2483,
      "CityName": "Bholav",
      "CityValue": "Bholav"
    },
    {
      "id": 2484,
      "CityName": "Ettumanoor",
      "CityValue": "Ettumanoor"
    },
    {
      "id": 2485,
      "CityName": "Nagavakulam",
      "CityValue": "Nagavakulam"
    },
    {
      "id": 2486,
      "CityName": "Mangrol",
      "CityValue": "Mangrol"
    },
    {
      "id": 2487,
      "CityName": "Kiraoali",
      "CityValue": "Kiraoali"
    },
    {
      "id": 2488,
      "CityName": "Mul",
      "CityValue": "Mul"
    },
    {
      "id": 2489,
      "CityName": "Mohammadabad",
      "CityValue": "Mohammadabad"
    },
    {
      "id": 2490,
      "CityName": "Falna",
      "CityValue": "Falna"
    },
    {
      "id": 2491,
      "CityName": "Sasauli",
      "CityValue": "Sasauli"
    },
    {
      "id": 2492,
      "CityName": "Kupwara",
      "CityValue": "Kupwara"
    },
    {
      "id": 2493,
      "CityName": "Nainpur",
      "CityValue": "Nainpur"
    },
    {
      "id": 2494,
      "CityName": "Thazhecode",
      "CityValue": "Thazhecode"
    },
    {
      "id": 2495,
      "CityName": "Deori",
      "CityValue": "Deori"
    },
    {
      "id": 2496,
      "CityName": "Muthuthala",
      "CityValue": "Muthuthala"
    },
    {
      "id": 2497,
      "CityName": "Daurala",
      "CityValue": "Daurala"
    },
    {
      "id": 2498,
      "CityName": "Shenkottai",
      "CityValue": "Shenkottai"
    },
    {
      "id": 2499,
      "CityName": "Mulamthuruthy",
      "CityValue": "Mulamthuruthy"
    },
    {
      "id": 2500,
      "CityName": "Marutharode",
      "CityValue": "Marutharode"
    },
    {
      "id": 2501,
      "CityName": "Krishnapuram",
      "CityValue": "Krishnapuram"
    },
    {
      "id": 2502,
      "CityName": "Brahmana Periya Agraharam",
      "CityValue": "Brahmana Periya Agraharam"
    },
    {
      "id": 2503,
      "CityName": "Adichanalloor",
      "CityValue": "Adichanalloor"
    },
    {
      "id": 2504,
      "CityName": "Sirauli",
      "CityValue": "Sirauli"
    },
    {
      "id": 2505,
      "CityName": "Tirora",
      "CityValue": "Tirora"
    },
    {
      "id": 2506,
      "CityName": "Kuthanallur",
      "CityValue": "Kuthanallur"
    },
    {
      "id": 2507,
      "CityName": "Ulundurpettai",
      "CityValue": "Ulundurpettai"
    },
    {
      "id": 2508,
      "CityName": "Kalladaikurichi",
      "CityValue": "Kalladaikurichi"
    },
    {
      "id": 2509,
      "CityName": "Ayancheri",
      "CityValue": "Ayancheri"
    },
    {
      "id": 2510,
      "CityName": "Iringal",
      "CityValue": "Iringal"
    },
    {
      "id": 2511,
      "CityName": "Pindwara",
      "CityValue": "Pindwara"
    },
    {
      "id": 2512,
      "CityName": "Sohagpur",
      "CityValue": "Sohagpur"
    },
    {
      "id": 2513,
      "CityName": "Keshoraipatan",
      "CityValue": "Keshoraipatan"
    },
    {
      "id": 2514,
      "CityName": "Sahebganj",
      "CityValue": "Sahebganj"
    },
    {
      "id": 2515,
      "CityName": "Umru Khurd",
      "CityValue": "Umru Khurd"
    },
    {
      "id": 2516,
      "CityName": "Pukhrayan",
      "CityValue": "Pukhrayan"
    },
    {
      "id": 2517,
      "CityName": "Naduvannur",
      "CityValue": "Naduvannur"
    },
    {
      "id": 2518,
      "CityName": "Kuttippuram",
      "CityValue": "Kuttippuram"
    },
    {
      "id": 2519,
      "CityName": "Kumarapuram",
      "CityValue": "Kumarapuram"
    },
    {
      "id": 2520,
      "CityName": "Hinjilicut",
      "CityValue": "Hinjilicut"
    },
    {
      "id": 2521,
      "CityName": "Barua Sagar",
      "CityValue": "Barua Sagar"
    },
    {
      "id": 2522,
      "CityName": "Mairwa",
      "CityValue": "Mairwa"
    },
    {
      "id": 2523,
      "CityName": "Mohenpur",
      "CityValue": "Mohenpur"
    },
    {
      "id": 2524,
      "CityName": "Azhoor",
      "CityValue": "Azhoor"
    },
    {
      "id": 2525,
      "CityName": "Chipyana Buzurg",
      "CityValue": "Chipyana Buzurg"
    },
    {
      "id": 2526,
      "CityName": "Thirupuvanam",
      "CityValue": "Thirupuvanam"
    },
    {
      "id": 2527,
      "CityName": "Mayang Imphal",
      "CityValue": "Mayang Imphal"
    },
    {
      "id": 2528,
      "CityName": "Lingiyadih",
      "CityValue": "Lingiyadih"
    },
    {
      "id": 2529,
      "CityName": "Kaithoon",
      "CityValue": "Kaithoon"
    },
    {
      "id": 2530,
      "CityName": "Loha",
      "CityValue": "Loha"
    },
    {
      "id": 2531,
      "CityName": "Gunnaur",
      "CityValue": "Gunnaur"
    },
    {
      "id": 2532,
      "CityName": "Vengikkal",
      "CityValue": "Vengikkal"
    },
    {
      "id": 2533,
      "CityName": "Pokaran",
      "CityValue": "Pokaran"
    },
    {
      "id": 2534,
      "CityName": "Junnar",
      "CityValue": "Junnar"
    },
    {
      "id": 2535,
      "CityName": "Mudkhed",
      "CityValue": "Mudkhed"
    },
    {
      "id": 2536,
      "CityName": "Purwa",
      "CityValue": "Purwa"
    },
    {
      "id": 2537,
      "CityName": "Taoru",
      "CityValue": "Taoru"
    },
    {
      "id": 2538,
      "CityName": "Kotwa",
      "CityValue": "Kotwa"
    },
    {
      "id": 2539,
      "CityName": "Ukhra",
      "CityValue": "Ukhra"
    },
    {
      "id": 2540,
      "CityName": "Mhaswad",
      "CityValue": "Mhaswad"
    },
    {
      "id": 2541,
      "CityName": "Haliyal",
      "CityValue": "Haliyal"
    },
    {
      "id": 2542,
      "CityName": "Pattan",
      "CityValue": "Pattan"
    },
    {
      "id": 2543,
      "CityName": "Chitlapakkam",
      "CityValue": "Chitlapakkam"
    },
    {
      "id": 2544,
      "CityName": "Veerakeralam",
      "CityValue": "Veerakeralam"
    },
    {
      "id": 2545,
      "CityName": "Shamgarh",
      "CityValue": "Shamgarh"
    },
    {
      "id": 2546,
      "CityName": "Tulsipur",
      "CityValue": "Tulsipur"
    },
    {
      "id": 2547,
      "CityName": "Kheda",
      "CityValue": "Kheda"
    },
    {
      "id": 2548,
      "CityName": "Pethapur",
      "CityValue": "Pethapur"
    },
    {
      "id": 2549,
      "CityName": "Tirwaganj",
      "CityValue": "Tirwaganj"
    },
    {
      "id": 2550,
      "CityName": "Maharajganj",
      "CityValue": "Maharajganj"
    },
    {
      "id": 2551,
      "CityName": "Sahnewal",
      "CityValue": "Sahnewal"
    },
    {
      "id": 2552,
      "CityName": "Ratu",
      "CityValue": "Ratu"
    },
    {
      "id": 2553,
      "CityName": "Someshwar",
      "CityValue": "Someshwar"
    },
    {
      "id": 2554,
      "CityName": "Edaicode",
      "CityValue": "Edaicode"
    },
    {
      "id": 2555,
      "CityName": "Hukumpeta",
      "CityValue": "Hukumpeta"
    },
    {
      "id": 2556,
      "CityName": "Mal",
      "CityValue": "Mal"
    },
    {
      "id": 2557,
      "CityName": "Pushkar",
      "CityValue": "Pushkar"
    },
    {
      "id": 2558,
      "CityName": "Jigani",
      "CityValue": "Jigani"
    },
    {
      "id": 2559,
      "CityName": "Godoli",
      "CityValue": "Godoli"
    },
    {
      "id": 2560,
      "CityName": "Pappankurichi",
      "CityValue": "Pappankurichi"
    },
    {
      "id": 2561,
      "CityName": "Koregaon",
      "CityValue": "Koregaon"
    },
    {
      "id": 2562,
      "CityName": "Eranholi",
      "CityValue": "Eranholi"
    },
    {
      "id": 2563,
      "CityName": "P.N.Patti",
      "CityValue": "P.N.Patti"
    },
    {
      "id": 2564,
      "CityName": "Kolaghat",
      "CityValue": "Kolaghat"
    },
    {
      "id": 2565,
      "CityName": "Muhamma",
      "CityValue": "Muhamma"
    },
    {
      "id": 2566,
      "CityName": "Periyapatti",
      "CityValue": "Periyapatti"
    },
    {
      "id": 2567,
      "CityName": "Salar",
      "CityValue": "Salar"
    },
    {
      "id": 2568,
      "CityName": "Kardhan",
      "CityValue": "Kardhan"
    },
    {
      "id": 2569,
      "CityName": "Uska Bazar",
      "CityValue": "Uska Bazar"
    },
    {
      "id": 2570,
      "CityName": "Ode",
      "CityValue": "Ode"
    },
    {
      "id": 2571,
      "CityName": "Longowal",
      "CityValue": "Longowal"
    },
    {
      "id": 2572,
      "CityName": "Padianallur",
      "CityValue": "Padianallur"
    },
    {
      "id": 2573,
      "CityName": "Thisayanvilai",
      "CityValue": "Thisayanvilai"
    },
    {
      "id": 2574,
      "CityName": "Khirkiya",
      "CityValue": "Khirkiya"
    },
    {
      "id": 2575,
      "CityName": "Phillaur",
      "CityValue": "Phillaur"
    },
    {
      "id": 2576,
      "CityName": "Sankaraperi",
      "CityValue": "Sankaraperi"
    },
    {
      "id": 2577,
      "CityName": "Sahawar",
      "CityValue": "Sahawar"
    },
    {
      "id": 2578,
      "CityName": "Sadulshahar",
      "CityValue": "Sadulshahar"
    },
    {
      "id": 2579,
      "CityName": "Pasighat",
      "CityValue": "Pasighat"
    },
    {
      "id": 2580,
      "CityName": "Karumandi Chellipalayam",
      "CityValue": "Karumandi Chellipalayam"
    },
    {
      "id": 2581,
      "CityName": "Kartarpur",
      "CityValue": "Kartarpur"
    },
    {
      "id": 2582,
      "CityName": "Talikkulam",
      "CityValue": "Talikkulam"
    },
    {
      "id": 2583,
      "CityName": "Jhinjhak",
      "CityValue": "Jhinjhak"
    },
    {
      "id": 2584,
      "CityName": "Nanauta",
      "CityValue": "Nanauta"
    },
    {
      "id": 2585,
      "CityName": "Karkal",
      "CityValue": "Karkal"
    },
    {
      "id": 2586,
      "CityName": "Chhata",
      "CityValue": "Chhata"
    },
    {
      "id": 2587,
      "CityName": "Sancoale",
      "CityValue": "Sancoale"
    },
    {
      "id": 2588,
      "CityName": "Panayam",
      "CityValue": "Panayam"
    },
    {
      "id": 2589,
      "CityName": "Kallur Vadakkummuri",
      "CityValue": "Kallur Vadakkummuri"
    },
    {
      "id": 2590,
      "CityName": "Dalsinghsarai",
      "CityValue": "Dalsinghsarai"
    },
    {
      "id": 2591,
      "CityName": "Narayanpur",
      "CityValue": "Narayanpur"
    },
    {
      "id": 2592,
      "CityName": "Mundra",
      "CityValue": "Mundra"
    },
    {
      "id": 2593,
      "CityName": "Dhulagari",
      "CityValue": "Dhulagari"
    },
    {
      "id": 2594,
      "CityName": "Allapur",
      "CityValue": "Allapur"
    },
    {
      "id": 2595,
      "CityName": "Paranpara",
      "CityValue": "Paranpara"
    },
    {
      "id": 2596,
      "CityName": "Kuda",
      "CityValue": "Kuda"
    },
    {
      "id": 2597,
      "CityName": "Mandi",
      "CityValue": "Mandi"
    },
    {
      "id": 2598,
      "CityName": "Tapa",
      "CityValue": "Tapa"
    },
    {
      "id": 2599,
      "CityName": "Baruipur",
      "CityValue": "Baruipur"
    },
    {
      "id": 2600,
      "CityName": "Saidpur",
      "CityValue": "Saidpur"
    },
    {
      "id": 2601,
      "CityName": "Chitgoppa",
      "CityValue": "Chitgoppa"
    },
    {
      "id": 2602,
      "CityName": "Lalru",
      "CityValue": "Lalru"
    },
    {
      "id": 2603,
      "CityName": "Siuliban",
      "CityValue": "Siuliban"
    },
    {
      "id": 2604,
      "CityName": "Bijbehara",
      "CityValue": "Bijbehara"
    },
    {
      "id": 2605,
      "CityName": "Navalgund",
      "CityValue": "Navalgund"
    },
    {
      "id": 2606,
      "CityName": "Waluj Bk.",
      "CityValue": "Waluj Bk."
    },
    {
      "id": 2607,
      "CityName": "Gopjan",
      "CityValue": "Gopjan"
    },
    {
      "id": 2608,
      "CityName": "Ahraura",
      "CityValue": "Ahraura"
    },
    {
      "id": 2609,
      "CityName": "Dhaura Tanda",
      "CityValue": "Dhaura Tanda"
    },
    {
      "id": 2610,
      "CityName": "Senneerkuppam",
      "CityValue": "Senneerkuppam"
    },
    {
      "id": 2611,
      "CityName": "Kuchera",
      "CityValue": "Kuchera"
    },
    {
      "id": 2612,
      "CityName": "Pauri",
      "CityValue": "Pauri"
    },
    {
      "id": 2613,
      "CityName": "Mehgaon",
      "CityValue": "Mehgaon"
    },
    {
      "id": 2614,
      "CityName": "Bhadauni",
      "CityValue": "Bhadauni"
    },
    {
      "id": 2615,
      "CityName": "Sadalgi",
      "CityValue": "Sadalgi"
    },
    {
      "id": 2616,
      "CityName": "Bhinga",
      "CityValue": "Bhinga"
    },
    {
      "id": 2617,
      "CityName": "Thiriya Nizamat Khan",
      "CityValue": "Thiriya Nizamat Khan"
    },
    {
      "id": 2618,
      "CityName": "Chhiri",
      "CityValue": "Chhiri"
    },
    {
      "id": 2619,
      "CityName": "Balarampur",
      "CityValue": "Balarampur"
    },
    {
      "id": 2620,
      "CityName": "Naraingarh",
      "CityValue": "Naraingarh"
    },
    {
      "id": 2621,
      "CityName": "Fatehabad",
      "CityValue": "Fatehabad"
    },
    {
      "id": 2622,
      "CityName": "Azhikode South",
      "CityValue": "Azhikode South"
    },
    {
      "id": 2623,
      "CityName": "Barughutu",
      "CityValue": "Barughutu"
    },
    {
      "id": 2624,
      "CityName": "Karunagappally",
      "CityValue": "Karunagappally"
    },
    {
      "id": 2625,
      "CityName": "Suket",
      "CityValue": "Suket"
    },
    {
      "id": 2626,
      "CityName": "Nasriganj",
      "CityValue": "Nasriganj"
    },
    {
      "id": 2627,
      "CityName": "Shrirangapattana",
      "CityValue": "Shrirangapattana"
    },
    {
      "id": 2628,
      "CityName": "Purdilnagar",
      "CityValue": "Purdilnagar"
    },
    {
      "id": 2629,
      "CityName": "Jagadanandapur",
      "CityValue": "Jagadanandapur"
    },
    {
      "id": 2630,
      "CityName": "Bakalpur Mathura",
      "CityValue": "Bakalpur Mathura"
    },
    {
      "id": 2631,
      "CityName": "Narasingapuram",
      "CityValue": "Narasingapuram"
    },
    {
      "id": 2632,
      "CityName": "Seondha",
      "CityValue": "Seondha"
    },
    {
      "id": 2633,
      "CityName": "Palavansathu",
      "CityValue": "Palavansathu"
    },
    {
      "id": 2634,
      "CityName": "Diu",
      "CityValue": "Diu"
    },
    {
      "id": 2635,
      "CityName": "Sahaspur",
      "CityValue": "Sahaspur"
    },
    {
      "id": 2636,
      "CityName": "Thrikkaruva",
      "CityValue": "Thrikkaruva"
    },
    {
      "id": 2637,
      "CityName": "Alot",
      "CityValue": "Alot"
    },
    {
      "id": 2638,
      "CityName": "Dhamanagar",
      "CityValue": "Dhamanagar"
    },
    {
      "id": 2639,
      "CityName": "Devarshola",
      "CityValue": "Devarshola"
    },
    {
      "id": 2640,
      "CityName": "Govardhan",
      "CityValue": "Govardhan"
    },
    {
      "id": 2641,
      "CityName": "Bhimaram",
      "CityValue": "Bhimaram"
    },
    {
      "id": 2642,
      "CityName": "Ponda",
      "CityValue": "Ponda"
    },
    {
      "id": 2643,
      "CityName": "Chekkiad",
      "CityValue": "Chekkiad"
    },
    {
      "id": 2644,
      "CityName": "Ghuni",
      "CityValue": "Ghuni"
    },
    {
      "id": 2645,
      "CityName": "Niwari",
      "CityValue": "Niwari"
    },
    {
      "id": 2646,
      "CityName": "Lonar",
      "CityValue": "Lonar"
    },
    {
      "id": 2647,
      "CityName": "Dakor",
      "CityValue": "Dakor"
    },
    {
      "id": 2648,
      "CityName": "Chandrakona",
      "CityValue": "Chandrakona"
    },
    {
      "id": 2649,
      "CityName": "Dina Nagar",
      "CityValue": "Dina Nagar"
    },
    {
      "id": 2650,
      "CityName": "Central Hope Town",
      "CityValue": "Central Hope Town"
    },
    {
      "id": 2651,
      "CityName": "Kumher",
      "CityValue": "Kumher"
    },
    {
      "id": 2652,
      "CityName": "Kottivakkam",
      "CityValue": "Kottivakkam"
    },
    {
      "id": 2653,
      "CityName": "Rajmahal",
      "CityValue": "Rajmahal"
    },
    {
      "id": 2654,
      "CityName": "Pampora",
      "CityValue": "Pampora"
    },
    {
      "id": 2655,
      "CityName": "Ahmadnagar",
      "CityValue": "Ahmadnagar"
    },
    {
      "id": 2656,
      "CityName": "Lawngtlai",
      "CityValue": "Lawngtlai"
    },
    {
      "id": 2657,
      "CityName": "Mathabhanga",
      "CityValue": "Mathabhanga"
    },
    {
      "id": 2658,
      "CityName": "Parasi",
      "CityValue": "Parasi"
    },
    {
      "id": 2659,
      "CityName": "Bewar",
      "CityValue": "Bewar"
    },
    {
      "id": 2660,
      "CityName": "Dainhat",
      "CityValue": "Dainhat"
    },
    {
      "id": 2661,
      "CityName": "Borawar",
      "CityValue": "Borawar"
    },
    {
      "id": 2662,
      "CityName": "Mannarai",
      "CityValue": "Mannarai"
    },
    {
      "id": 2663,
      "CityName": "Mavelikkara",
      "CityValue": "Mavelikkara"
    },
    {
      "id": 2664,
      "CityName": "Unnamalaikadai",
      "CityValue": "Unnamalaikadai"
    },
    {
      "id": 2665,
      "CityName": "Punjaipugalur",
      "CityValue": "Punjaipugalur"
    },
    {
      "id": 2666,
      "CityName": "Kudchi",
      "CityValue": "Kudchi"
    },
    {
      "id": 2667,
      "CityName": "Samalapuram",
      "CityValue": "Samalapuram"
    },
    {
      "id": 2668,
      "CityName": "Thiruthuraipoondi",
      "CityValue": "Thiruthuraipoondi"
    },
    {
      "id": 2669,
      "CityName": "Todaraisingh",
      "CityValue": "Todaraisingh"
    },
    {
      "id": 2670,
      "CityName": "Sarapaka",
      "CityValue": "Sarapaka"
    },
    {
      "id": 2671,
      "CityName": "Ayanivelikulangara",
      "CityValue": "Ayanivelikulangara"
    },
    {
      "id": 2672,
      "CityName": "Bhawanigarh",
      "CityValue": "Bhawanigarh"
    },
    {
      "id": 2673,
      "CityName": "Katghora",
      "CityValue": "Katghora"
    },
    {
      "id": 2674,
      "CityName": "Polasara",
      "CityValue": "Polasara"
    },
    {
      "id": 2675,
      "CityName": "Handia",
      "CityValue": "Handia"
    },
    {
      "id": 2676,
      "CityName": "Palda",
      "CityValue": "Palda"
    },
    {
      "id": 2677,
      "CityName": "Quammruddin Nagar",
      "CityValue": "Quammruddin Nagar"
    },
    {
      "id": 2678,
      "CityName": "Vadakkanandal",
      "CityValue": "Vadakkanandal"
    },
    {
      "id": 2679,
      "CityName": "Morinda",
      "CityValue": "Morinda"
    },
    {
      "id": 2680,
      "CityName": "Sikanderpur",
      "CityValue": "Sikanderpur"
    },
    {
      "id": 2681,
      "CityName": "Chhanera",
      "CityValue": "Chhanera"
    },
    {
      "id": 2682,
      "CityName": "Sewarhi",
      "CityValue": "Sewarhi"
    },
    {
      "id": 2683,
      "CityName": "Marampilly",
      "CityValue": "Marampilly"
    },
    {
      "id": 2684,
      "CityName": "Khedbrahma",
      "CityValue": "Khedbrahma"
    },
    {
      "id": 2685,
      "CityName": "Chethipuzha",
      "CityValue": "Chethipuzha"
    },
    {
      "id": 2686,
      "CityName": "Chikkabanavara",
      "CityValue": "Chikkabanavara"
    },
    {
      "id": 2687,
      "CityName": "Obedullaganj",
      "CityValue": "Obedullaganj"
    },
    {
      "id": 2688,
      "CityName": "Warhapur",
      "CityValue": "Warhapur"
    },
    {
      "id": 2689,
      "CityName": "Sakri",
      "CityValue": "Sakri"
    },
    {
      "id": 2690,
      "CityName": "Bikram",
      "CityValue": "Bikram"
    },
    {
      "id": 2691,
      "CityName": "Balod",
      "CityValue": "Balod"
    },
    {
      "id": 2692,
      "CityName": "Gauripur",
      "CityValue": "Gauripur"
    },
    {
      "id": 2693,
      "CityName": "Kasrawad",
      "CityValue": "Kasrawad"
    },
    {
      "id": 2694,
      "CityName": "Bijapur",
      "CityValue": "Bijapur"
    },
    {
      "id": 2695,
      "CityName": "Mankachar",
      "CityValue": "Mankachar"
    },
    {
      "id": 2696,
      "CityName": "Chitar Pur",
      "CityValue": "Chitar Pur"
    },
    {
      "id": 2697,
      "CityName": "Khagrabari",
      "CityValue": "Khagrabari"
    },
    {
      "id": 2698,
      "CityName": "Clement Town",
      "CityValue": "Clement Town"
    },
    {
      "id": 2699,
      "CityName": "Mudgal",
      "CityValue": "Mudgal"
    },
    {
      "id": 2700,
      "CityName": "Dugda",
      "CityValue": "Dugda"
    },
    {
      "id": 2701,
      "CityName": "Chotila",
      "CityValue": "Chotila"
    },
    {
      "id": 2702,
      "CityName": "Chachaura-Binaganj",
      "CityValue": "Chachaura-Binaganj"
    },
    {
      "id": 2703,
      "CityName": "Sarigam",
      "CityValue": "Sarigam"
    },
    {
      "id": 2704,
      "CityName": "Jirapur",
      "CityValue": "Jirapur"
    },
    {
      "id": 2705,
      "CityName": "Kushinagar",
      "CityValue": "Kushinagar"
    },
    {
      "id": 2706,
      "CityName": "Churi",
      "CityValue": "Churi"
    },
    {
      "id": 2707,
      "CityName": "Asifabad",
      "CityValue": "Asifabad"
    },
    {
      "id": 2708,
      "CityName": "Katheru",
      "CityValue": "Katheru"
    },
    {
      "id": 2709,
      "CityName": "Aralvaimozhi",
      "CityValue": "Aralvaimozhi"
    },
    {
      "id": 2710,
      "CityName": "Painkulam",
      "CityValue": "Painkulam"
    },
    {
      "id": 2711,
      "CityName": "Thirunavaya",
      "CityValue": "Thirunavaya"
    },
    {
      "id": 2712,
      "CityName": "Thiruneermalai",
      "CityValue": "Thiruneermalai"
    },
    {
      "id": 2713,
      "CityName": "Hukeri",
      "CityValue": "Hukeri"
    },
    {
      "id": 2714,
      "CityName": "Fatehnagar",
      "CityValue": "Fatehnagar"
    },
    {
      "id": 2715,
      "CityName": "Raipur Domana",
      "CityValue": "Raipur Domana"
    },
    {
      "id": 2716,
      "CityName": "Vayalar",
      "CityValue": "Vayalar"
    },
    {
      "id": 2717,
      "CityName": "Qadian",
      "CityValue": "Qadian"
    },
    {
      "id": 2718,
      "CityName": "Pacode",
      "CityValue": "Pacode"
    },
    {
      "id": 2719,
      "CityName": "Nildoh",
      "CityValue": "Nildoh"
    },
    {
      "id": 2720,
      "CityName": "Mahadeb Nagar",
      "CityValue": "Mahadeb Nagar"
    },
    {
      "id": 2721,
      "CityName": "Pallikonda",
      "CityValue": "Pallikonda"
    },
    {
      "id": 2722,
      "CityName": "Uttar Raypur",
      "CityValue": "Uttar Raypur"
    },
    {
      "id": 2723,
      "CityName": "Dergaon",
      "CityValue": "Dergaon"
    },
    {
      "id": 2724,
      "CityName": "Lehragaga",
      "CityValue": "Lehragaga"
    },
    {
      "id": 2725,
      "CityName": "Mandawa",
      "CityValue": "Mandawa"
    },
    {
      "id": 2726,
      "CityName": "Pothatturpettai",
      "CityValue": "Pothatturpettai"
    },
    {
      "id": 2727,
      "CityName": "Wazirganj",
      "CityValue": "Wazirganj"
    },
    {
      "id": 2728,
      "CityName": "Chandauli",
      "CityValue": "Chandauli"
    },
    {
      "id": 2729,
      "CityName": "Achhnera",
      "CityValue": "Achhnera"
    },
    {
      "id": 2730,
      "CityName": "Kakgaina",
      "CityValue": "Kakgaina"
    },
    {
      "id": 2731,
      "CityName": "Ariyallur",
      "CityValue": "Ariyallur"
    },
    {
      "id": 2732,
      "CityName": "Ghorabandha",
      "CityValue": "Ghorabandha"
    },
    {
      "id": 2733,
      "CityName": "Muzhappilangad",
      "CityValue": "Muzhappilangad"
    },
    {
      "id": 2734,
      "CityName": "Madhira",
      "CityValue": "Madhira"
    },
    {
      "id": 2735,
      "CityName": "Sindi Turf Hindnagar",
      "CityValue": "Sindi Turf Hindnagar"
    },
    {
      "id": 2736,
      "CityName": "Lawar",
      "CityValue": "Lawar"
    },
    {
      "id": 2737,
      "CityName": "Pantheeramkavu",
      "CityValue": "Pantheeramkavu"
    },
    {
      "id": 2738,
      "CityName": "Prantij",
      "CityValue": "Prantij"
    },
    {
      "id": 2739,
      "CityName": "Sivagiri",
      "CityValue": "Sivagiri"
    },
    {
      "id": 2740,
      "CityName": "Podara",
      "CityValue": "Podara"
    },
    {
      "id": 2741,
      "CityName": "Tharangambadi",
      "CityValue": "Tharangambadi"
    },
    {
      "id": 2742,
      "CityName": "Laksar",
      "CityValue": "Laksar"
    },
    {
      "id": 2743,
      "CityName": "Jaitaran",
      "CityValue": "Jaitaran"
    },
    {
      "id": 2744,
      "CityName": "Badami Bagh",
      "CityValue": "Badami Bagh"
    },
    {
      "id": 2745,
      "CityName": "Bhabhar",
      "CityValue": "Bhabhar"
    },
    {
      "id": 2746,
      "CityName": "Sreekaryam",
      "CityValue": "Sreekaryam"
    },
    {
      "id": 2747,
      "CityName": "Rahta Pimplas",
      "CityValue": "Rahta Pimplas"
    },
    {
      "id": 2748,
      "CityName": "Maharajpur",
      "CityValue": "Maharajpur"
    },
    {
      "id": 2749,
      "CityName": "Kral Pora",
      "CityValue": "Kral Pora"
    },
    {
      "id": 2750,
      "CityName": "Ankola",
      "CityValue": "Ankola"
    },
    {
      "id": 2751,
      "CityName": "Natham",
      "CityValue": "Natham"
    },
    {
      "id": 2752,
      "CityName": "Tittakudi",
      "CityValue": "Tittakudi"
    },
    {
      "id": 2753,
      "CityName": "Athimarapatti",
      "CityValue": "Athimarapatti"
    },
    {
      "id": 2754,
      "CityName": "Raman",
      "CityValue": "Raman"
    },
    {
      "id": 2755,
      "CityName": "Sundarnagar",
      "CityValue": "Sundarnagar"
    },
    {
      "id": 2756,
      "CityName": "Kuppam",
      "CityValue": "Kuppam"
    },
    {
      "id": 2757,
      "CityName": "Timarni",
      "CityValue": "Timarni"
    },
    {
      "id": 2758,
      "CityName": "Nawa",
      "CityValue": "Nawa"
    },
    {
      "id": 2759,
      "CityName": "Bissau",
      "CityValue": "Bissau"
    },
    {
      "id": 2760,
      "CityName": "Nandgaon",
      "CityValue": "Nandgaon"
    },
    {
      "id": 2761,
      "CityName": "Nagod",
      "CityValue": "Nagod"
    },
    {
      "id": 2762,
      "CityName": "Muthupet",
      "CityValue": "Muthupet"
    },
    {
      "id": 2763,
      "CityName": "Thuneri",
      "CityValue": "Thuneri"
    },
    {
      "id": 2764,
      "CityName": "Jatragachhi",
      "CityValue": "Jatragachhi"
    },
    {
      "id": 2765,
      "CityName": "Todabhim",
      "CityValue": "Todabhim"
    },
    {
      "id": 2766,
      "CityName": "Sawantwadi",
      "CityValue": "Sawantwadi"
    },
    {
      "id": 2767,
      "CityName": "Naraura",
      "CityValue": "Naraura"
    },
    {
      "id": 2768,
      "CityName": "Phulpur",
      "CityValue": "Phulpur"
    },
    {
      "id": 2769,
      "CityName": "Andankoil East",
      "CityValue": "Andankoil East"
    },
    {
      "id": 2770,
      "CityName": "Urmar Tanda",
      "CityValue": "Urmar Tanda"
    },
    {
      "id": 2771,
      "CityName": "Pakriya Naugwan Mustqil",
      "CityValue": "Pakriya Naugwan Mustqil"
    },
    {
      "id": 2772,
      "CityName": "Kizhuparamba",
      "CityValue": "Kizhuparamba"
    },
    {
      "id": 2773,
      "CityName": "Mariahu",
      "CityValue": "Mariahu"
    },
    {
      "id": 2774,
      "CityName": "Rosalpatti",
      "CityValue": "Rosalpatti"
    },
    {
      "id": 2775,
      "CityName": "Mangalam",
      "CityValue": "Mangalam"
    },
    {
      "id": 2776,
      "CityName": "Ron",
      "CityValue": "Ron"
    },
    {
      "id": 2777,
      "CityName": "Lalgudi",
      "CityValue": "Lalgudi"
    },
    {
      "id": 2778,
      "CityName": "Kanjikkuzhi",
      "CityValue": "Kanjikkuzhi"
    },
    {
      "id": 2779,
      "CityName": "Akaltara",
      "CityValue": "Akaltara"
    },
    {
      "id": 2780,
      "CityName": "Kunnathunad",
      "CityValue": "Kunnathunad"
    },
    {
      "id": 2781,
      "CityName": "Nambol",
      "CityValue": "Nambol"
    },
    {
      "id": 2782,
      "CityName": "Vilathurai",
      "CityValue": "Vilathurai"
    },
    {
      "id": 2783,
      "CityName": "Banki",
      "CityValue": "Banki"
    },
    {
      "id": 2784,
      "CityName": "Shyampur",
      "CityValue": "Shyampur"
    },
    {
      "id": 2785,
      "CityName": "Bagula",
      "CityValue": "Bagula"
    },
    {
      "id": 2786,
      "CityName": "Lalganj",
      "CityValue": "Lalganj"
    },
    {
      "id": 2787,
      "CityName": "Chitrakoot",
      "CityValue": "Chitrakoot"
    },
    {
      "id": 2788,
      "CityName": "Marakkanam",
      "CityValue": "Marakkanam"
    },
    {
      "id": 2789,
      "CityName": "Karad",
      "CityValue": "Karad"
    },
    {
      "id": 2790,
      "CityName": "Chanod",
      "CityValue": "Chanod"
    },
    {
      "id": 2791,
      "CityName": "Cooper's Camp",
      "CityValue": "Cooper's Camp"
    },
    {
      "id": 2792,
      "CityName": "Balachaur",
      "CityValue": "Balachaur"
    },
    {
      "id": 2793,
      "CityName": "Kanhan",
      "CityValue": "Kanhan"
    },
    {
      "id": 2794,
      "CityName": "Rajgarh",
      "CityValue": "Rajgarh"
    },
    {
      "id": 2795,
      "CityName": "Kanniyakumari",
      "CityValue": "Kanniyakumari"
    },
    {
      "id": 2796,
      "CityName": "Iroopara",
      "CityValue": "Iroopara"
    },
    {
      "id": 2797,
      "CityName": "Kooraikundu",
      "CityValue": "Kooraikundu"
    },
    {
      "id": 2798,
      "CityName": "Kathlal",
      "CityValue": "Kathlal"
    },
    {
      "id": 2799,
      "CityName": "Nallur",
      "CityValue": "Nallur"
    },
    {
      "id": 2800,
      "CityName": "Bankapura",
      "CityValue": "Bankapura"
    },
    {
      "id": 2801,
      "CityName": "Tamenglong",
      "CityValue": "Tamenglong"
    },
    {
      "id": 2802,
      "CityName": "Pathiyoor",
      "CityValue": "Pathiyoor"
    },
    {
      "id": 2803,
      "CityName": "L.A.Sagaram",
      "CityValue": "L.A.Sagaram"
    },
    {
      "id": 2804,
      "CityName": "Nakur",
      "CityValue": "Nakur"
    },
    {
      "id": 2805,
      "CityName": "Solsumba",
      "CityValue": "Solsumba"
    },
    {
      "id": 2806,
      "CityName": "Kuthiathode",
      "CityValue": "Kuthiathode"
    },
    {
      "id": 2807,
      "CityName": "Tirupattur",
      "CityValue": "Tirupattur"
    },
    {
      "id": 2808,
      "CityName": "Belur",
      "CityValue": "Belur"
    },
    {
      "id": 2809,
      "CityName": "Karmala",
      "CityValue": "Karmala"
    },
    {
      "id": 2810,
      "CityName": "Bhanvad",
      "CityValue": "Bhanvad"
    },
    {
      "id": 2811,
      "CityName": "Thiruvankulam",
      "CityValue": "Thiruvankulam"
    },
    {
      "id": 2812,
      "CityName": "Raya",
      "CityValue": "Raya"
    },
    {
      "id": 2813,
      "CityName": "Serchhip",
      "CityValue": "Serchhip"
    },
    {
      "id": 2814,
      "CityName": "Sangod",
      "CityValue": "Sangod"
    },
    {
      "id": 2815,
      "CityName": "Avalapalli",
      "CityValue": "Avalapalli"
    },
    {
      "id": 2816,
      "CityName": "Malik Pur Kohi alias Rang Puri",
      "CityValue": "Malik Pur Kohi alias Rang Puri"
    },
    {
      "id": 2817,
      "CityName": "Bokajan",
      "CityValue": "Bokajan"
    },
    {
      "id": 2818,
      "CityName": "Tehri",
      "CityValue": "Tehri"
    },
    {
      "id": 2819,
      "CityName": "Kheragarh",
      "CityValue": "Kheragarh"
    },
    {
      "id": 2820,
      "CityName": "Bhanpura",
      "CityValue": "Bhanpura"
    },
    {
      "id": 2821,
      "CityName": "Kailasahar",
      "CityValue": "Kailasahar"
    },
    {
      "id": 2822,
      "CityName": "Balongi",
      "CityValue": "Balongi"
    },
    {
      "id": 2823,
      "CityName": "Rasulabad",
      "CityValue": "Rasulabad"
    },
    {
      "id": 2824,
      "CityName": "Vashind",
      "CityValue": "Vashind"
    },
    {
      "id": 2825,
      "CityName": "Dindori",
      "CityValue": "Dindori"
    },
    {
      "id": 2826,
      "CityName": "Barara",
      "CityValue": "Barara"
    },
    {
      "id": 2827,
      "CityName": "Kumbhraj",
      "CityValue": "Kumbhraj"
    },
    {
      "id": 2828,
      "CityName": "Samthar",
      "CityValue": "Samthar"
    },
    {
      "id": 2829,
      "CityName": "Nilakkottai",
      "CityValue": "Nilakkottai"
    },
    {
      "id": 2830,
      "CityName": "Adinad",
      "CityValue": "Adinad"
    },
    {
      "id": 2831,
      "CityName": "Sahanpur",
      "CityValue": "Sahanpur"
    },
    {
      "id": 2832,
      "CityName": "Mahadula",
      "CityValue": "Mahadula"
    },
    {
      "id": 2833,
      "CityName": "Banaganapalle",
      "CityValue": "Banaganapalle"
    },
    {
      "id": 2834,
      "CityName": "Tikari",
      "CityValue": "Tikari"
    },
    {
      "id": 2835,
      "CityName": "Debagarh",
      "CityValue": "Debagarh"
    },
    {
      "id": 2836,
      "CityName": "Alampalayam",
      "CityValue": "Alampalayam"
    },
    {
      "id": 2837,
      "CityName": "Makrana Village",
      "CityValue": "Makrana Village"
    },
    {
      "id": 2838,
      "CityName": "Sakleshpur",
      "CityValue": "Sakleshpur"
    },
    {
      "id": 2839,
      "CityName": "Chiknayakanhalli",
      "CityValue": "Chiknayakanhalli"
    },
    {
      "id": 2840,
      "CityName": "Hailey Mandi",
      "CityValue": "Hailey Mandi"
    },
    {
      "id": 2841,
      "CityName": "Karanjia",
      "CityValue": "Karanjia"
    },
    {
      "id": 2842,
      "CityName": "Salempur",
      "CityValue": "Salempur"
    },
    {
      "id": 2843,
      "CityName": "South Thrikkaripur",
      "CityValue": "South Thrikkaripur"
    },
    {
      "id": 2844,
      "CityName": "Sholavandan",
      "CityValue": "Sholavandan"
    },
    {
      "id": 2845,
      "CityName": "Ghosia Bazar",
      "CityValue": "Ghosia Bazar"
    },
    {
      "id": 2846,
      "CityName": "Pataudi",
      "CityValue": "Pataudi"
    },
    {
      "id": 2847,
      "CityName": "Curchorem-Cacora",
      "CityValue": "Curchorem-Cacora"
    },
    {
      "id": 2848,
      "CityName": "Etmadpur",
      "CityValue": "Etmadpur"
    },
    {
      "id": 2849,
      "CityName": "Rajopatti urf Kota Bazar",
      "CityValue": "Rajopatti urf Kota Bazar"
    },
    {
      "id": 2850,
      "CityName": "Deoli",
      "CityValue": "Deoli"
    },
    {
      "id": 2851,
      "CityName": "Njarackal",
      "CityValue": "Njarackal"
    },
    {
      "id": 2852,
      "CityName": "Batlagundu",
      "CityValue": "Batlagundu"
    },
    {
      "id": 2853,
      "CityName": "Chorvad",
      "CityValue": "Chorvad"
    },
    {
      "id": 2854,
      "CityName": "Ancharakandy",
      "CityValue": "Ancharakandy"
    },
    {
      "id": 2855,
      "CityName": "Birlapur",
      "CityValue": "Birlapur"
    },
    {
      "id": 2856,
      "CityName": "Palangarai",
      "CityValue": "Palangarai"
    },
    {
      "id": 2857,
      "CityName": "Veiloor",
      "CityValue": "Veiloor"
    },
    {
      "id": 2858,
      "CityName": "Adampur",
      "CityValue": "Adampur"
    },
    {
      "id": 2859,
      "CityName": "Mahendranagar",
      "CityValue": "Mahendranagar"
    },
    {
      "id": 2860,
      "CityName": "Bhuvanagiri",
      "CityValue": "Bhuvanagiri"
    },
    {
      "id": 2861,
      "CityName": "Veraval",
      "CityValue": "Veraval"
    },
    {
      "id": 2862,
      "CityName": "Ghulewadi",
      "CityValue": "Ghulewadi"
    },
    {
      "id": 2863,
      "CityName": "Mount Abu",
      "CityValue": "Mount Abu"
    },
    {
      "id": 2864,
      "CityName": "Patharia",
      "CityValue": "Patharia"
    },
    {
      "id": 2865,
      "CityName": "Umarsara",
      "CityValue": "Umarsara"
    },
    {
      "id": 2866,
      "CityName": "Kirodimalnagar",
      "CityValue": "Kirodimalnagar"
    },
    {
      "id": 2867,
      "CityName": "Bhuban",
      "CityValue": "Bhuban"
    },
    {
      "id": 2868,
      "CityName": "Manoharpur",
      "CityValue": "Manoharpur"
    },
    {
      "id": 2869,
      "CityName": "Chakia",
      "CityValue": "Chakia"
    },
    {
      "id": 2870,
      "CityName": "Puthuvype",
      "CityValue": "Puthuvype"
    },
    {
      "id": 2871,
      "CityName": "Sanaur",
      "CityValue": "Sanaur"
    },
    {
      "id": 2872,
      "CityName": "Somandepalle",
      "CityValue": "Somandepalle"
    },
    {
      "id": 2873,
      "CityName": "Bhuwana",
      "CityValue": "Bhuwana"
    },
    {
      "id": 2874,
      "CityName": "Vaikom",
      "CityValue": "Vaikom"
    },
    {
      "id": 2875,
      "CityName": "Kim",
      "CityValue": "Kim"
    },
    {
      "id": 2876,
      "CityName": "Kannamangalam",
      "CityValue": "Kannamangalam"
    },
    {
      "id": 2877,
      "CityName": "Richha",
      "CityValue": "Richha"
    },
    {
      "id": 2878,
      "CityName": "Maholi",
      "CityValue": "Maholi"
    },
    {
      "id": 2879,
      "CityName": "Chincholi",
      "CityValue": "Chincholi"
    },
    {
      "id": 2880,
      "CityName": "Nyoria Husainpur",
      "CityValue": "Nyoria Husainpur"
    },
    {
      "id": 2881,
      "CityName": "Dasnapur",
      "CityValue": "Dasnapur"
    },
    {
      "id": 2882,
      "CityName": "Kurundvad",
      "CityValue": "Kurundvad"
    },
    {
      "id": 2883,
      "CityName": "Pallippuram",
      "CityValue": "Pallippuram"
    },
    {
      "id": 2884,
      "CityName": "Gadarpur",
      "CityValue": "Gadarpur"
    },
    {
      "id": 2885,
      "CityName": "Jainagar",
      "CityValue": "Jainagar"
    },
    {
      "id": 2886,
      "CityName": "Chennur",
      "CityValue": "Chennur"
    },
    {
      "id": 2887,
      "CityName": "Kuju",
      "CityValue": "Kuju"
    },
    {
      "id": 2888,
      "CityName": "Kilapavoor",
      "CityValue": "Kilapavoor"
    },
    {
      "id": 2889,
      "CityName": "Gormi",
      "CityValue": "Gormi"
    },
    {
      "id": 2890,
      "CityName": "Surajgarh",
      "CityValue": "Surajgarh"
    },
    {
      "id": 2891,
      "CityName": "Laundi",
      "CityValue": "Laundi"
    },
    {
      "id": 2892,
      "CityName": "Sarwar",
      "CityValue": "Sarwar"
    },
    {
      "id": 2893,
      "CityName": "Naravarikuppam",
      "CityValue": "Naravarikuppam"
    },
    {
      "id": 2894,
      "CityName": "Pamur",
      "CityValue": "Pamur"
    },
    {
      "id": 2895,
      "CityName": "Pali",
      "CityValue": "Pali"
    },
    {
      "id": 2896,
      "CityName": "Colachel",
      "CityValue": "Colachel"
    },
    {
      "id": 2897,
      "CityName": "Sakti",
      "CityValue": "Sakti"
    },
    {
      "id": 2898,
      "CityName": "Gurmatkal",
      "CityValue": "Gurmatkal"
    },
    {
      "id": 2899,
      "CityName": "Tenudam-Cum-Kathhara",
      "CityValue": "Tenudam-Cum-Kathhara"
    },
    {
      "id": 2900,
      "CityName": "Deoranian",
      "CityValue": "Deoranian"
    },
    {
      "id": 2901,
      "CityName": "Panniyannur",
      "CityValue": "Panniyannur"
    },
    {
      "id": 2902,
      "CityName": "Pehlad Pur Bangar",
      "CityValue": "Pehlad Pur Bangar"
    },
    {
      "id": 2903,
      "CityName": "Majhauliraj",
      "CityValue": "Majhauliraj"
    },
    {
      "id": 2904,
      "CityName": "Kadayal",
      "CityValue": "Kadayal"
    },
    {
      "id": 2905,
      "CityName": "Jalalabad",
      "CityValue": "Jalalabad"
    },
    {
      "id": 2906,
      "CityName": "Talwandi Sabo",
      "CityValue": "Talwandi Sabo"
    },
    {
      "id": 2907,
      "CityName": "Khapat",
      "CityValue": "Khapat"
    },
    {
      "id": 2908,
      "CityName": "Chhapraula",
      "CityValue": "Chhapraula"
    },
    {
      "id": 2909,
      "CityName": "Shimulpur",
      "CityValue": "Shimulpur"
    },
    {
      "id": 2910,
      "CityName": "Pahasu",
      "CityValue": "Pahasu"
    },
    {
      "id": 2911,
      "CityName": "Chhatrapur",
      "CityValue": "Chhatrapur"
    },
    {
      "id": 2912,
      "CityName": "Channagiri",
      "CityValue": "Channagiri"
    },
    {
      "id": 2913,
      "CityName": "Tral",
      "CityValue": "Tral"
    },
    {
      "id": 2914,
      "CityName": "Badnawar",
      "CityValue": "Badnawar"
    },
    {
      "id": 2915,
      "CityName": "Jharoda Majra Burari",
      "CityValue": "Jharoda Majra Burari"
    },
    {
      "id": 2916,
      "CityName": "Pauni",
      "CityValue": "Pauni"
    },
    {
      "id": 2917,
      "CityName": "Ruiya",
      "CityValue": "Ruiya"
    },
    {
      "id": 2918,
      "CityName": "Aalo",
      "CityValue": "Aalo"
    },
    {
      "id": 2919,
      "CityName": "Vadakarai Keezhpadugai",
      "CityValue": "Vadakarai Keezhpadugai"
    },
    {
      "id": 2920,
      "CityName": "Ajnala",
      "CityValue": "Ajnala"
    },
    {
      "id": 2921,
      "CityName": "Teleni Para",
      "CityValue": "Teleni Para"
    },
    {
      "id": 2922,
      "CityName": "Jamai",
      "CityValue": "Jamai"
    },
    {
      "id": 2923,
      "CityName": "Vinnamala",
      "CityValue": "Vinnamala"
    },
    {
      "id": 2924,
      "CityName": "Bhargain",
      "CityValue": "Bhargain"
    },
    {
      "id": 2925,
      "CityName": "Jujarsaha",
      "CityValue": "Jujarsaha"
    },
    {
      "id": 2926,
      "CityName": "Maham",
      "CityValue": "Maham"
    },
    {
      "id": 2927,
      "CityName": "Kantabanji",
      "CityValue": "Kantabanji"
    },
    {
      "id": 2928,
      "CityName": "Dhaluwala",
      "CityValue": "Dhaluwala"
    },
    {
      "id": 2929,
      "CityName": "Viratnagar",
      "CityValue": "Viratnagar"
    },
    {
      "id": 2930,
      "CityName": "Kheralu",
      "CityValue": "Kheralu"
    },
    {
      "id": 2931,
      "CityName": "Borkhedi",
      "CityValue": "Borkhedi"
    },
    {
      "id": 2932,
      "CityName": "Hargaon",
      "CityValue": "Hargaon"
    },
    {
      "id": 2933,
      "CityName": "Devadanapatti",
      "CityValue": "Devadanapatti"
    },
    {
      "id": 2934,
      "CityName": "Kompalle",
      "CityValue": "Kompalle"
    },
    {
      "id": 2935,
      "CityName": "Raval",
      "CityValue": "Raval"
    },
    {
      "id": 2936,
      "CityName": "Munderi",
      "CityValue": "Munderi"
    },
    {
      "id": 2937,
      "CityName": "Inam Maniyachi",
      "CityValue": "Inam Maniyachi"
    },
    {
      "id": 2938,
      "CityName": "Medankarwadi",
      "CityValue": "Medankarwadi"
    },
    {
      "id": 2939,
      "CityName": "Hindusthan Cables Town",
      "CityValue": "Hindusthan Cables Town"
    },
    {
      "id": 2940,
      "CityName": "Sambhar",
      "CityValue": "Sambhar"
    },
    {
      "id": 2941,
      "CityName": "Nirmali",
      "CityValue": "Nirmali"
    },
    {
      "id": 2942,
      "CityName": "Rajpur",
      "CityValue": "Rajpur"
    },
    {
      "id": 2943,
      "CityName": "Kannankurichi",
      "CityValue": "Kannankurichi"
    },
    {
      "id": 2944,
      "CityName": "Narasimhanaicken-palayam",
      "CityValue": "Narasimhanaicken-palayam"
    },
    {
      "id": 2945,
      "CityName": "Peravurani",
      "CityValue": "Peravurani"
    },
    {
      "id": 2946,
      "CityName": "Umri Pragane Balapur",
      "CityValue": "Umri Pragane Balapur"
    },
    {
      "id": 2947,
      "CityName": "Kalher",
      "CityValue": "Kalher"
    },
    {
      "id": 2948,
      "CityName": "Birur",
      "CityValue": "Birur"
    },
    {
      "id": 2949,
      "CityName": "Thirparappu",
      "CityValue": "Thirparappu"
    },
    {
      "id": 2950,
      "CityName": "Chitbara Gaon",
      "CityValue": "Chitbara Gaon"
    },
    {
      "id": 2951,
      "CityName": "Bareja",
      "CityValue": "Bareja"
    },
    {
      "id": 2952,
      "CityName": "Akkalkuwa",
      "CityValue": "Akkalkuwa"
    },
    {
      "id": 2953,
      "CityName": "Paravai",
      "CityValue": "Paravai"
    },
    {
      "id": 2954,
      "CityName": "Belonia",
      "CityValue": "Belonia"
    },
    {
      "id": 2955,
      "CityName": "Vasudevanallur",
      "CityValue": "Vasudevanallur"
    },
    {
      "id": 2956,
      "CityName": "Sirgitti",
      "CityValue": "Sirgitti"
    },
    {
      "id": 2957,
      "CityName": "Bommasandra",
      "CityValue": "Bommasandra"
    },
    {
      "id": 2958,
      "CityName": "Manchar",
      "CityValue": "Manchar"
    },
    {
      "id": 2959,
      "CityName": "Satyamangala",
      "CityValue": "Satyamangala"
    },
    {
      "id": 2960,
      "CityName": "Surajpur",
      "CityValue": "Surajpur"
    },
    {
      "id": 2961,
      "CityName": "Naduvattom",
      "CityValue": "Naduvattom"
    },
    {
      "id": 2962,
      "CityName": "Ranchi Bangar",
      "CityValue": "Ranchi Bangar"
    },
    {
      "id": 2963,
      "CityName": "Belsand",
      "CityValue": "Belsand"
    },
    {
      "id": 2964,
      "CityName": "Ramtek",
      "CityValue": "Ramtek"
    },
    {
      "id": 2965,
      "CityName": "Rameswaram",
      "CityValue": "Rameswaram"
    },
    {
      "id": 2966,
      "CityName": "Karuvanthuruthy",
      "CityValue": "Karuvanthuruthy"
    },
    {
      "id": 2967,
      "CityName": "Ghatanji",
      "CityValue": "Ghatanji"
    },
    {
      "id": 2968,
      "CityName": "Alangulam",
      "CityValue": "Alangulam"
    },
    {
      "id": 2969,
      "CityName": "Sonapur",
      "CityValue": "Sonapur"
    },
    {
      "id": 2970,
      "CityName": "Pichandarkovil",
      "CityValue": "Pichandarkovil"
    },
    {
      "id": 2971,
      "CityName": "Machche",
      "CityValue": "Machche"
    },
    {
      "id": 2972,
      "CityName": "Palakkodu",
      "CityValue": "Palakkodu"
    },
    {
      "id": 2973,
      "CityName": "Thorrur",
      "CityValue": "Thorrur"
    },
    {
      "id": 2974,
      "CityName": "Begumabad Budhana",
      "CityValue": "Begumabad Budhana"
    },
    {
      "id": 2975,
      "CityName": "Pudupattinam",
      "CityValue": "Pudupattinam"
    },
    {
      "id": 2976,
      "CityName": "Barhalganj",
      "CityValue": "Barhalganj"
    },
    {
      "id": 2977,
      "CityName": "Kaprain",
      "CityValue": "Kaprain"
    },
    {
      "id": 2978,
      "CityName": "Tirusulam",
      "CityValue": "Tirusulam"
    },
    {
      "id": 2979,
      "CityName": "Hungund",
      "CityValue": "Hungund"
    },
    {
      "id": 2980,
      "CityName": "Bundu",
      "CityValue": "Bundu"
    },
    {
      "id": 2981,
      "CityName": "Chamoli Gopeshwar",
      "CityValue": "Chamoli Gopeshwar"
    },
    {
      "id": 2982,
      "CityName": "Kolaras",
      "CityValue": "Kolaras"
    },
    {
      "id": 2983,
      "CityName": "Doom Dooma",
      "CityValue": "Doom Dooma"
    },
    {
      "id": 2984,
      "CityName": "Bade Bacheli",
      "CityValue": "Bade Bacheli"
    },
    {
      "id": 2985,
      "CityName": "Behat",
      "CityValue": "Behat"
    },
    {
      "id": 2986,
      "CityName": "Zunheboto",
      "CityValue": "Zunheboto"
    },
    {
      "id": 2987,
      "CityName": "Dhuilya",
      "CityValue": "Dhuilya"
    },
    {
      "id": 2988,
      "CityName": "Kondalampatti",
      "CityValue": "Kondalampatti"
    },
    {
      "id": 2989,
      "CityName": "Jalali",
      "CityValue": "Jalali"
    },
    {
      "id": 2990,
      "CityName": "Banga",
      "CityValue": "Banga"
    },
    {
      "id": 2991,
      "CityName": "Chengannur",
      "CityValue": "Chengannur"
    },
    {
      "id": 2992,
      "CityName": "Thirumazhisai",
      "CityValue": "Thirumazhisai"
    },
    {
      "id": 2993,
      "CityName": "Singur",
      "CityValue": "Singur"
    },
    {
      "id": 2994,
      "CityName": "Kurudampalayam",
      "CityValue": "Kurudampalayam"
    },
    {
      "id": 2995,
      "CityName": "Nohsa",
      "CityValue": "Nohsa"
    },
    {
      "id": 2996,
      "CityName": "Achampet",
      "CityValue": "Achampet"
    },
    {
      "id": 2997,
      "CityName": "Patnagarh",
      "CityValue": "Patnagarh"
    },
    {
      "id": 2998,
      "CityName": "Julana",
      "CityValue": "Julana"
    },
    {
      "id": 2999,
      "CityName": "Jafarpur",
      "CityValue": "Jafarpur"
    },
    {
      "id": 3000,
      "CityName": "Aurad",
      "CityValue": "Aurad"
    },
    {
      "id": 3001,
      "CityName": "Jagjeetpur",
      "CityValue": "Jagjeetpur"
    },
    {
      "id": 3002,
      "CityName": "Jhalu",
      "CityValue": "Jhalu"
    },
    {
      "id": 3003,
      "CityName": "Maniyur",
      "CityValue": "Maniyur"
    },
    {
      "id": 3004,
      "CityName": "Telhara",
      "CityValue": "Telhara"
    },
    {
      "id": 3005,
      "CityName": "Kurduvadi",
      "CityValue": "Kurduvadi"
    },
    {
      "id": 3006,
      "CityName": "Bahadurganj",
      "CityValue": "Bahadurganj"
    },
    {
      "id": 3007,
      "CityName": "Kethi",
      "CityValue": "Kethi"
    },
    {
      "id": 3008,
      "CityName": "Vadali",
      "CityValue": "Vadali"
    },
    {
      "id": 3009,
      "CityName": "Pirangut",
      "CityValue": "Pirangut"
    },
    {
      "id": 3010,
      "CityName": "Akbarpur",
      "CityValue": "Akbarpur"
    },
    {
      "id": 3011,
      "CityName": "Bawani khera",
      "CityValue": "Bawani khera"
    },
    {
      "id": 3012,
      "CityName": "Digboi",
      "CityValue": "Digboi"
    },
    {
      "id": 3013,
      "CityName": "Paippad",
      "CityValue": "Paippad"
    },
    {
      "id": 3014,
      "CityName": "Choutuppal",
      "CityValue": "Choutuppal"
    },
    {
      "id": 3015,
      "CityName": "Nagla",
      "CityValue": "Nagla"
    },
    {
      "id": 3016,
      "CityName": "Guriahati",
      "CityValue": "Guriahati"
    },
    {
      "id": 3017,
      "CityName": "Kwarasi",
      "CityValue": "Kwarasi"
    },
    {
      "id": 3018,
      "CityName": "Konerikuppam",
      "CityValue": "Konerikuppam"
    },
    {
      "id": 3019,
      "CityName": "Badi",
      "CityValue": "Badi"
    },
    {
      "id": 3020,
      "CityName": "Jhusi Kohna",
      "CityValue": "Jhusi Kohna"
    },
    {
      "id": 3021,
      "CityName": "Nagardeole",
      "CityValue": "Nagardeole"
    },
    {
      "id": 3022,
      "CityName": "Daulatpur",
      "CityValue": "Daulatpur"
    },
    {
      "id": 3023,
      "CityName": "Azhikode North",
      "CityValue": "Azhikode North"
    },
    {
      "id": 3024,
      "CityName": "Nainwa",
      "CityValue": "Nainwa"
    },
    {
      "id": 3025,
      "CityName": "Iluppaiyurani",
      "CityValue": "Iluppaiyurani"
    },
    {
      "id": 3026,
      "CityName": "Kallelibhagom",
      "CityValue": "Kallelibhagom"
    },
    {
      "id": 3027,
      "CityName": "Bhondsi",
      "CityValue": "Bhondsi"
    },
    {
      "id": 3028,
      "CityName": "Silwani",
      "CityValue": "Silwani"
    },
    {
      "id": 3029,
      "CityName": "Kapasan",
      "CityValue": "Kapasan"
    },
    {
      "id": 3030,
      "CityName": "Kottappally",
      "CityValue": "Kottappally"
    },
    {
      "id": 3031,
      "CityName": "Thiagadurgam",
      "CityValue": "Thiagadurgam"
    },
    {
      "id": 3032,
      "CityName": "Siswa Bazar",
      "CityValue": "Siswa Bazar"
    },
    {
      "id": 3033,
      "CityName": "Shahpur",
      "CityValue": "Shahpur"
    },
    {
      "id": 3034,
      "CityName": "Belthara Road",
      "CityValue": "Belthara Road"
    },
    {
      "id": 3035,
      "CityName": "Lateri",
      "CityValue": "Lateri"
    },
    {
      "id": 3036,
      "CityName": "Junagarh",
      "CityValue": "Junagarh"
    },
    {
      "id": 3037,
      "CityName": "Ghatkesar",
      "CityValue": "Ghatkesar"
    },
    {
      "id": 3038,
      "CityName": "Shendurjana",
      "CityValue": "Shendurjana"
    },
    {
      "id": 3039,
      "CityName": "Mandawar",
      "CityValue": "Mandawar"
    },
    {
      "id": 3040,
      "CityName": "Banur",
      "CityValue": "Banur"
    },
    {
      "id": 3041,
      "CityName": "Padmanabhapuram",
      "CityValue": "Padmanabhapuram"
    },
    {
      "id": 3042,
      "CityName": "Takhatpur",
      "CityValue": "Takhatpur"
    },
    {
      "id": 3043,
      "CityName": "Yellapur",
      "CityValue": "Yellapur"
    },
    {
      "id": 3044,
      "CityName": "Baben",
      "CityValue": "Baben"
    },
    {
      "id": 3045,
      "CityName": "Roha Ashtami",
      "CityValue": "Roha Ashtami"
    },
    {
      "id": 3046,
      "CityName": "Panchpara",
      "CityValue": "Panchpara"
    },
    {
      "id": 3047,
      "CityName": "Turkayamjal",
      "CityValue": "Turkayamjal"
    },
    {
      "id": 3048,
      "CityName": "Kamgachhi",
      "CityValue": "Kamgachhi"
    },
    {
      "id": 3049,
      "CityName": "Korochi",
      "CityValue": "Korochi"
    },
    {
      "id": 3050,
      "CityName": "Thammampatti",
      "CityValue": "Thammampatti"
    },
    {
      "id": 3051,
      "CityName": "Sarai Aquil",
      "CityValue": "Sarai Aquil"
    },
    {
      "id": 3052,
      "CityName": "Chharra Rafatpur",
      "CityValue": "Chharra Rafatpur"
    },
    {
      "id": 3053,
      "CityName": "Lumding Rly. Col.",
      "CityValue": "Lumding Rly. Col."
    },
    {
      "id": 3054,
      "CityName": "Arvi",
      "CityValue": "Arvi"
    },
    {
      "id": 3055,
      "CityName": "Arra",
      "CityValue": "Arra"
    },
    {
      "id": 3056,
      "CityName": "Shehera",
      "CityValue": "Shehera"
    },
    {
      "id": 3057,
      "CityName": "Bannur",
      "CityValue": "Bannur"
    },
    {
      "id": 3058,
      "CityName": "Asika",
      "CityValue": "Asika"
    },
    {
      "id": 3059,
      "CityName": "Mangalvedhe",
      "CityValue": "Mangalvedhe"
    },
    {
      "id": 3060,
      "CityName": "Teliamura",
      "CityValue": "Teliamura"
    },
    {
      "id": 3061,
      "CityName": "Adiyanuthu",
      "CityValue": "Adiyanuthu"
    },
    {
      "id": 3062,
      "CityName": "Devgadbaria",
      "CityValue": "Devgadbaria"
    },
    {
      "id": 3063,
      "CityName": "Saraipali",
      "CityValue": "Saraipali"
    },
    {
      "id": 3064,
      "CityName": "Tufanganj",
      "CityValue": "Tufanganj"
    },
    {
      "id": 3065,
      "CityName": "Dhusaripara",
      "CityValue": "Dhusaripara"
    },
    {
      "id": 3066,
      "CityName": "Naroli",
      "CityValue": "Naroli"
    },
    {
      "id": 3067,
      "CityName": "Banat",
      "CityValue": "Banat"
    },
    {
      "id": 3068,
      "CityName": "Barapali",
      "CityValue": "Barapali"
    },
    {
      "id": 3069,
      "CityName": "Joya",
      "CityValue": "Joya"
    },
    {
      "id": 3070,
      "CityName": "Pulwama",
      "CityValue": "Pulwama"
    },
    {
      "id": 3071,
      "CityName": "Kalinjur",
      "CityValue": "Kalinjur"
    },
    {
      "id": 3072,
      "CityName": "Anuppur",
      "CityValue": "Anuppur"
    },
    {
      "id": 3073,
      "CityName": "Killiyoor",
      "CityValue": "Killiyoor"
    },
    {
      "id": 3074,
      "CityName": "Santrampur",
      "CityValue": "Santrampur"
    },
    {
      "id": 3075,
      "CityName": "Lochapada",
      "CityValue": "Lochapada"
    },
    {
      "id": 3076,
      "CityName": "Vadakkumthala",
      "CityValue": "Vadakkumthala"
    },
    {
      "id": 3077,
      "CityName": "Bijawar",
      "CityValue": "Bijawar"
    },
    {
      "id": 3078,
      "CityName": "Anthiyur",
      "CityValue": "Anthiyur"
    },
    {
      "id": 3079,
      "CityName": "Bansdih",
      "CityValue": "Bansdih"
    },
    {
      "id": 3080,
      "CityName": "Baudhgarh",
      "CityValue": "Baudhgarh"
    },
    {
      "id": 3081,
      "CityName": "Orikkai",
      "CityValue": "Orikkai"
    },
    {
      "id": 3082,
      "CityName": "Nowrozabad",
      "CityValue": "Nowrozabad"
    },
    {
      "id": 3083,
      "CityName": "Narwar",
      "CityValue": "Narwar"
    },
    {
      "id": 3084,
      "CityName": "Bandlaguda",
      "CityValue": "Bandlaguda"
    },
    {
      "id": 3085,
      "CityName": "Bilhaur",
      "CityValue": "Bilhaur"
    },
    {
      "id": 3086,
      "CityName": "Anklav",
      "CityValue": "Anklav"
    },
    {
      "id": 3087,
      "CityName": "Kuzhithurai",
      "CityValue": "Kuzhithurai"
    },
    {
      "id": 3088,
      "CityName": "Bhitarwar",
      "CityValue": "Bhitarwar"
    },
    {
      "id": 3089,
      "CityName": "Patur",
      "CityValue": "Patur"
    },
    {
      "id": 3090,
      "CityName": "Phalauda",
      "CityValue": "Phalauda"
    },
    {
      "id": 3091,
      "CityName": "Natthan Pur",
      "CityValue": "Natthan Pur"
    },
    {
      "id": 3092,
      "CityName": "Puthencruz",
      "CityValue": "Puthencruz"
    },
    {
      "id": 3093,
      "CityName": "Mora",
      "CityValue": "Mora"
    },
    {
      "id": 3094,
      "CityName": "Kul Pahar",
      "CityValue": "Kul Pahar"
    },
    {
      "id": 3095,
      "CityName": "Nedungundram",
      "CityValue": "Nedungundram"
    },
    {
      "id": 3096,
      "CityName": "Rustamnagar Sahaspur",
      "CityValue": "Rustamnagar Sahaspur"
    },
    {
      "id": 3097,
      "CityName": "Jarwal",
      "CityValue": "Jarwal"
    },
    {
      "id": 3098,
      "CityName": "Kusgaon Bk.",
      "CityValue": "Kusgaon Bk."
    },
    {
      "id": 3099,
      "CityName": "Aluva",
      "CityValue": "Aluva"
    },
    {
      "id": 3100,
      "CityName": "Aurangabad Bangar",
      "CityValue": "Aurangabad Bangar"
    },
    {
      "id": 3101,
      "CityName": "Kesaria",
      "CityValue": "Kesaria"
    },
    {
      "id": 3102,
      "CityName": "Chelora",
      "CityValue": "Chelora"
    },
    {
      "id": 3103,
      "CityName": "Pariyaram",
      "CityValue": "Pariyaram"
    },
    {
      "id": 3104,
      "CityName": "Kataiya",
      "CityValue": "Kataiya"
    },
    {
      "id": 3105,
      "CityName": "Pothanur",
      "CityValue": "Pothanur"
    },
    {
      "id": 3106,
      "CityName": "Chilkana Sultanpur",
      "CityValue": "Chilkana Sultanpur"
    },
    {
      "id": 3107,
      "CityName": "Talala",
      "CityValue": "Talala"
    },
    {
      "id": 3108,
      "CityName": "Dharur",
      "CityValue": "Dharur"
    },
    {
      "id": 3109,
      "CityName": "Annur",
      "CityValue": "Annur"
    },
    {
      "id": 3110,
      "CityName": "Vallabh Vidyanagar",
      "CityValue": "Vallabh Vidyanagar"
    },
    {
      "id": 3111,
      "CityName": "Bhamian Kalan",
      "CityValue": "Bhamian Kalan"
    },
    {
      "id": 3112,
      "CityName": "Samrala",
      "CityValue": "Samrala"
    },
    {
      "id": 3113,
      "CityName": "Palai",
      "CityValue": "Palai"
    },
    {
      "id": 3114,
      "CityName": "Suleeswaranpatti",
      "CityValue": "Suleeswaranpatti"
    },
    {
      "id": 3115,
      "CityName": "Madathukulam",
      "CityValue": "Madathukulam"
    },
    {
      "id": 3116,
      "CityName": "Deoli",
      "CityValue": "Deoli"
    },
    {
      "id": 3117,
      "CityName": "Punjaipuliampatti",
      "CityValue": "Punjaipuliampatti"
    },
    {
      "id": 3118,
      "CityName": "Moradabad Pahari",
      "CityValue": "Moradabad Pahari"
    },
    {
      "id": 3119,
      "CityName": "Pandavapura",
      "CityValue": "Pandavapura"
    },
    {
      "id": 3120,
      "CityName": "Moirang",
      "CityValue": "Moirang"
    },
    {
      "id": 3121,
      "CityName": "Seithur",
      "CityValue": "Seithur"
    },
    {
      "id": 3122,
      "CityName": "Kodamthuruth",
      "CityValue": "Kodamthuruth"
    },
    {
      "id": 3123,
      "CityName": "Kansepur",
      "CityValue": "Kansepur"
    },
    {
      "id": 3124,
      "CityName": "Karmeta",
      "CityValue": "Karmeta"
    },
    {
      "id": 3125,
      "CityName": "Daria",
      "CityValue": "Daria"
    },
    {
      "id": 3126,
      "CityName": "Alibag",
      "CityValue": "Alibag"
    },
    {
      "id": 3127,
      "CityName": "Sahatwar",
      "CityValue": "Sahatwar"
    },
    {
      "id": 3128,
      "CityName": "Charthawal",
      "CityValue": "Charthawal"
    },
    {
      "id": 3129,
      "CityName": "Pudussery West",
      "CityValue": "Pudussery West"
    },
    {
      "id": 3130,
      "CityName": "Udaipura",
      "CityValue": "Udaipura"
    },
    {
      "id": 3131,
      "CityName": "Begun",
      "CityValue": "Begun"
    },
    {
      "id": 3132,
      "CityName": "Namagiripettai",
      "CityValue": "Namagiripettai"
    },
    {
      "id": 3133,
      "CityName": "Palayampatti",
      "CityValue": "Palayampatti"
    },
    {
      "id": 3134,
      "CityName": "Jahazpur",
      "CityValue": "Jahazpur"
    },
    {
      "id": 3135,
      "CityName": "Koduvayur",
      "CityValue": "Koduvayur"
    },
    {
      "id": 3136,
      "CityName": "Karanpur",
      "CityValue": "Karanpur"
    },
    {
      "id": 3137,
      "CityName": "Dharamkot",
      "CityValue": "Dharamkot"
    },
    {
      "id": 3138,
      "CityName": "Siwani",
      "CityValue": "Siwani"
    },
    {
      "id": 3139,
      "CityName": "Ahiwara",
      "CityValue": "Ahiwara"
    },
    {
      "id": 3140,
      "CityName": "Periakottai",
      "CityValue": "Periakottai"
    },
    {
      "id": 3141,
      "CityName": "Savda",
      "CityValue": "Savda"
    },
    {
      "id": 3142,
      "CityName": "Savgadh",
      "CityValue": "Savgadh"
    },
    {
      "id": 3143,
      "CityName": "Mau",
      "CityValue": "Mau"
    },
    {
      "id": 3144,
      "CityName": "Mulavukad",
      "CityValue": "Mulavukad"
    },
    {
      "id": 3145,
      "CityName": "Parikshitgarh",
      "CityValue": "Parikshitgarh"
    },
    {
      "id": 3146,
      "CityName": "Pallipalayam Agraharam",
      "CityValue": "Pallipalayam Agraharam"
    },
    {
      "id": 3147,
      "CityName": "Aistala",
      "CityValue": "Aistala"
    },
    {
      "id": 3148,
      "CityName": "Sarai Mir",
      "CityValue": "Sarai Mir"
    },
    {
      "id": 3149,
      "CityName": "Narkhed",
      "CityValue": "Narkhed"
    },
    {
      "id": 3150,
      "CityName": "Harij",
      "CityValue": "Harij"
    },
    {
      "id": 3151,
      "CityName": "Dehtora",
      "CityValue": "Dehtora"
    },
    {
      "id": 3152,
      "CityName": "Mariani",
      "CityValue": "Mariani"
    },
    {
      "id": 3153,
      "CityName": "Paduvilayi",
      "CityValue": "Paduvilayi"
    },
    {
      "id": 3154,
      "CityName": "Lathi",
      "CityValue": "Lathi"
    },
    {
      "id": 3155,
      "CityName": "Perinjanam",
      "CityValue": "Perinjanam"
    },
    {
      "id": 3156,
      "CityName": "Bagbahara",
      "CityValue": "Bagbahara"
    },
    {
      "id": 3157,
      "CityName": "Deori",
      "CityValue": "Deori"
    },
    {
      "id": 3158,
      "CityName": "Maksi",
      "CityValue": "Maksi"
    },
    {
      "id": 3159,
      "CityName": "Khilchipur",
      "CityValue": "Khilchipur"
    },
    {
      "id": 3160,
      "CityName": "Khetasarai",
      "CityValue": "Khetasarai"
    },
    {
      "id": 3161,
      "CityName": "Palakonda",
      "CityValue": "Palakonda"
    },
    {
      "id": 3162,
      "CityName": "Perur Chettipalayam",
      "CityValue": "Perur Chettipalayam"
    },
    {
      "id": 3163,
      "CityName": "Kerur",
      "CityValue": "Kerur"
    },
    {
      "id": 3164,
      "CityName": "Bhusawar",
      "CityValue": "Bhusawar"
    },
    {
      "id": 3165,
      "CityName": "Amarpatan",
      "CityValue": "Amarpatan"
    },
    {
      "id": 3166,
      "CityName": "Mogra Badshahpur",
      "CityValue": "Mogra Badshahpur"
    },
    {
      "id": 3167,
      "CityName": "Maghar",
      "CityValue": "Maghar"
    },
    {
      "id": 3168,
      "CityName": "Hirekerur",
      "CityValue": "Hirekerur"
    },
    {
      "id": 3169,
      "CityName": "Killannur",
      "CityValue": "Killannur"
    },
    {
      "id": 3170,
      "CityName": "Birpur",
      "CityValue": "Birpur"
    },
    {
      "id": 3171,
      "CityName": "Yavatmal",
      "CityValue": "Yavatmal"
    },
    {
      "id": 3172,
      "CityName": "Chettinaickenpatti",
      "CityValue": "Chettinaickenpatti"
    },
    {
      "id": 3173,
      "CityName": "Chapar",
      "CityValue": "Chapar"
    },
    {
      "id": 3174,
      "CityName": "Barki Saraiya",
      "CityValue": "Barki Saraiya"
    },
    {
      "id": 3175,
      "CityName": "Kalameshwar",
      "CityValue": "Kalameshwar"
    },
    {
      "id": 3176,
      "CityName": "Buzurg",
      "CityValue": "Buzurg"
    },
    {
      "id": 3177,
      "CityName": "Sulya",
      "CityValue": "Sulya"
    },
    {
      "id": 3178,
      "CityName": "Srisailam Project",
      "CityValue": "Srisailam Project"
    },
    {
      "id": 3179,
      "CityName": "Jansath",
      "CityValue": "Jansath"
    },
    {
      "id": 3180,
      "CityName": "Trehgam",
      "CityValue": "Trehgam"
    },
    {
      "id": 3181,
      "CityName": "Singarayakonda",
      "CityValue": "Singarayakonda"
    },
    {
      "id": 3182,
      "CityName": "Gummidipoondi",
      "CityValue": "Gummidipoondi"
    },
    {
      "id": 3183,
      "CityName": "Thaikattussery",
      "CityValue": "Thaikattussery"
    },
    {
      "id": 3184,
      "CityName": "Nellimarla",
      "CityValue": "Nellimarla"
    },
    {
      "id": 3185,
      "CityName": "Boriavi",
      "CityValue": "Boriavi"
    },
    {
      "id": 3186,
      "CityName": "Kakori",
      "CityValue": "Kakori"
    },
    {
      "id": 3187,
      "CityName": "Keezhallur",
      "CityValue": "Keezhallur"
    },
    {
      "id": 3188,
      "CityName": "Bassi Pathana",
      "CityValue": "Bassi Pathana"
    },
    {
      "id": 3189,
      "CityName": "Sonari",
      "CityValue": "Sonari"
    },
    {
      "id": 3190,
      "CityName": "Chetpet",
      "CityValue": "Chetpet"
    },
    {
      "id": 3191,
      "CityName": "Phagwara Sharki",
      "CityValue": "Phagwara Sharki"
    },
    {
      "id": 3192,
      "CityName": "Sardulgarh",
      "CityValue": "Sardulgarh"
    },
    {
      "id": 3193,
      "CityName": "Satlapur",
      "CityValue": "Satlapur"
    },
    {
      "id": 3194,
      "CityName": "Bangawan",
      "CityValue": "Bangawan"
    },
    {
      "id": 3195,
      "CityName": "Chhapar",
      "CityValue": "Chhapar"
    },
    {
      "id": 3196,
      "CityName": "Charipara",
      "CityValue": "Charipara"
    },
    {
      "id": 3197,
      "CityName": "Renukoot",
      "CityValue": "Renukoot"
    },
    {
      "id": 3198,
      "CityName": "Ramjibanpur",
      "CityValue": "Ramjibanpur"
    },
    {
      "id": 3199,
      "CityName": "Khanapur",
      "CityValue": "Khanapur"
    },
    {
      "id": 3200,
      "CityName": "Dhanaula",
      "CityValue": "Dhanaula"
    },
    {
      "id": 3201,
      "CityName": "Peeranwadi",
      "CityValue": "Peeranwadi"
    },
    {
      "id": 3202,
      "CityName": "Senthamangalam",
      "CityValue": "Senthamangalam"
    },
    {
      "id": 3203,
      "CityName": "Bhanjanagar",
      "CityValue": "Bhanjanagar"
    },
    {
      "id": 3204,
      "CityName": "Srirampur",
      "CityValue": "Srirampur"
    },
    {
      "id": 3205,
      "CityName": "Resubelpara",
      "CityValue": "Resubelpara"
    },
    {
      "id": 3206,
      "CityName": "Annamalai Nagar",
      "CityValue": "Annamalai Nagar"
    },
    {
      "id": 3207,
      "CityName": "Dattapur Dhamangaon",
      "CityValue": "Dattapur Dhamangaon"
    },
    {
      "id": 3208,
      "CityName": "Chandur Railway",
      "CityValue": "Chandur Railway"
    },
    {
      "id": 3209,
      "CityName": "Kariapatti",
      "CityValue": "Kariapatti"
    },
    {
      "id": 3210,
      "CityName": "Koath",
      "CityValue": "Koath"
    },
    {
      "id": 3211,
      "CityName": "Naharkatiya",
      "CityValue": "Naharkatiya"
    },
    {
      "id": 3212,
      "CityName": "Vazhakulam",
      "CityValue": "Vazhakulam"
    },
    {
      "id": 3213,
      "CityName": "Kalanwali",
      "CityValue": "Kalanwali"
    },
    {
      "id": 3214,
      "CityName": "Kiphire",
      "CityValue": "Kiphire"
    },
    {
      "id": 3215,
      "CityName": "Thara",
      "CityValue": "Thara"
    },
    {
      "id": 3216,
      "CityName": "Kargil",
      "CityValue": "Kargil"
    },
    {
      "id": 3217,
      "CityName": "Alang-Sosiya",
      "CityValue": "Alang-Sosiya"
    },
    {
      "id": 3218,
      "CityName": "Shankargarh",
      "CityValue": "Shankargarh"
    },
    {
      "id": 3219,
      "CityName": "Ammainaickanur",
      "CityValue": "Ammainaickanur"
    },
    {
      "id": 3220,
      "CityName": "Bali",
      "CityValue": "Bali"
    },
    {
      "id": 3221,
      "CityName": "Vadakkekara",
      "CityValue": "Vadakkekara"
    },
    {
      "id": 3222,
      "CityName": "Khelari",
      "CityValue": "Khelari"
    },
    {
      "id": 3223,
      "CityName": "Vellakinar",
      "CityValue": "Vellakinar"
    },
    {
      "id": 3224,
      "CityName": "Neral",
      "CityValue": "Neral"
    },
    {
      "id": 3225,
      "CityName": "Khed",
      "CityValue": "Khed"
    },
    {
      "id": 3226,
      "CityName": "Murbad",
      "CityValue": "Murbad"
    },
    {
      "id": 3227,
      "CityName": "Galpadar",
      "CityValue": "Galpadar"
    },
    {
      "id": 3228,
      "CityName": "Arang",
      "CityValue": "Arang"
    },
    {
      "id": 3229,
      "CityName": "Edavilangu",
      "CityValue": "Edavilangu"
    },
    {
      "id": 3230,
      "CityName": "Singahi Bhiraura",
      "CityValue": "Singahi Bhiraura"
    },
    {
      "id": 3231,
      "CityName": "Lasalgaon",
      "CityValue": "Lasalgaon"
    },
    {
      "id": 3232,
      "CityName": "Verkilambi",
      "CityValue": "Verkilambi"
    },
    {
      "id": 3233,
      "CityName": "Pali",
      "CityValue": "Pali"
    },
    {
      "id": 3234,
      "CityName": "Kota",
      "CityValue": "Kota"
    },
    {
      "id": 3235,
      "CityName": "Gadhra",
      "CityValue": "Gadhra"
    },
    {
      "id": 3236,
      "CityName": "Kesinga",
      "CityValue": "Kesinga"
    },
    {
      "id": 3237,
      "CityName": "Mau Aima",
      "CityValue": "Mau Aima"
    },
    {
      "id": 3238,
      "CityName": "Dayal Pur",
      "CityValue": "Dayal Pur"
    },
    {
      "id": 3239,
      "CityName": "Marehra",
      "CityValue": "Marehra"
    },
    {
      "id": 3240,
      "CityName": "Lilong",
      "CityValue": "Lilong"
    },
    {
      "id": 3241,
      "CityName": "Nimapada",
      "CityValue": "Nimapada"
    },
    {
      "id": 3242,
      "CityName": "Haldaur",
      "CityValue": "Haldaur"
    },
    {
      "id": 3243,
      "CityName": "Chhipabarod",
      "CityValue": "Chhipabarod"
    },
    {
      "id": 3244,
      "CityName": "Weir",
      "CityValue": "Weir"
    },
    {
      "id": 3245,
      "CityName": "Chunchupalle",
      "CityValue": "Chunchupalle"
    },
    {
      "id": 3246,
      "CityName": "Mundgod",
      "CityValue": "Mundgod"
    },
    {
      "id": 3247,
      "CityName": "Lonand",
      "CityValue": "Lonand"
    },
    {
      "id": 3248,
      "CityName": "Ghoghardiha",
      "CityValue": "Ghoghardiha"
    },
    {
      "id": 3249,
      "CityName": "Pennadam",
      "CityValue": "Pennadam"
    },
    {
      "id": 3250,
      "CityName": "Kalua",
      "CityValue": "Kalua"
    },
    {
      "id": 3251,
      "CityName": "Shahpur",
      "CityValue": "Shahpur"
    },
    {
      "id": 3252,
      "CityName": "Trajpar",
      "CityValue": "Trajpar"
    },
    {
      "id": 3253,
      "CityName": "Uthangarai",
      "CityValue": "Uthangarai"
    },
    {
      "id": 3254,
      "CityName": "Keota",
      "CityValue": "Keota"
    },
    {
      "id": 3255,
      "CityName": "Bhayavadar",
      "CityValue": "Bhayavadar"
    },
    {
      "id": 3256,
      "CityName": "Maniyar",
      "CityValue": "Maniyar"
    },
    {
      "id": 3257,
      "CityName": "Katangi",
      "CityValue": "Katangi"
    },
    {
      "id": 3258,
      "CityName": "Tadigadapa",
      "CityValue": "Tadigadapa"
    },
    {
      "id": 3259,
      "CityName": "Padirikuppam",
      "CityValue": "Padirikuppam"
    },
    {
      "id": 3260,
      "CityName": "Kolavelloor",
      "CityValue": "Kolavelloor"
    },
    {
      "id": 3261,
      "CityName": "Nanekarwadi",
      "CityValue": "Nanekarwadi"
    },
    {
      "id": 3262,
      "CityName": "Landhaura",
      "CityValue": "Landhaura"
    },
    {
      "id": 3263,
      "CityName": "Madhuban",
      "CityValue": "Madhuban"
    },
    {
      "id": 3264,
      "CityName": "Seppa",
      "CityValue": "Seppa"
    },
    {
      "id": 3265,
      "CityName": "Kattathurai",
      "CityValue": "Kattathurai"
    },
    {
      "id": 3266,
      "CityName": "Raybag",
      "CityValue": "Raybag"
    },
    {
      "id": 3267,
      "CityName": "Kadikkad",
      "CityValue": "Kadikkad"
    },
    {
      "id": 3268,
      "CityName": "Jhalda",
      "CityValue": "Jhalda"
    },
    {
      "id": 3269,
      "CityName": "Thadikombu",
      "CityValue": "Thadikombu"
    },
    {
      "id": 3270,
      "CityName": "Srinagar",
      "CityValue": "Srinagar"
    },
    {
      "id": 3271,
      "CityName": "Majhion",
      "CityValue": "Majhion"
    },
    {
      "id": 3272,
      "CityName": "Paraniputhur",
      "CityValue": "Paraniputhur"
    },
    {
      "id": 3273,
      "CityName": "Punnayur",
      "CityValue": "Punnayur"
    },
    {
      "id": 3274,
      "CityName": "Thondi",
      "CityValue": "Thondi"
    },
    {
      "id": 3275,
      "CityName": "Ambaji",
      "CityValue": "Ambaji"
    },
    {
      "id": 3276,
      "CityName": "O' Valley",
      "CityValue": "O' Valley"
    },
    {
      "id": 3277,
      "CityName": "Kurumathur",
      "CityValue": "Kurumathur"
    },
    {
      "id": 3278,
      "CityName": "Harpalpur",
      "CityValue": "Harpalpur"
    },
    {
      "id": 3279,
      "CityName": "Una",
      "CityValue": "Una"
    },
    {
      "id": 3280,
      "CityName": "Manikpur",
      "CityValue": "Manikpur"
    },
    {
      "id": 3281,
      "CityName": "Mirganj",
      "CityValue": "Mirganj"
    },
    {
      "id": 3282,
      "CityName": "Dhing",
      "CityValue": "Dhing"
    },
    {
      "id": 3283,
      "CityName": "Chettiarpatti",
      "CityValue": "Chettiarpatti"
    },
    {
      "id": 3284,
      "CityName": "Arookutty",
      "CityValue": "Arookutty"
    },
    {
      "id": 3285,
      "CityName": "Ali Pur",
      "CityValue": "Ali Pur"
    },
    {
      "id": 3286,
      "CityName": "Sarmathura",
      "CityValue": "Sarmathura"
    },
    {
      "id": 3287,
      "CityName": "Muthukulam",
      "CityValue": "Muthukulam"
    },
    {
      "id": 3288,
      "CityName": "Nuh",
      "CityValue": "Nuh"
    },
    {
      "id": 3289,
      "CityName": "Kanjiramkulam",
      "CityValue": "Kanjiramkulam"
    },
    {
      "id": 3290,
      "CityName": "Chodavaram",
      "CityValue": "Chodavaram"
    },
    {
      "id": 3291,
      "CityName": "Nalco",
      "CityValue": "Nalco"
    },
    {
      "id": 3292,
      "CityName": "Kalady",
      "CityValue": "Kalady"
    },
    {
      "id": 3293,
      "CityName": "Bada Malhera",
      "CityValue": "Bada Malhera"
    },
    {
      "id": 3294,
      "CityName": "Misrikh-cum-Neemsar",
      "CityValue": "Misrikh-cum-Neemsar"
    },
    {
      "id": 3295,
      "CityName": "Visavadar",
      "CityValue": "Visavadar"
    },
    {
      "id": 3296,
      "CityName": "Mulagumudu",
      "CityValue": "Mulagumudu"
    },
    {
      "id": 3297,
      "CityName": "Babrala",
      "CityValue": "Babrala"
    },
    {
      "id": 3298,
      "CityName": "Khariar Road",
      "CityValue": "Khariar Road"
    },
    {
      "id": 3299,
      "CityName": "Bawal",
      "CityValue": "Bawal"
    },
    {
      "id": 3300,
      "CityName": "Bodri",
      "CityValue": "Bodri"
    },
    {
      "id": 3301,
      "CityName": "Konnur",
      "CityValue": "Konnur"
    },
    {
      "id": 3302,
      "CityName": "Alipur",
      "CityValue": "Alipur"
    },
    {
      "id": 3303,
      "CityName": "Thakurganj",
      "CityValue": "Thakurganj"
    },
    {
      "id": 3304,
      "CityName": "Kharsia",
      "CityValue": "Kharsia"
    },
    {
      "id": 3305,
      "CityName": "Moonak",
      "CityValue": "Moonak"
    },
    {
      "id": 3306,
      "CityName": "Madambakkam",
      "CityValue": "Madambakkam"
    },
    {
      "id": 3307,
      "CityName": "Deshnoke",
      "CityValue": "Deshnoke"
    },
    {
      "id": 3308,
      "CityName": "Kallukuttam",
      "CityValue": "Kallukuttam"
    },
    {
      "id": 3309,
      "CityName": "Pichhore",
      "CityValue": "Pichhore"
    },
    {
      "id": 3310,
      "CityName": "Gorrekunta",
      "CityValue": "Gorrekunta"
    },
    {
      "id": 3311,
      "CityName": "Kewalpur",
      "CityValue": "Kewalpur"
    },
    {
      "id": 3312,
      "CityName": "Ondal",
      "CityValue": "Ondal"
    },
    {
      "id": 3313,
      "CityName": "Pattiom",
      "CityValue": "Pattiom"
    },
    {
      "id": 3314,
      "CityName": "Sarsawa",
      "CityValue": "Sarsawa"
    },
    {
      "id": 3315,
      "CityName": "Udangudi",
      "CityValue": "Udangudi"
    },
    {
      "id": 3316,
      "CityName": "Gaurella",
      "CityValue": "Gaurella"
    },
    {
      "id": 3317,
      "CityName": "Suhagi",
      "CityValue": "Suhagi"
    },
    {
      "id": 3318,
      "CityName": "Giria",
      "CityValue": "Giria"
    },
    {
      "id": 3319,
      "CityName": "Guruvayoor",
      "CityValue": "Guruvayoor"
    },
    {
      "id": 3320,
      "CityName": "Madhuban",
      "CityValue": "Madhuban"
    },
    {
      "id": 3321,
      "CityName": "Badoda",
      "CityValue": "Badoda"
    },
    {
      "id": 3322,
      "CityName": "Burhar",
      "CityValue": "Burhar"
    },
    {
      "id": 3323,
      "CityName": "Tezu",
      "CityValue": "Tezu"
    },
    {
      "id": 3324,
      "CityName": "Mokeri",
      "CityValue": "Mokeri"
    },
    {
      "id": 3325,
      "CityName": "Dariyabad",
      "CityValue": "Dariyabad"
    },
    {
      "id": 3326,
      "CityName": "Bishalgarh",
      "CityValue": "Bishalgarh"
    },
    {
      "id": 3327,
      "CityName": "Nichlaul",
      "CityValue": "Nichlaul"
    },
    {
      "id": 3328,
      "CityName": "Eruvadi",
      "CityValue": "Eruvadi"
    },
    {
      "id": 3329,
      "CityName": "Puthuppally",
      "CityValue": "Puthuppally"
    },
    {
      "id": 3330,
      "CityName": "Shivdaspur",
      "CityValue": "Shivdaspur"
    },
    {
      "id": 3331,
      "CityName": "Kottayam-Malabar",
      "CityValue": "Kottayam-Malabar"
    },
    {
      "id": 3332,
      "CityName": "Falakata",
      "CityValue": "Falakata"
    },
    {
      "id": 3333,
      "CityName": "Biswanath Chariali",
      "CityValue": "Biswanath Chariali"
    },
    {
      "id": 3334,
      "CityName": "Thiruvithancode",
      "CityValue": "Thiruvithancode"
    },
    {
      "id": 3335,
      "CityName": "Raha",
      "CityValue": "Raha"
    },
    {
      "id": 3336,
      "CityName": "Pinahat",
      "CityValue": "Pinahat"
    },
    {
      "id": 3337,
      "CityName": "Cheppad",
      "CityValue": "Cheppad"
    },
    {
      "id": 3338,
      "CityName": "Honavar",
      "CityValue": "Honavar"
    },
    {
      "id": 3339,
      "CityName": "Kothakota",
      "CityValue": "Kothakota"
    },
    {
      "id": 3340,
      "CityName": "Mahiari",
      "CityValue": "Mahiari"
    },
    {
      "id": 3341,
      "CityName": "Vandalur",
      "CityValue": "Vandalur"
    },
    {
      "id": 3342,
      "CityName": "Samarvarni",
      "CityValue": "Samarvarni"
    },
    {
      "id": 3343,
      "CityName": "Veerapandi",
      "CityValue": "Veerapandi"
    },
    {
      "id": 3344,
      "CityName": "Saiyad Raza",
      "CityValue": "Saiyad Raza"
    },
    {
      "id": 3345,
      "CityName": "Dantewada",
      "CityValue": "Dantewada"
    },
    {
      "id": 3346,
      "CityName": "Manavely",
      "CityValue": "Manavely"
    },
    {
      "id": 3347,
      "CityName": "Kundgol",
      "CityValue": "Kundgol"
    },
    {
      "id": 3348,
      "CityName": "Bhogpur",
      "CityValue": "Bhogpur"
    },
    {
      "id": 3349,
      "CityName": "Bhadaur",
      "CityValue": "Bhadaur"
    },
    {
      "id": 3350,
      "CityName": "Kalayat",
      "CityValue": "Kalayat"
    },
    {
      "id": 3351,
      "CityName": "Undera",
      "CityValue": "Undera"
    },
    {
      "id": 3352,
      "CityName": "Sirka",
      "CityValue": "Sirka"
    },
    {
      "id": 3353,
      "CityName": "Kadachira",
      "CityValue": "Kadachira"
    },
    {
      "id": 3354,
      "CityName": "Chhaprauli",
      "CityValue": "Chhaprauli"
    },
    {
      "id": 3355,
      "CityName": "Paranda",
      "CityValue": "Paranda"
    },
    {
      "id": 3356,
      "CityName": "Mandapam",
      "CityValue": "Mandapam"
    },
    {
      "id": 3357,
      "CityName": "Shahpur",
      "CityValue": "Shahpur"
    },
    {
      "id": 3358,
      "CityName": "Nongpoh",
      "CityValue": "Nongpoh"
    },
    {
      "id": 3359,
      "CityName": "Fatehpur Range",
      "CityValue": "Fatehpur Range"
    },
    {
      "id": 3360,
      "CityName": "Kanakkampalayam",
      "CityValue": "Kanakkampalayam"
    },
    {
      "id": 3361,
      "CityName": "Naldurg",
      "CityValue": "Naldurg"
    },
    {
      "id": 3362,
      "CityName": "Sompeta",
      "CityValue": "Sompeta"
    },
    {
      "id": 3363,
      "CityName": "Jatari",
      "CityValue": "Jatari"
    },
    {
      "id": 3364,
      "CityName": "Gopiganj",
      "CityValue": "Gopiganj"
    },
    {
      "id": 3365,
      "CityName": "Kondur",
      "CityValue": "Kondur"
    },
    {
      "id": 3366,
      "CityName": "Palappallam",
      "CityValue": "Palappallam"
    },
    {
      "id": 3367,
      "CityName": "Neelagiri",
      "CityValue": "Neelagiri"
    },
    {
      "id": 3368,
      "CityName": "Pipili",
      "CityValue": "Pipili"
    },
    {
      "id": 3369,
      "CityName": "Ezhome",
      "CityValue": "Ezhome"
    },
    {
      "id": 3370,
      "CityName": "Chamba",
      "CityValue": "Chamba"
    },
    {
      "id": 3371,
      "CityName": "Jharoda Kalan",
      "CityValue": "Jharoda Kalan"
    },
    {
      "id": 3372,
      "CityName": "Suriyawan",
      "CityValue": "Suriyawan"
    },
    {
      "id": 3373,
      "CityName": "Shenbakkam",
      "CityValue": "Shenbakkam"
    },
    {
      "id": 3374,
      "CityName": "Pasthal",
      "CityValue": "Pasthal"
    },
    {
      "id": 3375,
      "CityName": "Pattinamkattan",
      "CityValue": "Pattinamkattan"
    },
    {
      "id": 3376,
      "CityName": "Yerkheda",
      "CityValue": "Yerkheda"
    },
    {
      "id": 3377,
      "CityName": "Dhekiajuli",
      "CityValue": "Dhekiajuli"
    },
    {
      "id": 3378,
      "CityName": "Narauli",
      "CityValue": "Narauli"
    },
    {
      "id": 3379,
      "CityName": "Sarangpore",
      "CityValue": "Sarangpore"
    },
    {
      "id": 3380,
      "CityName": "Gangapur",
      "CityValue": "Gangapur"
    },
    {
      "id": 3381,
      "CityName": "Kandalloor",
      "CityValue": "Kandalloor"
    },
    {
      "id": 3382,
      "CityName": "Chakia",
      "CityValue": "Chakia"
    },
    {
      "id": 3383,
      "CityName": "Farakka Barrage Township",
      "CityValue": "Farakka Barrage Township"
    },
    {
      "id": 3384,
      "CityName": "Jogipet",
      "CityValue": "Jogipet"
    },
    {
      "id": 3385,
      "CityName": "Veeravanallur",
      "CityValue": "Veeravanallur"
    },
    {
      "id": 3386,
      "CityName": "Jhinjhana",
      "CityValue": "Jhinjhana"
    },
    {
      "id": 3387,
      "CityName": "Khanpur",
      "CityValue": "Khanpur"
    },
    {
      "id": 3388,
      "CityName": "Dirba",
      "CityValue": "Dirba"
    },
    {
      "id": 3389,
      "CityName": "Bhikhi",
      "CityValue": "Bhikhi"
    },
    {
      "id": 3390,
      "CityName": "Ashrafpur Jalal",
      "CityValue": "Ashrafpur Jalal"
    },
    {
      "id": 3391,
      "CityName": "Daboh",
      "CityValue": "Daboh"
    },
    {
      "id": 3392,
      "CityName": "Talbandha",
      "CityValue": "Talbandha"
    },
    {
      "id": 3393,
      "CityName": "Unchahara",
      "CityValue": "Unchahara"
    },
    {
      "id": 3394,
      "CityName": "Alangayam",
      "CityValue": "Alangayam"
    },
    {
      "id": 3395,
      "CityName": "Cheranmadevi",
      "CityValue": "Cheranmadevi"
    },
    {
      "id": 3396,
      "CityName": "Barwala",
      "CityValue": "Barwala"
    },
    {
      "id": 3397,
      "CityName": "Karambakkam",
      "CityValue": "Karambakkam"
    },
    {
      "id": 3398,
      "CityName": "Jadcherla",
      "CityValue": "Jadcherla"
    },
    {
      "id": 3399,
      "CityName": "Vadamadurai",
      "CityValue": "Vadamadurai"
    },
    {
      "id": 3400,
      "CityName": "Umri Kalan",
      "CityValue": "Umri Kalan"
    },
    {
      "id": 3401,
      "CityName": "Dapoli Camp",
      "CityValue": "Dapoli Camp"
    },
    {
      "id": 3402,
      "CityName": "Nissing",
      "CityValue": "Nissing"
    },
    {
      "id": 3403,
      "CityName": "Maliya",
      "CityValue": "Maliya"
    },
    {
      "id": 3404,
      "CityName": "Dunetha",
      "CityValue": "Dunetha"
    },
    {
      "id": 3405,
      "CityName": "Indri",
      "CityValue": "Indri"
    },
    {
      "id": 3406,
      "CityName": "Kunhimangalam",
      "CityValue": "Kunhimangalam"
    },
    {
      "id": 3407,
      "CityName": "Mattoor",
      "CityValue": "Mattoor"
    },
    {
      "id": 3408,
      "CityName": "Domjur",
      "CityValue": "Domjur"
    },
    {
      "id": 3409,
      "CityName": "Depalpur",
      "CityValue": "Depalpur"
    },
    {
      "id": 3410,
      "CityName": "Bhor",
      "CityValue": "Bhor"
    },
    {
      "id": 3411,
      "CityName": "Mangattidam",
      "CityValue": "Mangattidam"
    },
    {
      "id": 3412,
      "CityName": "Nadim Tiruvuru",
      "CityValue": "Nadim Tiruvuru"
    },
    {
      "id": 3413,
      "CityName": "Bayad",
      "CityValue": "Bayad"
    },
    {
      "id": 3414,
      "CityName": "Thiruvattar",
      "CityValue": "Thiruvattar"
    },
    {
      "id": 3415,
      "CityName": "Shupiyan",
      "CityValue": "Shupiyan"
    },
    {
      "id": 3416,
      "CityName": "Chandurbazar",
      "CityValue": "Chandurbazar"
    },
    {
      "id": 3417,
      "CityName": "Khutar",
      "CityValue": "Khutar"
    },
    {
      "id": 3418,
      "CityName": "Mandvi",
      "CityValue": "Mandvi"
    },
    {
      "id": 3419,
      "CityName": "Raja Pur Khurd",
      "CityValue": "Raja Pur Khurd"
    },
    {
      "id": 3420,
      "CityName": "Gothan",
      "CityValue": "Gothan"
    },
    {
      "id": 3421,
      "CityName": "Rithora",
      "CityValue": "Rithora"
    },
    {
      "id": 3422,
      "CityName": "Badkulla",
      "CityValue": "Badkulla"
    },
    {
      "id": 3423,
      "CityName": "Gubbi",
      "CityValue": "Gubbi"
    },
    {
      "id": 3424,
      "CityName": "Nalloor",
      "CityValue": "Nalloor"
    },
    {
      "id": 3425,
      "CityName": "Kanjari",
      "CityValue": "Kanjari"
    },
    {
      "id": 3426,
      "CityName": "Chhoti Sadri",
      "CityValue": "Chhoti Sadri"
    },
    {
      "id": 3427,
      "CityName": "Bhatgaon",
      "CityValue": "Bhatgaon"
    },
    {
      "id": 3428,
      "CityName": "Honnali",
      "CityValue": "Honnali"
    },
    {
      "id": 3429,
      "CityName": "Maswasi",
      "CityValue": "Maswasi"
    },
    {
      "id": 3430,
      "CityName": "Runkata",
      "CityValue": "Runkata"
    },
    {
      "id": 3431,
      "CityName": "Arwani",
      "CityValue": "Arwani"
    },
    {
      "id": 3432,
      "CityName": "Palera",
      "CityValue": "Palera"
    },
    {
      "id": 3433,
      "CityName": "Ellandaikuttai",
      "CityValue": "Ellandaikuttai"
    },
    {
      "id": 3434,
      "CityName": "North Thrikkaripur",
      "CityValue": "North Thrikkaripur"
    },
    {
      "id": 3435,
      "CityName": "Somayampalayam",
      "CityValue": "Somayampalayam"
    },
    {
      "id": 3436,
      "CityName": "Kirandul",
      "CityValue": "Kirandul"
    },
    {
      "id": 3437,
      "CityName": "Asaripallam",
      "CityValue": "Asaripallam"
    },
    {
      "id": 3438,
      "CityName": "Barkakana",
      "CityValue": "Barkakana"
    },
    {
      "id": 3439,
      "CityName": "Kattanam",
      "CityValue": "Kattanam"
    },
    {
      "id": 3440,
      "CityName": "Dadra",
      "CityValue": "Dadra"
    },
    {
      "id": 3441,
      "CityName": "Nasaratpur",
      "CityValue": "Nasaratpur"
    },
    {
      "id": 3442,
      "CityName": "Kherli",
      "CityValue": "Kherli"
    },
    {
      "id": 3443,
      "CityName": "Shahabad ACC",
      "CityValue": "Shahabad ACC"
    },
    {
      "id": 3444,
      "CityName": "Haidergarh",
      "CityValue": "Haidergarh"
    },
    {
      "id": 3445,
      "CityName": "Kannod",
      "CityValue": "Kannod"
    },
    {
      "id": 3446,
      "CityName": "Lonia Karbal",
      "CityValue": "Lonia Karbal"
    },
    {
      "id": 3447,
      "CityName": "Rajokri",
      "CityValue": "Rajokri"
    },
    {
      "id": 3448,
      "CityName": "Bilgi",
      "CityValue": "Bilgi"
    },
    {
      "id": 3449,
      "CityName": "Madanaiyakanahalli",
      "CityValue": "Madanaiyakanahalli"
    },
    {
      "id": 3450,
      "CityName": "Rangpo",
      "CityValue": "Rangpo"
    },
    {
      "id": 3451,
      "CityName": "Mamsapuram",
      "CityValue": "Mamsapuram"
    },
    {
      "id": 3452,
      "CityName": "Mir Pur Turk",
      "CityValue": "Mir Pur Turk"
    },
    {
      "id": 3453,
      "CityName": "Methukummal",
      "CityValue": "Methukummal"
    },
    {
      "id": 3454,
      "CityName": "Magrahat",
      "CityValue": "Magrahat"
    },
    {
      "id": 3455,
      "CityName": "Rishra",
      "CityValue": "Rishra"
    },
    {
      "id": 3456,
      "CityName": "Singampuneri",
      "CityValue": "Singampuneri"
    },
    {
      "id": 3457,
      "CityName": "Howli",
      "CityValue": "Howli"
    },
    {
      "id": 3458,
      "CityName": "Bhum",
      "CityValue": "Bhum"
    },
    {
      "id": 3459,
      "CityName": "Malihabad",
      "CityValue": "Malihabad"
    },
    {
      "id": 3460,
      "CityName": "Usehat",
      "CityValue": "Usehat"
    },
    {
      "id": 3461,
      "CityName": "Lakhnadon",
      "CityValue": "Lakhnadon"
    },
    {
      "id": 3462,
      "CityName": "Mulgund",
      "CityValue": "Mulgund"
    },
    {
      "id": 3463,
      "CityName": "Shahi",
      "CityValue": "Shahi"
    },
    {
      "id": 3464,
      "CityName": "Phaphund",
      "CityValue": "Phaphund"
    },
    {
      "id": 3465,
      "CityName": "Nahazari",
      "CityValue": "Nahazari"
    },
    {
      "id": 3466,
      "CityName": "Padappai",
      "CityValue": "Padappai"
    },
    {
      "id": 3467,
      "CityName": "Kharupatia",
      "CityValue": "Kharupatia"
    },
    {
      "id": 3468,
      "CityName": "Koipady",
      "CityValue": "Koipady"
    },
    {
      "id": 3469,
      "CityName": "Noamundi",
      "CityValue": "Noamundi"
    },
    {
      "id": 3470,
      "CityName": "Kymore",
      "CityValue": "Kymore"
    },
    {
      "id": 3471,
      "CityName": "Sithurajapuram",
      "CityValue": "Sithurajapuram"
    },
    {
      "id": 3472,
      "CityName": "Basukinath",
      "CityValue": "Basukinath"
    },
    {
      "id": 3473,
      "CityName": "Pachperwa",
      "CityValue": "Pachperwa"
    },
    {
      "id": 3474,
      "CityName": "Mettunasuvanpalayam",
      "CityValue": "Mettunasuvanpalayam"
    },
    {
      "id": 3475,
      "CityName": "Palayad",
      "CityValue": "Palayad"
    },
    {
      "id": 3476,
      "CityName": "Bhokarhedi",
      "CityValue": "Bhokarhedi"
    },
    {
      "id": 3477,
      "CityName": "Khowai",
      "CityValue": "Khowai"
    },
    {
      "id": 3478,
      "CityName": "Puthur",
      "CityValue": "Puthur"
    },
    {
      "id": 3479,
      "CityName": "Bareta",
      "CityValue": "Bareta"
    },
    {
      "id": 3480,
      "CityName": "Padampur",
      "CityValue": "Padampur"
    },
    {
      "id": 3481,
      "CityName": "Patulia",
      "CityValue": "Patulia"
    },
    {
      "id": 3482,
      "CityName": "Guru Har Sahai",
      "CityValue": "Guru Har Sahai"
    },
    {
      "id": 3483,
      "CityName": "Talwandi Bhai",
      "CityValue": "Talwandi Bhai"
    },
    {
      "id": 3484,
      "CityName": "Murum",
      "CityValue": "Murum"
    },
    {
      "id": 3485,
      "CityName": "Talod",
      "CityValue": "Talod"
    },
    {
      "id": 3486,
      "CityName": "Kalwari",
      "CityValue": "Kalwari"
    },
    {
      "id": 3487,
      "CityName": "Joshimath",
      "CityValue": "Joshimath"
    },
    {
      "id": 3488,
      "CityName": "Hunasamaranahalli",
      "CityValue": "Hunasamaranahalli"
    },
    {
      "id": 3489,
      "CityName": "Pennagaram",
      "CityValue": "Pennagaram"
    },
    {
      "id": 3490,
      "CityName": "Bargur",
      "CityValue": "Bargur"
    },
    {
      "id": 3491,
      "CityName": "Davlameti",
      "CityValue": "Davlameti"
    },
    {
      "id": 3492,
      "CityName": "Kaugachhi",
      "CityValue": "Kaugachhi"
    },
    {
      "id": 3493,
      "CityName": "Sivagiri",
      "CityValue": "Sivagiri"
    },
    {
      "id": 3494,
      "CityName": "Maohanpur Mohammadpur",
      "CityValue": "Maohanpur Mohammadpur"
    },
    {
      "id": 3495,
      "CityName": "Padmapur",
      "CityValue": "Padmapur"
    },
    {
      "id": 3496,
      "CityName": "Kakkalur",
      "CityValue": "Kakkalur"
    },
    {
      "id": 3497,
      "CityName": "Wellington",
      "CityValue": "Wellington"
    },
    {
      "id": 3498,
      "CityName": "Nabagram Colony",
      "CityValue": "Nabagram Colony"
    },
    {
      "id": 3499,
      "CityName": "Nilokheri",
      "CityValue": "Nilokheri"
    },
    {
      "id": 3500,
      "CityName": "Natepute",
      "CityValue": "Natepute"
    },
    {
      "id": 3501,
      "CityName": "Bhati",
      "CityValue": "Bhati"
    },
    {
      "id": 3502,
      "CityName": "Puthunagaram",
      "CityValue": "Puthunagaram"
    },
    {
      "id": 3503,
      "CityName": "Andada",
      "CityValue": "Andada"
    },
    {
      "id": 3504,
      "CityName": "Ranikhet",
      "CityValue": "Ranikhet"
    },
    {
      "id": 3505,
      "CityName": "Mahudha",
      "CityValue": "Mahudha"
    },
    {
      "id": 3506,
      "CityName": "Bhinder",
      "CityValue": "Bhinder"
    },
    {
      "id": 3507,
      "CityName": "Chicholi",
      "CityValue": "Chicholi"
    },
    {
      "id": 3508,
      "CityName": "Khed",
      "CityValue": "Khed"
    },
    {
      "id": 3509,
      "CityName": "Mukandgarh",
      "CityValue": "Mukandgarh"
    },
    {
      "id": 3510,
      "CityName": "Savli",
      "CityValue": "Savli"
    },
    {
      "id": 3511,
      "CityName": "Karthikappally",
      "CityValue": "Karthikappally"
    },
    {
      "id": 3512,
      "CityName": "Vaddu",
      "CityValue": "Vaddu"
    },
    {
      "id": 3513,
      "CityName": "Tanakpur",
      "CityValue": "Tanakpur"
    },
    {
      "id": 3514,
      "CityName": "Ajra",
      "CityValue": "Ajra"
    },
    {
      "id": 3515,
      "CityName": "Bara Mohansingh",
      "CityValue": "Bara Mohansingh"
    },
    {
      "id": 3516,
      "CityName": "Karari",
      "CityValue": "Karari"
    },
    {
      "id": 3517,
      "CityName": "Vittal",
      "CityValue": "Vittal"
    },
    {
      "id": 3518,
      "CityName": "Nilagiri",
      "CityValue": "Nilagiri"
    },
    {
      "id": 3519,
      "CityName": "Ranpur",
      "CityValue": "Ranpur"
    },
    {
      "id": 3520,
      "CityName": "Anandpar",
      "CityValue": "Anandpar"
    },
    {
      "id": 3521,
      "CityName": "Rampura",
      "CityValue": "Rampura"
    },
    {
      "id": 3522,
      "CityName": "Baidyanathpur",
      "CityValue": "Baidyanathpur"
    },
    {
      "id": 3523,
      "CityName": "Budni",
      "CityValue": "Budni"
    },
    {
      "id": 3524,
      "CityName": "Shergarh",
      "CityValue": "Shergarh"
    },
    {
      "id": 3525,
      "CityName": "Hatti",
      "CityValue": "Hatti"
    },
    {
      "id": 3526,
      "CityName": "Dafahat",
      "CityValue": "Dafahat"
    },
    {
      "id": 3527,
      "CityName": "Uchana",
      "CityValue": "Uchana"
    },
    {
      "id": 3528,
      "CityName": "Nagamangala",
      "CityValue": "Nagamangala"
    },
    {
      "id": 3529,
      "CityName": "Jagalur",
      "CityValue": "Jagalur"
    },
    {
      "id": 3530,
      "CityName": "Pandariya",
      "CityValue": "Pandariya"
    },
    {
      "id": 3531,
      "CityName": "Bharwari",
      "CityValue": "Bharwari"
    },
    {
      "id": 3532,
      "CityName": "Mallasamudram",
      "CityValue": "Mallasamudram"
    },
    {
      "id": 3533,
      "CityName": "Jatara",
      "CityValue": "Jatara"
    },
    {
      "id": 3534,
      "CityName": "Rekjuani",
      "CityValue": "Rekjuani"
    },
    {
      "id": 3535,
      "CityName": "Kolathupalayam",
      "CityValue": "Kolathupalayam"
    },
    {
      "id": 3536,
      "CityName": "Pathiriyad",
      "CityValue": "Pathiriyad"
    },
    {
      "id": 3537,
      "CityName": "Balugaon",
      "CityValue": "Balugaon"
    },
    {
      "id": 3538,
      "CityName": "Kothanallur",
      "CityValue": "Kothanallur"
    },
    {
      "id": 3539,
      "CityName": "Sriramnagar",
      "CityValue": "Sriramnagar"
    },
    {
      "id": 3540,
      "CityName": "Betma",
      "CityValue": "Betma"
    },
    {
      "id": 3541,
      "CityName": "Khetri",
      "CityValue": "Khetri"
    },
    {
      "id": 3542,
      "CityName": "Malwan",
      "CityValue": "Malwan"
    },
    {
      "id": 3543,
      "CityName": "Kanakkampalayam",
      "CityValue": "Kanakkampalayam"
    },
    {
      "id": 3544,
      "CityName": "Kamayagoundanpatti",
      "CityValue": "Kamayagoundanpatti"
    },
    {
      "id": 3545,
      "CityName": "Palawa",
      "CityValue": "Palawa"
    },
    {
      "id": 3546,
      "CityName": "Palugal",
      "CityValue": "Palugal"
    },
    {
      "id": 3547,
      "CityName": "Chandili",
      "CityValue": "Chandili"
    },
    {
      "id": 3548,
      "CityName": "Dafarpur",
      "CityValue": "Dafarpur"
    },
    {
      "id": 3549,
      "CityName": "Narnaund",
      "CityValue": "Narnaund"
    },
    {
      "id": 3550,
      "CityName": "Nayagarh",
      "CityValue": "Nayagarh"
    },
    {
      "id": 3551,
      "CityName": "Awantipora",
      "CityValue": "Awantipora"
    },
    {
      "id": 3552,
      "CityName": "Kannampalayam",
      "CityValue": "Kannampalayam"
    },
    {
      "id": 3553,
      "CityName": "Vijainagar",
      "CityValue": "Vijainagar"
    },
    {
      "id": 3554,
      "CityName": "Kullu",
      "CityValue": "Kullu"
    },
    {
      "id": 3555,
      "CityName": "Valavanur",
      "CityValue": "Valavanur"
    },
    {
      "id": 3556,
      "CityName": "Baratola",
      "CityValue": "Baratola"
    },
    {
      "id": 3557,
      "CityName": "Thorapadi",
      "CityValue": "Thorapadi"
    },
    {
      "id": 3558,
      "CityName": "Tosham",
      "CityValue": "Tosham"
    },
    {
      "id": 3559,
      "CityName": "Suzabad",
      "CityValue": "Suzabad"
    },
    {
      "id": 3560,
      "CityName": "Mallanwala Khass",
      "CityValue": "Mallanwala Khass"
    },
    {
      "id": 3561,
      "CityName": "Ajhuwa",
      "CityValue": "Ajhuwa"
    },
    {
      "id": 3562,
      "CityName": "Joypul",
      "CityValue": "Joypul"
    },
    {
      "id": 3563,
      "CityName": "Samurou NP",
      "CityValue": "Samurou NP"
    },
    {
      "id": 3564,
      "CityName": "Lalpet",
      "CityValue": "Lalpet"
    },
    {
      "id": 3565,
      "CityName": "Rahimatpur",
      "CityValue": "Rahimatpur"
    },
    {
      "id": 3566,
      "CityName": "Sawer",
      "CityValue": "Sawer"
    },
    {
      "id": 3567,
      "CityName": "Vaghodia",
      "CityValue": "Vaghodia"
    },
    {
      "id": 3568,
      "CityName": "Singapur",
      "CityValue": "Singapur"
    },
    {
      "id": 3569,
      "CityName": "Vakkom",
      "CityValue": "Vakkom"
    },
    {
      "id": 3570,
      "CityName": "Pethanaickenpalayam",
      "CityValue": "Pethanaickenpalayam"
    },
    {
      "id": 3571,
      "CityName": "Dhaurra Mafi",
      "CityValue": "Dhaurra Mafi"
    },
    {
      "id": 3572,
      "CityName": "Maithon",
      "CityValue": "Maithon"
    },
    {
      "id": 3573,
      "CityName": "Nangavaram",
      "CityValue": "Nangavaram"
    },
    {
      "id": 3574,
      "CityName": "Badgam",
      "CityValue": "Badgam"
    },
    {
      "id": 3575,
      "CityName": "Vada",
      "CityValue": "Vada"
    },
    {
      "id": 3576,
      "CityName": "Vijaypur",
      "CityValue": "Vijaypur"
    },
    {
      "id": 3577,
      "CityName": "Qadi Pur",
      "CityValue": "Qadi Pur"
    },
    {
      "id": 3578,
      "CityName": "Shirhatti",
      "CityValue": "Shirhatti"
    },
    {
      "id": 3579,
      "CityName": "Dharampuri",
      "CityValue": "Dharampuri"
    },
    {
      "id": 3580,
      "CityName": "Vazhapadi",
      "CityValue": "Vazhapadi"
    },
    {
      "id": 3581,
      "CityName": "Pathalgaon",
      "CityValue": "Pathalgaon"
    },
    {
      "id": 3582,
      "CityName": "Ghiraur",
      "CityValue": "Ghiraur"
    },
    {
      "id": 3583,
      "CityName": "Kannapuram",
      "CityValue": "Kannapuram"
    },
    {
      "id": 3584,
      "CityName": "Kosamba",
      "CityValue": "Kosamba"
    },
    {
      "id": 3585,
      "CityName": "Jagadishpur",
      "CityValue": "Jagadishpur"
    },
    {
      "id": 3586,
      "CityName": "Virajpet",
      "CityValue": "Virajpet"
    },
    {
      "id": 3587,
      "CityName": "Patdi",
      "CityValue": "Patdi"
    },
    {
      "id": 3588,
      "CityName": "Singaperumalkoil",
      "CityValue": "Singaperumalkoil"
    },
    {
      "id": 3589,
      "CityName": "Mullanpur Dakha",
      "CityValue": "Mullanpur Dakha"
    },
    {
      "id": 3590,
      "CityName": "Kabilpor",
      "CityValue": "Kabilpor"
    },
    {
      "id": 3591,
      "CityName": "Nenmenikkara",
      "CityValue": "Nenmenikkara"
    },
    {
      "id": 3592,
      "CityName": "Susner",
      "CityValue": "Susner"
    },
    {
      "id": 3593,
      "CityName": "Thiruporur",
      "CityValue": "Thiruporur"
    },
    {
      "id": 3594,
      "CityName": "Banki",
      "CityValue": "Banki"
    },
    {
      "id": 3595,
      "CityName": "Edakkazhiyur",
      "CityValue": "Edakkazhiyur"
    },
    {
      "id": 3596,
      "CityName": "Talwara",
      "CityValue": "Talwara"
    },
    {
      "id": 3597,
      "CityName": "Sundna",
      "CityValue": "Sundna"
    },
    {
      "id": 3598,
      "CityName": "Deogarh",
      "CityValue": "Deogarh"
    },
    {
      "id": 3599,
      "CityName": "Kunnummal",
      "CityValue": "Kunnummal"
    },
    {
      "id": 3600,
      "CityName": "Papanasam",
      "CityValue": "Papanasam"
    },
    {
      "id": 3601,
      "CityName": "Curti",
      "CityValue": "Curti"
    },
    {
      "id": 3602,
      "CityName": "Sumbal",
      "CityValue": "Sumbal"
    },
    {
      "id": 3603,
      "CityName": "Mihona",
      "CityValue": "Mihona"
    },
    {
      "id": 3604,
      "CityName": "Ahore",
      "CityValue": "Ahore"
    },
    {
      "id": 3605,
      "CityName": "Uttarkashi",
      "CityValue": "Uttarkashi"
    },
    {
      "id": 3606,
      "CityName": "Maldhe",
      "CityValue": "Maldhe"
    },
    {
      "id": 3607,
      "CityName": "Davorlim",
      "CityValue": "Davorlim"
    },
    {
      "id": 3608,
      "CityName": "Ajaigarh",
      "CityValue": "Ajaigarh"
    },
    {
      "id": 3609,
      "CityName": "Mithipur",
      "CityValue": "Mithipur"
    },
    {
      "id": 3610,
      "CityName": "Nalkheda",
      "CityValue": "Nalkheda"
    },
    {
      "id": 3611,
      "CityName": "Jadugora",
      "CityValue": "Jadugora"
    },
    {
      "id": 3612,
      "CityName": "Modameedipalle",
      "CityValue": "Modameedipalle"
    },
    {
      "id": 3613,
      "CityName": "Siralkoppa",
      "CityValue": "Siralkoppa"
    },
    {
      "id": 3614,
      "CityName": "Jalda",
      "CityValue": "Jalda"
    },
    {
      "id": 3615,
      "CityName": "Hatpiplya",
      "CityValue": "Hatpiplya"
    },
    {
      "id": 3616,
      "CityName": "Chikkabidarakallu",
      "CityValue": "Chikkabidarakallu"
    },
    {
      "id": 3617,
      "CityName": "Bahula",
      "CityValue": "Bahula"
    },
    {
      "id": 3618,
      "CityName": "Kabisurjyanagar",
      "CityValue": "Kabisurjyanagar"
    },
    {
      "id": 3619,
      "CityName": "Chamkaur Sahib",
      "CityValue": "Chamkaur Sahib"
    },
    {
      "id": 3620,
      "CityName": "Ayyalur",
      "CityValue": "Ayyalur"
    },
    {
      "id": 3621,
      "CityName": "Varam",
      "CityValue": "Varam"
    },
    {
      "id": 3622,
      "CityName": "Kalghatgi",
      "CityValue": "Kalghatgi"
    },
    {
      "id": 3623,
      "CityName": "Jagiroad",
      "CityValue": "Jagiroad"
    },
    {
      "id": 3624,
      "CityName": "Pudussery Central",
      "CityValue": "Pudussery Central"
    },
    {
      "id": 3625,
      "CityName": "Nagamalaipudukottai",
      "CityValue": "Nagamalaipudukottai"
    },
    {
      "id": 3626,
      "CityName": "Garacharma",
      "CityValue": "Garacharma"
    },
    {
      "id": 3627,
      "CityName": "Kadaiya",
      "CityValue": "Kadaiya"
    },
    {
      "id": 3628,
      "CityName": "Kattur",
      "CityValue": "Kattur"
    },
    {
      "id": 3629,
      "CityName": "Kanke",
      "CityValue": "Kanke"
    },
    {
      "id": 3630,
      "CityName": "Ranipur",
      "CityValue": "Ranipur"
    },
    {
      "id": 3631,
      "CityName": "Chirgaon",
      "CityValue": "Chirgaon"
    },
    {
      "id": 3632,
      "CityName": "Khusrupur",
      "CityValue": "Khusrupur"
    },
    {
      "id": 3633,
      "CityName": "Dharamapuram",
      "CityValue": "Dharamapuram"
    },
    {
      "id": 3634,
      "CityName": "Buita",
      "CityValue": "Buita"
    },
    {
      "id": 3635,
      "CityName": "Bakkar Wala",
      "CityValue": "Bakkar Wala"
    },
    {
      "id": 3636,
      "CityName": "Melachokkanathapuram",
      "CityValue": "Melachokkanathapuram"
    },
    {
      "id": 3637,
      "CityName": "Ban Harishpur",
      "CityValue": "Ban Harishpur"
    },
    {
      "id": 3638,
      "CityName": "Bicholim",
      "CityValue": "Bicholim"
    },
    {
      "id": 3639,
      "CityName": "Rahon",
      "CityValue": "Rahon"
    },
    {
      "id": 3640,
      "CityName": "Krishnapur",
      "CityValue": "Krishnapur"
    },
    {
      "id": 3641,
      "CityName": "Domchanch",
      "CityValue": "Domchanch"
    },
    {
      "id": 3642,
      "CityName": "Babai",
      "CityValue": "Babai"
    },
    {
      "id": 3643,
      "CityName": "Sirumugai",
      "CityValue": "Sirumugai"
    },
    {
      "id": 3644,
      "CityName": "Nawabganj",
      "CityValue": "Nawabganj"
    },
    {
      "id": 3645,
      "CityName": "Bangarda Bada",
      "CityValue": "Bangarda Bada"
    },
    {
      "id": 3646,
      "CityName": "Athagad",
      "CityValue": "Athagad"
    },
    {
      "id": 3647,
      "CityName": "Bara Suzapur",
      "CityValue": "Bara Suzapur"
    },
    {
      "id": 3648,
      "CityName": "Omalur",
      "CityValue": "Omalur"
    },
    {
      "id": 3649,
      "CityName": "Jaitpur",
      "CityValue": "Jaitpur"
    },
    {
      "id": 3650,
      "CityName": "Khaniyadhana",
      "CityValue": "Khaniyadhana"
    },
    {
      "id": 3651,
      "CityName": "Hadgood",
      "CityValue": "Hadgood"
    },
    {
      "id": 3652,
      "CityName": "Teonthar",
      "CityValue": "Teonthar"
    },
    {
      "id": 3653,
      "CityName": "Panoor",
      "CityValue": "Panoor"
    },
    {
      "id": 3654,
      "CityName": "Iglas",
      "CityValue": "Iglas"
    },
    {
      "id": 3655,
      "CityName": "Garhshankar",
      "CityValue": "Garhshankar"
    },
    {
      "id": 3656,
      "CityName": "Sangameshwar",
      "CityValue": "Sangameshwar"
    },
    {
      "id": 3657,
      "CityName": "Piriyapatna",
      "CityValue": "Piriyapatna"
    },
    {
      "id": 3658,
      "CityName": "Moreh",
      "CityValue": "Moreh"
    },
    {
      "id": 3659,
      "CityName": "Iriveri",
      "CityValue": "Iriveri"
    },
    {
      "id": 3660,
      "CityName": "Bisharatganj",
      "CityValue": "Bisharatganj"
    },
    {
      "id": 3661,
      "CityName": "Manalur",
      "CityValue": "Manalur"
    },
    {
      "id": 3662,
      "CityName": "Sankarapuram",
      "CityValue": "Sankarapuram"
    },
    {
      "id": 3663,
      "CityName": "Shelar",
      "CityValue": "Shelar"
    },
    {
      "id": 3664,
      "CityName": "Pandavarmangalam",
      "CityValue": "Pandavarmangalam"
    },
    {
      "id": 3665,
      "CityName": "Ajitpur",
      "CityValue": "Ajitpur"
    },
    {
      "id": 3666,
      "CityName": "Anaimalai",
      "CityValue": "Anaimalai"
    },
    {
      "id": 3667,
      "CityName": "Sindkhed Raja",
      "CityValue": "Sindkhed Raja"
    },
    {
      "id": 3668,
      "CityName": "Simga",
      "CityValue": "Simga"
    },
    {
      "id": 3669,
      "CityName": "Saidul Azaib",
      "CityValue": "Saidul Azaib"
    },
    {
      "id": 3670,
      "CityName": "Parasuvaikkal",
      "CityValue": "Parasuvaikkal"
    },
    {
      "id": 3671,
      "CityName": "Sivagiripatti",
      "CityValue": "Sivagiripatti"
    },
    {
      "id": 3672,
      "CityName": "Asind",
      "CityValue": "Asind"
    },
    {
      "id": 3673,
      "CityName": "Pachchal",
      "CityValue": "Pachchal"
    },
    {
      "id": 3674,
      "CityName": "Vallam",
      "CityValue": "Vallam"
    },
    {
      "id": 3675,
      "CityName": "Walajabad",
      "CityValue": "Walajabad"
    },
    {
      "id": 3676,
      "CityName": "Makum",
      "CityValue": "Makum"
    },
    {
      "id": 3677,
      "CityName": "Hanumana",
      "CityValue": "Hanumana"
    },
    {
      "id": 3678,
      "CityName": "Aurangabad",
      "CityValue": "Aurangabad"
    },
    {
      "id": 3679,
      "CityName": "Thenambakkam",
      "CityValue": "Thenambakkam"
    },
    {
      "id": 3680,
      "CityName": "Chala",
      "CityValue": "Chala"
    },
    {
      "id": 3681,
      "CityName": "Kohima Village",
      "CityValue": "Kohima Village"
    },
    {
      "id": 3682,
      "CityName": "Anandpur Sahib",
      "CityValue": "Anandpur Sahib"
    },
    {
      "id": 3683,
      "CityName": "Manjhanpur",
      "CityValue": "Manjhanpur"
    },
    {
      "id": 3684,
      "CityName": "Rangapara",
      "CityValue": "Rangapara"
    },
    {
      "id": 3685,
      "CityName": "Sawangi",
      "CityValue": "Sawangi"
    },
    {
      "id": 3686,
      "CityName": "Amta",
      "CityValue": "Amta"
    },
    {
      "id": 3687,
      "CityName": "Kombai",
      "CityValue": "Kombai"
    },
    {
      "id": 3688,
      "CityName": "Silapadi",
      "CityValue": "Silapadi"
    },
    {
      "id": 3689,
      "CityName": "Mulki",
      "CityValue": "Mulki"
    },
    {
      "id": 3690,
      "CityName": "Sambavar Vadagarai",
      "CityValue": "Sambavar Vadagarai"
    },
    {
      "id": 3691,
      "CityName": "Konark",
      "CityValue": "Konark"
    },
    {
      "id": 3692,
      "CityName": "Arkalgud",
      "CityValue": "Arkalgud"
    },
    {
      "id": 3693,
      "CityName": "Kamakshyanagar",
      "CityValue": "Kamakshyanagar"
    },
    {
      "id": 3694,
      "CityName": "Tari",
      "CityValue": "Tari"
    },
    {
      "id": 3695,
      "CityName": "Belgaum Cantonment",
      "CityValue": "Belgaum Cantonment"
    },
    {
      "id": 3696,
      "CityName": "Amet",
      "CityValue": "Amet"
    },
    {
      "id": 3697,
      "CityName": "Alnavar",
      "CityValue": "Alnavar"
    },
    {
      "id": 3698,
      "CityName": "Kotekara",
      "CityValue": "Kotekara"
    },
    {
      "id": 3699,
      "CityName": "Khuldabad",
      "CityValue": "Khuldabad"
    },
    {
      "id": 3700,
      "CityName": "Khatauli Rural",
      "CityValue": "Khatauli Rural"
    },
    {
      "id": 3701,
      "CityName": "Kolacherry",
      "CityValue": "Kolacherry"
    },
    {
      "id": 3702,
      "CityName": "Kulasekaram",
      "CityValue": "Kulasekaram"
    },
    {
      "id": 3703,
      "CityName": "Banasthali",
      "CityValue": "Banasthali"
    },
    {
      "id": 3704,
      "CityName": "Gopalpur",
      "CityValue": "Gopalpur"
    },
    {
      "id": 3705,
      "CityName": "Padali Gujar",
      "CityValue": "Padali Gujar"
    },
    {
      "id": 3706,
      "CityName": "Ariyappampalayam",
      "CityValue": "Ariyappampalayam"
    },
    {
      "id": 3707,
      "CityName": "Mamallapuram",
      "CityValue": "Mamallapuram"
    },
    {
      "id": 3708,
      "CityName": "Thandla",
      "CityValue": "Thandla"
    },
    {
      "id": 3709,
      "CityName": "Saidhari",
      "CityValue": "Saidhari"
    },
    {
      "id": 3710,
      "CityName": "Navalpattu",
      "CityValue": "Navalpattu"
    },
    {
      "id": 3711,
      "CityName": "Hamirpur",
      "CityValue": "Hamirpur"
    },
    {
      "id": 3712,
      "CityName": "Kallur Thekkummuri",
      "CityValue": "Kallur Thekkummuri"
    },
    {
      "id": 3713,
      "CityName": "Banapur",
      "CityValue": "Banapur"
    },
    {
      "id": 3714,
      "CityName": "Bermo",
      "CityValue": "Bermo"
    },
    {
      "id": 3715,
      "CityName": "Ashrafpur Kichhauchha",
      "CityValue": "Ashrafpur Kichhauchha"
    },
    {
      "id": 3716,
      "CityName": "Piplya Kumar",
      "CityValue": "Piplya Kumar"
    },
    {
      "id": 3717,
      "CityName": "Sanaswadi",
      "CityValue": "Sanaswadi"
    },
    {
      "id": 3718,
      "CityName": "Medipalle",
      "CityValue": "Medipalle"
    },
    {
      "id": 3719,
      "CityName": "Lakhipur",
      "CityValue": "Lakhipur"
    },
    {
      "id": 3720,
      "CityName": "Kuthalam",
      "CityValue": "Kuthalam"
    },
    {
      "id": 3721,
      "CityName": "Satpokhari",
      "CityValue": "Satpokhari"
    },
    {
      "id": 3722,
      "CityName": "Kulihanda",
      "CityValue": "Kulihanda"
    },
    {
      "id": 3723,
      "CityName": "Vengavasal",
      "CityValue": "Vengavasal"
    },
    {
      "id": 3724,
      "CityName": "Eravattur",
      "CityValue": "Eravattur"
    },
    {
      "id": 3725,
      "CityName": "Chandia",
      "CityValue": "Chandia"
    },
    {
      "id": 3726,
      "CityName": "Koratty",
      "CityValue": "Koratty"
    },
    {
      "id": 3727,
      "CityName": "Thanakkankulam",
      "CityValue": "Thanakkankulam"
    },
    {
      "id": 3728,
      "CityName": "Durgapur",
      "CityValue": "Durgapur"
    },
    {
      "id": 3729,
      "CityName": "Mughalsarai Railway Settlement",
      "CityValue": "Mughalsarai Railway Settlement"
    },
    {
      "id": 3730,
      "CityName": "Northern Railway Colony",
      "CityValue": "Northern Railway Colony"
    },
    {
      "id": 3731,
      "CityName": "Almaspur",
      "CityValue": "Almaspur"
    },
    {
      "id": 3732,
      "CityName": "Bhimpore",
      "CityValue": "Bhimpore"
    },
    {
      "id": 3733,
      "CityName": "Naranammalpuram",
      "CityValue": "Naranammalpuram"
    },
    {
      "id": 3734,
      "CityName": "Sultanpur",
      "CityValue": "Sultanpur"
    },
    {
      "id": 3735,
      "CityName": "Malumichampatti",
      "CityValue": "Malumichampatti"
    },
    {
      "id": 3736,
      "CityName": "Khongapani",
      "CityValue": "Khongapani"
    },
    {
      "id": 3737,
      "CityName": "Chelamattom",
      "CityValue": "Chelamattom"
    },
    {
      "id": 3738,
      "CityName": "Bilsanda",
      "CityValue": "Bilsanda"
    },
    {
      "id": 3739,
      "CityName": "Pathamadai",
      "CityValue": "Pathamadai"
    },
    {
      "id": 3740,
      "CityName": "Kottaiyur",
      "CityValue": "Kottaiyur"
    },
    {
      "id": 3741,
      "CityName": "Eruvatti",
      "CityValue": "Eruvatti"
    },
    {
      "id": 3742,
      "CityName": "Badshahpur",
      "CityValue": "Badshahpur"
    },
    {
      "id": 3743,
      "CityName": "Merta Road",
      "CityValue": "Merta Road"
    },
    {
      "id": 3744,
      "CityName": "Dewa",
      "CityValue": "Dewa"
    },
    {
      "id": 3745,
      "CityName": "Shahgarh",
      "CityValue": "Shahgarh"
    },
    {
      "id": 3746,
      "CityName": "Sanjan",
      "CityValue": "Sanjan"
    },
    {
      "id": 3747,
      "CityName": "Gandevi",
      "CityValue": "Gandevi"
    },
    {
      "id": 3748,
      "CityName": "Nongmynsong",
      "CityValue": "Nongmynsong"
    },
    {
      "id": 3749,
      "CityName": "Ayyampettai",
      "CityValue": "Ayyampettai"
    },
    {
      "id": 3750,
      "CityName": "Zamin Uthukuli",
      "CityValue": "Zamin Uthukuli"
    },
    {
      "id": 3751,
      "CityName": "Chakulia",
      "CityValue": "Chakulia"
    },
    {
      "id": 3752,
      "CityName": "Baliguda",
      "CityValue": "Baliguda"
    },
    {
      "id": 3753,
      "CityName": "Mohammad Pur Majri",
      "CityValue": "Mohammad Pur Majri"
    },
    {
      "id": 3754,
      "CityName": "Asangaon",
      "CityValue": "Asangaon"
    },
    {
      "id": 3755,
      "CityName": "Uttar Latabari",
      "CityValue": "Uttar Latabari"
    },
    {
      "id": 3756,
      "CityName": "Kappiyarai",
      "CityValue": "Kappiyarai"
    },
    {
      "id": 3757,
      "CityName": "Kshirpai",
      "CityValue": "Kshirpai"
    },
    {
      "id": 3758,
      "CityName": "Kanchanpur",
      "CityValue": "Kanchanpur"
    },
    {
      "id": 3759,
      "CityName": "Thirunagar",
      "CityValue": "Thirunagar"
    },
    {
      "id": 3760,
      "CityName": "Jhorhat",
      "CityValue": "Jhorhat"
    },
    {
      "id": 3761,
      "CityName": "Gerugambakkam",
      "CityValue": "Gerugambakkam"
    },
    {
      "id": 3762,
      "CityName": "Damodarpur",
      "CityValue": "Damodarpur"
    },
    {
      "id": 3763,
      "CityName": "Jambai",
      "CityValue": "Jambai"
    },
    {
      "id": 3764,
      "CityName": "Sundarkhed",
      "CityValue": "Sundarkhed"
    },
    {
      "id": 3765,
      "CityName": "Mundwa",
      "CityValue": "Mundwa"
    },
    {
      "id": 3766,
      "CityName": "Anantapur",
      "CityValue": "Anantapur"
    },
    {
      "id": 3767,
      "CityName": "Jalakandapuram",
      "CityValue": "Jalakandapuram"
    },
    {
      "id": 3768,
      "CityName": "Masat",
      "CityValue": "Masat"
    },
    {
      "id": 3769,
      "CityName": "Ambikapur Pt. X",
      "CityValue": "Ambikapur Pt. X"
    },
    {
      "id": 3770,
      "CityName": "Sayan",
      "CityValue": "Sayan"
    },
    {
      "id": 3771,
      "CityName": "Bhikangaon",
      "CityValue": "Bhikangaon"
    },
    {
      "id": 3772,
      "CityName": "Sonpeth",
      "CityValue": "Sonpeth"
    },
    {
      "id": 3773,
      "CityName": "Baihar",
      "CityValue": "Baihar"
    },
    {
      "id": 3774,
      "CityName": "Gopamau",
      "CityValue": "Gopamau"
    },
    {
      "id": 3775,
      "CityName": "Ambehta",
      "CityValue": "Ambehta"
    },
    {
      "id": 3776,
      "CityName": "Khadda",
      "CityValue": "Khadda"
    },
    {
      "id": 3777,
      "CityName": "Jawad",
      "CityValue": "Jawad"
    },
    {
      "id": 3778,
      "CityName": "Manikpur Sarhat",
      "CityValue": "Manikpur Sarhat"
    },
    {
      "id": 3779,
      "CityName": "Keezhkulam",
      "CityValue": "Keezhkulam"
    },
    {
      "id": 3780,
      "CityName": "Sunhaira",
      "CityValue": "Sunhaira"
    },
    {
      "id": 3781,
      "CityName": "Rabupura",
      "CityValue": "Rabupura"
    },
    {
      "id": 3782,
      "CityName": "Bagrana",
      "CityValue": "Bagrana"
    },
    {
      "id": 3783,
      "CityName": "Bhiloda",
      "CityValue": "Bhiloda"
    },
    {
      "id": 3784,
      "CityName": "Manapakkam",
      "CityValue": "Manapakkam"
    },
    {
      "id": 3785,
      "CityName": "Vettaikaranpudur",
      "CityValue": "Vettaikaranpudur"
    },
    {
      "id": 3786,
      "CityName": "Dighirpar",
      "CityValue": "Dighirpar"
    },
    {
      "id": 3787,
      "CityName": "Kankavli",
      "CityValue": "Kankavli"
    },
    {
      "id": 3788,
      "CityName": "Kudal",
      "CityValue": "Kudal"
    },
    {
      "id": 3789,
      "CityName": "Veerapandi",
      "CityValue": "Veerapandi"
    },
    {
      "id": 3790,
      "CityName": "Rajbalhat",
      "CityValue": "Rajbalhat"
    },
    {
      "id": 3791,
      "CityName": "Simla",
      "CityValue": "Simla"
    },
    {
      "id": 3792,
      "CityName": "Vathirairuppu",
      "CityValue": "Vathirairuppu"
    },
    {
      "id": 3793,
      "CityName": "Chimbel",
      "CityValue": "Chimbel"
    },
    {
      "id": 3794,
      "CityName": "Takhatgarh",
      "CityValue": "Takhatgarh"
    },
    {
      "id": 3795,
      "CityName": "Goraya",
      "CityValue": "Goraya"
    },
    {
      "id": 3796,
      "CityName": "Bah",
      "CityValue": "Bah"
    },
    {
      "id": 3797,
      "CityName": "Papampeta",
      "CityValue": "Papampeta"
    },
    {
      "id": 3798,
      "CityName": "Salap",
      "CityValue": "Salap"
    },
    {
      "id": 3799,
      "CityName": "Peralassery",
      "CityValue": "Peralassery"
    },
    {
      "id": 3800,
      "CityName": "Sidhpura",
      "CityValue": "Sidhpura"
    },
    {
      "id": 3801,
      "CityName": "Malkapur",
      "CityValue": "Malkapur"
    },
    {
      "id": 3802,
      "CityName": "Othakadai",
      "CityValue": "Othakadai"
    },
    {
      "id": 3803,
      "CityName": "Agaram",
      "CityValue": "Agaram"
    },
    {
      "id": 3804,
      "CityName": "Rura",
      "CityValue": "Rura"
    },
    {
      "id": 3805,
      "CityName": "Chitegaon",
      "CityValue": "Chitegaon"
    },
    {
      "id": 3806,
      "CityName": "Timiri",
      "CityValue": "Timiri"
    },
    {
      "id": 3807,
      "CityName": "Pinarayi",
      "CityValue": "Pinarayi"
    },
    {
      "id": 3808,
      "CityName": "Shah Pur",
      "CityValue": "Shah Pur"
    },
    {
      "id": 3809,
      "CityName": "Lormi",
      "CityValue": "Lormi"
    },
    {
      "id": 3810,
      "CityName": "Barahatir Jagdishpur",
      "CityValue": "Barahatir Jagdishpur"
    },
    {
      "id": 3811,
      "CityName": "Kandamkunnu",
      "CityValue": "Kandamkunnu"
    },
    {
      "id": 3812,
      "CityName": "Sojitra",
      "CityValue": "Sojitra"
    },
    {
      "id": 3813,
      "CityName": "Thiruvaiyaru",
      "CityValue": "Thiruvaiyaru"
    },
    {
      "id": 3814,
      "CityName": "Shahjahanpur",
      "CityValue": "Shahjahanpur"
    },
    {
      "id": 3815,
      "CityName": "Morampudi",
      "CityValue": "Morampudi"
    },
    {
      "id": 3816,
      "CityName": "Valvaithankoshtam",
      "CityValue": "Valvaithankoshtam"
    },
    {
      "id": 3817,
      "CityName": "Bharatganj",
      "CityValue": "Bharatganj"
    },
    {
      "id": 3818,
      "CityName": "Hinjavadi",
      "CityValue": "Hinjavadi"
    },
    {
      "id": 3819,
      "CityName": "Sambhalka",
      "CityValue": "Sambhalka"
    },
    {
      "id": 3820,
      "CityName": "Sonkatch",
      "CityValue": "Sonkatch"
    },
    {
      "id": 3821,
      "CityName": "Bisokhar",
      "CityValue": "Bisokhar"
    },
    {
      "id": 3822,
      "CityName": "Dukli",
      "CityValue": "Dukli"
    },
    {
      "id": 3823,
      "CityName": "Cuncolim",
      "CityValue": "Cuncolim"
    },
    {
      "id": 3824,
      "CityName": "Ongallur -I",
      "CityValue": "Ongallur -I"
    },
    {
      "id": 3825,
      "CityName": "Porathissery",
      "CityValue": "Porathissery"
    },
    {
      "id": 3826,
      "CityName": "Ambicapur Pt VIII",
      "CityValue": "Ambicapur Pt VIII"
    },
    {
      "id": 3827,
      "CityName": "Dharni",
      "CityValue": "Dharni"
    },
    {
      "id": 3828,
      "CityName": "Hathin",
      "CityValue": "Hathin"
    },
    {
      "id": 3829,
      "CityName": "Karungal",
      "CityValue": "Karungal"
    },
    {
      "id": 3830,
      "CityName": "Kusum Pur",
      "CityValue": "Kusum Pur"
    },
    {
      "id": 3831,
      "CityName": "Wadagaon",
      "CityValue": "Wadagaon"
    },
    {
      "id": 3832,
      "CityName": "Bhavri",
      "CityValue": "Bhavri"
    },
    {
      "id": 3833,
      "CityName": "Gandhigram",
      "CityValue": "Gandhigram"
    },
    {
      "id": 3834,
      "CityName": "Kandari",
      "CityValue": "Kandari"
    },
    {
      "id": 3835,
      "CityName": "Chhitpur",
      "CityValue": "Chhitpur"
    },
    {
      "id": 3836,
      "CityName": "Kotpad",
      "CityValue": "Kotpad"
    },
    {
      "id": 3837,
      "CityName": "Quimoh",
      "CityValue": "Quimoh"
    },
    {
      "id": 3838,
      "CityName": "Kandla",
      "CityValue": "Kandla"
    },
    {
      "id": 3839,
      "CityName": "Vavol",
      "CityValue": "Vavol"
    },
    {
      "id": 3840,
      "CityName": "Elumalai",
      "CityValue": "Elumalai"
    },
    {
      "id": 3841,
      "CityName": "Yelbarga",
      "CityValue": "Yelbarga"
    },
    {
      "id": 3842,
      "CityName": "Chachanda",
      "CityValue": "Chachanda"
    },
    {
      "id": 3843,
      "CityName": "Ajeetgarh",
      "CityValue": "Ajeetgarh"
    },
    {
      "id": 3844,
      "CityName": "Hindoria",
      "CityValue": "Hindoria"
    },
    {
      "id": 3845,
      "CityName": "Namchi",
      "CityValue": "Namchi"
    },
    {
      "id": 3846,
      "CityName": "Thevaram",
      "CityValue": "Thevaram"
    },
    {
      "id": 3847,
      "CityName": "Kokkothamangalam",
      "CityValue": "Kokkothamangalam"
    },
    {
      "id": 3848,
      "CityName": "Chanje",
      "CityValue": "Chanje"
    },
    {
      "id": 3849,
      "CityName": "Arumanai",
      "CityValue": "Arumanai"
    },
    {
      "id": 3850,
      "CityName": "Koilwar",
      "CityValue": "Koilwar"
    },
    {
      "id": 3851,
      "CityName": "Gird Gonda",
      "CityValue": "Gird Gonda"
    },
    {
      "id": 3852,
      "CityName": "Kaliyakkavilai",
      "CityValue": "Kaliyakkavilai"
    },
    {
      "id": 3853,
      "CityName": "Benia Gram",
      "CityValue": "Benia Gram"
    },
    {
      "id": 3854,
      "CityName": "Katangi",
      "CityValue": "Katangi"
    },
    {
      "id": 3855,
      "CityName": "Jali",
      "CityValue": "Jali"
    },
    {
      "id": 3856,
      "CityName": "Veerakkalpudur",
      "CityValue": "Veerakkalpudur"
    },
    {
      "id": 3857,
      "CityName": "Kachhauna Patseni",
      "CityValue": "Kachhauna Patseni"
    },
    {
      "id": 3858,
      "CityName": "Petlawad",
      "CityValue": "Petlawad"
    },
    {
      "id": 3859,
      "CityName": "Thenkarai",
      "CityValue": "Thenkarai"
    },
    {
      "id": 3860,
      "CityName": "Khadki Bk",
      "CityValue": "Khadki Bk"
    },
    {
      "id": 3861,
      "CityName": "Palani Chettipatti",
      "CityValue": "Palani Chettipatti"
    },
    {
      "id": 3862,
      "CityName": "Madukkur",
      "CityValue": "Madukkur"
    },
    {
      "id": 3863,
      "CityName": "Salumbar",
      "CityValue": "Salumbar"
    },
    {
      "id": 3864,
      "CityName": "Ichhawar",
      "CityValue": "Ichhawar"
    },
    {
      "id": 3865,
      "CityName": "Sukma",
      "CityValue": "Sukma"
    },
    {
      "id": 3866,
      "CityName": "Mannar",
      "CityValue": "Mannar"
    },
    {
      "id": 3867,
      "CityName": "Srikantabati",
      "CityValue": "Srikantabati"
    },
    {
      "id": 3868,
      "CityName": "Thakkolam",
      "CityValue": "Thakkolam"
    },
    {
      "id": 3869,
      "CityName": "Chhutmalpur",
      "CityValue": "Chhutmalpur"
    },
    {
      "id": 3870,
      "CityName": "Kangeyanallur",
      "CityValue": "Kangeyanallur"
    },
    {
      "id": 3871,
      "CityName": "Molakalmuru",
      "CityValue": "Molakalmuru"
    },
    {
      "id": 3872,
      "CityName": "Nambiyur",
      "CityValue": "Nambiyur"
    },
    {
      "id": 3873,
      "CityName": "Vettavalam",
      "CityValue": "Vettavalam"
    },
    {
      "id": 3874,
      "CityName": "Dongargaon",
      "CityValue": "Dongargaon"
    },
    {
      "id": 3875,
      "CityName": "Dera Mandi",
      "CityValue": "Dera Mandi"
    },
    {
      "id": 3876,
      "CityName": "R.S.Mangalam",
      "CityValue": "R.S.Mangalam"
    },
    {
      "id": 3877,
      "CityName": "Pratapgarh City",
      "CityValue": "Pratapgarh City"
    },
    {
      "id": 3878,
      "CityName": "Dharasuram",
      "CityValue": "Dharasuram"
    },
    {
      "id": 3879,
      "CityName": "Kochinda",
      "CityValue": "Kochinda"
    },
    {
      "id": 3880,
      "CityName": "Dungariya Chhapara",
      "CityValue": "Dungariya Chhapara"
    },
    {
      "id": 3881,
      "CityName": "Vaniyamkulam- II",
      "CityValue": "Vaniyamkulam- II"
    },
    {
      "id": 3882,
      "CityName": "Naregal",
      "CityValue": "Naregal"
    },
    {
      "id": 3883,
      "CityName": "Kutiyana",
      "CityValue": "Kutiyana"
    },
    {
      "id": 3884,
      "CityName": "Talode Panchnad",
      "CityValue": "Talode Panchnad"
    },
    {
      "id": 3885,
      "CityName": "Kasba Bonli",
      "CityValue": "Kasba Bonli"
    },
    {
      "id": 3886,
      "CityName": "Ponmanai",
      "CityValue": "Ponmanai"
    },
    {
      "id": 3887,
      "CityName": "Saidpur",
      "CityValue": "Saidpur"
    },
    {
      "id": 3888,
      "CityName": "Kamalpur",
      "CityValue": "Kamalpur"
    },
    {
      "id": 3889,
      "CityName": "Khetia",
      "CityValue": "Khetia"
    },
    {
      "id": 3890,
      "CityName": "Chalala",
      "CityValue": "Chalala"
    },
    {
      "id": 3891,
      "CityName": "Goniana",
      "CityValue": "Goniana"
    },
    {
      "id": 3892,
      "CityName": "Kurwai",
      "CityValue": "Kurwai"
    },
    {
      "id": 3893,
      "CityName": "Mouda",
      "CityValue": "Mouda"
    },
    {
      "id": 3894,
      "CityName": "Manikpur",
      "CityValue": "Manikpur"
    },
    {
      "id": 3895,
      "CityName": "Shaktinagar",
      "CityValue": "Shaktinagar"
    },
    {
      "id": 3896,
      "CityName": "Obra",
      "CityValue": "Obra"
    },
    {
      "id": 3897,
      "CityName": "Damnagar",
      "CityValue": "Damnagar"
    },
    {
      "id": 3898,
      "CityName": "Sainthal",
      "CityValue": "Sainthal"
    },
    {
      "id": 3899,
      "CityName": "Bhadkodara",
      "CityValue": "Bhadkodara"
    },
    {
      "id": 3900,
      "CityName": "Tangla",
      "CityValue": "Tangla"
    },
    {
      "id": 3901,
      "CityName": "Kachhwa",
      "CityValue": "Kachhwa"
    },
    {
      "id": 3902,
      "CityName": "Baharu",
      "CityValue": "Baharu"
    },
    {
      "id": 3903,
      "CityName": "Boothapandi",
      "CityValue": "Boothapandi"
    },
    {
      "id": 3904,
      "CityName": "Chengala",
      "CityValue": "Chengala"
    },
    {
      "id": 3905,
      "CityName": "Baghmara",
      "CityValue": "Baghmara"
    },
    {
      "id": 3906,
      "CityName": "Nazerath",
      "CityValue": "Nazerath"
    },
    {
      "id": 3907,
      "CityName": "Kushalnagar",
      "CityValue": "Kushalnagar"
    },
    {
      "id": 3908,
      "CityName": "Chendrappini",
      "CityValue": "Chendrappini"
    },
    {
      "id": 3909,
      "CityName": "Alur",
      "CityValue": "Alur"
    },
    {
      "id": 3910,
      "CityName": "Khudaganj",
      "CityValue": "Khudaganj"
    },
    {
      "id": 3911,
      "CityName": "Balitha",
      "CityValue": "Balitha"
    },
    {
      "id": 3912,
      "CityName": "Islampur",
      "CityValue": "Islampur"
    },
    {
      "id": 3913,
      "CityName": "Dumra",
      "CityValue": "Dumra"
    },
    {
      "id": 3914,
      "CityName": "Kadamakkudy",
      "CityValue": "Kadamakkudy"
    },
    {
      "id": 3915,
      "CityName": "Nilaiyur",
      "CityValue": "Nilaiyur"
    },
    {
      "id": 3916,
      "CityName": "Narayanapuram",
      "CityValue": "Narayanapuram"
    },
    {
      "id": 3917,
      "CityName": "Palayam",
      "CityValue": "Palayam"
    },
    {
      "id": 3918,
      "CityName": "Holalkere",
      "CityValue": "Holalkere"
    },
    {
      "id": 3919,
      "CityName": "Allahganj",
      "CityValue": "Allahganj"
    },
    {
      "id": 3920,
      "CityName": "Binika",
      "CityValue": "Binika"
    },
    {
      "id": 3921,
      "CityName": "Chandameta- Butaria",
      "CityValue": "Chandameta- Butaria"
    },
    {
      "id": 3922,
      "CityName": "Sadaura",
      "CityValue": "Sadaura"
    },
    {
      "id": 3923,
      "CityName": "Sayalgudi",
      "CityValue": "Sayalgudi"
    },
    {
      "id": 3924,
      "CityName": "Mulanur",
      "CityValue": "Mulanur"
    },
    {
      "id": 3925,
      "CityName": "Pothreddipalle",
      "CityValue": "Pothreddipalle"
    },
    {
      "id": 3926,
      "CityName": "Khandra",
      "CityValue": "Khandra"
    },
    {
      "id": 3927,
      "CityName": "Partap Singhwala",
      "CityValue": "Partap Singhwala"
    },
    {
      "id": 3928,
      "CityName": "Maurawan",
      "CityValue": "Maurawan"
    },
    {
      "id": 3929,
      "CityName": "Chak Bankola",
      "CityValue": "Chak Bankola"
    },
    {
      "id": 3930,
      "CityName": "Chengalam South",
      "CityValue": "Chengalam South"
    },
    {
      "id": 3931,
      "CityName": "Koraon",
      "CityValue": "Koraon"
    },
    {
      "id": 3932,
      "CityName": "Jejuri",
      "CityValue": "Jejuri"
    },
    {
      "id": 3933,
      "CityName": "Kenda",
      "CityValue": "Kenda"
    },
    {
      "id": 3934,
      "CityName": "Rani Khera",
      "CityValue": "Rani Khera"
    },
    {
      "id": 3935,
      "CityName": "Makdon",
      "CityValue": "Makdon"
    },
    {
      "id": 3936,
      "CityName": "Bugrasi",
      "CityValue": "Bugrasi"
    },
    {
      "id": 3937,
      "CityName": "Farrukhnagar",
      "CityValue": "Farrukhnagar"
    },
    {
      "id": 3938,
      "CityName": "Tendukheda",
      "CityValue": "Tendukheda"
    },
    {
      "id": 3939,
      "CityName": "Saram",
      "CityValue": "Saram"
    },
    {
      "id": 3940,
      "CityName": "Saidapur",
      "CityValue": "Saidapur"
    },
    {
      "id": 3941,
      "CityName": "Abhayapuri",
      "CityValue": "Abhayapuri"
    },
    {
      "id": 3942,
      "CityName": "Vallabhipur",
      "CityValue": "Vallabhipur"
    },
    {
      "id": 3943,
      "CityName": "Nowshehra",
      "CityValue": "Nowshehra"
    },
    {
      "id": 3944,
      "CityName": "Banarhat Tea Garden",
      "CityValue": "Banarhat Tea Garden"
    },
    {
      "id": 3945,
      "CityName": "Palaganangudy",
      "CityValue": "Palaganangudy"
    },
    {
      "id": 3946,
      "CityName": "Samusigapuram",
      "CityValue": "Samusigapuram"
    },
    {
      "id": 3947,
      "CityName": "Shaktinagar",
      "CityValue": "Shaktinagar"
    },
    {
      "id": 3948,
      "CityName": "Chuglamsar",
      "CityValue": "Chuglamsar"
    },
    {
      "id": 3949,
      "CityName": "Aygudi",
      "CityValue": "Aygudi"
    },
    {
      "id": 3950,
      "CityName": "Janakpur Road",
      "CityValue": "Janakpur Road"
    },
    {
      "id": 3951,
      "CityName": "Redhakhol",
      "CityValue": "Redhakhol"
    },
    {
      "id": 3952,
      "CityName": "Vadakkekad",
      "CityValue": "Vadakkekad"
    },
    {
      "id": 3953,
      "CityName": "Vilathikulam",
      "CityValue": "Vilathikulam"
    },
    {
      "id": 3954,
      "CityName": "Tikri Kalan",
      "CityValue": "Tikri Kalan"
    },
    {
      "id": 3955,
      "CityName": "Kadaura",
      "CityValue": "Kadaura"
    },
    {
      "id": 3956,
      "CityName": "Khargapur",
      "CityValue": "Khargapur"
    },
    {
      "id": 3957,
      "CityName": "Kamatgi",
      "CityValue": "Kamatgi"
    },
    {
      "id": 3958,
      "CityName": "Bhattian",
      "CityValue": "Bhattian"
    },
    {
      "id": 3959,
      "CityName": "Narayankhed",
      "CityValue": "Narayankhed"
    },
    {
      "id": 3960,
      "CityName": "Buguda",
      "CityValue": "Buguda"
    },
    {
      "id": 3961,
      "CityName": "Enumamula",
      "CityValue": "Enumamula"
    },
    {
      "id": 3962,
      "CityName": "Anandanagar",
      "CityValue": "Anandanagar"
    },
    {
      "id": 3963,
      "CityName": "Koregaon Bhima",
      "CityValue": "Koregaon Bhima"
    },
    {
      "id": 3964,
      "CityName": "Thasra",
      "CityValue": "Thasra"
    },
    {
      "id": 3965,
      "CityName": "Karambakkudi",
      "CityValue": "Karambakkudi"
    },
    {
      "id": 3966,
      "CityName": "Yadagirigutta",
      "CityValue": "Yadagirigutta"
    },
    {
      "id": 3967,
      "CityName": "Deoretha",
      "CityValue": "Deoretha"
    },
    {
      "id": 3968,
      "CityName": "Danta",
      "CityValue": "Danta"
    },
    {
      "id": 3969,
      "CityName": "Mairang",
      "CityValue": "Mairang"
    },
    {
      "id": 3970,
      "CityName": "Thongkhong Laxmi Bazar",
      "CityValue": "Thongkhong Laxmi Bazar"
    },
    {
      "id": 3971,
      "CityName": "Makhu",
      "CityValue": "Makhu"
    },
    {
      "id": 3972,
      "CityName": "Mehu Wala Mafi",
      "CityValue": "Mehu Wala Mafi"
    },
    {
      "id": 3973,
      "CityName": "Prasadampadu",
      "CityValue": "Prasadampadu"
    },
    {
      "id": 3974,
      "CityName": "Kasara Bk.",
      "CityValue": "Kasara Bk."
    },
    {
      "id": 3975,
      "CityName": "Anpara",
      "CityValue": "Anpara"
    },
    {
      "id": 3976,
      "CityName": "Vidyavihar",
      "CityValue": "Vidyavihar"
    },
    {
      "id": 3977,
      "CityName": "Siltara",
      "CityValue": "Siltara"
    },
    {
      "id": 3978,
      "CityName": "Khadkale",
      "CityValue": "Khadkale"
    },
    {
      "id": 3979,
      "CityName": "Nagrota",
      "CityValue": "Nagrota"
    },
    {
      "id": 3980,
      "CityName": "Bhatpar Rani",
      "CityValue": "Bhatpar Rani"
    },
    {
      "id": 3981,
      "CityName": "Villukuri",
      "CityValue": "Villukuri"
    },
    {
      "id": 3982,
      "CityName": "Bikihakola",
      "CityValue": "Bikihakola"
    },
    {
      "id": 3983,
      "CityName": "Banumukkala",
      "CityValue": "Banumukkala"
    },
    {
      "id": 3984,
      "CityName": "Mira",
      "CityValue": "Mira"
    },
    {
      "id": 3985,
      "CityName": "Deodar",
      "CityValue": "Deodar"
    },
    {
      "id": 3986,
      "CityName": "Khanauri",
      "CityValue": "Khanauri"
    },
    {
      "id": 3987,
      "CityName": "Killai",
      "CityValue": "Killai"
    },
    {
      "id": 3988,
      "CityName": "Panangad",
      "CityValue": "Panangad"
    },
    {
      "id": 3989,
      "CityName": "Satalkheri",
      "CityValue": "Satalkheri"
    },
    {
      "id": 3990,
      "CityName": "Salangapalayam",
      "CityValue": "Salangapalayam"
    },
    {
      "id": 3991,
      "CityName": "Koratagere",
      "CityValue": "Koratagere"
    },
    {
      "id": 3992,
      "CityName": "Buja Buja Nellore",
      "CityValue": "Buja Buja Nellore"
    },
    {
      "id": 3993,
      "CityName": "Cherukunnu",
      "CityValue": "Cherukunnu"
    },
    {
      "id": 3994,
      "CityName": "Quepem",
      "CityValue": "Quepem"
    },
    {
      "id": 3995,
      "CityName": "Bhagta Bhai Ka",
      "CityValue": "Bhagta Bhai Ka"
    },
    {
      "id": 3996,
      "CityName": "Churhat",
      "CityValue": "Churhat"
    },
    {
      "id": 3997,
      "CityName": "Abhanpur",
      "CityValue": "Abhanpur"
    },
    {
      "id": 3998,
      "CityName": "Mohan",
      "CityValue": "Mohan"
    },
    {
      "id": 3999,
      "CityName": "Mendu",
      "CityValue": "Mendu"
    },
    {
      "id": 4000,
      "CityName": "Arasinakunte",
      "CityValue": "Arasinakunte"
    },
    {
      "id": 4001,
      "CityName": "Shafipur",
      "CityValue": "Shafipur"
    },
    {
      "id": 4002,
      "CityName": "Utnur",
      "CityValue": "Utnur"
    },
    {
      "id": 4003,
      "CityName": "Virupakshipuram",
      "CityValue": "Virupakshipuram"
    },
    {
      "id": 4004,
      "CityName": "Bari Sadri",
      "CityValue": "Bari Sadri"
    },
    {
      "id": 4005,
      "CityName": "Niz-Hajo",
      "CityValue": "Niz-Hajo"
    },
    {
      "id": 4006,
      "CityName": "Barhani Bazar",
      "CityValue": "Barhani Bazar"
    },
    {
      "id": 4007,
      "CityName": "Dhariwal",
      "CityValue": "Dhariwal"
    },
    {
      "id": 4008,
      "CityName": "Satwas",
      "CityValue": "Satwas"
    },
    {
      "id": 4009,
      "CityName": "Pipraich",
      "CityValue": "Pipraich"
    },
    {
      "id": 4010,
      "CityName": "Pipri",
      "CityValue": "Pipri"
    },
    {
      "id": 4011,
      "CityName": "Kanhirode",
      "CityValue": "Kanhirode"
    },
    {
      "id": 4012,
      "CityName": "Bilpura",
      "CityValue": "Bilpura"
    },
    {
      "id": 4013,
      "CityName": "Mukasipidariyur",
      "CityValue": "Mukasipidariyur"
    },
    {
      "id": 4014,
      "CityName": "Chanasma",
      "CityValue": "Chanasma"
    },
    {
      "id": 4015,
      "CityName": "Cumbum",
      "CityValue": "Cumbum"
    },
    {
      "id": 4016,
      "CityName": "Khariar",
      "CityValue": "Khariar"
    },
    {
      "id": 4017,
      "CityName": "Subeha",
      "CityValue": "Subeha"
    },
    {
      "id": 4018,
      "CityName": "Purusottampur",
      "CityValue": "Purusottampur"
    },
    {
      "id": 4019,
      "CityName": "Tal",
      "CityValue": "Tal"
    },
    {
      "id": 4020,
      "CityName": "Yenugonda",
      "CityValue": "Yenugonda"
    },
    {
      "id": 4021,
      "CityName": "Ikauna",
      "CityValue": "Ikauna"
    },
    {
      "id": 4022,
      "CityName": "Menhaniam",
      "CityValue": "Menhaniam"
    },
    {
      "id": 4023,
      "CityName": "Beri",
      "CityValue": "Beri"
    },
    {
      "id": 4024,
      "CityName": "Bakewar",
      "CityValue": "Bakewar"
    },
    {
      "id": 4025,
      "CityName": "Bhucho Mandi",
      "CityValue": "Bhucho Mandi"
    },
    {
      "id": 4026,
      "CityName": "Panchgani",
      "CityValue": "Panchgani"
    },
    {
      "id": 4027,
      "CityName": "Pingwan",
      "CityValue": "Pingwan"
    },
    {
      "id": 4028,
      "CityName": "Kunkuri",
      "CityValue": "Kunkuri"
    },
    {
      "id": 4029,
      "CityName": "Kaveripakkam",
      "CityValue": "Kaveripakkam"
    },
    {
      "id": 4030,
      "CityName": "Saguna",
      "CityValue": "Saguna"
    },
    {
      "id": 4031,
      "CityName": "Bansgaon",
      "CityValue": "Bansgaon"
    },
    {
      "id": 4032,
      "CityName": "Taranagar",
      "CityValue": "Taranagar"
    },
    {
      "id": 4033,
      "CityName": "Bhatni Bazar",
      "CityValue": "Bhatni Bazar"
    },
    {
      "id": 4034,
      "CityName": "Pawai",
      "CityValue": "Pawai"
    },
    {
      "id": 4035,
      "CityName": "Heggadadevankote",
      "CityValue": "Heggadadevankote"
    },
    {
      "id": 4036,
      "CityName": "Parbatsar",
      "CityValue": "Parbatsar"
    },
    {
      "id": 4037,
      "CityName": "Drug Mulla",
      "CityValue": "Drug Mulla"
    },
    {
      "id": 4038,
      "CityName": "Kamalganj",
      "CityValue": "Kamalganj"
    },
    {
      "id": 4039,
      "CityName": "Aminagad",
      "CityValue": "Aminagad"
    },
    {
      "id": 4040,
      "CityName": "Chatta Baria",
      "CityValue": "Chatta Baria"
    },
    {
      "id": 4041,
      "CityName": "Kumbalagodu",
      "CityValue": "Kumbalagodu"
    },
    {
      "id": 4042,
      "CityName": "R.S. Pora",
      "CityValue": "R.S. Pora"
    },
    {
      "id": 4043,
      "CityName": "Bharanikkavu",
      "CityValue": "Bharanikkavu"
    },
    {
      "id": 4044,
      "CityName": "Deori",
      "CityValue": "Deori"
    },
    {
      "id": 4045,
      "CityName": "Vangani",
      "CityValue": "Vangani"
    },
    {
      "id": 4046,
      "CityName": "Amloh",
      "CityValue": "Amloh"
    },
    {
      "id": 4047,
      "CityName": "Thirunageswaram",
      "CityValue": "Thirunageswaram"
    },
    {
      "id": 4048,
      "CityName": "Darewadi",
      "CityValue": "Darewadi"
    },
    {
      "id": 4049,
      "CityName": "Kandanassery",
      "CityValue": "Kandanassery"
    },
    {
      "id": 4050,
      "CityName": "Ramachandrapuram",
      "CityValue": "Ramachandrapuram"
    },
    {
      "id": 4051,
      "CityName": "Srivaikuntam",
      "CityValue": "Srivaikuntam"
    },
    {
      "id": 4052,
      "CityName": "Mukrampur Khema",
      "CityValue": "Mukrampur Khema"
    },
    {
      "id": 4053,
      "CityName": "Shivani",
      "CityValue": "Shivani"
    },
    {
      "id": 4054,
      "CityName": "Panisagar",
      "CityValue": "Panisagar"
    },
    {
      "id": 4055,
      "CityName": "Handwara",
      "CityValue": "Handwara"
    },
    {
      "id": 4056,
      "CityName": "Barwar",
      "CityValue": "Barwar"
    },
    {
      "id": 4057,
      "CityName": "Biloli",
      "CityValue": "Biloli"
    },
    {
      "id": 4058,
      "CityName": "Piplya Mandi",
      "CityValue": "Piplya Mandi"
    },
    {
      "id": 4059,
      "CityName": "Byrapura",
      "CityValue": "Byrapura"
    },
    {
      "id": 4060,
      "CityName": "Katkar",
      "CityValue": "Katkar"
    },
    {
      "id": 4061,
      "CityName": "Gurh",
      "CityValue": "Gurh"
    },
    {
      "id": 4062,
      "CityName": "Runji-Gautampura",
      "CityValue": "Runji-Gautampura"
    },
    {
      "id": 4063,
      "CityName": "Wadakkanchery",
      "CityValue": "Wadakkanchery"
    },
    {
      "id": 4064,
      "CityName": "Rayya",
      "CityValue": "Rayya"
    },
    {
      "id": 4065,
      "CityName": "Namsai",
      "CityValue": "Namsai"
    },
    {
      "id": 4066,
      "CityName": "Kasdol",
      "CityValue": "Kasdol"
    },
    {
      "id": 4067,
      "CityName": "Barrackpur Cantonment",
      "CityValue": "Barrackpur Cantonment"
    },
    {
      "id": 4068,
      "CityName": "Raja Sansi",
      "CityValue": "Raja Sansi"
    },
    {
      "id": 4069,
      "CityName": "Mehnagar",
      "CityValue": "Mehnagar"
    },
    {
      "id": 4070,
      "CityName": "Barhi",
      "CityValue": "Barhi"
    },
    {
      "id": 4071,
      "CityName": "Thikkanamcode",
      "CityValue": "Thikkanamcode"
    },
    {
      "id": 4072,
      "CityName": "Krishnasamudram",
      "CityValue": "Krishnasamudram"
    },
    {
      "id": 4073,
      "CityName": "Mudukulathur",
      "CityValue": "Mudukulathur"
    },
    {
      "id": 4074,
      "CityName": "Bari Brahamana",
      "CityValue": "Bari Brahamana"
    },
    {
      "id": 4075,
      "CityName": "Waghapur",
      "CityValue": "Waghapur"
    },
    {
      "id": 4076,
      "CityName": "Thekkumkara",
      "CityValue": "Thekkumkara"
    },
    {
      "id": 4077,
      "CityName": "Ahwa",
      "CityValue": "Ahwa"
    },
    {
      "id": 4078,
      "CityName": "Sirathu",
      "CityValue": "Sirathu"
    },
    {
      "id": 4079,
      "CityName": "Nurmahal",
      "CityValue": "Nurmahal"
    },
    {
      "id": 4080,
      "CityName": "Aheri",
      "CityValue": "Aheri"
    },
    {
      "id": 4081,
      "CityName": "Antarjal",
      "CityValue": "Antarjal"
    },
    {
      "id": 4082,
      "CityName": "Kamuthi",
      "CityValue": "Kamuthi"
    },
    {
      "id": 4083,
      "CityName": "Sakari",
      "CityValue": "Sakari"
    },
    {
      "id": 4084,
      "CityName": "Haripur Kalan",
      "CityValue": "Haripur Kalan"
    },
    {
      "id": 4085,
      "CityName": "Lanja",
      "CityValue": "Lanja"
    },
    {
      "id": 4086,
      "CityName": "Khatima",
      "CityValue": "Khatima"
    },
    {
      "id": 4087,
      "CityName": "Paintepur",
      "CityValue": "Paintepur"
    },
    {
      "id": 4088,
      "CityName": "Patan",
      "CityValue": "Patan"
    },
    {
      "id": 4089,
      "CityName": "Thottiyam",
      "CityValue": "Thottiyam"
    },
    {
      "id": 4090,
      "CityName": "Badarwas",
      "CityValue": "Badarwas"
    },
    {
      "id": 4091,
      "CityName": "Pappinivattom",
      "CityValue": "Pappinivattom"
    },
    {
      "id": 4092,
      "CityName": "Kankipadu",
      "CityValue": "Kankipadu"
    },
    {
      "id": 4093,
      "CityName": "Hussainpur",
      "CityValue": "Hussainpur"
    },
    {
      "id": 4094,
      "CityName": "Alamcode",
      "CityValue": "Alamcode"
    },
    {
      "id": 4095,
      "CityName": "Neb Sarai",
      "CityValue": "Neb Sarai"
    },
    {
      "id": 4096,
      "CityName": "Keezhattingal",
      "CityValue": "Keezhattingal"
    },
    {
      "id": 4097,
      "CityName": "Dommasandra",
      "CityValue": "Dommasandra"
    },
    {
      "id": 4098,
      "CityName": "Loharu",
      "CityValue": "Loharu"
    },
    {
      "id": 4099,
      "CityName": "Koothappar",
      "CityValue": "Koothappar"
    },
    {
      "id": 4100,
      "CityName": "Koodali",
      "CityValue": "Koodali"
    },
    {
      "id": 4101,
      "CityName": "Karamuck",
      "CityValue": "Karamuck"
    },
    {
      "id": 4102,
      "CityName": "Chowwara",
      "CityValue": "Chowwara"
    },
    {
      "id": 4103,
      "CityName": "Chaumuhan",
      "CityValue": "Chaumuhan"
    },
    {
      "id": 4104,
      "CityName": "Salon",
      "CityValue": "Salon"
    },
    {
      "id": 4105,
      "CityName": "Majitha",
      "CityValue": "Majitha"
    },
    {
      "id": 4106,
      "CityName": "Hajan",
      "CityValue": "Hajan"
    },
    {
      "id": 4107,
      "CityName": "Shahkot",
      "CityValue": "Shahkot"
    },
    {
      "id": 4108,
      "CityName": "Kaveripattinam",
      "CityValue": "Kaveripattinam"
    },
    {
      "id": 4109,
      "CityName": "Patiyali",
      "CityValue": "Patiyali"
    },
    {
      "id": 4110,
      "CityName": "Chennimalai",
      "CityValue": "Chennimalai"
    },
    {
      "id": 4111,
      "CityName": "Sarangarh",
      "CityValue": "Sarangarh"
    },
    {
      "id": 4112,
      "CityName": "Gamdi",
      "CityValue": "Gamdi"
    },
    {
      "id": 4113,
      "CityName": "Salarpur Khadar",
      "CityValue": "Salarpur Khadar"
    },
    {
      "id": 4114,
      "CityName": "Soyatkalan",
      "CityValue": "Soyatkalan"
    },
    {
      "id": 4115,
      "CityName": "Raghudebbati",
      "CityValue": "Raghudebbati"
    },
    {
      "id": 4116,
      "CityName": "Rajim",
      "CityValue": "Rajim"
    },
    {
      "id": 4117,
      "CityName": "Thirupuvanam",
      "CityValue": "Thirupuvanam"
    },
    {
      "id": 4118,
      "CityName": "Jahanabad",
      "CityValue": "Jahanabad"
    },
    {
      "id": 4119,
      "CityName": "Thengampudur",
      "CityValue": "Thengampudur"
    },
    {
      "id": 4120,
      "CityName": "Muragachha",
      "CityValue": "Muragachha"
    },
    {
      "id": 4121,
      "CityName": "Bikapur",
      "CityValue": "Bikapur"
    },
    {
      "id": 4122,
      "CityName": "Bansda",
      "CityValue": "Bansda"
    },
    {
      "id": 4123,
      "CityName": "Un",
      "CityValue": "Un"
    },
    {
      "id": 4124,
      "CityName": "Paschimbhatjangla",
      "CityValue": "Paschimbhatjangla"
    },
    {
      "id": 4125,
      "CityName": "Kureekkad",
      "CityValue": "Kureekkad"
    },
    {
      "id": 4126,
      "CityName": "Birsinghpur",
      "CityValue": "Birsinghpur"
    },
    {
      "id": 4127,
      "CityName": "Mohanur",
      "CityValue": "Mohanur"
    },
    {
      "id": 4128,
      "CityName": "Ramnagar",
      "CityValue": "Ramnagar"
    },
    {
      "id": 4129,
      "CityName": "Ashokapuram",
      "CityValue": "Ashokapuram"
    },
    {
      "id": 4130,
      "CityName": "Srirampura",
      "CityValue": "Srirampura"
    },
    {
      "id": 4131,
      "CityName": "Yari Pora",
      "CityValue": "Yari Pora"
    },
    {
      "id": 4132,
      "CityName": "Barjora",
      "CityValue": "Barjora"
    },
    {
      "id": 4133,
      "CityName": "Keezhariyur",
      "CityValue": "Keezhariyur"
    },
    {
      "id": 4134,
      "CityName": "Kovur",
      "CityValue": "Kovur"
    },
    {
      "id": 4135,
      "CityName": "Mukkudal",
      "CityValue": "Mukkudal"
    },
    {
      "id": 4136,
      "CityName": "Pandhana",
      "CityValue": "Pandhana"
    },
    {
      "id": 4137,
      "CityName": "Ledwa Mahua",
      "CityValue": "Ledwa Mahua"
    },
    {
      "id": 4138,
      "CityName": "Unhel",
      "CityValue": "Unhel"
    },
    {
      "id": 4139,
      "CityName": "Garoth",
      "CityValue": "Garoth"
    },
    {
      "id": 4140,
      "CityName": "Hindalgi",
      "CityValue": "Hindalgi"
    },
    {
      "id": 4141,
      "CityName": "Risiya Bazar",
      "CityValue": "Risiya Bazar"
    },
    {
      "id": 4142,
      "CityName": "Seraikela",
      "CityValue": "Seraikela"
    },
    {
      "id": 4143,
      "CityName": "Melagaram",
      "CityValue": "Melagaram"
    },
    {
      "id": 4144,
      "CityName": "Puthur",
      "CityValue": "Puthur"
    },
    {
      "id": 4145,
      "CityName": "Chevvoor",
      "CityValue": "Chevvoor"
    },
    {
      "id": 4146,
      "CityName": "Baberu",
      "CityValue": "Baberu"
    },
    {
      "id": 4147,
      "CityName": "Huliyar",
      "CityValue": "Huliyar"
    },
    {
      "id": 4148,
      "CityName": "Haripad",
      "CityValue": "Haripad"
    },
    {
      "id": 4149,
      "CityName": "Thiruvidaimarudur",
      "CityValue": "Thiruvidaimarudur"
    },
    {
      "id": 4150,
      "CityName": "Kharkhoda",
      "CityValue": "Kharkhoda"
    },
    {
      "id": 4151,
      "CityName": "Bori",
      "CityValue": "Bori"
    },
    {
      "id": 4152,
      "CityName": "Kodavasal",
      "CityValue": "Kodavasal"
    },
    {
      "id": 4153,
      "CityName": "Kothavalasa",
      "CityValue": "Kothavalasa"
    },
    {
      "id": 4154,
      "CityName": "Kunjathur",
      "CityValue": "Kunjathur"
    },
    {
      "id": 4155,
      "CityName": "Kurthi Jafarpur",
      "CityValue": "Kurthi Jafarpur"
    },
    {
      "id": 4156,
      "CityName": "Amarwara",
      "CityValue": "Amarwara"
    },
    {
      "id": 4157,
      "CityName": "Amod",
      "CityValue": "Amod"
    },
    {
      "id": 4158,
      "CityName": "Arasiramani",
      "CityValue": "Arasiramani"
    },
    {
      "id": 4159,
      "CityName": "Raghunathpur",
      "CityValue": "Raghunathpur"
    },
    {
      "id": 4160,
      "CityName": "Bhalpara",
      "CityValue": "Bhalpara"
    },
    {
      "id": 4161,
      "CityName": "Odlabari",
      "CityValue": "Odlabari"
    },
    {
      "id": 4162,
      "CityName": "Kedla",
      "CityValue": "Kedla"
    },
    {
      "id": 4163,
      "CityName": "Amalhara",
      "CityValue": "Amalhara"
    },
    {
      "id": 4164,
      "CityName": "Rajnagar",
      "CityValue": "Rajnagar"
    },
    {
      "id": 4165,
      "CityName": "Kandharpur",
      "CityValue": "Kandharpur"
    },
    {
      "id": 4166,
      "CityName": "Avinissery",
      "CityValue": "Avinissery"
    },
    {
      "id": 4167,
      "CityName": "Uthayendram",
      "CityValue": "Uthayendram"
    },
    {
      "id": 4168,
      "CityName": "Bhabnan Bazar",
      "CityValue": "Bhabnan Bazar"
    },
    {
      "id": 4169,
      "CityName": "Bantwa",
      "CityValue": "Bantwa"
    },
    {
      "id": 4170,
      "CityName": "Penha-de-Franca",
      "CityValue": "Penha-de-Franca"
    },
    {
      "id": 4171,
      "CityName": "Nagapur",
      "CityValue": "Nagapur"
    },
    {
      "id": 4172,
      "CityName": "Udalguri",
      "CityValue": "Udalguri"
    },
    {
      "id": 4173,
      "CityName": "Vennanthur",
      "CityValue": "Vennanthur"
    },
    {
      "id": 4174,
      "CityName": "Chopan",
      "CityValue": "Chopan"
    },
    {
      "id": 4175,
      "CityName": "Bahir Sarbamangala",
      "CityValue": "Bahir Sarbamangala"
    },
    {
      "id": 4176,
      "CityName": "Srimushnam",
      "CityValue": "Srimushnam"
    },
    {
      "id": 4177,
      "CityName": "Calapor",
      "CityValue": "Calapor"
    },
    {
      "id": 4178,
      "CityName": "Mathapari",
      "CityValue": "Mathapari"
    },
    {
      "id": 4179,
      "CityName": "Pendra",
      "CityValue": "Pendra"
    },
    {
      "id": 4180,
      "CityName": "Mandir Hasaud",
      "CityValue": "Mandir Hasaud"
    },
    {
      "id": 4181,
      "CityName": "Telo",
      "CityValue": "Telo"
    },
    {
      "id": 4182,
      "CityName": "Bamangram",
      "CityValue": "Bamangram"
    },
    {
      "id": 4183,
      "CityName": "Nirsa",
      "CityValue": "Nirsa"
    },
    {
      "id": 4184,
      "CityName": "Sukhpar",
      "CityValue": "Sukhpar"
    },
    {
      "id": 4185,
      "CityName": "Bandh Dih",
      "CityValue": "Bandh Dih"
    },
    {
      "id": 4186,
      "CityName": "Harduaganj",
      "CityValue": "Harduaganj"
    },
    {
      "id": 4187,
      "CityName": "Manantheri",
      "CityValue": "Manantheri"
    },
    {
      "id": 4188,
      "CityName": "Amanganj",
      "CityValue": "Amanganj"
    },
    {
      "id": 4189,
      "CityName": "Dalurband",
      "CityValue": "Dalurband"
    },
    {
      "id": 4190,
      "CityName": "Shuvararah",
      "CityValue": "Shuvararah"
    },
    {
      "id": 4191,
      "CityName": "Radhakishorenagar",
      "CityValue": "Radhakishorenagar"
    },
    {
      "id": 4192,
      "CityName": "Shrivardhan",
      "CityValue": "Shrivardhan"
    },
    {
      "id": 4193,
      "CityName": "Balasamudram",
      "CityValue": "Balasamudram"
    },
    {
      "id": 4194,
      "CityName": "Kurud",
      "CityValue": "Kurud"
    },
    {
      "id": 4195,
      "CityName": "Sikandra",
      "CityValue": "Sikandra"
    },
    {
      "id": 4196,
      "CityName": "Karikkad",
      "CityValue": "Karikkad"
    },
    {
      "id": 4197,
      "CityName": "Perole",
      "CityValue": "Perole"
    },
    {
      "id": 4198,
      "CityName": "Dostpur",
      "CityValue": "Dostpur"
    },
    {
      "id": 4199,
      "CityName": "Patan",
      "CityValue": "Patan"
    },
    {
      "id": 4200,
      "CityName": "Ramkola",
      "CityValue": "Ramkola"
    },
    {
      "id": 4201,
      "CityName": "Nainana Jat",
      "CityValue": "Nainana Jat"
    },
    {
      "id": 4202,
      "CityName": "Mahua Kheraganj",
      "CityValue": "Mahua Kheraganj"
    },
    {
      "id": 4203,
      "CityName": "Kalugumalai",
      "CityValue": "Kalugumalai"
    },
    {
      "id": 4204,
      "CityName": "Rajnagar",
      "CityValue": "Rajnagar"
    },
    {
      "id": 4205,
      "CityName": "Sultan Pur",
      "CityValue": "Sultan Pur"
    },
    {
      "id": 4206,
      "CityName": "Komaralingam",
      "CityValue": "Komaralingam"
    },
    {
      "id": 4207,
      "CityName": "Sakkarakottai",
      "CityValue": "Sakkarakottai"
    },
    {
      "id": 4208,
      "CityName": "Cheepurupalle",
      "CityValue": "Cheepurupalle"
    },
    {
      "id": 4209,
      "CityName": "Kumarapuram",
      "CityValue": "Kumarapuram"
    },
    {
      "id": 4210,
      "CityName": "Vikasnagar",
      "CityValue": "Vikasnagar"
    },
    {
      "id": 4211,
      "CityName": "Sisauli",
      "CityValue": "Sisauli"
    },
    {
      "id": 4212,
      "CityName": "Saligram",
      "CityValue": "Saligram"
    },
    {
      "id": 4213,
      "CityName": "Badod",
      "CityValue": "Badod"
    },
    {
      "id": 4214,
      "CityName": "Charar-i-Sharief",
      "CityValue": "Charar-i-Sharief"
    },
    {
      "id": 4215,
      "CityName": "Sidsar",
      "CityValue": "Sidsar"
    },
    {
      "id": 4216,
      "CityName": "Dharamjaigarh",
      "CityValue": "Dharamjaigarh"
    },
    {
      "id": 4217,
      "CityName": "Dhasa Vishi",
      "CityValue": "Dhasa Vishi"
    },
    {
      "id": 4218,
      "CityName": "Saij",
      "CityValue": "Saij"
    },
    {
      "id": 4219,
      "CityName": "Ramgarh",
      "CityValue": "Ramgarh"
    },
    {
      "id": 4220,
      "CityName": "Iluppur",
      "CityValue": "Iluppur"
    },
    {
      "id": 4221,
      "CityName": "Radaur",
      "CityValue": "Radaur"
    },
    {
      "id": 4222,
      "CityName": "Nandelav",
      "CityValue": "Nandelav"
    },
    {
      "id": 4223,
      "CityName": "Surada",
      "CityValue": "Surada"
    },
    {
      "id": 4224,
      "CityName": "Ichchhapor",
      "CityValue": "Ichchhapor"
    },
    {
      "id": 4225,
      "CityName": "Chemnad",
      "CityValue": "Chemnad"
    },
    {
      "id": 4226,
      "CityName": "Bachra",
      "CityValue": "Bachra"
    },
    {
      "id": 4227,
      "CityName": "Laxmidevipalle",
      "CityValue": "Laxmidevipalle"
    },
    {
      "id": 4228,
      "CityName": "Dumriguri",
      "CityValue": "Dumriguri"
    },
    {
      "id": 4229,
      "CityName": "Kothapet",
      "CityValue": "Kothapet"
    },
    {
      "id": 4230,
      "CityName": "Ganapathipuram",
      "CityValue": "Ganapathipuram"
    },
    {
      "id": 4231,
      "CityName": "Kundalwadi",
      "CityValue": "Kundalwadi"
    },
    {
      "id": 4232,
      "CityName": "Cart Road",
      "CityValue": "Cart Road"
    },
    {
      "id": 4233,
      "CityName": "Brahmakulam",
      "CityValue": "Brahmakulam"
    },
    {
      "id": 4234,
      "CityName": "Haldibari",
      "CityValue": "Haldibari"
    },
    {
      "id": 4235,
      "CityName": "Sanquelim",
      "CityValue": "Sanquelim"
    },
    {
      "id": 4236,
      "CityName": "Dakshin Jhapardaha",
      "CityValue": "Dakshin Jhapardaha"
    },
    {
      "id": 4237,
      "CityName": "Talbehat",
      "CityValue": "Talbehat"
    },
    {
      "id": 4238,
      "CityName": "Ammapettai",
      "CityValue": "Ammapettai"
    },
    {
      "id": 4239,
      "CityName": "Jaykrishnapur",
      "CityValue": "Jaykrishnapur"
    },
    {
      "id": 4240,
      "CityName": "Ravapara",
      "CityValue": "Ravapara"
    },
    {
      "id": 4241,
      "CityName": "Anuppankulam",
      "CityValue": "Anuppankulam"
    },
    {
      "id": 4242,
      "CityName": "Beejoliya Kalan",
      "CityValue": "Beejoliya Kalan"
    },
    {
      "id": 4243,
      "CityName": "Noapara",
      "CityValue": "Noapara"
    },
    {
      "id": 4244,
      "CityName": "Rampur Baghelan",
      "CityValue": "Rampur Baghelan"
    },
    {
      "id": 4245,
      "CityName": "Obaree",
      "CityValue": "Obaree"
    },
    {
      "id": 4246,
      "CityName": "Nalwadi",
      "CityValue": "Nalwadi"
    },
    {
      "id": 4247,
      "CityName": "Kishtwar",
      "CityValue": "Kishtwar"
    },
    {
      "id": 4248,
      "CityName": "Sanrha",
      "CityValue": "Sanrha"
    },
    {
      "id": 4249,
      "CityName": "Sabour",
      "CityValue": "Sabour"
    },
    {
      "id": 4250,
      "CityName": "Baloda",
      "CityValue": "Baloda"
    },
    {
      "id": 4251,
      "CityName": "Usawan",
      "CityValue": "Usawan"
    },
    {
      "id": 4252,
      "CityName": "Gothra",
      "CityValue": "Gothra"
    },
    {
      "id": 4253,
      "CityName": "Anup Nagar",
      "CityValue": "Anup Nagar"
    },
    {
      "id": 4254,
      "CityName": "Gola Bazar",
      "CityValue": "Gola Bazar"
    },
    {
      "id": 4255,
      "CityName": "Hatwas",
      "CityValue": "Hatwas"
    },
    {
      "id": 4256,
      "CityName": "Hijuli",
      "CityValue": "Hijuli"
    },
    {
      "id": 4257,
      "CityName": "Mursan",
      "CityValue": "Mursan"
    },
    {
      "id": 4258,
      "CityName": "Amethi",
      "CityValue": "Amethi"
    },
    {
      "id": 4259,
      "CityName": "Phek",
      "CityValue": "Phek"
    },
    {
      "id": 4260,
      "CityName": "Peth Umri",
      "CityValue": "Peth Umri"
    },
    {
      "id": 4261,
      "CityName": "Kalambur",
      "CityValue": "Kalambur"
    },
    {
      "id": 4262,
      "CityName": "Chingoli",
      "CityValue": "Chingoli"
    },
    {
      "id": 4263,
      "CityName": "Kodala",
      "CityValue": "Kodala"
    },
    {
      "id": 4264,
      "CityName": "Manegaon",
      "CityValue": "Manegaon"
    },
    {
      "id": 4265,
      "CityName": "Nizamabad",
      "CityValue": "Nizamabad"
    },
    {
      "id": 4266,
      "CityName": "Ghantapada",
      "CityValue": "Ghantapada"
    },
    {
      "id": 4267,
      "CityName": "Ghitorni",
      "CityValue": "Ghitorni"
    },
    {
      "id": 4268,
      "CityName": "Mangawan",
      "CityValue": "Mangawan"
    },
    {
      "id": 4269,
      "CityName": "Sir Gobardhan",
      "CityValue": "Sir Gobardhan"
    },
    {
      "id": 4270,
      "CityName": "Paschim Jitpur",
      "CityValue": "Paschim Jitpur"
    },
    {
      "id": 4271,
      "CityName": "Bad",
      "CityValue": "Bad"
    },
    {
      "id": 4272,
      "CityName": "Kelamangalam",
      "CityValue": "Kelamangalam"
    },
    {
      "id": 4273,
      "CityName": "Tharike",
      "CityValue": "Tharike"
    },
    {
      "id": 4274,
      "CityName": "Fatehgarh",
      "CityValue": "Fatehgarh"
    },
    {
      "id": 4275,
      "CityName": "Annalagraharam",
      "CityValue": "Annalagraharam"
    },
    {
      "id": 4276,
      "CityName": "Marathakkara",
      "CityValue": "Marathakkara"
    },
    {
      "id": 4277,
      "CityName": "Velur",
      "CityValue": "Velur"
    },
    {
      "id": 4278,
      "CityName": "Lalhapur",
      "CityValue": "Lalhapur"
    },
    {
      "id": 4279,
      "CityName": "Lanji",
      "CityValue": "Lanji"
    },
    {
      "id": 4280,
      "CityName": "Kanina",
      "CityValue": "Kanina"
    },
    {
      "id": 4281,
      "CityName": "Karukkalvadi",
      "CityValue": "Karukkalvadi"
    },
    {
      "id": 4282,
      "CityName": "Bhagipur",
      "CityValue": "Bhagipur"
    },
    {
      "id": 4283,
      "CityName": "Kumaragiri",
      "CityValue": "Kumaragiri"
    },
    {
      "id": 4284,
      "CityName": "Ghuwara",
      "CityValue": "Ghuwara"
    },
    {
      "id": 4285,
      "CityName": "Bankner",
      "CityValue": "Bankner"
    },
    {
      "id": 4286,
      "CityName": "Matiari",
      "CityValue": "Matiari"
    },
    {
      "id": 4287,
      "CityName": "Lakshminarayanapuram",
      "CityValue": "Lakshminarayanapuram"
    },
    {
      "id": 4288,
      "CityName": "Khapa",
      "CityValue": "Khapa"
    },
    {
      "id": 4289,
      "CityName": "Sengamalanachiarpatti",
      "CityValue": "Sengamalanachiarpatti"
    },
    {
      "id": 4290,
      "CityName": "Kandwa",
      "CityValue": "Kandwa"
    },
    {
      "id": 4291,
      "CityName": "Madhupur",
      "CityValue": "Madhupur"
    },
    {
      "id": 4292,
      "CityName": "Shahpura",
      "CityValue": "Shahpura"
    },
    {
      "id": 4293,
      "CityName": "Yellareddy",
      "CityValue": "Yellareddy"
    },
    {
      "id": 4294,
      "CityName": "Iringaprom",
      "CityValue": "Iringaprom"
    },
    {
      "id": 4295,
      "CityName": "Sitamau",
      "CityValue": "Sitamau"
    },
    {
      "id": 4296,
      "CityName": "Pipiganj",
      "CityValue": "Pipiganj"
    },
    {
      "id": 4297,
      "CityName": "Doghat",
      "CityValue": "Doghat"
    },
    {
      "id": 4298,
      "CityName": "Dankaur",
      "CityValue": "Dankaur"
    },
    {
      "id": 4299,
      "CityName": "Nari",
      "CityValue": "Nari"
    },
    {
      "id": 4300,
      "CityName": "Ismailabad",
      "CityValue": "Ismailabad"
    },
    {
      "id": 4301,
      "CityName": "Sibdanga Badarpur",
      "CityValue": "Sibdanga Badarpur"
    },
    {
      "id": 4302,
      "CityName": "Shahpur",
      "CityValue": "Shahpur"
    },
    {
      "id": 4303,
      "CityName": "Adari",
      "CityValue": "Adari"
    },
    {
      "id": 4304,
      "CityName": "Jugial",
      "CityValue": "Jugial"
    },
    {
      "id": 4305,
      "CityName": "Budhgaon",
      "CityValue": "Budhgaon"
    },
    {
      "id": 4306,
      "CityName": "Rani",
      "CityValue": "Rani"
    },
    {
      "id": 4307,
      "CityName": "Turuvekere",
      "CityValue": "Turuvekere"
    },
    {
      "id": 4308,
      "CityName": "Kaniyur",
      "CityValue": "Kaniyur"
    },
    {
      "id": 4309,
      "CityName": "Chhawala",
      "CityValue": "Chhawala"
    },
    {
      "id": 4310,
      "CityName": "Agarwal Mandi",
      "CityValue": "Agarwal Mandi"
    },
    {
      "id": 4311,
      "CityName": "Pallikkara",
      "CityValue": "Pallikkara"
    },
    {
      "id": 4312,
      "CityName": "Rerma",
      "CityValue": "Rerma"
    },
    {
      "id": 4313,
      "CityName": "Vilavur",
      "CityValue": "Vilavur"
    },
    {
      "id": 4314,
      "CityName": "Nagari",
      "CityValue": "Nagari"
    },
    {
      "id": 4315,
      "CityName": "Panangad",
      "CityValue": "Panangad"
    },
    {
      "id": 4316,
      "CityName": "Sarjapura",
      "CityValue": "Sarjapura"
    },
    {
      "id": 4317,
      "CityName": "Khem Karan",
      "CityValue": "Khem Karan"
    },
    {
      "id": 4318,
      "CityName": "Suwasara",
      "CityValue": "Suwasara"
    },
    {
      "id": 4319,
      "CityName": "Gobindapur",
      "CityValue": "Gobindapur"
    },
    {
      "id": 4320,
      "CityName": "Uklana Mandi",
      "CityValue": "Uklana Mandi"
    },
    {
      "id": 4321,
      "CityName": "Milki",
      "CityValue": "Milki"
    },
    {
      "id": 4322,
      "CityName": "Balichak",
      "CityValue": "Balichak"
    },
    {
      "id": 4323,
      "CityName": "Malsisar",
      "CityValue": "Malsisar"
    },
    {
      "id": 4324,
      "CityName": "Soorapattu",
      "CityValue": "Soorapattu"
    },
    {
      "id": 4325,
      "CityName": "Doboka",
      "CityValue": "Doboka"
    },
    {
      "id": 4326,
      "CityName": "Meghnagar",
      "CityValue": "Meghnagar"
    },
    {
      "id": 4327,
      "CityName": "Kurumpilavu",
      "CityValue": "Kurumpilavu"
    },
    {
      "id": 4328,
      "CityName": "Ghanpur",
      "CityValue": "Ghanpur"
    },
    {
      "id": 4329,
      "CityName": "Madanpur",
      "CityValue": "Madanpur"
    },
    {
      "id": 4330,
      "CityName": "Reliance Complex",
      "CityValue": "Reliance Complex"
    },
    {
      "id": 4331,
      "CityName": "Basugaon",
      "CityValue": "Basugaon"
    },
    {
      "id": 4332,
      "CityName": "Valayam",
      "CityValue": "Valayam"
    },
    {
      "id": 4333,
      "CityName": "Dakshin Raypur",
      "CityValue": "Dakshin Raypur"
    },
    {
      "id": 4334,
      "CityName": "Madhogarh",
      "CityValue": "Madhogarh"
    },
    {
      "id": 4335,
      "CityName": "New Bongaigaon Rly. Col.",
      "CityValue": "New Bongaigaon Rly. Col."
    },
    {
      "id": 4336,
      "CityName": "Sambra",
      "CityValue": "Sambra"
    },
    {
      "id": 4337,
      "CityName": "Kakati",
      "CityValue": "Kakati"
    },
    {
      "id": 4338,
      "CityName": "Kalli Pashchim",
      "CityValue": "Kalli Pashchim"
    },
    {
      "id": 4339,
      "CityName": "Sakoli",
      "CityValue": "Sakoli"
    },
    {
      "id": 4340,
      "CityName": "Irikkur",
      "CityValue": "Irikkur"
    },
    {
      "id": 4341,
      "CityName": "Sentharapatti",
      "CityValue": "Sentharapatti"
    },
    {
      "id": 4342,
      "CityName": "Diwakari",
      "CityValue": "Diwakari"
    },
    {
      "id": 4343,
      "CityName": "Patihal",
      "CityValue": "Patihal"
    },
    {
      "id": 4344,
      "CityName": "Kurara",
      "CityValue": "Kurara"
    },
    {
      "id": 4345,
      "CityName": "Digapahandi",
      "CityValue": "Digapahandi"
    },
    {
      "id": 4346,
      "CityName": "Dera Colliery Township",
      "CityValue": "Dera Colliery Township"
    },
    {
      "id": 4347,
      "CityName": "Sithayankottai",
      "CityValue": "Sithayankottai"
    },
    {
      "id": 4348,
      "CityName": "Tikri",
      "CityValue": "Tikri"
    },
    {
      "id": 4349,
      "CityName": "Perur",
      "CityValue": "Perur"
    },
    {
      "id": 4350,
      "CityName": "Sivanthipuram",
      "CityValue": "Sivanthipuram"
    },
    {
      "id": 4351,
      "CityName": "Attayampatti",
      "CityValue": "Attayampatti"
    },
    {
      "id": 4352,
      "CityName": "Kishangarh",
      "CityValue": "Kishangarh"
    },
    {
      "id": 4353,
      "CityName": "Kariamangalam",
      "CityValue": "Kariamangalam"
    },
    {
      "id": 4354,
      "CityName": "Iltifatganj Bazar",
      "CityValue": "Iltifatganj Bazar"
    },
    {
      "id": 4355,
      "CityName": "Socorro",
      "CityValue": "Socorro"
    },
    {
      "id": 4356,
      "CityName": "Kampur Town",
      "CityValue": "Kampur Town"
    },
    {
      "id": 4357,
      "CityName": "Trikkur",
      "CityValue": "Trikkur"
    },
    {
      "id": 4358,
      "CityName": "Majholi",
      "CityValue": "Majholi"
    },
    {
      "id": 4359,
      "CityName": "Vijayapuri North",
      "CityValue": "Vijayapuri North"
    },
    {
      "id": 4360,
      "CityName": "Badarpur",
      "CityValue": "Badarpur"
    },
    {
      "id": 4361,
      "CityName": "Madayikonam",
      "CityValue": "Madayikonam"
    },
    {
      "id": 4362,
      "CityName": "Kilpennathur",
      "CityValue": "Kilpennathur"
    },
    {
      "id": 4363,
      "CityName": "Baqiabad",
      "CityValue": "Baqiabad"
    },
    {
      "id": 4364,
      "CityName": "Chandla",
      "CityValue": "Chandla"
    },
    {
      "id": 4365,
      "CityName": "Puthur Agraharam",
      "CityValue": "Puthur Agraharam"
    },
    {
      "id": 4366,
      "CityName": "Singarva",
      "CityValue": "Singarva"
    },
    {
      "id": 4367,
      "CityName": "Ningthoukhong",
      "CityValue": "Ningthoukhong"
    },
    {
      "id": 4368,
      "CityName": "Bhalwal",
      "CityValue": "Bhalwal"
    },
    {
      "id": 4369,
      "CityName": "Khanpur",
      "CityValue": "Khanpur"
    },
    {
      "id": 4370,
      "CityName": "Pazhanji",
      "CityValue": "Pazhanji"
    },
    {
      "id": 4371,
      "CityName": "Chinnasekkadu",
      "CityValue": "Chinnasekkadu"
    },
    {
      "id": 4372,
      "CityName": "Khalisani",
      "CityValue": "Khalisani"
    },
    {
      "id": 4373,
      "CityName": "Koyra",
      "CityValue": "Koyra"
    },
    {
      "id": 4374,
      "CityName": "Sakkimangalam",
      "CityValue": "Sakkimangalam"
    },
    {
      "id": 4375,
      "CityName": "Meppayyur",
      "CityValue": "Meppayyur"
    },
    {
      "id": 4376,
      "CityName": "Rampura",
      "CityValue": "Rampura"
    },
    {
      "id": 4377,
      "CityName": "Tirthahalli",
      "CityValue": "Tirthahalli"
    },
    {
      "id": 4378,
      "CityName": "Bhagdawada",
      "CityValue": "Bhagdawada"
    },
    {
      "id": 4379,
      "CityName": "Mundi",
      "CityValue": "Mundi"
    },
    {
      "id": 4380,
      "CityName": "Muthur",
      "CityValue": "Muthur"
    },
    {
      "id": 4381,
      "CityName": "Arail Uparhar",
      "CityValue": "Arail Uparhar"
    },
    {
      "id": 4382,
      "CityName": "Chhora",
      "CityValue": "Chhora"
    },
    {
      "id": 4383,
      "CityName": "Uthukkottai",
      "CityValue": "Uthukkottai"
    },
    {
      "id": 4384,
      "CityName": "Siddapur",
      "CityValue": "Siddapur"
    },
    {
      "id": 4385,
      "CityName": "Amethi",
      "CityValue": "Amethi"
    },
    {
      "id": 4386,
      "CityName": "Sathankulam",
      "CityValue": "Sathankulam"
    },
    {
      "id": 4387,
      "CityName": "Ballepalle",
      "CityValue": "Ballepalle"
    },
    {
      "id": 4388,
      "CityName": "Odaiyakulam",
      "CityValue": "Odaiyakulam"
    },
    {
      "id": 4389,
      "CityName": "Chapra",
      "CityValue": "Chapra"
    },
    {
      "id": 4390,
      "CityName": "Edayanchavadi",
      "CityValue": "Edayanchavadi"
    },
    {
      "id": 4391,
      "CityName": "Katghar Lalganj",
      "CityValue": "Katghar Lalganj"
    },
    {
      "id": 4392,
      "CityName": "Hathras Dehat",
      "CityValue": "Hathras Dehat"
    },
    {
      "id": 4393,
      "CityName": "Ullur",
      "CityValue": "Ullur"
    },
    {
      "id": 4394,
      "CityName": "Handiaya",
      "CityValue": "Handiaya"
    },
    {
      "id": 4395,
      "CityName": "Purba Bishnupur",
      "CityValue": "Purba Bishnupur"
    },
    {
      "id": 4396,
      "CityName": "Kesrisinghpur",
      "CityValue": "Kesrisinghpur"
    },
    {
      "id": 4397,
      "CityName": "Namrup",
      "CityValue": "Namrup"
    },
    {
      "id": 4398,
      "CityName": "Basudebpur",
      "CityValue": "Basudebpur"
    },
    {
      "id": 4399,
      "CityName": "Warthi",
      "CityValue": "Warthi"
    },
    {
      "id": 4400,
      "CityName": "Silampur",
      "CityValue": "Silampur"
    },
    {
      "id": 4401,
      "CityName": "Ranirbazar",
      "CityValue": "Ranirbazar"
    },
    {
      "id": 4402,
      "CityName": "Tehatta",
      "CityValue": "Tehatta"
    },
    {
      "id": 4403,
      "CityName": "Gadigachha",
      "CityValue": "Gadigachha"
    },
    {
      "id": 4404,
      "CityName": "Odaipatti",
      "CityValue": "Odaipatti"
    },
    {
      "id": 4405,
      "CityName": "Gandai",
      "CityValue": "Gandai"
    },
    {
      "id": 4406,
      "CityName": "Deara",
      "CityValue": "Deara"
    },
    {
      "id": 4407,
      "CityName": "Naiparapur",
      "CityValue": "Naiparapur"
    },
    {
      "id": 4408,
      "CityName": "Kot Ise Khan",
      "CityValue": "Kot Ise Khan"
    },
    {
      "id": 4409,
      "CityName": "Thurayur",
      "CityValue": "Thurayur"
    },
    {
      "id": 4410,
      "CityName": "Dundigal",
      "CityValue": "Dundigal"
    },
    {
      "id": 4411,
      "CityName": "Bholar Dabri",
      "CityValue": "Bholar Dabri"
    },
    {
      "id": 4412,
      "CityName": "Utekhol",
      "CityValue": "Utekhol"
    },
    {
      "id": 4413,
      "CityName": "Cheruthuruthi",
      "CityValue": "Cheruthuruthi"
    },
    {
      "id": 4414,
      "CityName": "Jagannathpur",
      "CityValue": "Jagannathpur"
    },
    {
      "id": 4415,
      "CityName": "Nai Bazar",
      "CityValue": "Nai Bazar"
    },
    {
      "id": 4416,
      "CityName": "Tati",
      "CityValue": "Tati"
    },
    {
      "id": 4417,
      "CityName": "Khalikote",
      "CityValue": "Khalikote"
    },
    {
      "id": 4418,
      "CityName": "Kolathur",
      "CityValue": "Kolathur"
    },
    {
      "id": 4419,
      "CityName": "Achampudur",
      "CityValue": "Achampudur"
    },
    {
      "id": 4420,
      "CityName": "Jiwangarh",
      "CityValue": "Jiwangarh"
    },
    {
      "id": 4421,
      "CityName": "Bishnupur",
      "CityValue": "Bishnupur"
    },
    {
      "id": 4422,
      "CityName": "Lasudiya Mori",
      "CityValue": "Lasudiya Mori"
    },
    {
      "id": 4423,
      "CityName": "Peramangalam",
      "CityValue": "Peramangalam"
    },
    {
      "id": 4424,
      "CityName": "Oel Dhakwa",
      "CityValue": "Oel Dhakwa"
    },
    {
      "id": 4425,
      "CityName": "Dignala",
      "CityValue": "Dignala"
    },
    {
      "id": 4426,
      "CityName": "Phuphkalan",
      "CityValue": "Phuphkalan"
    },
    {
      "id": 4427,
      "CityName": "Susuwahi",
      "CityValue": "Susuwahi"
    },
    {
      "id": 4428,
      "CityName": "Tendukheda",
      "CityValue": "Tendukheda"
    },
    {
      "id": 4429,
      "CityName": "Pudu",
      "CityValue": "Pudu"
    },
    {
      "id": 4430,
      "CityName": "Kannadiparamba",
      "CityValue": "Kannadiparamba"
    },
    {
      "id": 4431,
      "CityName": "Gerimari Chapori",
      "CityValue": "Gerimari Chapori"
    },
    {
      "id": 4432,
      "CityName": "Dhakuakhana",
      "CityValue": "Dhakuakhana"
    },
    {
      "id": 4433,
      "CityName": "Kareli",
      "CityValue": "Kareli"
    },
    {
      "id": 4434,
      "CityName": "Katai",
      "CityValue": "Katai"
    },
    {
      "id": 4435,
      "CityName": "Jhusi",
      "CityValue": "Jhusi"
    },
    {
      "id": 4436,
      "CityName": "Kalikapota",
      "CityValue": "Kalikapota"
    },
    {
      "id": 4437,
      "CityName": "Nullivilai",
      "CityValue": "Nullivilai"
    },
    {
      "id": 4438,
      "CityName": "Mehmood Pora",
      "CityValue": "Mehmood Pora"
    },
    {
      "id": 4439,
      "CityName": "Kozhukkallur",
      "CityValue": "Kozhukkallur"
    },
    {
      "id": 4440,
      "CityName": "Jandli",
      "CityValue": "Jandli"
    },
    {
      "id": 4441,
      "CityName": "Lidhora Khas",
      "CityValue": "Lidhora Khas"
    },
    {
      "id": 4442,
      "CityName": "Bhadarsa",
      "CityValue": "Bhadarsa"
    },
    {
      "id": 4443,
      "CityName": "Kondichettipatti",
      "CityValue": "Kondichettipatti"
    },
    {
      "id": 4444,
      "CityName": "Chamrail",
      "CityValue": "Chamrail"
    },
    {
      "id": 4445,
      "CityName": "Manglaya Sadak",
      "CityValue": "Manglaya Sadak"
    },
    {
      "id": 4446,
      "CityName": "Kerakat",
      "CityValue": "Kerakat"
    },
    {
      "id": 4447,
      "CityName": "Karuppur",
      "CityValue": "Karuppur"
    },
    {
      "id": 4448,
      "CityName": "Thingalnagar",
      "CityValue": "Thingalnagar"
    },
    {
      "id": 4449,
      "CityName": "Mauli Jagran",
      "CityValue": "Mauli Jagran"
    },
    {
      "id": 4450,
      "CityName": "Semaria",
      "CityValue": "Semaria"
    },
    {
      "id": 4451,
      "CityName": "Jagathala",
      "CityValue": "Jagathala"
    },
    {
      "id": 4452,
      "CityName": "Garhi - Malhera",
      "CityValue": "Garhi - Malhera"
    },
    {
      "id": 4453,
      "CityName": "Kanota",
      "CityValue": "Kanota"
    },
    {
      "id": 4454,
      "CityName": "Orumanayur",
      "CityValue": "Orumanayur"
    },
    {
      "id": 4455,
      "CityName": "Baggar",
      "CityValue": "Baggar"
    },
    {
      "id": 4456,
      "CityName": "Vanthali",
      "CityValue": "Vanthali"
    },
    {
      "id": 4457,
      "CityName": "Faridnagar",
      "CityValue": "Faridnagar"
    },
    {
      "id": 4458,
      "CityName": "Nagothane",
      "CityValue": "Nagothane"
    },
    {
      "id": 4459,
      "CityName": "Labbaikudikadu",
      "CityValue": "Labbaikudikadu"
    },
    {
      "id": 4460,
      "CityName": "Kharela",
      "CityValue": "Kharela"
    },
    {
      "id": 4461,
      "CityName": "Isagarh",
      "CityValue": "Isagarh"
    },
    {
      "id": 4462,
      "CityName": "Dornakal",
      "CityValue": "Dornakal"
    },
    {
      "id": 4463,
      "CityName": "Kumarghat",
      "CityValue": "Kumarghat"
    },
    {
      "id": 4464,
      "CityName": "Chityala",
      "CityValue": "Chityala"
    },
    {
      "id": 4465,
      "CityName": "Roorkee",
      "CityValue": "Roorkee"
    },
    {
      "id": 4466,
      "CityName": "Veluthur",
      "CityValue": "Veluthur"
    },
    {
      "id": 4467,
      "CityName": "Mahabaleshwar",
      "CityValue": "Mahabaleshwar"
    },
    {
      "id": 4468,
      "CityName": "Ratannagar",
      "CityValue": "Ratannagar"
    },
    {
      "id": 4469,
      "CityName": "Mailani",
      "CityValue": "Mailani"
    },
    {
      "id": 4470,
      "CityName": "Chinchani",
      "CityValue": "Chinchani"
    },
    {
      "id": 4471,
      "CityName": "Rahanal",
      "CityValue": "Rahanal"
    },
    {
      "id": 4472,
      "CityName": "Habibpur",
      "CityValue": "Habibpur"
    },
    {
      "id": 4473,
      "CityName": "Sorab",
      "CityValue": "Sorab"
    },
    {
      "id": 4474,
      "CityName": "Gokunda",
      "CityValue": "Gokunda"
    },
    {
      "id": 4475,
      "CityName": "Marandahalli",
      "CityValue": "Marandahalli"
    },
    {
      "id": 4476,
      "CityName": "Nababpur",
      "CityValue": "Nababpur"
    },
    {
      "id": 4477,
      "CityName": "Bilaua",
      "CityValue": "Bilaua"
    },
    {
      "id": 4478,
      "CityName": "Karivellur",
      "CityValue": "Karivellur"
    },
    {
      "id": 4479,
      "CityName": "Vellimalai",
      "CityValue": "Vellimalai"
    },
    {
      "id": 4480,
      "CityName": "S. Kannanur",
      "CityValue": "S. Kannanur"
    },
    {
      "id": 4481,
      "CityName": "Bilaspur",
      "CityValue": "Bilaspur"
    },
    {
      "id": 4482,
      "CityName": "Basantapur",
      "CityValue": "Basantapur"
    },
    {
      "id": 4483,
      "CityName": "Chaurai Khas",
      "CityValue": "Chaurai Khas"
    },
    {
      "id": 4484,
      "CityName": "Erumaipatti",
      "CityValue": "Erumaipatti"
    },
    {
      "id": 4485,
      "CityName": "Benipur",
      "CityValue": "Benipur"
    },
    {
      "id": 4486,
      "CityName": "Punnayurkulam",
      "CityValue": "Punnayurkulam"
    },
    {
      "id": 4487,
      "CityName": "Jhinkpani",
      "CityValue": "Jhinkpani"
    },
    {
      "id": 4488,
      "CityName": "Udala",
      "CityValue": "Udala"
    },
    {
      "id": 4489,
      "CityName": "Salempur Rajputan",
      "CityValue": "Salempur Rajputan"
    },
    {
      "id": 4490,
      "CityName": "Curtorim",
      "CityValue": "Curtorim"
    },
    {
      "id": 4491,
      "CityName": "Rajapur",
      "CityValue": "Rajapur"
    },
    {
      "id": 4492,
      "CityName": "Pirawa",
      "CityValue": "Pirawa"
    },
    {
      "id": 4493,
      "CityName": "Thirumittacode -II",
      "CityValue": "Thirumittacode -II"
    },
    {
      "id": 4494,
      "CityName": "Salpara Molandubi Pt.-I",
      "CityValue": "Salpara Molandubi Pt.-I"
    },
    {
      "id": 4495,
      "CityName": "Rohta",
      "CityValue": "Rohta"
    },
    {
      "id": 4496,
      "CityName": "Chandkapur",
      "CityValue": "Chandkapur"
    },
    {
      "id": 4497,
      "CityName": "Khairabad",
      "CityValue": "Khairabad"
    },
    {
      "id": 4498,
      "CityName": "Thazhakara",
      "CityValue": "Thazhakara"
    },
    {
      "id": 4499,
      "CityName": "Ichoda",
      "CityValue": "Ichoda"
    },
    {
      "id": 4500,
      "CityName": "Paschim Bainan",
      "CityValue": "Paschim Bainan"
    },
    {
      "id": 4501,
      "CityName": "Edakkode",
      "CityValue": "Edakkode"
    },
    {
      "id": 4502,
      "CityName": "Singarbil",
      "CityValue": "Singarbil"
    },
    {
      "id": 4503,
      "CityName": "Mandaikadu",
      "CityValue": "Mandaikadu"
    },
    {
      "id": 4504,
      "CityName": "Karunguzhi",
      "CityValue": "Karunguzhi"
    },
    {
      "id": 4505,
      "CityName": "Barela",
      "CityValue": "Barela"
    },
    {
      "id": 4506,
      "CityName": "Brindabanpur",
      "CityValue": "Brindabanpur"
    },
    {
      "id": 4507,
      "CityName": "Allipur",
      "CityValue": "Allipur"
    },
    {
      "id": 4508,
      "CityName": "Faizganj",
      "CityValue": "Faizganj"
    },
    {
      "id": 4509,
      "CityName": "Bilariaganj",
      "CityValue": "Bilariaganj"
    },
    {
      "id": 4510,
      "CityName": "Tikri Khurd",
      "CityValue": "Tikri Khurd"
    },
    {
      "id": 4511,
      "CityName": "Guma",
      "CityValue": "Guma"
    },
    {
      "id": 4512,
      "CityName": "Dildarnagar Fatehpur Bazar",
      "CityValue": "Dildarnagar Fatehpur Bazar"
    },
    {
      "id": 4513,
      "CityName": "Sikkarayapuram",
      "CityValue": "Sikkarayapuram"
    },
    {
      "id": 4514,
      "CityName": "Hata",
      "CityValue": "Hata"
    },
    {
      "id": 4515,
      "CityName": "Vadugapatti",
      "CityValue": "Vadugapatti"
    },
    {
      "id": 4516,
      "CityName": "Suchindrum",
      "CityValue": "Suchindrum"
    },
    {
      "id": 4517,
      "CityName": "Chandwa",
      "CityValue": "Chandwa"
    },
    {
      "id": 4518,
      "CityName": "Uttampur",
      "CityValue": "Uttampur"
    },
    {
      "id": 4519,
      "CityName": "Uniara",
      "CityValue": "Uniara"
    },
    {
      "id": 4520,
      "CityName": "Sukdal",
      "CityValue": "Sukdal"
    },
    {
      "id": 4521,
      "CityName": "Thummalamenta",
      "CityValue": "Thummalamenta"
    },
    {
      "id": 4522,
      "CityName": "Khailar",
      "CityValue": "Khailar"
    },
    {
      "id": 4523,
      "CityName": "Puthalam",
      "CityValue": "Puthalam"
    },
    {
      "id": 4524,
      "CityName": "Kanor",
      "CityValue": "Kanor"
    },
    {
      "id": 4525,
      "CityName": "Mallankinaru",
      "CityValue": "Mallankinaru"
    },
    {
      "id": 4526,
      "CityName": "Madhur",
      "CityValue": "Madhur"
    },
    {
      "id": 4527,
      "CityName": "Bechar Alias Becharaji",
      "CityValue": "Bechar Alias Becharaji"
    },
    {
      "id": 4528,
      "CityName": "Taldi",
      "CityValue": "Taldi"
    },
    {
      "id": 4529,
      "CityName": "Chanduria",
      "CityValue": "Chanduria"
    },
    {
      "id": 4530,
      "CityName": "Lido Town",
      "CityValue": "Lido Town"
    },
    {
      "id": 4531,
      "CityName": "Kusmara",
      "CityValue": "Kusmara"
    },
    {
      "id": 4532,
      "CityName": "Sualkuchi",
      "CityValue": "Sualkuchi"
    },
    {
      "id": 4533,
      "CityName": "Tilang Pur Kotla",
      "CityValue": "Tilang Pur Kotla"
    },
    {
      "id": 4534,
      "CityName": "Gua",
      "CityValue": "Gua"
    },
    {
      "id": 4535,
      "CityName": "Kauriaganj",
      "CityValue": "Kauriaganj"
    },
    {
      "id": 4536,
      "CityName": "Atmakur",
      "CityValue": "Atmakur"
    },
    {
      "id": 4537,
      "CityName": "Bare",
      "CityValue": "Bare"
    },
    {
      "id": 4538,
      "CityName": "Rori",
      "CityValue": "Rori"
    },
    {
      "id": 4539,
      "CityName": "Balirampur",
      "CityValue": "Balirampur"
    },
    {
      "id": 4540,
      "CityName": "Pooluvapatti",
      "CityValue": "Pooluvapatti"
    },
    {
      "id": 4541,
      "CityName": "Thamaraikulam",
      "CityValue": "Thamaraikulam"
    },
    {
      "id": 4542,
      "CityName": "Nagina",
      "CityValue": "Nagina"
    },
    {
      "id": 4543,
      "CityName": "Bijni",
      "CityValue": "Bijni"
    },
    {
      "id": 4544,
      "CityName": "Kasba Khanpur",
      "CityValue": "Kasba Khanpur"
    },
    {
      "id": 4545,
      "CityName": "Baliadanga",
      "CityValue": "Baliadanga"
    },
    {
      "id": 4546,
      "CityName": "Devendranagar",
      "CityValue": "Devendranagar"
    },
    {
      "id": 4547,
      "CityName": "Khairabad",
      "CityValue": "Khairabad"
    },
    {
      "id": 4548,
      "CityName": "Kodumudi",
      "CityValue": "Kodumudi"
    },
    {
      "id": 4549,
      "CityName": "Trimbak",
      "CityValue": "Trimbak"
    },
    {
      "id": 4550,
      "CityName": "Sadat",
      "CityValue": "Sadat"
    },
    {
      "id": 4551,
      "CityName": "B.Mallapuram",
      "CityValue": "B.Mallapuram"
    },
    {
      "id": 4552,
      "CityName": "Kadambathur",
      "CityValue": "Kadambathur"
    },
    {
      "id": 4553,
      "CityName": "Domohani",
      "CityValue": "Domohani"
    },
    {
      "id": 4554,
      "CityName": "Naraini",
      "CityValue": "Naraini"
    },
    {
      "id": 4555,
      "CityName": "Saurikh",
      "CityValue": "Saurikh"
    },
    {
      "id": 4556,
      "CityName": "Akoda",
      "CityValue": "Akoda"
    },
    {
      "id": 4557,
      "CityName": "Othakalmandapam",
      "CityValue": "Othakalmandapam"
    },
    {
      "id": 4558,
      "CityName": "Bankimnagar",
      "CityValue": "Bankimnagar"
    },
    {
      "id": 4559,
      "CityName": "Siddhaur",
      "CityValue": "Siddhaur"
    },
    {
      "id": 4560,
      "CityName": "Nannilam",
      "CityValue": "Nannilam"
    },
    {
      "id": 4561,
      "CityName": "Viralimalai",
      "CityValue": "Viralimalai"
    },
    {
      "id": 4562,
      "CityName": "Pochampalle",
      "CityValue": "Pochampalle"
    },
    {
      "id": 4563,
      "CityName": "Shivaji Nagar",
      "CityValue": "Shivaji Nagar"
    },
    {
      "id": 4564,
      "CityName": "Kaniyarkode",
      "CityValue": "Kaniyarkode"
    },
    {
      "id": 4565,
      "CityName": "Ranapur",
      "CityValue": "Ranapur"
    },
    {
      "id": 4566,
      "CityName": "Parnera",
      "CityValue": "Parnera"
    },
    {
      "id": 4567,
      "CityName": "Mankuva",
      "CityValue": "Mankuva"
    },
    {
      "id": 4568,
      "CityName": "Arasur",
      "CityValue": "Arasur"
    },
    {
      "id": 4569,
      "CityName": "Puliyur",
      "CityValue": "Puliyur"
    },
    {
      "id": 4570,
      "CityName": "Maharajpur",
      "CityValue": "Maharajpur"
    },
    {
      "id": 4571,
      "CityName": "Gidi",
      "CityValue": "Gidi"
    },
    {
      "id": 4572,
      "CityName": "Kherdi",
      "CityValue": "Kherdi"
    },
    {
      "id": 4573,
      "CityName": "Mangalpady",
      "CityValue": "Mangalpady"
    },
    {
      "id": 4574,
      "CityName": "Thathaiyangarpet",
      "CityValue": "Thathaiyangarpet"
    },
    {
      "id": 4575,
      "CityName": "Orchha",
      "CityValue": "Orchha"
    },
    {
      "id": 4576,
      "CityName": "Manohar Pur",
      "CityValue": "Manohar Pur"
    },
    {
      "id": 4577,
      "CityName": "Hassan Pur",
      "CityValue": "Hassan Pur"
    },
    {
      "id": 4578,
      "CityName": "Kuttiattoor",
      "CityValue": "Kuttiattoor"
    },
    {
      "id": 4579,
      "CityName": "Udayarpalayam",
      "CityValue": "Udayarpalayam"
    },
    {
      "id": 4580,
      "CityName": "Sembedu",
      "CityValue": "Sembedu"
    },
    {
      "id": 4581,
      "CityName": "Neykkarappatti",
      "CityValue": "Neykkarappatti"
    },
    {
      "id": 4582,
      "CityName": "Satrikh",
      "CityValue": "Satrikh"
    },
    {
      "id": 4583,
      "CityName": "Barharwa",
      "CityValue": "Barharwa"
    },
    {
      "id": 4584,
      "CityName": "Nazira",
      "CityValue": "Nazira"
    },
    {
      "id": 4585,
      "CityName": "Alangudi",
      "CityValue": "Alangudi"
    },
    {
      "id": 4586,
      "CityName": "Sirsa",
      "CityValue": "Sirsa"
    },
    {
      "id": 4587,
      "CityName": "Ramanujganj",
      "CityValue": "Ramanujganj"
    },
    {
      "id": 4588,
      "CityName": "Kudwa",
      "CityValue": "Kudwa"
    },
    {
      "id": 4589,
      "CityName": "Thalainayar",
      "CityValue": "Thalainayar"
    },
    {
      "id": 4590,
      "CityName": "Arani",
      "CityValue": "Arani"
    },
    {
      "id": 4591,
      "CityName": "Oorakam",
      "CityValue": "Oorakam"
    },
    {
      "id": 4592,
      "CityName": "Ammoor",
      "CityValue": "Ammoor"
    },
    {
      "id": 4593,
      "CityName": "Nedumpura",
      "CityValue": "Nedumpura"
    },
    {
      "id": 4594,
      "CityName": "Elavally",
      "CityValue": "Elavally"
    },
    {
      "id": 4595,
      "CityName": "Kolazhy",
      "CityValue": "Kolazhy"
    },
    {
      "id": 4596,
      "CityName": "Narath",
      "CityValue": "Narath"
    },
    {
      "id": 4597,
      "CityName": "Armapur Estate",
      "CityValue": "Armapur Estate"
    },
    {
      "id": 4598,
      "CityName": "Ankurhati",
      "CityValue": "Ankurhati"
    },
    {
      "id": 4599,
      "CityName": "Peda Boddepalle",
      "CityValue": "Peda Boddepalle"
    },
    {
      "id": 4600,
      "CityName": "Adalaj",
      "CityValue": "Adalaj"
    },
    {
      "id": 4601,
      "CityName": "Chak Kanthalia",
      "CityValue": "Chak Kanthalia"
    },
    {
      "id": 4602,
      "CityName": "Sonada Khasmahal",
      "CityValue": "Sonada Khasmahal"
    },
    {
      "id": 4603,
      "CityName": "Kelwa",
      "CityValue": "Kelwa"
    },
    {
      "id": 4604,
      "CityName": "Baldeo",
      "CityValue": "Baldeo"
    },
    {
      "id": 4605,
      "CityName": "Champahati",
      "CityValue": "Champahati"
    },
    {
      "id": 4606,
      "CityName": "Palej",
      "CityValue": "Palej"
    },
    {
      "id": 4607,
      "CityName": "Vikravandi",
      "CityValue": "Vikravandi"
    },
    {
      "id": 4608,
      "CityName": "Bhim",
      "CityValue": "Bhim"
    },
    {
      "id": 4609,
      "CityName": "Janai",
      "CityValue": "Janai"
    },
    {
      "id": 4610,
      "CityName": "Yercaud",
      "CityValue": "Yercaud"
    },
    {
      "id": 4611,
      "CityName": "Shankara",
      "CityValue": "Shankara"
    },
    {
      "id": 4612,
      "CityName": "Bishnupur",
      "CityValue": "Bishnupur"
    },
    {
      "id": 4613,
      "CityName": "Benakanahalli",
      "CityValue": "Benakanahalli"
    },
    {
      "id": 4614,
      "CityName": "Ponnamaravathi",
      "CityValue": "Ponnamaravathi"
    },
    {
      "id": 4615,
      "CityName": "Kota",
      "CityValue": "Kota"
    },
    {
      "id": 4616,
      "CityName": "Sawa",
      "CityValue": "Sawa"
    },
    {
      "id": 4617,
      "CityName": "Buxer",
      "CityValue": "Buxer"
    },
    {
      "id": 4618,
      "CityName": "Asola",
      "CityValue": "Asola"
    },
    {
      "id": 4619,
      "CityName": "Thirukkattupalli",
      "CityValue": "Thirukkattupalli"
    },
    {
      "id": 4620,
      "CityName": "Surul",
      "CityValue": "Surul"
    },
    {
      "id": 4621,
      "CityName": "Dhemaji",
      "CityValue": "Dhemaji"
    },
    {
      "id": 4622,
      "CityName": "Gazipur",
      "CityValue": "Gazipur"
    },
    {
      "id": 4623,
      "CityName": "Takagach",
      "CityValue": "Takagach"
    },
    {
      "id": 4624,
      "CityName": "Karivali",
      "CityValue": "Karivali"
    },
    {
      "id": 4625,
      "CityName": "Pratapsasan",
      "CityValue": "Pratapsasan"
    },
    {
      "id": 4626,
      "CityName": "Alanganallur",
      "CityValue": "Alanganallur"
    },
    {
      "id": 4627,
      "CityName": "Pallapalayam",
      "CityValue": "Pallapalayam"
    },
    {
      "id": 4628,
      "CityName": "Piran Kaliyar",
      "CityValue": "Piran Kaliyar"
    },
    {
      "id": 4629,
      "CityName": "Nagireddipalle",
      "CityValue": "Nagireddipalle"
    },
    {
      "id": 4630,
      "CityName": "Barkhera",
      "CityValue": "Barkhera"
    },
    {
      "id": 4631,
      "CityName": "Neyyoor",
      "CityValue": "Neyyoor"
    },
    {
      "id": 4632,
      "CityName": "Puthagaram",
      "CityValue": "Puthagaram"
    },
    {
      "id": 4633,
      "CityName": "Ara",
      "CityValue": "Ara"
    },
    {
      "id": 4634,
      "CityName": "Nachane",
      "CityValue": "Nachane"
    },
    {
      "id": 4635,
      "CityName": "Prothrapur",
      "CityValue": "Prothrapur"
    },
    {
      "id": 4636,
      "CityName": "Bara Jumla",
      "CityValue": "Bara Jumla"
    },
    {
      "id": 4637,
      "CityName": "Sulanggari",
      "CityValue": "Sulanggari"
    },
    {
      "id": 4638,
      "CityName": "Janpur",
      "CityValue": "Janpur"
    },
    {
      "id": 4639,
      "CityName": "Varanasi",
      "CityValue": "Varanasi"
    },
    {
      "id": 4640,
      "CityName": "Gutlong Gaon",
      "CityValue": "Gutlong Gaon"
    },
    {
      "id": 4641,
      "CityName": "Baskhoh",
      "CityValue": "Baskhoh"
    },
    {
      "id": 4642,
      "CityName": "Pudur",
      "CityValue": "Pudur"
    },
    {
      "id": 4643,
      "CityName": "S.Kodikulam",
      "CityValue": "S.Kodikulam"
    },
    {
      "id": 4644,
      "CityName": "Satgawan Alias Hariharganj",
      "CityValue": "Satgawan Alias Hariharganj"
    },
    {
      "id": 4645,
      "CityName": "Maruadih",
      "CityValue": "Maruadih"
    },
    {
      "id": 4646,
      "CityName": "Sojat Road",
      "CityValue": "Sojat Road"
    },
    {
      "id": 4647,
      "CityName": "Rampur Naikin",
      "CityValue": "Rampur Naikin"
    },
    {
      "id": 4648,
      "CityName": "Kanodar",
      "CityValue": "Kanodar"
    },
    {
      "id": 4649,
      "CityName": "Gyanpur",
      "CityValue": "Gyanpur"
    },
    {
      "id": 4650,
      "CityName": "Cheema",
      "CityValue": "Cheema"
    },
    {
      "id": 4651,
      "CityName": "Sevur",
      "CityValue": "Sevur"
    },
    {
      "id": 4652,
      "CityName": "Kela Khera",
      "CityValue": "Kela Khera"
    },
    {
      "id": 4653,
      "CityName": "Kurumbalur",
      "CityValue": "Kurumbalur"
    },
    {
      "id": 4654,
      "CityName": "Jobat",
      "CityValue": "Jobat"
    },
    {
      "id": 4655,
      "CityName": "Ponnampatti",
      "CityValue": "Ponnampatti"
    },
    {
      "id": 4656,
      "CityName": "Deulpur",
      "CityValue": "Deulpur"
    },
    {
      "id": 4657,
      "CityName": "Barhi",
      "CityValue": "Barhi"
    },
    {
      "id": 4658,
      "CityName": "Kothur",
      "CityValue": "Kothur"
    },
    {
      "id": 4659,
      "CityName": "Maniyoor",
      "CityValue": "Maniyoor"
    },
    {
      "id": 4660,
      "CityName": "Kalaria",
      "CityValue": "Kalaria"
    },
    {
      "id": 4661,
      "CityName": "Bodeli",
      "CityValue": "Bodeli"
    },
    {
      "id": 4662,
      "CityName": "Bhaurasa",
      "CityValue": "Bhaurasa"
    },
    {
      "id": 4663,
      "CityName": "Adikaratti",
      "CityValue": "Adikaratti"
    },
    {
      "id": 4664,
      "CityName": "Pankhedi",
      "CityValue": "Pankhedi"
    },
    {
      "id": 4665,
      "CityName": "Muri",
      "CityValue": "Muri"
    },
    {
      "id": 4666,
      "CityName": "Hariharpur",
      "CityValue": "Hariharpur"
    },
    {
      "id": 4667,
      "CityName": "Takalghat",
      "CityValue": "Takalghat"
    },
    {
      "id": 4668,
      "CityName": "Kambainallur",
      "CityValue": "Kambainallur"
    },
    {
      "id": 4669,
      "CityName": "Masila",
      "CityValue": "Masila"
    },
    {
      "id": 4670,
      "CityName": "Polaykalan",
      "CityValue": "Polaykalan"
    },
    {
      "id": 4671,
      "CityName": "Navelim",
      "CityValue": "Navelim"
    },
    {
      "id": 4672,
      "CityName": "Mandawar",
      "CityValue": "Mandawar"
    },
    {
      "id": 4673,
      "CityName": "Bilaspur",
      "CityValue": "Bilaspur"
    },
    {
      "id": 4674,
      "CityName": "Barghat",
      "CityValue": "Barghat"
    },
    {
      "id": 4675,
      "CityName": "Sarsawan",
      "CityValue": "Sarsawan"
    },
    {
      "id": 4676,
      "CityName": "Kesabpur",
      "CityValue": "Kesabpur"
    },
    {
      "id": 4677,
      "CityName": "Parsadepur",
      "CityValue": "Parsadepur"
    },
    {
      "id": 4678,
      "CityName": "Hatti Gold Mines",
      "CityValue": "Hatti Gold Mines"
    },
    {
      "id": 4679,
      "CityName": "Gosainganj",
      "CityValue": "Gosainganj"
    },
    {
      "id": 4680,
      "CityName": "Pavaratty",
      "CityValue": "Pavaratty"
    },
    {
      "id": 4681,
      "CityName": "Naranapuram",
      "CityValue": "Naranapuram"
    },
    {
      "id": 4682,
      "CityName": "Tawang",
      "CityValue": "Tawang"
    },
    {
      "id": 4683,
      "CityName": "Bandiya",
      "CityValue": "Bandiya"
    },
    {
      "id": 4684,
      "CityName": "Hukmakhedi",
      "CityValue": "Hukmakhedi"
    },
    {
      "id": 4685,
      "CityName": "Mayyil",
      "CityValue": "Mayyil"
    },
    {
      "id": 4686,
      "CityName": "Dudhi",
      "CityValue": "Dudhi"
    },
    {
      "id": 4687,
      "CityName": "Maramangalathupatti",
      "CityValue": "Maramangalathupatti"
    },
    {
      "id": 4688,
      "CityName": "Jaithara",
      "CityValue": "Jaithara"
    },
    {
      "id": 4689,
      "CityName": "Pansemal",
      "CityValue": "Pansemal"
    },
    {
      "id": 4690,
      "CityName": "Mirik",
      "CityValue": "Mirik"
    },
    {
      "id": 4691,
      "CityName": "Okani-II",
      "CityValue": "Okani-II"
    },
    {
      "id": 4692,
      "CityName": "Gobindpur",
      "CityValue": "Gobindpur"
    },
    {
      "id": 4693,
      "CityName": "Ghogha",
      "CityValue": "Ghogha"
    },
    {
      "id": 4694,
      "CityName": "Marai Kalan",
      "CityValue": "Marai Kalan"
    },
    {
      "id": 4695,
      "CityName": "Pichhore",
      "CityValue": "Pichhore"
    },
    {
      "id": 4696,
      "CityName": "Sindi",
      "CityValue": "Sindi"
    },
    {
      "id": 4697,
      "CityName": "Vedapatti",
      "CityValue": "Vedapatti"
    },
    {
      "id": 4698,
      "CityName": "Calangute",
      "CityValue": "Calangute"
    },
    {
      "id": 4699,
      "CityName": "Aravakurichi",
      "CityValue": "Aravakurichi"
    },
    {
      "id": 4700,
      "CityName": "Bandora",
      "CityValue": "Bandora"
    },
    {
      "id": 4701,
      "CityName": "Kuttoor",
      "CityValue": "Kuttoor"
    },
    {
      "id": 4702,
      "CityName": "Pilkhana",
      "CityValue": "Pilkhana"
    },
    {
      "id": 4703,
      "CityName": "Kantaberia",
      "CityValue": "Kantaberia"
    },
    {
      "id": 4704,
      "CityName": "Perakam",
      "CityValue": "Perakam"
    },
    {
      "id": 4705,
      "CityName": "Bhabinipur",
      "CityValue": "Bhabinipur"
    },
    {
      "id": 4706,
      "CityName": "Devrukh",
      "CityValue": "Devrukh"
    },
    {
      "id": 4707,
      "CityName": "Maigal Ganj",
      "CityValue": "Maigal Ganj"
    },
    {
      "id": 4708,
      "CityName": "Maruadih Railway Settlement",
      "CityValue": "Maruadih Railway Settlement"
    },
    {
      "id": 4709,
      "CityName": "Ziro",
      "CityValue": "Ziro"
    },
    {
      "id": 4710,
      "CityName": "Chinnakkampalayam",
      "CityValue": "Chinnakkampalayam"
    },
    {
      "id": 4711,
      "CityName": "Ponduru",
      "CityValue": "Ponduru"
    },
    {
      "id": 4712,
      "CityName": "Kadavallur",
      "CityValue": "Kadavallur"
    },
    {
      "id": 4713,
      "CityName": "Thirumalayampalayam",
      "CityValue": "Thirumalayampalayam"
    },
    {
      "id": 4714,
      "CityName": "Arumbavur",
      "CityValue": "Arumbavur"
    },
    {
      "id": 4715,
      "CityName": "Benaulim",
      "CityValue": "Benaulim"
    },
    {
      "id": 4716,
      "CityName": "Rambha",
      "CityValue": "Rambha"
    },
    {
      "id": 4717,
      "CityName": "Dineshpur",
      "CityValue": "Dineshpur"
    },
    {
      "id": 4718,
      "CityName": "Thondamuthur",
      "CityValue": "Thondamuthur"
    },
    {
      "id": 4719,
      "CityName": "Mandleshwar",
      "CityValue": "Mandleshwar"
    },
    {
      "id": 4720,
      "CityName": "Athipattu",
      "CityValue": "Athipattu"
    },
    {
      "id": 4721,
      "CityName": "Majhauli",
      "CityValue": "Majhauli"
    },
    {
      "id": 4722,
      "CityName": "Nandambakkam",
      "CityValue": "Nandambakkam"
    },
    {
      "id": 4723,
      "CityName": "Sayapuram",
      "CityValue": "Sayapuram"
    },
    {
      "id": 4724,
      "CityName": "Seerapalli",
      "CityValue": "Seerapalli"
    },
    {
      "id": 4725,
      "CityName": "Kumirmora",
      "CityValue": "Kumirmora"
    },
    {
      "id": 4726,
      "CityName": "Edathiruthy",
      "CityValue": "Edathiruthy"
    },
    {
      "id": 4727,
      "CityName": "Konavattam",
      "CityValue": "Konavattam"
    },
    {
      "id": 4728,
      "CityName": "Moth",
      "CityValue": "Moth"
    },
    {
      "id": 4729,
      "CityName": "Gangavalli",
      "CityValue": "Gangavalli"
    },
    {
      "id": 4730,
      "CityName": "Gandhinagar",
      "CityValue": "Gandhinagar"
    },
    {
      "id": 4731,
      "CityName": "Ettayapuram",
      "CityValue": "Ettayapuram"
    },
    {
      "id": 4732,
      "CityName": "Choondal",
      "CityValue": "Choondal"
    },
    {
      "id": 4733,
      "CityName": "Jangipur",
      "CityValue": "Jangipur"
    },
    {
      "id": 4734,
      "CityName": "Azmatgarh",
      "CityValue": "Azmatgarh"
    },
    {
      "id": 4735,
      "CityName": "Jemari",
      "CityValue": "Jemari"
    },
    {
      "id": 4736,
      "CityName": "Uttar Kamakhyaguri",
      "CityValue": "Uttar Kamakhyaguri"
    },
    {
      "id": 4737,
      "CityName": "Takali Pr. Chalisgaon",
      "CityValue": "Takali Pr. Chalisgaon"
    },
    {
      "id": 4738,
      "CityName": "Pullur",
      "CityValue": "Pullur"
    },
    {
      "id": 4739,
      "CityName": "Naldanga",
      "CityValue": "Naldanga"
    },
    {
      "id": 4740,
      "CityName": "Chendhare",
      "CityValue": "Chendhare"
    },
    {
      "id": 4741,
      "CityName": "Sevugampatti",
      "CityValue": "Sevugampatti"
    },
    {
      "id": 4742,
      "CityName": "Cherrapunjee",
      "CityValue": "Cherrapunjee"
    },
    {
      "id": 4743,
      "CityName": "Mana-Camp",
      "CityValue": "Mana-Camp"
    },
    {
      "id": 4744,
      "CityName": "Parangarpar",
      "CityValue": "Parangarpar"
    },
    {
      "id": 4745,
      "CityName": "Papparapatti",
      "CityValue": "Papparapatti"
    },
    {
      "id": 4746,
      "CityName": "Shivrajpur",
      "CityValue": "Shivrajpur"
    },
    {
      "id": 4747,
      "CityName": "Bachhrawan",
      "CityValue": "Bachhrawan"
    },
    {
      "id": 4748,
      "CityName": "Marwar Junction",
      "CityValue": "Marwar Junction"
    },
    {
      "id": 4749,
      "CityName": "Ganguvarpatti",
      "CityValue": "Ganguvarpatti"
    },
    {
      "id": 4750,
      "CityName": "Rehti",
      "CityValue": "Rehti"
    },
    {
      "id": 4751,
      "CityName": "Mahaban",
      "CityValue": "Mahaban"
    },
    {
      "id": 4752,
      "CityName": "Tondi Fatehpur",
      "CityValue": "Tondi Fatehpur"
    },
    {
      "id": 4753,
      "CityName": "Jagner",
      "CityValue": "Jagner"
    },
    {
      "id": 4754,
      "CityName": "Canacona",
      "CityValue": "Canacona"
    },
    {
      "id": 4755,
      "CityName": "Athner",
      "CityValue": "Athner"
    },
    {
      "id": 4756,
      "CityName": "Jhansi Railway Settlement",
      "CityValue": "Jhansi Railway Settlement"
    },
    {
      "id": 4757,
      "CityName": "Egarkunr",
      "CityValue": "Egarkunr"
    },
    {
      "id": 4758,
      "CityName": "Tibri",
      "CityValue": "Tibri"
    },
    {
      "id": 4759,
      "CityName": "Kotwali",
      "CityValue": "Kotwali"
    },
    {
      "id": 4760,
      "CityName": "Vallachira",
      "CityValue": "Vallachira"
    },
    {
      "id": 4761,
      "CityName": "Konaje",
      "CityValue": "Konaje"
    },
    {
      "id": 4762,
      "CityName": "Karjat",
      "CityValue": "Karjat"
    },
    {
      "id": 4763,
      "CityName": "Dhaliyur",
      "CityValue": "Dhaliyur"
    },
    {
      "id": 4764,
      "CityName": "Krishna Sali",
      "CityValue": "Krishna Sali"
    },
    {
      "id": 4765,
      "CityName": "Bakhtawar Pur",
      "CityValue": "Bakhtawar Pur"
    },
    {
      "id": 4766,
      "CityName": "Jiyanpur",
      "CityValue": "Jiyanpur"
    },
    {
      "id": 4767,
      "CityName": "Yol",
      "CityValue": "Yol"
    },
    {
      "id": 4768,
      "CityName": "Dihi Kalas",
      "CityValue": "Dihi Kalas"
    },
    {
      "id": 4769,
      "CityName": "Nandgaon",
      "CityValue": "Nandgaon"
    },
    {
      "id": 4770,
      "CityName": "Morongial",
      "CityValue": "Morongial"
    },
    {
      "id": 4771,
      "CityName": "Tekadi",
      "CityValue": "Tekadi"
    },
    {
      "id": 4772,
      "CityName": "Athmallik",
      "CityValue": "Athmallik"
    },
    {
      "id": 4773,
      "CityName": "Dohrighat",
      "CityValue": "Dohrighat"
    },
    {
      "id": 4774,
      "CityName": "Kasim Bazar",
      "CityValue": "Kasim Bazar"
    },
    {
      "id": 4775,
      "CityName": "Hameer Garh",
      "CityValue": "Hameer Garh"
    },
    {
      "id": 4776,
      "CityName": "Bilram",
      "CityValue": "Bilram"
    },
    {
      "id": 4777,
      "CityName": "Safa Pora",
      "CityValue": "Safa Pora"
    },
    {
      "id": 4778,
      "CityName": "Puduvayal",
      "CityValue": "Puduvayal"
    },
    {
      "id": 4779,
      "CityName": "Jawhar",
      "CityValue": "Jawhar"
    },
    {
      "id": 4780,
      "CityName": "Nawabganj",
      "CityValue": "Nawabganj"
    },
    {
      "id": 4781,
      "CityName": "Bihpuria",
      "CityValue": "Bihpuria"
    },
    {
      "id": 4782,
      "CityName": "Kakching Khunou",
      "CityValue": "Kakching Khunou"
    },
    {
      "id": 4783,
      "CityName": "Manoharthana",
      "CityValue": "Manoharthana"
    },
    {
      "id": 4784,
      "CityName": "Kharaghoda",
      "CityValue": "Kharaghoda"
    },
    {
      "id": 4785,
      "CityName": "Suthaliya",
      "CityValue": "Suthaliya"
    },
    {
      "id": 4786,
      "CityName": "Govindgarh",
      "CityValue": "Govindgarh"
    },
    {
      "id": 4787,
      "CityName": "Chhapra",
      "CityValue": "Chhapra"
    },
    {
      "id": 4788,
      "CityName": "Lala",
      "CityValue": "Lala"
    },
    {
      "id": 4789,
      "CityName": "Jhabrera",
      "CityValue": "Jhabrera"
    },
    {
      "id": 4790,
      "CityName": "Chak Kashipur",
      "CityValue": "Chak Kashipur"
    },
    {
      "id": 4791,
      "CityName": "Puthukkad",
      "CityValue": "Puthukkad"
    },
    {
      "id": 4792,
      "CityName": "Barsana",
      "CityValue": "Barsana"
    },
    {
      "id": 4793,
      "CityName": "Gogaon",
      "CityValue": "Gogaon"
    },
    {
      "id": 4794,
      "CityName": "Baruihuda",
      "CityValue": "Baruihuda"
    },
    {
      "id": 4795,
      "CityName": "Omkareshwar",
      "CityValue": "Omkareshwar"
    },
    {
      "id": 4796,
      "CityName": "Paramathi",
      "CityValue": "Paramathi"
    },
    {
      "id": 4797,
      "CityName": "Sasni",
      "CityValue": "Sasni"
    },
    {
      "id": 4798,
      "CityName": "Bihka Urf Pura Mufti",
      "CityValue": "Bihka Urf Pura Mufti"
    },
    {
      "id": 4799,
      "CityName": "Vairengte",
      "CityValue": "Vairengte"
    },
    {
      "id": 4800,
      "CityName": "Gudur",
      "CityValue": "Gudur"
    },
    {
      "id": 4801,
      "CityName": "Sailana",
      "CityValue": "Sailana"
    },
    {
      "id": 4802,
      "CityName": "Chechat",
      "CityValue": "Chechat"
    },
    {
      "id": 4803,
      "CityName": "Shamshabad",
      "CityValue": "Shamshabad"
    },
    {
      "id": 4804,
      "CityName": "Bhagur",
      "CityValue": "Bhagur"
    },
    {
      "id": 4805,
      "CityName": "Malhipur",
      "CityValue": "Malhipur"
    },
    {
      "id": 4806,
      "CityName": "Mallamooppampatti",
      "CityValue": "Mallamooppampatti"
    },
    {
      "id": 4807,
      "CityName": "Ashti",
      "CityValue": "Ashti"
    },
    {
      "id": 4808,
      "CityName": "Saragur",
      "CityValue": "Saragur"
    },
    {
      "id": 4809,
      "CityName": "Mahagma",
      "CityValue": "Mahagma"
    },
    {
      "id": 4810,
      "CityName": "Kukdeshwar",
      "CityValue": "Kukdeshwar"
    },
    {
      "id": 4811,
      "CityName": "Hariharpur",
      "CityValue": "Hariharpur"
    },
    {
      "id": 4812,
      "CityName": "Talgram",
      "CityValue": "Talgram"
    },
    {
      "id": 4813,
      "CityName": "Pachmarhi Cantt",
      "CityValue": "Pachmarhi Cantt"
    },
    {
      "id": 4814,
      "CityName": "Usgao",
      "CityValue": "Usgao"
    },
    {
      "id": 4815,
      "CityName": "Maindargi",
      "CityValue": "Maindargi"
    },
    {
      "id": 4816,
      "CityName": "Ambhua",
      "CityValue": "Ambhua"
    },
    {
      "id": 4817,
      "CityName": "Muni Ki Reti",
      "CityValue": "Muni Ki Reti"
    },
    {
      "id": 4818,
      "CityName": "Budha Theh",
      "CityValue": "Budha Theh"
    },
    {
      "id": 4819,
      "CityName": "Mahu Kalan",
      "CityValue": "Mahu Kalan"
    },
    {
      "id": 4820,
      "CityName": "Atru",
      "CityValue": "Atru"
    },
    {
      "id": 4821,
      "CityName": "Ilampillai",
      "CityValue": "Ilampillai"
    },
    {
      "id": 4822,
      "CityName": "Telipara Tea Garden",
      "CityValue": "Telipara Tea Garden"
    },
    {
      "id": 4823,
      "CityName": "Bahsuma",
      "CityValue": "Bahsuma"
    },
    {
      "id": 4824,
      "CityName": "Devipattinam",
      "CityValue": "Devipattinam"
    },
    {
      "id": 4825,
      "CityName": "Madhoganj",
      "CityValue": "Madhoganj"
    },
    {
      "id": 4826,
      "CityName": "Panapakkam",
      "CityValue": "Panapakkam"
    },
    {
      "id": 4827,
      "CityName": "Kadayampatti",
      "CityValue": "Kadayampatti"
    },
    {
      "id": 4828,
      "CityName": "Daheli",
      "CityValue": "Daheli"
    },
    {
      "id": 4829,
      "CityName": "Tundiul",
      "CityValue": "Tundiul"
    },
    {
      "id": 4830,
      "CityName": "Pillanallur",
      "CityValue": "Pillanallur"
    },
    {
      "id": 4831,
      "CityName": "Chapra",
      "CityValue": "Chapra"
    },
    {
      "id": 4832,
      "CityName": "Dharmaram",
      "CityValue": "Dharmaram"
    },
    {
      "id": 4833,
      "CityName": "Kamptee",
      "CityValue": "Kamptee"
    },
    {
      "id": 4834,
      "CityName": "Jagtaj",
      "CityValue": "Jagtaj"
    },
    {
      "id": 4835,
      "CityName": "Kharar",
      "CityValue": "Kharar"
    },
    {
      "id": 4836,
      "CityName": "Kishni",
      "CityValue": "Kishni"
    },
    {
      "id": 4837,
      "CityName": "Keeranur",
      "CityValue": "Keeranur"
    },
    {
      "id": 4838,
      "CityName": "Bilha",
      "CityValue": "Bilha"
    },
    {
      "id": 4839,
      "CityName": "Murgud",
      "CityValue": "Murgud"
    },
    {
      "id": 4840,
      "CityName": "Kailashpur",
      "CityValue": "Kailashpur"
    },
    {
      "id": 4841,
      "CityName": "Shahapur",
      "CityValue": "Shahapur"
    },
    {
      "id": 4842,
      "CityName": "Nahira",
      "CityValue": "Nahira"
    },
    {
      "id": 4843,
      "CityName": "Adra",
      "CityValue": "Adra"
    },
    {
      "id": 4844,
      "CityName": "Ayothiapattinam",
      "CityValue": "Ayothiapattinam"
    },
    {
      "id": 4845,
      "CityName": "Gakulnagar",
      "CityValue": "Gakulnagar"
    },
    {
      "id": 4846,
      "CityName": "Mopperipalayam",
      "CityValue": "Mopperipalayam"
    },
    {
      "id": 4847,
      "CityName": "Periyakodiveri",
      "CityValue": "Periyakodiveri"
    },
    {
      "id": 4848,
      "CityName": "Mavilayi",
      "CityValue": "Mavilayi"
    },
    {
      "id": 4849,
      "CityName": "Akodia",
      "CityValue": "Akodia"
    },
    {
      "id": 4850,
      "CityName": "Sirmour",
      "CityValue": "Sirmour"
    },
    {
      "id": 4851,
      "CityName": "Chak Banshberia",
      "CityValue": "Chak Banshberia"
    },
    {
      "id": 4852,
      "CityName": "Pudupatti",
      "CityValue": "Pudupatti"
    },
    {
      "id": 4853,
      "CityName": "Fulkumari",
      "CityValue": "Fulkumari"
    },
    {
      "id": 4854,
      "CityName": "Belumilki",
      "CityValue": "Belumilki"
    },
    {
      "id": 4855,
      "CityName": "Janki Nagar",
      "CityValue": "Janki Nagar"
    },
    {
      "id": 4856,
      "CityName": "Deodara",
      "CityValue": "Deodara"
    },
    {
      "id": 4857,
      "CityName": "Hathgram",
      "CityValue": "Hathgram"
    },
    {
      "id": 4858,
      "CityName": "Bagnan",
      "CityValue": "Bagnan"
    },
    {
      "id": 4859,
      "CityName": "Tekanpur",
      "CityValue": "Tekanpur"
    },
    {
      "id": 4860,
      "CityName": "Dakshin Rajyadharpur",
      "CityValue": "Dakshin Rajyadharpur"
    },
    {
      "id": 4861,
      "CityName": "Santir Bazar",
      "CityValue": "Santir Bazar"
    },
    {
      "id": 4862,
      "CityName": "Kandri",
      "CityValue": "Kandri"
    },
    {
      "id": 4863,
      "CityName": "Vengurla",
      "CityValue": "Vengurla"
    },
    {
      "id": 4864,
      "CityName": "Meghraj",
      "CityValue": "Meghraj"
    },
    {
      "id": 4865,
      "CityName": "Vartej",
      "CityValue": "Vartej"
    },
    {
      "id": 4866,
      "CityName": "Chandpur",
      "CityValue": "Chandpur"
    },
    {
      "id": 4867,
      "CityName": "Vedasandur",
      "CityValue": "Vedasandur"
    },
    {
      "id": 4868,
      "CityName": "Bhimram",
      "CityValue": "Bhimram"
    },
    {
      "id": 4869,
      "CityName": "Ayyampalayam",
      "CityValue": "Ayyampalayam"
    },
    {
      "id": 4870,
      "CityName": "Barha",
      "CityValue": "Barha"
    },
    {
      "id": 4871,
      "CityName": "Thenthamaraikulam",
      "CityValue": "Thenthamaraikulam"
    },
    {
      "id": 4872,
      "CityName": "Soraon",
      "CityValue": "Soraon"
    },
    {
      "id": 4873,
      "CityName": "Daporijo",
      "CityValue": "Daporijo"
    },
    {
      "id": 4874,
      "CityName": "Dakshin Santoshpur",
      "CityValue": "Dakshin Santoshpur"
    },
    {
      "id": 4875,
      "CityName": "Kulathur",
      "CityValue": "Kulathur"
    },
    {
      "id": 4876,
      "CityName": "Pudupalayam",
      "CityValue": "Pudupalayam"
    },
    {
      "id": 4877,
      "CityName": "Karari Chandpur",
      "CityValue": "Karari Chandpur"
    },
    {
      "id": 4878,
      "CityName": "Velloorkunnam",
      "CityValue": "Velloorkunnam"
    },
    {
      "id": 4879,
      "CityName": "Veeraganur",
      "CityValue": "Veeraganur"
    },
    {
      "id": 4880,
      "CityName": "Kadathur",
      "CityValue": "Kadathur"
    },
    {
      "id": 4881,
      "CityName": "Bhankharpur",
      "CityValue": "Bhankharpur"
    },
    {
      "id": 4882,
      "CityName": "Shivatkar",
      "CityValue": "Shivatkar"
    },
    {
      "id": 4883,
      "CityName": "Raja Ka Rampur",
      "CityValue": "Raja Ka Rampur"
    },
    {
      "id": 4884,
      "CityName": "Jahangirpur",
      "CityValue": "Jahangirpur"
    },
    {
      "id": 4885,
      "CityName": "Satrod Kalan",
      "CityValue": "Satrod Kalan"
    },
    {
      "id": 4886,
      "CityName": "Rengali",
      "CityValue": "Rengali"
    },
    {
      "id": 4887,
      "CityName": "Chikiti",
      "CityValue": "Chikiti"
    },
    {
      "id": 4888,
      "CityName": "Murud Janjira",
      "CityValue": "Murud Janjira"
    },
    {
      "id": 4889,
      "CityName": "Yellur",
      "CityValue": "Yellur"
    },
    {
      "id": 4890,
      "CityName": "Lakkampatti",
      "CityValue": "Lakkampatti"
    },
    {
      "id": 4891,
      "CityName": "Atasu",
      "CityValue": "Atasu"
    },
    {
      "id": 4892,
      "CityName": "Mahilpur",
      "CityValue": "Mahilpur"
    },
    {
      "id": 4893,
      "CityName": "Avalpoondurai",
      "CityValue": "Avalpoondurai"
    },
    {
      "id": 4894,
      "CityName": "Hanur",
      "CityValue": "Hanur"
    },
    {
      "id": 4895,
      "CityName": "Salwad",
      "CityValue": "Salwad"
    },
    {
      "id": 4896,
      "CityName": "Pudupattinam",
      "CityValue": "Pudupattinam"
    },
    {
      "id": 4897,
      "CityName": "Ranoli",
      "CityValue": "Ranoli"
    },
    {
      "id": 4898,
      "CityName": "Brahmabarada",
      "CityValue": "Brahmabarada"
    },
    {
      "id": 4899,
      "CityName": "Hasir",
      "CityValue": "Hasir"
    },
    {
      "id": 4900,
      "CityName": "Baragaon",
      "CityValue": "Baragaon"
    },
    {
      "id": 4901,
      "CityName": "Bithoor",
      "CityValue": "Bithoor"
    },
    {
      "id": 4902,
      "CityName": "Bisanda Buzurg",
      "CityValue": "Bisanda Buzurg"
    },
    {
      "id": 4903,
      "CityName": "Narasingapuram",
      "CityValue": "Narasingapuram"
    },
    {
      "id": 4904,
      "CityName": "Kalinagar",
      "CityValue": "Kalinagar"
    },
    {
      "id": 4905,
      "CityName": "Yenumalapalle",
      "CityValue": "Yenumalapalle"
    },
    {
      "id": 4906,
      "CityName": "3 e Village",
      "CityValue": "3 e Village"
    },
    {
      "id": 4907,
      "CityName": "Choubepur Kalan",
      "CityValue": "Choubepur Kalan"
    },
    {
      "id": 4908,
      "CityName": "Roing",
      "CityValue": "Roing"
    },
    {
      "id": 4909,
      "CityName": "Vadama",
      "CityValue": "Vadama"
    },
    {
      "id": 4910,
      "CityName": "Sapatgram",
      "CityValue": "Sapatgram"
    },
    {
      "id": 4911,
      "CityName": "Valayambattu",
      "CityValue": "Valayambattu"
    },
    {
      "id": 4912,
      "CityName": "Salarpur",
      "CityValue": "Salarpur"
    },
    {
      "id": 4913,
      "CityName": "Mandav",
      "CityValue": "Mandav"
    },
    {
      "id": 4914,
      "CityName": "Bellaguntha",
      "CityValue": "Bellaguntha"
    },
    {
      "id": 4915,
      "CityName": "Mubarak Pur Dabas",
      "CityValue": "Mubarak Pur Dabas"
    },
    {
      "id": 4916,
      "CityName": "Udyavara",
      "CityValue": "Udyavara"
    },
    {
      "id": 4917,
      "CityName": "Beldubi",
      "CityValue": "Beldubi"
    },
    {
      "id": 4918,
      "CityName": "Vaniputhur",
      "CityValue": "Vaniputhur"
    },
    {
      "id": 4919,
      "CityName": "Palamedu",
      "CityValue": "Palamedu"
    },
    {
      "id": 4920,
      "CityName": "Ekdil",
      "CityValue": "Ekdil"
    },
    {
      "id": 4921,
      "CityName": "Fatehgarh Churian",
      "CityValue": "Fatehgarh Churian"
    },
    {
      "id": 4922,
      "CityName": "Balimela",
      "CityValue": "Balimela"
    },
    {
      "id": 4923,
      "CityName": "Railway Settlement Roza",
      "CityValue": "Railway Settlement Roza"
    },
    {
      "id": 4924,
      "CityName": "Saitual",
      "CityValue": "Saitual"
    },
    {
      "id": 4925,
      "CityName": "Ganjam",
      "CityValue": "Ganjam"
    },
    {
      "id": 4926,
      "CityName": "Valangaiman",
      "CityValue": "Valangaiman"
    },
    {
      "id": 4927,
      "CityName": "Rurki Kasba",
      "CityValue": "Rurki Kasba"
    },
    {
      "id": 4928,
      "CityName": "Uttar Champamura",
      "CityValue": "Uttar Champamura"
    },
    {
      "id": 4929,
      "CityName": "Bhavra",
      "CityValue": "Bhavra"
    },
    {
      "id": 4930,
      "CityName": "Farah",
      "CityValue": "Farah"
    },
    {
      "id": 4931,
      "CityName": "Jobner",
      "CityValue": "Jobner"
    },
    {
      "id": 4932,
      "CityName": "Satai",
      "CityValue": "Satai"
    },
    {
      "id": 4933,
      "CityName": "Bahuwa",
      "CityValue": "Bahuwa"
    },
    {
      "id": 4934,
      "CityName": "Sonamura",
      "CityValue": "Sonamura"
    },
    {
      "id": 4935,
      "CityName": "Kshetrigao",
      "CityValue": "Kshetrigao"
    },
    {
      "id": 4936,
      "CityName": "Bahadarabad",
      "CityValue": "Bahadarabad"
    },
    {
      "id": 4937,
      "CityName": "Unchahar",
      "CityValue": "Unchahar"
    },
    {
      "id": 4938,
      "CityName": "Koothali",
      "CityValue": "Koothali"
    },
    {
      "id": 4939,
      "CityName": "Patti",
      "CityValue": "Patti"
    },
    {
      "id": 4940,
      "CityName": "Jiran",
      "CityValue": "Jiran"
    },
    {
      "id": 4941,
      "CityName": "Athur",
      "CityValue": "Athur"
    },
    {
      "id": 4942,
      "CityName": "Chak Alampur",
      "CityValue": "Chak Alampur"
    },
    {
      "id": 4943,
      "CityName": "Garautha",
      "CityValue": "Garautha"
    },
    {
      "id": 4944,
      "CityName": "Ganj Muradabad",
      "CityValue": "Ganj Muradabad"
    },
    {
      "id": 4945,
      "CityName": "Harrai",
      "CityValue": "Harrai"
    },
    {
      "id": 4946,
      "CityName": "Shillong",
      "CityValue": "Shillong"
    },
    {
      "id": 4947,
      "CityName": "Keskal",
      "CityValue": "Keskal"
    },
    {
      "id": 4948,
      "CityName": "Jafarabad",
      "CityValue": "Jafarabad"
    },
    {
      "id": 4949,
      "CityName": "Shahpura",
      "CityValue": "Shahpura"
    },
    {
      "id": 4950,
      "CityName": "Arachalur",
      "CityValue": "Arachalur"
    },
    {
      "id": 4951,
      "CityName": "Uppidamangalam",
      "CityValue": "Uppidamangalam"
    },
    {
      "id": 4952,
      "CityName": "Chettipalayam",
      "CityValue": "Chettipalayam"
    },
    {
      "id": 4953,
      "CityName": "Azizpur",
      "CityValue": "Azizpur"
    },
    {
      "id": 4954,
      "CityName": "3 STR",
      "CityValue": "3 STR"
    },
    {
      "id": 4955,
      "CityName": "Tindwari",
      "CityValue": "Tindwari"
    },
    {
      "id": 4956,
      "CityName": "Chhota Suzapur",
      "CityValue": "Chhota Suzapur"
    },
    {
      "id": 4957,
      "CityName": "Chennithala",
      "CityValue": "Chennithala"
    },
    {
      "id": 4958,
      "CityName": "Kattakampal",
      "CityValue": "Kattakampal"
    },
    {
      "id": 4959,
      "CityName": "Bira",
      "CityValue": "Bira"
    },
    {
      "id": 4960,
      "CityName": "Aduthurai alias Maruthuvakudi",
      "CityValue": "Aduthurai alias Maruthuvakudi"
    },
    {
      "id": 4961,
      "CityName": "Nadsal",
      "CityValue": "Nadsal"
    },
    {
      "id": 4962,
      "CityName": "Berandari Bagaria",
      "CityValue": "Berandari Bagaria"
    },
    {
      "id": 4963,
      "CityName": "Kuhalur",
      "CityValue": "Kuhalur"
    },
    {
      "id": 4964,
      "CityName": "Moolakaraipatti",
      "CityValue": "Moolakaraipatti"
    },
    {
      "id": 4965,
      "CityName": "Kashirampur",
      "CityValue": "Kashirampur"
    },
    {
      "id": 4966,
      "CityName": "Sarcarsamakulam",
      "CityValue": "Sarcarsamakulam"
    },
    {
      "id": 4967,
      "CityName": "G. Udayagiri",
      "CityValue": "G. Udayagiri"
    },
    {
      "id": 4968,
      "CityName": "Anjugrammam",
      "CityValue": "Anjugrammam"
    },
    {
      "id": 4969,
      "CityName": "Parashkol",
      "CityValue": "Parashkol"
    },
    {
      "id": 4970,
      "CityName": "Nemili",
      "CityValue": "Nemili"
    },
    {
      "id": 4971,
      "CityName": "Dhariawad",
      "CityValue": "Dhariawad"
    },
    {
      "id": 4972,
      "CityName": "Uppala",
      "CityValue": "Uppala"
    },
    {
      "id": 4973,
      "CityName": "Thimmapur",
      "CityValue": "Thimmapur"
    },
    {
      "id": 4974,
      "CityName": "Boothipuram",
      "CityValue": "Boothipuram"
    },
    {
      "id": 4975,
      "CityName": "Padiyam",
      "CityValue": "Padiyam"
    },
    {
      "id": 4976,
      "CityName": "Bagicha",
      "CityValue": "Bagicha"
    },
    {
      "id": 4977,
      "CityName": "Marungur",
      "CityValue": "Marungur"
    },
    {
      "id": 4978,
      "CityName": "Pathsala",
      "CityValue": "Pathsala"
    },
    {
      "id": 4979,
      "CityName": "Mullassery",
      "CityValue": "Mullassery"
    },
    {
      "id": 4980,
      "CityName": "Ammavarikuppam",
      "CityValue": "Ammavarikuppam"
    },
    {
      "id": 4981,
      "CityName": "Makhal Tala",
      "CityValue": "Makhal Tala"
    },
    {
      "id": 4982,
      "CityName": "Manor",
      "CityValue": "Manor"
    },
    {
      "id": 4983,
      "CityName": "Mudki",
      "CityValue": "Mudki"
    },
    {
      "id": 4984,
      "CityName": "Raia",
      "CityValue": "Raia"
    },
    {
      "id": 4985,
      "CityName": "Balagoda",
      "CityValue": "Balagoda"
    },
    {
      "id": 4986,
      "CityName": "Karnawad",
      "CityValue": "Karnawad"
    },
    {
      "id": 4987,
      "CityName": "Chhatari",
      "CityValue": "Chhatari"
    },
    {
      "id": 4988,
      "CityName": "Parappukkara",
      "CityValue": "Parappukkara"
    },
    {
      "id": 4989,
      "CityName": "Aminagar Sarai",
      "CityValue": "Aminagar Sarai"
    },
    {
      "id": 4990,
      "CityName": "Kallakudi",
      "CityValue": "Kallakudi"
    },
    {
      "id": 4991,
      "CityName": "Nawagarh",
      "CityValue": "Nawagarh"
    },
    {
      "id": 4992,
      "CityName": "Narayanavanam",
      "CityValue": "Narayanavanam"
    },
    {
      "id": 4993,
      "CityName": "Amanpur",
      "CityValue": "Amanpur"
    },
    {
      "id": 4994,
      "CityName": "Sakhanu",
      "CityValue": "Sakhanu"
    },
    {
      "id": 4995,
      "CityName": "Bhatgaon",
      "CityValue": "Bhatgaon"
    },
    {
      "id": 4996,
      "CityName": "Kavaratti",
      "CityValue": "Kavaratti"
    },
    {
      "id": 4997,
      "CityName": "Garhi Pukhta",
      "CityValue": "Garhi Pukhta"
    },
    {
      "id": 4998,
      "CityName": "Diphupar 'A'",
      "CityValue": "Diphupar 'A'"
    },
    {
      "id": 4999,
      "CityName": "Ailam",
      "CityValue": "Ailam"
    },
    {
      "id": 5000,
      "CityName": "Vadakkumbhagom",
      "CityValue": "Vadakkumbhagom"
    },
    {
      "id": 5001,
      "CityName": "Ghughuli",
      "CityValue": "Ghughuli"
    },
    {
      "id": 5002,
      "CityName": "Anjur",
      "CityValue": "Anjur"
    },
    {
      "id": 5003,
      "CityName": "Bishna",
      "CityValue": "Bishna"
    },
    {
      "id": 5004,
      "CityName": "Chilla Saroda Khadar",
      "CityValue": "Chilla Saroda Khadar"
    },
    {
      "id": 5005,
      "CityName": "Kangrali",
      "CityValue": "Kangrali"
    },
    {
      "id": 5006,
      "CityName": "Punjai Thottakurichi",
      "CityValue": "Punjai Thottakurichi"
    },
    {
      "id": 5007,
      "CityName": "Bhaderwah",
      "CityValue": "Bhaderwah"
    },
    {
      "id": 5008,
      "CityName": "Dumarkunda",
      "CityValue": "Dumarkunda"
    },
    {
      "id": 5009,
      "CityName": "T.Kallupatti",
      "CityValue": "T.Kallupatti"
    },
    {
      "id": 5010,
      "CityName": "Khujner",
      "CityValue": "Khujner"
    },
    {
      "id": 5011,
      "CityName": "Kathaura",
      "CityValue": "Kathaura"
    },
    {
      "id": 5012,
      "CityName": "Tiorkhali",
      "CityValue": "Tiorkhali"
    },
    {
      "id": 5013,
      "CityName": "Singhana",
      "CityValue": "Singhana"
    },
    {
      "id": 5014,
      "CityName": "Acharapakkam",
      "CityValue": "Acharapakkam"
    },
    {
      "id": 5015,
      "CityName": "Uttar Kusum",
      "CityValue": "Uttar Kusum"
    },
    {
      "id": 5016,
      "CityName": "Manjanady",
      "CityValue": "Manjanady"
    },
    {
      "id": 5017,
      "CityName": "Purana Dumka",
      "CityValue": "Purana Dumka"
    },
    {
      "id": 5018,
      "CityName": "Champua",
      "CityValue": "Champua"
    },
    {
      "id": 5019,
      "CityName": "Thirumalairayanpattinam",
      "CityValue": "Thirumalairayanpattinam"
    },
    {
      "id": 5020,
      "CityName": "Thiruppanandal",
      "CityValue": "Thiruppanandal"
    },
    {
      "id": 5021,
      "CityName": "Buria",
      "CityValue": "Buria"
    },
    {
      "id": 5022,
      "CityName": "Teegalpahad",
      "CityValue": "Teegalpahad"
    },
    {
      "id": 5023,
      "CityName": "Kottaram",
      "CityValue": "Kottaram"
    },
    {
      "id": 5024,
      "CityName": "Beriyawali",
      "CityValue": "Beriyawali"
    },
    {
      "id": 5025,
      "CityName": "Faizabad",
      "CityValue": "Faizabad"
    },
    {
      "id": 5026,
      "CityName": "Neikkarapatti",
      "CityValue": "Neikkarapatti"
    },
    {
      "id": 5027,
      "CityName": "Bamhni",
      "CityValue": "Bamhni"
    },
    {
      "id": 5028,
      "CityName": "Nadathara",
      "CityValue": "Nadathara"
    },
    {
      "id": 5029,
      "CityName": "Velankanni",
      "CityValue": "Velankanni"
    },
    {
      "id": 5030,
      "CityName": "Maktampur",
      "CityValue": "Maktampur"
    },
    {
      "id": 5031,
      "CityName": "Kozhenchery",
      "CityValue": "Kozhenchery"
    },
    {
      "id": 5032,
      "CityName": "Sriramapuram",
      "CityValue": "Sriramapuram"
    },
    {
      "id": 5033,
      "CityName": "Kamalapuram",
      "CityValue": "Kamalapuram"
    },
    {
      "id": 5034,
      "CityName": "Nasiyanur",
      "CityValue": "Nasiyanur"
    },
    {
      "id": 5035,
      "CityName": "Kembainaickenpalayam",
      "CityValue": "Kembainaickenpalayam"
    },
    {
      "id": 5036,
      "CityName": "Kathanian",
      "CityValue": "Kathanian"
    },
    {
      "id": 5037,
      "CityName": "Nagram",
      "CityValue": "Nagram"
    },
    {
      "id": 5038,
      "CityName": "Thongju",
      "CityValue": "Thongju"
    },
    {
      "id": 5039,
      "CityName": "Maslandapur",
      "CityValue": "Maslandapur"
    },
    {
      "id": 5040,
      "CityName": "Karrapur",
      "CityValue": "Karrapur"
    },
    {
      "id": 5041,
      "CityName": "Chakghat",
      "CityValue": "Chakghat"
    },
    {
      "id": 5042,
      "CityName": "Paddhari",
      "CityValue": "Paddhari"
    },
    {
      "id": 5043,
      "CityName": "Kurattissery",
      "CityValue": "Kurattissery"
    },
    {
      "id": 5044,
      "CityName": "Mandalgarh",
      "CityValue": "Mandalgarh"
    },
    {
      "id": 5045,
      "CityName": "Nrisinghapur",
      "CityValue": "Nrisinghapur"
    },
    {
      "id": 5046,
      "CityName": "Chikrand",
      "CityValue": "Chikrand"
    },
    {
      "id": 5047,
      "CityName": "Kanad",
      "CityValue": "Kanad"
    },
    {
      "id": 5048,
      "CityName": "Uthukuli",
      "CityValue": "Uthukuli"
    },
    {
      "id": 5049,
      "CityName": "Bichhiya",
      "CityValue": "Bichhiya"
    },
    {
      "id": 5050,
      "CityName": "Hatod",
      "CityValue": "Hatod"
    },
    {
      "id": 5051,
      "CityName": "Sonatala",
      "CityValue": "Sonatala"
    },
    {
      "id": 5052,
      "CityName": "Pakhanjur",
      "CityValue": "Pakhanjur"
    },
    {
      "id": 5053,
      "CityName": "Talen",
      "CityValue": "Talen"
    },
    {
      "id": 5054,
      "CityName": "Alampur",
      "CityValue": "Alampur"
    },
    {
      "id": 5055,
      "CityName": "Birpara",
      "CityValue": "Birpara"
    },
    {
      "id": 5056,
      "CityName": "Haludpukhur",
      "CityValue": "Haludpukhur"
    },
    {
      "id": 5057,
      "CityName": "Nangavalli",
      "CityValue": "Nangavalli"
    },
    {
      "id": 5058,
      "CityName": "Kanjikoil",
      "CityValue": "Kanjikoil"
    },
    {
      "id": 5059,
      "CityName": "Lamjaotongba",
      "CityValue": "Lamjaotongba"
    },
    {
      "id": 5060,
      "CityName": "Khargupur",
      "CityValue": "Khargupur"
    },
    {
      "id": 5061,
      "CityName": "Lohian Khass",
      "CityValue": "Lohian Khass"
    },
    {
      "id": 5062,
      "CityName": "Kari",
      "CityValue": "Kari"
    },
    {
      "id": 5063,
      "CityName": "Bethamangala",
      "CityValue": "Bethamangala"
    },
    {
      "id": 5064,
      "CityName": "Ethapur",
      "CityValue": "Ethapur"
    },
    {
      "id": 5065,
      "CityName": "Ghagga",
      "CityValue": "Ghagga"
    },
    {
      "id": 5066,
      "CityName": "Osmanpur",
      "CityValue": "Osmanpur"
    },
    {
      "id": 5067,
      "CityName": "Sultanpur",
      "CityValue": "Sultanpur"
    },
    {
      "id": 5068,
      "CityName": "Amgaon bk.",
      "CityValue": "Amgaon bk."
    },
    {
      "id": 5069,
      "CityName": "Kulia",
      "CityValue": "Kulia"
    },
    {
      "id": 5070,
      "CityName": "Argari",
      "CityValue": "Argari"
    },
    {
      "id": 5071,
      "CityName": "Andrott",
      "CityValue": "Andrott"
    },
    {
      "id": 5072,
      "CityName": "Nalagarh",
      "CityValue": "Nalagarh"
    },
    {
      "id": 5073,
      "CityName": "Mahuvar",
      "CityValue": "Mahuvar"
    },
    {
      "id": 5074,
      "CityName": "Bowali",
      "CityValue": "Bowali"
    },
    {
      "id": 5075,
      "CityName": "Bandarulanka",
      "CityValue": "Bandarulanka"
    },
    {
      "id": 5076,
      "CityName": "Ibrahimpatnam",
      "CityValue": "Ibrahimpatnam"
    },
    {
      "id": 5077,
      "CityName": "Kshidirpur",
      "CityValue": "Kshidirpur"
    },
    {
      "id": 5078,
      "CityName": "Badoni",
      "CityValue": "Badoni"
    },
    {
      "id": 5079,
      "CityName": "Nanakwada",
      "CityValue": "Nanakwada"
    },
    {
      "id": 5080,
      "CityName": "Rayagiri",
      "CityValue": "Rayagiri"
    },
    {
      "id": 5081,
      "CityName": "Luxettipet",
      "CityValue": "Luxettipet"
    },
    {
      "id": 5082,
      "CityName": "Kothri",
      "CityValue": "Kothri"
    },
    {
      "id": 5083,
      "CityName": "Sao Jose de Areal",
      "CityValue": "Sao Jose de Areal"
    },
    {
      "id": 5084,
      "CityName": "Samba",
      "CityValue": "Samba"
    },
    {
      "id": 5085,
      "CityName": "Amraudha",
      "CityValue": "Amraudha"
    },
    {
      "id": 5086,
      "CityName": "Marudur",
      "CityValue": "Marudur"
    },
    {
      "id": 5087,
      "CityName": "Poomangalam",
      "CityValue": "Poomangalam"
    },
    {
      "id": 5088,
      "CityName": "Naigarhi",
      "CityValue": "Naigarhi"
    },
    {
      "id": 5089,
      "CityName": "8 PSD-B",
      "CityValue": "8 PSD-B"
    },
    {
      "id": 5090,
      "CityName": "Hanumanthampatti",
      "CityValue": "Hanumanthampatti"
    },
    {
      "id": 5091,
      "CityName": "Kampil",
      "CityValue": "Kampil"
    },
    {
      "id": 5092,
      "CityName": "Karnawal",
      "CityValue": "Karnawal"
    },
    {
      "id": 5093,
      "CityName": "Alhoran",
      "CityValue": "Alhoran"
    },
    {
      "id": 5094,
      "CityName": "Madhavnagar",
      "CityValue": "Madhavnagar"
    },
    {
      "id": 5095,
      "CityName": "Basna",
      "CityValue": "Basna"
    },
    {
      "id": 5096,
      "CityName": "Palsud",
      "CityValue": "Palsud"
    },
    {
      "id": 5097,
      "CityName": "Devsar",
      "CityValue": "Devsar"
    },
    {
      "id": 5098,
      "CityName": "Appakudal",
      "CityValue": "Appakudal"
    },
    {
      "id": 5099,
      "CityName": "Fatehpur",
      "CityValue": "Fatehpur"
    },
    {
      "id": 5100,
      "CityName": "Kalappanaickenpatti",
      "CityValue": "Kalappanaickenpatti"
    },
    {
      "id": 5101,
      "CityName": "Manavalakurichi",
      "CityValue": "Manavalakurichi"
    },
    {
      "id": 5102,
      "CityName": "Sirugamani",
      "CityValue": "Sirugamani"
    },
    {
      "id": 5103,
      "CityName": "Siolim",
      "CityValue": "Siolim"
    },
    {
      "id": 5104,
      "CityName": "Kamalabaria N.C.",
      "CityValue": "Kamalabaria N.C."
    },
    {
      "id": 5105,
      "CityName": "Natrampalli",
      "CityValue": "Natrampalli"
    },
    {
      "id": 5106,
      "CityName": "Cheleri",
      "CityValue": "Cheleri"
    },
    {
      "id": 5107,
      "CityName": "Bishrampur",
      "CityValue": "Bishrampur"
    },
    {
      "id": 5108,
      "CityName": "Sirakol",
      "CityValue": "Sirakol"
    },
    {
      "id": 5109,
      "CityName": "Shekhpura",
      "CityValue": "Shekhpura"
    },
    {
      "id": 5110,
      "CityName": "Digboi Oil Town",
      "CityValue": "Digboi Oil Town"
    },
    {
      "id": 5111,
      "CityName": "Khawzawl",
      "CityValue": "Khawzawl"
    },
    {
      "id": 5112,
      "CityName": "Kanyanagar",
      "CityValue": "Kanyanagar"
    },
    {
      "id": 5113,
      "CityName": "Chiramanangad",
      "CityValue": "Chiramanangad"
    },
    {
      "id": 5114,
      "CityName": "Titron",
      "CityValue": "Titron"
    },
    {
      "id": 5115,
      "CityName": "Virpur",
      "CityValue": "Virpur"
    },
    {
      "id": 5116,
      "CityName": "Karidhya",
      "CityValue": "Karidhya"
    },
    {
      "id": 5117,
      "CityName": "Poolambadi",
      "CityValue": "Poolambadi"
    },
    {
      "id": 5118,
      "CityName": "Huligal",
      "CityValue": "Huligal"
    },
    {
      "id": 5119,
      "CityName": "Raj Pur Khurd",
      "CityValue": "Raj Pur Khurd"
    },
    {
      "id": 5120,
      "CityName": "Rajgamar",
      "CityValue": "Rajgamar"
    },
    {
      "id": 5121,
      "CityName": "Maholiya",
      "CityValue": "Maholiya"
    },
    {
      "id": 5122,
      "CityName": "Partapur",
      "CityValue": "Partapur"
    },
    {
      "id": 5123,
      "CityName": "Kizhakkumbhagom",
      "CityValue": "Kizhakkumbhagom"
    },
    {
      "id": 5124,
      "CityName": "Puranattukara",
      "CityValue": "Puranattukara"
    },
    {
      "id": 5125,
      "CityName": "Venkitangu",
      "CityValue": "Venkitangu"
    },
    {
      "id": 5126,
      "CityName": "Kayatharu",
      "CityValue": "Kayatharu"
    },
    {
      "id": 5127,
      "CityName": "Amballur",
      "CityValue": "Amballur"
    },
    {
      "id": 5128,
      "CityName": "Vadakkumkara",
      "CityValue": "Vadakkumkara"
    },
    {
      "id": 5129,
      "CityName": "Eddumailaram",
      "CityValue": "Eddumailaram"
    },
    {
      "id": 5130,
      "CityName": "Umrangso",
      "CityValue": "Umrangso"
    },
    {
      "id": 5131,
      "CityName": "Baliapur",
      "CityValue": "Baliapur"
    },
    {
      "id": 5132,
      "CityName": "Betul-Bazar",
      "CityValue": "Betul-Bazar"
    },
    {
      "id": 5133,
      "CityName": "Chapakhowa Town",
      "CityValue": "Chapakhowa Town"
    },
    {
      "id": 5134,
      "CityName": "Maidan Garhi",
      "CityValue": "Maidan Garhi"
    },
    {
      "id": 5135,
      "CityName": "Kharod",
      "CityValue": "Kharod"
    },
    {
      "id": 5136,
      "CityName": "Lakhna",
      "CityValue": "Lakhna"
    },
    {
      "id": 5137,
      "CityName": "Raghabpur",
      "CityValue": "Raghabpur"
    },
    {
      "id": 5138,
      "CityName": "Goredi Chancha",
      "CityValue": "Goredi Chancha"
    },
    {
      "id": 5139,
      "CityName": "Itamati",
      "CityValue": "Itamati"
    },
    {
      "id": 5140,
      "CityName": "Awagarh",
      "CityValue": "Awagarh"
    },
    {
      "id": 5141,
      "CityName": "Chhachhrauli",
      "CityValue": "Chhachhrauli"
    },
    {
      "id": 5142,
      "CityName": "Ramchandrapur",
      "CityValue": "Ramchandrapur"
    },
    {
      "id": 5143,
      "CityName": "Islampur",
      "CityValue": "Islampur"
    },
    {
      "id": 5144,
      "CityName": "Kantaphod",
      "CityValue": "Kantaphod"
    },
    {
      "id": 5145,
      "CityName": "Gopalasamudram",
      "CityValue": "Gopalasamudram"
    },
    {
      "id": 5146,
      "CityName": "Gariyaband",
      "CityValue": "Gariyaband"
    },
    {
      "id": 5147,
      "CityName": "Sankaramanallur",
      "CityValue": "Sankaramanallur"
    },
    {
      "id": 5148,
      "CityName": "Vellamcode",
      "CityValue": "Vellamcode"
    },
    {
      "id": 5149,
      "CityName": "Peraiyur",
      "CityValue": "Peraiyur"
    },
    {
      "id": 5150,
      "CityName": "Azhagiapandipuram",
      "CityValue": "Azhagiapandipuram"
    },
    {
      "id": 5151,
      "CityName": "Kalambe T. Thane",
      "CityValue": "Kalambe T. Thane"
    },
    {
      "id": 5152,
      "CityName": "Krishnarayapuram",
      "CityValue": "Krishnarayapuram"
    },
    {
      "id": 5153,
      "CityName": "Dimaruguri",
      "CityValue": "Dimaruguri"
    },
    {
      "id": 5154,
      "CityName": "Dhakuria",
      "CityValue": "Dhakuria"
    },
    {
      "id": 5155,
      "CityName": "Bharveli",
      "CityValue": "Bharveli"
    },
    {
      "id": 5156,
      "CityName": "Bokakhat",
      "CityValue": "Bokakhat"
    },
    {
      "id": 5157,
      "CityName": "Dudhani",
      "CityValue": "Dudhani"
    },
    {
      "id": 5158,
      "CityName": "Babai",
      "CityValue": "Babai"
    },
    {
      "id": 5159,
      "CityName": "Bastar",
      "CityValue": "Bastar"
    },
    {
      "id": 5160,
      "CityName": "Govindgarh",
      "CityValue": "Govindgarh"
    },
    {
      "id": 5161,
      "CityName": "Begampur",
      "CityValue": "Begampur"
    },
    {
      "id": 5162,
      "CityName": "Amarpur",
      "CityValue": "Amarpur"
    },
    {
      "id": 5163,
      "CityName": "Guntupalle",
      "CityValue": "Guntupalle"
    },
    {
      "id": 5164,
      "CityName": "Kattuputhur",
      "CityValue": "Kattuputhur"
    },
    {
      "id": 5165,
      "CityName": "Gavaravaram",
      "CityValue": "Gavaravaram"
    },
    {
      "id": 5166,
      "CityName": "Kushalgarh",
      "CityValue": "Kushalgarh"
    },
    {
      "id": 5167,
      "CityName": "Minicoy",
      "CityValue": "Minicoy"
    },
    {
      "id": 5168,
      "CityName": "Sivagnanapuram",
      "CityValue": "Sivagnanapuram"
    },
    {
      "id": 5169,
      "CityName": "Eraniel",
      "CityValue": "Eraniel"
    },
    {
      "id": 5170,
      "CityName": "Jaypur",
      "CityValue": "Jaypur"
    },
    {
      "id": 5171,
      "CityName": "Khamanon",
      "CityValue": "Khamanon"
    },
    {
      "id": 5172,
      "CityName": "Pandharpur",
      "CityValue": "Pandharpur"
    },
    {
      "id": 5173,
      "CityName": "Sevur",
      "CityValue": "Sevur"
    },
    {
      "id": 5174,
      "CityName": "Koteshwar",
      "CityValue": "Koteshwar"
    },
    {
      "id": 5175,
      "CityName": "Buxwaha",
      "CityValue": "Buxwaha"
    },
    {
      "id": 5176,
      "CityName": "Patan",
      "CityValue": "Patan"
    },
    {
      "id": 5177,
      "CityName": "New Mahe",
      "CityValue": "New Mahe"
    },
    {
      "id": 5178,
      "CityName": "Chandur",
      "CityValue": "Chandur"
    },
    {
      "id": 5179,
      "CityName": "Udpura",
      "CityValue": "Udpura"
    },
    {
      "id": 5180,
      "CityName": "Gangnapur",
      "CityValue": "Gangnapur"
    },
    {
      "id": 5181,
      "CityName": "Padaiveedu",
      "CityValue": "Padaiveedu"
    },
    {
      "id": 5182,
      "CityName": "Nasra",
      "CityValue": "Nasra"
    },
    {
      "id": 5183,
      "CityName": "Kamalnagar",
      "CityValue": "Kamalnagar"
    },
    {
      "id": 5184,
      "CityName": "Baikunthpur",
      "CityValue": "Baikunthpur"
    },
    {
      "id": 5185,
      "CityName": "Bhulath",
      "CityValue": "Bhulath"
    },
    {
      "id": 5186,
      "CityName": "Mallur",
      "CityValue": "Mallur"
    },
    {
      "id": 5187,
      "CityName": "Lakhipur",
      "CityValue": "Lakhipur"
    },
    {
      "id": 5188,
      "CityName": "Keeripatti",
      "CityValue": "Keeripatti"
    },
    {
      "id": 5189,
      "CityName": "Khariya",
      "CityValue": "Khariya"
    },
    {
      "id": 5190,
      "CityName": "Manimutharu",
      "CityValue": "Manimutharu"
    },
    {
      "id": 5191,
      "CityName": "Ilanji",
      "CityValue": "Ilanji"
    },
    {
      "id": 5192,
      "CityName": "Bhawan Bahadur Nagar",
      "CityValue": "Bhawan Bahadur Nagar"
    },
    {
      "id": 5193,
      "CityName": "Dhamua",
      "CityValue": "Dhamua"
    },
    {
      "id": 5194,
      "CityName": "Raniyan",
      "CityValue": "Raniyan"
    },
    {
      "id": 5195,
      "CityName": "Mandu",
      "CityValue": "Mandu"
    },
    {
      "id": 5196,
      "CityName": "Rajur",
      "CityValue": "Rajur"
    },
    {
      "id": 5197,
      "CityName": "Bamhani",
      "CityValue": "Bamhani"
    },
    {
      "id": 5198,
      "CityName": "Thekkumbhagom",
      "CityValue": "Thekkumbhagom"
    },
    {
      "id": 5199,
      "CityName": "Eranellur",
      "CityValue": "Eranellur"
    },
    {
      "id": 5200,
      "CityName": "Pooth Khurd",
      "CityValue": "Pooth Khurd"
    },
    {
      "id": 5201,
      "CityName": "Satrod Khas",
      "CityValue": "Satrod Khas"
    },
    {
      "id": 5202,
      "CityName": "Jona Pur",
      "CityValue": "Jona Pur"
    },
    {
      "id": 5203,
      "CityName": "Mylaudy",
      "CityValue": "Mylaudy"
    },
    {
      "id": 5204,
      "CityName": "Mundera Bazar",
      "CityValue": "Mundera Bazar"
    },
    {
      "id": 5205,
      "CityName": "Ibrahim Pur",
      "CityValue": "Ibrahim Pur"
    },
    {
      "id": 5206,
      "CityName": "Kadannappalli",
      "CityValue": "Kadannappalli"
    },
    {
      "id": 5207,
      "CityName": "Chhatral",
      "CityValue": "Chhatral"
    },
    {
      "id": 5208,
      "CityName": "Khaira Bari",
      "CityValue": "Khaira Bari"
    },
    {
      "id": 5209,
      "CityName": "Barwaha Kasba",
      "CityValue": "Barwaha Kasba"
    },
    {
      "id": 5210,
      "CityName": "Athur",
      "CityValue": "Athur"
    },
    {
      "id": 5211,
      "CityName": "Keerikkad",
      "CityValue": "Keerikkad"
    },
    {
      "id": 5212,
      "CityName": "Ranapurgada",
      "CityValue": "Ranapurgada"
    },
    {
      "id": 5213,
      "CityName": "Hubbathala",
      "CityValue": "Hubbathala"
    },
    {
      "id": 5214,
      "CityName": "Lilia",
      "CityValue": "Lilia"
    },
    {
      "id": 5215,
      "CityName": "Khand",
      "CityValue": "Khand"
    },
    {
      "id": 5216,
      "CityName": "Beniganj",
      "CityValue": "Beniganj"
    },
    {
      "id": 5217,
      "CityName": "Poyya",
      "CityValue": "Poyya"
    },
    {
      "id": 5218,
      "CityName": "Bhainsdehi",
      "CityValue": "Bhainsdehi"
    },
    {
      "id": 5219,
      "CityName": "Ala",
      "CityValue": "Ala"
    },
    {
      "id": 5220,
      "CityName": "Rajur",
      "CityValue": "Rajur"
    },
    {
      "id": 5221,
      "CityName": "Bagli",
      "CityValue": "Bagli"
    },
    {
      "id": 5222,
      "CityName": "Vylathur",
      "CityValue": "Vylathur"
    },
    {
      "id": 5223,
      "CityName": "Begowal",
      "CityValue": "Begowal"
    },
    {
      "id": 5224,
      "CityName": "Kannivadi",
      "CityValue": "Kannivadi"
    },
    {
      "id": 5225,
      "CityName": "Orathanadu",
      "CityValue": "Orathanadu"
    },
    {
      "id": 5226,
      "CityName": "Kanjhawala",
      "CityValue": "Kanjhawala"
    },
    {
      "id": 5227,
      "CityName": "Alwarkurichi",
      "CityValue": "Alwarkurichi"
    },
    {
      "id": 5228,
      "CityName": "Narayangarh",
      "CityValue": "Narayangarh"
    },
    {
      "id": 5229,
      "CityName": "Banjetia",
      "CityValue": "Banjetia"
    },
    {
      "id": 5230,
      "CityName": "Kasba",
      "CityValue": "Kasba"
    },
    {
      "id": 5231,
      "CityName": "Saidabad",
      "CityValue": "Saidabad"
    },
    {
      "id": 5232,
      "CityName": "Jasrana",
      "CityValue": "Jasrana"
    },
    {
      "id": 5233,
      "CityName": "Jog Kargal",
      "CityValue": "Jog Kargal"
    },
    {
      "id": 5234,
      "CityName": "Sashpur",
      "CityValue": "Sashpur"
    },
    {
      "id": 5235,
      "CityName": "Pfutsero",
      "CityValue": "Pfutsero"
    },
    {
      "id": 5236,
      "CityName": "Anandnagar",
      "CityValue": "Anandnagar"
    },
    {
      "id": 5237,
      "CityName": "Ramannapeta",
      "CityValue": "Ramannapeta"
    },
    {
      "id": 5238,
      "CityName": "Kargahia Purab",
      "CityValue": "Kargahia Purab"
    },
    {
      "id": 5239,
      "CityName": "Gajsinghpur",
      "CityValue": "Gajsinghpur"
    },
    {
      "id": 5240,
      "CityName": "Dalmau",
      "CityValue": "Dalmau"
    },
    {
      "id": 5241,
      "CityName": "Tirumakudal Narsipur",
      "CityValue": "Tirumakudal Narsipur"
    },
    {
      "id": 5242,
      "CityName": "Chichli",
      "CityValue": "Chichli"
    },
    {
      "id": 5243,
      "CityName": "Bhakali",
      "CityValue": "Bhakali"
    },
    {
      "id": 5244,
      "CityName": "Debipur",
      "CityValue": "Debipur"
    },
    {
      "id": 5245,
      "CityName": "Dhamdha",
      "CityValue": "Dhamdha"
    },
    {
      "id": 5246,
      "CityName": "Olagadam",
      "CityValue": "Olagadam"
    },
    {
      "id": 5247,
      "CityName": "Nagojanahalli",
      "CityValue": "Nagojanahalli"
    },
    {
      "id": 5248,
      "CityName": "Rampachodavaram",
      "CityValue": "Rampachodavaram"
    },
    {
      "id": 5249,
      "CityName": "Dhatrigram",
      "CityValue": "Dhatrigram"
    },
    {
      "id": 5250,
      "CityName": "Lodhikheda",
      "CityValue": "Lodhikheda"
    },
    {
      "id": 5251,
      "CityName": "Shrirampur",
      "CityValue": "Shrirampur"
    },
    {
      "id": 5252,
      "CityName": "Antari",
      "CityValue": "Antari"
    },
    {
      "id": 5253,
      "CityName": "Rampur Karkhana",
      "CityValue": "Rampur Karkhana"
    },
    {
      "id": 5254,
      "CityName": "Suthana Barsola",
      "CityValue": "Suthana Barsola"
    },
    {
      "id": 5255,
      "CityName": "Gadhi",
      "CityValue": "Gadhi"
    },
    {
      "id": 5256,
      "CityName": "Sarupathar",
      "CityValue": "Sarupathar"
    },
    {
      "id": 5257,
      "CityName": "Allipura",
      "CityValue": "Allipura"
    },
    {
      "id": 5258,
      "CityName": "Khonsa",
      "CityValue": "Khonsa"
    },
    {
      "id": 5259,
      "CityName": "Panposh",
      "CityValue": "Panposh"
    },
    {
      "id": 5260,
      "CityName": "Kozhukkully",
      "CityValue": "Kozhukkully"
    },
    {
      "id": 5261,
      "CityName": "Kahi Kuchi",
      "CityValue": "Kahi Kuchi"
    },
    {
      "id": 5262,
      "CityName": "Bidadi",
      "CityValue": "Bidadi"
    },
    {
      "id": 5263,
      "CityName": "Freelandgunj",
      "CityValue": "Freelandgunj"
    },
    {
      "id": 5264,
      "CityName": "Puthukkadai",
      "CityValue": "Puthukkadai"
    },
    {
      "id": 5265,
      "CityName": "Mohgaon",
      "CityValue": "Mohgaon"
    },
    {
      "id": 5266,
      "CityName": "Kondumal",
      "CityValue": "Kondumal"
    },
    {
      "id": 5267,
      "CityName": "Sahapur",
      "CityValue": "Sahapur"
    },
    {
      "id": 5268,
      "CityName": "Modakurichi",
      "CityValue": "Modakurichi"
    },
    {
      "id": 5269,
      "CityName": "Tangdhar",
      "CityValue": "Tangdhar"
    },
    {
      "id": 5270,
      "CityName": "Pullampadi",
      "CityValue": "Pullampadi"
    },
    {
      "id": 5271,
      "CityName": "Badkuhi",
      "CityValue": "Badkuhi"
    },
    {
      "id": 5272,
      "CityName": "Ambagarh Chowki",
      "CityValue": "Ambagarh Chowki"
    },
    {
      "id": 5273,
      "CityName": "Amritsar Cantt.",
      "CityValue": "Amritsar Cantt."
    },
    {
      "id": 5274,
      "CityName": "Boda",
      "CityValue": "Boda"
    },
    {
      "id": 5275,
      "CityName": "Sultanpur",
      "CityValue": "Sultanpur"
    },
    {
      "id": 5276,
      "CityName": "Kurthaur",
      "CityValue": "Kurthaur"
    },
    {
      "id": 5277,
      "CityName": "Qazi Gund",
      "CityValue": "Qazi Gund"
    },
    {
      "id": 5278,
      "CityName": "Avadattur",
      "CityValue": "Avadattur"
    },
    {
      "id": 5279,
      "CityName": "Mamrejpur",
      "CityValue": "Mamrejpur"
    },
    {
      "id": 5280,
      "CityName": "Khrew",
      "CityValue": "Khrew"
    },
    {
      "id": 5281,
      "CityName": "Achipatti",
      "CityValue": "Achipatti"
    },
    {
      "id": 5282,
      "CityName": "Edathirinji",
      "CityValue": "Edathirinji"
    },
    {
      "id": 5283,
      "CityName": "Satigachha",
      "CityValue": "Satigachha"
    },
    {
      "id": 5284,
      "CityName": "Bhuragaon",
      "CityValue": "Bhuragaon"
    },
    {
      "id": 5285,
      "CityName": "Neuton Chikhli Kalan",
      "CityValue": "Neuton Chikhli Kalan"
    },
    {
      "id": 5286,
      "CityName": "Ordnance Factory Khamaria",
      "CityValue": "Ordnance Factory Khamaria"
    },
    {
      "id": 5287,
      "CityName": "Shahjahanpur",
      "CityValue": "Shahjahanpur"
    },
    {
      "id": 5288,
      "CityName": "Sujavalpur",
      "CityValue": "Sujavalpur"
    },
    {
      "id": 5289,
      "CityName": "Athanur",
      "CityValue": "Athanur"
    },
    {
      "id": 5290,
      "CityName": "Anthicad",
      "CityValue": "Anthicad"
    },
    {
      "id": 5291,
      "CityName": "Elwala",
      "CityValue": "Elwala"
    },
    {
      "id": 5292,
      "CityName": "Chail",
      "CityValue": "Chail"
    },
    {
      "id": 5293,
      "CityName": "Jetalsar",
      "CityValue": "Jetalsar"
    },
    {
      "id": 5294,
      "CityName": "Bakani",
      "CityValue": "Bakani"
    },
    {
      "id": 5295,
      "CityName": "Golaghatia Basti",
      "CityValue": "Golaghatia Basti"
    },
    {
      "id": 5296,
      "CityName": "Dhanegaon",
      "CityValue": "Dhanegaon"
    },
    {
      "id": 5297,
      "CityName": "Alur",
      "CityValue": "Alur"
    },
    {
      "id": 5298,
      "CityName": "Nurpur",
      "CityValue": "Nurpur"
    },
    {
      "id": 5299,
      "CityName": "Jhundpura",
      "CityValue": "Jhundpura"
    },
    {
      "id": 5300,
      "CityName": "Padampur Sukhran",
      "CityValue": "Padampur Sukhran"
    },
    {
      "id": 5301,
      "CityName": "Devikapuram",
      "CityValue": "Devikapuram"
    },
    {
      "id": 5302,
      "CityName": "Kashimnagar",
      "CityValue": "Kashimnagar"
    },
    {
      "id": 5303,
      "CityName": "Chiranellur",
      "CityValue": "Chiranellur"
    },
    {
      "id": 5304,
      "CityName": "Boriguma",
      "CityValue": "Boriguma"
    },
    {
      "id": 5305,
      "CityName": "Dhunki",
      "CityValue": "Dhunki"
    },
    {
      "id": 5306,
      "CityName": "Herbertpur",
      "CityValue": "Herbertpur"
    },
    {
      "id": 5307,
      "CityName": "Khera Khurd",
      "CityValue": "Khera Khurd"
    },
    {
      "id": 5308,
      "CityName": "Muddanur",
      "CityValue": "Muddanur"
    },
    {
      "id": 5309,
      "CityName": "Namli",
      "CityValue": "Namli"
    },
    {
      "id": 5310,
      "CityName": "Kalavai",
      "CityValue": "Kalavai"
    },
    {
      "id": 5311,
      "CityName": "Wajegaon",
      "CityValue": "Wajegaon"
    },
    {
      "id": 5312,
      "CityName": "Gohpur",
      "CityValue": "Gohpur"
    },
    {
      "id": 5313,
      "CityName": "Devsar",
      "CityValue": "Devsar"
    },
    {
      "id": 5314,
      "CityName": "Gobindapur",
      "CityValue": "Gobindapur"
    },
    {
      "id": 5315,
      "CityName": "Halwara",
      "CityValue": "Halwara"
    },
    {
      "id": 5316,
      "CityName": "Rajapur",
      "CityValue": "Rajapur"
    },
    {
      "id": 5317,
      "CityName": "Rajapur",
      "CityValue": "Rajapur"
    },
    {
      "id": 5318,
      "CityName": "Berhait Santali",
      "CityValue": "Berhait Santali"
    },
    {
      "id": 5319,
      "CityName": "Fingeshwar",
      "CityValue": "Fingeshwar"
    },
    {
      "id": 5320,
      "CityName": "Isri",
      "CityValue": "Isri"
    },
    {
      "id": 5321,
      "CityName": "Shantipur",
      "CityValue": "Shantipur"
    },
    {
      "id": 5322,
      "CityName": "Ghoda Dongri Ryt",
      "CityValue": "Ghoda Dongri Ryt"
    },
    {
      "id": 5323,
      "CityName": "Sholur",
      "CityValue": "Sholur"
    },
    {
      "id": 5324,
      "CityName": "Thanniyam",
      "CityValue": "Thanniyam"
    },
    {
      "id": 5325,
      "CityName": "Keekan",
      "CityValue": "Keekan"
    },
    {
      "id": 5326,
      "CityName": "Bandhbahal",
      "CityValue": "Bandhbahal"
    },
    {
      "id": 5327,
      "CityName": "Chiloda",
      "CityValue": "Chiloda"
    },
    {
      "id": 5328,
      "CityName": "Kakoshi",
      "CityValue": "Kakoshi"
    },
    {
      "id": 5329,
      "CityName": "Periapattinam",
      "CityValue": "Periapattinam"
    },
    {
      "id": 5330,
      "CityName": "Agastheeswaram",
      "CityValue": "Agastheeswaram"
    },
    {
      "id": 5331,
      "CityName": "Pipalsana Chaudhari",
      "CityValue": "Pipalsana Chaudhari"
    },
    {
      "id": 5332,
      "CityName": "Charama",
      "CityValue": "Charama"
    },
    {
      "id": 5333,
      "CityName": "Shivrinarayan",
      "CityValue": "Shivrinarayan"
    },
    {
      "id": 5334,
      "CityName": "Bajpe",
      "CityValue": "Bajpe"
    },
    {
      "id": 5335,
      "CityName": "Mohpada Alias Wasambe",
      "CityValue": "Mohpada Alias Wasambe"
    },
    {
      "id": 5336,
      "CityName": "Deohra",
      "CityValue": "Deohra"
    },
    {
      "id": 5337,
      "CityName": "Jaitwara",
      "CityValue": "Jaitwara"
    },
    {
      "id": 5338,
      "CityName": "Jafrabad",
      "CityValue": "Jafrabad"
    },
    {
      "id": 5339,
      "CityName": "Kolayat",
      "CityValue": "Kolayat"
    },
    {
      "id": 5340,
      "CityName": "Kashinagar",
      "CityValue": "Kashinagar"
    },
    {
      "id": 5341,
      "CityName": "Devapur",
      "CityValue": "Devapur"
    },
    {
      "id": 5342,
      "CityName": "Kondamallapalle",
      "CityValue": "Kondamallapalle"
    },
    {
      "id": 5343,
      "CityName": "Mhasla",
      "CityValue": "Mhasla"
    },
    {
      "id": 5344,
      "CityName": "Dhana",
      "CityValue": "Dhana"
    },
    {
      "id": 5345,
      "CityName": "Ammapettai",
      "CityValue": "Ammapettai"
    },
    {
      "id": 5346,
      "CityName": "Mudigere",
      "CityValue": "Mudigere"
    },
    {
      "id": 5347,
      "CityName": "Pilicode",
      "CityValue": "Pilicode"
    },
    {
      "id": 5348,
      "CityName": "Moovarasampettai",
      "CityValue": "Moovarasampettai"
    },
    {
      "id": 5349,
      "CityName": "Deulia",
      "CityValue": "Deulia"
    },
    {
      "id": 5350,
      "CityName": "Karimpur",
      "CityValue": "Karimpur"
    },
    {
      "id": 5351,
      "CityName": "Vadugapatti",
      "CityValue": "Vadugapatti"
    },
    {
      "id": 5352,
      "CityName": "Thallapalle",
      "CityValue": "Thallapalle"
    },
    {
      "id": 5353,
      "CityName": "Keolari",
      "CityValue": "Keolari"
    },
    {
      "id": 5354,
      "CityName": "Pipalrawan",
      "CityValue": "Pipalrawan"
    },
    {
      "id": 5355,
      "CityName": "Raghunathpur",
      "CityValue": "Raghunathpur"
    },
    {
      "id": 5356,
      "CityName": "Gosainganj",
      "CityValue": "Gosainganj"
    },
    {
      "id": 5357,
      "CityName": "Visrampuree",
      "CityValue": "Visrampuree"
    },
    {
      "id": 5358,
      "CityName": "Khalor",
      "CityValue": "Khalor"
    },
    {
      "id": 5359,
      "CityName": "Bhalukdubi",
      "CityValue": "Bhalukdubi"
    },
    {
      "id": 5360,
      "CityName": "Anjuna",
      "CityValue": "Anjuna"
    },
    {
      "id": 5361,
      "CityName": "Uttar Madarihat",
      "CityValue": "Uttar Madarihat"
    },
    {
      "id": 5362,
      "CityName": "Alagappapuram",
      "CityValue": "Alagappapuram"
    },
    {
      "id": 5363,
      "CityName": "Banupur",
      "CityValue": "Banupur"
    },
    {
      "id": 5364,
      "CityName": "Thiruvennainallur",
      "CityValue": "Thiruvennainallur"
    },
    {
      "id": 5365,
      "CityName": "Khojn Pur",
      "CityValue": "Khojn Pur"
    },
    {
      "id": 5366,
      "CityName": "Bajwa",
      "CityValue": "Bajwa"
    },
    {
      "id": 5367,
      "CityName": "Kolappalur",
      "CityValue": "Kolappalur"
    },
    {
      "id": 5368,
      "CityName": "Jaypur Bil",
      "CityValue": "Jaypur Bil"
    },
    {
      "id": 5369,
      "CityName": "Kaniyambadi",
      "CityValue": "Kaniyambadi"
    },
    {
      "id": 5370,
      "CityName": "Petua",
      "CityValue": "Petua"
    },
    {
      "id": 5371,
      "CityName": "Chikanpara",
      "CityValue": "Chikanpara"
    },
    {
      "id": 5372,
      "CityName": "Mechiabasti",
      "CityValue": "Mechiabasti"
    },
    {
      "id": 5373,
      "CityName": "Papparapatti",
      "CityValue": "Papparapatti"
    },
    {
      "id": 5374,
      "CityName": "Vadakkummuri",
      "CityValue": "Vadakkummuri"
    },
    {
      "id": 5375,
      "CityName": "Beharia",
      "CityValue": "Beharia"
    },
    {
      "id": 5376,
      "CityName": "Parbbatipur",
      "CityValue": "Parbbatipur"
    },
    {
      "id": 5377,
      "CityName": "Enkakkad",
      "CityValue": "Enkakkad"
    },
    {
      "id": 5378,
      "CityName": "Arsande",
      "CityValue": "Arsande"
    },
    {
      "id": 5379,
      "CityName": "Pallathur",
      "CityValue": "Pallathur"
    },
    {
      "id": 5380,
      "CityName": "Kadaparai",
      "CityValue": "Kadaparai"
    },
    {
      "id": 5381,
      "CityName": "Farakhpur",
      "CityValue": "Farakhpur"
    },
    {
      "id": 5382,
      "CityName": "Yairipok",
      "CityValue": "Yairipok"
    },
    {
      "id": 5383,
      "CityName": "Gawan",
      "CityValue": "Gawan"
    },
    {
      "id": 5384,
      "CityName": "Kantlia",
      "CityValue": "Kantlia"
    },
    {
      "id": 5385,
      "CityName": "Pratitnagar",
      "CityValue": "Pratitnagar"
    },
    {
      "id": 5386,
      "CityName": "Panrra",
      "CityValue": "Panrra"
    },
    {
      "id": 5387,
      "CityName": "Saunkh",
      "CityValue": "Saunkh"
    },
    {
      "id": 5388,
      "CityName": "Kavant",
      "CityValue": "Kavant"
    },
    {
      "id": 5389,
      "CityName": "Kumbi",
      "CityValue": "Kumbi"
    },
    {
      "id": 5390,
      "CityName": "Bhilakhedi",
      "CityValue": "Bhilakhedi"
    },
    {
      "id": 5391,
      "CityName": "Venkatachalapuram",
      "CityValue": "Venkatachalapuram"
    },
    {
      "id": 5392,
      "CityName": "Padikkasu vaithanpatti",
      "CityValue": "Padikkasu vaithanpatti"
    },
    {
      "id": 5393,
      "CityName": "Patra",
      "CityValue": "Patra"
    },
    {
      "id": 5394,
      "CityName": "Talapady",
      "CityValue": "Talapady"
    },
    {
      "id": 5395,
      "CityName": "Erich",
      "CityValue": "Erich"
    },
    {
      "id": 5396,
      "CityName": "Shekhpura",
      "CityValue": "Shekhpura"
    },
    {
      "id": 5397,
      "CityName": "Ramnagar",
      "CityValue": "Ramnagar"
    },
    {
      "id": 5398,
      "CityName": "Kangra",
      "CityValue": "Kangra"
    },
    {
      "id": 5399,
      "CityName": "Singoli",
      "CityValue": "Singoli"
    },
    {
      "id": 5400,
      "CityName": "Manbazar",
      "CityValue": "Manbazar"
    },
    {
      "id": 5401,
      "CityName": "Kanakpur Pt. II",
      "CityValue": "Kanakpur Pt. II"
    },
    {
      "id": 5402,
      "CityName": "Patala",
      "CityValue": "Patala"
    },
    {
      "id": 5403,
      "CityName": "Mithapur",
      "CityValue": "Mithapur"
    },
    {
      "id": 5404,
      "CityName": "Ottapparai",
      "CityValue": "Ottapparai"
    },
    {
      "id": 5405,
      "CityName": "Baua Kalan",
      "CityValue": "Baua Kalan"
    },
    {
      "id": 5406,
      "CityName": "Limbodi",
      "CityValue": "Limbodi"
    },
    {
      "id": 5407,
      "CityName": "Kawai",
      "CityValue": "Kawai"
    },
    {
      "id": 5408,
      "CityName": "Kendra Khottamdi",
      "CityValue": "Kendra Khottamdi"
    },
    {
      "id": 5409,
      "CityName": "Fatehganj Purvi",
      "CityValue": "Fatehganj Purvi"
    },
    {
      "id": 5410,
      "CityName": "Dumar Kachhar",
      "CityValue": "Dumar Kachhar"
    },
    {
      "id": 5411,
      "CityName": "Eral",
      "CityValue": "Eral"
    },
    {
      "id": 5412,
      "CityName": "Poolampatti",
      "CityValue": "Poolampatti"
    },
    {
      "id": 5413,
      "CityName": "Machalpur",
      "CityValue": "Machalpur"
    },
    {
      "id": 5414,
      "CityName": "Tapookra",
      "CityValue": "Tapookra"
    },
    {
      "id": 5415,
      "CityName": "Kachhla",
      "CityValue": "Kachhla"
    },
    {
      "id": 5416,
      "CityName": "Nari",
      "CityValue": "Nari"
    },
    {
      "id": 5417,
      "CityName": "Mankapur",
      "CityValue": "Mankapur"
    },
    {
      "id": 5418,
      "CityName": "Tikait Nagar",
      "CityValue": "Tikait Nagar"
    },
    {
      "id": 5419,
      "CityName": "Barwara Mazra",
      "CityValue": "Barwara Mazra"
    },
    {
      "id": 5420,
      "CityName": "Gharghoda",
      "CityValue": "Gharghoda"
    },
    {
      "id": 5421,
      "CityName": "Narsingi",
      "CityValue": "Narsingi"
    },
    {
      "id": 5422,
      "CityName": "Hafiz Ganj",
      "CityValue": "Hafiz Ganj"
    },
    {
      "id": 5423,
      "CityName": "Gudibanda",
      "CityValue": "Gudibanda"
    },
    {
      "id": 5424,
      "CityName": "Nehon",
      "CityValue": "Nehon"
    },
    {
      "id": 5425,
      "CityName": "Saidpur Khajuria",
      "CityValue": "Saidpur Khajuria"
    },
    {
      "id": 5426,
      "CityName": "Achhalda",
      "CityValue": "Achhalda"
    },
    {
      "id": 5427,
      "CityName": "Jeron Khalsa",
      "CityValue": "Jeron Khalsa"
    },
    {
      "id": 5428,
      "CityName": "Pennathur",
      "CityValue": "Pennathur"
    },
    {
      "id": 5429,
      "CityName": "Kakramari",
      "CityValue": "Kakramari"
    },
    {
      "id": 5430,
      "CityName": "Kailasagiri",
      "CityValue": "Kailasagiri"
    },
    {
      "id": 5431,
      "CityName": "Bijpur",
      "CityValue": "Bijpur"
    },
    {
      "id": 5432,
      "CityName": "Mahroni",
      "CityValue": "Mahroni"
    },
    {
      "id": 5433,
      "CityName": "Harharia Chak",
      "CityValue": "Harharia Chak"
    },
    {
      "id": 5434,
      "CityName": "Nagri Kalan",
      "CityValue": "Nagri Kalan"
    },
    {
      "id": 5435,
      "CityName": "Bipra Noapara",
      "CityValue": "Bipra Noapara"
    },
    {
      "id": 5436,
      "CityName": "Sufipind",
      "CityValue": "Sufipind"
    },
    {
      "id": 5437,
      "CityName": "Betpuli",
      "CityValue": "Betpuli"
    },
    {
      "id": 5438,
      "CityName": "Alapakkam",
      "CityValue": "Alapakkam"
    },
    {
      "id": 5439,
      "CityName": "Visapur",
      "CityValue": "Visapur"
    },
    {
      "id": 5440,
      "CityName": "Walani",
      "CityValue": "Walani"
    },
    {
      "id": 5441,
      "CityName": "Papadahandi",
      "CityValue": "Papadahandi"
    },
    {
      "id": 5442,
      "CityName": "Sonegaon",
      "CityValue": "Sonegaon"
    },
    {
      "id": 5443,
      "CityName": "Jhalda",
      "CityValue": "Jhalda"
    },
    {
      "id": 5444,
      "CityName": "Manjoor Garhi",
      "CityValue": "Manjoor Garhi"
    },
    {
      "id": 5445,
      "CityName": "Nanda",
      "CityValue": "Nanda"
    },
    {
      "id": 5446,
      "CityName": "Eklahare",
      "CityValue": "Eklahare"
    },
    {
      "id": 5447,
      "CityName": "Laruara",
      "CityValue": "Laruara"
    },
    {
      "id": 5448,
      "CityName": "Atrauliya",
      "CityValue": "Atrauliya"
    },
    {
      "id": 5449,
      "CityName": "Solgohalia",
      "CityValue": "Solgohalia"
    },
    {
      "id": 5450,
      "CityName": "Kiriburu",
      "CityValue": "Kiriburu"
    },
    {
      "id": 5451,
      "CityName": "Mataundh",
      "CityValue": "Mataundh"
    },
    {
      "id": 5452,
      "CityName": "Pappireddipatti",
      "CityValue": "Pappireddipatti"
    },
    {
      "id": 5453,
      "CityName": "Panaimarathupatti",
      "CityValue": "Panaimarathupatti"
    },
    {
      "id": 5454,
      "CityName": "Uri",
      "CityValue": "Uri"
    },
    {
      "id": 5455,
      "CityName": "Pookode",
      "CityValue": "Pookode"
    },
    {
      "id": 5456,
      "CityName": "Santokhgarh",
      "CityValue": "Santokhgarh"
    },
    {
      "id": 5457,
      "CityName": "Sitapur",
      "CityValue": "Sitapur"
    },
    {
      "id": 5458,
      "CityName": "Ahirkhedi",
      "CityValue": "Ahirkhedi"
    },
    {
      "id": 5459,
      "CityName": "Keeramangalam",
      "CityValue": "Keeramangalam"
    },
    {
      "id": 5460,
      "CityName": "Ettimadai",
      "CityValue": "Ettimadai"
    },
    {
      "id": 5461,
      "CityName": "Kalaikunda",
      "CityValue": "Kalaikunda"
    },
    {
      "id": 5462,
      "CityName": "Thirukarungudi",
      "CityValue": "Thirukarungudi"
    },
    {
      "id": 5463,
      "CityName": "Needamangalam",
      "CityValue": "Needamangalam"
    },
    {
      "id": 5464,
      "CityName": "Venkarai",
      "CityValue": "Venkarai"
    },
    {
      "id": 5465,
      "CityName": "Phulpur",
      "CityValue": "Phulpur"
    },
    {
      "id": 5466,
      "CityName": "Jallaram",
      "CityValue": "Jallaram"
    },
    {
      "id": 5467,
      "CityName": "Shohratgarh",
      "CityValue": "Shohratgarh"
    },
    {
      "id": 5468,
      "CityName": "Pannaipuram",
      "CityValue": "Pannaipuram"
    },
    {
      "id": 5469,
      "CityName": "Hajarmachi",
      "CityValue": "Hajarmachi"
    },
    {
      "id": 5470,
      "CityName": "Rudraprayag",
      "CityValue": "Rudraprayag"
    },
    {
      "id": 5471,
      "CityName": "Panpoli",
      "CityValue": "Panpoli"
    },
    {
      "id": 5472,
      "CityName": "Badagabettu",
      "CityValue": "Badagabettu"
    },
    {
      "id": 5473,
      "CityName": "Kanayannur",
      "CityValue": "Kanayannur"
    },
    {
      "id": 5474,
      "CityName": "Sohana",
      "CityValue": "Sohana"
    },
    {
      "id": 5475,
      "CityName": "Joka",
      "CityValue": "Joka"
    },
    {
      "id": 5476,
      "CityName": "Khadinan",
      "CityValue": "Khadinan"
    },
    {
      "id": 5477,
      "CityName": "Alwarthirunagiri",
      "CityValue": "Alwarthirunagiri"
    },
    {
      "id": 5478,
      "CityName": "Nuvem",
      "CityValue": "Nuvem"
    },
    {
      "id": 5479,
      "CityName": "Konganapuram",
      "CityValue": "Konganapuram"
    },
    {
      "id": 5480,
      "CityName": "GCF Jabalpur",
      "CityValue": "GCF Jabalpur"
    },
    {
      "id": 5481,
      "CityName": "Badagaon",
      "CityValue": "Badagaon"
    },
    {
      "id": 5482,
      "CityName": "Chicholi",
      "CityValue": "Chicholi"
    },
    {
      "id": 5483,
      "CityName": "Mangalampet",
      "CityValue": "Mangalampet"
    },
    {
      "id": 5484,
      "CityName": "Bagh",
      "CityValue": "Bagh"
    },
    {
      "id": 5485,
      "CityName": "Dola",
      "CityValue": "Dola"
    },
    {
      "id": 5486,
      "CityName": "Sarila",
      "CityValue": "Sarila"
    },
    {
      "id": 5487,
      "CityName": "Pali",
      "CityValue": "Pali"
    },
    {
      "id": 5488,
      "CityName": "Sarsaul",
      "CityValue": "Sarsaul"
    },
    {
      "id": 5489,
      "CityName": "Pahalgam",
      "CityValue": "Pahalgam"
    },
    {
      "id": 5490,
      "CityName": "Nadukuthagai",
      "CityValue": "Nadukuthagai"
    },
    {
      "id": 5491,
      "CityName": "Shapur",
      "CityValue": "Shapur"
    },
    {
      "id": 5492,
      "CityName": "Umri",
      "CityValue": "Umri"
    },
    {
      "id": 5493,
      "CityName": "Boyapalle",
      "CityValue": "Boyapalle"
    },
    {
      "id": 5494,
      "CityName": "Mattan",
      "CityValue": "Mattan"
    },
    {
      "id": 5495,
      "CityName": "Tittacheri",
      "CityValue": "Tittacheri"
    },
    {
      "id": 5496,
      "CityName": "Ahmadpur",
      "CityValue": "Ahmadpur"
    },
    {
      "id": 5497,
      "CityName": "Nayabahadurpur",
      "CityValue": "Nayabahadurpur"
    },
    {
      "id": 5498,
      "CityName": "Kharora",
      "CityValue": "Kharora"
    },
    {
      "id": 5499,
      "CityName": "Samayanallur",
      "CityValue": "Samayanallur"
    },
    {
      "id": 5500,
      "CityName": "Vaddakkankulam",
      "CityValue": "Vaddakkankulam"
    },
    {
      "id": 5501,
      "CityName": "Mehatpur Basdehra",
      "CityValue": "Mehatpur Basdehra"
    },
    {
      "id": 5502,
      "CityName": "Kakod",
      "CityValue": "Kakod"
    },
    {
      "id": 5503,
      "CityName": "Sikanderpur",
      "CityValue": "Sikanderpur"
    },
    {
      "id": 5504,
      "CityName": "Natthuwa Wala",
      "CityValue": "Natthuwa Wala"
    },
    {
      "id": 5505,
      "CityName": "Niwari",
      "CityValue": "Niwari"
    },
    {
      "id": 5506,
      "CityName": "Modak",
      "CityValue": "Modak"
    },
    {
      "id": 5507,
      "CityName": "Bhanur",
      "CityValue": "Bhanur"
    },
    {
      "id": 5508,
      "CityName": "Valavandankottai",
      "CityValue": "Valavandankottai"
    },
    {
      "id": 5509,
      "CityName": "Loharda",
      "CityValue": "Loharda"
    },
    {
      "id": 5510,
      "CityName": "Kharabwadi",
      "CityValue": "Kharabwadi"
    },
    {
      "id": 5511,
      "CityName": "Mavli",
      "CityValue": "Mavli"
    },
    {
      "id": 5512,
      "CityName": "Kollankoil",
      "CityValue": "Kollankoil"
    },
    {
      "id": 5513,
      "CityName": "Pattanam",
      "CityValue": "Pattanam"
    },
    {
      "id": 5514,
      "CityName": "Palidevad",
      "CityValue": "Palidevad"
    },
    {
      "id": 5515,
      "CityName": "Bargaon",
      "CityValue": "Bargaon"
    },
    {
      "id": 5516,
      "CityName": "Ganeshpur",
      "CityValue": "Ganeshpur"
    },
    {
      "id": 5517,
      "CityName": "Velayudampalayam",
      "CityValue": "Velayudampalayam"
    },
    {
      "id": 5518,
      "CityName": "Badhi Majra",
      "CityValue": "Badhi Majra"
    },
    {
      "id": 5519,
      "CityName": "Paralam",
      "CityValue": "Paralam"
    },
    {
      "id": 5520,
      "CityName": "Santpur",
      "CityValue": "Santpur"
    },
    {
      "id": 5521,
      "CityName": "Perumbavoor",
      "CityValue": "Perumbavoor"
    },
    {
      "id": 5522,
      "CityName": "Kalkut",
      "CityValue": "Kalkut"
    },
    {
      "id": 5523,
      "CityName": "Kantilo",
      "CityValue": "Kantilo"
    },
    {
      "id": 5524,
      "CityName": "Maheshari",
      "CityValue": "Maheshari"
    },
    {
      "id": 5525,
      "CityName": "Nandej",
      "CityValue": "Nandej"
    },
    {
      "id": 5526,
      "CityName": "Pali",
      "CityValue": "Pali"
    },
    {
      "id": 5527,
      "CityName": "Rishabhdeo",
      "CityValue": "Rishabhdeo"
    },
    {
      "id": 5528,
      "CityName": "Anantabati",
      "CityValue": "Anantabati"
    },
    {
      "id": 5529,
      "CityName": "Pardi Kanade",
      "CityValue": "Pardi Kanade"
    },
    {
      "id": 5530,
      "CityName": "Pangachhiya",
      "CityValue": "Pangachhiya"
    },
    {
      "id": 5531,
      "CityName": "Harraiya",
      "CityValue": "Harraiya"
    },
    {
      "id": 5532,
      "CityName": "Thiruvalam",
      "CityValue": "Thiruvalam"
    },
    {
      "id": 5533,
      "CityName": "Thuthipattu",
      "CityValue": "Thuthipattu"
    },
    {
      "id": 5534,
      "CityName": "Bamanpukur",
      "CityValue": "Bamanpukur"
    },
    {
      "id": 5535,
      "CityName": "Mekliganj",
      "CityValue": "Mekliganj"
    },
    {
      "id": 5536,
      "CityName": "Paluvai",
      "CityValue": "Paluvai"
    },
    {
      "id": 5537,
      "CityName": "Donkamokam",
      "CityValue": "Donkamokam"
    },
    {
      "id": 5538,
      "CityName": "Tittangulam",
      "CityValue": "Tittangulam"
    },
    {
      "id": 5539,
      "CityName": "Gandhinagar",
      "CityValue": "Gandhinagar"
    },
    {
      "id": 5540,
      "CityName": "Kudur",
      "CityValue": "Kudur"
    },
    {
      "id": 5541,
      "CityName": "P.Mettupalayam",
      "CityValue": "P.Mettupalayam"
    },
    {
      "id": 5542,
      "CityName": "Vavdi Bujarg",
      "CityValue": "Vavdi Bujarg"
    },
    {
      "id": 5543,
      "CityName": "Wangoi",
      "CityValue": "Wangoi"
    },
    {
      "id": 5544,
      "CityName": "Masinaickenpatty",
      "CityValue": "Masinaickenpatty"
    },
    {
      "id": 5545,
      "CityName": "Mohanpur",
      "CityValue": "Mohanpur"
    },
    {
      "id": 5546,
      "CityName": "Parakkad",
      "CityValue": "Parakkad"
    },
    {
      "id": 5547,
      "CityName": "Kasipalayam",
      "CityValue": "Kasipalayam"
    },
    {
      "id": 5548,
      "CityName": "Ratlam Rly.Col.",
      "CityValue": "Ratlam Rly.Col."
    },
    {
      "id": 5549,
      "CityName": "Cortalim",
      "CityValue": "Cortalim"
    },
    {
      "id": 5550,
      "CityName": "Bageshwar",
      "CityValue": "Bageshwar"
    },
    {
      "id": 5551,
      "CityName": "Baldeogarh",
      "CityValue": "Baldeogarh"
    },
    {
      "id": 5552,
      "CityName": "Senur",
      "CityValue": "Senur"
    },
    {
      "id": 5553,
      "CityName": "Ujalaiwadi",
      "CityValue": "Ujalaiwadi"
    },
    {
      "id": 5554,
      "CityName": "Hongalli",
      "CityValue": "Hongalli"
    },
    {
      "id": 5555,
      "CityName": "Melathiruppanthuruthi",
      "CityValue": "Melathiruppanthuruthi"
    },
    {
      "id": 5556,
      "CityName": "Balaga",
      "CityValue": "Balaga"
    },
    {
      "id": 5557,
      "CityName": "Gossaigaon",
      "CityValue": "Gossaigaon"
    },
    {
      "id": 5558,
      "CityName": "Alhaipur",
      "CityValue": "Alhaipur"
    },
    {
      "id": 5559,
      "CityName": "Akedadoongar",
      "CityValue": "Akedadoongar"
    },
    {
      "id": 5560,
      "CityName": "Dhwajnagar",
      "CityValue": "Dhwajnagar"
    },
    {
      "id": 5561,
      "CityName": "Durga Nagar Pt. V",
      "CityValue": "Durga Nagar Pt. V"
    },
    {
      "id": 5562,
      "CityName": "Chandi Mandir",
      "CityValue": "Chandi Mandir"
    },
    {
      "id": 5563,
      "CityName": "Kuanrmunda",
      "CityValue": "Kuanrmunda"
    },
    {
      "id": 5564,
      "CityName": "Mustafabad",
      "CityValue": "Mustafabad"
    },
    {
      "id": 5565,
      "CityName": "Bhogadi",
      "CityValue": "Bhogadi"
    },
    {
      "id": 5566,
      "CityName": "Khandapada",
      "CityValue": "Khandapada"
    },
    {
      "id": 5567,
      "CityName": "Vareli",
      "CityValue": "Vareli"
    },
    {
      "id": 5568,
      "CityName": "Kosavampatti",
      "CityValue": "Kosavampatti"
    },
    {
      "id": 5569,
      "CityName": "Minampalli-Pachamadevi",
      "CityValue": "Minampalli-Pachamadevi"
    },
    {
      "id": 5570,
      "CityName": "Raipur Rani",
      "CityValue": "Raipur Rani"
    },
    {
      "id": 5571,
      "CityName": "Vilar",
      "CityValue": "Vilar"
    },
    {
      "id": 5572,
      "CityName": "Chekonidhara",
      "CityValue": "Chekonidhara"
    },
    {
      "id": 5573,
      "CityName": "Bhairamgarh",
      "CityValue": "Bhairamgarh"
    },
    {
      "id": 5574,
      "CityName": "Dediapada",
      "CityValue": "Dediapada"
    },
    {
      "id": 5575,
      "CityName": "Karwi Mafi",
      "CityValue": "Karwi Mafi"
    },
    {
      "id": 5576,
      "CityName": "Kodivalasa",
      "CityValue": "Kodivalasa"
    },
    {
      "id": 5577,
      "CityName": "Sahapur",
      "CityValue": "Sahapur"
    },
    {
      "id": 5578,
      "CityName": "Bhilad",
      "CityValue": "Bhilad"
    },
    {
      "id": 5579,
      "CityName": "Manalmedu",
      "CityValue": "Manalmedu"
    },
    {
      "id": 5580,
      "CityName": "Harveypatti",
      "CityValue": "Harveypatti"
    },
    {
      "id": 5581,
      "CityName": "Jorethang",
      "CityValue": "Jorethang"
    },
    {
      "id": 5582,
      "CityName": "Katra",
      "CityValue": "Katra"
    },
    {
      "id": 5583,
      "CityName": "Deuli",
      "CityValue": "Deuli"
    },
    {
      "id": 5584,
      "CityName": "Erumapalayam",
      "CityValue": "Erumapalayam"
    },
    {
      "id": 5585,
      "CityName": "8 LLG",
      "CityValue": "8 LLG"
    },
    {
      "id": 5586,
      "CityName": "Odugathur",
      "CityValue": "Odugathur"
    },
    {
      "id": 5587,
      "CityName": "Kodalia",
      "CityValue": "Kodalia"
    },
    {
      "id": 5588,
      "CityName": "Thazhakudy",
      "CityValue": "Thazhakudy"
    },
    {
      "id": 5589,
      "CityName": "Bajna",
      "CityValue": "Bajna"
    },
    {
      "id": 5590,
      "CityName": "Alipurduar Rly.Jnc.",
      "CityValue": "Alipurduar Rly.Jnc."
    },
    {
      "id": 5591,
      "CityName": "Muthugoundam Pudur",
      "CityValue": "Muthugoundam Pudur"
    },
    {
      "id": 5592,
      "CityName": "Sundarapandiapuram",
      "CityValue": "Sundarapandiapuram"
    },
    {
      "id": 5593,
      "CityName": "Lawan",
      "CityValue": "Lawan"
    },
    {
      "id": 5594,
      "CityName": "Harishpur",
      "CityValue": "Harishpur"
    },
    {
      "id": 5595,
      "CityName": "Bilaspur",
      "CityValue": "Bilaspur"
    },
    {
      "id": 5596,
      "CityName": "Krushnanandapur",
      "CityValue": "Krushnanandapur"
    },
    {
      "id": 5597,
      "CityName": "Kosmi",
      "CityValue": "Kosmi"
    },
    {
      "id": 5598,
      "CityName": "Chabua",
      "CityValue": "Chabua"
    },
    {
      "id": 5599,
      "CityName": "Jateshwar",
      "CityValue": "Jateshwar"
    },
    {
      "id": 5600,
      "CityName": "Siduli",
      "CityValue": "Siduli"
    },
    {
      "id": 5601,
      "CityName": "Amkhera",
      "CityValue": "Amkhera"
    },
    {
      "id": 5602,
      "CityName": "Alikherva",
      "CityValue": "Alikherva"
    },
    {
      "id": 5603,
      "CityName": "Barwala",
      "CityValue": "Barwala"
    },
    {
      "id": 5604,
      "CityName": "Arnia",
      "CityValue": "Arnia"
    },
    {
      "id": 5605,
      "CityName": "Arimalam",
      "CityValue": "Arimalam"
    },
    {
      "id": 5606,
      "CityName": "Baba Bakala",
      "CityValue": "Baba Bakala"
    },
    {
      "id": 5607,
      "CityName": "Babanpur",
      "CityValue": "Babanpur"
    },
    {
      "id": 5608,
      "CityName": "Dandi",
      "CityValue": "Dandi"
    },
    {
      "id": 5609,
      "CityName": "Hatibandha",
      "CityValue": "Hatibandha"
    },
    {
      "id": 5610,
      "CityName": "Hariana",
      "CityValue": "Hariana"
    },
    {
      "id": 5611,
      "CityName": "Chinnamudalaipatti",
      "CityValue": "Chinnamudalaipatti"
    },
    {
      "id": 5612,
      "CityName": "Koeripur",
      "CityValue": "Koeripur"
    },
    {
      "id": 5613,
      "CityName": "Uncha Siwana",
      "CityValue": "Uncha Siwana"
    },
    {
      "id": 5614,
      "CityName": "Sahpau",
      "CityValue": "Sahpau"
    },
    {
      "id": 5615,
      "CityName": "Munnur",
      "CityValue": "Munnur"
    },
    {
      "id": 5616,
      "CityName": "Barigarh",
      "CityValue": "Barigarh"
    },
    {
      "id": 5617,
      "CityName": "Nathampannai",
      "CityValue": "Nathampannai"
    },
    {
      "id": 5618,
      "CityName": "Kalyanpur",
      "CityValue": "Kalyanpur"
    },
    {
      "id": 5619,
      "CityName": "Mogral",
      "CityValue": "Mogral"
    },
    {
      "id": 5620,
      "CityName": "Pattinam",
      "CityValue": "Pattinam"
    },
    {
      "id": 5621,
      "CityName": "Chandrapur",
      "CityValue": "Chandrapur"
    },
    {
      "id": 5622,
      "CityName": "Annavasal",
      "CityValue": "Annavasal"
    },
    {
      "id": 5623,
      "CityName": "Serpur",
      "CityValue": "Serpur"
    },
    {
      "id": 5624,
      "CityName": "Parasia",
      "CityValue": "Parasia"
    },
    {
      "id": 5625,
      "CityName": "Sikanderpur",
      "CityValue": "Sikanderpur"
    },
    {
      "id": 5626,
      "CityName": "V. Pudur",
      "CityValue": "V. Pudur"
    },
    {
      "id": 5627,
      "CityName": "Eriodu",
      "CityValue": "Eriodu"
    },
    {
      "id": 5628,
      "CityName": "Adaikkakuzhi",
      "CityValue": "Adaikkakuzhi"
    },
    {
      "id": 5629,
      "CityName": "Molachur",
      "CityValue": "Molachur"
    },
    {
      "id": 5630,
      "CityName": "Kilkunda",
      "CityValue": "Kilkunda"
    },
    {
      "id": 5631,
      "CityName": "Narot Mehra",
      "CityValue": "Narot Mehra"
    },
    {
      "id": 5632,
      "CityName": "Ajodhya Nagar",
      "CityValue": "Ajodhya Nagar"
    },
    {
      "id": 5633,
      "CityName": "Masala",
      "CityValue": "Masala"
    },
    {
      "id": 5634,
      "CityName": "Badarpur Rly. Town",
      "CityValue": "Badarpur Rly. Town"
    },
    {
      "id": 5635,
      "CityName": "Sec. 11&12 Part II",
      "CityValue": "Sec. 11&12 Part II"
    },
    {
      "id": 5636,
      "CityName": "Ayacode",
      "CityValue": "Ayacode"
    },
    {
      "id": 5637,
      "CityName": "Bhuwaneshwar",
      "CityValue": "Bhuwaneshwar"
    },
    {
      "id": 5638,
      "CityName": "Eranapuram",
      "CityValue": "Eranapuram"
    },
    {
      "id": 5639,
      "CityName": "Shamshi",
      "CityValue": "Shamshi"
    },
    {
      "id": 5640,
      "CityName": "Mallik Bagan",
      "CityValue": "Mallik Bagan"
    },
    {
      "id": 5641,
      "CityName": "Gochar",
      "CityValue": "Gochar"
    },
    {
      "id": 5642,
      "CityName": "Munnuru",
      "CityValue": "Munnuru"
    },
    {
      "id": 5643,
      "CityName": "Damanjodi",
      "CityValue": "Damanjodi"
    },
    {
      "id": 5644,
      "CityName": "Birjapur",
      "CityValue": "Birjapur"
    },
    {
      "id": 5645,
      "CityName": "Fateh Pur Beri",
      "CityValue": "Fateh Pur Beri"
    },
    {
      "id": 5646,
      "CityName": "Koora",
      "CityValue": "Koora"
    },
    {
      "id": 5647,
      "CityName": "Bhanowara",
      "CityValue": "Bhanowara"
    },
    {
      "id": 5648,
      "CityName": "Amin Gaon",
      "CityValue": "Amin Gaon"
    },
    {
      "id": 5649,
      "CityName": "Paratdih",
      "CityValue": "Paratdih"
    },
    {
      "id": 5650,
      "CityName": "Barua",
      "CityValue": "Barua"
    },
    {
      "id": 5651,
      "CityName": "Padarathpur",
      "CityValue": "Padarathpur"
    },
    {
      "id": 5652,
      "CityName": "Jhagra Pt.III",
      "CityValue": "Jhagra Pt.III"
    },
    {
      "id": 5653,
      "CityName": "Mouje Nandgad",
      "CityValue": "Mouje Nandgad"
    },
    {
      "id": 5654,
      "CityName": "Birwadi",
      "CityValue": "Birwadi"
    },
    {
      "id": 5655,
      "CityName": "Sethiathoppu",
      "CityValue": "Sethiathoppu"
    },
    {
      "id": 5656,
      "CityName": "Maragondahalli",
      "CityValue": "Maragondahalli"
    },
    {
      "id": 5657,
      "CityName": "Padiyur",
      "CityValue": "Padiyur"
    },
    {
      "id": 5658,
      "CityName": "Chak Sikari",
      "CityValue": "Chak Sikari"
    },
    {
      "id": 5659,
      "CityName": "Rajbhita Alias Rajganj",
      "CityValue": "Rajbhita Alias Rajganj"
    },
    {
      "id": 5660,
      "CityName": "Sankrailjala",
      "CityValue": "Sankrailjala"
    },
    {
      "id": 5661,
      "CityName": "Sayadpur",
      "CityValue": "Sayadpur"
    },
    {
      "id": 5662,
      "CityName": "Kelhauri",
      "CityValue": "Kelhauri"
    },
    {
      "id": 5663,
      "CityName": "Teok",
      "CityValue": "Teok"
    },
    {
      "id": 5664,
      "CityName": "Kothi",
      "CityValue": "Kothi"
    },
    {
      "id": 5665,
      "CityName": "Naya Baradwar",
      "CityValue": "Naya Baradwar"
    },
    {
      "id": 5666,
      "CityName": "Gudivada",
      "CityValue": "Gudivada"
    },
    {
      "id": 5667,
      "CityName": "Boraj-Kazipura",
      "CityValue": "Boraj-Kazipura"
    },
    {
      "id": 5668,
      "CityName": "Azara",
      "CityValue": "Azara"
    },
    {
      "id": 5669,
      "CityName": "Yelandur",
      "CityValue": "Yelandur"
    },
    {
      "id": 5670,
      "CityName": "Nazirpur",
      "CityValue": "Nazirpur"
    },
    {
      "id": 5671,
      "CityName": "Mowad",
      "CityValue": "Mowad"
    },
    {
      "id": 5672,
      "CityName": "Dhanwar",
      "CityValue": "Dhanwar"
    },
    {
      "id": 5673,
      "CityName": "Muthangi",
      "CityValue": "Muthangi"
    },
    {
      "id": 5674,
      "CityName": "Bhicholi Hapsi",
      "CityValue": "Bhicholi Hapsi"
    },
    {
      "id": 5675,
      "CityName": "Kunnathur",
      "CityValue": "Kunnathur"
    },
    {
      "id": 5676,
      "CityName": "Basai",
      "CityValue": "Basai"
    },
    {
      "id": 5677,
      "CityName": "Sawari Jawharnagar",
      "CityValue": "Sawari Jawharnagar"
    },
    {
      "id": 5678,
      "CityName": "Amtala",
      "CityValue": "Amtala"
    },
    {
      "id": 5679,
      "CityName": "Dewanpasa",
      "CityValue": "Dewanpasa"
    },
    {
      "id": 5680,
      "CityName": "Parwanoo",
      "CityValue": "Parwanoo"
    },
    {
      "id": 5681,
      "CityName": "Tarapur Pt VI",
      "CityValue": "Tarapur Pt VI"
    },
    {
      "id": 5682,
      "CityName": "Sarupathar Bengali",
      "CityValue": "Sarupathar Bengali"
    },
    {
      "id": 5683,
      "CityName": "Utai",
      "CityValue": "Utai"
    },
    {
      "id": 5684,
      "CityName": "Gogunda",
      "CityValue": "Gogunda"
    },
    {
      "id": 5685,
      "CityName": "Geni",
      "CityValue": "Geni"
    },
    {
      "id": 5686,
      "CityName": "Hamren",
      "CityValue": "Hamren"
    },
    {
      "id": 5687,
      "CityName": "Andro",
      "CityValue": "Andro"
    },
    {
      "id": 5688,
      "CityName": "Manjeshwar",
      "CityValue": "Manjeshwar"
    },
    {
      "id": 5689,
      "CityName": "Medziphema",
      "CityValue": "Medziphema"
    },
    {
      "id": 5690,
      "CityName": "Belur",
      "CityValue": "Belur"
    },
    {
      "id": 5691,
      "CityName": "Pannaikadu",
      "CityValue": "Pannaikadu"
    },
    {
      "id": 5692,
      "CityName": "Nadaikavu",
      "CityValue": "Nadaikavu"
    },
    {
      "id": 5693,
      "CityName": "Satyewala",
      "CityValue": "Satyewala"
    },
    {
      "id": 5694,
      "CityName": "Pallipattu",
      "CityValue": "Pallipattu"
    },
    {
      "id": 5695,
      "CityName": "Batika",
      "CityValue": "Batika"
    },
    {
      "id": 5696,
      "CityName": "Makardaha",
      "CityValue": "Makardaha"
    },
    {
      "id": 5697,
      "CityName": "Saravali",
      "CityValue": "Saravali"
    },
    {
      "id": 5698,
      "CityName": "Doiwala",
      "CityValue": "Doiwala"
    },
    {
      "id": 5699,
      "CityName": "Jalukie",
      "CityValue": "Jalukie"
    },
    {
      "id": 5700,
      "CityName": "Badawada",
      "CityValue": "Badawada"
    },
    {
      "id": 5701,
      "CityName": "Gausganj",
      "CityValue": "Gausganj"
    },
    {
      "id": 5702,
      "CityName": "Basta",
      "CityValue": "Basta"
    },
    {
      "id": 5703,
      "CityName": "Chinnampalayam",
      "CityValue": "Chinnampalayam"
    },
    {
      "id": 5704,
      "CityName": "Ramgarh",
      "CityValue": "Ramgarh"
    },
    {
      "id": 5705,
      "CityName": "Idikarai",
      "CityValue": "Idikarai"
    },
    {
      "id": 5706,
      "CityName": "Kadipikonda",
      "CityValue": "Kadipikonda"
    },
    {
      "id": 5707,
      "CityName": "Rajpur Bangar",
      "CityValue": "Rajpur Bangar"
    },
    {
      "id": 5708,
      "CityName": "Itinda",
      "CityValue": "Itinda"
    },
    {
      "id": 5709,
      "CityName": "Katpar",
      "CityValue": "Katpar"
    },
    {
      "id": 5710,
      "CityName": "Munirabad Project Area",
      "CityValue": "Munirabad Project Area"
    },
    {
      "id": 5711,
      "CityName": "Bariwala",
      "CityValue": "Bariwala"
    },
    {
      "id": 5712,
      "CityName": "Bagbari",
      "CityValue": "Bagbari"
    },
    {
      "id": 5713,
      "CityName": "Nazarathpettai",
      "CityValue": "Nazarathpettai"
    },
    {
      "id": 5714,
      "CityName": "Poali",
      "CityValue": "Poali"
    },
    {
      "id": 5715,
      "CityName": "Kinathukadavu",
      "CityValue": "Kinathukadavu"
    },
    {
      "id": 5716,
      "CityName": "Balakrishnampatti",
      "CityValue": "Balakrishnampatti"
    },
    {
      "id": 5717,
      "CityName": "Sathkar",
      "CityValue": "Sathkar"
    },
    {
      "id": 5718,
      "CityName": "Pali Khera",
      "CityValue": "Pali Khera"
    },
    {
      "id": 5719,
      "CityName": "Rees",
      "CityValue": "Rees"
    },
    {
      "id": 5720,
      "CityName": "Barajamda",
      "CityValue": "Barajamda"
    },
    {
      "id": 5721,
      "CityName": "Bhor Garh",
      "CityValue": "Bhor Garh"
    },
    {
      "id": 5722,
      "CityName": "Khandauli",
      "CityValue": "Khandauli"
    },
    {
      "id": 5723,
      "CityName": "Midalam",
      "CityValue": "Midalam"
    },
    {
      "id": 5724,
      "CityName": "Gokul Shirgaon",
      "CityValue": "Gokul Shirgaon"
    },
    {
      "id": 5725,
      "CityName": "Shivli",
      "CityValue": "Shivli"
    },
    {
      "id": 5726,
      "CityName": "Perumandi",
      "CityValue": "Perumandi"
    },
    {
      "id": 5727,
      "CityName": "Bargarwa",
      "CityValue": "Bargarwa"
    },
    {
      "id": 5728,
      "CityName": "Gunderdehi",
      "CityValue": "Gunderdehi"
    },
    {
      "id": 5729,
      "CityName": "Puzhakkal",
      "CityValue": "Puzhakkal"
    },
    {
      "id": 5730,
      "CityName": "Dehrisaray",
      "CityValue": "Dehrisaray"
    },
    {
      "id": 5731,
      "CityName": "Belvata",
      "CityValue": "Belvata"
    },
    {
      "id": 5732,
      "CityName": "Bhokara",
      "CityValue": "Bhokara"
    },
    {
      "id": 5733,
      "CityName": "Pattiveeranpatti",
      "CityValue": "Pattiveeranpatti"
    },
    {
      "id": 5734,
      "CityName": "Shankhanagar",
      "CityValue": "Shankhanagar"
    },
    {
      "id": 5735,
      "CityName": "Piplanarayanwar",
      "CityValue": "Piplanarayanwar"
    },
    {
      "id": 5736,
      "CityName": "Torpa",
      "CityValue": "Torpa"
    },
    {
      "id": 5737,
      "CityName": "Baragaon",
      "CityValue": "Baragaon"
    },
    {
      "id": 5738,
      "CityName": "Kolvi @ Mandi Rajendrapur",
      "CityValue": "Kolvi @ Mandi Rajendrapur"
    },
    {
      "id": 5739,
      "CityName": "Bangherimahabatpur",
      "CityValue": "Bangherimahabatpur"
    },
    {
      "id": 5740,
      "CityName": "Chakchaka",
      "CityValue": "Chakchaka"
    },
    {
      "id": 5741,
      "CityName": "Virbhadra IDPL",
      "CityValue": "Virbhadra IDPL"
    },
    {
      "id": 5742,
      "CityName": "Ghagra",
      "CityValue": "Ghagra"
    },
    {
      "id": 5743,
      "CityName": "Kwakta",
      "CityValue": "Kwakta"
    },
    {
      "id": 5744,
      "CityName": "Saijni Nankar",
      "CityValue": "Saijni Nankar"
    },
    {
      "id": 5745,
      "CityName": "Palari",
      "CityValue": "Palari"
    },
    {
      "id": 5746,
      "CityName": "Bilpahari",
      "CityValue": "Bilpahari"
    },
    {
      "id": 5747,
      "CityName": "Mahona",
      "CityValue": "Mahona"
    },
    {
      "id": 5748,
      "CityName": "Chithode",
      "CityValue": "Chithode"
    },
    {
      "id": 5749,
      "CityName": "Lavachha",
      "CityValue": "Lavachha"
    },
    {
      "id": 5750,
      "CityName": "Thevur",
      "CityValue": "Thevur"
    },
    {
      "id": 5751,
      "CityName": "Kattimancode",
      "CityValue": "Kattimancode"
    },
    {
      "id": 5752,
      "CityName": "Nagal Chaudhry",
      "CityValue": "Nagal Chaudhry"
    },
    {
      "id": 5753,
      "CityName": "Lalpur",
      "CityValue": "Lalpur"
    },
    {
      "id": 5754,
      "CityName": "Harsewakpur No.2",
      "CityValue": "Harsewakpur No.2"
    },
    {
      "id": 5755,
      "CityName": "Valpoi",
      "CityValue": "Valpoi"
    },
    {
      "id": 5756,
      "CityName": "Umpling",
      "CityValue": "Umpling"
    },
    {
      "id": 5757,
      "CityName": "Belsor",
      "CityValue": "Belsor"
    },
    {
      "id": 5758,
      "CityName": "Manalurpet",
      "CityValue": "Manalurpet"
    },
    {
      "id": 5759,
      "CityName": "Balibhara",
      "CityValue": "Balibhara"
    },
    {
      "id": 5760,
      "CityName": "Manu",
      "CityValue": "Manu"
    },
    {
      "id": 5761,
      "CityName": "Bahadurpur",
      "CityValue": "Bahadurpur"
    },
    {
      "id": 5762,
      "CityName": "Sundarapandiam",
      "CityValue": "Sundarapandiam"
    },
    {
      "id": 5763,
      "CityName": "Dahi",
      "CityValue": "Dahi"
    },
    {
      "id": 5764,
      "CityName": "Naduvattam",
      "CityValue": "Naduvattam"
    },
    {
      "id": 5765,
      "CityName": "Malhar",
      "CityValue": "Malhar"
    },
    {
      "id": 5766,
      "CityName": "Kurumuli",
      "CityValue": "Kurumuli"
    },
    {
      "id": 5767,
      "CityName": "Antu",
      "CityValue": "Antu"
    },
    {
      "id": 5768,
      "CityName": "Sulebhavi",
      "CityValue": "Sulebhavi"
    },
    {
      "id": 5769,
      "CityName": "Chhapiheda",
      "CityValue": "Chhapiheda"
    },
    {
      "id": 5770,
      "CityName": "Candolim",
      "CityValue": "Candolim"
    },
    {
      "id": 5771,
      "CityName": "Bamora",
      "CityValue": "Bamora"
    },
    {
      "id": 5772,
      "CityName": "Teghari",
      "CityValue": "Teghari"
    },
    {
      "id": 5773,
      "CityName": "Kasiya",
      "CityValue": "Kasiya"
    },
    {
      "id": 5774,
      "CityName": "Konardihi",
      "CityValue": "Konardihi"
    },
    {
      "id": 5775,
      "CityName": "Jandiala",
      "CityValue": "Jandiala"
    },
    {
      "id": 5776,
      "CityName": "Naduvaneri",
      "CityValue": "Naduvaneri"
    },
    {
      "id": 5777,
      "CityName": "Kotulpur",
      "CityValue": "Kotulpur"
    },
    {
      "id": 5778,
      "CityName": "Boh",
      "CityValue": "Boh"
    },
    {
      "id": 5779,
      "CityName": "Mirdhanga",
      "CityValue": "Mirdhanga"
    },
    {
      "id": 5780,
      "CityName": "Patadi",
      "CityValue": "Patadi"
    },
    {
      "id": 5781,
      "CityName": "Bagh Nagar Urf Bakhira",
      "CityValue": "Bagh Nagar Urf Bakhira"
    },
    {
      "id": 5782,
      "CityName": "Gondpipri",
      "CityValue": "Gondpipri"
    },
    {
      "id": 5783,
      "CityName": "Dara Pora",
      "CityValue": "Dara Pora"
    },
    {
      "id": 5784,
      "CityName": "Dharmapur",
      "CityValue": "Dharmapur"
    },
    {
      "id": 5785,
      "CityName": "Kakarhati",
      "CityValue": "Kakarhati"
    },
    {
      "id": 5786,
      "CityName": "Charka",
      "CityValue": "Charka"
    },
    {
      "id": 5787,
      "CityName": "Tirodi",
      "CityValue": "Tirodi"
    },
    {
      "id": 5788,
      "CityName": "Dakshin Khanda",
      "CityValue": "Dakshin Khanda"
    },
    {
      "id": 5789,
      "CityName": "Pareo",
      "CityValue": "Pareo"
    },
    {
      "id": 5790,
      "CityName": "Melacheval",
      "CityValue": "Melacheval"
    },
    {
      "id": 5791,
      "CityName": "Moran Town",
      "CityValue": "Moran Town"
    },
    {
      "id": 5792,
      "CityName": "Athani",
      "CityValue": "Athani"
    },
    {
      "id": 5793,
      "CityName": "Semmipalayam",
      "CityValue": "Semmipalayam"
    },
    {
      "id": 5794,
      "CityName": "Padandal",
      "CityValue": "Padandal"
    },
    {
      "id": 5795,
      "CityName": "Pithora",
      "CityValue": "Pithora"
    },
    {
      "id": 5796,
      "CityName": "Panchghara",
      "CityValue": "Panchghara"
    },
    {
      "id": 5797,
      "CityName": "Uttar Jhapardaha",
      "CityValue": "Uttar Jhapardaha"
    },
    {
      "id": 5798,
      "CityName": "Ramgarh",
      "CityValue": "Ramgarh"
    },
    {
      "id": 5799,
      "CityName": "Nidhauli Kalan",
      "CityValue": "Nidhauli Kalan"
    },
    {
      "id": 5800,
      "CityName": "Amarkantak",
      "CityValue": "Amarkantak"
    },
    {
      "id": 5801,
      "CityName": "Chinnathadagam",
      "CityValue": "Chinnathadagam"
    },
    {
      "id": 5802,
      "CityName": "Kharak mafi",
      "CityValue": "Kharak mafi"
    },
    {
      "id": 5803,
      "CityName": "Sanchi",
      "CityValue": "Sanchi"
    },
    {
      "id": 5804,
      "CityName": "Bishamakatak",
      "CityValue": "Bishamakatak"
    },
    {
      "id": 5805,
      "CityName": "Panuria",
      "CityValue": "Panuria"
    },
    {
      "id": 5806,
      "CityName": "Dahali",
      "CityValue": "Dahali"
    },
    {
      "id": 5807,
      "CityName": "Jaithari",
      "CityValue": "Jaithari"
    },
    {
      "id": 5808,
      "CityName": "Nilpur",
      "CityValue": "Nilpur"
    },
    {
      "id": 5809,
      "CityName": "Kotra",
      "CityValue": "Kotra"
    },
    {
      "id": 5810,
      "CityName": "Athipatti",
      "CityValue": "Athipatti"
    },
    {
      "id": 5811,
      "CityName": "Neelambur",
      "CityValue": "Neelambur"
    },
    {
      "id": 5812,
      "CityName": "Chhapi",
      "CityValue": "Chhapi"
    },
    {
      "id": 5813,
      "CityName": "Venmanad",
      "CityValue": "Venmanad"
    },
    {
      "id": 5814,
      "CityName": "Than-Khamharia",
      "CityValue": "Than-Khamharia"
    },
    {
      "id": 5815,
      "CityName": "Vijayraghavgarh",
      "CityValue": "Vijayraghavgarh"
    },
    {
      "id": 5816,
      "CityName": "Bomdila",
      "CityValue": "Bomdila"
    },
    {
      "id": 5817,
      "CityName": "Vavarai",
      "CityValue": "Vavarai"
    },
    {
      "id": 5818,
      "CityName": "Gakulpur",
      "CityValue": "Gakulpur"
    },
    {
      "id": 5819,
      "CityName": "Desamangalam",
      "CityValue": "Desamangalam"
    },
    {
      "id": 5820,
      "CityName": "Tikarpada",
      "CityValue": "Tikarpada"
    },
    {
      "id": 5821,
      "CityName": "Bayarsing",
      "CityValue": "Bayarsing"
    },
    {
      "id": 5822,
      "CityName": "Shaha Urf Pipalgaon",
      "CityValue": "Shaha Urf Pipalgaon"
    },
    {
      "id": 5823,
      "CityName": "Dhamnod",
      "CityValue": "Dhamnod"
    },
    {
      "id": 5824,
      "CityName": "Rakholi",
      "CityValue": "Rakholi"
    },
    {
      "id": 5825,
      "CityName": "Dommara Nandyala",
      "CityValue": "Dommara Nandyala"
    },
    {
      "id": 5826,
      "CityName": "Thiruvenkadam",
      "CityValue": "Thiruvenkadam"
    },
    {
      "id": 5827,
      "CityName": "Mandrem",
      "CityValue": "Mandrem"
    },
    {
      "id": 5828,
      "CityName": "Wail",
      "CityValue": "Wail"
    },
    {
      "id": 5829,
      "CityName": "Raghunandanpur",
      "CityValue": "Raghunandanpur"
    },
    {
      "id": 5830,
      "CityName": "Tarbha",
      "CityValue": "Tarbha"
    },
    {
      "id": 5831,
      "CityName": "Malhargarh",
      "CityValue": "Malhargarh"
    },
    {
      "id": 5832,
      "CityName": "Jangl Hakeem No 2",
      "CityValue": "Jangl Hakeem No 2"
    },
    {
      "id": 5833,
      "CityName": "A.Vellalapatti",
      "CityValue": "A.Vellalapatti"
    },
    {
      "id": 5834,
      "CityName": "Rasikpur",
      "CityValue": "Rasikpur"
    },
    {
      "id": 5835,
      "CityName": "Bibinagar",
      "CityValue": "Bibinagar"
    },
    {
      "id": 5836,
      "CityName": "Borpukhuri",
      "CityValue": "Borpukhuri"
    },
    {
      "id": 5837,
      "CityName": "Pimpalgaon",
      "CityValue": "Pimpalgaon"
    },
    {
      "id": 5838,
      "CityName": "V.Pudupatti",
      "CityValue": "V.Pudupatti"
    },
    {
      "id": 5839,
      "CityName": "Alang",
      "CityValue": "Alang"
    },
    {
      "id": 5840,
      "CityName": "Gonikoppal",
      "CityValue": "Gonikoppal"
    },
    {
      "id": 5841,
      "CityName": "Kasba Sultanpur",
      "CityValue": "Kasba Sultanpur"
    },
    {
      "id": 5842,
      "CityName": "Deogiri",
      "CityValue": "Deogiri"
    },
    {
      "id": 5843,
      "CityName": "Karnaprayag",
      "CityValue": "Karnaprayag"
    },
    {
      "id": 5844,
      "CityName": "Piploda",
      "CityValue": "Piploda"
    },
    {
      "id": 5845,
      "CityName": "Hiranagar",
      "CityValue": "Hiranagar"
    },
    {
      "id": 5846,
      "CityName": "Rengkai",
      "CityValue": "Rengkai"
    },
    {
      "id": 5847,
      "CityName": "Majhgawan",
      "CityValue": "Majhgawan"
    },
    {
      "id": 5848,
      "CityName": "Nandesari",
      "CityValue": "Nandesari"
    },
    {
      "id": 5849,
      "CityName": "Simaluguri",
      "CityValue": "Simaluguri"
    },
    {
      "id": 5850,
      "CityName": "Behlana",
      "CityValue": "Behlana"
    },
    {
      "id": 5851,
      "CityName": "Maruthancode",
      "CityValue": "Maruthancode"
    },
    {
      "id": 5852,
      "CityName": "Isnapur",
      "CityValue": "Isnapur"
    },
    {
      "id": 5853,
      "CityName": "Darappur",
      "CityValue": "Darappur"
    },
    {
      "id": 5854,
      "CityName": "Kilvelur",
      "CityValue": "Kilvelur"
    },
    {
      "id": 5855,
      "CityName": "Uttar Mahammadpur",
      "CityValue": "Uttar Mahammadpur"
    },
    {
      "id": 5856,
      "CityName": "Bohari",
      "CityValue": "Bohari"
    },
    {
      "id": 5857,
      "CityName": "Maliya Guda",
      "CityValue": "Maliya Guda"
    },
    {
      "id": 5858,
      "CityName": "Saraiya",
      "CityValue": "Saraiya"
    },
    {
      "id": 5859,
      "CityName": "Varadarajanpettai",
      "CityValue": "Varadarajanpettai"
    },
    {
      "id": 5860,
      "CityName": "Surala",
      "CityValue": "Surala"
    },
    {
      "id": 5861,
      "CityName": "Konra",
      "CityValue": "Konra"
    },
    {
      "id": 5862,
      "CityName": "Borim",
      "CityValue": "Borim"
    },
    {
      "id": 5863,
      "CityName": "Itwa",
      "CityValue": "Itwa"
    },
    {
      "id": 5864,
      "CityName": "Khera Kalan",
      "CityValue": "Khera Kalan"
    },
    {
      "id": 5865,
      "CityName": "Tangellamudi",
      "CityValue": "Tangellamudi"
    },
    {
      "id": 5866,
      "CityName": "Nideban",
      "CityValue": "Nideban"
    },
    {
      "id": 5867,
      "CityName": "Niwas",
      "CityValue": "Niwas"
    },
    {
      "id": 5868,
      "CityName": "Raigachhi",
      "CityValue": "Raigachhi"
    },
    {
      "id": 5869,
      "CityName": "Golokganj",
      "CityValue": "Golokganj"
    },
    {
      "id": 5870,
      "CityName": "Dulhipur",
      "CityValue": "Dulhipur"
    },
    {
      "id": 5871,
      "CityName": "Sri Hargobindpur",
      "CityValue": "Sri Hargobindpur"
    },
    {
      "id": 5872,
      "CityName": "Religara Alias Pachhiari",
      "CityValue": "Religara Alias Pachhiari"
    },
    {
      "id": 5873,
      "CityName": "Chhurikala",
      "CityValue": "Chhurikala"
    },
    {
      "id": 5874,
      "CityName": "Jasai",
      "CityValue": "Jasai"
    },
    {
      "id": 5875,
      "CityName": "Birbhanpur",
      "CityValue": "Birbhanpur"
    },
    {
      "id": 5876,
      "CityName": "Seer Hamdan",
      "CityValue": "Seer Hamdan"
    },
    {
      "id": 5877,
      "CityName": "Jaisinghnagar",
      "CityValue": "Jaisinghnagar"
    },
    {
      "id": 5878,
      "CityName": "Malkera",
      "CityValue": "Malkera"
    },
    {
      "id": 5879,
      "CityName": "Chinniam palayam",
      "CityValue": "Chinniam palayam"
    },
    {
      "id": 5880,
      "CityName": "Melaparthibanur",
      "CityValue": "Melaparthibanur"
    },
    {
      "id": 5881,
      "CityName": "Chatibor Gaon",
      "CityValue": "Chatibor Gaon"
    },
    {
      "id": 5882,
      "CityName": "Mehmand",
      "CityValue": "Mehmand"
    },
    {
      "id": 5883,
      "CityName": "Thedavur",
      "CityValue": "Thedavur"
    },
    {
      "id": 5884,
      "CityName": "Usuppur",
      "CityValue": "Usuppur"
    },
    {
      "id": 5885,
      "CityName": "Lawsohtun",
      "CityValue": "Lawsohtun"
    },
    {
      "id": 5886,
      "CityName": "Tundra",
      "CityValue": "Tundra"
    },
    {
      "id": 5887,
      "CityName": "Lailunga",
      "CityValue": "Lailunga"
    },
    {
      "id": 5888,
      "CityName": "Jawar",
      "CityValue": "Jawar"
    },
    {
      "id": 5889,
      "CityName": "Krishnapur",
      "CityValue": "Krishnapur"
    },
    {
      "id": 5890,
      "CityName": "Kangrali",
      "CityValue": "Kangrali"
    },
    {
      "id": 5891,
      "CityName": "Umroi",
      "CityValue": "Umroi"
    },
    {
      "id": 5892,
      "CityName": "Ramchandrapur",
      "CityValue": "Ramchandrapur"
    },
    {
      "id": 5893,
      "CityName": "Hafizpur",
      "CityValue": "Hafizpur"
    },
    {
      "id": 5894,
      "CityName": "Nagdaha",
      "CityValue": "Nagdaha"
    },
    {
      "id": 5895,
      "CityName": "Beerwah",
      "CityValue": "Beerwah"
    },
    {
      "id": 5896,
      "CityName": "Chain Pur",
      "CityValue": "Chain Pur"
    },
    {
      "id": 5897,
      "CityName": "Karanpur",
      "CityValue": "Karanpur"
    },
    {
      "id": 5898,
      "CityName": "Hingalganj",
      "CityValue": "Hingalganj"
    },
    {
      "id": 5899,
      "CityName": "Vilapakkam",
      "CityValue": "Vilapakkam"
    },
    {
      "id": 5900,
      "CityName": "Hafania",
      "CityValue": "Hafania"
    },
    {
      "id": 5901,
      "CityName": "Priol",
      "CityValue": "Priol"
    },
    {
      "id": 5902,
      "CityName": "Haldwani Talli",
      "CityValue": "Haldwani Talli"
    },
    {
      "id": 5903,
      "CityName": "Digha",
      "CityValue": "Digha"
    },
    {
      "id": 5904,
      "CityName": "Kandra",
      "CityValue": "Kandra"
    },
    {
      "id": 5905,
      "CityName": "Dhilwan",
      "CityValue": "Dhilwan"
    },
    {
      "id": 5906,
      "CityName": "Mudushedde",
      "CityValue": "Mudushedde"
    },
    {
      "id": 5907,
      "CityName": "Majgaon",
      "CityValue": "Majgaon"
    },
    {
      "id": 5908,
      "CityName": "Manushpur",
      "CityValue": "Manushpur"
    },
    {
      "id": 5909,
      "CityName": "Krishna Chandrapur",
      "CityValue": "Krishna Chandrapur"
    },
    {
      "id": 5910,
      "CityName": "Vellookkara",
      "CityValue": "Vellookkara"
    },
    {
      "id": 5911,
      "CityName": "Abiramam",
      "CityValue": "Abiramam"
    },
    {
      "id": 5912,
      "CityName": "Barman Kalan",
      "CityValue": "Barman Kalan"
    },
    {
      "id": 5913,
      "CityName": "Jena",
      "CityValue": "Jena"
    },
    {
      "id": 5914,
      "CityName": "Sanivarapupeta",
      "CityValue": "Sanivarapupeta"
    },
    {
      "id": 5915,
      "CityName": "Makkinampatti",
      "CityValue": "Makkinampatti"
    },
    {
      "id": 5916,
      "CityName": "Samuktola",
      "CityValue": "Samuktola"
    },
    {
      "id": 5917,
      "CityName": "Melattur",
      "CityValue": "Melattur"
    },
    {
      "id": 5918,
      "CityName": "Lamsang",
      "CityValue": "Lamsang"
    },
    {
      "id": 5919,
      "CityName": "Bhanupratappur",
      "CityValue": "Bhanupratappur"
    },
    {
      "id": 5920,
      "CityName": "Kovalam",
      "CityValue": "Kovalam"
    },
    {
      "id": 5921,
      "CityName": "Jaffrabad",
      "CityValue": "Jaffrabad"
    },
    {
      "id": 5922,
      "CityName": "Bishnupur",
      "CityValue": "Bishnupur"
    },
    {
      "id": 5923,
      "CityName": "Nawagarh",
      "CityValue": "Nawagarh"
    },
    {
      "id": 5924,
      "CityName": "Reodar",
      "CityValue": "Reodar"
    },
    {
      "id": 5925,
      "CityName": "Bada",
      "CityValue": "Bada"
    },
    {
      "id": 5926,
      "CityName": "Naginimora",
      "CityValue": "Naginimora"
    },
    {
      "id": 5927,
      "CityName": "Bandipur",
      "CityValue": "Bandipur"
    },
    {
      "id": 5928,
      "CityName": "Udma",
      "CityValue": "Udma"
    },
    {
      "id": 5929,
      "CityName": "Mangasamudram",
      "CityValue": "Mangasamudram"
    },
    {
      "id": 5930,
      "CityName": "Sarbhog",
      "CityValue": "Sarbhog"
    },
    {
      "id": 5931,
      "CityName": "Chennasamudram",
      "CityValue": "Chennasamudram"
    },
    {
      "id": 5932,
      "CityName": "Katra",
      "CityValue": "Katra"
    },
    {
      "id": 5933,
      "CityName": "Fatepur",
      "CityValue": "Fatepur"
    },
    {
      "id": 5934,
      "CityName": "Saha",
      "CityValue": "Saha"
    },
    {
      "id": 5935,
      "CityName": "Hirdepur",
      "CityValue": "Hirdepur"
    },
    {
      "id": 5936,
      "CityName": "Manali",
      "CityValue": "Manali"
    },
    {
      "id": 5937,
      "CityName": "Dharapur",
      "CityValue": "Dharapur"
    },
    {
      "id": 5938,
      "CityName": "Sakit",
      "CityValue": "Sakit"
    },
    {
      "id": 5939,
      "CityName": "Chak Barbaria",
      "CityValue": "Chak Barbaria"
    },
    {
      "id": 5940,
      "CityName": "Vehicle Factory Area Jabalpur",
      "CityValue": "Vehicle Factory Area Jabalpur"
    },
    {
      "id": 5941,
      "CityName": "Gokak Falls",
      "CityValue": "Gokak Falls"
    },
    {
      "id": 5942,
      "CityName": "Nasvadi",
      "CityValue": "Nasvadi"
    },
    {
      "id": 5943,
      "CityName": "Tajpura",
      "CityValue": "Tajpura"
    },
    {
      "id": 5944,
      "CityName": "Satghara",
      "CityValue": "Satghara"
    },
    {
      "id": 5945,
      "CityName": "Nuapatna",
      "CityValue": "Nuapatna"
    },
    {
      "id": 5946,
      "CityName": "Kumar Kaibarta Gaon",
      "CityValue": "Kumar Kaibarta Gaon"
    },
    {
      "id": 5947,
      "CityName": "Barabazar",
      "CityValue": "Barabazar"
    },
    {
      "id": 5948,
      "CityName": "Wangjing",
      "CityValue": "Wangjing"
    },
    {
      "id": 5949,
      "CityName": "Kolki",
      "CityValue": "Kolki"
    },
    {
      "id": 5950,
      "CityName": "Kunwargaon",
      "CityValue": "Kunwargaon"
    },
    {
      "id": 5951,
      "CityName": "Reis Magos",
      "CityValue": "Reis Magos"
    },
    {
      "id": 5952,
      "CityName": "Rupahi Town",
      "CityValue": "Rupahi Town"
    },
    {
      "id": 5953,
      "CityName": "Vijay Pur",
      "CityValue": "Vijay Pur"
    },
    {
      "id": 5954,
      "CityName": "Umrala",
      "CityValue": "Umrala"
    },
    {
      "id": 5955,
      "CityName": "Peddikuppam",
      "CityValue": "Peddikuppam"
    },
    {
      "id": 5956,
      "CityName": "Doundi",
      "CityValue": "Doundi"
    },
    {
      "id": 5957,
      "CityName": "Edakkalathur",
      "CityValue": "Edakkalathur"
    },
    {
      "id": 5958,
      "CityName": "Rishikesh",
      "CityValue": "Rishikesh"
    },
    {
      "id": 5959,
      "CityName": "Sirukaveripakkam",
      "CityValue": "Sirukaveripakkam"
    },
    {
      "id": 5960,
      "CityName": "Bhimarayanagudi",
      "CityValue": "Bhimarayanagudi"
    },
    {
      "id": 5961,
      "CityName": "Sarimpur",
      "CityValue": "Sarimpur"
    },
    {
      "id": 5962,
      "CityName": "Lapanga",
      "CityValue": "Lapanga"
    },
    {
      "id": 5963,
      "CityName": "Nimpith",
      "CityValue": "Nimpith"
    },
    {
      "id": 5964,
      "CityName": "Pudiyamputhur",
      "CityValue": "Pudiyamputhur"
    },
    {
      "id": 5965,
      "CityName": "Kalmath",
      "CityValue": "Kalmath"
    },
    {
      "id": 5966,
      "CityName": "Kadipur",
      "CityValue": "Kadipur"
    },
    {
      "id": 5967,
      "CityName": "North Guwahati",
      "CityValue": "North Guwahati"
    },
    {
      "id": 5968,
      "CityName": "Masat",
      "CityValue": "Masat"
    },
    {
      "id": 5969,
      "CityName": "Iduvai",
      "CityValue": "Iduvai"
    },
    {
      "id": 5970,
      "CityName": "Perur",
      "CityValue": "Perur"
    },
    {
      "id": 5971,
      "CityName": "Amguri",
      "CityValue": "Amguri"
    },
    {
      "id": 5972,
      "CityName": "Arjyapalli",
      "CityValue": "Arjyapalli"
    },
    {
      "id": 5973,
      "CityName": "Muzhucode",
      "CityValue": "Muzhucode"
    },
    {
      "id": 5974,
      "CityName": "Musafirkhana",
      "CityValue": "Musafirkhana"
    },
    {
      "id": 5975,
      "CityName": "Ratangarh",
      "CityValue": "Ratangarh"
    },
    {
      "id": 5976,
      "CityName": "Suvani",
      "CityValue": "Suvani"
    },
    {
      "id": 5977,
      "CityName": "Nadigaon",
      "CityValue": "Nadigaon"
    },
    {
      "id": 5978,
      "CityName": "Banbasa",
      "CityValue": "Banbasa"
    },
    {
      "id": 5979,
      "CityName": "Khurai Sajor Leikai",
      "CityValue": "Khurai Sajor Leikai"
    },
    {
      "id": 5980,
      "CityName": "Gamiria Sagar",
      "CityValue": "Gamiria Sagar"
    },
    {
      "id": 5981,
      "CityName": "Tanr Balidih",
      "CityValue": "Tanr Balidih"
    },
    {
      "id": 5982,
      "CityName": "Tonk Khurd",
      "CityValue": "Tonk Khurd"
    },
    {
      "id": 5983,
      "CityName": "Mahindale",
      "CityValue": "Mahindale"
    },
    {
      "id": 5984,
      "CityName": "Iklehra",
      "CityValue": "Iklehra"
    },
    {
      "id": 5985,
      "CityName": "Ambicapur Pt VI",
      "CityValue": "Ambicapur Pt VI"
    },
    {
      "id": 5986,
      "CityName": "Chaurhat",
      "CityValue": "Chaurhat"
    },
    {
      "id": 5987,
      "CityName": "Talkhapur Dumra",
      "CityValue": "Talkhapur Dumra"
    },
    {
      "id": 5988,
      "CityName": "Bambooflat",
      "CityValue": "Bambooflat"
    },
    {
      "id": 5989,
      "CityName": "Mugkalyan",
      "CityValue": "Mugkalyan"
    },
    {
      "id": 5990,
      "CityName": "Valapattanam",
      "CityValue": "Valapattanam"
    },
    {
      "id": 5991,
      "CityName": "Nidanpur Pt-II",
      "CityValue": "Nidanpur Pt-II"
    },
    {
      "id": 5992,
      "CityName": "Diken",
      "CityValue": "Diken"
    },
    {
      "id": 5993,
      "CityName": "Purbba Narayanpur",
      "CityValue": "Purbba Narayanpur"
    },
    {
      "id": 5994,
      "CityName": "Veppathur",
      "CityValue": "Veppathur"
    },
    {
      "id": 5995,
      "CityName": "Jaijepur",
      "CityValue": "Jaijepur"
    },
    {
      "id": 5996,
      "CityName": "Tira Sujanpur",
      "CityValue": "Tira Sujanpur"
    },
    {
      "id": 5997,
      "CityName": "Basettihalli",
      "CityValue": "Basettihalli"
    },
    {
      "id": 5998,
      "CityName": "Hanspura",
      "CityValue": "Hanspura"
    },
    {
      "id": 5999,
      "CityName": "Kherli Hafizpur",
      "CityValue": "Kherli Hafizpur"
    },
    {
      "id": 6000,
      "CityName": "Katra Medniganj",
      "CityValue": "Katra Medniganj"
    },
    {
      "id": 6001,
      "CityName": "Rasulabad",
      "CityValue": "Rasulabad"
    },
    {
      "id": 6002,
      "CityName": "Lohaghat",
      "CityValue": "Lohaghat"
    },
    {
      "id": 6003,
      "CityName": "Ramgarh",
      "CityValue": "Ramgarh"
    },
    {
      "id": 6004,
      "CityName": "Jamshila",
      "CityValue": "Jamshila"
    },
    {
      "id": 6005,
      "CityName": "Payal",
      "CityValue": "Payal"
    },
    {
      "id": 6006,
      "CityName": "Hijuli",
      "CityValue": "Hijuli"
    },
    {
      "id": 6007,
      "CityName": "Barika Chuburi",
      "CityValue": "Barika Chuburi"
    },
    {
      "id": 6008,
      "CityName": "Tonse East",
      "CityValue": "Tonse East"
    },
    {
      "id": 6009,
      "CityName": "Dihimandalghat",
      "CityValue": "Dihimandalghat"
    },
    {
      "id": 6010,
      "CityName": "Amodghata",
      "CityValue": "Amodghata"
    },
    {
      "id": 6011,
      "CityName": "Puvalur",
      "CityValue": "Puvalur"
    },
    {
      "id": 6012,
      "CityName": "Kanwat",
      "CityValue": "Kanwat"
    },
    {
      "id": 6013,
      "CityName": "Alamelumangapuram",
      "CityValue": "Alamelumangapuram"
    },
    {
      "id": 6014,
      "CityName": "Ghumarwin",
      "CityValue": "Ghumarwin"
    },
    {
      "id": 6015,
      "CityName": "Purihasa",
      "CityValue": "Purihasa"
    },
    {
      "id": 6016,
      "CityName": "Garhi Harsaru",
      "CityValue": "Garhi Harsaru"
    },
    {
      "id": 6017,
      "CityName": "Madanpur Rampur",
      "CityValue": "Madanpur Rampur"
    },
    {
      "id": 6018,
      "CityName": "Dehari",
      "CityValue": "Dehari"
    },
    {
      "id": 6019,
      "CityName": "Madiya",
      "CityValue": "Madiya"
    },
    {
      "id": 6020,
      "CityName": "Kottagoundampatty",
      "CityValue": "Kottagoundampatty"
    },
    {
      "id": 6021,
      "CityName": "Erei",
      "CityValue": "Erei"
    },
    {
      "id": 6022,
      "CityName": "Chintapalle",
      "CityValue": "Chintapalle"
    },
    {
      "id": 6023,
      "CityName": "Barwadih",
      "CityValue": "Barwadih"
    },
    {
      "id": 6024,
      "CityName": "Mamit",
      "CityValue": "Mamit"
    },
    {
      "id": 6025,
      "CityName": "Patholi",
      "CityValue": "Patholi"
    },
    {
      "id": 6026,
      "CityName": "Avanur",
      "CityValue": "Avanur"
    },
    {
      "id": 6027,
      "CityName": "Ordnance Factory Itarsi",
      "CityValue": "Ordnance Factory Itarsi"
    },
    {
      "id": 6028,
      "CityName": "Shangus",
      "CityValue": "Shangus"
    },
    {
      "id": 6029,
      "CityName": "Sewai",
      "CityValue": "Sewai"
    },
    {
      "id": 6030,
      "CityName": "Rashidpur Garhi",
      "CityValue": "Rashidpur Garhi"
    },
    {
      "id": 6031,
      "CityName": "Tuli",
      "CityValue": "Tuli"
    },
    {
      "id": 6032,
      "CityName": "Jetpur",
      "CityValue": "Jetpur"
    },
    {
      "id": 6033,
      "CityName": "Cherpu",
      "CityValue": "Cherpu"
    },
    {
      "id": 6034,
      "CityName": "Gangadharpur",
      "CityValue": "Gangadharpur"
    },
    {
      "id": 6035,
      "CityName": "Bandhgora",
      "CityValue": "Bandhgora"
    },
    {
      "id": 6036,
      "CityName": "Sehara",
      "CityValue": "Sehara"
    },
    {
      "id": 6037,
      "CityName": "Naupala",
      "CityValue": "Naupala"
    },
    {
      "id": 6038,
      "CityName": "Dabhra",
      "CityValue": "Dabhra"
    },
    {
      "id": 6039,
      "CityName": "Ibrahimpur",
      "CityValue": "Ibrahimpur"
    },
    {
      "id": 6040,
      "CityName": "Pottore",
      "CityValue": "Pottore"
    },
    {
      "id": 6041,
      "CityName": "Tatarpur Lallu",
      "CityValue": "Tatarpur Lallu"
    },
    {
      "id": 6042,
      "CityName": "B. Meenakshipuram",
      "CityValue": "B. Meenakshipuram"
    },
    {
      "id": 6043,
      "CityName": "Kharimala Khagrabari",
      "CityValue": "Kharimala Khagrabari"
    },
    {
      "id": 6044,
      "CityName": "Ghorsala",
      "CityValue": "Ghorsala"
    },
    {
      "id": 6045,
      "CityName": "Pardi Sondhpur",
      "CityValue": "Pardi Sondhpur"
    },
    {
      "id": 6046,
      "CityName": "Majdia",
      "CityValue": "Majdia"
    },
    {
      "id": 6047,
      "CityName": "Nallur",
      "CityValue": "Nallur"
    },
    {
      "id": 6048,
      "CityName": "Elathur",
      "CityValue": "Elathur"
    },
    {
      "id": 6049,
      "CityName": "Sanniyasigundu",
      "CityValue": "Sanniyasigundu"
    },
    {
      "id": 6050,
      "CityName": "Gopalpur",
      "CityValue": "Gopalpur"
    },
    {
      "id": 6051,
      "CityName": "Alawalpur",
      "CityValue": "Alawalpur"
    },
    {
      "id": 6052,
      "CityName": "Uppinangady",
      "CityValue": "Uppinangady"
    },
    {
      "id": 6053,
      "CityName": "Malayadi",
      "CityValue": "Malayadi"
    },
    {
      "id": 6054,
      "CityName": "Arra",
      "CityValue": "Arra"
    },
    {
      "id": 6055,
      "CityName": "Zadgaon",
      "CityValue": "Zadgaon"
    },
    {
      "id": 6056,
      "CityName": "Yerrabalem",
      "CityValue": "Yerrabalem"
    },
    {
      "id": 6057,
      "CityName": "Narikombu",
      "CityValue": "Narikombu"
    },
    {
      "id": 6058,
      "CityName": "Mathurapur",
      "CityValue": "Mathurapur"
    },
    {
      "id": 6059,
      "CityName": "Reasi",
      "CityValue": "Reasi"
    },
    {
      "id": 6060,
      "CityName": "Perumagoundampatti",
      "CityValue": "Perumagoundampatti"
    },
    {
      "id": 6061,
      "CityName": "Qasimpur Power House Colony",
      "CityValue": "Qasimpur Power House Colony"
    },
    {
      "id": 6062,
      "CityName": "Kurpania",
      "CityValue": "Kurpania"
    },
    {
      "id": 6063,
      "CityName": "Jhakal Mandi",
      "CityValue": "Jhakal Mandi"
    },
    {
      "id": 6064,
      "CityName": "Vilacheri",
      "CityValue": "Vilacheri"
    },
    {
      "id": 6065,
      "CityName": "Paniara",
      "CityValue": "Paniara"
    },
    {
      "id": 6066,
      "CityName": "Chettithangal",
      "CityValue": "Chettithangal"
    },
    {
      "id": 6067,
      "CityName": "Meru",
      "CityValue": "Meru"
    },
    {
      "id": 6068,
      "CityName": "Gagalhedi Must.",
      "CityValue": "Gagalhedi Must."
    },
    {
      "id": 6069,
      "CityName": "Sadpur",
      "CityValue": "Sadpur"
    },
    {
      "id": 6070,
      "CityName": "Bhabki",
      "CityValue": "Bhabki"
    },
    {
      "id": 6071,
      "CityName": "Chamba",
      "CityValue": "Chamba"
    },
    {
      "id": 6072,
      "CityName": "Zenhang Lamka",
      "CityValue": "Zenhang Lamka"
    },
    {
      "id": 6073,
      "CityName": "Dandidih",
      "CityValue": "Dandidih"
    },
    {
      "id": 6074,
      "CityName": "Mallar",
      "CityValue": "Mallar"
    },
    {
      "id": 6075,
      "CityName": "Dwari Geria",
      "CityValue": "Dwari Geria"
    },
    {
      "id": 6076,
      "CityName": "Chandrapada",
      "CityValue": "Chandrapada"
    },
    {
      "id": 6077,
      "CityName": "Achalganj",
      "CityValue": "Achalganj"
    },
    {
      "id": 6078,
      "CityName": "Kannanoor",
      "CityValue": "Kannanoor"
    },
    {
      "id": 6079,
      "CityName": "Beltangadi",
      "CityValue": "Beltangadi"
    },
    {
      "id": 6080,
      "CityName": "Vallam",
      "CityValue": "Vallam"
    },
    {
      "id": 6081,
      "CityName": "Kuldanga",
      "CityValue": "Kuldanga"
    },
    {
      "id": 6082,
      "CityName": "Tirumala",
      "CityValue": "Tirumala"
    },
    {
      "id": 6083,
      "CityName": "Nokpul",
      "CityValue": "Nokpul"
    },
    {
      "id": 6084,
      "CityName": "Govindgarh",
      "CityValue": "Govindgarh"
    },
    {
      "id": 6085,
      "CityName": "Mirzapur",
      "CityValue": "Mirzapur"
    },
    {
      "id": 6086,
      "CityName": "N. Kawnpui",
      "CityValue": "N. Kawnpui"
    },
    {
      "id": 6087,
      "CityName": "Dalavaipatti",
      "CityValue": "Dalavaipatti"
    },
    {
      "id": 6088,
      "CityName": "Chaspara",
      "CityValue": "Chaspara"
    },
    {
      "id": 6089,
      "CityName": "Ammanur",
      "CityValue": "Ammanur"
    },
    {
      "id": 6090,
      "CityName": "Palissery",
      "CityValue": "Palissery"
    },
    {
      "id": 6091,
      "CityName": "Bhimtal",
      "CityValue": "Bhimtal"
    },
    {
      "id": 6092,
      "CityName": "Fateh Nangal",
      "CityValue": "Fateh Nangal"
    },
    {
      "id": 6093,
      "CityName": "Pallippuram",
      "CityValue": "Pallippuram"
    },
    {
      "id": 6094,
      "CityName": "Harpalpur",
      "CityValue": "Harpalpur"
    },
    {
      "id": 6095,
      "CityName": "Bhavanisagar",
      "CityValue": "Bhavanisagar"
    },
    {
      "id": 6096,
      "CityName": "Uppiliapuram",
      "CityValue": "Uppiliapuram"
    },
    {
      "id": 6097,
      "CityName": "Tangapur",
      "CityValue": "Tangapur"
    },
    {
      "id": 6098,
      "CityName": "Virinchipuram",
      "CityValue": "Virinchipuram"
    },
    {
      "id": 6099,
      "CityName": "Hyderabad",
      "CityValue": "Hyderabad"
    },
    {
      "id": 6100,
      "CityName": "Nijampur",
      "CityValue": "Nijampur"
    },
    {
      "id": 6101,
      "CityName": "Kandanur",
      "CityValue": "Kandanur"
    },
    {
      "id": 6102,
      "CityName": "Santoshpur",
      "CityValue": "Santoshpur"
    },
    {
      "id": 6103,
      "CityName": "Chandrapur",
      "CityValue": "Chandrapur"
    },
    {
      "id": 6104,
      "CityName": "Irongmara",
      "CityValue": "Irongmara"
    },
    {
      "id": 6105,
      "CityName": "Jot Kamal",
      "CityValue": "Jot Kamal"
    },
    {
      "id": 6106,
      "CityName": "Yewalewadi",
      "CityValue": "Yewalewadi"
    },
    {
      "id": 6107,
      "CityName": "Harduli",
      "CityValue": "Harduli"
    },
    {
      "id": 6108,
      "CityName": "Parole",
      "CityValue": "Parole"
    },
    {
      "id": 6109,
      "CityName": "Mettupalayam",
      "CityValue": "Mettupalayam"
    },
    {
      "id": 6110,
      "CityName": "Jhagrakhand",
      "CityValue": "Jhagrakhand"
    },
    {
      "id": 6111,
      "CityName": "Akalgarh",
      "CityValue": "Akalgarh"
    },
    {
      "id": 6112,
      "CityName": "Kulappuram",
      "CityValue": "Kulappuram"
    },
    {
      "id": 6113,
      "CityName": "Vaitheeswarankoil",
      "CityValue": "Vaitheeswarankoil"
    },
    {
      "id": 6114,
      "CityName": "Tarichar Kalan",
      "CityValue": "Tarichar Kalan"
    },
    {
      "id": 6115,
      "CityName": "Nattalam",
      "CityValue": "Nattalam"
    },
    {
      "id": 6116,
      "CityName": "Faridpur",
      "CityValue": "Faridpur"
    },
    {
      "id": 6117,
      "CityName": "Mundathikode",
      "CityValue": "Mundathikode"
    },
    {
      "id": 6118,
      "CityName": "Bhoogar",
      "CityValue": "Bhoogar"
    },
    {
      "id": 6119,
      "CityName": "Jamwa Ramgarh",
      "CityValue": "Jamwa Ramgarh"
    },
    {
      "id": 6120,
      "CityName": "Tilthi",
      "CityValue": "Tilthi"
    },
    {
      "id": 6121,
      "CityName": "Hansghara",
      "CityValue": "Hansghara"
    },
    {
      "id": 6122,
      "CityName": "Virudampattu",
      "CityValue": "Virudampattu"
    },
    {
      "id": 6123,
      "CityName": "Amini",
      "CityValue": "Amini"
    },
    {
      "id": 6124,
      "CityName": "Thorapadi",
      "CityValue": "Thorapadi"
    },
    {
      "id": 6125,
      "CityName": "Barpathar",
      "CityValue": "Barpathar"
    },
    {
      "id": 6126,
      "CityName": "Kodannur",
      "CityValue": "Kodannur"
    },
    {
      "id": 6127,
      "CityName": "Lalkuan",
      "CityValue": "Lalkuan"
    },
    {
      "id": 6128,
      "CityName": "Qutab Garh",
      "CityValue": "Qutab Garh"
    },
    {
      "id": 6129,
      "CityName": "Shiribagilu",
      "CityValue": "Shiribagilu"
    },
    {
      "id": 6130,
      "CityName": "Bamun Sualkuchi",
      "CityValue": "Bamun Sualkuchi"
    },
    {
      "id": 6131,
      "CityName": "Waghoda",
      "CityValue": "Waghoda"
    },
    {
      "id": 6132,
      "CityName": "Mathurapur",
      "CityValue": "Mathurapur"
    },
    {
      "id": 6133,
      "CityName": "Aligarh",
      "CityValue": "Aligarh"
    },
    {
      "id": 6134,
      "CityName": "Rekurthi",
      "CityValue": "Rekurthi"
    },
    {
      "id": 6135,
      "CityName": "Egara",
      "CityValue": "Egara"
    },
    {
      "id": 6136,
      "CityName": "Pavali",
      "CityValue": "Pavali"
    },
    {
      "id": 6137,
      "CityName": "Vellottamparappu",
      "CityValue": "Vellottamparappu"
    },
    {
      "id": 6138,
      "CityName": "Manpur",
      "CityValue": "Manpur"
    },
    {
      "id": 6139,
      "CityName": "Rudayan",
      "CityValue": "Rudayan"
    },
    {
      "id": 6140,
      "CityName": "Ateli",
      "CityValue": "Ateli"
    },
    {
      "id": 6141,
      "CityName": "Therur",
      "CityValue": "Therur"
    },
    {
      "id": 6142,
      "CityName": "Longleng",
      "CityValue": "Longleng"
    },
    {
      "id": 6143,
      "CityName": "Chechakhata",
      "CityValue": "Chechakhata"
    },
    {
      "id": 6144,
      "CityName": "Garshyamnagar",
      "CityValue": "Garshyamnagar"
    },
    {
      "id": 6145,
      "CityName": "Kaladhungi",
      "CityValue": "Kaladhungi"
    },
    {
      "id": 6146,
      "CityName": "Muthukadu",
      "CityValue": "Muthukadu"
    },
    {
      "id": 6147,
      "CityName": "Gogapur",
      "CityValue": "Gogapur"
    },
    {
      "id": 6148,
      "CityName": "Umbar Pada Nandade",
      "CityValue": "Umbar Pada Nandade"
    },
    {
      "id": 6149,
      "CityName": "Jangalpur",
      "CityValue": "Jangalpur"
    },
    {
      "id": 6150,
      "CityName": "Nakkapalle",
      "CityValue": "Nakkapalle"
    },
    {
      "id": 6151,
      "CityName": "Periyakurichi",
      "CityValue": "Periyakurichi"
    },
    {
      "id": 6152,
      "CityName": "Gardhiwala",
      "CityValue": "Gardhiwala"
    },
    {
      "id": 6153,
      "CityName": "Rail",
      "CityValue": "Rail"
    },
    {
      "id": 6154,
      "CityName": "Ahmadabad Cantonment",
      "CityValue": "Ahmadabad Cantonment"
    },
    {
      "id": 6155,
      "CityName": "Rakh Gadi Garh",
      "CityValue": "Rakh Gadi Garh"
    },
    {
      "id": 6156,
      "CityName": "Sembianallur",
      "CityValue": "Sembianallur"
    },
    {
      "id": 6157,
      "CityName": "Jadupur",
      "CityValue": "Jadupur"
    },
    {
      "id": 6158,
      "CityName": "Kherwara Chhaoni",
      "CityValue": "Kherwara Chhaoni"
    },
    {
      "id": 6159,
      "CityName": "Kadhirvedu",
      "CityValue": "Kadhirvedu"
    },
    {
      "id": 6160,
      "CityName": "Nyotini",
      "CityValue": "Nyotini"
    },
    {
      "id": 6161,
      "CityName": "Gairkata",
      "CityValue": "Gairkata"
    },
    {
      "id": 6162,
      "CityName": "Murmadi",
      "CityValue": "Murmadi"
    },
    {
      "id": 6163,
      "CityName": "Bhagwanpur",
      "CityValue": "Bhagwanpur"
    },
    {
      "id": 6164,
      "CityName": "Ordnance Factory Muradnagar",
      "CityValue": "Ordnance Factory Muradnagar"
    },
    {
      "id": 6165,
      "CityName": "Maloud",
      "CityValue": "Maloud"
    },
    {
      "id": 6166,
      "CityName": "Padri",
      "CityValue": "Padri"
    },
    {
      "id": 6167,
      "CityName": "Gangapur",
      "CityValue": "Gangapur"
    },
    {
      "id": 6168,
      "CityName": "Mutga",
      "CityValue": "Mutga"
    },
    {
      "id": 6169,
      "CityName": "Dhola",
      "CityValue": "Dhola"
    },
    {
      "id": 6170,
      "CityName": "Paiyur",
      "CityValue": "Paiyur"
    },
    {
      "id": 6171,
      "CityName": "Kumaranellur",
      "CityValue": "Kumaranellur"
    },
    {
      "id": 6172,
      "CityName": "Bhakhara",
      "CityValue": "Bhakhara"
    },
    {
      "id": 6173,
      "CityName": "Gogipeth",
      "CityValue": "Gogipeth"
    },
    {
      "id": 6174,
      "CityName": "Kochpara",
      "CityValue": "Kochpara"
    },
    {
      "id": 6175,
      "CityName": "Barunda",
      "CityValue": "Barunda"
    },
    {
      "id": 6176,
      "CityName": "Gangadharpur",
      "CityValue": "Gangadharpur"
    },
    {
      "id": 6177,
      "CityName": "Derapur",
      "CityValue": "Derapur"
    },
    {
      "id": 6178,
      "CityName": "Kathera",
      "CityValue": "Kathera"
    },
    {
      "id": 6179,
      "CityName": "Changtongya",
      "CityValue": "Changtongya"
    },
    {
      "id": 6180,
      "CityName": "Chelakkara",
      "CityValue": "Chelakkara"
    },
    {
      "id": 6181,
      "CityName": "Chakeri",
      "CityValue": "Chakeri"
    },
    {
      "id": 6182,
      "CityName": "Kotar",
      "CityValue": "Kotar"
    },
    {
      "id": 6183,
      "CityName": "Nasibpur",
      "CityValue": "Nasibpur"
    },
    {
      "id": 6184,
      "CityName": "Murda",
      "CityValue": "Murda"
    },
    {
      "id": 6185,
      "CityName": "Radhakund",
      "CityValue": "Radhakund"
    },
    {
      "id": 6186,
      "CityName": "Sunari Kalan",
      "CityValue": "Sunari Kalan"
    },
    {
      "id": 6187,
      "CityName": "Chota Gamahria",
      "CityValue": "Chota Gamahria"
    },
    {
      "id": 6188,
      "CityName": "Natchiarkoil",
      "CityValue": "Natchiarkoil"
    },
    {
      "id": 6189,
      "CityName": "Gohand",
      "CityValue": "Gohand"
    },
    {
      "id": 6190,
      "CityName": "Naoriya Pakhanglakpa",
      "CityValue": "Naoriya Pakhanglakpa"
    },
    {
      "id": 6191,
      "CityName": "Nimbhore Budruk",
      "CityValue": "Nimbhore Budruk"
    },
    {
      "id": 6192,
      "CityName": "Eksara",
      "CityValue": "Eksara"
    },
    {
      "id": 6193,
      "CityName": "Borgaon",
      "CityValue": "Borgaon"
    },
    {
      "id": 6194,
      "CityName": "Malanpur",
      "CityValue": "Malanpur"
    },
    {
      "id": 6195,
      "CityName": "Kegaon",
      "CityValue": "Kegaon"
    },
    {
      "id": 6196,
      "CityName": "Kuruppanaickenpalayam",
      "CityValue": "Kuruppanaickenpalayam"
    },
    {
      "id": 6197,
      "CityName": "Sargaon",
      "CityValue": "Sargaon"
    },
    {
      "id": 6198,
      "CityName": "P.J. Cholapuram",
      "CityValue": "P.J. Cholapuram"
    },
    {
      "id": 6199,
      "CityName": "Jajanga",
      "CityValue": "Jajanga"
    },
    {
      "id": 6200,
      "CityName": "R.Pudupatti",
      "CityValue": "R.Pudupatti"
    },
    {
      "id": 6201,
      "CityName": "Jangalpara",
      "CityValue": "Jangalpara"
    },
    {
      "id": 6202,
      "CityName": "Kangpokpi",
      "CityValue": "Kangpokpi"
    },
    {
      "id": 6203,
      "CityName": "Chelad",
      "CityValue": "Chelad"
    },
    {
      "id": 6204,
      "CityName": "Dakshin Khagrabari",
      "CityValue": "Dakshin Khagrabari"
    },
    {
      "id": 6205,
      "CityName": "Ichgam",
      "CityValue": "Ichgam"
    },
    {
      "id": 6206,
      "CityName": "Narasimharajapura",
      "CityValue": "Narasimharajapura"
    },
    {
      "id": 6207,
      "CityName": "Tholur",
      "CityValue": "Tholur"
    },
    {
      "id": 6208,
      "CityName": "Ukai",
      "CityValue": "Ukai"
    },
    {
      "id": 6209,
      "CityName": "Tarapur",
      "CityValue": "Tarapur"
    },
    {
      "id": 6210,
      "CityName": "Sanjeli",
      "CityValue": "Sanjeli"
    },
    {
      "id": 6211,
      "CityName": "Kusmi",
      "CityValue": "Kusmi"
    },
    {
      "id": 6212,
      "CityName": "Indragarh",
      "CityValue": "Indragarh"
    },
    {
      "id": 6213,
      "CityName": "Vengampudur",
      "CityValue": "Vengampudur"
    },
    {
      "id": 6214,
      "CityName": "Marwad",
      "CityValue": "Marwad"
    },
    {
      "id": 6215,
      "CityName": "Geedam",
      "CityValue": "Geedam"
    },
    {
      "id": 6216,
      "CityName": "Xeldem",
      "CityValue": "Xeldem"
    },
    {
      "id": 6217,
      "CityName": "Thokur-62",
      "CityValue": "Thokur-62"
    },
    {
      "id": 6218,
      "CityName": "Jirat",
      "CityValue": "Jirat"
    },
    {
      "id": 6219,
      "CityName": "Sirgora",
      "CityValue": "Sirgora"
    },
    {
      "id": 6220,
      "CityName": "Venkatapuram",
      "CityValue": "Venkatapuram"
    },
    {
      "id": 6221,
      "CityName": "Kurkheda",
      "CityValue": "Kurkheda"
    },
    {
      "id": 6222,
      "CityName": "Manirampur",
      "CityValue": "Manirampur"
    },
    {
      "id": 6223,
      "CityName": "Sironcha Ry.",
      "CityValue": "Sironcha Ry."
    },
    {
      "id": 6224,
      "CityName": "Alamathi",
      "CityValue": "Alamathi"
    },
    {
      "id": 6225,
      "CityName": "Iravadanallur",
      "CityValue": "Iravadanallur"
    },
    {
      "id": 6226,
      "CityName": "Gotamara",
      "CityValue": "Gotamara"
    },
    {
      "id": 6227,
      "CityName": "Kot Fatta",
      "CityValue": "Kot Fatta"
    },
    {
      "id": 6228,
      "CityName": "Lathikata",
      "CityValue": "Lathikata"
    },
    {
      "id": 6229,
      "CityName": "Narsingarh",
      "CityValue": "Narsingarh"
    },
    {
      "id": 6230,
      "CityName": "Tundla Railway Colony",
      "CityValue": "Tundla Railway Colony"
    },
    {
      "id": 6231,
      "CityName": "Baniban Jagadishpur",
      "CityValue": "Baniban Jagadishpur"
    },
    {
      "id": 6232,
      "CityName": "Athivilai",
      "CityValue": "Athivilai"
    },
    {
      "id": 6233,
      "CityName": "Kannamangalam",
      "CityValue": "Kannamangalam"
    },
    {
      "id": 6234,
      "CityName": "Sakaldiha",
      "CityValue": "Sakaldiha"
    },
    {
      "id": 6235,
      "CityName": "Tumgaon",
      "CityValue": "Tumgaon"
    },
    {
      "id": 6236,
      "CityName": "Saidpura",
      "CityValue": "Saidpura"
    },
    {
      "id": 6237,
      "CityName": "Sikhong Sekmai",
      "CityValue": "Sikhong Sekmai"
    },
    {
      "id": 6238,
      "CityName": "Mahesh Mundi",
      "CityValue": "Mahesh Mundi"
    },
    {
      "id": 6239,
      "CityName": "Puranabazar 'A'",
      "CityValue": "Puranabazar 'A'"
    },
    {
      "id": 6240,
      "CityName": "Ahrauli Shekh",
      "CityValue": "Ahrauli Shekh"
    },
    {
      "id": 6241,
      "CityName": "Khatra",
      "CityValue": "Khatra"
    },
    {
      "id": 6242,
      "CityName": "Umlyngka",
      "CityValue": "Umlyngka"
    },
    {
      "id": 6243,
      "CityName": "Khajutti",
      "CityValue": "Khajutti"
    },
    {
      "id": 6244,
      "CityName": "Palakurthy",
      "CityValue": "Palakurthy"
    },
    {
      "id": 6245,
      "CityName": "Kakarmatta",
      "CityValue": "Kakarmatta"
    },
    {
      "id": 6246,
      "CityName": "Jamunamukh",
      "CityValue": "Jamunamukh"
    },
    {
      "id": 6247,
      "CityName": "Kotla Mahigiran",
      "CityValue": "Kotla Mahigiran"
    },
    {
      "id": 6248,
      "CityName": "Murgathaul",
      "CityValue": "Murgathaul"
    },
    {
      "id": 6249,
      "CityName": "Nagam",
      "CityValue": "Nagam"
    },
    {
      "id": 6250,
      "CityName": "Manavalassery",
      "CityValue": "Manavalassery"
    },
    {
      "id": 6251,
      "CityName": "Kukudakhandi",
      "CityValue": "Kukudakhandi"
    },
    {
      "id": 6252,
      "CityName": "Kanisail Pt I",
      "CityValue": "Kanisail Pt I"
    },
    {
      "id": 6253,
      "CityName": "Asudubi",
      "CityValue": "Asudubi"
    },
    {
      "id": 6254,
      "CityName": "Perumanallur",
      "CityValue": "Perumanallur"
    },
    {
      "id": 6255,
      "CityName": "Shyampur",
      "CityValue": "Shyampur"
    },
    {
      "id": 6256,
      "CityName": "Rehambal",
      "CityValue": "Rehambal"
    },
    {
      "id": 6257,
      "CityName": "Maina Maujpur",
      "CityValue": "Maina Maujpur"
    },
    {
      "id": 6258,
      "CityName": "Thenkarai",
      "CityValue": "Thenkarai"
    },
    {
      "id": 6259,
      "CityName": "Nihal Garh Chak Jangla",
      "CityValue": "Nihal Garh Chak Jangla"
    },
    {
      "id": 6260,
      "CityName": "Kainoor",
      "CityValue": "Kainoor"
    },
    {
      "id": 6261,
      "CityName": "Jiribam",
      "CityValue": "Jiribam"
    },
    {
      "id": 6262,
      "CityName": "Vadghar",
      "CityValue": "Vadghar"
    },
    {
      "id": 6263,
      "CityName": "Amargarh",
      "CityValue": "Amargarh"
    },
    {
      "id": 6264,
      "CityName": "Vembadithalam",
      "CityValue": "Vembadithalam"
    },
    {
      "id": 6265,
      "CityName": "Mahua Dabra Haripura",
      "CityValue": "Mahua Dabra Haripura"
    },
    {
      "id": 6266,
      "CityName": "Jagannathpur",
      "CityValue": "Jagannathpur"
    },
    {
      "id": 6267,
      "CityName": "Desoola",
      "CityValue": "Desoola"
    },
    {
      "id": 6268,
      "CityName": "Itaunja",
      "CityValue": "Itaunja"
    },
    {
      "id": 6269,
      "CityName": "Chohal",
      "CityValue": "Chohal"
    },
    {
      "id": 6270,
      "CityName": "1 GB-A",
      "CityValue": "1 GB-A"
    },
    {
      "id": 6271,
      "CityName": "Panchet",
      "CityValue": "Panchet"
    },
    {
      "id": 6272,
      "CityName": "Sardarpur",
      "CityValue": "Sardarpur"
    },
    {
      "id": 6273,
      "CityName": "Ghorawal",
      "CityValue": "Ghorawal"
    },
    {
      "id": 6274,
      "CityName": "Sillewada",
      "CityValue": "Sillewada"
    },
    {
      "id": 6275,
      "CityName": "Karadipatti",
      "CityValue": "Karadipatti"
    },
    {
      "id": 6276,
      "CityName": "Swamimalai",
      "CityValue": "Swamimalai"
    },
    {
      "id": 6277,
      "CityName": "Kismatpur",
      "CityValue": "Kismatpur"
    },
    {
      "id": 6278,
      "CityName": "Gunduuppalavadi",
      "CityValue": "Gunduuppalavadi"
    },
    {
      "id": 6279,
      "CityName": "Khodarampur",
      "CityValue": "Khodarampur"
    },
    {
      "id": 6280,
      "CityName": "Dakshin Chatra",
      "CityValue": "Dakshin Chatra"
    },
    {
      "id": 6281,
      "CityName": "Adbhar",
      "CityValue": "Adbhar"
    },
    {
      "id": 6282,
      "CityName": "Kola",
      "CityValue": "Kola"
    },
    {
      "id": 6283,
      "CityName": "Bhagawanpur",
      "CityValue": "Bhagawanpur"
    },
    {
      "id": 6284,
      "CityName": "Pallapalayam",
      "CityValue": "Pallapalayam"
    },
    {
      "id": 6285,
      "CityName": "Nawapara",
      "CityValue": "Nawapara"
    },
    {
      "id": 6286,
      "CityName": "Bhadson",
      "CityValue": "Bhadson"
    },
    {
      "id": 6287,
      "CityName": "Thenzawl",
      "CityValue": "Thenzawl"
    },
    {
      "id": 6288,
      "CityName": "Pandamangalam",
      "CityValue": "Pandamangalam"
    },
    {
      "id": 6289,
      "CityName": "Swroop Nagar",
      "CityValue": "Swroop Nagar"
    },
    {
      "id": 6290,
      "CityName": "Baksinagar",
      "CityValue": "Baksinagar"
    },
    {
      "id": 6291,
      "CityName": "Namajgram",
      "CityValue": "Namajgram"
    },
    {
      "id": 6292,
      "CityName": "Ariyur",
      "CityValue": "Ariyur"
    },
    {
      "id": 6293,
      "CityName": "Kadma No-II",
      "CityValue": "Kadma No-II"
    },
    {
      "id": 6294,
      "CityName": "Dornapal",
      "CityValue": "Dornapal"
    },
    {
      "id": 6295,
      "CityName": "Talipady",
      "CityValue": "Talipady"
    },
    {
      "id": 6296,
      "CityName": "Khapar",
      "CityValue": "Khapar"
    },
    {
      "id": 6297,
      "CityName": "Jarhi",
      "CityValue": "Jarhi"
    },
    {
      "id": 6298,
      "CityName": "Sripur",
      "CityValue": "Sripur"
    },
    {
      "id": 6299,
      "CityName": "Vijayapuri",
      "CityValue": "Vijayapuri"
    },
    {
      "id": 6300,
      "CityName": "Morewadi",
      "CityValue": "Morewadi"
    },
    {
      "id": 6301,
      "CityName": "Gopalpur",
      "CityValue": "Gopalpur"
    },
    {
      "id": 6302,
      "CityName": "Alanthurai",
      "CityValue": "Alanthurai"
    },
    {
      "id": 6303,
      "CityName": "Khera",
      "CityValue": "Khera"
    },
    {
      "id": 6304,
      "CityName": "Badagaon",
      "CityValue": "Badagaon"
    },
    {
      "id": 6305,
      "CityName": "Saragaon",
      "CityValue": "Saragaon"
    },
    {
      "id": 6306,
      "CityName": "Sathamba",
      "CityValue": "Sathamba"
    },
    {
      "id": 6307,
      "CityName": "Oran",
      "CityValue": "Oran"
    },
    {
      "id": 6308,
      "CityName": "Natibpur",
      "CityValue": "Natibpur"
    },
    {
      "id": 6309,
      "CityName": "Fatellapur",
      "CityValue": "Fatellapur"
    },
    {
      "id": 6310,
      "CityName": "Simlapal",
      "CityValue": "Simlapal"
    },
    {
      "id": 6311,
      "CityName": "Kudus",
      "CityValue": "Kudus"
    },
    {
      "id": 6312,
      "CityName": "Bilimora",
      "CityValue": "Bilimora"
    },
    {
      "id": 6313,
      "CityName": "Talera",
      "CityValue": "Talera"
    },
    {
      "id": 6314,
      "CityName": "Tentulkuli",
      "CityValue": "Tentulkuli"
    },
    {
      "id": 6315,
      "CityName": "Perungulam",
      "CityValue": "Perungulam"
    },
    {
      "id": 6316,
      "CityName": "Nurpur",
      "CityValue": "Nurpur"
    },
    {
      "id": 6317,
      "CityName": "Rameswarpur",
      "CityValue": "Rameswarpur"
    },
    {
      "id": 6318,
      "CityName": "Jarod",
      "CityValue": "Jarod"
    },
    {
      "id": 6319,
      "CityName": "Keeranur",
      "CityValue": "Keeranur"
    },
    {
      "id": 6320,
      "CityName": "Bara Bamonia",
      "CityValue": "Bara Bamonia"
    },
    {
      "id": 6321,
      "CityName": "Hnahthial",
      "CityValue": "Hnahthial"
    },
    {
      "id": 6322,
      "CityName": "Bekobar",
      "CityValue": "Bekobar"
    },
    {
      "id": 6323,
      "CityName": "Sirsia",
      "CityValue": "Sirsia"
    },
    {
      "id": 6324,
      "CityName": "Samali",
      "CityValue": "Samali"
    },
    {
      "id": 6325,
      "CityName": "Kaiparamba",
      "CityValue": "Kaiparamba"
    },
    {
      "id": 6326,
      "CityName": "Uttar Goara",
      "CityValue": "Uttar Goara"
    },
    {
      "id": 6327,
      "CityName": "Gandhi Sagar Hydel Col",
      "CityValue": "Gandhi Sagar Hydel Col"
    },
    {
      "id": 6328,
      "CityName": "Mairabari Town",
      "CityValue": "Mairabari Town"
    },
    {
      "id": 6329,
      "CityName": "Birpur",
      "CityValue": "Birpur"
    },
    {
      "id": 6330,
      "CityName": "Hirapur",
      "CityValue": "Hirapur"
    },
    {
      "id": 6331,
      "CityName": "Nerkuppai",
      "CityValue": "Nerkuppai"
    },
    {
      "id": 6332,
      "CityName": "Oinam",
      "CityValue": "Oinam"
    },
    {
      "id": 6333,
      "CityName": "Hathua",
      "CityValue": "Hathua"
    },
    {
      "id": 6334,
      "CityName": "Goregaon",
      "CityValue": "Goregaon"
    },
    {
      "id": 6335,
      "CityName": "Kumarpur",
      "CityValue": "Kumarpur"
    },
    {
      "id": 6336,
      "CityName": "Pethampalayam",
      "CityValue": "Pethampalayam"
    },
    {
      "id": 6337,
      "CityName": "Jairampur",
      "CityValue": "Jairampur"
    },
    {
      "id": 6338,
      "CityName": "Manakkody",
      "CityValue": "Manakkody"
    },
    {
      "id": 6339,
      "CityName": "Mulaguntapadu",
      "CityValue": "Mulaguntapadu"
    },
    {
      "id": 6340,
      "CityName": "Neemrana",
      "CityValue": "Neemrana"
    },
    {
      "id": 6341,
      "CityName": "Sabroom",
      "CityValue": "Sabroom"
    },
    {
      "id": 6342,
      "CityName": "Hatsimla",
      "CityValue": "Hatsimla"
    },
    {
      "id": 6343,
      "CityName": "Bargo",
      "CityValue": "Bargo"
    },
    {
      "id": 6344,
      "CityName": "Jamuhan",
      "CityValue": "Jamuhan"
    },
    {
      "id": 6345,
      "CityName": "Laskarpara",
      "CityValue": "Laskarpara"
    },
    {
      "id": 6346,
      "CityName": "Barijhati",
      "CityValue": "Barijhati"
    },
    {
      "id": 6347,
      "CityName": "Vanavasi",
      "CityValue": "Vanavasi"
    },
    {
      "id": 6348,
      "CityName": "Nivi",
      "CityValue": "Nivi"
    },
    {
      "id": 6349,
      "CityName": "Ilambazar",
      "CityValue": "Ilambazar"
    },
    {
      "id": 6350,
      "CityName": "Vijaigarh",
      "CityValue": "Vijaigarh"
    },
    {
      "id": 6351,
      "CityName": "Viraganur",
      "CityValue": "Viraganur"
    },
    {
      "id": 6352,
      "CityName": "Ghansaur",
      "CityValue": "Ghansaur"
    },
    {
      "id": 6353,
      "CityName": "Dudhani",
      "CityValue": "Dudhani"
    },
    {
      "id": 6354,
      "CityName": "Chandpara",
      "CityValue": "Chandpara"
    },
    {
      "id": 6355,
      "CityName": "Jagatballavpur",
      "CityValue": "Jagatballavpur"
    },
    {
      "id": 6356,
      "CityName": "Bhakarsahi",
      "CityValue": "Bhakarsahi"
    },
    {
      "id": 6357,
      "CityName": "Aakera",
      "CityValue": "Aakera"
    },
    {
      "id": 6358,
      "CityName": "Sahebpur",
      "CityValue": "Sahebpur"
    },
    {
      "id": 6359,
      "CityName": "Mirjhapar",
      "CityValue": "Mirjhapar"
    },
    {
      "id": 6360,
      "CityName": "Karachiya",
      "CityValue": "Karachiya"
    },
    {
      "id": 6361,
      "CityName": "Periya Negamam",
      "CityValue": "Periya Negamam"
    },
    {
      "id": 6362,
      "CityName": "Kallettumkara",
      "CityValue": "Kallettumkara"
    },
    {
      "id": 6363,
      "CityName": "Upar Hali",
      "CityValue": "Upar Hali"
    },
    {
      "id": 6364,
      "CityName": "Sankarnagar",
      "CityValue": "Sankarnagar"
    },
    {
      "id": 6365,
      "CityName": "Chhuikhadan",
      "CityValue": "Chhuikhadan"
    },
    {
      "id": 6366,
      "CityName": "Raghudebpur",
      "CityValue": "Raghudebpur"
    },
    {
      "id": 6367,
      "CityName": "Banaigarh",
      "CityValue": "Banaigarh"
    },
    {
      "id": 6368,
      "CityName": "Tundla Kham",
      "CityValue": "Tundla Kham"
    },
    {
      "id": 6369,
      "CityName": "Lalsingi",
      "CityValue": "Lalsingi"
    },
    {
      "id": 6370,
      "CityName": "Ambaliyasan",
      "CityValue": "Ambaliyasan"
    },
    {
      "id": 6371,
      "CityName": "Madhubani",
      "CityValue": "Madhubani"
    },
    {
      "id": 6372,
      "CityName": "Bhagabatipur",
      "CityValue": "Bhagabatipur"
    },
    {
      "id": 6373,
      "CityName": "Chennamukkapalle",
      "CityValue": "Chennamukkapalle"
    },
    {
      "id": 6374,
      "CityName": "Koni",
      "CityValue": "Koni"
    },
    {
      "id": 6375,
      "CityName": "Koorgalli",
      "CityValue": "Koorgalli"
    },
    {
      "id": 6376,
      "CityName": "Premchak Urf Baheri",
      "CityValue": "Premchak Urf Baheri"
    },
    {
      "id": 6377,
      "CityName": "Badagaulipady",
      "CityValue": "Badagaulipady"
    },
    {
      "id": 6378,
      "CityName": "Kodiyal",
      "CityValue": "Kodiyal"
    },
    {
      "id": 6379,
      "CityName": "Pal Chaurai",
      "CityValue": "Pal Chaurai"
    },
    {
      "id": 6380,
      "CityName": "Dalhousie",
      "CityValue": "Dalhousie"
    },
    {
      "id": 6381,
      "CityName": "Mera",
      "CityValue": "Mera"
    },
    {
      "id": 6382,
      "CityName": "Kabri",
      "CityValue": "Kabri"
    },
    {
      "id": 6383,
      "CityName": "Parichha",
      "CityValue": "Parichha"
    },
    {
      "id": 6384,
      "CityName": "Sarai Khas",
      "CityValue": "Sarai Khas"
    },
    {
      "id": 6385,
      "CityName": "Briddhanagar",
      "CityValue": "Briddhanagar"
    },
    {
      "id": 6386,
      "CityName": "Kuvettu",
      "CityValue": "Kuvettu"
    },
    {
      "id": 6387,
      "CityName": "Dharchula",
      "CityValue": "Dharchula"
    },
    {
      "id": 6388,
      "CityName": "Konta",
      "CityValue": "Konta"
    },
    {
      "id": 6389,
      "CityName": "Dabhol",
      "CityValue": "Dabhol"
    },
    {
      "id": 6390,
      "CityName": "Sikri Kalan",
      "CityValue": "Sikri Kalan"
    },
    {
      "id": 6391,
      "CityName": "Purbba Tajpur",
      "CityValue": "Purbba Tajpur"
    },
    {
      "id": 6392,
      "CityName": "Nagri",
      "CityValue": "Nagri"
    },
    {
      "id": 6393,
      "CityName": "Adyar",
      "CityValue": "Adyar"
    },
    {
      "id": 6394,
      "CityName": "Chikhli",
      "CityValue": "Chikhli"
    },
    {
      "id": 6395,
      "CityName": "Kuchanur",
      "CityValue": "Kuchanur"
    },
    {
      "id": 6396,
      "CityName": "Beelna",
      "CityValue": "Beelna"
    },
    {
      "id": 6397,
      "CityName": "Kherliganj",
      "CityValue": "Kherliganj"
    },
    {
      "id": 6398,
      "CityName": "Pahar Ganj",
      "CityValue": "Pahar Ganj"
    },
    {
      "id": 6399,
      "CityName": "Sahaspur-Lohara",
      "CityValue": "Sahaspur-Lohara"
    },
    {
      "id": 6400,
      "CityName": "Gopalpur",
      "CityValue": "Gopalpur"
    },
    {
      "id": 6401,
      "CityName": "Watra Gam",
      "CityValue": "Watra Gam"
    },
    {
      "id": 6402,
      "CityName": "Makhanpur",
      "CityValue": "Makhanpur"
    },
    {
      "id": 6403,
      "CityName": "Pandatarai",
      "CityValue": "Pandatarai"
    },
    {
      "id": 6404,
      "CityName": "Bhojudih",
      "CityValue": "Bhojudih"
    },
    {
      "id": 6405,
      "CityName": "Batarashi",
      "CityValue": "Batarashi"
    },
    {
      "id": 6406,
      "CityName": "Kishunpur",
      "CityValue": "Kishunpur"
    },
    {
      "id": 6407,
      "CityName": "Hallaur",
      "CityValue": "Hallaur"
    },
    {
      "id": 6408,
      "CityName": "Indian Tehephone Industry, Mankapur",
      "CityValue": "Indian Tehephone Industry, Mankapur"
    },
    {
      "id": 6409,
      "CityName": "Naiti",
      "CityValue": "Naiti"
    },
    {
      "id": 6410,
      "CityName": "Bhagwant Nagar",
      "CityValue": "Bhagwant Nagar"
    },
    {
      "id": 6411,
      "CityName": "Daringbadi",
      "CityValue": "Daringbadi"
    },
    {
      "id": 6412,
      "CityName": "Khan Pur Dhani",
      "CityValue": "Khan Pur Dhani"
    },
    {
      "id": 6413,
      "CityName": "Kulberia",
      "CityValue": "Kulberia"
    },
    {
      "id": 6414,
      "CityName": "Chittilappilly",
      "CityValue": "Chittilappilly"
    },
    {
      "id": 6415,
      "CityName": "Mohpa",
      "CityValue": "Mohpa"
    },
    {
      "id": 6416,
      "CityName": "Thappakuttai",
      "CityValue": "Thappakuttai"
    },
    {
      "id": 6417,
      "CityName": "Ghanaur",
      "CityValue": "Ghanaur"
    },
    {
      "id": 6418,
      "CityName": "Badagada",
      "CityValue": "Badagada"
    },
    {
      "id": 6419,
      "CityName": "Mahendrapur",
      "CityValue": "Mahendrapur"
    },
    {
      "id": 6420,
      "CityName": "Tarapur VII",
      "CityValue": "Tarapur VII"
    },
    {
      "id": 6421,
      "CityName": "Ray",
      "CityValue": "Ray"
    },
    {
      "id": 6422,
      "CityName": "Jaynagar",
      "CityValue": "Jaynagar"
    },
    {
      "id": 6423,
      "CityName": "Umbergaon",
      "CityValue": "Umbergaon"
    },
    {
      "id": 6424,
      "CityName": "Telodih",
      "CityValue": "Telodih"
    },
    {
      "id": 6425,
      "CityName": "Borio",
      "CityValue": "Borio"
    },
    {
      "id": 6426,
      "CityName": "Tarapur",
      "CityValue": "Tarapur"
    },
    {
      "id": 6427,
      "CityName": "Uttar Krishnapur Pt. I",
      "CityValue": "Uttar Krishnapur Pt. I"
    },
    {
      "id": 6428,
      "CityName": "Gumaniwala",
      "CityValue": "Gumaniwala"
    },
    {
      "id": 6429,
      "CityName": "Borli Panchtan",
      "CityValue": "Borli Panchtan"
    },
    {
      "id": 6430,
      "CityName": "Paschim Panchla",
      "CityValue": "Paschim Panchla"
    },
    {
      "id": 6431,
      "CityName": "Chatrapatti",
      "CityValue": "Chatrapatti"
    },
    {
      "id": 6432,
      "CityName": "Parsona",
      "CityValue": "Parsona"
    },
    {
      "id": 6433,
      "CityName": "Simhat",
      "CityValue": "Simhat"
    },
    {
      "id": 6434,
      "CityName": "Badh Malak",
      "CityValue": "Badh Malak"
    },
    {
      "id": 6435,
      "CityName": "Hariharpur",
      "CityValue": "Hariharpur"
    },
    {
      "id": 6436,
      "CityName": "Antaliya",
      "CityValue": "Antaliya"
    },
    {
      "id": 6437,
      "CityName": "Lerhupur",
      "CityValue": "Lerhupur"
    },
    {
      "id": 6438,
      "CityName": "Chicalim",
      "CityValue": "Chicalim"
    },
    {
      "id": 6439,
      "CityName": "Khore",
      "CityValue": "Khore"
    },
    {
      "id": 6440,
      "CityName": "Gudari",
      "CityValue": "Gudari"
    },
    {
      "id": 6441,
      "CityName": "Sunderbani",
      "CityValue": "Sunderbani"
    },
    {
      "id": 6442,
      "CityName": "Venkatapura",
      "CityValue": "Venkatapura"
    },
    {
      "id": 6443,
      "CityName": "Sariya",
      "CityValue": "Sariya"
    },
    {
      "id": 6444,
      "CityName": "Risama",
      "CityValue": "Risama"
    },
    {
      "id": 6445,
      "CityName": "Bijepur",
      "CityValue": "Bijepur"
    },
    {
      "id": 6446,
      "CityName": "Deosaini",
      "CityValue": "Deosaini"
    },
    {
      "id": 6447,
      "CityName": "Sonatikiri",
      "CityValue": "Sonatikiri"
    },
    {
      "id": 6448,
      "CityName": "Sohela",
      "CityValue": "Sohela"
    },
    {
      "id": 6449,
      "CityName": "Digha",
      "CityValue": "Digha"
    },
    {
      "id": 6450,
      "CityName": "Bargi",
      "CityValue": "Bargi"
    },
    {
      "id": 6451,
      "CityName": "Shashati",
      "CityValue": "Shashati"
    },
    {
      "id": 6452,
      "CityName": "Sarthebari",
      "CityValue": "Sarthebari"
    },
    {
      "id": 6453,
      "CityName": "Kilpudupakkam",
      "CityValue": "Kilpudupakkam"
    },
    {
      "id": 6454,
      "CityName": "Nalpur",
      "CityValue": "Nalpur"
    },
    {
      "id": 6455,
      "CityName": "Chinchinim",
      "CityValue": "Chinchinim"
    },
    {
      "id": 6456,
      "CityName": "Mihitikri",
      "CityValue": "Mihitikri"
    },
    {
      "id": 6457,
      "CityName": "Kunjabangarh",
      "CityValue": "Kunjabangarh"
    },
    {
      "id": 6458,
      "CityName": "Ladrawan",
      "CityValue": "Ladrawan"
    },
    {
      "id": 6459,
      "CityName": "Balipatapur",
      "CityValue": "Balipatapur"
    },
    {
      "id": 6460,
      "CityName": "Bankra",
      "CityValue": "Bankra"
    },
    {
      "id": 6461,
      "CityName": "Lalman",
      "CityValue": "Lalman"
    },
    {
      "id": 6462,
      "CityName": "Kondappanaickenpatti",
      "CityValue": "Kondappanaickenpatti"
    },
    {
      "id": 6463,
      "CityName": "Ananthapuram",
      "CityValue": "Ananthapuram"
    },
    {
      "id": 6464,
      "CityName": "Seemalwara",
      "CityValue": "Seemalwara"
    },
    {
      "id": 6465,
      "CityName": "Melpattampakkam",
      "CityValue": "Melpattampakkam"
    },
    {
      "id": 6466,
      "CityName": "Fariha",
      "CityValue": "Fariha"
    },
    {
      "id": 6467,
      "CityName": "Burhanagar",
      "CityValue": "Burhanagar"
    },
    {
      "id": 6468,
      "CityName": "Bambolim",
      "CityValue": "Bambolim"
    },
    {
      "id": 6469,
      "CityName": "Kanki",
      "CityValue": "Kanki"
    },
    {
      "id": 6470,
      "CityName": "Churk Ghurma",
      "CityValue": "Churk Ghurma"
    },
    {
      "id": 6471,
      "CityName": "Ghoh",
      "CityValue": "Ghoh"
    },
    {
      "id": 6472,
      "CityName": "Sanoor",
      "CityValue": "Sanoor"
    },
    {
      "id": 6473,
      "CityName": "Sumaitheerthapuram",
      "CityValue": "Sumaitheerthapuram"
    },
    {
      "id": 6474,
      "CityName": "Periyamanali",
      "CityValue": "Periyamanali"
    },
    {
      "id": 6475,
      "CityName": "Gheora",
      "CityValue": "Gheora"
    },
    {
      "id": 6476,
      "CityName": "Rohru",
      "CityValue": "Rohru"
    },
    {
      "id": 6477,
      "CityName": "Padmabil",
      "CityValue": "Padmabil"
    },
    {
      "id": 6478,
      "CityName": "Asan Khurd",
      "CityValue": "Asan Khurd"
    },
    {
      "id": 6479,
      "CityName": "Khaliapali",
      "CityValue": "Khaliapali"
    },
    {
      "id": 6480,
      "CityName": "Hat Saraiya",
      "CityValue": "Hat Saraiya"
    },
    {
      "id": 6481,
      "CityName": "Daund",
      "CityValue": "Daund"
    },
    {
      "id": 6482,
      "CityName": "Punglia",
      "CityValue": "Punglia"
    },
    {
      "id": 6483,
      "CityName": "Quela",
      "CityValue": "Quela"
    },
    {
      "id": 6484,
      "CityName": "Gunghasa",
      "CityValue": "Gunghasa"
    },
    {
      "id": 6485,
      "CityName": "Charhi",
      "CityValue": "Charhi"
    },
    {
      "id": 6486,
      "CityName": "Annaram",
      "CityValue": "Annaram"
    },
    {
      "id": 6487,
      "CityName": "Manjalumoodu",
      "CityValue": "Manjalumoodu"
    },
    {
      "id": 6488,
      "CityName": "Khuda Alisher",
      "CityValue": "Khuda Alisher"
    },
    {
      "id": 6489,
      "CityName": "Basudebpur",
      "CityValue": "Basudebpur"
    },
    {
      "id": 6490,
      "CityName": "Baddowal",
      "CityValue": "Baddowal"
    },
    {
      "id": 6491,
      "CityName": "Bamangachhi",
      "CityValue": "Bamangachhi"
    },
    {
      "id": 6492,
      "CityName": "Bhat",
      "CityValue": "Bhat"
    },
    {
      "id": 6493,
      "CityName": "Bahbari Gaon",
      "CityValue": "Bahbari Gaon"
    },
    {
      "id": 6494,
      "CityName": "Pipri",
      "CityValue": "Pipri"
    },
    {
      "id": 6495,
      "CityName": "Soanpet",
      "CityValue": "Soanpet"
    },
    {
      "id": 6496,
      "CityName": "Majhauli Khetal",
      "CityValue": "Majhauli Khetal"
    },
    {
      "id": 6497,
      "CityName": "Paloor",
      "CityValue": "Paloor"
    },
    {
      "id": 6498,
      "CityName": "Harekala",
      "CityValue": "Harekala"
    },
    {
      "id": 6499,
      "CityName": "Elayirampannai",
      "CityValue": "Elayirampannai"
    },
    {
      "id": 6500,
      "CityName": "Sibnagar",
      "CityValue": "Sibnagar"
    },
    {
      "id": 6501,
      "CityName": "Moragudi",
      "CityValue": "Moragudi"
    },
    {
      "id": 6502,
      "CityName": "Murakambattu",
      "CityValue": "Murakambattu"
    },
    {
      "id": 6503,
      "CityName": "Ramanathpur",
      "CityValue": "Ramanathpur"
    },
    {
      "id": 6504,
      "CityName": "Uttarparanij",
      "CityValue": "Uttarparanij"
    },
    {
      "id": 6505,
      "CityName": "Rudrapur",
      "CityValue": "Rudrapur"
    },
    {
      "id": 6506,
      "CityName": "Varamballi",
      "CityValue": "Varamballi"
    },
    {
      "id": 6507,
      "CityName": "Choozhal",
      "CityValue": "Choozhal"
    },
    {
      "id": 6508,
      "CityName": "Rudravathi",
      "CityValue": "Rudravathi"
    },
    {
      "id": 6509,
      "CityName": "Bablari Dewanganj",
      "CityValue": "Bablari Dewanganj"
    },
    {
      "id": 6510,
      "CityName": "Jabera",
      "CityValue": "Jabera"
    },
    {
      "id": 6511,
      "CityName": "Kotba",
      "CityValue": "Kotba"
    },
    {
      "id": 6512,
      "CityName": "Cholapuram",
      "CityValue": "Cholapuram"
    },
    {
      "id": 6513,
      "CityName": "Mathurapur",
      "CityValue": "Mathurapur"
    },
    {
      "id": 6514,
      "CityName": "Vilholi",
      "CityValue": "Vilholi"
    },
    {
      "id": 6515,
      "CityName": "Benudia",
      "CityValue": "Benudia"
    },
    {
      "id": 6516,
      "CityName": "Durllabhganj",
      "CityValue": "Durllabhganj"
    },
    {
      "id": 6517,
      "CityName": "Khamari",
      "CityValue": "Khamari"
    },
    {
      "id": 6518,
      "CityName": "Nerunjipettai",
      "CityValue": "Nerunjipettai"
    },
    {
      "id": 6519,
      "CityName": "Dungra Khasmahal",
      "CityValue": "Dungra Khasmahal"
    },
    {
      "id": 6520,
      "CityName": "Kevadiya",
      "CityValue": "Kevadiya"
    },
    {
      "id": 6521,
      "CityName": "Badhni Kalan",
      "CityValue": "Badhni Kalan"
    },
    {
      "id": 6522,
      "CityName": "Chandan Hola",
      "CityValue": "Chandan Hola"
    },
    {
      "id": 6523,
      "CityName": "Bankul",
      "CityValue": "Bankul"
    },
    {
      "id": 6524,
      "CityName": "Chandpur",
      "CityValue": "Chandpur"
    },
    {
      "id": 6525,
      "CityName": "Antagarh",
      "CityValue": "Antagarh"
    },
    {
      "id": 6526,
      "CityName": "Sahnidih",
      "CityValue": "Sahnidih"
    },
    {
      "id": 6527,
      "CityName": "Nainan",
      "CityValue": "Nainan"
    },
    {
      "id": 6528,
      "CityName": "Dumra",
      "CityValue": "Dumra"
    },
    {
      "id": 6529,
      "CityName": "Kursath",
      "CityValue": "Kursath"
    },
    {
      "id": 6530,
      "CityName": "Rosera",
      "CityValue": "Rosera"
    },
    {
      "id": 6531,
      "CityName": "Hariyawan",
      "CityValue": "Hariyawan"
    },
    {
      "id": 6532,
      "CityName": "Ukwa",
      "CityValue": "Ukwa"
    },
    {
      "id": 6533,
      "CityName": "Danga",
      "CityValue": "Danga"
    },
    {
      "id": 6534,
      "CityName": "Koida",
      "CityValue": "Koida"
    },
    {
      "id": 6535,
      "CityName": "Osmania University",
      "CityValue": "Osmania University"
    },
    {
      "id": 6536,
      "CityName": "Bangalpur",
      "CityValue": "Bangalpur"
    },
    {
      "id": 6537,
      "CityName": "Morjim",
      "CityValue": "Morjim"
    },
    {
      "id": 6538,
      "CityName": "Umari Pr. Akola",
      "CityValue": "Umari Pr. Akola"
    },
    {
      "id": 6539,
      "CityName": "Chak Enayetnagar",
      "CityValue": "Chak Enayetnagar"
    },
    {
      "id": 6540,
      "CityName": "Dhampur Husainpur",
      "CityValue": "Dhampur Husainpur"
    },
    {
      "id": 6541,
      "CityName": "Palashi",
      "CityValue": "Palashi"
    },
    {
      "id": 6542,
      "CityName": "Udang",
      "CityValue": "Udang"
    },
    {
      "id": 6543,
      "CityName": "Surankote",
      "CityValue": "Surankote"
    },
    {
      "id": 6544,
      "CityName": "Madai",
      "CityValue": "Madai"
    },
    {
      "id": 6545,
      "CityName": "Ula",
      "CityValue": "Ula"
    },
    {
      "id": 6546,
      "CityName": "Mahrajganj",
      "CityValue": "Mahrajganj"
    },
    {
      "id": 6547,
      "CityName": "Narsinghgarh",
      "CityValue": "Narsinghgarh"
    },
    {
      "id": 6548,
      "CityName": "Baruipara",
      "CityValue": "Baruipara"
    },
    {
      "id": 6549,
      "CityName": "Vilavancode",
      "CityValue": "Vilavancode"
    },
    {
      "id": 6550,
      "CityName": "Somvarpet",
      "CityValue": "Somvarpet"
    },
    {
      "id": 6551,
      "CityName": "Kottappuram",
      "CityValue": "Kottappuram"
    },
    {
      "id": 6552,
      "CityName": "Eyyal",
      "CityValue": "Eyyal"
    },
    {
      "id": 6553,
      "CityName": "Chitrakonda",
      "CityValue": "Chitrakonda"
    },
    {
      "id": 6554,
      "CityName": "Barkot",
      "CityValue": "Barkot"
    },
    {
      "id": 6555,
      "CityName": "Malhipur",
      "CityValue": "Malhipur"
    },
    {
      "id": 6556,
      "CityName": "Selamba",
      "CityValue": "Selamba"
    },
    {
      "id": 6557,
      "CityName": "Sapotra",
      "CityValue": "Sapotra"
    },
    {
      "id": 6558,
      "CityName": "Fatehpur Chaurasi",
      "CityValue": "Fatehpur Chaurasi"
    },
    {
      "id": 6559,
      "CityName": "Deora",
      "CityValue": "Deora"
    },
    {
      "id": 6560,
      "CityName": "Waghai",
      "CityValue": "Waghai"
    },
    {
      "id": 6561,
      "CityName": "Kantabamsuguda",
      "CityValue": "Kantabamsuguda"
    },
    {
      "id": 6562,
      "CityName": "Veparala",
      "CityValue": "Veparala"
    },
    {
      "id": 6563,
      "CityName": "Navandgi",
      "CityValue": "Navandgi"
    },
    {
      "id": 6564,
      "CityName": "Anwari",
      "CityValue": "Anwari"
    },
    {
      "id": 6565,
      "CityName": "Madhusudanpur",
      "CityValue": "Madhusudanpur"
    },
    {
      "id": 6566,
      "CityName": "Khanupur",
      "CityValue": "Khanupur"
    },
    {
      "id": 6567,
      "CityName": "Radhanagar",
      "CityValue": "Radhanagar"
    },
    {
      "id": 6568,
      "CityName": "Rangapahar",
      "CityValue": "Rangapahar"
    },
    {
      "id": 6569,
      "CityName": "Maharajganj",
      "CityValue": "Maharajganj"
    },
    {
      "id": 6570,
      "CityName": "Donimalai Township",
      "CityValue": "Donimalai Township"
    },
    {
      "id": 6571,
      "CityName": "Ekangar Sarai",
      "CityValue": "Ekangar Sarai"
    },
    {
      "id": 6572,
      "CityName": "Kandasar",
      "CityValue": "Kandasar"
    },
    {
      "id": 6573,
      "CityName": "Bamunara",
      "CityValue": "Bamunara"
    },
    {
      "id": 6574,
      "CityName": "Garh Kamalpur",
      "CityValue": "Garh Kamalpur"
    },
    {
      "id": 6575,
      "CityName": "Kharibari",
      "CityValue": "Kharibari"
    },
    {
      "id": 6576,
      "CityName": "Dhania",
      "CityValue": "Dhania"
    },
    {
      "id": 6577,
      "CityName": "Selathampatti",
      "CityValue": "Selathampatti"
    },
    {
      "id": 6578,
      "CityName": "Jala Kendua",
      "CityValue": "Jala Kendua"
    },
    {
      "id": 6579,
      "CityName": "Kanganbaria",
      "CityValue": "Kanganbaria"
    },
    {
      "id": 6580,
      "CityName": "Bhedaghat",
      "CityValue": "Bhedaghat"
    },
    {
      "id": 6581,
      "CityName": "Sheragada",
      "CityValue": "Sheragada"
    },
    {
      "id": 6582,
      "CityName": "Ammapattinam",
      "CityValue": "Ammapattinam"
    },
    {
      "id": 6583,
      "CityName": "Deomali",
      "CityValue": "Deomali"
    },
    {
      "id": 6584,
      "CityName": "24 AS-C",
      "CityValue": "24 AS-C"
    },
    {
      "id": 6585,
      "CityName": "Narsapur",
      "CityValue": "Narsapur"
    },
    {
      "id": 6586,
      "CityName": "Kambe",
      "CityValue": "Kambe"
    },
    {
      "id": 6587,
      "CityName": "Nanguneri",
      "CityValue": "Nanguneri"
    },
    {
      "id": 6588,
      "CityName": "Halyan",
      "CityValue": "Halyan"
    },
    {
      "id": 6589,
      "CityName": "Barsur",
      "CityValue": "Barsur"
    },
    {
      "id": 6590,
      "CityName": "Padagha",
      "CityValue": "Padagha"
    },
    {
      "id": 6591,
      "CityName": "Verna",
      "CityValue": "Verna"
    },
    {
      "id": 6592,
      "CityName": "Bharuhana",
      "CityValue": "Bharuhana"
    },
    {
      "id": 6593,
      "CityName": "Haridasmati",
      "CityValue": "Haridasmati"
    },
    {
      "id": 6594,
      "CityName": "Kalundre",
      "CityValue": "Kalundre"
    },
    {
      "id": 6595,
      "CityName": "Basanti",
      "CityValue": "Basanti"
    },
    {
      "id": 6596,
      "CityName": "Hasayan",
      "CityValue": "Hasayan"
    },
    {
      "id": 6597,
      "CityName": "Chinchuria",
      "CityValue": "Chinchuria"
    },
    {
      "id": 6598,
      "CityName": "Barua Gopalpur",
      "CityValue": "Barua Gopalpur"
    },
    {
      "id": 6599,
      "CityName": "Bhuindhara",
      "CityValue": "Bhuindhara"
    },
    {
      "id": 6600,
      "CityName": "Ramapuram",
      "CityValue": "Ramapuram"
    },
    {
      "id": 6601,
      "CityName": "Takhlibilar Pathar",
      "CityValue": "Takhlibilar Pathar"
    },
    {
      "id": 6602,
      "CityName": "Pondarkanali",
      "CityValue": "Pondarkanali"
    },
    {
      "id": 6603,
      "CityName": "Banda",
      "CityValue": "Banda"
    },
    {
      "id": 6604,
      "CityName": "Ayyampettai",
      "CityValue": "Ayyampettai"
    },
    {
      "id": 6605,
      "CityName": "Baska",
      "CityValue": "Baska"
    },
    {
      "id": 6606,
      "CityName": "Bonthapalle",
      "CityValue": "Bonthapalle"
    },
    {
      "id": 6607,
      "CityName": "Navghar",
      "CityValue": "Navghar"
    },
    {
      "id": 6608,
      "CityName": "Hiramandalam",
      "CityValue": "Hiramandalam"
    },
    {
      "id": 6609,
      "CityName": "Kumbhkot",
      "CityValue": "Kumbhkot"
    },
    {
      "id": 6610,
      "CityName": "Sardargarh",
      "CityValue": "Sardargarh"
    },
    {
      "id": 6611,
      "CityName": "Aamadi",
      "CityValue": "Aamadi"
    },
    {
      "id": 6612,
      "CityName": "Got",
      "CityValue": "Got"
    },
    {
      "id": 6613,
      "CityName": "Goasafat",
      "CityValue": "Goasafat"
    },
    {
      "id": 6614,
      "CityName": "Baniban",
      "CityValue": "Baniban"
    },
    {
      "id": 6615,
      "CityName": "Maro",
      "CityValue": "Maro"
    },
    {
      "id": 6616,
      "CityName": "Thaikkad",
      "CityValue": "Thaikkad"
    },
    {
      "id": 6617,
      "CityName": "Purne",
      "CityValue": "Purne"
    },
    {
      "id": 6618,
      "CityName": "Kadigenahalli",
      "CityValue": "Kadigenahalli"
    },
    {
      "id": 6619,
      "CityName": "Chengappalli",
      "CityValue": "Chengappalli"
    },
    {
      "id": 6620,
      "CityName": "Vanganur",
      "CityValue": "Vanganur"
    },
    {
      "id": 6621,
      "CityName": "Amara Khaira Chak",
      "CityValue": "Amara Khaira Chak"
    },
    {
      "id": 6622,
      "CityName": "Jaffar Pur Kalan",
      "CityValue": "Jaffar Pur Kalan"
    },
    {
      "id": 6623,
      "CityName": "Chanari",
      "CityValue": "Chanari"
    },
    {
      "id": 6624,
      "CityName": "Daulatpur",
      "CityValue": "Daulatpur"
    },
    {
      "id": 6625,
      "CityName": "Corlim",
      "CityValue": "Corlim"
    },
    {
      "id": 6626,
      "CityName": "Shivnandanpur Alias Omkarbahara",
      "CityValue": "Shivnandanpur Alias Omkarbahara"
    },
    {
      "id": 6627,
      "CityName": "Anjira",
      "CityValue": "Anjira"
    },
    {
      "id": 6628,
      "CityName": "Chapari",
      "CityValue": "Chapari"
    },
    {
      "id": 6629,
      "CityName": "Seota",
      "CityValue": "Seota"
    },
    {
      "id": 6630,
      "CityName": "Koker Nag",
      "CityValue": "Koker Nag"
    },
    {
      "id": 6631,
      "CityName": "Khantora",
      "CityValue": "Khantora"
    },
    {
      "id": 6632,
      "CityName": "Hasangarh",
      "CityValue": "Hasangarh"
    },
    {
      "id": 6633,
      "CityName": "Alur",
      "CityValue": "Alur"
    },
    {
      "id": 6634,
      "CityName": "Yingkiong",
      "CityValue": "Yingkiong"
    },
    {
      "id": 6635,
      "CityName": "Santer",
      "CityValue": "Santer"
    },
    {
      "id": 6636,
      "CityName": "Mathicode",
      "CityValue": "Mathicode"
    },
    {
      "id": 6637,
      "CityName": "Matarbari",
      "CityValue": "Matarbari"
    },
    {
      "id": 6638,
      "CityName": "Sarpara",
      "CityValue": "Sarpara"
    },
    {
      "id": 6639,
      "CityName": "Naudhia",
      "CityValue": "Naudhia"
    },
    {
      "id": 6640,
      "CityName": "Korwa",
      "CityValue": "Korwa"
    },
    {
      "id": 6641,
      "CityName": "Middha",
      "CityValue": "Middha"
    },
    {
      "id": 6642,
      "CityName": "Didihat",
      "CityValue": "Didihat"
    },
    {
      "id": 6643,
      "CityName": "Borai",
      "CityValue": "Borai"
    },
    {
      "id": 6644,
      "CityName": "Aishmuquam",
      "CityValue": "Aishmuquam"
    },
    {
      "id": 6645,
      "CityName": "Kral Pora",
      "CityValue": "Kral Pora"
    },
    {
      "id": 6646,
      "CityName": "Deulgram",
      "CityValue": "Deulgram"
    },
    {
      "id": 6647,
      "CityName": "Dhorimanna",
      "CityValue": "Dhorimanna"
    },
    {
      "id": 6648,
      "CityName": "Mitraon",
      "CityValue": "Mitraon"
    },
    {
      "id": 6649,
      "CityName": "Aquem",
      "CityValue": "Aquem"
    },
    {
      "id": 6650,
      "CityName": "Khandbara",
      "CityValue": "Khandbara"
    },
    {
      "id": 6651,
      "CityName": "Mahroni Rural",
      "CityValue": "Mahroni Rural"
    },
    {
      "id": 6652,
      "CityName": "Debipur",
      "CityValue": "Debipur"
    },
    {
      "id": 6653,
      "CityName": "Porkulam",
      "CityValue": "Porkulam"
    },
    {
      "id": 6654,
      "CityName": "Bighapur",
      "CityValue": "Bighapur"
    },
    {
      "id": 6655,
      "CityName": "Sangramnagar",
      "CityValue": "Sangramnagar"
    },
    {
      "id": 6656,
      "CityName": "Ambivali T. Tungartan",
      "CityValue": "Ambivali T. Tungartan"
    },
    {
      "id": 6657,
      "CityName": "Kuwarbav",
      "CityValue": "Kuwarbav"
    },
    {
      "id": 6658,
      "CityName": "Manwal",
      "CityValue": "Manwal"
    },
    {
      "id": 6659,
      "CityName": "Chandipur",
      "CityValue": "Chandipur"
    },
    {
      "id": 6660,
      "CityName": "Shravanabelgola",
      "CityValue": "Shravanabelgola"
    },
    {
      "id": 6661,
      "CityName": "Goda",
      "CityValue": "Goda"
    },
    {
      "id": 6662,
      "CityName": "Chadura",
      "CityValue": "Chadura"
    },
    {
      "id": 6663,
      "CityName": "Jalgaon",
      "CityValue": "Jalgaon"
    },
    {
      "id": 6664,
      "CityName": "Gaddoo Pur",
      "CityValue": "Gaddoo Pur"
    },
    {
      "id": 6665,
      "CityName": "Uttar Kalas",
      "CityValue": "Uttar Kalas"
    },
    {
      "id": 6666,
      "CityName": "Jagijhora Barabak",
      "CityValue": "Jagijhora Barabak"
    },
    {
      "id": 6667,
      "CityName": "Panuhat",
      "CityValue": "Panuhat"
    },
    {
      "id": 6668,
      "CityName": "Kurgunta",
      "CityValue": "Kurgunta"
    },
    {
      "id": 6669,
      "CityName": "Ballavpur",
      "CityValue": "Ballavpur"
    },
    {
      "id": 6670,
      "CityName": "Gauri Bazar",
      "CityValue": "Gauri Bazar"
    },
    {
      "id": 6671,
      "CityName": "Jalah",
      "CityValue": "Jalah"
    },
    {
      "id": 6672,
      "CityName": "Auras",
      "CityValue": "Auras"
    },
    {
      "id": 6673,
      "CityName": "Beliatore",
      "CityValue": "Beliatore"
    },
    {
      "id": 6674,
      "CityName": "Perambakkam",
      "CityValue": "Perambakkam"
    },
    {
      "id": 6675,
      "CityName": "Chandrapur",
      "CityValue": "Chandrapur"
    },
    {
      "id": 6676,
      "CityName": "Kendua",
      "CityValue": "Kendua"
    },
    {
      "id": 6677,
      "CityName": "Belma",
      "CityValue": "Belma"
    },
    {
      "id": 6678,
      "CityName": "Tola Mansaraut",
      "CityValue": "Tola Mansaraut"
    },
    {
      "id": 6679,
      "CityName": "Koradacheri",
      "CityValue": "Koradacheri"
    },
    {
      "id": 6680,
      "CityName": "Bhulepur",
      "CityValue": "Bhulepur"
    },
    {
      "id": 6681,
      "CityName": "Sanguem",
      "CityValue": "Sanguem"
    },
    {
      "id": 6682,
      "CityName": "Patuli",
      "CityValue": "Patuli"
    },
    {
      "id": 6683,
      "CityName": "Katangi Kala",
      "CityValue": "Katangi Kala"
    },
    {
      "id": 6684,
      "CityName": "Bhapur",
      "CityValue": "Bhapur"
    },
    {
      "id": 6685,
      "CityName": "Khairi",
      "CityValue": "Khairi"
    },
    {
      "id": 6686,
      "CityName": "Silaiman",
      "CityValue": "Silaiman"
    },
    {
      "id": 6687,
      "CityName": "Kadakola",
      "CityValue": "Kadakola"
    },
    {
      "id": 6688,
      "CityName": "Khanjarpur",
      "CityValue": "Khanjarpur"
    },
    {
      "id": 6689,
      "CityName": "Gangaikondan",
      "CityValue": "Gangaikondan"
    },
    {
      "id": 6690,
      "CityName": "Baksa",
      "CityValue": "Baksa"
    },
    {
      "id": 6691,
      "CityName": "Chandapur Champagachhi",
      "CityValue": "Chandapur Champagachhi"
    },
    {
      "id": 6692,
      "CityName": "Umarha",
      "CityValue": "Umarha"
    },
    {
      "id": 6693,
      "CityName": "Para",
      "CityValue": "Para"
    },
    {
      "id": 6694,
      "CityName": "Chandpur",
      "CityValue": "Chandpur"
    },
    {
      "id": 6695,
      "CityName": "Saranga",
      "CityValue": "Saranga"
    },
    {
      "id": 6696,
      "CityName": "Kilampadi",
      "CityValue": "Kilampadi"
    },
    {
      "id": 6697,
      "CityName": "Agastinuagan",
      "CityValue": "Agastinuagan"
    },
    {
      "id": 6698,
      "CityName": "Paltapara",
      "CityValue": "Paltapara"
    },
    {
      "id": 6699,
      "CityName": "Mahewa Patti Pashchim Uparhar",
      "CityValue": "Mahewa Patti Pashchim Uparhar"
    },
    {
      "id": 6700,
      "CityName": "Dari",
      "CityValue": "Dari"
    },
    {
      "id": 6701,
      "CityName": "Amarshi Kasba",
      "CityValue": "Amarshi Kasba"
    },
    {
      "id": 6702,
      "CityName": "Sankarpur",
      "CityValue": "Sankarpur"
    },
    {
      "id": 6703,
      "CityName": "Ramdas",
      "CityValue": "Ramdas"
    },
    {
      "id": 6704,
      "CityName": "Kallangudy",
      "CityValue": "Kallangudy"
    },
    {
      "id": 6705,
      "CityName": "Dera Baba Nanak",
      "CityValue": "Dera Baba Nanak"
    },
    {
      "id": 6706,
      "CityName": "Mellahalli",
      "CityValue": "Mellahalli"
    },
    {
      "id": 6707,
      "CityName": "Satrampadu",
      "CityValue": "Satrampadu"
    },
    {
      "id": 6708,
      "CityName": "Dakhin Rampur",
      "CityValue": "Dakhin Rampur"
    },
    {
      "id": 6709,
      "CityName": "Karmatanr",
      "CityValue": "Karmatanr"
    },
    {
      "id": 6710,
      "CityName": "Dandirhat",
      "CityValue": "Dandirhat"
    },
    {
      "id": 6711,
      "CityName": "Warud",
      "CityValue": "Warud"
    },
    {
      "id": 6712,
      "CityName": "Mundiya",
      "CityValue": "Mundiya"
    },
    {
      "id": 6713,
      "CityName": "Sini",
      "CityValue": "Sini"
    },
    {
      "id": 6714,
      "CityName": "Mahlidih",
      "CityValue": "Mahlidih"
    },
    {
      "id": 6715,
      "CityName": "Malpur",
      "CityValue": "Malpur"
    },
    {
      "id": 6716,
      "CityName": "Jalalpur Dehat",
      "CityValue": "Jalalpur Dehat"
    },
    {
      "id": 6717,
      "CityName": "Salvador do Mundo",
      "CityValue": "Salvador do Mundo"
    },
    {
      "id": 6718,
      "CityName": "Pallippadai",
      "CityValue": "Pallippadai"
    },
    {
      "id": 6719,
      "CityName": "Rathian",
      "CityValue": "Rathian"
    },
    {
      "id": 6720,
      "CityName": "Chidiga",
      "CityValue": "Chidiga"
    },
    {
      "id": 6721,
      "CityName": "Kariyannur",
      "CityValue": "Kariyannur"
    },
    {
      "id": 6722,
      "CityName": "Khatiguda",
      "CityValue": "Khatiguda"
    },
    {
      "id": 6723,
      "CityName": "Puliyoorsalai",
      "CityValue": "Puliyoorsalai"
    },
    {
      "id": 6724,
      "CityName": "Khodar Bazar",
      "CityValue": "Khodar Bazar"
    },
    {
      "id": 6725,
      "CityName": "Bhore",
      "CityValue": "Bhore"
    },
    {
      "id": 6726,
      "CityName": "Muraidih",
      "CityValue": "Muraidih"
    },
    {
      "id": 6727,
      "CityName": "Bali Koria",
      "CityValue": "Bali Koria"
    },
    {
      "id": 6728,
      "CityName": "Devkar",
      "CityValue": "Devkar"
    },
    {
      "id": 6729,
      "CityName": "Raghunathpur",
      "CityValue": "Raghunathpur"
    },
    {
      "id": 6730,
      "CityName": "Nischintapur",
      "CityValue": "Nischintapur"
    },
    {
      "id": 6731,
      "CityName": "Dindaspur",
      "CityValue": "Dindaspur"
    },
    {
      "id": 6732,
      "CityName": "Jetia",
      "CityValue": "Jetia"
    },
    {
      "id": 6733,
      "CityName": "Bhatenda",
      "CityValue": "Bhatenda"
    },
    {
      "id": 6734,
      "CityName": "Pathariya",
      "CityValue": "Pathariya"
    },
    {
      "id": 6735,
      "CityName": "Heri",
      "CityValue": "Heri"
    },
    {
      "id": 6736,
      "CityName": "Ramakantapur",
      "CityValue": "Ramakantapur"
    },
    {
      "id": 6737,
      "CityName": "Gangpur",
      "CityValue": "Gangpur"
    },
    {
      "id": 6738,
      "CityName": "Begun Kodar",
      "CityValue": "Begun Kodar"
    },
    {
      "id": 6739,
      "CityName": "Ara",
      "CityValue": "Ara"
    },
    {
      "id": 6740,
      "CityName": "Rengali Damproject Township",
      "CityValue": "Rengali Damproject Township"
    },
    {
      "id": 6741,
      "CityName": "Baral",
      "CityValue": "Baral"
    },
    {
      "id": 6742,
      "CityName": "Guhala",
      "CityValue": "Guhala"
    },
    {
      "id": 6743,
      "CityName": "Karugampattur",
      "CityValue": "Karugampattur"
    },
    {
      "id": 6744,
      "CityName": "Jainoor",
      "CityValue": "Jainoor"
    },
    {
      "id": 6745,
      "CityName": "Panchghara",
      "CityValue": "Panchghara"
    },
    {
      "id": 6746,
      "CityName": "Thana",
      "CityValue": "Thana"
    },
    {
      "id": 6747,
      "CityName": "Kendua",
      "CityValue": "Kendua"
    },
    {
      "id": 6748,
      "CityName": "Bilandapur",
      "CityValue": "Bilandapur"
    },
    {
      "id": 6749,
      "CityName": "Asarganj",
      "CityValue": "Asarganj"
    },
    {
      "id": 6750,
      "CityName": "Akdoni Khurd",
      "CityValue": "Akdoni Khurd"
    },
    {
      "id": 6751,
      "CityName": "Koradi",
      "CityValue": "Koradi"
    },
    {
      "id": 6752,
      "CityName": "Mamnoor",
      "CityValue": "Mamnoor"
    },
    {
      "id": 6753,
      "CityName": "Ugu",
      "CityValue": "Ugu"
    },
    {
      "id": 6754,
      "CityName": "Kokapur",
      "CityValue": "Kokapur"
    },
    {
      "id": 6755,
      "CityName": "Tseminyu",
      "CityValue": "Tseminyu"
    },
    {
      "id": 6756,
      "CityName": "Chota Haibor",
      "CityValue": "Chota Haibor"
    },
    {
      "id": 6757,
      "CityName": "Hirapur",
      "CityValue": "Hirapur"
    },
    {
      "id": 6758,
      "CityName": "Silchar Pt. X",
      "CityValue": "Silchar Pt. X"
    },
    {
      "id": 6759,
      "CityName": "Madna",
      "CityValue": "Madna"
    },
    {
      "id": 6760,
      "CityName": "Darya Pur Kalan",
      "CityValue": "Darya Pur Kalan"
    },
    {
      "id": 6761,
      "CityName": "Bhowali",
      "CityValue": "Bhowali"
    },
    {
      "id": 6762,
      "CityName": "Shaktigarh",
      "CityValue": "Shaktigarh"
    },
    {
      "id": 6763,
      "CityName": "Farasgaon",
      "CityValue": "Farasgaon"
    },
    {
      "id": 6764,
      "CityName": "Andul",
      "CityValue": "Andul"
    },
    {
      "id": 6765,
      "CityName": "Alevoor",
      "CityValue": "Alevoor"
    },
    {
      "id": 6766,
      "CityName": "Gangapur",
      "CityValue": "Gangapur"
    },
    {
      "id": 6767,
      "CityName": "Dumari urf Damodarpur Shahjahan",
      "CityValue": "Dumari urf Damodarpur Shahjahan"
    },
    {
      "id": 6768,
      "CityName": "Rajasunakhala",
      "CityValue": "Rajasunakhala"
    },
    {
      "id": 6769,
      "CityName": "Garimellapadu",
      "CityValue": "Garimellapadu"
    },
    {
      "id": 6770,
      "CityName": "Kallipur",
      "CityValue": "Kallipur"
    },
    {
      "id": 6771,
      "CityName": "Ram Nagar",
      "CityValue": "Ram Nagar"
    },
    {
      "id": 6772,
      "CityName": "Jugauli",
      "CityValue": "Jugauli"
    },
    {
      "id": 6773,
      "CityName": "Jakhaon",
      "CityValue": "Jakhaon"
    },
    {
      "id": 6774,
      "CityName": "Badamba",
      "CityValue": "Badamba"
    },
    {
      "id": 6775,
      "CityName": "Ambada",
      "CityValue": "Ambada"
    },
    {
      "id": 6776,
      "CityName": "Pub - Dhaniram Pather",
      "CityValue": "Pub - Dhaniram Pather"
    },
    {
      "id": 6777,
      "CityName": "Magarlod",
      "CityValue": "Magarlod"
    },
    {
      "id": 6778,
      "CityName": "Isapur Banger",
      "CityValue": "Isapur Banger"
    },
    {
      "id": 6779,
      "CityName": "Raipur Bazar",
      "CityValue": "Raipur Bazar"
    },
    {
      "id": 6780,
      "CityName": "Saligao",
      "CityValue": "Saligao"
    },
    {
      "id": 6781,
      "CityName": "Beswan",
      "CityValue": "Beswan"
    },
    {
      "id": 6782,
      "CityName": "Vaddeswaram",
      "CityValue": "Vaddeswaram"
    },
    {
      "id": 6783,
      "CityName": "Keelamanjakudi",
      "CityValue": "Keelamanjakudi"
    },
    {
      "id": 6784,
      "CityName": "Asuti",
      "CityValue": "Asuti"
    },
    {
      "id": 6785,
      "CityName": "Dungamal",
      "CityValue": "Dungamal"
    },
    {
      "id": 6786,
      "CityName": "Piprayli Bujurg",
      "CityValue": "Piprayli Bujurg"
    },
    {
      "id": 6787,
      "CityName": "Aldona",
      "CityValue": "Aldona"
    },
    {
      "id": 6788,
      "CityName": "Lakhanpur",
      "CityValue": "Lakhanpur"
    },
    {
      "id": 6789,
      "CityName": "Chak Bhrigu",
      "CityValue": "Chak Bhrigu"
    },
    {
      "id": 6790,
      "CityName": "Garhi Tamana",
      "CityValue": "Garhi Tamana"
    },
    {
      "id": 6791,
      "CityName": "Bamunari",
      "CityValue": "Bamunari"
    },
    {
      "id": 6792,
      "CityName": "Khalia",
      "CityValue": "Khalia"
    },
    {
      "id": 6793,
      "CityName": "Kawalettu",
      "CityValue": "Kawalettu"
    },
    {
      "id": 6794,
      "CityName": "Surgana",
      "CityValue": "Surgana"
    },
    {
      "id": 6795,
      "CityName": "Apra",
      "CityValue": "Apra"
    },
    {
      "id": 6796,
      "CityName": "Jamtara",
      "CityValue": "Jamtara"
    },
    {
      "id": 6797,
      "CityName": "Parabada",
      "CityValue": "Parabada"
    },
    {
      "id": 6798,
      "CityName": "Ramchandrapur",
      "CityValue": "Ramchandrapur"
    },
    {
      "id": 6799,
      "CityName": "Bhagal",
      "CityValue": "Bhagal"
    },
    {
      "id": 6800,
      "CityName": "Sadigachhi",
      "CityValue": "Sadigachhi"
    },
    {
      "id": 6801,
      "CityName": "Abupur",
      "CityValue": "Abupur"
    },
    {
      "id": 6802,
      "CityName": "Kharsarai",
      "CityValue": "Kharsarai"
    },
    {
      "id": 6803,
      "CityName": "Mahul Khas Or Mahul",
      "CityValue": "Mahul Khas Or Mahul"
    },
    {
      "id": 6804,
      "CityName": "Shivunipalle",
      "CityValue": "Shivunipalle"
    },
    {
      "id": 6805,
      "CityName": "Thadikarankonam",
      "CityValue": "Thadikarankonam"
    },
    {
      "id": 6806,
      "CityName": "Changlang",
      "CityValue": "Changlang"
    },
    {
      "id": 6807,
      "CityName": "Maibong",
      "CityValue": "Maibong"
    },
    {
      "id": 6808,
      "CityName": "Chikkajajur",
      "CityValue": "Chikkajajur"
    },
    {
      "id": 6809,
      "CityName": "Kila Ambur",
      "CityValue": "Kila Ambur"
    },
    {
      "id": 6810,
      "CityName": "Golabandha",
      "CityValue": "Golabandha"
    },
    {
      "id": 6811,
      "CityName": "Usthi",
      "CityValue": "Usthi"
    },
    {
      "id": 6812,
      "CityName": "Thumbe",
      "CityValue": "Thumbe"
    },
    {
      "id": 6813,
      "CityName": "Shankarampet",
      "CityValue": "Shankarampet"
    },
    {
      "id": 6814,
      "CityName": "Chakkarapalli",
      "CityValue": "Chakkarapalli"
    },
    {
      "id": 6815,
      "CityName": "Satiyava",
      "CityValue": "Satiyava"
    },
    {
      "id": 6816,
      "CityName": "Loisinga",
      "CityValue": "Loisinga"
    },
    {
      "id": 6817,
      "CityName": "Kanakpur I",
      "CityValue": "Kanakpur I"
    },
    {
      "id": 6818,
      "CityName": "Fazi Nagar",
      "CityValue": "Fazi Nagar"
    },
    {
      "id": 6819,
      "CityName": "Marcaim",
      "CityValue": "Marcaim"
    },
    {
      "id": 6820,
      "CityName": "Manickapuram",
      "CityValue": "Manickapuram"
    },
    {
      "id": 6821,
      "CityName": "Dhanauha",
      "CityValue": "Dhanauha"
    },
    {
      "id": 6822,
      "CityName": "Ghola Noapara",
      "CityValue": "Ghola Noapara"
    },
    {
      "id": 6823,
      "CityName": "Chhitauni",
      "CityValue": "Chhitauni"
    },
    {
      "id": 6824,
      "CityName": "Porompat",
      "CityValue": "Porompat"
    },
    {
      "id": 6825,
      "CityName": "Tenkanidyoor",
      "CityValue": "Tenkanidyoor"
    },
    {
      "id": 6826,
      "CityName": "Sanri Alias Tilaiya",
      "CityValue": "Sanri Alias Tilaiya"
    },
    {
      "id": 6827,
      "CityName": "Chaulia",
      "CityValue": "Chaulia"
    },
    {
      "id": 6828,
      "CityName": "Mawpat",
      "CityValue": "Mawpat"
    },
    {
      "id": 6829,
      "CityName": "Sagarmpur",
      "CityValue": "Sagarmpur"
    },
    {
      "id": 6830,
      "CityName": "Katirail T.E.",
      "CityValue": "Katirail T.E."
    },
    {
      "id": 6831,
      "CityName": "Kurumbapatti",
      "CityValue": "Kurumbapatti"
    },
    {
      "id": 6832,
      "CityName": "Kaniyur",
      "CityValue": "Kaniyur"
    },
    {
      "id": 6833,
      "CityName": "Andal",
      "CityValue": "Andal"
    },
    {
      "id": 6834,
      "CityName": "Bandhail",
      "CityValue": "Bandhail"
    },
    {
      "id": 6835,
      "CityName": "Gulariya Bhindara",
      "CityValue": "Gulariya Bhindara"
    },
    {
      "id": 6836,
      "CityName": "Aurangabad Gadana",
      "CityValue": "Aurangabad Gadana"
    },
    {
      "id": 6837,
      "CityName": "Naba Kola",
      "CityValue": "Naba Kola"
    },
    {
      "id": 6838,
      "CityName": "Vellanikkara",
      "CityValue": "Vellanikkara"
    },
    {
      "id": 6839,
      "CityName": "Anantpur",
      "CityValue": "Anantpur"
    },
    {
      "id": 6840,
      "CityName": "Mullanpur Garib Dass",
      "CityValue": "Mullanpur Garib Dass"
    },
    {
      "id": 6841,
      "CityName": "Digvijaygram",
      "CityValue": "Digvijaygram"
    },
    {
      "id": 6842,
      "CityName": "Chikhalakasa",
      "CityValue": "Chikhalakasa"
    },
    {
      "id": 6843,
      "CityName": "Rahaud",
      "CityValue": "Rahaud"
    },
    {
      "id": 6844,
      "CityName": "Bhandar Gachha",
      "CityValue": "Bhandar Gachha"
    },
    {
      "id": 6845,
      "CityName": "Mukhiguda",
      "CityValue": "Mukhiguda"
    },
    {
      "id": 6846,
      "CityName": "Asapur",
      "CityValue": "Asapur"
    },
    {
      "id": 6847,
      "CityName": "Peralam",
      "CityValue": "Peralam"
    },
    {
      "id": 6848,
      "CityName": "Waddhamana",
      "CityValue": "Waddhamana"
    },
    {
      "id": 6849,
      "CityName": "Arumbanur",
      "CityValue": "Arumbanur"
    },
    {
      "id": 6850,
      "CityName": "Cherlopalle",
      "CityValue": "Cherlopalle"
    },
    {
      "id": 6851,
      "CityName": "Manthithoppu",
      "CityValue": "Manthithoppu"
    },
    {
      "id": 6852,
      "CityName": "Aminagar Urf Bhurbaral",
      "CityValue": "Aminagar Urf Bhurbaral"
    },
    {
      "id": 6853,
      "CityName": "Kirnapur",
      "CityValue": "Kirnapur"
    },
    {
      "id": 6854,
      "CityName": "Amboli",
      "CityValue": "Amboli"
    },
    {
      "id": 6855,
      "CityName": "Markayankottai",
      "CityValue": "Markayankottai"
    },
    {
      "id": 6856,
      "CityName": "Dehu",
      "CityValue": "Dehu"
    },
    {
      "id": 6857,
      "CityName": "Forest Vill. Lakhipathar",
      "CityValue": "Forest Vill. Lakhipathar"
    },
    {
      "id": 6858,
      "CityName": "Budhapanka",
      "CityValue": "Budhapanka"
    },
    {
      "id": 6859,
      "CityName": "Tada Khandrika",
      "CityValue": "Tada Khandrika"
    },
    {
      "id": 6860,
      "CityName": "Minalur",
      "CityValue": "Minalur"
    },
    {
      "id": 6861,
      "CityName": "Sirukalathur",
      "CityValue": "Sirukalathur"
    },
    {
      "id": 6862,
      "CityName": "Heingang",
      "CityValue": "Heingang"
    },
    {
      "id": 6863,
      "CityName": "Gandipuram",
      "CityValue": "Gandipuram"
    },
    {
      "id": 6864,
      "CityName": "Kalikapur Barasat",
      "CityValue": "Kalikapur Barasat"
    },
    {
      "id": 6865,
      "CityName": "Badamtam Tea Garden",
      "CityValue": "Badamtam Tea Garden"
    },
    {
      "id": 6866,
      "CityName": "Khongman",
      "CityValue": "Khongman"
    },
    {
      "id": 6867,
      "CityName": "Chhura",
      "CityValue": "Chhura"
    },
    {
      "id": 6868,
      "CityName": "Vadfalya",
      "CityValue": "Vadfalya"
    },
    {
      "id": 6869,
      "CityName": "Hazipur",
      "CityValue": "Hazipur"
    },
    {
      "id": 6870,
      "CityName": "Uttar Athiabari",
      "CityValue": "Uttar Athiabari"
    },
    {
      "id": 6871,
      "CityName": "Peruvilai",
      "CityValue": "Peruvilai"
    },
    {
      "id": 6872,
      "CityName": "Kotbar",
      "CityValue": "Kotbar"
    },
    {
      "id": 6873,
      "CityName": "Mirzapur",
      "CityValue": "Mirzapur"
    },
    {
      "id": 6874,
      "CityName": "Topchanchi",
      "CityValue": "Topchanchi"
    },
    {
      "id": 6875,
      "CityName": "Bilkanda",
      "CityValue": "Bilkanda"
    },
    {
      "id": 6876,
      "CityName": "Donalia",
      "CityValue": "Donalia"
    },
    {
      "id": 6877,
      "CityName": "Vijaynagar",
      "CityValue": "Vijaynagar"
    },
    {
      "id": 6878,
      "CityName": "Pathar",
      "CityValue": "Pathar"
    },
    {
      "id": 6879,
      "CityName": "Tola Chain",
      "CityValue": "Tola Chain"
    },
    {
      "id": 6880,
      "CityName": "Kanpur",
      "CityValue": "Kanpur"
    },
    {
      "id": 6881,
      "CityName": "Jhumpura",
      "CityValue": "Jhumpura"
    },
    {
      "id": 6882,
      "CityName": "Artauni",
      "CityValue": "Artauni"
    },
    {
      "id": 6883,
      "CityName": "Patrapur",
      "CityValue": "Patrapur"
    },
    {
      "id": 6884,
      "CityName": "Narendranagar",
      "CityValue": "Narendranagar"
    },
    {
      "id": 6885,
      "CityName": "Nooan",
      "CityValue": "Nooan"
    },
    {
      "id": 6886,
      "CityName": "Wadrafnagar",
      "CityValue": "Wadrafnagar"
    },
    {
      "id": 6887,
      "CityName": "Shivar",
      "CityValue": "Shivar"
    },
    {
      "id": 6888,
      "CityName": "Daundi Lohara",
      "CityValue": "Daundi Lohara"
    },
    {
      "id": 6889,
      "CityName": "Pale",
      "CityValue": "Pale"
    },
    {
      "id": 6890,
      "CityName": "Arjunpur",
      "CityValue": "Arjunpur"
    },
    {
      "id": 6891,
      "CityName": "Mayyer",
      "CityValue": "Mayyer"
    },
    {
      "id": 6892,
      "CityName": "Chidambaram",
      "CityValue": "Chidambaram"
    },
    {
      "id": 6893,
      "CityName": "Bhangar Raghunathpur",
      "CityValue": "Bhangar Raghunathpur"
    },
    {
      "id": 6894,
      "CityName": "Nanhera",
      "CityValue": "Nanhera"
    },
    {
      "id": 6895,
      "CityName": "Jalal Patti",
      "CityValue": "Jalal Patti"
    },
    {
      "id": 6896,
      "CityName": "Uttar Bagundi",
      "CityValue": "Uttar Bagundi"
    },
    {
      "id": 6897,
      "CityName": "Songadh",
      "CityValue": "Songadh"
    },
    {
      "id": 6898,
      "CityName": "Itahar",
      "CityValue": "Itahar"
    },
    {
      "id": 6899,
      "CityName": "Dahilamau",
      "CityValue": "Dahilamau"
    },
    {
      "id": 6900,
      "CityName": "Ichhlampur",
      "CityValue": "Ichhlampur"
    },
    {
      "id": 6901,
      "CityName": "Veerapandianpattinam",
      "CityValue": "Veerapandianpattinam"
    },
    {
      "id": 6902,
      "CityName": "Nachhratpur Katabari",
      "CityValue": "Nachhratpur Katabari"
    },
    {
      "id": 6903,
      "CityName": "Khori Kalan",
      "CityValue": "Khori Kalan"
    },
    {
      "id": 6904,
      "CityName": "Raghunathchak",
      "CityValue": "Raghunathchak"
    },
    {
      "id": 6905,
      "CityName": "Ghatal Mahaniawas",
      "CityValue": "Ghatal Mahaniawas"
    },
    {
      "id": 6906,
      "CityName": "Mansinhapur",
      "CityValue": "Mansinhapur"
    },
    {
      "id": 6907,
      "CityName": "B.R.P.L. Township",
      "CityValue": "B.R.P.L. Township"
    },
    {
      "id": 6908,
      "CityName": "Narayanpur",
      "CityValue": "Narayanpur"
    },
    {
      "id": 6909,
      "CityName": "Masratu",
      "CityValue": "Masratu"
    },
    {
      "id": 6910,
      "CityName": "Chekya",
      "CityValue": "Chekya"
    },
    {
      "id": 6911,
      "CityName": "Palladaha",
      "CityValue": "Palladaha"
    },
    {
      "id": 6912,
      "CityName": "Nelluwaya",
      "CityValue": "Nelluwaya"
    },
    {
      "id": 6913,
      "CityName": "Bandoan",
      "CityValue": "Bandoan"
    },
    {
      "id": 6914,
      "CityName": "Meghahatuburu Forest Village",
      "CityValue": "Meghahatuburu Forest Village"
    },
    {
      "id": 6915,
      "CityName": "Vikramgad",
      "CityValue": "Vikramgad"
    },
    {
      "id": 6916,
      "CityName": "Sircar Periapalayam",
      "CityValue": "Sircar Periapalayam"
    },
    {
      "id": 6917,
      "CityName": "Devinagar",
      "CityValue": "Devinagar"
    },
    {
      "id": 6918,
      "CityName": "Hatgachha",
      "CityValue": "Hatgachha"
    },
    {
      "id": 6919,
      "CityName": "Terkukallikulam",
      "CityValue": "Terkukallikulam"
    },
    {
      "id": 6920,
      "CityName": "Ramkrishnapur",
      "CityValue": "Ramkrishnapur"
    },
    {
      "id": 6921,
      "CityName": "Shirgaon",
      "CityValue": "Shirgaon"
    },
    {
      "id": 6922,
      "CityName": "Narasingam",
      "CityValue": "Narasingam"
    },
    {
      "id": 6923,
      "CityName": "Godiputamatiapara",
      "CityValue": "Godiputamatiapara"
    },
    {
      "id": 6924,
      "CityName": "Mirpur",
      "CityValue": "Mirpur"
    },
    {
      "id": 6925,
      "CityName": "Nabagram",
      "CityValue": "Nabagram"
    },
    {
      "id": 6926,
      "CityName": "Dhin",
      "CityValue": "Dhin"
    },
    {
      "id": 6927,
      "CityName": "Kanapaka",
      "CityValue": "Kanapaka"
    },
    {
      "id": 6928,
      "CityName": "Dhodaramohar Alias Bhoura",
      "CityValue": "Dhodaramohar Alias Bhoura"
    },
    {
      "id": 6929,
      "CityName": "Londa",
      "CityValue": "Londa"
    },
    {
      "id": 6930,
      "CityName": "Rampura",
      "CityValue": "Rampura"
    },
    {
      "id": 6931,
      "CityName": "Dighawani",
      "CityValue": "Dighawani"
    },
    {
      "id": 6932,
      "CityName": "Sairang",
      "CityValue": "Sairang"
    },
    {
      "id": 6933,
      "CityName": "Poladpur",
      "CityValue": "Poladpur"
    },
    {
      "id": 6934,
      "CityName": "Hanskunda",
      "CityValue": "Hanskunda"
    },
    {
      "id": 6935,
      "CityName": "Alipur",
      "CityValue": "Alipur"
    },
    {
      "id": 6936,
      "CityName": "Raighar",
      "CityValue": "Raighar"
    },
    {
      "id": 6937,
      "CityName": "Bay",
      "CityValue": "Bay"
    },
    {
      "id": 6938,
      "CityName": "Daper",
      "CityValue": "Daper"
    },
    {
      "id": 6939,
      "CityName": "Chittanda",
      "CityValue": "Chittanda"
    },
    {
      "id": 6940,
      "CityName": "Mangalbari",
      "CityValue": "Mangalbari"
    },
    {
      "id": 6941,
      "CityName": "Kaijuri",
      "CityValue": "Kaijuri"
    },
    {
      "id": 6942,
      "CityName": "Murulia",
      "CityValue": "Murulia"
    },
    {
      "id": 6943,
      "CityName": "Nadabhanga",
      "CityValue": "Nadabhanga"
    },
    {
      "id": 6944,
      "CityName": "Niz- Mankata",
      "CityValue": "Niz- Mankata"
    },
    {
      "id": 6945,
      "CityName": "Kursath",
      "CityValue": "Kursath"
    },
    {
      "id": 6946,
      "CityName": "Chintalavalasa",
      "CityValue": "Chintalavalasa"
    },
    {
      "id": 6947,
      "CityName": "Nuahata",
      "CityValue": "Nuahata"
    },
    {
      "id": 6948,
      "CityName": "Bela",
      "CityValue": "Bela"
    },
    {
      "id": 6949,
      "CityName": "Chelur",
      "CityValue": "Chelur"
    },
    {
      "id": 6950,
      "CityName": "Haripur",
      "CityValue": "Haripur"
    },
    {
      "id": 6951,
      "CityName": "Nagrota Bagwan",
      "CityValue": "Nagrota Bagwan"
    },
    {
      "id": 6952,
      "CityName": "Aiho",
      "CityValue": "Aiho"
    },
    {
      "id": 6953,
      "CityName": "Anarbaria",
      "CityValue": "Anarbaria"
    },
    {
      "id": 6954,
      "CityName": "Kulitapara",
      "CityValue": "Kulitapara"
    },
    {
      "id": 6955,
      "CityName": "Sathiyavijayanagaram",
      "CityValue": "Sathiyavijayanagaram"
    },
    {
      "id": 6956,
      "CityName": "Jitu",
      "CityValue": "Jitu"
    },
    {
      "id": 6957,
      "CityName": "Durena",
      "CityValue": "Durena"
    },
    {
      "id": 6958,
      "CityName": "Baroun",
      "CityValue": "Baroun"
    },
    {
      "id": 6959,
      "CityName": "Gabhana",
      "CityValue": "Gabhana"
    },
    {
      "id": 6960,
      "CityName": "Matadakurubarahatti",
      "CityValue": "Matadakurubarahatti"
    },
    {
      "id": 6961,
      "CityName": "Phulwartanr",
      "CityValue": "Phulwartanr"
    },
    {
      "id": 6962,
      "CityName": "Rhenak",
      "CityValue": "Rhenak"
    },
    {
      "id": 6963,
      "CityName": "Hutmura",
      "CityValue": "Hutmura"
    },
    {
      "id": 6964,
      "CityName": "Nabghara",
      "CityValue": "Nabghara"
    },
    {
      "id": 6965,
      "CityName": "Ghanteshvar",
      "CityValue": "Ghanteshvar"
    },
    {
      "id": 6966,
      "CityName": "Dhali",
      "CityValue": "Dhali"
    },
    {
      "id": 6967,
      "CityName": "Bangaon",
      "CityValue": "Bangaon"
    },
    {
      "id": 6968,
      "CityName": "Amardad",
      "CityValue": "Amardad"
    },
    {
      "id": 6969,
      "CityName": "Kachnar",
      "CityValue": "Kachnar"
    },
    {
      "id": 6970,
      "CityName": "Singtam",
      "CityValue": "Singtam"
    },
    {
      "id": 6971,
      "CityName": "Karma Tanr",
      "CityValue": "Karma Tanr"
    },
    {
      "id": 6972,
      "CityName": "Uttar Pirpur",
      "CityValue": "Uttar Pirpur"
    },
    {
      "id": 6973,
      "CityName": "Bayana",
      "CityValue": "Bayana"
    },
    {
      "id": 6974,
      "CityName": "Naul",
      "CityValue": "Naul"
    },
    {
      "id": 6975,
      "CityName": "Bikketti",
      "CityValue": "Bikketti"
    },
    {
      "id": 6976,
      "CityName": "Onda",
      "CityValue": "Onda"
    },
    {
      "id": 6977,
      "CityName": "Kalikapur",
      "CityValue": "Kalikapur"
    },
    {
      "id": 6978,
      "CityName": "Nattarasankottai",
      "CityValue": "Nattarasankottai"
    },
    {
      "id": 6979,
      "CityName": "Arsodiya",
      "CityValue": "Arsodiya"
    },
    {
      "id": 6980,
      "CityName": "Belgharia",
      "CityValue": "Belgharia"
    },
    {
      "id": 6981,
      "CityName": "Tola Pairamatihana",
      "CityValue": "Tola Pairamatihana"
    },
    {
      "id": 6982,
      "CityName": "Bondathila",
      "CityValue": "Bondathila"
    },
    {
      "id": 6983,
      "CityName": "Dasanaickenpatti",
      "CityValue": "Dasanaickenpatti"
    },
    {
      "id": 6984,
      "CityName": "Khorddabamonia",
      "CityValue": "Khorddabamonia"
    },
    {
      "id": 6985,
      "CityName": "Amlajora",
      "CityValue": "Amlajora"
    },
    {
      "id": 6986,
      "CityName": "Sarna",
      "CityValue": "Sarna"
    },
    {
      "id": 6987,
      "CityName": "Akathiyoor",
      "CityValue": "Akathiyoor"
    },
    {
      "id": 6988,
      "CityName": "Sajipanadu",
      "CityValue": "Sajipanadu"
    },
    {
      "id": 6989,
      "CityName": "Miao",
      "CityValue": "Miao"
    },
    {
      "id": 6990,
      "CityName": "Masat",
      "CityValue": "Masat"
    },
    {
      "id": 6991,
      "CityName": "Hosanagara",
      "CityValue": "Hosanagara"
    },
    {
      "id": 6992,
      "CityName": "Shibalaya",
      "CityValue": "Shibalaya"
    },
    {
      "id": 6993,
      "CityName": "Pilerne",
      "CityValue": "Pilerne"
    },
    {
      "id": 6994,
      "CityName": "Kotwa",
      "CityValue": "Kotwa"
    },
    {
      "id": 6995,
      "CityName": "Gabberia",
      "CityValue": "Gabberia"
    },
    {
      "id": 6996,
      "CityName": "Mohanpur",
      "CityValue": "Mohanpur"
    },
    {
      "id": 6997,
      "CityName": "Pashchim Khalna",
      "CityValue": "Pashchim Khalna"
    },
    {
      "id": 6998,
      "CityName": "Orla",
      "CityValue": "Orla"
    },
    {
      "id": 6999,
      "CityName": "Rajapalayam",
      "CityValue": "Rajapalayam"
    },
    {
      "id": 7000,
      "CityName": "Dhola",
      "CityValue": "Dhola"
    },
    {
      "id": 7001,
      "CityName": "Nandigram",
      "CityValue": "Nandigram"
    },
    {
      "id": 7002,
      "CityName": "Chongtong Tea Garden",
      "CityValue": "Chongtong Tea Garden"
    },
    {
      "id": 7003,
      "CityName": "Peranamallur",
      "CityValue": "Peranamallur"
    },
    {
      "id": 7004,
      "CityName": "Deori Singhpura",
      "CityValue": "Deori Singhpura"
    },
    {
      "id": 7005,
      "CityName": "Morattupalayam",
      "CityValue": "Morattupalayam"
    },
    {
      "id": 7006,
      "CityName": "Singtam Tea Garden",
      "CityValue": "Singtam Tea Garden"
    },
    {
      "id": 7007,
      "CityName": "Bangra Manjeshwar",
      "CityValue": "Bangra Manjeshwar"
    },
    {
      "id": 7008,
      "CityName": "Parlli Part",
      "CityValue": "Parlli Part"
    },
    {
      "id": 7009,
      "CityName": "Kairangala",
      "CityValue": "Kairangala"
    },
    {
      "id": 7010,
      "CityName": "Saktasanala",
      "CityValue": "Saktasanala"
    },
    {
      "id": 7011,
      "CityName": "Nagamangalam",
      "CityValue": "Nagamangalam"
    },
    {
      "id": 7012,
      "CityName": "Komarhat",
      "CityValue": "Komarhat"
    },
    {
      "id": 7013,
      "CityName": "Sansari",
      "CityValue": "Sansari"
    },
    {
      "id": 7014,
      "CityName": "Borivali Tarf Rahur",
      "CityValue": "Borivali Tarf Rahur"
    },
    {
      "id": 7015,
      "CityName": "Chopra",
      "CityValue": "Chopra"
    },
    {
      "id": 7016,
      "CityName": "Takyel Mapal",
      "CityValue": "Takyel Mapal"
    },
    {
      "id": 7017,
      "CityName": "Nagala Imarti",
      "CityValue": "Nagala Imarti"
    },
    {
      "id": 7018,
      "CityName": "Sonapur Gaon",
      "CityValue": "Sonapur Gaon"
    },
    {
      "id": 7019,
      "CityName": "Korianwali",
      "CityValue": "Korianwali"
    },
    {
      "id": 7020,
      "CityName": "Murarai",
      "CityValue": "Murarai"
    },
    {
      "id": 7021,
      "CityName": "Bedla",
      "CityValue": "Bedla"
    },
    {
      "id": 7022,
      "CityName": "Banthla",
      "CityValue": "Banthla"
    },
    {
      "id": 7023,
      "CityName": "Priyanagar",
      "CityValue": "Priyanagar"
    },
    {
      "id": 7024,
      "CityName": "Samathur",
      "CityValue": "Samathur"
    },
    {
      "id": 7025,
      "CityName": "Jarjapupeta",
      "CityValue": "Jarjapupeta"
    },
    {
      "id": 7026,
      "CityName": "Subarnapur",
      "CityValue": "Subarnapur"
    },
    {
      "id": 7027,
      "CityName": "Bangomunda",
      "CityValue": "Bangomunda"
    },
    {
      "id": 7028,
      "CityName": "Karma",
      "CityValue": "Karma"
    },
    {
      "id": 7029,
      "CityName": "Kachu Pukur",
      "CityValue": "Kachu Pukur"
    },
    {
      "id": 7030,
      "CityName": "Palalahada",
      "CityValue": "Palalahada"
    },
    {
      "id": 7031,
      "CityName": "Ganwaria Tulsipur",
      "CityValue": "Ganwaria Tulsipur"
    },
    {
      "id": 7032,
      "CityName": "Raghabpur",
      "CityValue": "Raghabpur"
    },
    {
      "id": 7033,
      "CityName": "Sherpura",
      "CityValue": "Sherpura"
    },
    {
      "id": 7034,
      "CityName": "Chegunta",
      "CityValue": "Chegunta"
    },
    {
      "id": 7035,
      "CityName": "Karvad",
      "CityValue": "Karvad"
    },
    {
      "id": 7036,
      "CityName": "Chalantapara Pt IV",
      "CityValue": "Chalantapara Pt IV"
    },
    {
      "id": 7037,
      "CityName": "Nemilicheri",
      "CityValue": "Nemilicheri"
    },
    {
      "id": 7038,
      "CityName": "Pimpalgaon Najik",
      "CityValue": "Pimpalgaon Najik"
    },
    {
      "id": 7039,
      "CityName": "Thalakudi",
      "CityValue": "Thalakudi"
    },
    {
      "id": 7040,
      "CityName": "Ramdaspur Urf Nagal",
      "CityValue": "Ramdaspur Urf Nagal"
    },
    {
      "id": 7041,
      "CityName": "Bhurivel",
      "CityValue": "Bhurivel"
    },
    {
      "id": 7042,
      "CityName": "Jhagarwas",
      "CityValue": "Jhagarwas"
    },
    {
      "id": 7043,
      "CityName": "Goyli",
      "CityValue": "Goyli"
    },
    {
      "id": 7044,
      "CityName": "Salamba",
      "CityValue": "Salamba"
    },
    {
      "id": 7045,
      "CityName": "Shilda",
      "CityValue": "Shilda"
    },
    {
      "id": 7046,
      "CityName": "Uttar Durgapur",
      "CityValue": "Uttar Durgapur"
    },
    {
      "id": 7047,
      "CityName": "Rakri",
      "CityValue": "Rakri"
    },
    {
      "id": 7048,
      "CityName": "Adat",
      "CityValue": "Adat"
    },
    {
      "id": 7049,
      "CityName": "Arakandanallur",
      "CityValue": "Arakandanallur"
    },
    {
      "id": 7050,
      "CityName": "Dogachhia",
      "CityValue": "Dogachhia"
    },
    {
      "id": 7051,
      "CityName": "Pudupalayam Agraharam",
      "CityValue": "Pudupalayam Agraharam"
    },
    {
      "id": 7052,
      "CityName": "Banshra",
      "CityValue": "Banshra"
    },
    {
      "id": 7053,
      "CityName": "Palpur",
      "CityValue": "Palpur"
    },
    {
      "id": 7054,
      "CityName": "Swangrampur",
      "CityValue": "Swangrampur"
    },
    {
      "id": 7055,
      "CityName": "Thozhur",
      "CityValue": "Thozhur"
    },
    {
      "id": 7056,
      "CityName": "Lagda",
      "CityValue": "Lagda"
    },
    {
      "id": 7057,
      "CityName": "Overi",
      "CityValue": "Overi"
    },
    {
      "id": 7058,
      "CityName": "Bodla",
      "CityValue": "Bodla"
    },
    {
      "id": 7059,
      "CityName": "Niz Katigorah Pt III",
      "CityValue": "Niz Katigorah Pt III"
    },
    {
      "id": 7060,
      "CityName": "Gajapathinagaram",
      "CityValue": "Gajapathinagaram"
    },
    {
      "id": 7061,
      "CityName": "Muduperar",
      "CityValue": "Muduperar"
    },
    {
      "id": 7062,
      "CityName": "Matigara",
      "CityValue": "Matigara"
    },
    {
      "id": 7063,
      "CityName": "Shahpur",
      "CityValue": "Shahpur"
    },
    {
      "id": 7064,
      "CityName": "Khor",
      "CityValue": "Khor"
    },
    {
      "id": 7065,
      "CityName": "Dhakauli",
      "CityValue": "Dhakauli"
    },
    {
      "id": 7066,
      "CityName": "Ghosal Chak",
      "CityValue": "Ghosal Chak"
    },
    {
      "id": 7067,
      "CityName": "Moranhat",
      "CityValue": "Moranhat"
    },
    {
      "id": 7068,
      "CityName": "Bharthana Kosad",
      "CityValue": "Bharthana Kosad"
    },
    {
      "id": 7069,
      "CityName": "Lansdowne",
      "CityValue": "Lansdowne"
    },
    {
      "id": 7070,
      "CityName": "Bhandardaha",
      "CityValue": "Bhandardaha"
    },
    {
      "id": 7071,
      "CityName": "Mahapoli",
      "CityValue": "Mahapoli"
    },
    {
      "id": 7072,
      "CityName": "Jyoti Khuriya",
      "CityValue": "Jyoti Khuriya"
    },
    {
      "id": 7073,
      "CityName": "Sangrampur",
      "CityValue": "Sangrampur"
    },
    {
      "id": 7074,
      "CityName": "Tukhmir Pur",
      "CityValue": "Tukhmir Pur"
    },
    {
      "id": 7075,
      "CityName": "Tundla",
      "CityValue": "Tundla"
    },
    {
      "id": 7076,
      "CityName": "Chanddandaha",
      "CityValue": "Chanddandaha"
    },
    {
      "id": 7077,
      "CityName": "Kharkhari",
      "CityValue": "Kharkhari"
    },
    {
      "id": 7078,
      "CityName": "Rampur",
      "CityValue": "Rampur"
    },
    {
      "id": 7079,
      "CityName": "Kullada",
      "CityValue": "Kullada"
    },
    {
      "id": 7080,
      "CityName": "Mangarjung Tea Garden",
      "CityValue": "Mangarjung Tea Garden"
    },
    {
      "id": 7081,
      "CityName": "Amethi Jadid",
      "CityValue": "Amethi Jadid"
    },
    {
      "id": 7082,
      "CityName": "Saidpura",
      "CityValue": "Saidpura"
    },
    {
      "id": 7083,
      "CityName": "Mohmaiki",
      "CityValue": "Mohmaiki"
    },
    {
      "id": 7084,
      "CityName": "Chinnachintakunta",
      "CityValue": "Chinnachintakunta"
    },
    {
      "id": 7085,
      "CityName": "Banagram",
      "CityValue": "Banagram"
    },
    {
      "id": 7086,
      "CityName": "Pratappur",
      "CityValue": "Pratappur"
    },
    {
      "id": 7087,
      "CityName": "Nowangabra",
      "CityValue": "Nowangabra"
    },
    {
      "id": 7088,
      "CityName": "Ashti",
      "CityValue": "Ashti"
    },
    {
      "id": 7089,
      "CityName": "Nakoda",
      "CityValue": "Nakoda"
    },
    {
      "id": 7090,
      "CityName": "Nanpur",
      "CityValue": "Nanpur"
    },
    {
      "id": 7091,
      "CityName": "No.2 Goreswar",
      "CityValue": "No.2 Goreswar"
    },
    {
      "id": 7092,
      "CityName": "K.Madapur",
      "CityValue": "K.Madapur"
    },
    {
      "id": 7093,
      "CityName": "Thekashu Pt.-II",
      "CityValue": "Thekashu Pt.-II"
    },
    {
      "id": 7094,
      "CityName": "Meharagaon",
      "CityValue": "Meharagaon"
    },
    {
      "id": 7095,
      "CityName": "Gaura",
      "CityValue": "Gaura"
    },
    {
      "id": 7096,
      "CityName": "Ramgarh",
      "CityValue": "Ramgarh"
    },
    {
      "id": 7097,
      "CityName": "Chandigarh",
      "CityValue": "Chandigarh"
    },
    {
      "id": 7098,
      "CityName": "Perumagalur",
      "CityValue": "Perumagalur"
    },
    {
      "id": 7099,
      "CityName": "Bhuran",
      "CityValue": "Bhuran"
    },
    {
      "id": 7100,
      "CityName": "Baramkela",
      "CityValue": "Baramkela"
    },
    {
      "id": 7101,
      "CityName": "Majhihara",
      "CityValue": "Majhihara"
    },
    {
      "id": 7102,
      "CityName": "Chitkul",
      "CityValue": "Chitkul"
    },
    {
      "id": 7103,
      "CityName": "Nurpur",
      "CityValue": "Nurpur"
    },
    {
      "id": 7104,
      "CityName": "Raynagar",
      "CityValue": "Raynagar"
    },
    {
      "id": 7105,
      "CityName": "Kolambe",
      "CityValue": "Kolambe"
    },
    {
      "id": 7106,
      "CityName": "Chhatianmor",
      "CityValue": "Chhatianmor"
    },
    {
      "id": 7107,
      "CityName": "Lachhiman Patti",
      "CityValue": "Lachhiman Patti"
    },
    {
      "id": 7108,
      "CityName": "Chenar Village",
      "CityValue": "Chenar Village"
    },
    {
      "id": 7109,
      "CityName": "Khardi",
      "CityValue": "Khardi"
    },
    {
      "id": 7110,
      "CityName": "Dusi",
      "CityValue": "Dusi"
    },
    {
      "id": 7111,
      "CityName": "Peringandoor",
      "CityValue": "Peringandoor"
    },
    {
      "id": 7112,
      "CityName": "Gangiri",
      "CityValue": "Gangiri"
    },
    {
      "id": 7113,
      "CityName": "Putheri",
      "CityValue": "Putheri"
    },
    {
      "id": 7114,
      "CityName": "Chanchal",
      "CityValue": "Chanchal"
    },
    {
      "id": 7115,
      "CityName": "Satrod Khurd",
      "CityValue": "Satrod Khurd"
    },
    {
      "id": 7116,
      "CityName": "Tegheria",
      "CityValue": "Tegheria"
    },
    {
      "id": 7117,
      "CityName": "Ashmuji Khalsa",
      "CityValue": "Ashmuji Khalsa"
    },
    {
      "id": 7118,
      "CityName": "Chandapur",
      "CityValue": "Chandapur"
    },
    {
      "id": 7119,
      "CityName": "Basantpur Saitli",
      "CityValue": "Basantpur Saitli"
    },
    {
      "id": 7120,
      "CityName": "Hajira",
      "CityValue": "Hajira"
    },
    {
      "id": 7121,
      "CityName": "Bhasa",
      "CityValue": "Bhasa"
    },
    {
      "id": 7122,
      "CityName": "Dudhkalmi",
      "CityValue": "Dudhkalmi"
    },
    {
      "id": 7123,
      "CityName": "TNPL Pugalur",
      "CityValue": "TNPL Pugalur"
    },
    {
      "id": 7124,
      "CityName": "Atrari",
      "CityValue": "Atrari"
    },
    {
      "id": 7125,
      "CityName": "Maheshpur",
      "CityValue": "Maheshpur"
    },
    {
      "id": 7126,
      "CityName": "Baswar",
      "CityValue": "Baswar"
    },
    {
      "id": 7127,
      "CityName": "Alipukur",
      "CityValue": "Alipukur"
    },
    {
      "id": 7128,
      "CityName": "Kharijapikon",
      "CityValue": "Kharijapikon"
    },
    {
      "id": 7129,
      "CityName": "Kakaya",
      "CityValue": "Kakaya"
    },
    {
      "id": 7130,
      "CityName": "Sarpi",
      "CityValue": "Sarpi"
    },
    {
      "id": 7131,
      "CityName": "Balarampota",
      "CityValue": "Balarampota"
    },
    {
      "id": 7132,
      "CityName": "Bilaigarh",
      "CityValue": "Bilaigarh"
    },
    {
      "id": 7133,
      "CityName": "Dwarakatirumala",
      "CityValue": "Dwarakatirumala"
    },
    {
      "id": 7134,
      "CityName": "Gulariya",
      "CityValue": "Gulariya"
    },
    {
      "id": 7135,
      "CityName": "Barijpur",
      "CityValue": "Barijpur"
    },
    {
      "id": 7136,
      "CityName": "Garra",
      "CityValue": "Garra"
    },
    {
      "id": 7137,
      "CityName": "Venginissery",
      "CityValue": "Venginissery"
    },
    {
      "id": 7138,
      "CityName": "Gangni",
      "CityValue": "Gangni"
    },
    {
      "id": 7139,
      "CityName": "Anantapur",
      "CityValue": "Anantapur"
    },
    {
      "id": 7140,
      "CityName": "Lad Pur",
      "CityValue": "Lad Pur"
    },
    {
      "id": 7141,
      "CityName": "Valathur",
      "CityValue": "Valathur"
    },
    {
      "id": 7142,
      "CityName": "Khidirpur",
      "CityValue": "Khidirpur"
    },
    {
      "id": 7143,
      "CityName": "Belagachhia",
      "CityValue": "Belagachhia"
    },
    {
      "id": 7144,
      "CityName": "Pali",
      "CityValue": "Pali"
    },
    {
      "id": 7145,
      "CityName": "Khanpur",
      "CityValue": "Khanpur"
    },
    {
      "id": 7146,
      "CityName": "Dubra",
      "CityValue": "Dubra"
    },
    {
      "id": 7147,
      "CityName": "Kalarangiata",
      "CityValue": "Kalarangiata"
    },
    {
      "id": 7148,
      "CityName": "Begari",
      "CityValue": "Begari"
    },
    {
      "id": 7149,
      "CityName": "Neermarga",
      "CityValue": "Neermarga"
    },
    {
      "id": 7150,
      "CityName": "Carapur",
      "CityValue": "Carapur"
    },
    {
      "id": 7151,
      "CityName": "Rampur",
      "CityValue": "Rampur"
    },
    {
      "id": 7152,
      "CityName": "Thanamandi",
      "CityValue": "Thanamandi"
    },
    {
      "id": 7153,
      "CityName": "Sobhaganj",
      "CityValue": "Sobhaganj"
    },
    {
      "id": 7154,
      "CityName": "Salvav",
      "CityValue": "Salvav"
    },
    {
      "id": 7155,
      "CityName": "Amehra Adipur",
      "CityValue": "Amehra Adipur"
    },
    {
      "id": 7156,
      "CityName": "Khambra",
      "CityValue": "Khambra"
    },
    {
      "id": 7157,
      "CityName": "Jasra",
      "CityValue": "Jasra"
    },
    {
      "id": 7158,
      "CityName": "Chhota Laukuthi",
      "CityValue": "Chhota Laukuthi"
    },
    {
      "id": 7159,
      "CityName": "Fulchur",
      "CityValue": "Fulchur"
    },
    {
      "id": 7160,
      "CityName": "Dokmoka",
      "CityValue": "Dokmoka"
    },
    {
      "id": 7161,
      "CityName": "Kakdihi",
      "CityValue": "Kakdihi"
    },
    {
      "id": 7162,
      "CityName": "Baniara",
      "CityValue": "Baniara"
    },
    {
      "id": 7163,
      "CityName": "Vellakkalpatty",
      "CityValue": "Vellakkalpatty"
    },
    {
      "id": 7164,
      "CityName": "Dhalavoipuram",
      "CityValue": "Dhalavoipuram"
    },
    {
      "id": 7165,
      "CityName": "Raipur",
      "CityValue": "Raipur"
    },
    {
      "id": 7166,
      "CityName": "Magam",
      "CityValue": "Magam"
    },
    {
      "id": 7167,
      "CityName": "Rangabhita",
      "CityValue": "Rangabhita"
    },
    {
      "id": 7168,
      "CityName": "Dhulasimla",
      "CityValue": "Dhulasimla"
    },
    {
      "id": 7169,
      "CityName": "Dafarpur",
      "CityValue": "Dafarpur"
    },
    {
      "id": 7170,
      "CityName": "Jalalpur",
      "CityValue": "Jalalpur"
    },
    {
      "id": 7171,
      "CityName": "Torban",
      "CityValue": "Torban"
    },
    {
      "id": 7172,
      "CityName": "Bansatar Kheda",
      "CityValue": "Bansatar Kheda"
    },
    {
      "id": 7173,
      "CityName": "Basantia",
      "CityValue": "Basantia"
    },
    {
      "id": 7174,
      "CityName": "Pardi Parnera",
      "CityValue": "Pardi Parnera"
    },
    {
      "id": 7175,
      "CityName": "Babugarh",
      "CityValue": "Babugarh"
    },
    {
      "id": 7176,
      "CityName": "Langjing",
      "CityValue": "Langjing"
    },
    {
      "id": 7177,
      "CityName": "Sialgudri",
      "CityValue": "Sialgudri"
    },
    {
      "id": 7178,
      "CityName": "Ramnagar",
      "CityValue": "Ramnagar"
    },
    {
      "id": 7179,
      "CityName": "Amkula",
      "CityValue": "Amkula"
    },
    {
      "id": 7180,
      "CityName": "Mulur",
      "CityValue": "Mulur"
    },
    {
      "id": 7181,
      "CityName": "Majiara",
      "CityValue": "Majiara"
    },
    {
      "id": 7182,
      "CityName": "Barua Bari Gaon",
      "CityValue": "Barua Bari Gaon"
    },
    {
      "id": 7183,
      "CityName": "Howraghat",
      "CityValue": "Howraghat"
    },
    {
      "id": 7184,
      "CityName": "Bardol",
      "CityValue": "Bardol"
    },
    {
      "id": 7185,
      "CityName": "Choolissery",
      "CityValue": "Choolissery"
    },
    {
      "id": 7186,
      "CityName": "Harindanga",
      "CityValue": "Harindanga"
    },
    {
      "id": 7187,
      "CityName": "Varca",
      "CityValue": "Varca"
    },
    {
      "id": 7188,
      "CityName": "Kaulakha",
      "CityValue": "Kaulakha"
    },
    {
      "id": 7189,
      "CityName": "Kambe",
      "CityValue": "Kambe"
    },
    {
      "id": 7190,
      "CityName": "Kharach",
      "CityValue": "Kharach"
    },
    {
      "id": 7191,
      "CityName": "Kantaranguri",
      "CityValue": "Kantaranguri"
    },
    {
      "id": 7192,
      "CityName": "Kusadanga",
      "CityValue": "Kusadanga"
    },
    {
      "id": 7193,
      "CityName": "Bashohli",
      "CityValue": "Bashohli"
    },
    {
      "id": 7194,
      "CityName": "Behrampur",
      "CityValue": "Behrampur"
    },
    {
      "id": 7195,
      "CityName": "Saidapur",
      "CityValue": "Saidapur"
    },
    {
      "id": 7196,
      "CityName": "Badmal",
      "CityValue": "Badmal"
    },
    {
      "id": 7197,
      "CityName": "Kadayam",
      "CityValue": "Kadayam"
    },
    {
      "id": 7198,
      "CityName": "Addur",
      "CityValue": "Addur"
    },
    {
      "id": 7199,
      "CityName": "Pipara Dand",
      "CityValue": "Pipara Dand"
    },
    {
      "id": 7200,
      "CityName": "Orvad",
      "CityValue": "Orvad"
    },
    {
      "id": 7201,
      "CityName": "Labhpur",
      "CityValue": "Labhpur"
    },
    {
      "id": 7202,
      "CityName": "Chogawan",
      "CityValue": "Chogawan"
    },
    {
      "id": 7203,
      "CityName": "Baghola",
      "CityValue": "Baghola"
    },
    {
      "id": 7204,
      "CityName": "Basina",
      "CityValue": "Basina"
    },
    {
      "id": 7205,
      "CityName": "Theerthagiriyampattu",
      "CityValue": "Theerthagiriyampattu"
    },
    {
      "id": 7206,
      "CityName": "Garalgachha",
      "CityValue": "Garalgachha"
    },
    {
      "id": 7207,
      "CityName": "Paschim Gazipur",
      "CityValue": "Paschim Gazipur"
    },
    {
      "id": 7208,
      "CityName": "Gujarkheda",
      "CityValue": "Gujarkheda"
    },
    {
      "id": 7209,
      "CityName": "Bobruwada",
      "CityValue": "Bobruwada"
    },
    {
      "id": 7210,
      "CityName": "Kot",
      "CityValue": "Kot"
    },
    {
      "id": 7211,
      "CityName": "Bayyanpur",
      "CityValue": "Bayyanpur"
    },
    {
      "id": 7212,
      "CityName": "Kadmat",
      "CityValue": "Kadmat"
    },
    {
      "id": 7213,
      "CityName": "Odagaon",
      "CityValue": "Odagaon"
    },
    {
      "id": 7214,
      "CityName": "Kachrauli",
      "CityValue": "Kachrauli"
    },
    {
      "id": 7215,
      "CityName": "Tola Baliadih",
      "CityValue": "Tola Baliadih"
    },
    {
      "id": 7216,
      "CityName": "Narra",
      "CityValue": "Narra"
    },
    {
      "id": 7217,
      "CityName": "Dadar",
      "CityValue": "Dadar"
    },
    {
      "id": 7218,
      "CityName": "Pallanthurai",
      "CityValue": "Pallanthurai"
    },
    {
      "id": 7219,
      "CityName": "Nabaghanapur",
      "CityValue": "Nabaghanapur"
    },
    {
      "id": 7220,
      "CityName": "Sangvi",
      "CityValue": "Sangvi"
    },
    {
      "id": 7221,
      "CityName": "Kullursandai",
      "CityValue": "Kullursandai"
    },
    {
      "id": 7222,
      "CityName": "Jallabad",
      "CityValue": "Jallabad"
    },
    {
      "id": 7223,
      "CityName": "Kesaripur",
      "CityValue": "Kesaripur"
    },
    {
      "id": 7224,
      "CityName": "Kohetpur",
      "CityValue": "Kohetpur"
    },
    {
      "id": 7225,
      "CityName": "Garauri",
      "CityValue": "Garauri"
    },
    {
      "id": 7226,
      "CityName": "Amanganj",
      "CityValue": "Amanganj"
    },
    {
      "id": 7227,
      "CityName": "Fatikroy",
      "CityValue": "Fatikroy"
    },
    {
      "id": 7228,
      "CityName": "Lapara",
      "CityValue": "Lapara"
    },
    {
      "id": 7229,
      "CityName": "Navoor",
      "CityValue": "Navoor"
    },
    {
      "id": 7230,
      "CityName": "Jawalamukhi",
      "CityValue": "Jawalamukhi"
    },
    {
      "id": 7231,
      "CityName": "Chauparan",
      "CityValue": "Chauparan"
    },
    {
      "id": 7232,
      "CityName": "Chakmeghoan",
      "CityValue": "Chakmeghoan"
    },
    {
      "id": 7233,
      "CityName": "Chathirareddipatti",
      "CityValue": "Chathirareddipatti"
    },
    {
      "id": 7234,
      "CityName": "Mansur Chak",
      "CityValue": "Mansur Chak"
    },
    {
      "id": 7235,
      "CityName": "Chapui",
      "CityValue": "Chapui"
    },
    {
      "id": 7236,
      "CityName": "Changsari",
      "CityValue": "Changsari"
    },
    {
      "id": 7237,
      "CityName": "Candola",
      "CityValue": "Candola"
    },
    {
      "id": 7238,
      "CityName": "Panpara",
      "CityValue": "Panpara"
    },
    {
      "id": 7239,
      "CityName": "Omerkhan Daira",
      "CityValue": "Omerkhan Daira"
    },
    {
      "id": 7240,
      "CityName": "Lakhi Nepali",
      "CityValue": "Lakhi Nepali"
    },
    {
      "id": 7241,
      "CityName": "Jai Nagar",
      "CityValue": "Jai Nagar"
    },
    {
      "id": 7242,
      "CityName": "Khadalgobra",
      "CityValue": "Khadalgobra"
    },
    {
      "id": 7243,
      "CityName": "Sanjali",
      "CityValue": "Sanjali"
    },
    {
      "id": 7244,
      "CityName": "Gargeswari",
      "CityValue": "Gargeswari"
    },
    {
      "id": 7245,
      "CityName": "Bankauli",
      "CityValue": "Bankauli"
    },
    {
      "id": 7246,
      "CityName": "Kamat Phulbari",
      "CityValue": "Kamat Phulbari"
    },
    {
      "id": 7247,
      "CityName": "Raghunathpur",
      "CityValue": "Raghunathpur"
    },
    {
      "id": 7248,
      "CityName": "Malkapur",
      "CityValue": "Malkapur"
    },
    {
      "id": 7249,
      "CityName": "Chennagiri",
      "CityValue": "Chennagiri"
    },
    {
      "id": 7250,
      "CityName": "Kenjar",
      "CityValue": "Kenjar"
    },
    {
      "id": 7251,
      "CityName": "Kiyamgei",
      "CityValue": "Kiyamgei"
    },
    {
      "id": 7252,
      "CityName": "Tin Pahar",
      "CityValue": "Tin Pahar"
    },
    {
      "id": 7253,
      "CityName": "Jogindarnagar",
      "CityValue": "Jogindarnagar"
    },
    {
      "id": 7254,
      "CityName": "Sindhawali",
      "CityValue": "Sindhawali"
    },
    {
      "id": 7255,
      "CityName": "Vanapadi",
      "CityValue": "Vanapadi"
    },
    {
      "id": 7256,
      "CityName": "Nai-Ledri",
      "CityValue": "Nai-Ledri"
    },
    {
      "id": 7257,
      "CityName": "Erashal",
      "CityValue": "Erashal"
    },
    {
      "id": 7258,
      "CityName": "Jhanti Pahari",
      "CityValue": "Jhanti Pahari"
    },
    {
      "id": 7259,
      "CityName": "Bhagirathpur",
      "CityValue": "Bhagirathpur"
    },
    {
      "id": 7260,
      "CityName": "Doramangalam",
      "CityValue": "Doramangalam"
    },
    {
      "id": 7261,
      "CityName": "Arambol",
      "CityValue": "Arambol"
    },
    {
      "id": 7262,
      "CityName": "Kilvaithinankuppam",
      "CityValue": "Kilvaithinankuppam"
    },
    {
      "id": 7263,
      "CityName": "Bhardua",
      "CityValue": "Bhardua"
    },
    {
      "id": 7264,
      "CityName": "Ghutgarya",
      "CityValue": "Ghutgarya"
    },
    {
      "id": 7265,
      "CityName": "Marar",
      "CityValue": "Marar"
    },
    {
      "id": 7266,
      "CityName": "Singhesar Asthan",
      "CityValue": "Singhesar Asthan"
    },
    {
      "id": 7267,
      "CityName": "Pertodih",
      "CityValue": "Pertodih"
    },
    {
      "id": 7268,
      "CityName": "Dharampur",
      "CityValue": "Dharampur"
    },
    {
      "id": 7269,
      "CityName": "Osmanpur",
      "CityValue": "Osmanpur"
    },
    {
      "id": 7270,
      "CityName": "Chandpala Anantapathpur",
      "CityValue": "Chandpala Anantapathpur"
    },
    {
      "id": 7271,
      "CityName": "Digheli",
      "CityValue": "Digheli"
    },
    {
      "id": 7272,
      "CityName": "Lasjan",
      "CityValue": "Lasjan"
    },
    {
      "id": 7273,
      "CityName": "Mahimapur",
      "CityValue": "Mahimapur"
    },
    {
      "id": 7274,
      "CityName": "Par Beliya",
      "CityValue": "Par Beliya"
    },
    {
      "id": 7275,
      "CityName": "Cherra",
      "CityValue": "Cherra"
    },
    {
      "id": 7276,
      "CityName": "Shiriya",
      "CityValue": "Shiriya"
    },
    {
      "id": 7277,
      "CityName": "Kizhuppillikkara",
      "CityValue": "Kizhuppillikkara"
    },
    {
      "id": 7278,
      "CityName": "Padoli",
      "CityValue": "Padoli"
    },
    {
      "id": 7279,
      "CityName": "Kanadukathan",
      "CityValue": "Kanadukathan"
    },
    {
      "id": 7280,
      "CityName": "Bora Gagangohalia",
      "CityValue": "Bora Gagangohalia"
    },
    {
      "id": 7281,
      "CityName": "Ashadtalya",
      "CityValue": "Ashadtalya"
    },
    {
      "id": 7282,
      "CityName": "Lebachhara",
      "CityValue": "Lebachhara"
    },
    {
      "id": 7283,
      "CityName": "Dinga Khola",
      "CityValue": "Dinga Khola"
    },
    {
      "id": 7284,
      "CityName": "Dakra",
      "CityValue": "Dakra"
    },
    {
      "id": 7285,
      "CityName": "Ghoralia",
      "CityValue": "Ghoralia"
    },
    {
      "id": 7286,
      "CityName": "Laipham Siphai",
      "CityValue": "Laipham Siphai"
    },
    {
      "id": 7287,
      "CityName": "Hasimnagar",
      "CityValue": "Hasimnagar"
    },
    {
      "id": 7288,
      "CityName": "Parota",
      "CityValue": "Parota"
    },
    {
      "id": 7289,
      "CityName": "Sinduria",
      "CityValue": "Sinduria"
    },
    {
      "id": 7290,
      "CityName": "Gaur Daha",
      "CityValue": "Gaur Daha"
    },
    {
      "id": 7291,
      "CityName": "Bungal",
      "CityValue": "Bungal"
    },
    {
      "id": 7292,
      "CityName": "Saja",
      "CityValue": "Saja"
    },
    {
      "id": 7293,
      "CityName": "Balarampur",
      "CityValue": "Balarampur"
    },
    {
      "id": 7294,
      "CityName": "Chakiabhita",
      "CityValue": "Chakiabhita"
    },
    {
      "id": 7295,
      "CityName": "Kharbav",
      "CityValue": "Kharbav"
    },
    {
      "id": 7296,
      "CityName": "Damalerimuthur",
      "CityValue": "Damalerimuthur"
    },
    {
      "id": 7297,
      "CityName": "Madhawgdha",
      "CityValue": "Madhawgdha"
    },
    {
      "id": 7298,
      "CityName": "Jagatnagar",
      "CityValue": "Jagatnagar"
    },
    {
      "id": 7299,
      "CityName": "Gird Baragaon",
      "CityValue": "Gird Baragaon"
    },
    {
      "id": 7300,
      "CityName": "Borgolai Grant No.II",
      "CityValue": "Borgolai Grant No.II"
    },
    {
      "id": 7301,
      "CityName": "Toto",
      "CityValue": "Toto"
    },
    {
      "id": 7302,
      "CityName": "Bongabar",
      "CityValue": "Bongabar"
    },
    {
      "id": 7303,
      "CityName": "Amila",
      "CityValue": "Amila"
    },
    {
      "id": 7304,
      "CityName": "Malicherla",
      "CityValue": "Malicherla"
    },
    {
      "id": 7305,
      "CityName": "Chak Ratnu",
      "CityValue": "Chak Ratnu"
    },
    {
      "id": 7306,
      "CityName": "Tamgaon",
      "CityValue": "Tamgaon"
    },
    {
      "id": 7307,
      "CityName": "Kakari",
      "CityValue": "Kakari"
    },
    {
      "id": 7308,
      "CityName": "Anulia",
      "CityValue": "Anulia"
    },
    {
      "id": 7309,
      "CityName": "Ichhapur Defence Estate",
      "CityValue": "Ichhapur Defence Estate"
    },
    {
      "id": 7310,
      "CityName": "Bangsidharpur",
      "CityValue": "Bangsidharpur"
    },
    {
      "id": 7311,
      "CityName": "Mubarakpur",
      "CityValue": "Mubarakpur"
    },
    {
      "id": 7312,
      "CityName": "Irba",
      "CityValue": "Irba"
    },
    {
      "id": 7313,
      "CityName": "Ganye Gangadharpur",
      "CityValue": "Ganye Gangadharpur"
    },
    {
      "id": 7314,
      "CityName": "Purba Ranaghat",
      "CityValue": "Purba Ranaghat"
    },
    {
      "id": 7315,
      "CityName": "Halalpur Krishnapur",
      "CityValue": "Halalpur Krishnapur"
    },
    {
      "id": 7316,
      "CityName": "Gora Bazar",
      "CityValue": "Gora Bazar"
    },
    {
      "id": 7317,
      "CityName": "Mohiuddinpur",
      "CityValue": "Mohiuddinpur"
    },
    {
      "id": 7318,
      "CityName": "Mohana",
      "CityValue": "Mohana"
    },
    {
      "id": 7319,
      "CityName": "Amgaon kh.",
      "CityValue": "Amgaon kh."
    },
    {
      "id": 7320,
      "CityName": "Mahuda",
      "CityValue": "Mahuda"
    },
    {
      "id": 7321,
      "CityName": "Shyamdhan",
      "CityValue": "Shyamdhan"
    },
    {
      "id": 7322,
      "CityName": "Palhni",
      "CityValue": "Palhni"
    },
    {
      "id": 7323,
      "CityName": "Uttar Krishnapur Pt III",
      "CityValue": "Uttar Krishnapur Pt III"
    },
    {
      "id": 7324,
      "CityName": "Benjanhari Acharial",
      "CityValue": "Benjanhari Acharial"
    },
    {
      "id": 7325,
      "CityName": "Ekabbarpur",
      "CityValue": "Ekabbarpur"
    },
    {
      "id": 7326,
      "CityName": "Bara",
      "CityValue": "Bara"
    },
    {
      "id": 7327,
      "CityName": "Niz-Bahjani",
      "CityValue": "Niz-Bahjani"
    },
    {
      "id": 7328,
      "CityName": "Ghanpur",
      "CityValue": "Ghanpur"
    },
    {
      "id": 7329,
      "CityName": "Garhi",
      "CityValue": "Garhi"
    },
    {
      "id": 7330,
      "CityName": "Dakshin Baguan",
      "CityValue": "Dakshin Baguan"
    },
    {
      "id": 7331,
      "CityName": "Jaykrishnapur",
      "CityValue": "Jaykrishnapur"
    },
    {
      "id": 7332,
      "CityName": "Hosabettu",
      "CityValue": "Hosabettu"
    },
    {
      "id": 7333,
      "CityName": "Mangampeta",
      "CityValue": "Mangampeta"
    },
    {
      "id": 7334,
      "CityName": "Mettamalai",
      "CityValue": "Mettamalai"
    },
    {
      "id": 7335,
      "CityName": "Bara",
      "CityValue": "Bara"
    },
    {
      "id": 7336,
      "CityName": "Bhim Kanari",
      "CityValue": "Bhim Kanari"
    },
    {
      "id": 7337,
      "CityName": "Khatyari",
      "CityValue": "Khatyari"
    },
    {
      "id": 7338,
      "CityName": "Amlagora",
      "CityValue": "Amlagora"
    },
    {
      "id": 7339,
      "CityName": "Berla",
      "CityValue": "Berla"
    },
    {
      "id": 7340,
      "CityName": "Chinnakalayamputhur",
      "CityValue": "Chinnakalayamputhur"
    },
    {
      "id": 7341,
      "CityName": "Chikhaldara",
      "CityValue": "Chikhaldara"
    },
    {
      "id": 7342,
      "CityName": "Samangaon",
      "CityValue": "Samangaon"
    },
    {
      "id": 7343,
      "CityName": "Barda",
      "CityValue": "Barda"
    },
    {
      "id": 7344,
      "CityName": "Rongmook Ceder Tea Garden",
      "CityValue": "Rongmook Ceder Tea Garden"
    },
    {
      "id": 7345,
      "CityName": "Dhanyakuria",
      "CityValue": "Dhanyakuria"
    },
    {
      "id": 7346,
      "CityName": "Kapashanria",
      "CityValue": "Kapashanria"
    },
    {
      "id": 7347,
      "CityName": "Kilmanavur",
      "CityValue": "Kilmanavur"
    },
    {
      "id": 7348,
      "CityName": "Bhainsa",
      "CityValue": "Bhainsa"
    },
    {
      "id": 7349,
      "CityName": "Parmanandpur",
      "CityValue": "Parmanandpur"
    },
    {
      "id": 7350,
      "CityName": "Hirmi",
      "CityValue": "Hirmi"
    },
    {
      "id": 7351,
      "CityName": "Badakodanda",
      "CityValue": "Badakodanda"
    },
    {
      "id": 7352,
      "CityName": "Kasipet",
      "CityValue": "Kasipet"
    },
    {
      "id": 7353,
      "CityName": "Sugnu",
      "CityValue": "Sugnu"
    },
    {
      "id": 7354,
      "CityName": "Frisal",
      "CityValue": "Frisal"
    },
    {
      "id": 7355,
      "CityName": "Kunustara",
      "CityValue": "Kunustara"
    },
    {
      "id": 7356,
      "CityName": "Deori",
      "CityValue": "Deori"
    },
    {
      "id": 7357,
      "CityName": "Talwara",
      "CityValue": "Talwara"
    },
    {
      "id": 7358,
      "CityName": "Lahartara",
      "CityValue": "Lahartara"
    },
    {
      "id": 7359,
      "CityName": "Muradgram Pur Pursi",
      "CityValue": "Muradgram Pur Pursi"
    },
    {
      "id": 7360,
      "CityName": "Kuthankuzhi",
      "CityValue": "Kuthankuzhi"
    },
    {
      "id": 7361,
      "CityName": "Chakrata",
      "CityValue": "Chakrata"
    },
    {
      "id": 7362,
      "CityName": "Mirjapur",
      "CityValue": "Mirjapur"
    },
    {
      "id": 7363,
      "CityName": "Baktarnagar",
      "CityValue": "Baktarnagar"
    },
    {
      "id": 7364,
      "CityName": "Kalaigaon Town Part",
      "CityValue": "Kalaigaon Town Part"
    },
    {
      "id": 7365,
      "CityName": "Badlya",
      "CityValue": "Badlya"
    },
    {
      "id": 7366,
      "CityName": "Khasjalalsi",
      "CityValue": "Khasjalalsi"
    },
    {
      "id": 7367,
      "CityName": "Termi",
      "CityValue": "Termi"
    },
    {
      "id": 7368,
      "CityName": "Garbeta",
      "CityValue": "Garbeta"
    },
    {
      "id": 7369,
      "CityName": "Banjarepur",
      "CityValue": "Banjarepur"
    },
    {
      "id": 7370,
      "CityName": "Jangal",
      "CityValue": "Jangal"
    },
    {
      "id": 7371,
      "CityName": "Chandrapur Baghicha",
      "CityValue": "Chandrapur Baghicha"
    },
    {
      "id": 7372,
      "CityName": "Sanvordem",
      "CityValue": "Sanvordem"
    },
    {
      "id": 7373,
      "CityName": "Jashipur",
      "CityValue": "Jashipur"
    },
    {
      "id": 7374,
      "CityName": "Rawan",
      "CityValue": "Rawan"
    },
    {
      "id": 7375,
      "CityName": "Kandri",
      "CityValue": "Kandri"
    },
    {
      "id": 7376,
      "CityName": "Charingia Gaon",
      "CityValue": "Charingia Gaon"
    },
    {
      "id": 7377,
      "CityName": "Saltor",
      "CityValue": "Saltor"
    },
    {
      "id": 7378,
      "CityName": "Lido Tikok",
      "CityValue": "Lido Tikok"
    },
    {
      "id": 7379,
      "CityName": "Salipur",
      "CityValue": "Salipur"
    },
    {
      "id": 7380,
      "CityName": "Mosli Pt I",
      "CityValue": "Mosli Pt I"
    },
    {
      "id": 7381,
      "CityName": "Peren",
      "CityValue": "Peren"
    },
    {
      "id": 7382,
      "CityName": "Pipariya",
      "CityValue": "Pipariya"
    },
    {
      "id": 7383,
      "CityName": "Dudhpatil Pt VI",
      "CityValue": "Dudhpatil Pt VI"
    },
    {
      "id": 7384,
      "CityName": "Tharial",
      "CityValue": "Tharial"
    },
    {
      "id": 7385,
      "CityName": "Majgaon",
      "CityValue": "Majgaon"
    },
    {
      "id": 7386,
      "CityName": "Taping",
      "CityValue": "Taping"
    },
    {
      "id": 7387,
      "CityName": "Nallipalayam",
      "CityValue": "Nallipalayam"
    },
    {
      "id": 7388,
      "CityName": "Arkula",
      "CityValue": "Arkula"
    },
    {
      "id": 7389,
      "CityName": "Arempudi",
      "CityValue": "Arempudi"
    },
    {
      "id": 7390,
      "CityName": "Subalaya",
      "CityValue": "Subalaya"
    },
    {
      "id": 7391,
      "CityName": "Budhpura",
      "CityValue": "Budhpura"
    },
    {
      "id": 7392,
      "CityName": "Manethi",
      "CityValue": "Manethi"
    },
    {
      "id": 7393,
      "CityName": "Vellaravalli",
      "CityValue": "Vellaravalli"
    },
    {
      "id": 7394,
      "CityName": "Balarambati",
      "CityValue": "Balarambati"
    },
    {
      "id": 7395,
      "CityName": "Dhatau",
      "CityValue": "Dhatau"
    },
    {
      "id": 7396,
      "CityName": "Mahmudpur Taluka Madpur Sult",
      "CityValue": "Mahmudpur Taluka Madpur Sult"
    },
    {
      "id": 7397,
      "CityName": "Sekmai Bazar",
      "CityValue": "Sekmai Bazar"
    },
    {
      "id": 7398,
      "CityName": "Ledisol",
      "CityValue": "Ledisol"
    },
    {
      "id": 7399,
      "CityName": "Char Brahmanagar",
      "CityValue": "Char Brahmanagar"
    },
    {
      "id": 7400,
      "CityName": "Badhagachhi",
      "CityValue": "Badhagachhi"
    },
    {
      "id": 7401,
      "CityName": "Lahsari",
      "CityValue": "Lahsari"
    },
    {
      "id": 7402,
      "CityName": "Sinhasa",
      "CityValue": "Sinhasa"
    },
    {
      "id": 7403,
      "CityName": "Chandrapur",
      "CityValue": "Chandrapur"
    },
    {
      "id": 7404,
      "CityName": "Khothran",
      "CityValue": "Khothran"
    },
    {
      "id": 7405,
      "CityName": "Kalparapatti",
      "CityValue": "Kalparapatti"
    },
    {
      "id": 7406,
      "CityName": "Srikalikapuram",
      "CityValue": "Srikalikapuram"
    },
    {
      "id": 7407,
      "CityName": "Nerul",
      "CityValue": "Nerul"
    },
    {
      "id": 7408,
      "CityName": "Manjakollai",
      "CityValue": "Manjakollai"
    },
    {
      "id": 7409,
      "CityName": "Dungarpur",
      "CityValue": "Dungarpur"
    },
    {
      "id": 7410,
      "CityName": "Kurukkupatti",
      "CityValue": "Kurukkupatti"
    },
    {
      "id": 7411,
      "CityName": "Guirim",
      "CityValue": "Guirim"
    },
    {
      "id": 7412,
      "CityName": "Bina Rly Colony",
      "CityValue": "Bina Rly Colony"
    },
    {
      "id": 7413,
      "CityName": "Ambepur",
      "CityValue": "Ambepur"
    },
    {
      "id": 7414,
      "CityName": "Vithal Udyognagar",
      "CityValue": "Vithal Udyognagar"
    },
    {
      "id": 7415,
      "CityName": "Naka Majiari",
      "CityValue": "Naka Majiari"
    },
    {
      "id": 7416,
      "CityName": "Bishnupur",
      "CityValue": "Bishnupur"
    },
    {
      "id": 7417,
      "CityName": "Topa",
      "CityValue": "Topa"
    },
    {
      "id": 7418,
      "CityName": "Kaliapani",
      "CityValue": "Kaliapani"
    },
    {
      "id": 7419,
      "CityName": "Chenbagaramanputhur",
      "CityValue": "Chenbagaramanputhur"
    },
    {
      "id": 7420,
      "CityName": "Simurali",
      "CityValue": "Simurali"
    },
    {
      "id": 7421,
      "CityName": "Gimhavane",
      "CityValue": "Gimhavane"
    },
    {
      "id": 7422,
      "CityName": "Koil palayam",
      "CityValue": "Koil palayam"
    },
    {
      "id": 7423,
      "CityName": "Kanwla",
      "CityValue": "Kanwla"
    },
    {
      "id": 7424,
      "CityName": "Jhangra",
      "CityValue": "Jhangra"
    },
    {
      "id": 7425,
      "CityName": "Pernem",
      "CityValue": "Pernem"
    },
    {
      "id": 7426,
      "CityName": "Harirampur",
      "CityValue": "Harirampur"
    },
    {
      "id": 7427,
      "CityName": "Baisguri",
      "CityValue": "Baisguri"
    },
    {
      "id": 7428,
      "CityName": "Palurgada",
      "CityValue": "Palurgada"
    },
    {
      "id": 7429,
      "CityName": "Moodabettu",
      "CityValue": "Moodabettu"
    },
    {
      "id": 7430,
      "CityName": "Yenagudde",
      "CityValue": "Yenagudde"
    },
    {
      "id": 7431,
      "CityName": "Naubaisa Goan",
      "CityValue": "Naubaisa Goan"
    },
    {
      "id": 7432,
      "CityName": "Char Maijdia",
      "CityValue": "Char Maijdia"
    },
    {
      "id": 7433,
      "CityName": "Dadhapatna",
      "CityValue": "Dadhapatna"
    },
    {
      "id": 7434,
      "CityName": "Oadipur",
      "CityValue": "Oadipur"
    },
    {
      "id": 7435,
      "CityName": "Manipura",
      "CityValue": "Manipura"
    },
    {
      "id": 7436,
      "CityName": "Bowluvada",
      "CityValue": "Bowluvada"
    },
    {
      "id": 7437,
      "CityName": "SuPallipattu",
      "CityValue": "SuPallipattu"
    },
    {
      "id": 7438,
      "CityName": "Dakshin Odlabari",
      "CityValue": "Dakshin Odlabari"
    },
    {
      "id": 7439,
      "CityName": "Chhekati",
      "CityValue": "Chhekati"
    },
    {
      "id": 7440,
      "CityName": "Nava Bhildi",
      "CityValue": "Nava Bhildi"
    },
    {
      "id": 7441,
      "CityName": "Patdaha",
      "CityValue": "Patdaha"
    },
    {
      "id": 7442,
      "CityName": "Koppa",
      "CityValue": "Koppa"
    },
    {
      "id": 7443,
      "CityName": "Soolakkarai",
      "CityValue": "Soolakkarai"
    },
    {
      "id": 7444,
      "CityName": "Harinadibhastsala",
      "CityValue": "Harinadibhastsala"
    },
    {
      "id": 7445,
      "CityName": "Makundapur",
      "CityValue": "Makundapur"
    },
    {
      "id": 7446,
      "CityName": "Lataguri",
      "CityValue": "Lataguri"
    },
    {
      "id": 7447,
      "CityName": "Rupiabathan",
      "CityValue": "Rupiabathan"
    },
    {
      "id": 7448,
      "CityName": "Muringur Vadakkummuri",
      "CityValue": "Muringur Vadakkummuri"
    },
    {
      "id": 7449,
      "CityName": "Harsul",
      "CityValue": "Harsul"
    },
    {
      "id": 7450,
      "CityName": "Billawar",
      "CityValue": "Billawar"
    },
    {
      "id": 7451,
      "CityName": "Chalsa Mahabari",
      "CityValue": "Chalsa Mahabari"
    },
    {
      "id": 7452,
      "CityName": "Sarauni",
      "CityValue": "Sarauni"
    },
    {
      "id": 7453,
      "CityName": "Mercurim",
      "CityValue": "Mercurim"
    },
    {
      "id": 7454,
      "CityName": "Satakha Hq.",
      "CityValue": "Satakha Hq."
    },
    {
      "id": 7455,
      "CityName": "Sagolband",
      "CityValue": "Sagolband"
    },
    {
      "id": 7456,
      "CityName": "Devasthanam",
      "CityValue": "Devasthanam"
    },
    {
      "id": 7457,
      "CityName": "Hincha Gerya",
      "CityValue": "Hincha Gerya"
    },
    {
      "id": 7458,
      "CityName": "Mullipattu",
      "CityValue": "Mullipattu"
    },
    {
      "id": 7459,
      "CityName": "Premnagar",
      "CityValue": "Premnagar"
    },
    {
      "id": 7460,
      "CityName": "Urimari",
      "CityValue": "Urimari"
    },
    {
      "id": 7461,
      "CityName": "Dhopatala",
      "CityValue": "Dhopatala"
    },
    {
      "id": 7462,
      "CityName": "Korangrapady",
      "CityValue": "Korangrapady"
    },
    {
      "id": 7463,
      "CityName": "Bir Ghaghar",
      "CityValue": "Bir Ghaghar"
    },
    {
      "id": 7464,
      "CityName": "Alaudia",
      "CityValue": "Alaudia"
    },
    {
      "id": 7465,
      "CityName": "Tadali",
      "CityValue": "Tadali"
    },
    {
      "id": 7466,
      "CityName": "Vadi",
      "CityValue": "Vadi"
    },
    {
      "id": 7467,
      "CityName": "Ram Garh",
      "CityValue": "Ram Garh"
    },
    {
      "id": 7468,
      "CityName": "Thenthiruperai",
      "CityValue": "Thenthiruperai"
    },
    {
      "id": 7469,
      "CityName": "Mukhmel Pur",
      "CityValue": "Mukhmel Pur"
    },
    {
      "id": 7470,
      "CityName": "Alangulam",
      "CityValue": "Alangulam"
    },
    {
      "id": 7471,
      "CityName": "Tarsali",
      "CityValue": "Tarsali"
    },
    {
      "id": 7472,
      "CityName": "Kataka",
      "CityValue": "Kataka"
    },
    {
      "id": 7473,
      "CityName": "Palasbari",
      "CityValue": "Palasbari"
    },
    {
      "id": 7474,
      "CityName": "Damara Patpara",
      "CityValue": "Damara Patpara"
    },
    {
      "id": 7475,
      "CityName": "Paria",
      "CityValue": "Paria"
    },
    {
      "id": 7476,
      "CityName": "Bahalimpura",
      "CityValue": "Bahalimpura"
    },
    {
      "id": 7477,
      "CityName": "Mohanpur",
      "CityValue": "Mohanpur"
    },
    {
      "id": 7478,
      "CityName": "Yehiapur",
      "CityValue": "Yehiapur"
    },
    {
      "id": 7479,
      "CityName": "Cumbarjua",
      "CityValue": "Cumbarjua"
    },
    {
      "id": 7480,
      "CityName": "Gokul",
      "CityValue": "Gokul"
    },
    {
      "id": 7481,
      "CityName": "Bairatisal",
      "CityValue": "Bairatisal"
    },
    {
      "id": 7482,
      "CityName": "Periagaram",
      "CityValue": "Periagaram"
    },
    {
      "id": 7483,
      "CityName": "Chak Baria",
      "CityValue": "Chak Baria"
    },
    {
      "id": 7484,
      "CityName": "Tummikapalle",
      "CityValue": "Tummikapalle"
    },
    {
      "id": 7485,
      "CityName": "Chingangbam Leikai",
      "CityValue": "Chingangbam Leikai"
    },
    {
      "id": 7486,
      "CityName": "Hale-Dharmapuri",
      "CityValue": "Hale-Dharmapuri"
    },
    {
      "id": 7487,
      "CityName": "Ghanauli",
      "CityValue": "Ghanauli"
    },
    {
      "id": 7488,
      "CityName": "Bhisiana",
      "CityValue": "Bhisiana"
    },
    {
      "id": 7489,
      "CityName": "Mancad",
      "CityValue": "Mancad"
    },
    {
      "id": 7490,
      "CityName": "Dhande Kalan",
      "CityValue": "Dhande Kalan"
    },
    {
      "id": 7491,
      "CityName": "Majra",
      "CityValue": "Majra"
    },
    {
      "id": 7492,
      "CityName": "Highways",
      "CityValue": "Highways"
    },
    {
      "id": 7493,
      "CityName": "Tisa",
      "CityValue": "Tisa"
    },
    {
      "id": 7494,
      "CityName": "Awalpur",
      "CityValue": "Awalpur"
    },
    {
      "id": 7495,
      "CityName": "Chak Babura Alimabad",
      "CityValue": "Chak Babura Alimabad"
    },
    {
      "id": 7496,
      "CityName": "Bargachhia",
      "CityValue": "Bargachhia"
    },
    {
      "id": 7497,
      "CityName": "Nadgaon Tarf Birwadi",
      "CityValue": "Nadgaon Tarf Birwadi"
    },
    {
      "id": 7498,
      "CityName": "Kurul",
      "CityValue": "Kurul"
    },
    {
      "id": 7499,
      "CityName": "Bhatli",
      "CityValue": "Bhatli"
    },
    {
      "id": 7500,
      "CityName": "Salakati",
      "CityValue": "Salakati"
    },
    {
      "id": 7501,
      "CityName": "Pipariya",
      "CityValue": "Pipariya"
    },
    {
      "id": 7502,
      "CityName": "Ujwa",
      "CityValue": "Ujwa"
    },
    {
      "id": 7503,
      "CityName": "Kundam",
      "CityValue": "Kundam"
    },
    {
      "id": 7504,
      "CityName": "Borigam",
      "CityValue": "Borigam"
    },
    {
      "id": 7505,
      "CityName": "R. Udayagiri",
      "CityValue": "R. Udayagiri"
    },
    {
      "id": 7506,
      "CityName": "Dariapur",
      "CityValue": "Dariapur"
    },
    {
      "id": 7507,
      "CityName": "Arjunda",
      "CityValue": "Arjunda"
    },
    {
      "id": 7508,
      "CityName": "Goaljan",
      "CityValue": "Goaljan"
    },
    {
      "id": 7509,
      "CityName": "Poolankinar",
      "CityValue": "Poolankinar"
    },
    {
      "id": 7510,
      "CityName": "Bishnugarh",
      "CityValue": "Bishnugarh"
    },
    {
      "id": 7511,
      "CityName": "Nongkseh",
      "CityValue": "Nongkseh"
    },
    {
      "id": 7512,
      "CityName": "Mohanpur",
      "CityValue": "Mohanpur"
    },
    {
      "id": 7513,
      "CityName": "Maricha",
      "CityValue": "Maricha"
    },
    {
      "id": 7514,
      "CityName": "Mahal",
      "CityValue": "Mahal"
    },
    {
      "id": 7515,
      "CityName": "Baneswar",
      "CityValue": "Baneswar"
    },
    {
      "id": 7516,
      "CityName": "Chandil",
      "CityValue": "Chandil"
    },
    {
      "id": 7517,
      "CityName": "Rajpur",
      "CityValue": "Rajpur"
    },
    {
      "id": 7518,
      "CityName": "Chakmano Urf Dargah",
      "CityValue": "Chakmano Urf Dargah"
    },
    {
      "id": 7519,
      "CityName": "Panchmahali",
      "CityValue": "Panchmahali"
    },
    {
      "id": 7520,
      "CityName": "Kalachhari",
      "CityValue": "Kalachhari"
    },
    {
      "id": 7521,
      "CityName": "Sarai Mohana",
      "CityValue": "Sarai Mohana"
    },
    {
      "id": 7522,
      "CityName": "Tushura",
      "CityValue": "Tushura"
    },
    {
      "id": 7523,
      "CityName": "Bhamal",
      "CityValue": "Bhamal"
    },
    {
      "id": 7524,
      "CityName": "Colvale",
      "CityValue": "Colvale"
    },
    {
      "id": 7525,
      "CityName": "Dera Gopipur",
      "CityValue": "Dera Gopipur"
    },
    {
      "id": 7526,
      "CityName": "Sarai Abdulmalik",
      "CityValue": "Sarai Abdulmalik"
    },
    {
      "id": 7527,
      "CityName": "Makranifali",
      "CityValue": "Makranifali"
    },
    {
      "id": 7528,
      "CityName": "Palashban",
      "CityValue": "Palashban"
    },
    {
      "id": 7529,
      "CityName": "Garia",
      "CityValue": "Garia"
    },
    {
      "id": 7530,
      "CityName": "Thirumalpur",
      "CityValue": "Thirumalpur"
    },
    {
      "id": 7531,
      "CityName": "Champawat",
      "CityValue": "Champawat"
    },
    {
      "id": 7532,
      "CityName": "Sadatmasaura",
      "CityValue": "Sadatmasaura"
    },
    {
      "id": 7533,
      "CityName": "Kannur Cantonment",
      "CityValue": "Kannur Cantonment"
    },
    {
      "id": 7534,
      "CityName": "Ichhapur",
      "CityValue": "Ichhapur"
    },
    {
      "id": 7535,
      "CityName": "Paradipgarh",
      "CityValue": "Paradipgarh"
    },
    {
      "id": 7536,
      "CityName": "Badra",
      "CityValue": "Badra"
    },
    {
      "id": 7537,
      "CityName": "Kanaipur",
      "CityValue": "Kanaipur"
    },
    {
      "id": 7538,
      "CityName": "Kasinayagampatti",
      "CityValue": "Kasinayagampatti"
    },
    {
      "id": 7539,
      "CityName": "52 Heroor",
      "CityValue": "52 Heroor"
    },
    {
      "id": 7540,
      "CityName": "Majir Gaon",
      "CityValue": "Majir Gaon"
    },
    {
      "id": 7541,
      "CityName": "Kalipur",
      "CityValue": "Kalipur"
    },
    {
      "id": 7542,
      "CityName": "Malanka",
      "CityValue": "Malanka"
    },
    {
      "id": 7543,
      "CityName": "Emri",
      "CityValue": "Emri"
    },
    {
      "id": 7544,
      "CityName": "Chhatha",
      "CityValue": "Chhatha"
    },
    {
      "id": 7545,
      "CityName": "Pindrai",
      "CityValue": "Pindrai"
    },
    {
      "id": 7546,
      "CityName": "Urjanagar",
      "CityValue": "Urjanagar"
    },
    {
      "id": 7547,
      "CityName": "Pusaur",
      "CityValue": "Pusaur"
    },
    {
      "id": 7548,
      "CityName": "Chandrapur",
      "CityValue": "Chandrapur"
    },
    {
      "id": 7549,
      "CityName": "Baneshwarpur",
      "CityValue": "Baneshwarpur"
    },
    {
      "id": 7550,
      "CityName": "Karia",
      "CityValue": "Karia"
    },
    {
      "id": 7551,
      "CityName": "Berhait Bazar",
      "CityValue": "Berhait Bazar"
    },
    {
      "id": 7552,
      "CityName": "Awadhan",
      "CityValue": "Awadhan"
    },
    {
      "id": 7553,
      "CityName": "Majarkuri",
      "CityValue": "Majarkuri"
    },
    {
      "id": 7554,
      "CityName": "Majaragollappatti",
      "CityValue": "Majaragollappatti"
    },
    {
      "id": 7555,
      "CityName": "Chak Kalu",
      "CityValue": "Chak Kalu"
    },
    {
      "id": 7556,
      "CityName": "Kotla Nihang",
      "CityValue": "Kotla Nihang"
    },
    {
      "id": 7557,
      "CityName": "Sahebganj",
      "CityValue": "Sahebganj"
    },
    {
      "id": 7558,
      "CityName": "Sarkaghat",
      "CityValue": "Sarkaghat"
    },
    {
      "id": 7559,
      "CityName": "Dhekorgorha",
      "CityValue": "Dhekorgorha"
    },
    {
      "id": 7560,
      "CityName": "Birapratappur",
      "CityValue": "Birapratappur"
    },
    {
      "id": 7561,
      "CityName": "Suranga",
      "CityValue": "Suranga"
    },
    {
      "id": 7562,
      "CityName": "Amathur",
      "CityValue": "Amathur"
    },
    {
      "id": 7563,
      "CityName": "Galiakot",
      "CityValue": "Galiakot"
    },
    {
      "id": 7564,
      "CityName": "Batul",
      "CityValue": "Batul"
    },
    {
      "id": 7565,
      "CityName": "Kannapalayam",
      "CityValue": "Kannapalayam"
    },
    {
      "id": 7566,
      "CityName": "Uttar Bishnupur",
      "CityValue": "Uttar Bishnupur"
    },
    {
      "id": 7567,
      "CityName": "Pairagachha",
      "CityValue": "Pairagachha"
    },
    {
      "id": 7568,
      "CityName": "Patharberia",
      "CityValue": "Patharberia"
    },
    {
      "id": 7569,
      "CityName": "Kariyangala",
      "CityValue": "Kariyangala"
    },
    {
      "id": 7570,
      "CityName": "Wadi Ratnagiri",
      "CityValue": "Wadi Ratnagiri"
    },
    {
      "id": 7571,
      "CityName": "Lalpur",
      "CityValue": "Lalpur"
    },
    {
      "id": 7572,
      "CityName": "Gopinathpur",
      "CityValue": "Gopinathpur"
    },
    {
      "id": 7573,
      "CityName": "Maniyat",
      "CityValue": "Maniyat"
    },
    {
      "id": 7574,
      "CityName": "Medha",
      "CityValue": "Medha"
    },
    {
      "id": 7575,
      "CityName": "Jonk",
      "CityValue": "Jonk"
    },
    {
      "id": 7576,
      "CityName": "Mahura",
      "CityValue": "Mahura"
    },
    {
      "id": 7577,
      "CityName": "Rayagada",
      "CityValue": "Rayagada"
    },
    {
      "id": 7578,
      "CityName": "Kalyanasingpur",
      "CityValue": "Kalyanasingpur"
    },
    {
      "id": 7579,
      "CityName": "Durganagar",
      "CityValue": "Durganagar"
    },
    {
      "id": 7580,
      "CityName": "Sansarpur",
      "CityValue": "Sansarpur"
    },
    {
      "id": 7581,
      "CityName": "Indipur",
      "CityValue": "Indipur"
    },
    {
      "id": 7582,
      "CityName": "Jhakhri",
      "CityValue": "Jhakhri"
    },
    {
      "id": 7583,
      "CityName": "Haralapura",
      "CityValue": "Haralapura"
    },
    {
      "id": 7584,
      "CityName": "Gaura Kala",
      "CityValue": "Gaura Kala"
    },
    {
      "id": 7585,
      "CityName": "Barkalikapur",
      "CityValue": "Barkalikapur"
    },
    {
      "id": 7586,
      "CityName": "Aralgoria",
      "CityValue": "Aralgoria"
    },
    {
      "id": 7587,
      "CityName": "Malikpur",
      "CityValue": "Malikpur"
    },
    {
      "id": 7588,
      "CityName": "Udiana",
      "CityValue": "Udiana"
    },
    {
      "id": 7589,
      "CityName": "Mangan",
      "CityValue": "Mangan"
    },
    {
      "id": 7590,
      "CityName": "Chak Imam Ali",
      "CityValue": "Chak Imam Ali"
    },
    {
      "id": 7591,
      "CityName": "Tupkhana Pt I",
      "CityValue": "Tupkhana Pt I"
    },
    {
      "id": 7592,
      "CityName": "Marma",
      "CityValue": "Marma"
    },
    {
      "id": 7593,
      "CityName": "Harina",
      "CityValue": "Harina"
    },
    {
      "id": 7594,
      "CityName": "Amlabad",
      "CityValue": "Amlabad"
    },
    {
      "id": 7595,
      "CityName": "Kizhakkummuri",
      "CityValue": "Kizhakkummuri"
    },
    {
      "id": 7596,
      "CityName": "Kanchal Gosain",
      "CityValue": "Kanchal Gosain"
    },
    {
      "id": 7597,
      "CityName": "Upper Sileru Project Site Camp",
      "CityValue": "Upper Sileru Project Site Camp"
    },
    {
      "id": 7598,
      "CityName": "Bidyadharpur",
      "CityValue": "Bidyadharpur"
    },
    {
      "id": 7599,
      "CityName": "Gorah Salathian",
      "CityValue": "Gorah Salathian"
    },
    {
      "id": 7600,
      "CityName": "Parcem",
      "CityValue": "Parcem"
    },
    {
      "id": 7601,
      "CityName": "Nabgram",
      "CityValue": "Nabgram"
    },
    {
      "id": 7602,
      "CityName": "Radhapur",
      "CityValue": "Radhapur"
    },
    {
      "id": 7603,
      "CityName": "Abhirampur",
      "CityValue": "Abhirampur"
    },
    {
      "id": 7604,
      "CityName": "Alikhoja",
      "CityValue": "Alikhoja"
    },
    {
      "id": 7605,
      "CityName": "Semari",
      "CityValue": "Semari"
    },
    {
      "id": 7606,
      "CityName": "Ghoraberia",
      "CityValue": "Ghoraberia"
    },
    {
      "id": 7607,
      "CityName": "Kanheipur",
      "CityValue": "Kanheipur"
    },
    {
      "id": 7608,
      "CityName": "Pure Tiwari",
      "CityValue": "Pure Tiwari"
    },
    {
      "id": 7609,
      "CityName": "Alagdiha",
      "CityValue": "Alagdiha"
    },
    {
      "id": 7610,
      "CityName": "Danguwapasi",
      "CityValue": "Danguwapasi"
    },
    {
      "id": 7611,
      "CityName": "Bhandra",
      "CityValue": "Bhandra"
    },
    {
      "id": 7612,
      "CityName": "Utarlai",
      "CityValue": "Utarlai"
    },
    {
      "id": 7613,
      "CityName": "Orgao",
      "CityValue": "Orgao"
    },
    {
      "id": 7614,
      "CityName": "Lamlai",
      "CityValue": "Lamlai"
    },
    {
      "id": 7615,
      "CityName": "Sirsha",
      "CityValue": "Sirsha"
    },
    {
      "id": 7616,
      "CityName": "Tihu",
      "CityValue": "Tihu"
    },
    {
      "id": 7617,
      "CityName": "Desur",
      "CityValue": "Desur"
    },
    {
      "id": 7618,
      "CityName": "Chong Ghurali",
      "CityValue": "Chong Ghurali"
    },
    {
      "id": 7619,
      "CityName": "Mandarbani",
      "CityValue": "Mandarbani"
    },
    {
      "id": 7620,
      "CityName": "Kaliawadi",
      "CityValue": "Kaliawadi"
    },
    {
      "id": 7621,
      "CityName": "Khilchian",
      "CityValue": "Khilchian"
    },
    {
      "id": 7622,
      "CityName": "Thangalur",
      "CityValue": "Thangalur"
    },
    {
      "id": 7623,
      "CityName": "Lairikyengbam Leikai",
      "CityValue": "Lairikyengbam Leikai"
    },
    {
      "id": 7624,
      "CityName": "Kamranga",
      "CityValue": "Kamranga"
    },
    {
      "id": 7625,
      "CityName": "Air Force Area",
      "CityValue": "Air Force Area"
    },
    {
      "id": 7626,
      "CityName": "Jaluidanga",
      "CityValue": "Jaluidanga"
    },
    {
      "id": 7627,
      "CityName": "Sansikhara",
      "CityValue": "Sansikhara"
    },
    {
      "id": 7628,
      "CityName": "Anurudhpur Purab Patti",
      "CityValue": "Anurudhpur Purab Patti"
    },
    {
      "id": 7629,
      "CityName": "Bargachhia",
      "CityValue": "Bargachhia"
    },
    {
      "id": 7630,
      "CityName": "Ranipur",
      "CityValue": "Ranipur"
    },
    {
      "id": 7631,
      "CityName": "Budbud",
      "CityValue": "Budbud"
    },
    {
      "id": 7632,
      "CityName": "Tlabung",
      "CityValue": "Tlabung"
    },
    {
      "id": 7633,
      "CityName": "Katkona",
      "CityValue": "Katkona"
    },
    {
      "id": 7634,
      "CityName": "Jhinghipahari",
      "CityValue": "Jhinghipahari"
    },
    {
      "id": 7635,
      "CityName": "Ramgarh",
      "CityValue": "Ramgarh"
    },
    {
      "id": 7636,
      "CityName": "Vatwarlapalle",
      "CityValue": "Vatwarlapalle"
    },
    {
      "id": 7637,
      "CityName": "Indora",
      "CityValue": "Indora"
    },
    {
      "id": 7638,
      "CityName": "Sanpara",
      "CityValue": "Sanpara"
    },
    {
      "id": 7639,
      "CityName": "Pipalibari",
      "CityValue": "Pipalibari"
    },
    {
      "id": 7640,
      "CityName": "Tenu",
      "CityValue": "Tenu"
    },
    {
      "id": 7641,
      "CityName": "Bamna",
      "CityValue": "Bamna"
    },
    {
      "id": 7642,
      "CityName": "Mulakuddu",
      "CityValue": "Mulakuddu"
    },
    {
      "id": 7643,
      "CityName": "Kaipadar",
      "CityValue": "Kaipadar"
    },
    {
      "id": 7644,
      "CityName": "Sonda",
      "CityValue": "Sonda"
    },
    {
      "id": 7645,
      "CityName": "Narharpur",
      "CityValue": "Narharpur"
    },
    {
      "id": 7646,
      "CityName": "Chhuriya",
      "CityValue": "Chhuriya"
    },
    {
      "id": 7647,
      "CityName": "Ratibati",
      "CityValue": "Ratibati"
    },
    {
      "id": 7648,
      "CityName": "Ramalingapuram",
      "CityValue": "Ramalingapuram"
    },
    {
      "id": 7649,
      "CityName": "Telkap",
      "CityValue": "Telkap"
    },
    {
      "id": 7650,
      "CityName": "Nainana-Brahman",
      "CityValue": "Nainana-Brahman"
    },
    {
      "id": 7651,
      "CityName": "Sagbara",
      "CityValue": "Sagbara"
    },
    {
      "id": 7652,
      "CityName": "Bishrampur",
      "CityValue": "Bishrampur"
    },
    {
      "id": 7653,
      "CityName": "Nagar Changrabandha",
      "CityValue": "Nagar Changrabandha"
    },
    {
      "id": 7654,
      "CityName": "Saloh",
      "CityValue": "Saloh"
    },
    {
      "id": 7655,
      "CityName": "Prayagpur",
      "CityValue": "Prayagpur"
    },
    {
      "id": 7656,
      "CityName": "Samanatham",
      "CityValue": "Samanatham"
    },
    {
      "id": 7657,
      "CityName": "Haralahalli",
      "CityValue": "Haralahalli"
    },
    {
      "id": 7658,
      "CityName": "Bhuntar",
      "CityValue": "Bhuntar"
    },
    {
      "id": 7659,
      "CityName": "Gondalpara",
      "CityValue": "Gondalpara"
    },
    {
      "id": 7660,
      "CityName": "Tangi",
      "CityValue": "Tangi"
    },
    {
      "id": 7661,
      "CityName": "Tensa",
      "CityValue": "Tensa"
    },
    {
      "id": 7662,
      "CityName": "Belebathan",
      "CityValue": "Belebathan"
    },
    {
      "id": 7663,
      "CityName": "Jonai Bazar",
      "CityValue": "Jonai Bazar"
    },
    {
      "id": 7664,
      "CityName": "Shahapur",
      "CityValue": "Shahapur"
    },
    {
      "id": 7665,
      "CityName": "Pitala",
      "CityValue": "Pitala"
    },
    {
      "id": 7666,
      "CityName": "Balrampur",
      "CityValue": "Balrampur"
    },
    {
      "id": 7667,
      "CityName": "Hesla",
      "CityValue": "Hesla"
    },
    {
      "id": 7668,
      "CityName": "Sukhiapokhri",
      "CityValue": "Sukhiapokhri"
    },
    {
      "id": 7669,
      "CityName": "Eravu",
      "CityValue": "Eravu"
    },
    {
      "id": 7670,
      "CityName": "Bhopalpattanam",
      "CityValue": "Bhopalpattanam"
    },
    {
      "id": 7671,
      "CityName": "Aravankad",
      "CityValue": "Aravankad"
    },
    {
      "id": 7672,
      "CityName": "Nadaun",
      "CityValue": "Nadaun"
    },
    {
      "id": 7673,
      "CityName": "Delwara",
      "CityValue": "Delwara"
    },
    {
      "id": 7674,
      "CityName": "Jorada",
      "CityValue": "Jorada"
    },
    {
      "id": 7675,
      "CityName": "Nandkharki",
      "CityValue": "Nandkharki"
    },
    {
      "id": 7676,
      "CityName": "Sham Chaurasi",
      "CityValue": "Sham Chaurasi"
    },
    {
      "id": 7677,
      "CityName": "Poravacheri",
      "CityValue": "Poravacheri"
    },
    {
      "id": 7678,
      "CityName": "Alipur",
      "CityValue": "Alipur"
    },
    {
      "id": 7679,
      "CityName": "Kalpeni",
      "CityValue": "Kalpeni"
    },
    {
      "id": 7680,
      "CityName": "Tsudikong",
      "CityValue": "Tsudikong"
    },
    {
      "id": 7681,
      "CityName": "Ajodhyanagar",
      "CityValue": "Ajodhyanagar"
    },
    {
      "id": 7682,
      "CityName": "Goraiya",
      "CityValue": "Goraiya"
    },
    {
      "id": 7683,
      "CityName": "Sarai Lahur Urf Lahurpur",
      "CityValue": "Sarai Lahur Urf Lahurpur"
    },
    {
      "id": 7684,
      "CityName": "Bahadurpur",
      "CityValue": "Bahadurpur"
    },
    {
      "id": 7685,
      "CityName": "Ratanpura",
      "CityValue": "Ratanpura"
    },
    {
      "id": 7686,
      "CityName": "Kadwad",
      "CityValue": "Kadwad"
    },
    {
      "id": 7687,
      "CityName": "Venkatraipur",
      "CityValue": "Venkatraipur"
    },
    {
      "id": 7688,
      "CityName": "Garal",
      "CityValue": "Garal"
    },
    {
      "id": 7689,
      "CityName": "Matheran",
      "CityValue": "Matheran"
    },
    {
      "id": 7690,
      "CityName": "Marhi",
      "CityValue": "Marhi"
    },
    {
      "id": 7691,
      "CityName": "Govindpur",
      "CityValue": "Govindpur"
    },
    {
      "id": 7692,
      "CityName": "Kannivadi",
      "CityValue": "Kannivadi"
    },
    {
      "id": 7693,
      "CityName": "Thekashu Pt-I",
      "CityValue": "Thekashu Pt-I"
    },
    {
      "id": 7694,
      "CityName": "Dhaliabari",
      "CityValue": "Dhaliabari"
    },
    {
      "id": 7695,
      "CityName": "Karle",
      "CityValue": "Karle"
    },
    {
      "id": 7696,
      "CityName": "Bhangri Pratham Khanda",
      "CityValue": "Bhangri Pratham Khanda"
    },
    {
      "id": 7697,
      "CityName": "Sijhua",
      "CityValue": "Sijhua"
    },
    {
      "id": 7698,
      "CityName": "Srimantapur",
      "CityValue": "Srimantapur"
    },
    {
      "id": 7699,
      "CityName": "Tulshighata",
      "CityValue": "Tulshighata"
    },
    {
      "id": 7700,
      "CityName": "Sabo",
      "CityValue": "Sabo"
    },
    {
      "id": 7701,
      "CityName": "Isasani",
      "CityValue": "Isasani"
    },
    {
      "id": 7702,
      "CityName": "Baluhati",
      "CityValue": "Baluhati"
    },
    {
      "id": 7703,
      "CityName": "Deora",
      "CityValue": "Deora"
    },
    {
      "id": 7704,
      "CityName": "Nowbasata",
      "CityValue": "Nowbasata"
    },
    {
      "id": 7705,
      "CityName": "Theog",
      "CityValue": "Theog"
    },
    {
      "id": 7706,
      "CityName": "Chikhala",
      "CityValue": "Chikhala"
    },
    {
      "id": 7707,
      "CityName": "Topsi",
      "CityValue": "Topsi"
    },
    {
      "id": 7708,
      "CityName": "Maralia",
      "CityValue": "Maralia"
    },
    {
      "id": 7709,
      "CityName": "Goa Velha",
      "CityValue": "Goa Velha"
    },
    {
      "id": 7710,
      "CityName": "Bairabi",
      "CityValue": "Bairabi"
    },
    {
      "id": 7711,
      "CityName": "Sasti",
      "CityValue": "Sasti"
    },
    {
      "id": 7712,
      "CityName": "Vanniyoor",
      "CityValue": "Vanniyoor"
    },
    {
      "id": 7713,
      "CityName": "Madhuban",
      "CityValue": "Madhuban"
    },
    {
      "id": 7714,
      "CityName": "Batote",
      "CityValue": "Batote"
    },
    {
      "id": 7715,
      "CityName": "Nowsolia Gaon",
      "CityValue": "Nowsolia Gaon"
    },
    {
      "id": 7716,
      "CityName": "Chachoki",
      "CityValue": "Chachoki"
    },
    {
      "id": 7717,
      "CityName": "Bundia",
      "CityValue": "Bundia"
    },
    {
      "id": 7718,
      "CityName": "Bishunipur",
      "CityValue": "Bishunipur"
    },
    {
      "id": 7719,
      "CityName": "Moira",
      "CityValue": "Moira"
    },
    {
      "id": 7720,
      "CityName": "Bichhri",
      "CityValue": "Bichhri"
    },
    {
      "id": 7721,
      "CityName": "Madathumpady",
      "CityValue": "Madathumpady"
    },
    {
      "id": 7722,
      "CityName": "Piala",
      "CityValue": "Piala"
    },
    {
      "id": 7723,
      "CityName": "Aversa",
      "CityValue": "Aversa"
    },
    {
      "id": 7724,
      "CityName": "Basar",
      "CityValue": "Basar"
    },
    {
      "id": 7725,
      "CityName": "Naridana",
      "CityValue": "Naridana"
    },
    {
      "id": 7726,
      "CityName": "Owle",
      "CityValue": "Owle"
    },
    {
      "id": 7727,
      "CityName": "Kulad",
      "CityValue": "Kulad"
    },
    {
      "id": 7728,
      "CityName": "Mundamarai",
      "CityValue": "Mundamarai"
    },
    {
      "id": 7729,
      "CityName": "Chawand",
      "CityValue": "Chawand"
    },
    {
      "id": 7730,
      "CityName": "Madaharpakkam",
      "CityValue": "Madaharpakkam"
    },
    {
      "id": 7731,
      "CityName": "Sepon",
      "CityValue": "Sepon"
    },
    {
      "id": 7732,
      "CityName": "Longding",
      "CityValue": "Longding"
    },
    {
      "id": 7733,
      "CityName": "Gotheghar",
      "CityValue": "Gotheghar"
    },
    {
      "id": 7734,
      "CityName": "Matialihat",
      "CityValue": "Matialihat"
    },
    {
      "id": 7735,
      "CityName": "Dhaki",
      "CityValue": "Dhaki"
    },
    {
      "id": 7736,
      "CityName": "Mahira",
      "CityValue": "Mahira"
    },
    {
      "id": 7737,
      "CityName": "Bardubhi",
      "CityValue": "Bardubhi"
    },
    {
      "id": 7738,
      "CityName": "Kadambur",
      "CityValue": "Kadambur"
    },
    {
      "id": 7739,
      "CityName": "Danara",
      "CityValue": "Danara"
    },
    {
      "id": 7740,
      "CityName": "Jua",
      "CityValue": "Jua"
    },
    {
      "id": 7741,
      "CityName": "Sisha-Jumrha",
      "CityValue": "Sisha-Jumrha"
    },
    {
      "id": 7742,
      "CityName": "Dudhpatil Pt V",
      "CityValue": "Dudhpatil Pt V"
    },
    {
      "id": 7743,
      "CityName": "Kharisha",
      "CityValue": "Kharisha"
    },
    {
      "id": 7744,
      "CityName": "Tirumalaigiri",
      "CityValue": "Tirumalaigiri"
    },
    {
      "id": 7745,
      "CityName": "Ging Tea Garden",
      "CityValue": "Ging Tea Garden"
    },
    {
      "id": 7746,
      "CityName": "Manakudi",
      "CityValue": "Manakudi"
    },
    {
      "id": 7747,
      "CityName": "Kurichikkara",
      "CityValue": "Kurichikkara"
    },
    {
      "id": 7748,
      "CityName": "Kabatabandha",
      "CityValue": "Kabatabandha"
    },
    {
      "id": 7749,
      "CityName": "Panara",
      "CityValue": "Panara"
    },
    {
      "id": 7750,
      "CityName": "Kair",
      "CityValue": "Kair"
    },
    {
      "id": 7751,
      "CityName": "Bursera",
      "CityValue": "Bursera"
    },
    {
      "id": 7752,
      "CityName": "Pura Pandey",
      "CityValue": "Pura Pandey"
    },
    {
      "id": 7753,
      "CityName": "Daitari",
      "CityValue": "Daitari"
    },
    {
      "id": 7754,
      "CityName": "Aur",
      "CityValue": "Aur"
    },
    {
      "id": 7755,
      "CityName": "Spituk",
      "CityValue": "Spituk"
    },
    {
      "id": 7756,
      "CityName": "Charibatia",
      "CityValue": "Charibatia"
    },
    {
      "id": 7757,
      "CityName": "Iffco Census Village",
      "CityValue": "Iffco Census Village"
    },
    {
      "id": 7758,
      "CityName": "Gyalshing",
      "CityValue": "Gyalshing"
    },
    {
      "id": 7759,
      "CityName": "Raghunathpur",
      "CityValue": "Raghunathpur"
    },
    {
      "id": 7760,
      "CityName": "Sundarapandianpattinam",
      "CityValue": "Sundarapandianpattinam"
    },
    {
      "id": 7761,
      "CityName": "Marowa",
      "CityValue": "Marowa"
    },
    {
      "id": 7762,
      "CityName": "Shikar",
      "CityValue": "Shikar"
    },
    {
      "id": 7763,
      "CityName": "Haringhata Farm",
      "CityValue": "Haringhata Farm"
    },
    {
      "id": 7764,
      "CityName": "Ranaghat",
      "CityValue": "Ranaghat"
    },
    {
      "id": 7765,
      "CityName": "Sool Koot",
      "CityValue": "Sool Koot"
    },
    {
      "id": 7766,
      "CityName": "Bhalariya",
      "CityValue": "Bhalariya"
    },
    {
      "id": 7767,
      "CityName": "Ghomanhasan",
      "CityValue": "Ghomanhasan"
    },
    {
      "id": 7768,
      "CityName": "Jourian",
      "CityValue": "Jourian"
    },
    {
      "id": 7769,
      "CityName": "Limkheda",
      "CityValue": "Limkheda"
    },
    {
      "id": 7770,
      "CityName": "Sringeri",
      "CityValue": "Sringeri"
    },
    {
      "id": 7771,
      "CityName": "Raipur Rasulpur",
      "CityValue": "Raipur Rasulpur"
    },
    {
      "id": 7772,
      "CityName": "Baliyasan",
      "CityValue": "Baliyasan"
    },
    {
      "id": 7773,
      "CityName": "Balkundra",
      "CityValue": "Balkundra"
    },
    {
      "id": 7774,
      "CityName": "Banihal",
      "CityValue": "Banihal"
    },
    {
      "id": 7775,
      "CityName": "Barora",
      "CityValue": "Barora"
    },
    {
      "id": 7776,
      "CityName": "Hanspukuria",
      "CityValue": "Hanspukuria"
    },
    {
      "id": 7777,
      "CityName": "Kasauli",
      "CityValue": "Kasauli"
    },
    {
      "id": 7778,
      "CityName": "Mudal",
      "CityValue": "Mudal"
    },
    {
      "id": 7779,
      "CityName": "Nallampatti",
      "CityValue": "Nallampatti"
    },
    {
      "id": 7780,
      "CityName": "HMT Pinjore",
      "CityValue": "HMT Pinjore"
    },
    {
      "id": 7781,
      "CityName": "Nihalpur Simbal",
      "CityValue": "Nihalpur Simbal"
    },
    {
      "id": 7782,
      "CityName": "Shahjangi",
      "CityValue": "Shahjangi"
    },
    {
      "id": 7783,
      "CityName": "Gagret",
      "CityValue": "Gagret"
    },
    {
      "id": 7784,
      "CityName": "Jodhan",
      "CityValue": "Jodhan"
    },
    {
      "id": 7785,
      "CityName": "Birodhi",
      "CityValue": "Birodhi"
    },
    {
      "id": 7786,
      "CityName": "Nandgaon Pode",
      "CityValue": "Nandgaon Pode"
    },
    {
      "id": 7787,
      "CityName": "Rupa",
      "CityValue": "Rupa"
    },
    {
      "id": 7788,
      "CityName": "Nakhula Grant",
      "CityValue": "Nakhula Grant"
    },
    {
      "id": 7789,
      "CityName": "Mevalurkuppam",
      "CityValue": "Mevalurkuppam"
    },
    {
      "id": 7790,
      "CityName": "Kriparampur",
      "CityValue": "Kriparampur"
    },
    {
      "id": 7791,
      "CityName": "Gurur",
      "CityValue": "Gurur"
    },
    {
      "id": 7792,
      "CityName": "Chuari Khas",
      "CityValue": "Chuari Khas"
    },
    {
      "id": 7793,
      "CityName": "Darlawn",
      "CityValue": "Darlawn"
    },
    {
      "id": 7794,
      "CityName": "Daulatpur",
      "CityValue": "Daulatpur"
    },
    {
      "id": 7795,
      "CityName": "Dirang",
      "CityValue": "Dirang"
    },
    {
      "id": 7796,
      "CityName": "Parpondi",
      "CityValue": "Parpondi"
    },
    {
      "id": 7797,
      "CityName": "Zawlnuam",
      "CityValue": "Zawlnuam"
    },
    {
      "id": 7798,
      "CityName": "Chomon",
      "CityValue": "Chomon"
    },
    {
      "id": 7799,
      "CityName": "Fazalpur",
      "CityValue": "Fazalpur"
    },
    {
      "id": 7800,
      "CityName": "Sabathu",
      "CityValue": "Sabathu"
    },
    {
      "id": 7801,
      "CityName": "Limla",
      "CityValue": "Limla"
    },
    {
      "id": 7802,
      "CityName": "Nandesari",
      "CityValue": "Nandesari"
    },
    {
      "id": 7803,
      "CityName": "Piarinagar",
      "CityValue": "Piarinagar"
    },
    {
      "id": 7804,
      "CityName": "Tattilli",
      "CityValue": "Tattilli"
    },
    {
      "id": 7805,
      "CityName": "Pasur",
      "CityValue": "Pasur"
    },
    {
      "id": 7806,
      "CityName": "Purusottampur",
      "CityValue": "Purusottampur"
    },
    {
      "id": 7807,
      "CityName": "Lalpania",
      "CityValue": "Lalpania"
    },
    {
      "id": 7808,
      "CityName": "Balihati",
      "CityValue": "Balihati"
    },
    {
      "id": 7809,
      "CityName": "Sumerganj Mandi",
      "CityValue": "Sumerganj Mandi"
    },
    {
      "id": 7810,
      "CityName": "Kanaudia Chamical & Industries Ltd.",
      "CityValue": "Kanaudia Chamical & Industries Ltd."
    },
    {
      "id": 7811,
      "CityName": "T.T.P.S. Township",
      "CityValue": "T.T.P.S. Township"
    },
    {
      "id": 7812,
      "CityName": "North Vanlaiphai",
      "CityValue": "North Vanlaiphai"
    },
    {
      "id": 7813,
      "CityName": "Ramban",
      "CityValue": "Ramban"
    },
    {
      "id": 7814,
      "CityName": "Ambikanagara",
      "CityValue": "Ambikanagara"
    },
    {
      "id": 7815,
      "CityName": "Dalhousie",
      "CityValue": "Dalhousie"
    },
    {
      "id": 7816,
      "CityName": "Palampur",
      "CityValue": "Palampur"
    },
    {
      "id": 7817,
      "CityName": "Landaur",
      "CityValue": "Landaur"
    },
    {
      "id": 7818,
      "CityName": "Pataliputra Housing Colony",
      "CityValue": "Pataliputra Housing Colony"
    },
    {
      "id": 7819,
      "CityName": "Baryar",
      "CityValue": "Baryar"
    },
    {
      "id": 7820,
      "CityName": "Sadruddin Nagar",
      "CityValue": "Sadruddin Nagar"
    },
    {
      "id": 7821,
      "CityName": "Atul",
      "CityValue": "Atul"
    },
    {
      "id": 7822,
      "CityName": "Minakhan",
      "CityValue": "Minakhan"
    },
    {
      "id": 7823,
      "CityName": "Lakhanpur",
      "CityValue": "Lakhanpur"
    },
    {
      "id": 7824,
      "CityName": "Nimsa",
      "CityValue": "Nimsa"
    },
    {
      "id": 7825,
      "CityName": "Luwangsangbam",
      "CityValue": "Luwangsangbam"
    },
    {
      "id": 7826,
      "CityName": "Jawaharnagar",
      "CityValue": "Jawaharnagar"
    },
    {
      "id": 7827,
      "CityName": "Zotirpada",
      "CityValue": "Zotirpada"
    },
    {
      "id": 7828,
      "CityName": "Kamalapur",
      "CityValue": "Kamalapur"
    },
    {
      "id": 7829,
      "CityName": "Bharuch",
      "CityValue": "Bharuch"
    },
    {
      "id": 7830,
      "CityName": "Lengpui",
      "CityValue": "Lengpui"
    },
    {
      "id": 7831,
      "CityName": "Kachujan Gaon",
      "CityValue": "Kachujan Gaon"
    },
    {
      "id": 7832,
      "CityName": "Kakar Majra",
      "CityValue": "Kakar Majra"
    },
    {
      "id": 7833,
      "CityName": "Par Patiram",
      "CityValue": "Par Patiram"
    },
    {
      "id": 7834,
      "CityName": "Digaru Gaon",
      "CityValue": "Digaru Gaon"
    },
    {
      "id": 7835,
      "CityName": "Ratnapur",
      "CityValue": "Ratnapur"
    },
    {
      "id": 7836,
      "CityName": "Kanam",
      "CityValue": "Kanam"
    },
    {
      "id": 7837,
      "CityName": "Panhala",
      "CityValue": "Panhala"
    },
    {
      "id": 7838,
      "CityName": "Sethiya",
      "CityValue": "Sethiya"
    },
    {
      "id": 7839,
      "CityName": "Rajgarh",
      "CityValue": "Rajgarh"
    },
    {
      "id": 7840,
      "CityName": "Arki",
      "CityValue": "Arki"
    },
    {
      "id": 7841,
      "CityName": "Tipo",
      "CityValue": "Tipo"
    },
    {
      "id": 7842,
      "CityName": "Boleng",
      "CityValue": "Boleng"
    },
    {
      "id": 7843,
      "CityName": "Heirok",
      "CityValue": "Heirok"
    },
    {
      "id": 7844,
      "CityName": "Saputara",
      "CityValue": "Saputara"
    },
    {
      "id": 7845,
      "CityName": "Dagshai",
      "CityValue": "Dagshai"
    },
    {
      "id": 7846,
      "CityName": "Barbari",
      "CityValue": "Barbari"
    },
    {
      "id": 7847,
      "CityName": "Devaprayag",
      "CityValue": "Devaprayag"
    },
    {
      "id": 7848,
      "CityName": "Dayalbagh",
      "CityValue": "Dayalbagh"
    },
    {
      "id": 7849,
      "CityName": "Dwarahat",
      "CityValue": "Dwarahat"
    },
    {
      "id": 7850,
      "CityName": "Sangat",
      "CityValue": "Sangat"
    },
    {
      "id": 7851,
      "CityName": "Bakultala",
      "CityValue": "Bakultala"
    },
    {
      "id": 7852,
      "CityName": "H.P.C. Township",
      "CityValue": "H.P.C. Township"
    },
    {
      "id": 7853,
      "CityName": "Banguwan Kalan",
      "CityValue": "Banguwan Kalan"
    },
    {
      "id": 7854,
      "CityName": "Sachin",
      "CityValue": "Sachin"
    },
    {
      "id": 7855,
      "CityName": "Khonmoh",
      "CityValue": "Khonmoh"
    },
    {
      "id": 7856,
      "CityName": "Chhatral INA",
      "CityValue": "Chhatral INA"
    },
    {
      "id": 7857,
      "CityName": "Dakshin Bagdogra",
      "CityValue": "Dakshin Bagdogra"
    },
    {
      "id": 7858,
      "CityName": "Palaj",
      "CityValue": "Palaj"
    },
    {
      "id": 7859,
      "CityName": "Khansahib",
      "CityValue": "Khansahib"
    },
    {
      "id": 7860,
      "CityName": "Amaravathi",
      "CityValue": "Amaravathi"
    },
    {
      "id": 7861,
      "CityName": "Adityapatna",
      "CityValue": "Adityapatna"
    },
    {
      "id": 7862,
      "CityName": "Chenani",
      "CityValue": "Chenani"
    },
    {
      "id": 7863,
      "CityName": "Seoni",
      "CityValue": "Seoni"
    },
    {
      "id": 7864,
      "CityName": "Nagalwadi",
      "CityValue": "Nagalwadi"
    },
    {
      "id": 7865,
      "CityName": "Newa Talai",
      "CityValue": "Newa Talai"
    },
    {
      "id": 7866,
      "CityName": "Laharijan Natun Bosti",
      "CityValue": "Laharijan Natun Bosti"
    },
    {
      "id": 7867,
      "CityName": "Santaldih Thermal Power Project-Town",
      "CityValue": "Santaldih Thermal Power Project-Town"
    },
    {
      "id": 7868,
      "CityName": "Khawhai",
      "CityValue": "Khawhai"
    },
    {
      "id": 7869,
      "CityName": "Now Gam",
      "CityValue": "Now Gam"
    },
    {
      "id": 7870,
      "CityName": "Unjalur",
      "CityValue": "Unjalur"
    },
    {
      "id": 7871,
      "CityName": "Badrinathpuri",
      "CityValue": "Badrinathpuri"
    },
    {
      "id": 7872,
      "CityName": "Dogadda",
      "CityValue": "Dogadda"
    },
    {
      "id": 7873,
      "CityName": "O.C.L.",
      "CityValue": "O.C.L."
    },
    {
      "id": 7874,
      "CityName": "Anini",
      "CityValue": "Anini"
    },
    {
      "id": 7875,
      "CityName": "Talai",
      "CityValue": "Talai"
    },
    {
      "id": 7876,
      "CityName": "Koloriang",
      "CityValue": "Koloriang"
    },
    {
      "id": 7877,
      "CityName": "Numaligarh Refinery Township",
      "CityValue": "Numaligarh Refinery Township"
    },
    {
      "id": 7878,
      "CityName": "Hill Town",
      "CityValue": "Hill Town"
    },
    {
      "id": 7879,
      "CityName": "Biate",
      "CityValue": "Biate"
    },
    {
      "id": 7880,
      "CityName": "GSFC Complex",
      "CityValue": "GSFC Complex"
    },
    {
      "id": 7881,
      "CityName": "Kudremukh",
      "CityValue": "Kudremukh"
    },
    {
      "id": 7882,
      "CityName": "Mahur",
      "CityValue": "Mahur"
    },
    {
      "id": 7883,
      "CityName": "Courtalam",
      "CityValue": "Courtalam"
    },
    {
      "id": 7884,
      "CityName": "Jutogh",
      "CityValue": "Jutogh"
    },
    {
      "id": 7885,
      "CityName": "Anand Nagar",
      "CityValue": "Anand Nagar"
    },
    {
      "id": 7886,
      "CityName": "Swamibagh",
      "CityValue": "Swamibagh"
    },
    {
      "id": 7887,
      "CityName": "Mahishrekha",
      "CityValue": "Mahishrekha"
    },
    {
      "id": 7888,
      "CityName": "Nabadiganta Industrial Township",
      "CityValue": "Nabadiganta Industrial Township"
    },
    {
      "id": 7889,
      "CityName": "Gulmarg",
      "CityValue": "Gulmarg"
    },
    {
      "id": 7890,
      "CityName": "Petro-Chemical Complex",
      "CityValue": "Petro-Chemical Complex"
    },
    {
      "id": 7891,
      "CityName": "Kapuria",
      "CityValue": "Kapuria"
    },
    {
      "id": 7892,
      "CityName": "Paharpur",
      "CityValue": "Paharpur"
    },
    {
      "id": 7893,
      "CityName": "Kunzer",
      "CityValue": "Kunzer"
    },
    {
      "id": 7894,
      "CityName": "Chaupal",
      "CityValue": "Chaupal"
    },
    {
      "id": 7895,
      "CityName": "Rawalsar",
      "CityValue": "Rawalsar"
    },
    {
      "id": 7896,
      "CityName": "Bakloh",
      "CityValue": "Bakloh"
    },
    {
      "id": 7897,
      "CityName": "Colgong",
      "CityValue": "Colgong"
    },
    {
      "id": 7898,
      "CityName": "Nandprayag",
      "CityValue": "Nandprayag"
    },
    {
      "id": 7899,
      "CityName": "Jubbal",
      "CityValue": "Jubbal"
    },
    {
      "id": 7900,
      "CityName": "Mouje Anjanvel",
      "CityValue": "Mouje Anjanvel"
    },
    {
      "id": 7901,
      "CityName": "Kud",
      "CityValue": "Kud"
    },
    {
      "id": 7902,
      "CityName": "Kirtinagar",
      "CityValue": "Kirtinagar"
    },
    {
      "id": 7903,
      "CityName": "Bhota",
      "CityValue": "Bhota"
    },
    {
      "id": 7904,
      "CityName": "Banjar",
      "CityValue": "Banjar"
    },
    {
      "id": 7905,
      "CityName": "Nainital",
      "CityValue": "Nainital"
    },
    {
      "id": 7906,
      "CityName": "Almora",
      "CityValue": "Almora"
    },
    {
      "id": 7907,
      "CityName": "F.C.I. Township",
      "CityValue": "F.C.I. Township"
    },
    {
      "id": 7908,
      "CityName": "Sagalee",
      "CityValue": "Sagalee"
    },
    {
      "id": 7909,
      "CityName": "Faizabad",
      "CityValue": "Faizabad"
    },
    {
      "id": 7910,
      "CityName": "Nayabazar Notified Bazar Area",
      "CityValue": "Nayabazar Notified Bazar Area"
    },
    {
      "id": 7911,
      "CityName": "Naina Devi",
      "CityValue": "Naina Devi"
    },
    {
      "id": 7912,
      "CityName": "Kotkhai",
      "CityValue": "Kotkhai"
    },
    {
      "id": 7913,
      "CityName": "Shakar Pur Baramad",
      "CityValue": "Shakar Pur Baramad"
    },
    {
      "id": 7914,
      "CityName": "Valsad",
      "CityValue": "Valsad"
    },
    {
      "id": 7915,
      "CityName": "Valia - Jhagadia",
      "CityValue": "Valia - Jhagadia"
    },
    {
      "id": 7916,
      "CityName": "Hawai",
      "CityValue": "Hawai"
    },
    {
      "id": 7917,
      "CityName": "Sarigam",
      "CityValue": "Sarigam"
    },
    {
      "id": 7918,
      "CityName": "Narkanda",
      "CityValue": "Narkanda"
    },
    {
      "id": 7919,
      "CityName": "Panoli",
      "CityValue": "Panoli"
    },
    {
      "id": 7920,
      "CityName": "Songirwadi",
      "CityValue": "Songirwadi"
    },
    {
      "id": 7921,
      "CityName": "Pavi Sadakpur",
      "CityValue": "Pavi Sadakpur"
    },
    {
      "id": 7922,
      "CityName": "Purana Daroorh",
      "CityValue": "Purana Daroorh"
    },
    {
      "id": 7923,
      "CityName": "Lodhika",
      "CityValue": "Lodhika"
    },
    {
      "id": 7924,
      "CityName": "Kedarnath",
      "CityValue": "Kedarnath"
    },
    {
      "id": 7925,
      "CityName": "Kalol INA",
      "CityValue": "Kalol INA"
    },
    {
      "id": 7926,
      "CityName": "GSFC",
      "CityValue": "GSFC"
    },
    {
      "id": 7927,
      "CityName": "Kalol",
      "CityValue": "Kalol"
    },
    {
      "id": 7928,
      "CityName": "Por-Ramangamdi",
      "CityValue": "Por-Ramangamdi"
    },
    {
      "id": 7929,
      "CityName": "Vaghodia",
      "CityValue": "Vaghodia"
    },
    {
      "id": 7930,
      "CityName": "Pachora",
      "CityValue": "Pachora"
    },
    {
      "id": 7931,
      "CityName": "Vasna Borsad",
      "CityValue": "Vasna Borsad"
    },
    {
      "id": 7932,
      "CityName": "Gangotri",
      "CityValue": "Gangotri"
    },
    {
      "id": 7933,
      "CityName": "Magdalla",
      "CityValue": "Magdalla"
    },
    {
      "id": 7934,
      "CityName": "Valia",
      "CityValue": "Valia"
    }
   ]