import React from 'react'

const TermsCondition = () => {
    return (
        <div className='terms_condition_content'>
            <div className="content_para">
                <p>This is an Agreement between you and Kentrix Services Private Limited, Cin U74999mh2021ptc371774, 1st Floor, Kagalwala House, C Block, Metro Es 175, Cst Road Kalina, Near Honda Showroom, Santacruz East Mumbai, Mumbai Suburban, Maharashtra, 400098, Gstin 27AAJCK1601K1ZL (hereinafter ‘KENTRIX’) that describes the Terms of Service (“Terms”) with respect to your access to and use of content, reports, documents, products, and services (referred to collectively as the “Services”) we make available through KENTRIX PERSONA360   (referred to collectively as the “Solution”). Please take the time to read the Agreement carefully as it governs your use of the Site and Services.</p>

            </div>

            <div className="content_para">
                <p>Here at KENTRIX we believe that all KENTRIX PERSONA360 User digital marketing campaigns should be transparent and put consumers first. Therefore, both Agencies and Brands that use KENTRIX PERSONA360, need to ensure that no campaign or post is false or misleading. KENTRIX expects that all marketing campaigns which are being facilitated by users through the KENTRIX PERSONA360 comply with all applicable Laws and industry self-regulation relating to digital marketing, including the Advertising Standard Council of India (ASCI), AANA Guidelines (AU), CAP Code (UK), and Federal Trade Commission’s Guides Concerning Endorsements and Testimonials in Advertising (USA) as applicable to each Brand.</p>
            </div>
            <div className="content_para">
                <p>These Customer Terms of Service (the “Agreement”) shall outline the mutual understanding between the Parties. The Parties hereto agree as follows:
                </p>
            </div>

            <div className="content_para">
                <h4>  Introduction </h4>
                <p>

                    These terms and conditions govern your use of the KENTRIX digital advertisement solution PERSONA360 by Kentrix Solutions Private Limited (hereinafter referred to as ‘company’) is the sole and exclusive owner of KENTRIX PERSONA360, owned and registered in its name. By using KENTRIX PERSONA360 in any manner whatsoever and in any capacity whatsoever, you accept these terms and conditions in full.
                    This document is an electronic record in terms of the Information Technology Act, 2000 ("IT Act") and the rules thereunder as applicable and the provisions pertaining to electronic records in various statutes as amended by the IT Act and is published in accordance with the provisions of applicable laws, including the Consumer Protection (E-Commerce) Rules 2020, that require publishing the rules and regulations, privacy policy and terms of use for access or usage of the Platform. Further, platform for the purposes of this documents means the One Impression. This electronic record is generated by a computer system and does not require any physical or digital signatures.

                </p>
            </div>

            <div className="content_para">
                <h4>  User Account </h4>
                <p>

                    You will be required to successfully sign up for an account (the “User Account”) and be issued with a username and password login (“User ID”) in order to use the Services. If you are issued with a User ID, you shall keep your User ID secure and shall not share your User ID with anyone else, and you shall not collect or harvest any personal data of any other user of One Impression, including account names. KENTRIX reserves the right to disable any KENTRIX PERSONA360 account issued to you at any time at own sole discretion. If KENTRIX disables access to a KENTRIX PERSONA360 account issued to you, you may be prevented from accessing KENTRIX PERSONA360, your account details or any campaigns that are associated with your account. By registering with KENTRIX you agree to receive emails, including but not limited to: newsletters, product updates, opportunities. You will be able to unsubscribe from each group. By accessing or using the Website, You represent and warrant that You will be legally binding contracts with us.

                </p>
                <p>
                    If you act on behalf of a business entity in accepting these Terms of Service, you warrant and undertake that you have the requisite power and authority to act on behalf of that entity and bind the entity to these Terms of Service. By registering the user account, you agree to: (i) provide and keep current, accurate and complete information about yourself and your company (“Information”); (ii) you agree to accept responsibility for all activities that occur under the user account. If we have reasonable grounds to suspect that the Information you provide is inaccurate, incomplete or impersonates another person, We reserve the right to suspend or terminate your User Account. All Information will be stored and used in accordance with our Privacy Policy.


                </p>
                <p>
                    In order to register the user account, you will be asked to choose a sign-in name (“Sign-In Name”), a password (“Password”), and perhaps certain additional information that will assist in authenticating your identity when you log-in in the future (“Unique Identifiers”). When creating your account, you must provide true, accurate, current, and complete information. each sign-in name and corresponding password can be used by only one user, and sharing your access credentials with others (especially if they are using your credentials to access our content (as defined below) is a material breach of these Terms of Service. You are solely responsible for the confidentiality and use of your sign-in name and password, as well as for any use, misuse, or communications entered through the websites using one or more of them. You will promptly inform us of any need to deactivate a password or sign-in name. KENTRIX will not be liable for any loss or damage caused by any unauthorized use of your account. By creating the user account and providing details of a business entity, you confirm that you are an authorised employee, contractor, or representative of the business entity. KENTRIX shall not be responsible in any manner and bear no liability for Your activities carried out without such authorisation.


                </p>
            </div>

            <div className="content_para">
                <h4>Use of KENTRIX PERSONA360 </h4>

                <p>By accessing and using KENTRIX PERSONA360 , you acknowledge that you are responsible for your actions. You represent and warrant, to the best of your knowledge that:</p>
                <ul>
                    <li>
                        You have all the necessary rights, power and authority to enter into these Terms of Service and to fulfil your contractual obligations hereunder;
                    </li>
                    <li>
                        Any and all information you post or provide is true, accurate and not misleading and that you will not allow any other person or entity to use your user account;
                    </li>
                    <li>
                        The information and marketing campaigns you upload, post, e-mail, transmit, or otherwise make available using KENTRIX PERSONA360 ,  including without limitation reviews, trademarks, logos, screenshots and videos is accurate and free of third-party encumbrances;
                    </li>
                    <li>
                        You will, when approving audience segments on KENTRIX PERSONA360 , adhere to all the applicable guidelines and bear all liability related to your submission of META own campaign rules and guidelines;
                    </li>
                    <li>
                        You will not post or otherwise use marketing content that is unlawful, defamatory, infringing, libellous, abusive, disparaging, pornographic, invasive of another's privacy, promotes illegal activities/conduct or violates applicable laws or regulations or META rules and regulations using KENTRIX PERSONA360 ;
                    </li>
                    <li>
                        You will not post or otherwise provide user content that you do not have the right to make available under any law or contractual or fiduciary relationship (such as insider information, proprietary and confidential information, learned or disclosed as part of employment relationships, or information protected under nondisclosure agreements) using KENTRIX PERSONA360 ;
                    </li>
                    <li>
                        You will not post or otherwise provide user content that contains a software virus or any other code files or programs with the ability to interrupt, destroy, compromise, or otherwise limit the functionality of any computer software or hardware or telecommunications equipment using KENTRIX PERSONA360 ;
                    </li>
                </ul>
                <p>
                    At our discretion, we may, but are not obligated to, monitor and terminate user activity on KENTRIX PERSONA360 , and/or edit or remove User Content, which violates or otherwise fails to comply with these Terms of Service.
                </p>
            </div>

            <div className="content_para">
                <h4>Fees</h4>

                You shall pay KENTRIX all applicable fees and taxes (collectively, the “Fees”) for the Service rendered by KENTRIX PERSONA360 . We (or our third-party payment processor) shall authorize your credit card, bank account, or other approved payment facility that you provided during the registration process for the full payment of the fees, and you hereby consent to the same. All fees will be charged and made in Indian Rupee.

                You are responsible for ensuring that all payment information is correct and the funds necessary for payment are available. You agree not to hold us liable for payments that do not reach us because you have quoted incorrect payment information or the payment processors refused the payment for any other reason.


                <h4>Confidentiality</h4>

                <p>“Confidential Information” means all non-public information, in any form and on any medium, whether or not designated by a party (the “Disclosing Party”) as confidential and disclosed by such party to the other party (the “Receiving Party”) under this contract, regardless of the form of disclosure, and includes without limitation and without the need to designate as confidential and related documents and information; BUT DOES NOT INCLUDE any information to the extent, but only to the extent, that such information becomes or is: (a) already known to or in the possession of the Receiving Party free of any restriction at the time it is obtained from the Disclosing Party; (b) subsequently learned by the Receiving Party from an independent third party free of any restriction and without breach of this contract or any contract with such third party; (c) publicly available through no wrongful act of the Receiving Party; or (d) independently developed by the Receiving Party without reference to any confidential information, as evidenced by the ordinary business records of the Receiving Party.
                </p>

                <h4>Intellectual Property Rights</h4>

                <p>Other than content you own, which you may have opted to include on KENTRIX PERSONA360, under these Terms of Service, KENTRIX owns all rights to the intellectual property and material contained in its KENTRIX PERSONA360  Solution, and all such rights are reserved.
                </p>
            </div>

            <div className="content_para">
                <h4>Your Content</h4>
            </div>

            <p>By using KENTRIX PERSONA360  for executing marketing campaigns for which you provide the content, you guarantee that you have the legitimate rights to use this content. You must not infringe any intellectual property rights of others through your content. KENTRIX is in no way liable for any consequences arising from your content used in digital advertisement campaigns using KENTRIX PERSONA360  audience selection tool.
            </p>
            <div className="content_para">
                <h4>Disclaimer</h4>

                <p>Except as specifically set forth herein (i) your use of KENTRIX PERSONA360  is at your own risk, and (ii) to the fullest extent permitted by applicable law, KENTRIX PERSONA360 is provided on an "as is" and "as available" basis, without warranties of any kind, express or implied, including without limitation: any implied warranties of merchantability, fitness for a particular purpose, non-infringement of third party intellectual property rights, title, custom, trade, quiet enjoyment, accuracy of informational content or system integration. While KENTRIX PERSONA360  may serve as a platform to host; including but not limited to; software/platforms/agencies/tools and service and product listings, we are not responsible for ensuring the legal and/or regulatory compliance of any service made available on KENTRIX PERSONA360 . We do not warrant that KENTRIX PERSONA360  will be secure, available or operate in an uninterrupted or error-free manner; that errors or defects will be corrected; or that the content on our sites is accurate or appropriate for you and your business needs.
                </p>
            </div>
            <div className="content_para">
                <h4>Indemnification</h4>

                The User (in its capacity as “Indemnitor”) agrees to indemnify, defend and hold harmless KENTRIX, its agents, affiliates and employees (in its capacity as "Indemnitee”) from and against any and all third-party claims, liabilities, losses and expenses (including damage awards, settlement amounts and reasonable attorneys’ fees) arising out of or relating to User’s respective violation of these Terms of Service, except to the extent such losses and expenses arise from the negligence or wilful misconduct of Indemnitee.
            </div>
            <div className="content_para">
                <h4>Limitation of Liability</h4>

                <p>We will not be liable for any consequential, special, indirect, exemplary or punitive damages arising out of or in any way related to use of service, Users’ use of KENTRIX PERSONA360, including without limitation, loss of profits, revenue, interest, goodwill, loss or corruption of data or other interruption of business (whether in contract, tort or under other legal theory), even if advised of such damages.
                </p>
            </div>

            <div className="content_para">
                <h4>Termination</h4>

                <p>We reserve the right, in our sole discretion, without penalty and at any time without notice, to modify or discontinue (temporarily or permanently) the service, or any part thereof if we believe that the User is violating these Term of Service. If the User uses or attempts to use KENTRIX PERSONA360  for any purpose that contravenes these Term of Service (including without limitation tampering, hacking, data scraping, modifying or otherwise corrupting the security or functionality of the Website), the User may also be subject to civil and criminal liability.
                </p>
            </div>
            <div className="content_para">
                <h4> Severability</h4>
                <p>
                    If any provision of these Term of Service is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Term of Service unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.
                </p>
            </div>

            <div className="content_para">
                <h4>Assignment</h4>

                <p>KENTRIX shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Term of Service without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Term of Service.
                </p>
            </div>

            <div className="content_para">
                <h4>Force Majeure</h4>

                <p>KENTRIX will not be liable for any failure or delay of performance under these Terms of Service resulting from a force majeure event beyond its reasonable control, including, without limitation, natural disasters, acts of God, government regulations, war, terrorism, labor disputes, power failures, epidemic and pandemic.
                </p></div>

            <div className="content_para">
                <h4>Governing Law</h4>

                <p>These Terms of Service, including non-contractual rights and obligations arising out of or in connection with these Terms of Service, shall be governed by the laws of India. Any legal proceedings arising out of these Terms of Service or relating thereto, shall be instituted in Courts of Mumbai, Maharashtra, India only.
                </p></div>

            <div className="content_para">
                <h4>Waiver & Severability</h4>
                <p>
                    Our failure to act with respect to a breach of these Terms of Service will not constitute a waiver and does not waive our right to act with respect to subsequent or similar breaches. If any of these Terms of Service shall be deemed invalid, void, or for any reason unenforceable under applicable law, then that term shall be deemed severable and shall not affect the validity and enforceability of any remaining term or provision of these Terms of Service.
                </p>
            </div >

            <div className="content_para">
                <h4>Entire Agreement</h4>
                <p>
                    These Terms of Service along with the documents referred to herein, constitute the entire and exclusive agreement between KENTRIX and the User with regard to User’s use of the service through KENTRIX PERSONA360.
                </p>
            </div>
            <div className="content_para">
                <h4>International Users</h4>
                <p>
                    If you access KENTRIX PERSONA360 from a location outside the India, you do so at your own risk and are responsible for compliance with all applicable laws, rules, regulations or decrees of your jurisdiction.
                </p>
            </div>
            <div className="content_para">
                <h4>Entire Contract</h4>

                <p>
                    These Terms of Service constitute the entire and exclusive understanding and agreement between KENTRIX and you regarding the Service and supersede and replace any and all prior oral or written understandings or agreements between KENTRIX and you regarding the same.
                </p>
            </div>
            <div className="content_para">
                <h4>Contact</h4>
                <p>
                    You may contact KENTRIX, PERSONA360 division, via:                </p>
                <ul>
                    <li><strong>Telephone :</strong> +91 72593 71600

                    </li>
                    <li>
                        <strong>E-mail :</strong><a href="mailto:legal@persona360.in">legal@persona360.in</a>
                    </li>
                    <li>
                        <strong>Web :</strong> <a href='https://kentrix.in/contact/' target='_blank'> https://kentrix.in/contact/</a>
                    </li>
                </ul>



            </div>
            <div className="content_para">
                <h4>Revision</h4>

                <p>We often update the services offered on KENTRIX PERSONA360. As a result, we may change/modify/ alter/ update/ add or discontinue any terms and conditions laid down herein. You can determine the applicable terms by referring to the "LAST UPDATED" legend below. It shall be your responsibility to check terms periodically for changes. Please check this page regularly to ensure you are familiar with the updated Terms & Conditions. By using KENTRIX PERSONA360  in any manner whatsoever and in any capacity whatsoever, it shall be deemed that you have seen the updated terms and conditions and consented thereto.
                </p>
            </div>




        </div>
    )
}

export default TermsCondition