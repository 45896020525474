import React, { useEffect, useState } from 'react';
import { BACKEND_URL } from '../components/BackendURL';
import axios from 'axios';

const MyAccount = ({ user, setAlertMessage, setShowAlert, }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [onboardUserdata, setOnboardUserdata] = useState([])

    useEffect(() => {
        fetchUsers();
    }, []);


    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/get_onboarding_data`);
            setOnboardUserdata(response.data.onboard_user)
        } catch (error) {
            setAlertMessage("Error fetching users:", error);
            setShowAlert(true);
        }
    };


    const [userOnboarding, setUserOnboarding] = useState({
        full_name: "",
        phone_number: "",
        business_name: "",
        business_address_1: "",
        business_address_2: "",
        address_city: "",
        address_state: "",
        address_pincode: "",
        address_country: "",
        business_GSTIN: "",
        business_PAN: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserOnboarding({
            ...userOnboarding,
            [name]: value,
        });
    };





    const handleUpdateOnboarding = () => {
        axios.post(`${BACKEND_URL}/update_onboarding`, {
            user_mail: user.email,

            full_name: userOnboarding.full_name,
            phone_number: userOnboarding.phone_number,
            business_name: userOnboarding.business_name,
            business_address_1: userOnboarding.business_address_1,
            business_address_2: userOnboarding.business_address_2,
            address_city: userOnboarding.address_city,
            address_country: userOnboarding.address_country,
            address_state: userOnboarding.address_state,
            address_pincode: userOnboarding.address_pincode,
            business_GSTIN: userOnboarding.business_GSTIN,
            business_PAN: userOnboarding.business_PAN,

        })
            .then(response => {
                setAlertMessage(response.data.message);
                setShowAlert(true)
                setUserOnboarding({
                    full_name: "",
                    phone_number: "",
                    business_name: "",
                    business_address_1: "",
                    business_address_2: "",
                    address_city: "",
                    address_state: "",
                    address_pincode: "",
                    address_country: "",
                    business_GSTIN: "",
                    business_PAN: "",
                })
                setIsEditing(!isEditing)
                fetchUsers();

            })
            .catch(error => {
                setAlertMessage('An error occurred. Please try again.', error);
                setShowAlert(true)
                setIsEditing(!isEditing)
            });
    }


    const handleChangePassword = () => {
        if (oldPassword === '') {
            setAlertMessage('Please enter your current password.');
            setShowAlert(true)
            return;
        }

        if (newPassword === '') {
            setAlertMessage('Please enter a new password.');
            setShowAlert(true)
            return;
        }

        if (newPassword !== confirmPassword) {
            setAlertMessage('New password and confirm password do not match.');
            setShowAlert(true)
            return;
        }

        axios
            .post(`${BACKEND_URL}/change-password`, {
                user_mail: user.email,
                oldPassword: oldPassword,
                newPassword: newPassword
            })
            .then(response => {
                setAlertMessage(response.data.message);
                setShowAlert(true)
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            })
            .catch(error => {
                setAlertMessage('An error occurred. Please try again.', error);
                setShowAlert(true)
            });
    };

    const [isEditing, setIsEditing] = useState(false); // State to track editing status

    const handleEditClick = () => {
        setIsEditing(!isEditing); // Toggle editing status
    };


    return (
        <div className='my_account_container'>
            <div className="section_content">
                <p>Welcome! {user.name} ({user.email})</p>
            </div>



            <div className="accordion">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="personal_info">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_personal_info" aria-expanded="true">
                            Personal Info
                        </button>
                    </h2>
                    <div id="collapse_personal_info" className="accordion-collapse collapse show" aria-labelledby="personal_info">
                        <div className="accordion-body">
                            <div className="mb-3">
                                <label className="form-label">Your Full Name</label>
                                <input
                                    type="text"
                                    value={userOnboarding.full_name}
                                    name="full_name"
                                    className="form-control"
                                    disabled={!isEditing}
                                    placeholder={onboardUserdata.full_name}
                                    onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Your Email</label>
                                <input type="email" className="form-control" disabled placeholder={onboardUserdata.user_mail} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Phone Number</label>
                                <input
                                    type="text"
                                    value={userOnboarding.phone_number}
                                    name="phone_number"
                                    className="form-control"
                                    disabled={!isEditing}
                                    placeholder={onboardUserdata.phone_number}
                                    onChange={handleChange} />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="business_info">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush_business_info" aria-expanded="false">
                            Business Info
                        </button>
                    </h2>
                    <div id="flush_business_info" className="accordion-collapse collapse show" aria-labelledby="business_info">
                        <div className="accordion-body">
                            <div className="mb-3">
                                <label className="form-label">Business Name</label>
                                <input
                                    type="text"
                                    value={userOnboarding.business_name}
                                    name="business_name"
                                    className="form-control"
                                    disabled={!isEditing}
                                    placeholder={onboardUserdata.business_name}
                                    onChange={handleChange} />
                            </div>

                            <div className="mb-3">
                                <label className="form-label mb-3">Business Address</label>
                                <input
                                    type="text"
                                    value={userOnboarding.business_address_1}
                                    name="business_address_1"
                                    className="form-control mb-3"
                                    disabled={!isEditing}
                                    placeholder={onboardUserdata.business_address_1}
                                    onChange={handleChange} />

                                <input
                                    type="text"
                                    value={userOnboarding.business_address_2}
                                    name="business_address_2"
                                    className="form-control mb-3"
                                    disabled={!isEditing}
                                    placeholder={onboardUserdata.business_address_2}
                                    onChange={handleChange} />


                                <div className="row">
                                    <div className="col-md-6">
                                        <input
                                            value={userOnboarding.address_city}
                                            name="address_city"
                                            type="text"
                                            className="form-control mb-3"
                                            disabled={!isEditing}
                                            placeholder={onboardUserdata.address_city}
                                            onChange={handleChange} />
                                    </div>

                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            value={userOnboarding.address_pincode}
                                            name="address_pincode"
                                            className="form-control mb-3"
                                            disabled={!isEditing}
                                            placeholder={onboardUserdata.address_pincode}
                                            onChange={handleChange} />

                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            value={userOnboarding.address_state}
                                            name="address_state"
                                            className="form-control mb-3"
                                            disabled={!isEditing}
                                            placeholder={onboardUserdata.address_state}
                                            onChange={handleChange} />

                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            value={userOnboarding.address_country}
                                            name="address_country"
                                            className="form-control mb-3"
                                            disabled={!isEditing}
                                            placeholder={onboardUserdata.address_country}
                                            onChange={handleChange} />

                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Business Info</label>
                                    <input
                                        type="text"
                                        value={userOnboarding.business_GSTIN}
                                        name="business_GSTIN"
                                        className="form-control mb-3"
                                        disabled={!isEditing}
                                        placeholder={onboardUserdata.business_GSTIN}
                                        onChange={handleChange} />

                                    <input
                                        type="text"
                                        value={userOnboarding.business_PAN}
                                        name="business_PAN"
                                        className="form-control mb-3"
                                        disabled={!isEditing}
                                        placeholder={onboardUserdata.business_PAN}
                                        onChange={handleChange} />
                                </div>

                                <button className="btn_general" style={{padding:"10px 30px"}} onClick={isEditing ? handleUpdateOnboarding : handleEditClick}>
                                    {isEditing ? 'Save' : 'Edit'}
                                </button>

                            </div>



                        </div>

                    </div>

                </div>

                <div className="accordion-item">

                    <h2 className="accordion-header" id="change_password_label">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#change_password" aria-expanded="false" aria-controls="change_password">
                            Change Password
                        </button>
                    </h2>
                    <div id="change_password" className="accordion-collapse collapse show" aria-labelledby="change_password_label">
                        <div className="accordion-body">
                            <div className="mb-3">
                                <label className="form-label">Current Password</label>
                                <input
                                    type="password"
                                    className="form-control mb-3"
                                    placeholder="Current Password"
                                    value={oldPassword}
                                    onChange={e => setOldPassword(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">New Password</label>

                                <input
                                    type="password"
                                    className="form-control mb-3"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Confirm New Password</label>

                                <input
                                    type="password"
                                    placeholder="Confirm New Password"
                                    className="form-control mb-3"
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                            </div>

                            <button className="btn_general" onClick={handleChangePassword}>
                                Change Password
                            </button>

                        </div>
                    </div>
                </div>

                {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="update_profile_label">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#update_profile" aria-expanded="false" aria-controls="update_profile">
                            Update Profile
                        </button>
                    </h2>
                    <div id="update_profile" className="accordion-collapse collapse" aria-labelledby="update_profile_label">
                        <div className="accordion-body">



                            <button className='btn_general mt-3'>Update</button>

                        </div>
                    </div>
                </div>
 */}


            </div>

        </div>
    )
}

export default MyAccount