export const KentrixFiltersDescription = [
  {
    "Group": "Socio-Economic Profile",
    "Category": "Income",
    "SubCategory": "01: INR <40,000",
    "Description(Facebook)": "Consumer is living in an urban area, where the monthly household-level income is typically 01: INR <40,000",
    "OneLiner": "Househole Income is below 40,000 Rs/Month",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "Demographic information such as Affluence and income are provided in these segments.",
    "ID": 1
  },
  {
    "Group": "Socio-Economic Profile",
    "Category": "Income",
    "SubCategory": "02: INR 40,000 - 80,000",
    "Description(Facebook)": "Consumer is living in an urban area, where the monthly household-level income is typically 02: INR 40,000 - 80,000",
    "OneLiner": "Househole Income is between 40,000 to 80,000 Rs/Month",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 2
  },
  {
    "Group": "Socio-Economic Profile",
    "Category": "Income",
    "SubCategory": "03: INR 80,000 - 1,70,000",
    "Description(Facebook)": "Consumer is living in an urban area, where the monthly household-level income is typically 03: INR 80,000 - 1,70,000",
    "OneLiner": "Househole Income is between 80,000 to 1,70,000 Rs/Month",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 3
  },
  {
    "Group": "Socio-Economic Profile",
    "Category": "Income",
    "SubCategory": "04: INR 1,70,000 - 3,00,000",
    "Description(Facebook)": "Consumer is living in an urban area, where the monthly household-level income is typically 04: INR 1,70,000 - 3,00,000",
    "OneLiner": "Househole Income is between 1,70,000 to 3,00,000 Rs/Month",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 4
  },
  {
    "Group": "Socio-Economic Profile",
    "Category": "Income",
    "SubCategory": "05: INR >3,00,000",
    "Description(Facebook)": "Consumer is living in an urban area, where the monthly household-level income is typically 05: INR >3,00,000",
    "OneLiner": "Househole Income is greater 3,00,000 Rs/Month",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 5
  },
  {
    "Group": "Consumer Segments",
    "Category": "Lifestyle",
    "SubCategory": "01: Urban Established Elite",
    "Description(Facebook)": "“The world is our playground”. Wealthy, educated families. Attended (inter)national leading educational institutes. Accustomed to international luxury and quality standards. Global mind-set. Sophisticated orientations in style and taste. Self-confident. Individual, liberal attitudes. Financially secure. Highly brand conscious. Shop for status, not for price. Memberships in selected Private Clubs. Outdoor dining, exquisite restaurants.",
    "OneLiner": "“The world is our playground”. Wealthy, educated families.",
    "PopUpInfoIconDescription": "Attended (inter)national leading educational institutes. Accustomed to international luxury and quality standards. Global mind-set. Sophisticated orientations in style and taste. Self-confident. Individual, liberal attitudes. Financially secure. Highly brand conscious. Shop for status, not for price. Memberships in selected Private Clubs. Outdoor dining, exquisite restaurants.",
    "FilterDescription": "Segments the population into different categories based on demographic information and consumption habits like consumption and social status.",
    "ID": 6
  },
  {
    "Group": "Consumer Segments",
    "Category": "Lifestyle",
    "SubCategory": "02: Urban New Wealth",
    "Description(Facebook)": "“We are the new cosmopolitan leaders”. Newly arrived, status conscious Upper Class. Having gained their professional platform and recognition based upon their high-level education. Ambitious, convinced in their own strength. Developing international perspectives. Striving for the exquisite taste. Easy to be led in search for top class quality and comfort standards. Memberships in Private Clubs. Outdoor dining, high class restaurants.",
    "OneLiner": "“We are the new cosmopolitan leaders”. Newly arrived, status conscious Upper Class.",
    "PopUpInfoIconDescription": "Having gained their professional platform and recognition based upon their high-level education. Ambitious, convinced in their own strength. Developing international perspectives. Striving for the exquisite taste. Easy to be led in search for top class quality and comfort standards. Memberships in Private Clubs. Outdoor dining, high class restaurants.",
    "FilterDescription": "",
    "ID": 7
  },
  {
    "Group": "Consumer Segments",
    "Category": "Lifestyle",
    "SubCategory": "03: Aspiring Urban Middle Class",
    "Description(Facebook)": "“We are here and want it now”. Aspiring Middle Class. Value seekers. Educational background: University degrees, dominant. Benchmarking with the next above. Desire a predictable life. Spending mid-range budgets on consumer goods and leisure time activities. Want to buy cheap, but not feel cheap. Ready to change for a new attractive offer. Younger members spend and indulge in their present wellbeing.",
    "OneLiner": "“We are here and want it now”. Aspiring Middle Class. Value seekers.",
    "PopUpInfoIconDescription": "Educational background: University degrees, dominant. Benchmarking with the next above. Desire a predictable life. Spending mid-range budgets on consumer goods and leisure time activities. Want to buy cheap, but not feel cheap. Ready to change for a new attractive offer. Younger members spend and indulge in their present wellbeing.",
    "FilterDescription": "",
    "ID": 8
  },
  {
    "Group": "Consumer Segments",
    "Category": "Lifestyle",
    "SubCategory": "04: Conservative Urban Middle Class",
    "Description(Facebook)": "“Cherish what you have”. Focusing on safeguarding their achievements. Seeking a pleasant and sheltered life without risks and extremes. Eager on social relations with their community and neighbourhood society. Celebrating festivals with great enthusiasm. Predominantly mainstream Indian college education. Typically upper-class salaried jobs. Critically comparing prices and services. National brands and values meet their spending consciousness.",
    "OneLiner": "“Cherish what you have”. Focusing on safeguarding their achievements.",
    "PopUpInfoIconDescription": "Seeking a pleasant and sheltered life without risks and extremes. Eager on social relations with their community and neighbourhood society. Celebrating festivals with great enthusiasm. Predominantly mainstream Indian college education. Typically upper-class salaried jobs. Critically comparing prices and services. National brands and values meet their spending consciousness.",
    "FilterDescription": "",
    "ID": 9
  },
  {
    "Group": "Consumer Segments",
    "Category": "Lifestyle",
    "SubCategory": "05: Lower Middle Class",
    "Description(Facebook)": "“Education is the ticket to a better future”. Born from lesser-educated parents, striving to reach higher education levels. Ambitious to be successful in life. Seeking a comfortable, stable life. Focussing on next level professional options away from regular blue-collar jobs. Believing in their own strength. Cautious spenders. Early adopters of new products. ‘Buy Indian’, preferring national brands and technology. Thrifty in details.",
    "OneLiner": "“Education is the ticket to a better future”. Born from lesser-educated parents, striving to reach higher education levels.",
    "PopUpInfoIconDescription": "Ambitious to be successful in life. Seeking a comfortable, stable life. Focussing on next level professional options away from regular blue-collar jobs. Believing in their own strength. Cautious spenders. Early adopters of new products. ‘Buy Indian’, preferring national brands and technology. Thrifty in details.",
    "FilterDescription": "",
    "ID": 10
  },
  {
    "Group": "Consumer Segments",
    "Category": "Lifestyle",
    "SubCategory": "06: Lower Class",
    "Description(Facebook)": "“The day is my life”. Typical Day Workers and Labourers. Skilled, but always in search of daily work. Dominantly illiterate. Family and community are the most important ‘back-up’ in life. Focusing their activities on short-term benefits for themselves and their family. Buying decisions on any service or product are solely driven by daily needs and ‘functionality’ at lowest possible costs.",
    "OneLiner": "“The day is my life”. Typical Day Workers and Labourers.",
    "PopUpInfoIconDescription": "Skilled, but always in search of daily work. Dominantly illiterate. Family and community are the most important ‘back-up’ in life. Focusing their activities on short-term benefits for themselves and their family. Buying decisions on any service or product are solely driven by daily needs and ‘functionality’ at lowest possible costs.",
    "FilterDescription": "",
    "ID": 11
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS A1",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class A1 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class A1 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 12
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS A2",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class A2 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class A2 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 13
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS A3",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class A3 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class A3 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 14
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS B1",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class B1 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class B1 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 15
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS B2",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class B2 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class B2 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 16
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS C1",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class C1 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class C1 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 17
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS C2",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class C2 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class C2 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 18
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS D1",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class D1 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class D1 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 19
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS D2",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class D2 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class D2 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 20
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS E1",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class E1 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class E1 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 21
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS E2",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class E2 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class E2 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 22
  },
  {
    "Group": "Consumer Segments",
    "Category": "NCCS Reference",
    "SubCategory": "NCCS E3",
    "Description(Facebook)": "Consumer belongs to a household falling into the NCCS Class E3 for Urban Areas.",
    "OneLiner": "Consumer belongs to a household falling into the NCCS Class E3 for Urban Areas.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 23
  },
  {
    "Group": "Consumer Segments",
    "Category": "Special Health Care Target Group",
    "SubCategory": "Health Care - ‘The Informed’ In Urban India",
    "Description(Facebook)": "Typically health conscious consumers in urban areas. They are well accustomed to the benefits of preventive healthcare. Leading a health conscious and wellness focussed life. Willing to spend for maintaining a good health status. Caring about body weight and appearance.",
    "OneLiner": "Typically health conscious consumers in urban areas.",
    "PopUpInfoIconDescription": "They are well accustomed to the benefits of preventive healthcare. Leading a health conscious and wellness focussed life. Willing to spend for maintaining a good health status. Caring about body weight and appearance.",
    "FilterDescription": "",
    "ID": 24
  },
  {
    "Group": "Consumer Segments",
    "Category": "Special Health Care Target Group",
    "SubCategory": "Health Care - ‘The Carefree / Anxious’ In Urban India",
    "Description(Facebook)": "Typically no distinctive health consciousness. Showing not much interest for preventive health care (healthy food, physical exercise). Knowing mainly traditional medicine. Block out possible health risks and medical issues. Benefit and service conscious, but minimal spender on preventive medical care.",
    "OneLiner": "Typically no distinctive health consciousness.",
    "PopUpInfoIconDescription": "Showing not much interest for preventive health care (healthy food, physical exercise). Knowing mainly traditional medicine. Block out possible health risks and medical issues. Benefit and service conscious, but minimal spender on preventive medical care.",
    "FilterDescription": "",
    "ID": 25
  },
  {
    "Group": "Financial Products",
    "Category": "Insurance: Saving Plan",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to holding life insurance ‘Savings Plan’ product(s). Being interested in alternate offerings. Meeting needs in planning ahead for the children’s education and / or wedding, buying a house / apartment or making a dream vacation.",
    "OneLiner": "High affinity to holding life insurance ‘Savings Plan’ product(s).",
    "PopUpInfoIconDescription": "Being interested in alternate offerings. Meeting needs in planning ahead for the children’s education and / or wedding, buying a house / apartment or making a dream vacation.",
    "FilterDescription": "This audience category contains information about individual’s Financial Services such as credit card holders.",
    "ID": 26
  },
  {
    "Group": "Financial Products",
    "Category": "Insurance: Retirement Plan",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to life insurance product ‘Retirement Plan’. Strong awareness of increasing life expectancy rate, rising health care costs, absence of social security system and disintegration of joint family system as key reasons for making retirement planning critically important.",
    "OneLiner": "High affinity to life insurance product ‘Retirement Plan’.",
    "PopUpInfoIconDescription": "Strong awareness of increasing life expectancy rate, rising health care costs, absence of social security system and disintegration of joint family system as key reasons for making retirement planning critically important.",
    "FilterDescription": "",
    "ID": 27
  },
  {
    "Group": "Financial Products",
    "Category": "Insurance: Unit Linked Plan",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to life insurance product ‘Unit Linked Insurance Plan’. Seeking long term investment plus protection plan and availing market-linked returns while providing life insurance protection.",
    "OneLiner": "High affinity to life insurance product ‘Unit Linked Insurance Plan’.",
    "PopUpInfoIconDescription": "Seeking long term investment plus protection plan and availing market-linked returns while providing life insurance protection.",
    "FilterDescription": "",
    "ID": 28
  },
  {
    "Group": "Financial Products",
    "Category": "Insurance: Health Plan",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to insurance product ‘Health Insurance’. Obtaining coverage paying for medical and surgical expenses if incurring. Being reimbursed for expenses deriving from illness or injury treatment.",
    "OneLiner": "High affinity to insurance product ‘Health Insurance’.",
    "PopUpInfoIconDescription": "Obtaining coverage paying for medical and surgical expenses if incurring. Being reimbursed for expenses deriving from illness or injury treatment.",
    "FilterDescription": "",
    "ID": 29
  },
  {
    "Group": "Financial Products",
    "Category": "Banking: Mutual Fund",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to banking product ‘Mutual Fund’. Considering to invest in a mutual fund as a pool of money from numerous investors. Preferring these advantages instead of buying and selling individual stocks and bonds.",
    "OneLiner": "High affinity to banking product ‘Mutual Fund’.",
    "PopUpInfoIconDescription": "Considering to invest in a mutual fund as a pool of money from numerous investors. Preferring these advantages instead of buying and selling individual stocks and bonds.",
    "FilterDescription": "",
    "ID": 30
  },
  {
    "Group": "Financial Products",
    "Category": "Banking: Fixed Deposit",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to banking product ‘Fixed Deposits’. Attracted to the higher rate of interest than achievable for regular savings account.",
    "OneLiner": "High affinity to banking product ‘Fixed Deposits’.",
    "PopUpInfoIconDescription": "Attracted to the higher rate of interest than achievable for regular savings account.",
    "FilterDescription": "",
    "ID": 31
  },
  {
    "Group": "Financial Products",
    "Category": "Banking: Credit Card Holding",
    "SubCategory": "Expected rare / Irregular Use",
    "Description(Facebook)": "High affinity to hold a credit card with expected irregular use, more for 'emergencies' and exxceptional situations.",
    "OneLiner": "High affinity to hold a credit card with expected irregular use, more for 'emergencies' and exxceptional situations.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 32
  },
  {
    "Group": "Financial Products",
    "Category": "Banking: Credit Card Holding",
    "SubCategory": "Expected Moderate / Average Use",
    "Description(Facebook)": "High affinity to hold a credit card with expected moderate use, regularly but not heavy for many payments to be done.",
    "OneLiner": "High affinity to hold a credit card with expected moderate use, regularly but not heavy for many payments to be done.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 33
  },
  {
    "Group": "Financial Products",
    "Category": "Banking: Credit Card Holding",
    "SubCategory": "Expected heavy / Frequent Use",
    "Description(Facebook)": "High affinity to hold a credit card with expected heavy use, regularly and for many payments 'every other day'.",
    "OneLiner": "High affinity to hold a credit card with expected heavy use, regularly and for many payments 'every other day'.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 34
  },
  {
    "Group": "Automobile",
    "Category": "Entry Price - Segment INR 2,00,000 – 4,50,000",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Entry Price Segment INR 2,00,000 – 4,50,000 (Renault Kwid, Suzuki Alto, Datsun GO, Hyundai eon, etc).",
    "OneLiner": "Renault Kwid, Suzuki Alto, Datsun GO, Hyundai eon, etc",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "Consumers who are likely to own varying types of motor vehicles as well as the affinity to buy cars in a specific price range.",
    "ID": 35
  },
  {
    "Group": "Automobile",
    "Category": "Entry Premium Hatch Segment INR 4,00,000 – 7,00,000",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Entry Premium Hatch Segment INR 4,00,000 – 7,00,000 (Suzuki Grand i10, Maruti Suzuki swift, TATA Tiago, Honda Brio, etc.).",
    "OneLiner": "Suzuki Grand i10, Maruti Suzuki swift, TATA Tiago, Honda Brio, etc",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 36
  },
  {
    "Group": "Automobile",
    "Category": "Premium Hatch Segment INR 5,50,000 – 9,00,000",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Premium Hatch Segment INR 5,50,000 – 9,00,000 (Hyundai i20, Maruti Suzuki Baleno, VW Polo, Honda Jazz, etc.).",
    "OneLiner": "Hyundai i20, Maruti Suzuki Baleno, VW Polo, Honda Jazz, etc",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 37
  },
  {
    "Group": "Automobile",
    "Category": "Compact Sedan Segment INR 5,30,000 – 8,50,000",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Compact Sedan Segment INR 5,30,000 – 8,50,000 (Maruti Suzuki Swift Dzire, Hyundai Xcent, Honda Amaze, Ford Aspire, etc.).",
    "OneLiner": "Maruti Suzuki Swift Dzire, Hyundai Xcent, Honda Amaze, Ford Aspire, etc",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 38
  },
  {
    "Group": "Automobile",
    "Category": "Sedan Segment INR 8,00,000 – 13,00,000",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Sedan Segment INR 8,00,000 – 13,00,000 (Honda City, Hyundai Verna, Maruti Suzuki Ciaz, VW Vento, etc.).",
    "OneLiner": "Honda City, Hyundai Verna, Maruti Suzuki Ciaz, VW Vento, etc",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 39
  },
  {
    "Group": "Automobile",
    "Category": "Premium Sedan Segment INR 13,00,000 – 20,00,000",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Premium Sedan Segment INR 13,00,000 – 20,00,000 (Toyota Corolla Altis, Toyota Accord, Skoda Superb, Honda Civic, etc.).",
    "OneLiner": "Toyota Corolla Altis, Toyota Accord, Skoda Superb, Honda Civic, etc",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 40
  },
  {
    "Group": "Automobile",
    "Category": "Compact / Entry SUV Segment INR 9,00,000 – 14,00,000",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Compact / Entry SUV Segment INR 9,00,000 – 14,00,000 (Ford Ecosport, Suzuki S-Cross, Honda BRV, Suzuki Ertiga, etc.).",
    "OneLiner": "Ford Ecosport, Suzuki S-Cross, Honda BRV, Suzuki Ertiga, etc",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 41
  },
  {
    "Group": "Automobile",
    "Category": "SUV Segment INR 15,00,000 – 30,00,000",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the SUV Segment INR 15,00,000 – 30,00,000 (Toyota Fortuner, Ford Endeavour, TATA Aria, etc.)",
    "OneLiner": "Toyota Fortuner, Ford Endeavour, TATA Aria, etc",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 42
  },
  {
    "Group": "Automobile",
    "Category": "Luxury Premium Sedan & SUV Segment INR 25,00,000+",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Luxury Premium Sedan & SUV Segment INR 25,00,000+ (BMW, Mercedes, AUDI, VW).",
    "OneLiner": "BMW, Mercedes, AUDI, VW",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 43
  },
  {
    "Group": "Automobile",
    "Category": "Second-hand Sedan Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy a second-hand Sedan Segment car (Honda City, Hyundai Verna, Maruti Suzuki Ciaz, VW Vento, etc.).",
    "OneLiner": "Honda City, Hyundai Verna, Maruti Suzuki Ciaz, VW Vento, etc",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 44
  },
  {
    "Group": "Automobile",
    "Category": "Second-hand Premium Sedan / SUV Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy a second-hand Premium Sedan / SUV Segment car (Toyota Corolla Altis, Toyota Accord, Skoda Suberb, Honda Civic, Toyota Fortuner, Ford Endeavour, TATA Aria etc.).",
    "OneLiner": "Toyota Corolla Altis, Toyota Accord, Skoda Suberb, Honda Civic, Toyota Fortuner, Ford Endeavour, TATA Aria etc.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 45
  },
  {
    "Group": "Automobile",
    "Category": "Second-hand Luxury Premium Sedan & SUV Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy a second-hand Luxury Premium Sedan & SUV Segment car (BMW, Mercedes, AUDI, VW).",
    "OneLiner": "BMW, Mercedes, AUDI, VW",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 46
  },
  {
    "Group": "Real Estate",
    "Category": "Purchase Price Segment: INR 15,00,000 – 25,00,000",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy a property in the price range INR 15,00,000 – 25,00,000.",
    "OneLiner": "High affinity to buy a property in the price range INR 15,00,000 – 25,00,000.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "This audience category contains information about Home and Property ownership as well as expenditures for it.",
    "ID": 47
  },
  {
    "Group": "Real Estate",
    "Category": "Purchase Price Segment: INR 25,00,000 – 50,00,000",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy a property in the price range INR 25,00,000 – 50,00,000.",
    "OneLiner": "High affinity to buy a property in the price range INR 25,00,000 – 50,00,000.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 48
  },
  {
    "Group": "Real Estate",
    "Category": "Purchase Price Segment: INR 51,00,000 - 1 Crore",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy a property in the price range INR 51,00,000 - 1,00,00,000.",
    "OneLiner": "High affinity to buy a property in the price range INR 51,00,000 - 1,00,00,000.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 49
  },
  {
    "Group": "Real Estate",
    "Category": "Purchase Price Segment: INR 1 - 4 Crore",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy a property in the price range INR 1 - 4 Crore.",
    "OneLiner": "High affinity to buy a property in the price range INR 1 - 4 Crore.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 50
  },
  {
    "Group": "Real Estate",
    "Category": "Purchase Price Segment: Beyond INR 4 Crore",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy a property in the price range beyond INR 4 Crore.",
    "OneLiner": "High affinity to buy a property in the price range beyond INR 4 Crore.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 51
  },
  {
    "Group": "FMCG",
    "Category": "Laundry",
    "SubCategory": "Fabric Wash Products: Mass Classified",
    "Description(Facebook)": "High affinity in the category ‘laundry’ to buy mass fabric wash products: practical, effective, focused on basic performance (Wheel, etc.).",
    "OneLiner": "High affinity to buy mass fabric wash products: practical, effective, focused on basic performance (Wheel, etc.).",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "Consumers who are likely to purchase goods across various product categories including Food, Personal Wash, Beauty, Home and Living.",
    "ID": 52
  },
  {
    "Group": "FMCG",
    "Category": "Laundry",
    "SubCategory": "Fabric Wash Products: Premium Classified",
    "Description(Facebook)": "High affinity in the category ‘laundry’ to buy premium fabric wash products. Clean clothes for creating a positive impression and being recognized by society. Special fragrance conscious. (Rin, Sunlight, etc.).",
    "OneLiner": "High affinity to buy premium fabric wash products. Clean clothes for creating a positive impression and being recognized by society. Special fragrance conscious. (Rin, Sunlight, etc.).",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 53
  },
  {
    "Group": "FMCG",
    "Category": "Laundry",
    "SubCategory": "Washing Machine Products",
    "Description(Facebook)": "High affinity in the category ‘detergents’ and ‘laundry’ to buy products for washing machine use (Surf Excel, etc.).",
    "OneLiner": "High affinity in the category ‘detergents’ and ‘laundry’ to buy products for washing machine use (Surf Excel, etc.).",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 54
  },
  {
    "Group": "FMCG",
    "Category": "Personal Wash",
    "SubCategory": "Mass Classified Products",
    "Description(Facebook)": "High affinity in the category ‘personal wash’ to buy mass classified products. Convenience and pricing fulfilling basic needs (Lifebuoy, Breeze, Clinic, etc.).",
    "OneLiner": "High affinity to buy mass classified products. Convenience and pricing fulfilling basic needs (Lifebuoy, Breeze, Clinic, etc.).",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 55
  },
  {
    "Group": "FMCG",
    "Category": "Personal Wash",
    "SubCategory": "Popular Classified Products",
    "Description(Facebook)": "High affinity in the category ‘personal wash’ to buy popular classified products Eager to be trendy, getting the “million dollar” feeling (Rexone, Lux, Hamam, Sunsilk, Pepsodent, etc.).",
    "OneLiner": "High affinity to buy popular classified products Eager to be trendy, getting the “million dollar” feeling (Rexone, Lux, Hamam, Sunsilk, Pepsodent, etc.).",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 56
  },
  {
    "Group": "FMCG",
    "Category": "Personal Wash",
    "SubCategory": "Premium Classified Products",
    "Description(Facebook)": "High affinity in the category ‘personal wash’ to buy premium classified products. Health and ingredients conscious, looking for exquisite products (Dove, Ponds, Closeup, Tresemme, etc.).",
    "OneLiner": "High affinity to buy premium classified products. Health and ingredients conscious, looking for exquisite products (Dove, Ponds, Closeup, Tresemme, etc.).",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 57
  },
  {
    "Group": "FMCG",
    "Category": "Packaged Food",
    "SubCategory": "Mass Classified Products",
    "Description(Facebook)": "High affinity in the category ‘packaged food’ to buy mass classified products. Convenience and price conscious, accepting non organic basis. Seeking to be fancy and modern, having quick meals and drinks.",
    "OneLiner": "High affinity to buy mass classified products. Convenience and price conscious, accepting non organic basis. Seeking to be fancy and modern, having quick meals and drinks.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 58
  },
  {
    "Group": "FMCG",
    "Category": "Packaged Food",
    "SubCategory": "Premium Classified Products",
    "Description(Facebook)": "High affinity in the category ‘packaged food’ to buy premium classified products. Seeking high quality, willing to pay higher prices. Associates safety with higher price and being modern. Health driven, searching for natural products. Organic and nutrition conscious.",
    "OneLiner": "High affinity to buy premium classified products. Seeking high quality, willing to pay higher prices. Associates safety with higher price and being modern. Health driven, searching for natural products. Organic and nutrition conscious.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 59
  },
  {
    "Group": "FMCG",
    "Category": "Cosmetics",
    "SubCategory": "Mass Classified Products",
    "Description(Facebook)": "High affinity in the category ‘cosmetics’ to buy mass classified products. Convenience and price conscious, fulfilling basic needs.",
    "OneLiner": "High affinity to buy mass classified products. Convenience and price conscious, fulfilling basic needs.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 60
  },
  {
    "Group": "FMCG",
    "Category": "Cosmetics",
    "SubCategory": "Popular Classified Products",
    "Description(Facebook)": "High affinity in the category ‘cosmetics’ to buy popular branded products. Trendy, giving the “million dollar” feeling.",
    "OneLiner": "High affinity to buy popular branded products. Trendy, giving the “million dollar” feeling.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 61
  },
  {
    "Group": "FMCG",
    "Category": "Cosmetics",
    "SubCategory": "Premium Classified Products",
    "Description(Facebook)": "High affinity in the category ‘cosmetics’ to buy premium classified products. Affinity to luxury. Exquisite, top branded products.",
    "OneLiner": "High affinity to buy premium classified products. Affinity to luxury. Exquisite, top branded products.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 62
  },
  {
    "Group": "Fashion & Apparel",
    "Category": "Purchase Price Category",
    "SubCategory": "Economy (typically INR 500 – 800)",
    "Description(Facebook)": "High affinity to buy clothes and foot ware in the price category \"Economy\" INR Below 800.",
    "OneLiner": "High affinity to buy clothes and foot ware in the price category \"Economy\" INR Below 800.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 63
  },
  {
    "Group": "Fashion & Apparel",
    "Category": "Purchase Price Category",
    "SubCategory": "Medium (typically INR 800 – 1,500)",
    "Description(Facebook)": "High affinity to buy clothes and foot ware in the price category \"Medium\" INR 800 – 2,000.",
    "OneLiner": "High affinity to buy clothes and foot ware in the price category \"Medium\" INR 800 – 2,000.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 64
  },
  {
    "Group": "Fashion & Apparel",
    "Category": "Purchase Price Category",
    "SubCategory": "Premium (typically INR 1,500 – 3,500)",
    "Description(Facebook)": "High affinity to buy clothes and foot ware in the price category \"Premium\" INR 2,000 – 3,500.",
    "OneLiner": "High affinity to buy clothes and foot ware in the price category \"Premium\" INR 2,000 – 3,500.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 65
  },
  {
    "Group": "Fashion & Apparel",
    "Category": "Purchase Price Category",
    "SubCategory": "Super Premium (typically INR 3,500 – 7,000)",
    "Description(Facebook)": "High affinity to buy clothes and foot ware in the price category \"Super Premium\" INR 3,500 – 7,000.",
    "OneLiner": "High affinity to buy clothes and foot ware in the price category \"Super Premium\" INR 3,500 – 7,000.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 66
  },
  {
    "Group": "Fashion & Apparel",
    "Category": "Purchase Price Category",
    "SubCategory": "Luxury (typically INR 7,000+)",
    "Description(Facebook)": "High affinity to buy clothes and foot ware in the price category \"Luxury\" INR 7,000 +.",
    "OneLiner": "High affinity to buy clothes and foot ware in the price category \"Luxury\" INR 7,000 +.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 67
  },
  {
    "Group": "Jewellery & Gold",
    "Category": "Category: Total",
    "SubCategory": "Branded Jewellery",
    "Description(Facebook)": "High Affinity to buying branded jewellery, contemporary and stylish. Having confidence in name reputation and guarantees.",
    "OneLiner": "High Affinity to buying branded jewellery, contemporary and stylish. Having confidence in name reputation and guarantees.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "Consumers who are likely to purchase goods across various product categories including Gold and Jewellery.",
    "ID": 68
  },
  {
    "Group": "Jewellery & Gold",
    "Category": "Category: Total",
    "SubCategory": "Traditional / Non-branded Jewellery",
    "Description(Facebook)": "High Affinity to buying traditional / non-branded jewellery, tailor-made according to own preferences.",
    "OneLiner": "High Affinity to buying traditional / non-branded jewellery, tailor-made according to own preferences.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 69
  },
  {
    "Group": "Jewellery & Gold",
    "Category": "Category: Gold",
    "SubCategory": "Moderately priced Gold Jewellery",
    "Description(Facebook)": "High Affinity to buying moderately priced gold jewellery. Expecting to look astonishingly beautiful with gold jewellery and wearing gold as lucky omen with blessings of goddesses.",
    "OneLiner": "High Affinity to buying moderately priced gold jewellery. Expecting to look astonishingly beautiful with gold jewellery and wearing gold as lucky omen with blessings of goddesses.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 70
  },
  {
    "Group": "Jewellery & Gold",
    "Category": "Category: Gold",
    "SubCategory": "High priced Gold Jewellery",
    "Description(Facebook)": "High Affinity to buying high priced gold jewellery. Expecting to look astonishingly beautiful with gold jewellery and wearing gold as lucky omen with blessings of goddesses.",
    "OneLiner": "High Affinity to buying high priced gold jewellery. Expecting to look astonishingly beautiful with gold jewellery and wearing gold as lucky omen with blessings of goddesses.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 71
  },
  {
    "Group": "Jewellery & Gold",
    "Category": "Category: Diamond",
    "SubCategory": "Moderately priced Diamond Jewellery",
    "Description(Facebook)": "High Affinity to buying moderately priced diamond jewellery. Wearing (branded) diamond jewellery as level of modern exclusivity and luxury.",
    "OneLiner": "High Affinity to buying moderately priced diamond jewellery. Wearing (branded) diamond jewellery as level of modern exclusivity and luxury.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 72
  },
  {
    "Group": "Jewellery & Gold",
    "Category": "Category: Diamond",
    "SubCategory": "High priced Diamond Jewellery",
    "Description(Facebook)": "High Affinity to buying high priced diamond jewellery. Wearing (branded) diamond jewellery as level of modern exclusivity and luxury.",
    "OneLiner": "High Affinity to buying high priced diamond jewellery. Wearing (branded) diamond jewellery as level of modern exclusivity and luxury.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 73
  },
  {
    "Group": "Travel",
    "Category": "Short Term Holidays",
    "SubCategory": "Category typically 'Basic'",
    "Description(Facebook)": "High Affinity to go on weekend get-aways, standard quality destinations - appreciating basic amenities.",
    "OneLiner": "High Affinity to go on weekend get-aways, standard quality destinations - appreciating basic amenities.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "Consumers who are likely to travel are part of these segments. Information about their travel habits, like destination and spending, is provided.",
    "ID": 74
  },
  {
    "Group": "Travel",
    "Category": "Short Term Holidays",
    "SubCategory": "Category typically 'Luxury'",
    "Description(Facebook)": "High Affinity to go on weekend get-aways, luxury destinations - wellness and high comfort focussed.",
    "OneLiner": "High Affinity to go on weekend get-aways, luxury destinations - wellness and high comfort focussed.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 75
  },
  {
    "Group": "Travel",
    "Category": "Long Term Holidays",
    "SubCategory": "Destination typically 'National'",
    "Description(Facebook)": "High Affinity to go on trips under 10 days, national destinations.",
    "OneLiner": "High Affinity to go on trips under 10 days, national destinations.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 76
  },
  {
    "Group": "Travel",
    "Category": "Long Term Holidays",
    "SubCategory": "Destination typically 'International'",
    "Description(Facebook)": "High Affinity to go on trips longer than 10 days, worldwide destinations inlcuding cruises.",
    "OneLiner": "High Affinity to go on trips longer than 10 days, worldwide destinations inlcuding cruises.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 77
  },
  {
    "Group": "Travel",
    "Category": "Long Term Holidays",
    "SubCategory": "",
    "Description(Facebook)": "High Affinity to go on niche / specialty tours - keen on experiencing cultural differences, unknown sites and exploring new places.",
    "OneLiner": "High Affinity to go on niche / specialty tours - keen on experiencing cultural differences, unknown sites and exploring new places.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 78
  },
  {
    "Group": "Retail",
    "Category": "High Frequency Online Shopping",
    "SubCategory": "",
    "Description(Facebook)": "High Affinity to shop more online than offline (typically 75% of purchases are done online)",
    "OneLiner": "High Affinity to shop more online than offline (typically 75% of purchases are done online)",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 79
  },
  {
    "Group": "Retail",
    "Category": "Home and Living",
    "SubCategory": "Typically 'High Priced' Purchases",
    "Description(Facebook)": "High Affinity to buy international style, high-priced furniture.",
    "OneLiner": "High Affinity to buy international style, high-priced furniture.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 80
  },
  {
    "Group": "Retail",
    "Category": "Home and Living",
    "SubCategory": "Typically 'Moderate Priced' Purchases",
    "Description(Facebook)": "High Affinity to buy common style, mid-priced furniture.",
    "OneLiner": "High Affinity to buy common style, mid-priced furniture.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 81
  },
  {
    "Group": "Two Wheeler",
    "Category": "Entry Level Scooter Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Entry Level Scooter Segment",
    "OneLiner": "High affinity to buy in the Entry Level Scooter Segment",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "Consumers who are likely to own varying types of Two Wheelers as well as the affinity to buy in a specific price range.",
    "ID": 82
  },
  {
    "Group": "Two Wheeler",
    "Category": "Premium Scooter Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Premium Scooter Segment",
    "OneLiner": "High affinity to buy in the Premium Scooter Segment",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 83
  },
  {
    "Group": "Two Wheeler",
    "Category": "Entry Level Commuter Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Entry Level Commuter Segment",
    "OneLiner": "High affinity to buy in the Entry Level Commuter Segment",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 84
  },
  {
    "Group": "Two Wheeler",
    "Category": "Premium Commuter Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Premium Commuter Segment",
    "OneLiner": "High affinity to buy in the Premium Commuter Segment",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 85
  },
  {
    "Group": "Two Wheeler",
    "Category": "Entry Level Sports Bike Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Entry Level Sports Bike Segment",
    "OneLiner": "High affinity to buy in the Entry Level Sports Bike Segment",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 86
  },
  {
    "Group": "Two Wheeler",
    "Category": "Premium Sports Bike Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Premium Sports Bike Segment",
    "OneLiner": "High affinity to buy in the Premium Sports Bike Segment",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 87
  },
  {
    "Group": "Two Wheeler",
    "Category": "Entry Level Cruiser Bike Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Entry Level Cruiser Bike Segment",
    "OneLiner": "High affinity to buy in the Entry Level Cruiser Bike Segment",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 88
  },
  {
    "Group": "Two Wheeler",
    "Category": "Premium Cruiser Bike Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Premium Cruiser Bike Segment",
    "OneLiner": "High affinity to buy in the Premium Cruiser Bike Segment",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 89
  },
  {
    "Group": "Two Wheeler",
    "Category": "Touring Bike Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Touring Bike Segment",
    "OneLiner": "High affinity to buy in the Touring Bike Segment",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 90
  },
  {
    "Group": "Two Wheeler",
    "Category": "Off Road Bike Segment",
    "SubCategory": "",
    "Description(Facebook)": "High affinity to buy in the Off Road Bike Segment",
    "OneLiner": "High affinity to buy in the Off Road Bike Segment",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 91
  },
  {
    "Group": "Retail",
    "Category": "High Frequency Online Shopping",
    "SubCategory": "",
    "Description(Facebook)": "Expected irregular use affinity for online shopping",
    "OneLiner": "Expected irregular use affinity for online shopping",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 92
  },
  {
    "Group": "Retail",
    "Category": "High Frequency Online Shopping",
    "SubCategory": "",
    "Description(Facebook)": "Expected moderate use affinity for online shopping",
    "OneLiner": "Expected moderate use affinity for online shopping",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 93
  },
  {
    "Group": "Retail",
    "Category": "High Frequency Online Shopping",
    "SubCategory": "",
    "Description(Facebook)": "Expected expected heavy use affinity for online shopping",
    "OneLiner": "Expected expected heavy use affinity for online shopping",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 94
  },
  {
    "Group": "FMCG",
    "Category": "Packaged Food",
    "SubCategory": "Popular Classified Products",
    "Description(Facebook)": "High affinity in the category ‘packaged food’ to buy popular classified products. Seeking high quality, willing to pay higher prices. Associates safety with higher price and being modern. Health driven, searching for natural products. Organic and nutrition conscious.",
    "OneLiner": "High affinity to buy popular classified products. Seeking high quality, willing to pay higher prices. Associates safety with higher price and being modern. Health driven, searching for natural products. Organic and nutrition conscious.",
    "PopUpInfoIconDescription": "",
    "FilterDescription": "",
    "ID": 95
  },
 ]