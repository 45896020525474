import React, { useState } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../components/BackendURL';
import { useNavigate } from 'react-router-dom';
import TermsCondition from './TermsCondition';




const Onboarding = ({ user, setAlertMessage, setShowAlert, setLoginUser }) => {
    const navigate = useNavigate()
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleLogout = () => {
        setLoginUser({});
        localStorage.removeItem('user');

        axios.post(`${BACKEND_URL}/logout`)
            .then(response => {
                setAlertMessage(response.data.message)
                setShowAlert(true)
                navigate("/login");
            })
            .catch(error => {
                setAlertMessage('Logout failed:', error)
                setShowAlert(true)
            });
    };

    const [userOnboarding, setUserOnboarding] = useState({
        full_name: "",
        phone_number: "",
        business_name: "",
        business_address_1: "",
        business_address_2: "",
        address_city: "",
        address_state: "",
        address_pincode: "",
        address_country: "",
        business_GSTIN: "",
        business_PAN: "",
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserOnboarding({
            ...userOnboarding,
            [name]: value,
        });
    };




    const handleOnboarding = () => {
        const { full_name, phone_number, business_name, business_address_1, business_address_2, address_city, address_state, address_pincode, address_country, business_GSTIN, business_PAN } = userOnboarding;

        if (oldPassword === '') {
            setAlertMessage('Please Change the password');
            setShowAlert(true);
            return;
        }

        if (newPassword === '') {
            setAlertMessage('Please enter a new password.');
            setShowAlert(true);
            return;
        }

        if (newPassword !== confirmPassword) {
            setAlertMessage('New password and confirm password do not match.');
            setShowAlert(true);
            return;
        }

        if (isChecked) {
            if (full_name && phone_number && business_name && business_address_1 && address_city && address_state && address_pincode && address_country && business_GSTIN && business_PAN && file) {
                const formData = new FormData();
                formData.append('user_mail', user.email);
                formData.append('oldPassword', oldPassword);
                formData.append('newPassword', newPassword);
                formData.append('certificate_file', file);
                formData.append('full_name', userOnboarding.full_name);
                formData.append('phone_number', userOnboarding.phone_number);
                formData.append('business_name', userOnboarding.business_name);
                formData.append('business_address_1', userOnboarding.business_address_1);
                formData.append('business_address_2', userOnboarding.business_address_2);
                formData.append('address_city', userOnboarding.address_city);
                formData.append('address_country', userOnboarding.address_country);
                formData.append('address_state', userOnboarding.address_state);
                formData.append('address_pincode', userOnboarding.address_pincode);
                formData.append('business_GSTIN', userOnboarding.business_GSTIN);
                formData.append('business_PAN', userOnboarding.business_PAN);

                axios
                    .post(`${BACKEND_URL}/onboarding`, formData)
                    .then((res) => {
                        setAlertMessage(res.data.message);
                        setShowAlert(true);
                        setOldPassword('');
                        setNewPassword('');
                        setConfirmPassword('');
                        navigate("/login");
                    })
                    .catch((error) => {
                        setAlertMessage('An error occurred. Please try again.', error);
                        setShowAlert(true);
                    });
            } else {
                setAlertMessage("Please add all onboarding details.");
                setShowAlert(true);
            }
        } else {
            setAlertMessage('Please agree to the Terms and Conditions.');
            setShowAlert(true);
        }
    };





    return (
        <div className='page_container'>
            <div className="onboarding_alert">
                <div className="row">
                    <div className="col-md-9">

                        <h4>Welcome to Persona 360!</h4>
                        <p>Please register yourself, change the password and read the Terms & Conditions to access the <strong>Persona 360</strong> Dashboard. </p>
                    </div>


                    <div className="col-md-3">
                        <div className="onboarding_logout">
                            <button className='btn_general mt-3' onClick={handleLogout}>Logout</button>
                        </div>

                    </div>
                </div>
            </div>


            <div class="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="Onboarding">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-Onboarding" aria-expanded="false" aria-controls="flush-collapseOne">
                            Onboarding
                        </button>
                    </h2>
                    <div id="flush-Onboarding" className="accordion-collapse collapse show" aria-labelledby="Onboarding" >
                        <div className="accordion-body">


                            <div className="accordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="personal_info">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_personal_info" aria-expanded="true">
                                            Add Personal Info
                                        </button>
                                    </h2>
                                    <div id="collapse_personal_info" className="accordion-collapse collapse show" aria-labelledby="personal_info">
                                        <div className="accordion-body">
                                            <div className="mb-3">
                                                <label className="form-label">Your Full Name</label>
                                                <input
                                                    type="text"
                                                    value={userOnboarding.full_name}
                                                    className="form-control"
                                                    name="full_name"
                                                    placeholder="Your Full Name"
                                                    onChange={handleChange} />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Your Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder={user.email}
                                                    disabled={true} />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Phone Number</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="phone_number"
                                                    value={userOnboarding.phone_number}
                                                    placeholder="Phone Number"
                                                    onChange={handleChange} />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="business_info">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush_business_info" aria-expanded="false">
                                            Add Business Info
                                        </button>
                                    </h2>
                                    <div id="flush_business_info" className="accordion-collapse collapse show" aria-labelledby="business_info">
                                        <div className="accordion-body">
                                            <div className="mb-3">
                                                <label className="form-label">Business Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="business_name"
                                                    value={userOnboarding.business_name}
                                                    placeholder="Business Name"
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label mb-3">Business Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control mb-3"
                                                    name="business_address_1"
                                                    value={userOnboarding.business_address_1}
                                                    placeholder="Street Address 1"
                                                    onChange={handleChange} />

                                                <input
                                                    type="text"
                                                    className="form-control mb-3"
                                                    name="business_address_2"
                                                    value={userOnboarding.business_address_2}
                                                    placeholder="Street Address 2"
                                                    onChange={handleChange} />


                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <input
                                                            type="text"
                                                            className="form-control mb-3"
                                                            name="address_city"
                                                            value={userOnboarding.address_city}
                                                            placeholder="Town or City"
                                                            onChange={handleChange} />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <input
                                                            type="number"
                                                            className="form-control mb-3"

                                                            name="address_pincode"
                                                            value={userOnboarding.address_pincode}
                                                            placeholder="Pincode"
                                                            onChange={handleChange} />

                                                    </div>
                                                    <div className="col-md-6">
                                                        <input
                                                            type="text"
                                                            className="form-control mb-3"
                                                            name="address_state"
                                                            value={userOnboarding.address_state}
                                                            placeholder="State"
                                                            onChange={handleChange} />

                                                    </div>
                                                    <div className="col-md-6">
                                                        <input
                                                            type="text"
                                                            className="form-control mb-3"
                                                            name="address_country"
                                                            value={userOnboarding.address_country}
                                                            placeholder="Country"
                                                            onChange={handleChange} />

                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label className="form-label">Business Info</label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3"
                                                        id="gstin"
                                                        name="business_GSTIN"
                                                        value={userOnboarding.business_GSTIN}
                                                        placeholder="GSTIN"
                                                        onChange={handleChange} />

                                                    <input
                                                        type="text"
                                                        className="form-control mb-3"
                                                        id="pan_number"
                                                        name="business_PAN"
                                                        value={userOnboarding.business_PAN}
                                                        placeholder="PAN"
                                                        onChange={handleChange} />
                                                </div>

                                                <div className="mb-3">
                                                    <label className="form-label">Upload GSTIN Certificate</label>
                                                    <input className="form-control" type="file" id="formFile" accept='.pdf' onChange={handleFileChange} />
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                                {/* <div className="accordion-item">
                                    <h2 className="accordion-header" id="payment_method">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush_payment_method" aria-expanded="false" aria-controls="flush-collapseThree">
                                            Payment Method
                                        </button>
                                    </h2>
                                    <div id="flush_payment_method" className="accordion-collapse collapse show" aria-labelledby="payment_method">
                                        <div className="accordion-body">
                                            Payment Method

                                        </div>


                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            Change Password
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo">
                        <div className="accordion-body">
                            <div className="change_password">
                                <label className="form-label">Current Password</label>
                                <input
                                    type="password"
                                    placeholder="Current Password"
                                    value={oldPassword}
                                    onChange={e => setOldPassword(e.target.value)}
                                />
                                {/* <br /> */}
                                <label className="form-label">New Password</label>

                                <input
                                    type="password"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                />
                                {/* <br /> */}
                                <label className="form-label">Confirm New Password</label>

                                <input
                                    type="password"
                                    placeholder="Confirm New Password"
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                {/* <br /> */}

                                {/* <button className="btn_general" onClick={handleChangePassword}>
                                    Change Password
                                </button> */}
                            </div>

                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            Terms & Condition
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body">
                            <TermsCondition />
                            <div className="onboarding_confirm">
                                <div className="content_para">
                                    <p> <input
                                        type="checkbox"
                                        id="vehicle1"
                                        name="vehicle1"
                                        value="Bike"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />


                                        {' '} I have read, understood, and agree to the Terms and Conditions.

                                        By checking the box, you acknowledge that you have reviewed the Terms and Conditions and consent to be bound by them.</p>

                                </div>
                            </div>


                            <button className='btn_general mt-3' onClick={handleOnboarding}>Submit</button>



                        </div>
                    </div>
                </div>



            </div>

        </div >
    )
}

export default Onboarding