import React, { useEffect } from 'react';

const AlertMessage = ({ text, showAlert, setShowAlert }) => {
    useEffect(() => {
        let timeout;
        if (showAlert) {
            timeout = setTimeout(() => {
                setShowAlert(false);
            }, 4000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [showAlert, setShowAlert]);

    return (
        <div className={`alert_container ${showAlert ? '' : 'hide-alert'}`}>
            {text}
        </div>
    );
};

export default AlertMessage;
