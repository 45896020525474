import React, { useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FaBars, FaTimes } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import KentrixFiltersPage from './KentrixFiltersPage';
// import FacebookFiltersPage from './FacebookFiltersPage';
import axios from "axios"
import { BACKEND_URL } from '../components/BackendURL';
import MyAccount from './MyAccount';
import { useNavigate } from 'react-router-dom';

const Home = ({ user, setLoginUser, setAlertMessage, setShowAlert }) => {
    const navigate = useNavigate();

    const [showMenu, setShowMenu] = useState(false);
    const handleToggle = () => {
        setShowMenu(!showMenu);
    };

    const handleLinkClick = () => {
        window.scrollTo(0, 0);
        setShowMenu(false);
    };

    const [city_name, setCityName] = useState([]);
    const [income, setIncome] = useState([]);
    const [regio_type, setRegionType] = useState([]);
    const [lifestyle, setLifestyle] = useState([]);
    const [sec_reference, setSecReference] = useState([]);
    const [smaller_towns, setSmallerTowns] = useState([]);
    const [health_care, setHealthCare] = useState([]);
    const [insurance, setInsurance] = useState([]);
    const [banking_product, setBankingProduct] = useState([]);
    const [credit_card, setCreditCard] = useState([]);
    const [automobile, setAutomobile] = useState([]);
    const [real_estate, setRealEstate] = useState([]);
    const [laundry, setLaundry] = useState([]);
    const [personal_wash, setPersonalWash] = useState([]);
    const [packaged_food, setPackagedFood] = useState([]);
    const [cosmetics, setCosmetics] = useState([]);
    const [fashion, setFashion] = useState([]);
    const [jewellery_total, setJewelleryTotal] = useState([]);
    const [jewellery_gold, setJewelleryGold] = useState([]);
    const [jewellery_diamond, setJewelleryDiamond] = useState([]);
    const [travel_spend, setTravelSpend] = useState([]);
    const [travel_destination, setTravelDestination] = useState([]);
    const [online_retail, setOnlineRetail] = useState([]);
    const [retail, setRetail] = useState([]);
    const [two_wheeler, setTwoWheeler] = useState([]);
    const [laundry_washing_machine, setLaundry_washing_machine] = useState([]);
    const [travel_destination_special, setTravel_destination_special] = useState([]);

    const [isLoading, setIsLoading] = useState(false);



    const [campaign_name, setCampaign_name] = useState([]);
    const [campaign_objective, setCampaign_objective] = useState([]);

    const [inclusionArray, setInclusionArray] = useState([]);
    const [exclusiveArray, setExclusiveArray] = useState([]);


    const [inclusion_list, setInclusion_list] = useState({});
    const [exclusion_list, setExclusion_list] = useState({});
    const [fb_gender, setFbGender] = useState(["0"]);
    // const [fb_locations, setFbLocations] = useState(["home&recent"]);
    const [fb_age_min, setFb_age_min] = useState("20");
    const [fb_age_max, setFb_age_max] = useState("65");

    const [presetName, setPresetName] = useState('');

    const [deletePresetName, setDeletePresetName] = useState('');
    const [presetsData, setPresetsData] = useState([]);

    const [time_estimate_data, setTime_estimate_data] = useState([])
    const [reachedEstimationLower, setReachedEstimationLower] = useState([])
    const [reachedEstimationUpper, setReachedEstimationUpper] = useState([])
    const [reachedEstimationSolo, setReachedEstimationSolo] = useState([])
    const [commandStatus, setCommandStatus] = useState([])
    const [presetDataInclusion, setPresetDataInclusion] = useState([])
    const [presetDataExclusion, setPresetDataExclusion] = useState([])



    const handlePresetSelection = (item) => {
        const presetData = item.presetData

        const {
            city_name,
            income,
            regio_type,
            lifestyle,
            sec_reference,
            smaller_towns,
            health_care,
            insurance,
            banking_product,
            credit_card,
            automobile,
            real_estate,
            laundry,
            personal_wash,
            packaged_food,
            cosmetics,
            fashion,
            jewellery_total,
            jewellery_gold,
            jewellery_diamond,
            travel_spend,
            travel_destination,
            online_retail,
            retail,
            two_wheeler,
            fb_gender,
            // fb_locations,
            fb_age_min,
            fb_age_max,
            inclusion_list,
            exclusion_list,
            laundry_washing_machine,
            travel_destination_special


        } = presetData;

        setCityName(city_name || []);
        setIncome(income || []);
        setRegionType(regio_type || []);
        setLifestyle(lifestyle || []);
        setSecReference(sec_reference || []);
        setSmallerTowns(smaller_towns || []);
        setHealthCare(health_care || []);
        setInsurance(insurance || []);
        setBankingProduct(banking_product || []);
        setCreditCard(credit_card || []);
        setAutomobile(automobile || []);
        setRealEstate(real_estate || []);
        setLaundry(laundry || []);
        setPersonalWash(personal_wash || []);
        setPackagedFood(packaged_food || []);
        setCosmetics(cosmetics || []);
        setFashion(fashion || []);
        setJewelleryTotal(jewellery_total || []);
        setJewelleryGold(jewellery_gold || []);
        setJewelleryDiamond(jewellery_diamond || []);
        setTravelSpend(travel_spend || []);
        setTravelDestination(travel_destination || []);
        setOnlineRetail(online_retail || []);
        setRetail(retail || []);
        setTwoWheeler(two_wheeler || []);
        setFbGender(fb_gender || []);
        // setFbLocations(fb_locations || []);
        setFb_age_min(fb_age_min || []);
        setFb_age_max(fb_age_max || []);
        setInclusion_list(inclusion_list || []);
        setPresetDataInclusion(inclusion_list || [])
        setExclusion_list(exclusion_list || []);
        setPresetDataExclusion(exclusion_list || [])
        setLaundry_washing_machine(laundry_washing_machine || []);
        setTravel_destination_special(travel_destination_special || []);
        setReachedEstimationLower(item.reachedEstimationLower)
        setReachedEstimationUpper(item.reachedEstimationUpper)
        setExclusiveArray([])
        setInclusionArray([])

    };




    const handleMultipleSelection = (event, setState) => {
        const { value, checked } = event.target;
        if (checked) {
            setState((prevValue) => [...prevValue, value]);
        } else {
            setState((prevValue) =>
                prevValue.filter((option) => option !== value)
            );
        }
    };

    const handleCityChange = (event) => {
        const { value } = event.target;
        setCityName([value]);
    };



    const handleIncomeChange = (event) => {
        handleMultipleSelection(event, setIncome);
    };

    const handleRegionTypeChange = (event) => {
        handleMultipleSelection(event, setRegionType);
    };

    const handleLifestyleChange = (event) => {
        handleMultipleSelection(event, setLifestyle);
    };

    const handleSecReferenceChange = (event) => {
        handleMultipleSelection(event, setSecReference);
    };

    const handleSmallerTownsChange = (event) => {
        handleMultipleSelection(event, setSmallerTowns);
    };

    const handleHealthCareChange = (event) => {
        handleMultipleSelection(event, setHealthCare);
    };

    const handleInsuranceChange = (event) => {
        handleMultipleSelection(event, setInsurance);
    };

    const handleBankingProductChange = (event) => {
        handleMultipleSelection(event, setBankingProduct);
    };

    const handleCreditCardChange = (event) => {
        handleMultipleSelection(event, setCreditCard);
    };

    const handleAutomobileChange = (event) => {
        handleMultipleSelection(event, setAutomobile);
    };

    const handleRealEstateChange = (event) => {
        handleMultipleSelection(event, setRealEstate);
    };

    const handleLaundryChange = (event) => {
        handleMultipleSelection(event, setLaundry);
        handleLaundryWashingMachineChange(event);
    };

    const handleLaundryWashingMachineChange = (event) => {
        const { value, checked } = event.target;
        if (value === "laundry_washing_machine") {
            if (checked) {
                setLaundry_washing_machine(["laundry_washing_machine"]);
            } else {
                setLaundry_washing_machine([]);
            }
        }
    };

    const handleTravelDestinationChange = (event) => {
        handleMultipleSelection(event, setTravelDestination);
        handleTravelDestinationSpecialChange(event);
    };
    const handleTravelDestinationSpecialChange = (event) => {
        const { value, checked } = event.target;
        if (value === "travel_destination_special") {
            if (checked) {
                setTravel_destination_special(["travel_destination_special"]);
            } else {
                setTravel_destination_special([]);
            }
        }
    };



    const handlePersonalWashChange = (event) => {
        handleMultipleSelection(event, setPersonalWash);
    };

    const handlePackagedFoodChange = (event) => {
        handleMultipleSelection(event, setPackagedFood);
    };

    const handleCosmeticsChange = (event) => {
        handleMultipleSelection(event, setCosmetics);
    };

    const handleFashionChange = (event) => {
        handleMultipleSelection(event, setFashion);
    };

    const handleJewelleryTotalChange = (event) => {
        handleMultipleSelection(event, setJewelleryTotal);
    };

    const handleJewelleryGoldChange = (event) => {
        handleMultipleSelection(event, setJewelleryGold);
    };

    const handleJewelleryDiamondChange = (event) => {
        handleMultipleSelection(event, setJewelleryDiamond);
    };

    const handleTravelSpendChange = (event) => {
        handleMultipleSelection(event, setTravelSpend);
    };



    const handleOnlineRetailChange = (event) => {
        handleMultipleSelection(event, setOnlineRetail);
    };

    const handleRetailChange = (event) => {
        handleMultipleSelection(event, setRetail);
    };

    const handleTwoWheelerChange = (event) => {
        handleMultipleSelection(event, setTwoWheeler);
    };
    const handleFbGenderChange = (event) => {
        setFbGender(event.target.value);
    };

    // const handleFbLocationsChange = (event) => {
    //     setFbLocations(event.target.value);
    // }

    const handleFb_age_minChange = (event) => {
        setFb_age_min(event.target.value);
    };

    const handleFb_age_maxChange = (event) => {
        setFb_age_max(event.target.value);
    };


    const formdata = {
        city_name: city_name,
        income: income,
        regio_type: regio_type,
        lifestyle: lifestyle,
        sec_reference: sec_reference,
        smaller_towns: smaller_towns,
        health_care: health_care,
        insurance: insurance,
        banking_product: banking_product,
        credit_card: credit_card,
        automobile: automobile,
        real_estate: real_estate,
        laundry: laundry,
        personal_wash: personal_wash,
        packaged_food: packaged_food,
        cosmetics: cosmetics,
        fashion: fashion,
        jewellery_total: jewellery_total,
        jewellery_gold: jewellery_gold,
        jewellery_diamond: jewellery_diamond,
        travel_spend: travel_spend,
        travel_destination: travel_destination,
        online_retail: online_retail,
        retail: retail,
        two_wheeler: two_wheeler,
        fb_gender: fb_gender,
        // fb_locations: fb_locations,
        fb_age_min: fb_age_min,
        fb_age_max: fb_age_max,
        inclusion_list: inclusion_list,
        exclusion_list: exclusion_list,
        laundry_washing_machine: laundry_washing_machine,
        travel_destination_special: travel_destination_special,

    };


    const filter_names_campaign = {
        campaign_name: campaign_name,
        campaign_objective: campaign_objective
    }


    const handleSavePreset = () => {
        if (!presetName) {
            setAlertMessage("Please provide a name to the audience.")
            setShowAlert(true)
        } else {
            const data = {
                formdata: formdata,
                user_email: user.email,
                preset_name: presetName,
                reachedEstimationLower: reachedEstimationLower,
                reachedEstimationUpper: reachedEstimationUpper,
            };

            axios
                .post(`${BACKEND_URL}/save-preset`, data)
                .then(response => {
                    setAlertMessage(response.data.message)
                    setShowAlert(true)
                    setPresetName('');
                    document.getElementById('savePresetSolo').classList.remove('show');
                    document.body.classList.remove('modal-open');
                    document.getElementsByClassName('modal-backdrop')[0].remove();
                    fetchPresets();

                })
                .catch(error => {
                    setAlertMessage('Error saving audiences:', error)
                    setShowAlert(true)
                });
        }
    };



    const fetchPresets = () => {
        axios
            .get(`${BACKEND_URL}/get-presets`)
            .then(response => {
                setPresetsData(response.data.presets);
            })
            .catch(error => {
                setAlertMessage('Error fetching audiences:', error)
                setShowAlert(true)

            });
    };
    const fetchCredits = () => {
        axios
            .get(`${BACKEND_URL}/get_status`)
            .then(response => response.data)
            .then(data => {
                setCommandStatus(data.status_text)
            })
            .catch(error => {
                setAlertMessage('Error fetching status...', error)
                setShowAlert(true)
            });
    };

    useEffect(() => {
        fetchPresets();
    }, []);



    useEffect(() => {
        fetchCredits();
        const interval = setInterval(fetchCredits, 10000);
        return () => clearInterval(interval);
    }, []);




    const handleDeletePreset = (preset_name) => {
        axios
            .delete(`${BACKEND_URL}/delete-preset/${preset_name}`)
            .then(response => {
                setAlertMessage(response.data.message)
                setShowAlert(true)
                setDeletePresetName('');
                document.getElementById('deletePresetSolo').classList.remove('show');
                document.body.classList.remove('modal-open');
                document.getElementsByClassName('modal-backdrop')[0].remove();
                fetchPresets();

            })
            .catch(error => {
                setAlertMessage('Error deleting audience:', error)
                setShowAlert(true)

            });
    };


    // console.log(campaign_objective)
    const handleCreateCampaign = () => {
        if (campaign_name.length && campaign_objective.length > 0) {
            const data = {
                "data": formdata,
                "camapign_data": filter_names_campaign
            }
            setCommandStatus("Calculating Campaign...")
            setTime_estimate_data([])
            document.getElementById('createCompaign').classList.remove('show');
            document.body.classList.remove('modal-open');
            document.getElementsByClassName('modal-backdrop')[0].remove();

            axios.post(`${BACKEND_URL}/create_campaign`, data)
                .then(response => {
                    console.log(response)
                    setCommandStatus("Ready")
                    setAlertMessage(response.data.status)
                    setShowAlert(true)
                    setCampaign_name([])
                    setCampaign_objective([])


                })
                .catch(error => {
                    setAlertMessage("Error", error)
                    setShowAlert(true)


                });
        } else {
            setAlertMessage("Please enter Campaign Name and Objective!")
            setShowAlert(true)

        }
    }


    const handleTimeEstimate = () => {
        if (city_name.length > 0) {
            const data = { "data": formdata };
            setCommandStatus("Calculating Time...")
            axios.post(`${BACKEND_URL}/calculate_time`, data)
                .then(response => {
                    const resultData = response.data;
                    if (resultData.status === 'done') {
                        setTime_estimate_data(resultData);
                    } else if (resultData.status === 'error') {
                        setAlertMessage("Error: " + resultData.message);
                        setShowAlert(true);
                        // setCommandStatus("Ready")
                    }
                    setReachedEstimationLower([])
                    setReachedEstimationUpper([])
                    setCommandStatus("Ready")

                })
                .catch(error => {
                    setAlertMessage("Error: " + error.message);
                    setShowAlert(true);
                });
        } else {
            setAlertMessage("Please Select City!")
            setShowAlert(true)
        }
    };


    const handleKentrixSolo = () => {
        if (city_name.length > 0) {
            const data = { "data": formdata };
            setIsLoading(true);
            setCommandStatus("Calculating market audience...")


            axios.post(`${BACKEND_URL}/calculate_reach_kentrix_solo`, data)
                .then(response => {
                    const resultData = response.data;
                    if (resultData.message === 'done') {
                        setReachedEstimationSolo(resultData.data);
                    } else if (resultData.message === 'error') {
                        setAlertMessage("Error: " + resultData.error);
                        setShowAlert(true);
                        setReachedEstimationSolo([])
                    }
                    setIsLoading(false);
                    setCommandStatus("Ready")
                })
                .catch(error => {
                    setAlertMessage("Error: " + error.message);
                    setShowAlert(true);
                    setIsLoading(false);
                    setCommandStatus("Ready")
                });
        } else {
            setAlertMessage("Please Select City!");
            setShowAlert(true);
        }
    };





    // const handleReachEstimate = () => {
    //     if (city_name.length > 0) {
    //         const data = { data: formdata };
    //         setCommandStatus("Calculating Reach...")
    //         axios
    //             .post(`${BACKEND_URL}/calculate_reach`, data)
    //             .then(response => {
    //                 const resultData = response.data;
    //                 setReachedEstimationLower(resultData.data.users_lower_bound)
    //                 setReachedEstimationUpper(resultData.data.users_upper_bound)
    //                 setCommandStatus("Ready")
    //             })
    //             .catch(error => {
    //                 setAlertMessage("Error", error)
    //                 setShowAlert(true)
    //             });
    //     } else {
    //         setAlertMessage("Please Select City!")
    //         setShowAlert(true)
    //     }
    // };


    const handleReset = () => {
        window.location.reload();
    };


    const handleLogout = () => {
        setLoginUser({});
        localStorage.removeItem('user');

        axios.post(`${BACKEND_URL}/logout`)
            .then(response => {
                setAlertMessage(response.data.message)
                setShowAlert(true)
                navigate("/login");
            })
            .catch(error => {
                setAlertMessage('Logout failed:', error)
                setShowAlert(true)
            });
    };


    const [instanceStatus, setInstanceStatus] = useState("off");

    const fetchInstaceStatus = () => {
        axios
            .post(`${BACKEND_URL}/instance-status`, {
                ews_instance_url: user.ews_instance_url
            })
            .then(response => {
                setInstanceStatus(response.data.instanceStatus);
            })
            .catch(error => {
                setAlertMessage('Error fetching Status', error)
                setShowAlert(true)

            });
    };

    const handleInstanceUpdate = () => {
        axios.post(`${BACKEND_URL}/instance-status-update`, {
            instanceStatus: instanceStatus === "ON" ? "OFF" : "ON",
            ews_instance_url: user.ews_instance_url
        })
            .then(response => {
                setInstanceStatus(response.data.instanceStatus);
                document.getElementById('instanceStatus').classList.remove('show');
                document.body.classList.remove('modal-open');
                document.getElementsByClassName('modal-backdrop')[0].remove();

                setAlertMessage(response.data.message);
                setShowAlert(true)

            })
            .catch(error => {
                setAlertMessage('An error occurred. Please try again.', error);
                setShowAlert(true)
            });
    }











    return (

        <div className="main_container">
            <button className="react_tab_toggle" onClick={handleToggle}>
                {showMenu ? <FaTimes /> : <FaBars />}
            </button>

            <Tabs forceRenderTabPanel={true} >

                <TabList className={`tab_menu ${showMenu ? 'show' : ''}`}>
                    <Tab onClick={handleLinkClick}>
                        <p>Persona 360 Filters</p>
                    </Tab>
                    {/* <Tab onClick={handleLinkClick}>
                        <p>Facebook Filters </p>
                    </Tab> */}

                    <Tab onClick={handleLinkClick}>
                        <p>My Account </p>
                    </Tab>



                    <div className="tab_button">
                        <button
                            data-bs-toggle="modal"
                            data-bs-target="#instanceStatus"
                            onClick={fetchInstaceStatus}>
                            RDP Connection Status
                        </button>
                    </div>

                    <div className="tab_button">
                        <a href='https://drive.google.com/file/d/1F2Q50Q46STDHiWBUwg3hEtcKGgCuhP6y/view' target='_balnk'><button onClick={handleLinkClick}>
                            Segmentation Booklet
                        </button> </a>
                    </div>




                    <button className="logout_btn" onClick={handleLogout}>
                        <p>Logout <FiLogOut /></p>
                    </button>
                </TabList>


                <TabPanel>
                    <KentrixFiltersPage
                        user={user}
                        handleCityChange={handleCityChange}
                        handleIncomeChange={handleIncomeChange}
                        handleRegionTypeChange={handleRegionTypeChange}
                        handleLifestyleChange={handleLifestyleChange}
                        handleSecReferenceChange={handleSecReferenceChange}
                        handleSmallerTownsChange={handleSmallerTownsChange}
                        handleHealthCareChange={handleHealthCareChange}
                        handleInsuranceChange={handleInsuranceChange}
                        handleBankingProductChange={handleBankingProductChange}
                        handleCreditCardChange={handleCreditCardChange}
                        handleAutomobileChange={handleAutomobileChange}
                        handleRealEstateChange={handleRealEstateChange}
                        handleLaundryChange={handleLaundryChange}
                        handlePersonalWashChange={handlePersonalWashChange}
                        handlePackagedFoodChange={handlePackagedFoodChange}
                        handleCosmeticsChange={handleCosmeticsChange}
                        handleFashionChange={handleFashionChange}
                        handleJewelleryTotalChange={handleJewelleryTotalChange}
                        handleJewelleryGoldChange={handleJewelleryGoldChange}
                        handleJewelleryDiamondChange={handleJewelleryDiamondChange}
                        handleTravelSpendChange={handleTravelSpendChange}
                        handleTravelDestinationChange={handleTravelDestinationChange}
                        handleOnlineRetailChange={handleOnlineRetailChange}
                        handleRetailChange={handleRetailChange}
                        handleTwoWheelerChange={handleTwoWheelerChange}
                        formdata={formdata}

                        presetName={presetName}
                        setPresetName={setPresetName}
                        deletePresetName={deletePresetName}
                        setDeletePresetName={setDeletePresetName}
                        handleReset={handleReset}
                        handleTimeEstimate={handleTimeEstimate}
                        handleKentrixSolo={handleKentrixSolo}
                        // handleReachEstimate={handleReachEstimate}
                        handleSavePreset={handleSavePreset}
                        handleCreateCampaign={handleCreateCampaign}
                        presetsData={presetsData}
                        handleDeletePreset={handleDeletePreset}
                        time_estimate_data={time_estimate_data}
                        reachedEstimationSolo={reachedEstimationSolo}
                        campaign_name={campaign_name}
                        setCampaign_name={setCampaign_name}
                        campaign_objective={campaign_objective}
                        setCampaign_objective={setCampaign_objective}
                        commandStatus={commandStatus}
                        handlePresetSelection={handlePresetSelection}
                        isLoading={isLoading}

                    />
                </TabPanel>
                {/* <TabPanel>
                    <FacebookFiltersPage
                        formdata={formdata}
                        handleFbGenderChange={handleFbGenderChange}
                        // handleFbLocationsChange={handleFbLocationsChange}
                        handleFb_age_minChange={handleFb_age_minChange}
                        handleFb_age_maxChange={handleFb_age_maxChange}
                        presetDataInclusion={presetDataInclusion}
                        presetDataExclusion={presetDataExclusion}

                        setInclusion_list={setInclusion_list}
                        setExclusion_list={setExclusion_list}

                        setAlertMessage={setAlertMessage}
                        setShowAlert={setShowAlert}

                        presetName={presetName}
                        setPresetName={setPresetName}
                        deletePresetName={deletePresetName}
                        setDeletePresetName={setDeletePresetName}
                        handleReset={handleReset}
                        handleTimeEstimate={handleTimeEstimate}
                        handleKentrixSolo={handleKentrixSolo}
                        handleReachEstimate={handleReachEstimate}
                        handleSavePreset={handleSavePreset}
                        handleCreateCampaign={handleCreateCampaign}
                        presetsData={presetsData}
                        handleDeletePreset={handleDeletePreset}
                        time_estimate_data={time_estimate_data}
                        reachedEstimationUpper={reachedEstimationUpper}
                        reachedEstimationLower={reachedEstimationLower}
                        campaign_name={campaign_name}
                        setCampaign_name={setCampaign_name}
                        campaign_objective={campaign_objective}
                        setCampaign_objective={setCampaign_objective}
                        commandStatus={commandStatus}
                        handlePresetSelection={handlePresetSelection}
                        inclusionArray={inclusionArray}
                        exclusiveArray={exclusiveArray}
                        setInclusionArray={setInclusionArray}
                        setExclusiveArray={setExclusiveArray}

                    />
                </TabPanel> */}
                <TabPanel>
                    <MyAccount
                        user={user}
                        setAlertMessage={setAlertMessage}
                        setShowAlert={setShowAlert}
                    />
                </TabPanel>

            </Tabs>

            <div className="modal fade" id="instanceStatus" tabIndex="-1" aria-labelledby="instanceStatusLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="instanceStatusLabel">RDP Connection Status</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="popup_item">
                                <div className="instance_status_container">
                                    {/* <h5><strong>Current Status: </strong>
                                        {instanceStatus === "PENDING" ? "Process is in progress, Please check after some time" : instanceStatus === "ON" ? "ON" : "OFF"}
                                    </h5> */}


                                    <h5>
                                        <strong>Current Status: </strong>
                                        <span className={instanceStatus === "PENDING" ? "pending_color" : instanceStatus === "ON" ? "instance_on_bg" : "instance_off_bg"}>
                                            {instanceStatus === "PENDING" ? "Process is in progress, Please check after some time" : instanceStatus === "ON" ? "ON" : "OFF"}
                                        </span>
                                    </h5>






                                    {instanceStatus !== "PENDING" && (
                                        <button onClick={handleInstanceUpdate} className='btn_general btn_instance_status'>
                                            {instanceStatus === "ON" ? "OFF" : "ON"}
                                        </button>
                                    )}
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>




        </div>

    )
}

export default Home