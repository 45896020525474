import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../components/BackendURL";
import { FiLogOut } from "react-icons/fi";
import { CityData } from "../data/CityNameData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const AdminPage = ({ setLoginUser, setAlertMessage, setShowAlert }) => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [updateUser, setUpdateUser] = useState([]);
    const [facebookAccountID, setFacebookAccountID] = useState([]);
    const [facebookAccountAT, setFacebookAccountAT] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [instanceURL, setInstanceURL] = useState([]);

    const handleCitySelection = (event, values) => {
        setSelectedCities(values);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/users`);
            setUsers(response.data.users);
        } catch (error) {
            setAlertMessage("Error fetching users:", error);
            setShowAlert(true);
        }
    };

    const deleteUser = async (userEmail) => {
        const confirmDelete = window.confirm(
            "Are you sure you want to delete this user?"
        );
        if (confirmDelete) {
            try {
                await axios
                    .delete(`${BACKEND_URL}/users/${userEmail}`)
                    .then((response) => {
                        fetchUsers();
                        setAlertMessage(response.data.message);
                        setShowAlert(true);
                    });
            } catch (error) {
                setAlertMessage("Error deleting user:", error);
                setShowAlert(true);
            }
        }
    };

    const handleLogout = () => {
        setLoginUser({});
        localStorage.removeItem("user");

        axios
            .post(`${BACKEND_URL}/logout`)
            .then((response) => {
                setAlertMessage(response.data.message);
                setShowAlert(true);
                navigate("/login");
            })
            .catch((error) => {
                setAlertMessage("Logout failed:", error);
                setShowAlert(true);
            });
    };

    const handleUpdateUser = () => {
        axios
            .post(`${BACKEND_URL}/update_user`, {
                user_mail: updateUser.email,
                selectedCities: selectedCities,
                facebook_accountID: facebookAccountID,
                facebook_authenticate_token: facebookAccountAT,
                ews_instance_url:instanceURL
            })
            .then((response) => {
                setFacebookAccountAT("");
                setFacebookAccountID("");
                setInstanceURL("");
                setSelectedCities([]);
                document.getElementById("updateUser").classList.remove("show");
                document.body.classList.remove("modal-open");
                document.getElementsByClassName("modal-backdrop")[0].remove();
                setAlertMessage(response.data.message);
                setShowAlert(true);
                fetchUsers();
            })
            .catch((error) => {
                setAlertMessage("Error:", error);
                setShowAlert(true);
            });
    };

    return (
        <div className="admin_container">
            <div className="panel-content">
                <div className="section_content">
                    <h1>Admin Panel</h1>
                    <Link to="/admin/create-user">
                        {" "}
                        <button className="btn_general">Create User</button>
                    </Link>
                    <button className="btn_general" onClick={handleLogout}>
                        Logout <FiLogOut />
                    </button>
                </div>
                <div className="section_content">
                    <table className="item_table">
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>User Email</th>
                                <th>Registered Date</th>
                                <th>Facebook API Access</th>
                                <th>Assigned Cities</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <>
                                    <tr key={user.id}>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{new Date(new Date(user.registration_time).setMonth(new Date(user.facebook_api_time).getMonth())).toDateString()}</td>

                                        <td>
                                            {new Date(new Date(user.facebook_api_time).setDate(new Date(user.facebook_api_time).getDate() + 60)).toDateString()}<br />
                                            ({Math.ceil((new Date(new Date(user.facebook_api_time).setDate(new Date(user.facebook_api_time).getDate() + 60)).getTime() - Date.now()) / (1000 * 60 * 60 * 24))} days left)
                                        </td>




                                        <td>
                                            {user.selectedCities && (
                                                <>
                                                    {user.selectedCities.map((city) => (
                                                        <p key={city.id}>{city.CityName}</p>
                                                    ))}
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            {user.isAdmin !== "true" && (
                                                <div className="user_delete_btn">
                                                    <button onClick={() => deleteUser(user.email)}>
                                                        Delete User
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            <div className="user_update_btn">
                                                <button
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#updateUser"
                                                    onClick={() => setUpdateUser(user)}
                                                >
                                                    Update User
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div
                    className="modal fade"
                    id="updateUser"
                    tabIndex="-1"
                    aria-labelledby="updateUserLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="updateUserLabel">
                                    Update User
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="popup_item">
                                    {updateUser &&
                                        updateUser.selectedCities &&
                                        updateUser.selectedCities.length > 0 && (
                                            <>
                                                <p>
                                                    <strong>User Name:</strong> {updateUser.name}
                                                </p>
                                                <p>
                                                    <strong>User Email:</strong> {updateUser.email}
                                                </p>

                                                <p>
                                                    <strong>Assigned Cities: </strong>
                                                    {updateUser.selectedCities.map((city, index) => (
                                                        <span key={city.id}>
                                                            {city.CityName}
                                                            {index !== updateUser.selectedCities.length - 1
                                                                ? ", "
                                                                : ""}
                                                        </span>
                                                    ))}
                                                </p>
                                                <Autocomplete
                                                    multiple
                                                    id="city-dropdown"
                                                    options={CityData}
                                                    getOptionLabel={(city) => city.CityName}
                                                    getOptionSelected={(city, value) =>
                                                        city.CityValue === value.CityValue
                                                    }
                                                    value={selectedCities}
                                                    onChange={handleCitySelection}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className="auth_dropdown"
                                                            variant="outlined"
                                                            label="Update Assigned Cities"
                                                            placeholder="Update Assigned Cities"
                                                        />
                                                    )}
                                                />
                                                <p>
                                                    <strong>Facebook Account ID:</strong>
                                                </p>
                                                <textarea
                                                    placeholder={updateUser.facebook_accountID}
                                                    rows={2}
                                                    value={facebookAccountID}
                                                    onChange={(event) =>
                                                        setFacebookAccountID(event.target.value)
                                                    }
                                                    required
                                                />

                                                <p>
                                                    <strong>Facebook Authenticate Token:</strong>
                                                </p>
                                                <textarea
                                                    placeholder={updateUser.facebook_authenticate_token}
                                                    rows={4}
                                                    value={facebookAccountAT}
                                                    onChange={(event) =>
                                                        setFacebookAccountAT(event.target.value)
                                                    }
                                                />
                                                <p>
                                                    <strong>AWS Instance URL:</strong>
                                                </p>
                                                <textarea
                                                    placeholder={updateUser.ews_instance_url}
                                                    rows={2}
                                                    value={instanceURL}
                                                    onChange={(event) =>
                                                        setInstanceURL(event.target.value)
                                                    }
                                                    required
                                                />
                                            </>
                                        )}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn_general"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn_general"
                                    onClick={handleUpdateUser}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPage;
