import React from 'react'
import Logo from "../assets/images/logo.jpeg"
import PersonaLogo from "../assets/images/Persona360 Logo.png"
import { Link } from 'react-router-dom'

const Navbar = ({ user }) => {
    return (
        <div className='nav_container'>
            <div className="nav_logo_main">
                <Link to="/"><img src={PersonaLogo} alt='logo' /></Link>
            </div>
            <div className="nav_menu">
            <div className="nav_logo">
                <img src={Logo} alt='logo' />
            </div>
                <div className="nav_item">
                    {user && user.id ? (
                        <>
                            <p className='user_profile'>Hey {user.name} !</p>
                        </>
                    ) : (
                        <>
                            <Link to="/login"><button className='btn_auth'>Login</button></Link>
                        </>
                    )}

                </div>
            </div>

        </div>
    )
}

export default Navbar