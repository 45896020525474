import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Home from './pages/Home'
import Login from './components/Login'
import Register from './components/Register'
import Navbar from './components/Navbar'
import AdminPage from './pages/AdminPage';
import AdminLogin from './components/AdminLogin';
import AlertMessage from './components/AlertMessage';
import Onboarding from './pages/Onboarding';


const App = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [user, setLoginUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    try {
      return storedUser ? JSON.parse(storedUser) : {};
    } catch (error) {
      console.error('Error parsing stored user data:', error);
      return {};
    }
  });

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);


  const isAdmin = user && user.isAdmin;
  const isOnboarding = user && user.checkedTerms;

  return (
    <Router>
      <Navbar user={user} />
      {alertMessage && <AlertMessage text={alertMessage} showAlert={showAlert}
        setShowAlert={setShowAlert} />}
      <Routes>
        <Route
          path="/"
          element={
            user && user.id ? (
              isOnboarding == "true" ? (
                <Home
                  user={user}
                  setLoginUser={setLoginUser}
                  setAlertMessage={setAlertMessage}
                  setShowAlert={setShowAlert}
                />
              ) : (
                <Navigate to="/onboarding" replace={true} />
              )
            ) : (
              <Login
                setLoginUser={setLoginUser}
                setAlertMessage={setAlertMessage}
                setShowAlert={setShowAlert}
              />
            )
          }
        />

        <Route path='/login' element={<Login
          setLoginUser={setLoginUser}
          setAlertMessage={setAlertMessage}
          setShowAlert={setShowAlert} />} />

        <Route path='/onboarding'
          element={
            user && user.id ? (
              <Onboarding
                user={user}
                setAlertMessage={setAlertMessage}
                setShowAlert={setShowAlert}
                setLoginUser={setLoginUser}
              />

            ) : (
              <Login
                setLoginUser={setLoginUser}
                setAlertMessage={setAlertMessage}
                setShowAlert={setShowAlert} />

            )
          }/>

        <Route
          path="/admin"
          element={isAdmin == "true" ? (<AdminPage
            setLoginUser={setLoginUser}
            setAlertMessage={setAlertMessage}
            setShowAlert={setShowAlert} />) : (<AdminLogin
              setLoginUser={setLoginUser}
              setAlertMessage={setAlertMessage}
              setShowAlert={setShowAlert}
            />)} />

        <Route path="/admin/create-user" element={isAdmin == "true" ? (<Register
          setAlertMessage={setAlertMessage}
          setShowAlert={setShowAlert}
        />) : (<AdminLogin
          setLoginUser={setLoginUser}
          setAlertMessage={setAlertMessage}
          setShowAlert={setShowAlert}
        />)} />
      </Routes>
    </Router>
  )
}

export default App