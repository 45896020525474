// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .react_tab_toggle {
    display: block;
    font-size: 36px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border: none;
    padding: 5px;
    color: #008ecf;
    position: absolute;
    top: 0;
    left: 5vw;
  }
  .main_container {
    flex-direction: column;
  }
  .tab_menu {
    width: 100vw;
    position: fixed;
    top: 10vh;
    left: -100%;
    color: white;
    height: 90vh;
    padding: 2rem 0;
    z-index: 10000 !important;
    transition: 0.5s;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .show {
    left: 0;
  }
  .react-tabs{
    width: 100vw;
  }
  .react-tabs__tab-panel {
    width: 100vw;
    padding: 10px;
    height: 100%;
  }
  .nav_logo {
    width: 100px;
    margin-left: 15vw;
  }
  .logout_btn {
    position: relative;
    width: 100%;
    font-size: 20px;
    bottom: 0;
    text-align: left;
  }
  .command_panel{
    width: 100vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/responsive.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE;IACE,cAAc;IACd,eAAe;IACf,eAAe;IACf,oCAAoC;IACpC,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,MAAM;IACN,SAAS;EACX;EACA;IACE,sBAAsB;EACxB;EACA;IACE,YAAY;IACZ,eAAe;IACf,SAAS;IACT,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;IAChB,wCAAwC;EAC1C;EACA;IACE,OAAO;EACT;EACA;IACE,YAAY;EACd;EACA;IACE,YAAY;IACZ,aAAa;IACb,YAAY;EACd;EACA;IACE,YAAY;IACZ,iBAAiB;EACnB;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,SAAS;IACT,gBAAgB;EAClB;EACA;IACE,YAAY;EACd;AACF","sourcesContent":["@media (max-width: 991px) {\n}\n\n@media (max-width: 767px) {\n  .react_tab_toggle {\n    display: block;\n    font-size: 36px;\n    cursor: pointer;\n    background-color: rgb(255, 255, 255);\n    border: none;\n    padding: 5px;\n    color: #008ecf;\n    position: absolute;\n    top: 0;\n    left: 5vw;\n  }\n  .main_container {\n    flex-direction: column;\n  }\n  .tab_menu {\n    width: 100vw;\n    position: fixed;\n    top: 10vh;\n    left: -100%;\n    color: white;\n    height: 90vh;\n    padding: 2rem 0;\n    z-index: 10000 !important;\n    transition: 0.5s;\n    overflow-y: auto;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  .show {\n    left: 0;\n  }\n  .react-tabs{\n    width: 100vw;\n  }\n  .react-tabs__tab-panel {\n    width: 100vw;\n    padding: 10px;\n    height: 100%;\n  }\n  .nav_logo {\n    width: 100px;\n    margin-left: 15vw;\n  }\n  .logout_btn {\n    position: relative;\n    width: 100%;\n    font-size: 20px;\n    bottom: 0;\n    text-align: left;\n  }\n  .command_panel{\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
