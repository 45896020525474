import React, { useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { BACKEND_URL } from "./BackendURL"


const AdminLogin = ({ setLoginUser, setAlertMessage, setShowAlert }) => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [user, setUser] = useState({
        email: "",
        password: ""
    })
    const handleChange = e => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const adminLogin = () => {
        if (user.email === "" || user.password === "") {
            setAlertMessage("Please fill all fields")
            setShowAlert(true)
            return;
        }

        axios.post(`${BACKEND_URL}/login`, user)
            .then((res) => {
                const userData = res.data.user;
                if (userData && userData.isAdmin === "true") {
                    setAlertMessage(res.data.message);
                    setShowAlert(true);
                    setLoginUser(userData);
                    navigate("/admin");
                } else if (userData && userData.isAdmin !== "true") {
                    setAlertMessage("Access denied. Only admin users are allowed.");
                    setShowAlert(true);
                } else {
                    setAlertMessage(res.data.message);
                    setShowAlert(true);
                }
            })
            .catch((err) => {
                setAlertMessage("An error occurred. Please try again.", err);
                setShowAlert(true)

            });
    };




    return (
        <div className="auth_container">
            <div className="auth_item_box">

                <h2>Admin Login</h2>
                <div className="auth_form">
                    <input type="text" name="email" value={user.email} onChange={handleChange} required placeholder="Enter Admin Email"></input>

                    <input type={showPassword ? "text" : "password"} name="password" value={user.password} onChange={handleChange} required placeholder="Enter Admin Password" ></input>

                </div>

                <div className="login_checkbox">
                    <input
                        type="checkbox"
                        checked={showPassword}
                        onChange={toggleShowPassword}
                    />
                    Show Password
                </div>
                <div className="auth_form">
                    <button onClick={adminLogin}>Sign In</button>

                </div>


            </div>


        </div>



    )
}

export default AdminLogin
