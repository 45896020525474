import React, { useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { BACKEND_URL } from "./BackendURL"

const Login = ({ setLoginUser, setAlertMessage, setShowAlert }) => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [user, setUser] = useState({
        email: "",
        password: ""
    })

    const handleChange = e => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const login = () => {
        if (user.email === "" || user.password === "") {
            setAlertMessage("Please fill all fields")
            setShowAlert(true)

            return;
        }

        axios
            .post(`${BACKEND_URL}/login`, user)
            .then(res => {
                setAlertMessage(res.data.message)
                setShowAlert(true)
                setLoginUser(res.data.user);
                navigate("/");
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.message) {
                    setAlertMessage(err.response.data.message)
                    setShowAlert(true)
                } else {
                    setAlertMessage("An error occurred. Please try again.")
                    setShowAlert(true)
                }
            });
    };


    return (
        <div className='auth_container'>
            <div className="auth_item_box">
                <h2>Sign In</h2>
                <div className="auth_form">
                    <input type="text" name="email" value={user.email} onChange={handleChange} required placeholder="Enter your Email"></input>

                    <input type={showPassword ? "text" : "password"} name="password" value={user.password} onChange={handleChange} required placeholder="Enter your Password" ></input>
                </div>
                <div className="login_checkbox">
                    <input
                        type="checkbox"
                        checked={showPassword}
                        onChange={toggleShowPassword}
                    />Show Password
                </div>
                <div className="auth_form">
                    <button onClick={login}>Sign In</button>
                </div>
            </div>

        </div>
    )
}

export default Login