import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "./BackendURL";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CityData } from "../data/CityNameData";


const Register = ({ setAlertMessage, setShowAlert }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);


  const handleCitySelection = (event, values) => {
    setSelectedCities(values);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };



  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    facebook_accountID: "",
    facebook_authenticate_token: "",
    ews_instance_url: ""

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };



  const register = () => {
    const { name, email, password, facebook_accountID, facebook_authenticate_token,ews_instance_url, reEnterPassword } = user;
    if (name && email && facebook_accountID && facebook_authenticate_token && ews_instance_url && password && password === reEnterPassword) {

      axios
        .post(`${BACKEND_URL}/register`, {
          name: user.name,
          email: user.email,
          password: user.password,
          selectedCities: selectedCities,
          facebook_accountID: user.facebook_accountID,
          facebook_authenticate_token: user.facebook_authenticate_token,
          ews_instance_url:ews_instance_url
        })
        .then((res) => {
          setAlertMessage(res.data.message)
          setShowAlert(true)
          navigate("/admin");
        })
        .catch((err) => {
          setAlertMessage(err.response.data.message)
          setShowAlert(true)
        });
    } else {
      setAlertMessage("Invalid input")
      setShowAlert(true)

    }
  };

  return (
    <div className="auth_container">
      <div className="auth_item_box">


        <h2>Create User</h2>
        <div className="auth_form">
          <input
            type="text"
            name="name"
            value={user.name}
            required
            placeholder="User Name"
            onChange={handleChange}
          ></input>

          <input
            type="text"
            name="email"
            value={user.email}
            required
            placeholder="User Email"
            onChange={handleChange}
          ></input>

          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={user.password}
            required
            placeholder="Create Password"
            onChange={handleChange}
          ></input>
          <input
            className="auth_form_input"
            type={showPassword ? "text" : "password"}
            name="reEnterPassword"
            value={user.reEnterPassword}
            required
            placeholder="Re-enter Password"
            onChange={handleChange}
          ></input>
        </div>

        <div className="login_checkbox">
          <input
            type="checkbox"
            checked={showPassword}
            onChange={toggleShowPassword}
          />
          Show Password
        </div>

        <Autocomplete
          multiple
          id="city-dropdown"
          options={CityData}

          getOptionLabel={(city) => city.CityName}
          getOptionSelected={(city, value) => city.CityValue === value.CityValue}
          value={selectedCities}
          onChange={handleCitySelection}
          renderInput={(params) => (
            <TextField
              {...params}
              className="auth_dropdown"
              variant="outlined"
              label="Select Cities"
              placeholder="Select Cities"
            />
          )}
        />
        <div className="auth_form">
          <input
            type="text"
            name="facebook_accountID"
            value={user.facebook_accountID}
            required
            placeholder="Facebook Account ID"
            onChange={handleChange}
          ></input>
          <textarea
            name="facebook_authenticate_token"
            value={user.facebook_authenticate_token}
            required
            placeholder="Facebook Authenticate Token"
            onChange={handleChange}
            rows="3"></textarea>

<input
            type="text"
            name="ews_instance_url"
            value={user.ews_instance_url}
            required
            placeholder="EC2 Instance URL"
            onChange={handleChange}
          ></input>

        </div>


        <div className="auth_form">
          <button onClick={register}> Create User</button>

        </div>




      </div>


    </div>

  );
};

export default Register;
